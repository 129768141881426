import {VisualValueChartElement} from "../../models/valuechart/VisualValueChartElement";
import {Color} from "csstype";
import {getLevelDefaultColor} from "../../../core/utils/LevelColorUtil";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import * as React from "react";

/**
 * React lightweight component used to prerender invisible value chart elements
 * @param props
 * @constructor
 */
export function LightweightNestedValueChartElement(props: { viewModel: VisualValueChartElement }): JSX.Element {
  const bgColor: Color = getLevelDefaultColor(props.viewModel.level.index, props.viewModel.level.levelCount);
  const elementTransform = `translate(${props.viewModel.relativeX},${props.viewModel.relativeY})`;
  const visibleValueChartElements = props.viewModel.children.filter(c => c.visible);
  // recursively render LightweightNestedValueChartElements for all sub elements, use container <g> to relocate properly
  const rect: JSX.Element = <rect key={props.viewModel.id.toKey()}
                                  width={props.viewModel.width}
                                  height={props.viewModel.height} rx={DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC}
                                  ry={DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC}
                                  style={{fill: bgColor, stroke: "black"}}/>;
  let contentBox: JSX.Element = null;
  // nest elements if children available
  if (visibleValueChartElements.length !== 0) {
    const contentBoxTransform = `translate(${props.viewModel.level.contentDx},${DiagramVisualConstants.VC_GAP + DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT + props.viewModel.level.contentDy})`;
    contentBox = <g transform={contentBoxTransform}>
      {visibleValueChartElements.map((viewModel: VisualValueChartElement) => <LightweightNestedValueChartElement
          key={viewModel.id.toKey()} viewModel={viewModel}/>)}
    </g>;
  }
  return <g transform={elementTransform}>
    {rect}
    {contentBox}
  </g>;
}