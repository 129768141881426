import {Theme, withStyles, WithStyles, WithTheme} from "@material-ui/core";
import * as React from "react";
import Log from "../utils/Logger";
import {Classifier} from "../utils/ClassifierLogger";
import {createStyles, StyleRules} from "@material-ui/core/styles";
import classNames from "classnames";
import MenuToLeftIcon from "../icons/navigation/MenuToLeftIcon";
import autobind from "autobind-decorator";
import MenuToRightIcon from "../icons/navigation/MenuToRightIcon";

type SidebarType = "navigation" | "properties" | "menu";


interface LocalProps {
  type: SidebarType;
  // the visible / expanded / drawed out entry within the list of MetusSidebarEntry children
  visibleEntryIndex?: number;
  // callback when a sidebar entry is expanded or collapsed (visibleEntry is null)
  onVisibleEntryChange?: (visibleEntry: number) => void;
  // Add entries at the bottom of the iconbar which are not part of any section
  additionalIconbarEntries?: JSX.Element[];
}

// TODO MB: set css order using @material-ui/styles

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

const styles = (theme: Theme): StyleRules => {
  return createStyles({
    root: {
      display: "flex",
      width: "100%",
      height: "100%",
      backgroundColor: theme.metus.navigation.iconDivider,
    },

    iconBar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
    },
    propertiesIconBar: {
      flex: "0 0 56px",
      backgroundColor: theme.metus.properties.fill,
      color: theme.metus.properties.icon,
    },
    navigationIconBar: {
      flex: "0 0 64px",
      backgroundColor: theme.metus.navigation.fill,
      color: theme.metus.navigation.icon,
    },

    iconBarEntry: {
      padding: "16px",
      display: "flex", // without flex, there will be a vertical gap of 4 pixels between the inner SVG and the icon entry div
      alignItems: "center",
      "&:hover":{
        backgroundColor: theme.metus.navigation.active.secondaryFill // hover color
      }
    },
    propertiesIconBarEntry: {
      borderBottom: "1px solid " + theme.metus.properties.iconDivider,
    },
    navigationIconBarEntry: {
      height: "64px",
      width: "64px",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: 0,
      paddingRight: 0,
      borderBottom: "0px",
      flexDirection: "column"
    },
    menuIconBarEntry: {
      borderBottom: "0px",
    },
    navigationToggleIconBarEntry: {
      borderBottom: "1px solid " + theme.metus.navigation.iconDivider,
      paddingBottom: 15,
    },

    tabRoot: {
      flex: "1 1 auto",
      overflow: "auto",
    },
    propertiesTabRoot: {
      backgroundColor: theme.metus.properties.fill,
      color: theme.metus.properties.secondaryText,
      marginRight: "1px",
    },
    navigationTabRoot: {
      backgroundColor: theme.metus.properties.fill,
      color: theme.metus.navigation.secondaryText,
      marginLeft: "1px",
      // borderRight: "1px solid " + theme.metus.navigation.iconDivider
    },

    navigationIcon: {
      width: "32px",
      height: "32px",
    },
    propertiesIcon: {
      width: "24px",
      height: "24px",
    },
    additionalIcon: {
      color: theme.metus.navigation.icon,
      marginTop: "auto", // align at bottom
      marginBottom: 20,
    },

    navigationSubtitle: {
      fontSize: "12px",
      fontWeight: "normal",
      textAlign: "center",
      letterSpacing: "0px",
      lineHeight: "10px",
    },
    navigationRoot: {},
    propertiesRoot: {}
  });
};


type StyledLocalProps = LocalProps & WithStyles<typeof styles> & WithTheme;

interface MetusSidebarState {
  // the index of the expanded / drawed out MetusSidebarEntry within the list of MetusSidebarEntry children
  visibleEntryIndex: number;
  // when collapsed, save the entry which was visible. When expanded again by the toggle, the last visible entry
  // will be reopened
  lastVisibleEntryIndex: number;
}


/*
Sidebar consisting of icons arranged in a vertical column and a tab
 */
class MetusSidebar extends React.Component<StyledLocalProps, MetusSidebarState> {

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {
      visibleEntryIndex: props.visibleEntryIndex === undefined ? null : props.visibleEntryIndex,
      lastVisibleEntryIndex: null,
    };
  }

  render(): JSX.Element {
    const visibleEntryIndex = this.state.visibleEntryIndex;
    const isCollapsed = this.state.visibleEntryIndex === null;
    const children = this.props.children;
    const iconBarEntryClassNames = this.getClassNames("iconBarEntry");
    let sidebarType: SidebarType = this.props.type;
    let themePropsOfType = this.props.theme.metus[sidebarType];
    if (!themePropsOfType) {
      sidebarType = "navigation";
      themePropsOfType = this.props.theme.metus[sidebarType]
    }
    const isNavigation = sidebarType === "navigation";

    const icons = React.Children
        .map(children, (child, index) => {
          if (!React.isValidElement(child)) {
            return null;
          }
          const onClickHandler = this.handleIconClicked.bind(this, index);
          const isActive = index === visibleEntryIndex;
          const icon = (child.props as any).icon;
          const subtitle = (child.props as any).subtitle;
          const styledIcon = icon ? React.cloneElement(icon, {classes: {root: this.props.classes[sidebarType + "Icon"]}}) : null;
          const styledSubtitle = subtitle ?
              <div className={this.props.classes.navigationSubtitle}>{subtitle}</div> : null;
          // overwrite style for active icon
          const iconBarEntryStyle = isActive ? {
            backgroundColor: themePropsOfType.active.fill,
            color: themePropsOfType.active.icon,
            textColor: themePropsOfType.active.primaryText
          } : {};

          return <div className={iconBarEntryClassNames}
                      data-testselector={"IconBarEntry" + child.key + "#"}
                      onClick={onClickHandler}
                      style={iconBarEntryStyle}>
            {styledIcon}{styledSubtitle}
          </div>;
        });

    let toggleIcon;
    if (isNavigation && isCollapsed || (!isNavigation && !isCollapsed)) {
      toggleIcon = <MenuToLeftIcon classes={{root: this.props.classes[sidebarType + "Icon"]}}/>
    } else {
      toggleIcon = <MenuToRightIcon classes={{root: this.props.classes[sidebarType + "Icon"]}}/>
    }

    const toggleIconBarEntry = <div
        className={isNavigation ? classNames(this.props.classes.iconBarEntry, this.props.classes.navigationToggleIconBarEntry) : classNames(this.props.classes.iconBarEntry, this.props.classes.propertiesIconBarEntry)}
        data-testselector={"IconBarEntryToggle#"}
        onClick={this.handleToggle}>{toggleIcon}
    </div>

    const orderIconBar = isNavigation ? 0 : 1;// TODO MB: use @material-ui/styles and (prop) => ()
    const orderTab = isNavigation ? 1 : 0;// TODO MB: use @material-ui/styles
    let visibleEntry = null;
    if (visibleEntryIndex !== null) {
      const child = visibleEntryIndex === 0 && !Array.isArray(children) ? children : children[visibleEntryIndex];
      visibleEntry = <div className={this.getClassNames("tabRoot", sidebarType)}
                          style={{order: orderTab}}
                          data-testselector={isNavigation ? "NavigationTabRoot" : "PropertiesTabRoot"}>{child && child.props.children}</div>;
    }

    const iconbar = <div className={this.getClassNames("iconBar", sidebarType)} style={{order: orderIconBar}}>
      {toggleIconBarEntry}
      {icons}
      {this.props.additionalIconbarEntries && this.props.additionalIconbarEntries
          .map(icon => React.cloneElement(icon, {classes: {root: this.props.classes.additionalIcon}}))}
    </div>

    return <div className={this.getClassNames("root", sidebarType)}>{iconbar}{visibleEntry}</div>;
  }


  private handleIconClicked(visibleEntryIndex: number): void {
    const newVisibleEntry = visibleEntryIndex === this.state.visibleEntryIndex ? null : visibleEntryIndex;
    // console.log("Set visible entry", newVisibleEntry);
    this.setState((oldState) => ({
      visibleEntryIndex: newVisibleEntry,
      lastVisibleEntryIndex: oldState.visibleEntryIndex
    }));
    if (typeof this.props.onVisibleEntryChange === "function") {
      this.props.onVisibleEntryChange(newVisibleEntry);
    }
  }

  @autobind
  private handleToggle() {
    const lastOpenIndexOrFirst = this.state.lastVisibleEntryIndex || 0;
    const newVisible = this.state.visibleEntryIndex === null ? lastOpenIndexOrFirst : null;
    this.handleIconClicked(newVisible);
  }

  /**
   * Combine common and properties/navigation bar specific styles.
   * E.g. for base iconBarEntry => the classes iconBarEntry and navigationIconBarEntry are returnred, if the type
   * of this sidebar is navigation.
   * @param base
   */
  private getClassNames(base: string, sidebarType?: SidebarType): string {
    if (sidebarType === undefined) {
      sidebarType = this.props.type;
    }
    const classKey = sidebarType + base[0].toUpperCase() + base.substr(1);
    return classNames(this.props.classes[base], this.props.classes[classKey]);
  }
}

export default withStyles(styles, {withTheme: true})(MetusSidebar);
