import {TableId} from "../Core";
import {ViewType} from "../../../common/constants/Enums";
import {HierarchicalElementGraph} from "../elementgraph/ElementGraph";
import {
  allLowerLevelNodesAndAllConnectedChildrenMatcher,
  getNodeCollectorMatcher,
  HierarchicalNodeCollectorMatcher,
  isHierarchicalFilterText,
  isValidNodeCollectorText
} from "./NodeMatcher";
import {allPseudoMatcher, getFilterMatcher, IFilterMatcher, nonePseudoMatcher} from "./FilterMatcher";
import {modelStore} from "../../stores/ModelStore";
import {AttributeFilterData} from "./Filter";

/**
 * Created by P.Bernhard on 09.11.2017.
 */


class FilterData {
  protected _tables: TableId[];
  protected _filterText: string;
  protected _attributeName: string;
  protected _viewType: ViewType;

  public get tables(): TableId[] {
    return this._tables;
  }

  public get viewType(): ViewType {
    return this._viewType;
  }

  public get attributeName(): string {
    return this._attributeName;
  }

  public get filterText(): string {
    return this._filterText;
  }

  /**
   *
   * @param attributeName
   * @param filterText
   * @param viewType
   * @param tables tables In a structured table for each filter text field one gives all tables
   */
  constructor(attributeName: string, filterText: string, viewType: ViewType, ...tables: TableId[]) {
    if (viewType !== ViewType.StructuredTable && tables.length > 1)
      throw new Error("You cannot define one attribute filter text for multiple tables but in a structured table!");

    if (tables.length < 1)
      throw new Error("A FilterData must have at least one table!");

    this._tables = tables;
    this._attributeName = attributeName;
    this._filterText = filterText;
    this._viewType = viewType;
  }
}

export class HierarchicalFilterData extends FilterData {
  constructor(attributeName: string, filterText: string, readonly hierarchy: HierarchicalElementGraph, viewType: ViewType, ...tables: TableId[]) {
    super(attributeName, filterText, viewType, ...tables);

    if (viewType === ViewType.StructuredTable) {
      const allTables: TableId[] = hierarchy.allTables;

      if (this.tables.length !== allTables.length)
        throw new Error("In a structured table one has to pass all tables for each attribute filter text!");

      for (const table of allTables)
        if (!this.tables.includes(table))
          throw new Error("In a structured table one has to pass all tables for each attribute filter text!");
    } else if (viewType === ViewType.ValueChart) {
      if (hierarchy !== null) {
        console.debug("FilterTextProcessor.constructor(...) - for value charts the hierarchy should be null for performance reasons!");
      }
    } else {
      if (!hierarchy.allTables.includes(this.tables[0])) {
        throw new Error("The given table is not within the given hierarchy!");
      }
    }

  }
}


export abstract class FilteringStep {
  protected _filterMatcher: IFilterMatcher;
  protected _nodeCollectorMatcher: HierarchicalNodeCollectorMatcher;
  private _filterText: string;

  public getFilterMatcher(hierarchy: HierarchicalElementGraph): IFilterMatcher {
    if (this.stepNum === 1 && this.getFilterString(hierarchy).trim() === "")
      return allPseudoMatcher;

    return this._filterMatcher;
  }

  public get nodeCollectorMatcher(): HierarchicalNodeCollectorMatcher {
    return this._nodeCollectorMatcher;
  }

  public setFilterText(filterText: string): void {
    this._filterText = filterText;
  }

  public getFilterString(hierarchy: HierarchicalElementGraph): string {
    if (this._filterText === null || this._filterText === undefined)
      throw new Error("The whole attribute filter text has to be set before calling this method!");

    if (this._nodeCollectorMatcher)
      return this._nodeCollectorMatcher.restString(this._filterText, hierarchy);

    return this._filterText;
  }

  public abstract get stepNum(): number;
}

export class FilterTextCheckerResult {
  constructor(readonly tables: TableId[], readonly attributeName: string, readonly filterText: string, readonly isValid: boolean) {
  }

  public stepsPerTable: Map<TableId, Map<number, FilteringStep>>;

  public setStep(tableId: TableId, filteringStep: FilteringStep): void {
    if (!this.tables || !this.tables.includes(tableId))
      throw new Error("You are adding a filter step for a table, which is not set in this FilterTextCheckerResult!");

    filteringStep.setFilterText(this.filterText);

    if (!this.stepsPerTable)
      this.stepsPerTable = new Map<TableId, Map<number, FilteringStep>>();

    let stepsPerTableMap: Map<number, FilteringStep>;
    if (this.stepsPerTable.has(tableId)) {
      stepsPerTableMap = this.stepsPerTable.get(tableId);
    } else {
      stepsPerTableMap = new Map<number, FilteringStep>();
      this.stepsPerTable.set(tableId, stepsPerTableMap);
    }

    stepsPerTableMap.set(filteringStep.stepNum, filteringStep);
  }

  public getAttributeFilterDataForTable(tableId: TableId, stepNum: number, hierarchy: HierarchicalElementGraph): AttributeFilterData {
    if (!this.stepsPerTable || !this.stepsPerTable.has(tableId) || !this.stepsPerTable.get(tableId).has(stepNum))
      return null;

    const fileringStep: FilteringStep = this.stepsPerTable.get(tableId).get(stepNum);

    const attributeFilterData: AttributeFilterData = new AttributeFilterData();
    attributeFilterData.attributeName = this.attributeName;
    attributeFilterData.filterText = fileringStep.getFilterString(hierarchy);
    attributeFilterData.matcher = fileringStep.getFilterMatcher(hierarchy);

    return attributeFilterData;
  }

  public getNodeCollectorMatcherForTable(tableId: TableId, stepNum: number): HierarchicalNodeCollectorMatcher {
    if (!this.stepsPerTable || !this.stepsPerTable.has(tableId) || !this.stepsPerTable.get(tableId).has(stepNum))
      return null;

    return this.stepsPerTable.get(tableId).get(stepNum).nodeCollectorMatcher;
  }
}

export class FilteringStep1 extends FilteringStep {

  constructor(filterMatcher: IFilterMatcher, nodeCollectorMatcher: HierarchicalNodeCollectorMatcher) {
    super();

    if (!filterMatcher || !nodeCollectorMatcher) {
      throw new Error("Step 1 must provide a filter and a collector!");
    }

    this._filterMatcher = filterMatcher;
    this._nodeCollectorMatcher = nodeCollectorMatcher;
  }

  public get stepNum(): number {
    return 1;
  }

}

export class FilteringStep2 extends FilteringStep {

  constructor(filterMatcher: IFilterMatcher) {
    super();

    if (!filterMatcher) {
      throw new Error("Step 2 must provide a filter!");
    }

    this._filterMatcher = filterMatcher;
    this._nodeCollectorMatcher = null;
  }

  public get stepNum(): number {
    return 2;
  }

}

export function processFilterText(filterData: HierarchicalFilterData): FilterTextCheckerResult {
  if (filterData.filterText.trim() === "") {
    return getResultForEmptyFilterText(filterData);
  } else if (isHierarchicalFilterText(filterData.filterText)) {
    return getResultForNodeCollectorFilterText(filterData);
  } else {
    return getResultForStandardFilterText(filterData);
  }
}

function getResultForEmptyFilterText(filterData: HierarchicalFilterData): FilterTextCheckerResult {
  return getValidFilterTextCheckerResult(filterData);
}

function getResultForNodeCollectorFilterText(filterData: HierarchicalFilterData): FilterTextCheckerResult {
  if (!isValidNodeCollectorText(filterData.filterText, filterData.hierarchy)) {
    return getInvalidFilterTextCheckerResult(filterData);
  }

  const nodeCollectorMatcher: HierarchicalNodeCollectorMatcher = getNodeCollectorMatcher(filterData.filterText, filterData.hierarchy);
  if (!nodeCollectorMatcher.isAllowedFor(filterData.viewType)) {
    return getInvalidFilterTextCheckerResult(filterData);
  }

  const filterTextFilterPart: string = nodeCollectorMatcher.restString(filterData.filterText, filterData.hierarchy);

  const level: number = nodeCollectorMatcher === allLowerLevelNodesAndAllConnectedChildrenMatcher ? allLowerLevelNodesAndAllConnectedChildrenMatcher.getLevel(filterData.filterText) : filterData.hierarchy.getLevelForTable(filterData.tables[0]);
  const table: TableId = filterData.hierarchy.getTableForLevel(level);

  const filterMatcher: IFilterMatcher = findFilterMatcher(table, filterTextFilterPart, filterData);
  if (!filterMatcher) {
    return getInvalidFilterTextCheckerResult(filterData);
  }

  const filteringStep1: FilteringStep1 = new FilteringStep1(filterMatcher, nodeCollectorMatcher);
  const validFilterTextCheckerResult: FilterTextCheckerResult = getValidFilterTextCheckerResult(filterData);
  validFilterTextCheckerResult.setStep(table, filteringStep1);

  return validFilterTextCheckerResult;
}

function getResultForStandardFilterText(filterData: HierarchicalFilterData): FilterTextCheckerResult {
  const validFilterTextCheckerResult: FilterTextCheckerResult = getValidFilterTextCheckerResult(filterData);

  for (const table of filterData.tables) {
    let filterMatcher: IFilterMatcher = findFilterMatcher(table, filterData.filterText, filterData);

    if (!filterMatcher && filterData.viewType !== ViewType.StructuredTable) {
      return getInvalidFilterTextCheckerResult(filterData);
    } else if (!filterMatcher) {
      /*
       * Structured Table case:
       *  - Is handled in another way
       *  - An incorrect filter for charts is incorrect
       *  - For structured tables not, because, multiple table can have attributes with the same name but different types
       *  - Is this handling in another way correct or should a chart also get a nonPseudoMatcher?
       */

      filterMatcher = nonePseudoMatcher;
    }

    const filteringStep2: FilteringStep2 = new FilteringStep2(filterMatcher);
    validFilterTextCheckerResult.setStep(table, filteringStep2);
  }

  return validFilterTextCheckerResult;
}

function findFilterMatcher(table: TableId, filterTextFilterPart: string, filterData: HierarchicalFilterData): IFilterMatcher {
  if (filterTextFilterPart.trim() === "")
    return allPseudoMatcher;

  const tableHasAttribute: boolean = modelStore.tableHasAttributeDefinition(table, filterData.attributeName);
  if (!tableHasAttribute)
    return null;

  const filterMatcher: IFilterMatcher = getFilterMatcher(filterTextFilterPart, table, filterData.attributeName);

  return filterMatcher;
}

function getInvalidFilterTextCheckerResult(filterData: HierarchicalFilterData): FilterTextCheckerResult {
  return new FilterTextCheckerResult(filterData.tables, filterData.attributeName, filterData.filterText, false);
}

function getValidFilterTextCheckerResult(filterData: HierarchicalFilterData): FilterTextCheckerResult {
  return new FilterTextCheckerResult(filterData.tables, filterData.attributeName, filterData.filterText, true);
}
