/* ProductstructureIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import {withStyles} from "@material-ui/core";
import * as React from "react";

class ProductstructureIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
      .st0structure{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;fill-opacity:0.5;}
      .st1structure{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
     `}</style>
      <g>
        <path id="Combined-Shape_6_" className="st0structure" d="M32,24.24v5.53C32,31,33,32,34.24,32h11.53C47,32,48,31,48,29.76V28h2
		c0.55,0,1,0.45,1,1V53c0,0.55-0.45,1-1,1h-2v-1.76C48,51,47,50,45.76,50H34.24C33,50,32,51,32,52.24v5.53C32,59,33,60,34.24,60
		h11.53C47,60,48,59,48,57.76V56h2c1.66,0,3-1.35,3-3V29c0-1.66-1.35-3-3-3h-2v-1.76C48,23,47,22,45.76,22H34.24
		C33,22,32,23,32,24.24z M46,57.76c0,0.13-0.11,0.24-0.24,0.24H34.24C34.1,58,34,57.89,34,57.76v-5.53c0-0.13,0.1-0.24,0.24-0.24
		h11.53c0.13,0,0.24,0.11,0.24,0.24V57.76z"/>
        <path className="st1structure" d="M8,45.24v5.53C8,52,9,53,10.24,53h11.53C23,53,24,52,24,50.76V49h2c0.55,0,1,0.45,1,1V60c0,0.55-0.45,1-1,1h-2
		v-1.76C24,58,23,57,21.76,57H10.24C9,57,8,58,8,59.24v5.53C8,66,9,67,10.24,67h11.53C23,67,24,66,24,64.76V63h2c1.66,0,3-1.35,3-3
		V50c0-1.66-1.35-3-3-3h-2v-1.76C24,44,23,43,21.76,43H10.24C9,43,8,44,8,45.24z M10,45.24c0-0.13,0.11-0.24,0.24-0.24h11.53
		C21.9,45,22,45.11,22,45.24v5.53c0,0.13-0.1,0.24-0.24,0.24H10.24c-0.13,0-0.24-0.1-0.24-0.24V45.24z M22,64.76
		c0,0.13-0.1,0.24-0.24,0.24H10.24c-0.13,0-0.24-0.1-0.24-0.24v-5.53c0-0.13,0.11-0.24,0.24-0.24h11.53C21.9,59,22,59.11,22,59.24
		V64.76z"/>
        <path id="Path_1_" className="st0structure" d="M21.76,25H10.24C9,25,8,24,8,22.76v-5.53C8,16,9,15,10.24,15h11.53C23,15,24,16,24,17.24V19h2
		c1.66,0,3,1.35,3,3V32c0,1.66-1.35,3-3,3h-2v1.76C24,38,23,39,21.76,39H10.24C9,39,8,38,8,36.76v-5.53C8,30,9,29,10.24,29h11.53
		C23,29,24,30,24,31.24V33h2c0.55,0,1-0.45,1-1V22c0-0.55-0.45-1-1-1h-2v1.76C24,24,23,25,21.76,25z"/>
        <path className="st0structure" d="M56,38.24v5.53C56,45,57,46,58.24,46h11.53C71,46,72,45,72,43.76v-5.53C72,37,71,36,69.76,36H58.24
		C57,36,56,37,56,38.24z"/>
      </g>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(ProductstructureIcon);
