import * as React from "react";

import {Classifier} from "../../../common/utils/ClassifierLogger";
import Log from "../../../common/utils/Logger";
import {BaseSectionProperties} from "../../../properties/model/PropertiesModel";
import {createStyles, FormControlLabel, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import PropertiesSectionLayout from "../../../properties/components/PropertiesSectionLayout";
import autobind from "autobind-decorator";
import {modelStore} from "../../../core/stores/ModelStore";
import {MatrixCellConfigurationPayload} from "../../models/MatrixModel";
import {AttributeDefinition} from "../../../api/api";
import {MetusCheckbox} from "../../../common/components/MetusCheckboxComponent";
import {changeCellConfiguration} from "../../actions/MatrixAsyncActionCreators";
import {observer} from "mobx-react";

const renderLog = Log.logger("MatrixCellPropertiesSection", Classifier.render);
const log = Log.logger("properties");

const styles = (theme: Theme): StyleRules => createStyles({
  formControl: {
    minWidth: 200
  }
});

export interface MatrixCellConfigurationProperties extends BaseSectionProperties, MatrixCellConfigurationPayload {
}

type StyledLocalProps = MatrixCellConfigurationProperties & WithStyles<typeof styles>;

@observer
class MatrixCellConfigurationPropertiesComponent extends React.Component<StyledLocalProps> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering MatrixCellConfigurationPropertiesComponent");
    return <PropertiesSectionLayout>
      <FormControlLabel label="Show Connection Count"
                        checked={this.props.showCount}
                        onChange={this.onShowCountSelected}
                        control={<MetusCheckbox/>}/>
    </PropertiesSectionLayout>;
  }

  @autobind
  private onShowCountSelected(event: any): void {
    log.debug("show count selected" + event.target.checked);
    this.dispatchChange({showCount: event.target.checked});
  }

  private dispatchChange(delta: MatrixCellConfigurationPayload): void {
    // remap null value to undefined
    const newState: MatrixCellConfigurationPayload = {
      joinTableId: this.props.joinTableId,
      joinTableAttributeName: this.props.joinTableAttributeName,
      showCount: this.props.showCount
    };
    Object.assign(newState, delta);
    changeCellConfiguration(this.props.activeViewId, newState.joinTableId.tableId, newState.joinTableAttributeName, newState.showCount);
  }
}

export default withStyles(styles)(MatrixCellConfigurationPropertiesComponent);