import {ActionBase, LoadAction} from "../../common/actions/BaseAction";
import {DiagramData, Table, UUID} from "../../api/api";
import {
  Attribute,
  AttributeId,
  ElementId,
  TableId,
  ViewId,
  VisualAttributeId,
  VisualTableId
} from "../../core/utils/Core";
import {ServerWritingAction} from "../../core/actions/CoreActions";
import {PersistencyState, Target} from "../../common/constants/Enums";
import {generateUUID} from "../../common/utils/IdGenerator";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {
  deleteElementsAndDispatch,
  getAttributeValuesForTable,
  getConnectionsForTables,
  updateAttributeValuesForElementAndTable
} from "../../core/actions/CoreAsyncActionCreators";
import {AddAttributeToViewAction, AddTableToViewAction} from "../../core/actions/CoreAsyncActions";
import {viewManagerRegistry} from "../models/ViewManager";
import {ObjectIdAndTitle} from "../../common/components/ListSelectionDialog";
import {newElement, toggleConnections} from "../../core/services/CoreDataServices";

export class LoadWebViewAction extends LoadAction<DiagramData> {
  type: "webview" = "webview";

  constructor(viewId: ViewId, diagramData: DiagramData) {
    super(diagramData, viewId);
  }
}

export class SaveViewAction extends ActionBase<PersistencyState> implements ServerWritingAction {
  type: "saveView" = "saveView";

  constructor(public commandId: string, persistencyState: PersistencyState, resourceId: string, groupId: UUID = undefined) {
    super(persistencyState, resourceId, groupId, true, true, false);
  }
}

export interface AddAttributeToViewPayload {
  visualAttributeId: VisualAttributeId;
  // svg coordinates where the drop occured */
  requestedCoords: Coords;
  /** true, if triggered by table drop, different behavior in chart versus value chart needed here */
  initial: boolean;
  /** Attribute will be added after all exisiting attributes under the table, x coord will be ignored */
  addToRight: boolean;
  /**
   * true if attribute should be added to matrix columns, false: add to default hierarchy
   */
  isColumn: boolean;
}

export interface Coords {
  x: number;
  y: number;
}

/**
 * add a table to the diagram at the given position
 * @param viewId viewId to add to
 * @param tableId table to add
 * @param insertPosition coordinates for freely placeable artefacts or array index for auto-layouted hierarchies, undefined if no insertPosition is known
 * @param target targeting row or column hierarchy ?, default row hierarchy. column hierarchy only available for matrix
 */
export function addTableToDiagram(viewId: ViewId, tableId: TableId, insertPosition: Coords | number, target: Target = Target.ROW): Promise<any> {
  const visualTableId = new VisualTableId(tableId);
  const groupId: UUID = generateUUID();
  const requestedCoords: Coords = typeof insertPosition === "number" ? undefined : insertPosition;
  const promises: Promise<any>[] = [];

  Dispatcher.dispatch(new AddTableToViewAction(viewId, visualTableId, insertPosition, groupId, target));

  return addAttributeToView(viewId, new VisualAttributeId(visualTableId, "name"), requestedCoords, true, false, groupId)
      .then(() => {
        return getConnectionsForTables(viewId, [tableId], groupId);
      });
}

export interface AddTableToViewPayload {
  visualTableId: VisualTableId;
  insertPosition: Coords | number;
  target: Target;
}

/**
 * add an attribute to a view, loading core information from server if needed.
 * For matrix views addAttributeToMatrix is used, because one attribute is added for every level there
 * @param viewId view to add to
 * @param visualAttributeId visual attribute id of the attribute to add
 * @param requestedCoords coordinates where attribute should be inserted
 * @param initial true if initial add done during table add, false if done by user
 * @param addToRight if true, attribute should be appendd to the end of attributes list
 * @param groupId
 */
export function addAttributeToView(viewId: ViewId, visualAttributeId: VisualAttributeId, requestedCoords: { x: number; y: number }, initial: boolean = false, addToRight: boolean = false, groupId: UUID = undefined): Promise<Table[]> {
  if (groupId === undefined) {
    groupId = generateUUID();
  }

  const tableId: TableId = visualAttributeId.visualTableId.tableId;
  const attributeName: string = visualAttributeId.attributeName;

  const viewInfo = viewManagerRegistry.viewManager.getViewInfoById(viewId);
  Dispatcher.dispatch(new AddAttributeToViewAction(viewId, visualAttributeId, requestedCoords, initial, addToRight, false, groupId));
  return getAttributeValuesForTable(viewId, tableId, [attributeName], groupId);
}

export async function updateElements(toCreate: ObjectIdAndTitle[], toDelete: string[], toToggle: ElementId[], rowElementId: ElementId, attributeId: AttributeId, columnElementId?: ElementId): Promise<void> {
  const tableId = attributeId.tableId;
  for (const elementToCreate of toCreate) {
    await newElement(tableId, 0, 0, elementToCreate.id);
    const attribute: Attribute = {name: attributeId.attributeName, value: elementToCreate.title};
    await updateAttributeValuesForElementAndTable([attribute], elementToCreate.id, tableId);
    if (columnElementId) {
      await toggleConnections([elementToCreate.id], columnElementId);
    }
  }
  if (toToggle.length > 0) {
    await toggleConnections(toToggle, rowElementId);
  }
  if (toDelete.length > 0) {
    await deleteElementsAndDispatch(toDelete);
  }
  return Promise.resolve();
}
