// lib imports
import * as React from "react";
import * as ReactDOM from "react-dom";
import {Configuration} from "./common/utils/Configuration";
import "es6-shim";
import {detect} from "detect-browser";
import {Route, Router, Switch} from "react-router";
// to migrate from react-router v3 to v4 this eases onEnter migration
import myHistory from "./workbench/utils/MyHistory";
import Log from "./common/utils/Logger";
import MetusDndBackend from "./common/utils/MetusDndBackend";
import {ModelLocationType, ViewType} from "./common/constants/Enums";
import Root from "./workbench/components/Root";
import {loadInitialModelData, loadInitialServerData} from "./modelselection/actions/ModelAsyncActionCreators";
import {loadViewsForCockpit, openCockpit,} from "./workbench/actions/ViewManagerAsyncActionCreators";
import AppContainer from "./workbench/components/AppContainer";
import {AppState, getStateFromLocalStorage, resetLocalStorage} from "./workbench/utils/MetusLocalStorage";
import {RouteWithEnter} from "./workbench/utils/RouteWithEnter";
import {configure} from "mobx";
import * as queryString from "querystring";
import {metusStore} from "./workbench/stores/MetusStore";
import {findTreeItem} from "./core/models/ListItemHierarchy";
import {CockpitData, OpenView} from "./api/api";
import {generateUUID} from "./common/utils/IdGenerator";
import {createWebSocketConnection} from "./WebSocketClient";
import {patchPassiveEventListeners} from "./reactPassiveEventListenerPatch";

import {LicenseManager} from "ag-grid-enterprise";
// needed by MaterialUI
import injectTapEventPlugin from "react-tap-event-plugin";
import {DndProvider} from "react-dnd";
import {registerLocales} from "./common/utils/FormatUtil";
import {ModelInfo} from "./core/models/ModelInfo";

// set ag-grid enterprise license
LicenseManager.setLicenseKey("Metus_GmbH__Metus_online_2Devs19_August_2020__MTU5Nzc5MTYwMDAwMA==58d951b8c8c7d5d4295c5c35b585f4c5");

try {
  // to avoid error on hot reload
  injectTapEventPlugin();
} catch (e) {

}

const log = Log.logger("Main");

/*
 * Bootstrap the app
 */

let __WARNING_DISPLAYED__: boolean = false;

patchPassiveEventListeners();

enum Compatibility { supported, unsupported, untested }

// Browser Switch
export function determineCompatibility(name: string, version: string): Compatibility {
  let result: Compatibility = Compatibility.unsupported;
  switch (name) {
    case "chrome":
      if (compareVersion(version, 56) >= 0) {
        result = Compatibility.supported;
      } else if (compareVersion(version, 50) >= 0) {
        result = Compatibility.untested;
      }
      break;
    case "ie":
      if (compareVersion(version, 11) >= 0) {
        result = Compatibility.untested;
      }
      break;
    case "firefox":
      if (compareVersion(version, 50) >= 0) {
        result = Compatibility.untested;
      }
      break;
    case "edge":
      result = Compatibility.untested;
      break;
    case "edge-chromium":
      result = Compatibility.supported;
      break;
    default:
      result = Compatibility.untested;
      break;
  }
  return result;
}

/**
 * Note: this compares only the primary version of the browser
 * (until first indexOf delimiter, e.g. for 100.0.6830 it compares 100 with supportedVersion)
 * @param browserVersion
 * @param supportedVersion
 * @param delimiter
 * @return 1 if browserVersion > supported, 0 if br === supp, -1 if br < supp
 */
export function compareVersion(browserVersion: string, supportedVersion: number, delimiter: string = "."): number {
  if (isNaN(Number(browserVersion))) {
    const primaryBrowserVersion = Number(browserVersion.substr(0, browserVersion.indexOf(delimiter)))
    if (isNaN(primaryBrowserVersion)) {
      return -1; // TODO: what to do with NaN browserversion?
    } else {
      return Math.sign(Number(primaryBrowserVersion) - supportedVersion);
    }
  } else {
    return Math.sign(Number(browserVersion) - supportedVersion);
  }
}

// use mobx observed mode, mutations of observed values only allowed inside actions
configure({enforceActions: "observed"});

const queryParams = function (location: Location): any {
  let result = {};
  const a = location.search.split("?");
  if (a.length === 2) {
    result = queryString.parse(a[1]);
  }
  return result;
};

function main(): void {
  log.info("METUS Online running in mode " + (Configuration.isDevelopment() ? "development" : "production"));
  log.debug("NODE_ENV: " + process.env.NODE_ENV);
  const {name, version} = detect();
  log.debug("Browser detected: " + name + " V" + version);
  const compat: Compatibility = determineCompatibility(name, version);
  let startmetus = false;
  if (compat === Compatibility.supported) {
    startmetus = true;
  } else if (compat === Compatibility.untested) {
    if (!__WARNING_DISPLAYED__) {
      startmetus = window.confirm("Your Browser offers not all the features required for Metus Online. You might experience that functions are not working or performance is slow. Only Chrome >= V56 guarantees full and correct functionality. Do you want to continue anyway?");
      __WARNING_DISPLAYED__ = true;
    } else {
      startmetus = true;
    }
  } else {
    window.alert("Your Browser is not powerful enough to run Metus Online, please use the latest Chrome Version (V57 or newer)");
    __WARNING_DISPLAYED__ = true;
    startmetus = false;
  }

  if (startmetus) {
    // TODO: Use localized delimiter
    registerLocales(".", ",");

    // React Router needs one component, thus create wrapper components with Dnd Context
    const DndAppContainer = (props: {}) => (<DndProvider backend={MetusDndBackend}><AppContainer/></DndProvider>);

    const router = <Router history={myHistory}>
      <Root>
        <Switch>
          <Route path="/" exact component={DndAppContainer}/>
          <RouteWithEnter path="/project/:modelLocation/:modelName/:version/app"
                          component={DndAppContainer}
                          onEnter={(computedMatch: any, location): void => {
                            const modelInfo = createModelInfo(computedMatch);
                            log.debug("selecting model", modelInfo.name, version);
                            log.debug("Loading Server Configuration and Model List", modelInfo.location);
                            loadInitialServerData(modelInfo.location).then(() => {
                              loadInitialModelData(modelInfo).then(() => {
                                createWebSocketConnection(modelInfo.name);

                                const isRestoreView = queryParams(location).restoreView !== undefined;
                                if (isRestoreView) {
                                  const oldState: AppState = getStateFromLocalStorage();
                                  if (oldState) {
                                    const isReloadSameModel = oldState.modelLocation === modelInfo.location && oldState.modelName === modelInfo.name;
                                    if (isReloadSameModel) {
                                      restoreViews(oldState);
                                    }
                                  }
                                } else {
                                  resetLocalStorage();
                                }
                              })
                            });
                          }}
          />
          <RouteWithEnter path="/project/:modelLocation/:modelName/view/:viewType/:viewId"
                          component={DndAppContainer}
                          onEnter={(computedMatch: any): void => {
                            const modelInfo = createModelInfo(computedMatch);
                            log.debug("selecting model", modelInfo.name, version);

                            loadInitialModelData(modelInfo).then(() => {
                              createWebSocketConnection(modelInfo.name);

                              const viewId = computedMatch.params["viewId"];
                              const viewType: ViewType = ViewType[computedMatch.params["viewType"] as string];

                              if (viewType !== undefined) {
                                if (viewType === ViewType.Cockpit) {
                                  const cockpitItem = findTreeItem(metusStore.cockpitAndViewHierarchy, viewId);
                                  cockpitItem && openCockpit(viewId, cockpitItem.svgIconColor, cockpitItem.svgIconType);
                                } else {
                                  const openViews: OpenView[] = [{id: viewId, type: viewType, windowPath: undefined}];
                                  const dummyCockpit: CockpitData = {
                                    openViews,
                                    windowArrangement: 1
                                  };
                                  loadViewsForCockpit(dummyCockpit, generateUUID());
                                }
                              } else {
                                throw new Error("Invalid view type.");
                              }
                            });
                          }}
          />
        </Switch>
      </Root>
    </Router>;

    // TODO: enable concurrent mode on whole tree when all libs are strict-mode-compliant
    //  const rootElement = Configuration.USE_CONCURRENT_REACT ? <ConcurrentMode>{router}</ConcurrentMode> : router;
    ReactDOM.render(<DndProvider
        backend={MetusDndBackend}>{router}</DndProvider>, document.getElementById("app-container"));
  } else {
    document.getElementById("app-container").innerHTML = "<p>Browser " + name + " in version " + version + " not supported</p>";
  }
}


function createModelInfo(computedMatch: any): ModelInfo {
  const modelLocationString: keyof typeof ModelLocationType = computedMatch.params["modelLocation"];
  const modelLocationType: ModelLocationType = ModelLocationType[modelLocationString];
  const modelName = computedMatch.params["modelName"];
  const version = computedMatch.params["version"];
  return new ModelInfo(modelLocationType, modelName, version);
}

function restoreViews(oldState: AppState) {
  const cockpitId = oldState.openCockpit;
  if (cockpitId) {
    log.debug("Restoring cockpit", cockpitId);
    const cockpitItem = findTreeItem(metusStore.cockpitAndViewHierarchy, cockpitId);
    cockpitItem && openCockpit(cockpitId, cockpitItem.svgIconColor, cockpitItem.svgIconType);
  } else {
    log.debug("Restoring views", oldState.openViews);

    // only restore if all views or tables still exist
    const missingView = oldState.openViews.find(
        view => findTreeItem(metusStore.cockpitAndViewHierarchy, view.id) === null && (view.type !== ViewType.Table || metusStore.getViewInfoById(view.id) === undefined)
    );

    if (missingView === undefined) {
      // create a dummy cockpit
      const dummyCockpit: CockpitData = {
        openViews: oldState.openViews,
        windowArrangement: oldState.windowArrangement
      };
      loadViewsForCockpit(dummyCockpit, generateUUID());
    }
  }
}


main();
