/**
 * Created by vanmeegen on 09.05.2017
 */
import * as React from "react";
import Log from "../../../common/utils/Logger";
import {ViewId, VisualTableId, VisualTableIdString} from "../../../core/utils/Core";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import * as ReactDnD from "react-dnd";
import {DragSource, DragSourceConnector, DragSourceMonitor, DragSourceSpec} from "react-dnd";
import DragTypes from "../../../common/constants/DragTypes";
import DragType from "../../../common/constants/DragTypes";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {DndSourceFeedbackAction} from "../../../common/actions/InteractionStateActions";
import {FeedbackHelper} from "../../../common/utils/DragDropHelper";
import {
  TableColumnHeaderComponentDndProps,
  TableColumnHeaderComponentLocalAndDndProps,
  TableColumnHeaderComponentNoDnd,
  TableColumnHeaderComponentProps
} from "../../../commonviews/components/TableColumnHeaderComponent";
import {VisualTableMovingItem} from "../../../common/utils/CoreDropTypes";
import {Coords} from "../../../commonviews/actions/SharedViewAsyncActions";
import {reorderTablesInView} from "../../../commonviews/actions/SharedViewActionCreators";

const dndLog = Log.logger("ChartColumnHeaderComponent", Classifier.dnd);


function collect(props: TableColumnHeaderComponentProps): { id: VisualTableIdString } {
  return {id: props["data-id"]};
}

const connectDnd = (connect: DragSourceConnector, monitor: DragSourceMonitor): TableColumnHeaderComponentDndProps => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
});

const dragTableColumnHeaderSourceSpec: DragSourceSpec<TableColumnHeaderComponentProps, VisualTableMovingItem> = {
  beginDrag(props: TableColumnHeaderComponentProps, monitor: DragSourceMonitor, component: TableColumnHeaderComponentNoDnd): VisualTableMovingItem {
    Dispatcher.dispatch(new DndSourceFeedbackAction(FeedbackHelper.createSourceFeedbackBox(component)));
    return {
      viewId: props.viewContext.viewId,
      visualId: props.id.visualId,
      tableId: props.id.tableId,
      newIndexPosition: -1,
      type: DragType.VISUAL_TABLE_COLUMN_MOVE
    };
  },

  //noinspection JSUnusedLocalSymbols
  endDrag(props: TableColumnHeaderComponentProps, monitor: ReactDnD.DragSourceMonitor, component: TableColumnHeaderComponentNoDnd): void {
    dndLog.debug("ChartColumnHeaderComponent end drag");

    if (monitor.didDrop()) {
      const dragItem: VisualTableMovingItem = monitor.getItem() as VisualTableMovingItem;
      const {viewId, targetPosition, tableIndex} = monitor.getDropResult() as { viewId: ViewId, targetPosition: { x: number, y: number }, tableIndex: number };
      const insertPosition: Coords | number = targetPosition ? targetPosition : tableIndex;

      if (viewId === dragItem.viewId) {
        // if in same view, reorder tables
        reorderTablesInView(viewId, new VisualTableId(dragItem.tableId, dragItem.visualId), props.id, dragItem.newIndexPosition);
      }
    }
  }

};

/**
 * TableColumnHeaderComponent is used with different Dnd HOCs, this HOC can move the column in a chart
 */
export const ChartColumnHeaderComponent = DragSource<TableColumnHeaderComponentLocalAndDndProps>(DragTypes.VISUAL_TABLE_COLUMN_MOVE, dragTableColumnHeaderSourceSpec, connectDnd)(TableColumnHeaderComponentNoDnd);

