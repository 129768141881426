import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";



class NewFolderIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M21,10 L27,16 L48,16 L48,40 C48,42.209 46.209,44 44,44 L44,44 L12,44 C9.791,44 8,42.209 8,40 L8,40 L8,14 C8,11.791 9.791,10 12,10 L12,10 L21,10 Z M46,18 L10,18 L10,40 C10,41.103 10.897,42 12,42 L12,42 L44,42 C45.103,42 46,41.103 46,40 L46,40 L46,18 Z M34,22 L34,29 L41,29 L41,31 L34,31 L34,38 L32,38 L32,31 L25,31 L25,29 L32,29 L32,22 L34,22 Z"/>;
  }

  viewBox(): string {
    return "0 0 56 56";
  }
}

export default withStyles(svgIconStyles)(NewFolderIcon);