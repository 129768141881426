import {Validate} from "../../common/utils/Validate";
import * as React from "react";
import TinyColor from "tinycolor2";


// classic level colors
// const levelColors: string[] = ["#ffffff", "#edf2fb", "#dfe5ef", "#c7d1e2", "#9db1cf", "#899eb6"]

// original Imago Colors const levelColors: string[] = ["#F1F1F1", "#D1D1D1", "#B1B1B1", "#919191", "#717171", "#595959"];
// original Imago Colors const levelTextColors: string[] = ["#000000", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF"];
const levelColors: string[] = ["#E8EDF4", "#D8E0EC", "#C3CFE1", "#ABBCD5", "#9DB1CF", "#869FC4", "#7692BC"];
const levelTextColors: string[] = ["#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000"];

export function getLevelDefaultColor(levelIndex: number, levelCount: number, cycle: boolean = false): string {
  // colors to define for levels, top level first
  const index: number = getColorIndex(levelIndex, levelCount, levelColors.length, cycle);
  return levelColors[index];
}

export function getLevelDefaultTextColor(levelIndex: number, levelCount: number, cycle: boolean = false): string {
  // colors to define for level text, top level first
  const index: number = getColorIndex(levelIndex, levelCount, levelTextColors.length, cycle);
  return levelTextColors[index];
}

export function getNumberOfAvailableColors() {
  return levelColors.length;
}

/**
 * determine the color to use for a level
 *
 * @param level level to get color for
 * @param levelCount nr of levels displayed in ST
 * @param availableColors nr of colors available, the highest is used for level 0
 * @param cycle if true, colors are cycled for a level higher than available colors; otherwise the same color is used
 *          for these levels
 * @return index of color to use; 0<=index<availableColors, if cycle is false: 0 is the color index for lower levels,
 *         availableColors -1 for highest level if needed
 */
export function getColorIndex(level: number, levelCount: number, availableColors: number, cycle: boolean): number {
  Validate.isTrue(level >= 0 && level < levelCount);
  Validate.isTrue(availableColors > 0);

  let index = Math.min(levelCount, availableColors) - 1 - level;
  if (index < 0) {
    index = cycle ? index + availableColors : 0;
  } else if (index >= availableColors) {
    index = cycle ? index % availableColors : availableColors - 1;
  }
  return index;
}

/**
 * modify the style's fill color and opacity that it looks highlighted when mouse hovers over it
 * @param {React.CSSProperties} rectStyles
 * @returns {React.CSSProperties}
 */
export function highlight(rectStyles: React.CSSProperties): React.CSSProperties {
  const result: React.CSSProperties = {...rectStyles};
  const color = new TinyColor(rectStyles.fill);
  // highlight or lolight
  if (color.isDark()) {
    result.fill = color.lighten(20).toRgbString();
  } else {
    result.fill = color.darken(20).toRgbString();
  }
  return result;
}
