/*  Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by MeegenM, Juli 2017
 */
import {VisualTableId, VisualTableIdString} from "../../../core/utils/Core";
import {object, serializable} from "serializr";
import {LayoutAlgorithm} from "../../../api/api";

/**
 * properties autolayout can take, serialized in the diagram
 */
export class AutoLayoutOptions {
  constructor(algorithm: LayoutAlgorithm, preserveOrdering: boolean, autolayout: boolean, selectedTable?: VisualTableId) {
    this.algorithm = algorithm;
    this.selectedTable = selectedTable;
    this.preserveOrdering = preserveOrdering;
    this.autolayout = autolayout;
  }

  /** layout algorithm/library to use */
  @serializable algorithm: LayoutAlgorithm;
  @serializable(object(VisualTableId)) selectedTable: VisualTableId;
  @serializable preserveOrdering: boolean;
  /** if true, chart will automatically layout with these options every time it changes */
  @serializable autolayout: boolean;
}

/**
 *  autolayout options extended with the information needed by autolayout properties component
 */
export class AutoLayoutOptionProperties extends AutoLayoutOptions {
  leftTables: VisualTableIdString[];
  rightTables: VisualTableIdString[];
  tableNames: { id: string, name: string }[];
  allSortedTables: VisualTableIdString[];

}

export function splitTablesIntoSubtrees(tableId: VisualTableIdString, allSortedTables: VisualTableIdString[]): { leftTables: VisualTableIdString[], rightTables: VisualTableIdString[] } {
  const index: number = allSortedTables.indexOf(tableId) + 1;
  return {leftTables: allSortedTables.slice(0, index), rightTables: allSortedTables.slice(index)};
}