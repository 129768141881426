/**
 * polymorphic indicates that this property contains an object of polymorphic type.
 * Accepts a model schema dispatcher which selects the model schema based on the concrete type of the polymorphic attribute.

 * @param propSchemaResolver selects the schema for a given object or json, both must be possible
 * @param {AdditionalPropArgs} additionalArgs optional object that contains beforeDeserialize and/or afterDeserialize handlers
 * @returns {PropSchema}
 */
import {custom, ModelSchema, object, PropSchema} from "serializr";
import Context from "serializr/lib/core/Context";

var formatters = {
  j: function json(v) {
    try {
      return JSON.stringify(v)
    } catch (error) {
      return "[UnexpectedJSONParseError]: " + error.message
    }
  }
};

// copied from serializr.js invariant
export function invariant(condition, message) {
  if (!condition) {
    var variables = Array.prototype.slice.call(arguments, 2);
    var variablesToLog = [];

    var index = 0;
    var formattedMessage = message.replace(/%([a-zA-Z%])/g, function messageFormatter(match, format) {
      if (match === "%%") return match

      var formatter = formatters[format];

      if (typeof formatter === "function") {
        var variable = variables[index++];

        variablesToLog.push(variable);

        return formatter(variable)
      }

      return match
    });

    if (console && variablesToLog.length > 0) {
      // eslint-disable-next-line no-console
      console.log.apply(console, variablesToLog);
    }

    throw new Error("[serializr] " + (formattedMessage || "Illegal State"))
  }
}

/**
 * serializes/deserializes member object as JSON.stringified plain json
 */
export function jsonObjectAsString(): PropSchema {
  var result = custom(function (sourcePropertyValue: any): any {
        if (sourcePropertyValue === null || sourcePropertyValue === undefined) {
          return sourcePropertyValue;
        } else {
          return JSON.stringify(sourcePropertyValue);
        }
      },
      function (jsonValue: any, context: Context, oldValue: any): any {
        if (jsonValue === null || jsonValue === undefined)
          return jsonValue;
        return JSON.parse(jsonValue);
      });
  return result;
}


/**
 *  function maps an object or a typeof ClassObject result string to a PropSchema.
 *  the first is called for serializing the given object writing json.polymorphicType = typeof object to the serialized json ,
 *  the second form is called for deserializing the object with the json.polymorphicType string..
 *  It mimices the style and structure of object(modelSchema, additionalArgs) from serializr.js
 */
type ModelSchemaResolver<T> = (any: Object | string) => ModelSchema<T>;

export function polymorphic<T>(modelSchemaResolver, additionalArgs?): PropSchema {
  var result = {
    serializer: function (sourcePropertyValue) {
      if (sourcePropertyValue === null || sourcePropertyValue === undefined) {
        return sourcePropertyValue;
      }
      const resolvedSchema: ModelSchema<T> = modelSchemaResolver(sourcePropertyValue);
      invariant(resolvedSchema, "polymorphic attribute modelSchemaResolver did not resolve to a schema for object " + sourcePropertyValue);
      //vM typing error in serializr 2.0.5, object.serializer should be of type PropSerializer, but is not
      const result = (object(resolvedSchema, additionalArgs) as any).serializer(sourcePropertyValue);
      result.polymorphicType = sourcePropertyValue && sourcePropertyValue.constructor && sourcePropertyValue.constructor.name;
      return result;
    },
    deserializer: function (childJson, done, context) {
      if (childJson === null || childJson === undefined)
        return void done(null, childJson)
      const resolvedSchema: ModelSchema<T> = modelSchemaResolver(childJson.polymorphicType);
      invariant(resolvedSchema, "polymorphic attribute modelSchemaResolver did not resolve to a schema for object " + childJson);
      return object(resolvedSchema, additionalArgs).deserializer(childJson, done, context, undefined);
    }
  };
  return result;
}

