/* CockpitsIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "./MetusSvgIcon";

class TablesIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <g>
        <path
            d="M30,25 L30,27 L17,27 L17,25 L30,25 Z M13,25 L13,27 L8,27 L8,25 L13,25 Z M30,17 L30,19 L17,19 L17,17 L30,17 Z M13,17 L13,19 L8,19 L8,17 L13,17 Z M30,9 L30,11 L17,11 L17,9 L30,9 Z M13,9 L13,11 L8,11 L8,9 L13,9 Z"
            />
      </g>
    </React.Fragment>
  }

  viewBox(): string {
    return "0 0 36 36";
  }
}

export default withStyles(svgIconStyles)(TablesIcon);
