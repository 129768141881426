import Store from "./Store";
import Action from "../actions/BaseAction";
import Log from "./Logger";
import {Dispatcher as FluxDispatcher} from "flux";
import StoreBase from "../stores/StoreBase";

const log = Log.logger("Dispatcher");

class Dispatcher extends FluxDispatcher<Action> {
  private _stores: Map<string, Store> = new Map();

  constructor() {
    super();
    this.dispatch = this.dispatch.bind(this);
  }

  public dispatch(action: Action): void {
    log.debug("Dispatching action to stores", action);
    super.dispatch(action);
  }

  public registerStore(store: Store): string {
    log.debug("Registered store", store);
    const id = super.register((action: Action) => store.accept(action));
    this._stores.set(id, store);
    return id;
  }

  //noinspection JSUnusedGlobalSymbols
  public deregisterStoreById(id: string): void {
    this._stores.delete(id);
    super.unregister(id);
  }

  public deregisterStoreByStore(store: Store): void {
    let id: string = null;
    this._stores.forEach((_store, _id) => {
      if (_store === store) {
        id = _id;
      }
    });

    if (id) {
      this.deregisterStoreById(id);
    }
  }


  /**
   * resets each store relevant for undo to initial state, but saving current model information
   */
  public resetStoresForUndo(): void {
    this._stores.forEach((val: StoreBase, id) => {
      if (val.isUndoRelevant) {
        val.reset(true);
      }
    });
  }

  public reset(): void {
    this._stores.forEach((val, id) => super.unregister(id));
    this._stores.clear();
  }

}

export const dispatcher = new Dispatcher();
export {dispatcher as Dispatcher};
