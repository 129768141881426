import * as React from "react";
import {CSSProperties} from "react";
import {DropTarget} from "react-dnd";
import {
  ElementId,
  SelectionKind,
  TableId,
  ViewId,
  VisualAttributeId,
  VisualElementId,
  VisualElementIdString,
  VisualTableId
} from "../../../core/utils/Core";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import {ConnectionsComponent} from "./ConnectionsComponent";
import {ChartColumnsComponent} from "../chart/ChartColumnsComponent";
import {PanZoomScrollableSVGComponent} from "./PanZoomScrollableSVGComponent";
import {DragRect} from "../../../common/components/DragRect";
import {Point, Rect, Rectangle} from "../../../common/utils/Geometry";
import {ZoomHelper} from "../../../common/utils/ZoomHelper";
import DragTypes from "../../../common/constants/DragTypes";
import {isDiagramDerivative, ViewType} from "../../../common/constants/Enums";
import Log from "../../../common/utils/Logger";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {createChildElement, deleteElementsAndDispatch} from "../../../core/actions/CoreAsyncActionCreators";
import {EditorDragHandlerResult, ViewerContext} from "../../utils/ViewerContext";
import {DndSourceFeedbackAction} from "../../../common/actions/InteractionStateActions";
import {ValueChartComponent} from "../valuechart/ValueChartComponent";
import {ConditionalFormat} from "../../../commonviews/models/ConditionalFormat";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {ClearSelectionAction, SelectElementsAction} from "../../../core/actions/CoreActions";
import {VisualChartColumn} from "../../models/chart/VisualChartColumn";
import {observer} from "mobx-react";
import {VisualBaseElement, VisualBaseTable} from "../../models/common/CommonDiagramTypes";
import autobind from "autobind-decorator";
import {RemoveAttributeFromViewAction, RemoveTableFromViewAction} from "../../../commonviews/actions/SharedViewActions";
import {VisualValueChartElement} from "../../models/valuechart/VisualValueChartElement";
import EmptyChartIcon from "../../../common/icons/constructionHelper/EmptyChartIcon";
import EmptyValueChartIcon from "../../../common/icons/constructionHelper/EmptyValueChartIcon";
import Portal from "@material-ui/core/Portal";
import {AttributeSelectionSubMenuComponent} from "../../../commonviews/components/AttributeSelectionSubMenuComponent";
import {SVGEmbeddedIcons} from "../../../common/components/SVGEmbeddedIcons";
import {showConfirmationDialog, showErrorDialog, showMessageDialog} from "../../../common/utils/CommonDialogUtil";
import {Identifier} from "dnd-core";
import {isRerenderThresholdReached} from "../../../common/utils/VirtualRenderUtil";
import {printDiagramInNewWindow, saveDiagramAsSvg} from "../../utils/DiagramExportUtil";
import {diagramStore} from "../../stores/DiagramStore";
import identity from "lodash/fp/identity";
import shallowEqual from "shallowequal";
import {ViewContext, viewContextId, ViewContextProvider} from "../../../commonviews/contexts/ViewContext";
import {createDynamicAttributeHeaderContextMenu} from "../../../commonviews/components/AttributeHeaderContextMenu";
import {collect, CoordinateConverterProvider, diagramDropTarget, ScrollController} from "./DiagramComponentDropSpec";
import {DiagramComponentProps} from "../../models/DiagramComponentProps";
import {SVGScalingConverter} from "../../../common/utils/CoordinateUtil";
import {duplicateElements, newElement} from "../../../core/services/CoreDataServices";
import {ISetDidChange, Lambda, ObservableSet, observe} from "mobx";
import {modelStore} from "../../../core/stores/ModelStore";
import {showConditionalFormatDialog} from "../../../commonviews/components/ConditionalFormatDialog";
import {ToggleConnectionActionPayload} from "../../../core/actions/CoreAsyncActions";
import {Validate} from "../../../common/utils/Validate";
import {viewManagerRegistry} from "../../../commonviews/models/ViewManager";
import {addAttributeToView} from "../../../commonviews/actions/SharedViewAsyncActions";
import {
  ChangeAutoLayoutPropertiesAction,
  NewTextboxAction,
  RemoveTextboxFromViewAction,
  SortChartColumnAction
} from "../../actions/DiagramActions";
import {VisualTextBoxComponent} from "./VisualTextBoxComponent";
import {AttributeContextMenuData} from "../../interfaces/ContextMenuDataTypes";
import {AutoLayoutOptions} from "../../utils/autolayout/AutoLayoutOptions";

const log = Log.logger("diagram");
const eventLog = Log.logger("diagram", Classifier.event);
const renderLog = Log.logger("diagram", Classifier.render);

/** determines chart position by storing the first click coordinate and the original node position, thus delta can be calculated later */
class ChartPosition {
  client: Point;
  node: Point;
}

function isAnyBtnPressed(e: React.MouseEvent<any>): boolean {
  return (e.buttons & 3) !== 0;
}

function isLeftBtnPressed(e: React.MouseEvent<any>): boolean {
  return (e.buttons & 1) !== 0;
}

function isRightBtnPressed(e: React.MouseEvent<any>): boolean {
  return (e.buttons & 2) !== 0;
}

interface DiagramComponentState {
  /** tracking is in progress, feedback will depend on this */
  tracking: boolean;
  /** upper left corner of rect */
  start: Point;
  /** lower right corner of rect */
  end: Point;
  /** save element id to activate edit mode when it will be rendered */
  directEditElementId?: ElementId;
  directEditVisualTableId?: VisualTableId;

  /** currently visible rect in svg coordinates, undefined if not known yet */
  visibleSVGRect: Rect;

  /** current scaling, undefined if not known yet */
  scale: number;

  /** temporary render state while exporting; will be deactivated after dumping the svg from dom after final rendering; content is the type string of the causing export action */
  exporting: "printView" | "exportView" | undefined;
}

/**
 * encapsulates mouse drag/drop/move handling state
 */
class MouseState {
  /** mouse down on sth draggable */
  dragging: boolean = false;
  /** mouse down over some draggable and relevant movement was detected */
  moving: boolean = false;
  /** evt.buttons value on mouse down */
  mouseButtons: number;
  clickedNode: VisualElementId;
  posAtMouseDown: ChartPosition;
  /** flag if touch start/touch end occured without a pan or pinch in between */
  inTouch: boolean = false;

  reset(): void {
    this.dragging = false;
    this.moving = false;
    this.posAtMouseDown = undefined;
    this.mouseButtons = 0;
    this.clickedNode = undefined;
  }
}

// App component
@observer
export class DiagramComponentNoDnd extends React.Component<DiagramComponentProps, DiagramComponentState> implements CoordinateConverterProvider {
  /** screen coordinates and svg coordinates are translated by the coordinate converter which respects zoom and scroll positions; it references the PanZoomScrollableSVGComponent for conversion */
  private readonly coordinateConverterRef: React.RefObject<PanZoomScrollableSVGComponent>;
  /** visual objects in this editor which are currently selected */
  private selectedVisuals: Set<VisualElementIdString>;
  private mouseState: MouseState;
  private contextClickPosition: Point;

  /**
   * holds the last animate count rendered; a rerender with animate true occurs if model.animate > renderedAnimationCount
   */
  private renderedAnimationCount: number = 0;
  private _primarySelectionObserverDisposer: Lambda;

  constructor(props: DiagramComponentProps) {
    super(props);
    Validate.isDefined(props.windowIndex);
    Validate.isDefined(props.windowPath);
    this.coordinateConverterRef = React.createRef();
    this.mouseState = new MouseState();
    this.mouseState.reset();
    this.state = {
      tracking: false,
      start: new Point(0, 0),
      end: new Point(0, 0),
      // MO-2928: start with dummy svg rect to avoid rendering whole chart until visible rect is calculated
      visibleSVGRect: {x: 0, y: 0, width: 800, height: 600},
      scale: 1.0,
      exporting: undefined
    };
  }

  @autobind
  private onPrimarySelectionChanged(event: ISetDidChange): any {
    switch (event.type) {
      case "add":
        log.debug("Element added to primary selection, now syncing diagram selected elements", event);
        const addedElementId = event.newValue;
        const addedVisualElements = this.props.viewModel.getVisualElementsByElementId(addedElementId);
        addedVisualElements.forEach(ve => this.selectedVisuals.add(ve.id.toKey()));
        break;
      case "delete":
        log.debug("Element removed from selection, now syncing diagram selected elements", event);
        const removedElementId = event.oldValue;
        const removedVisualElements = this.props.viewModel.getVisualElementsByElementId(removedElementId);
        removedVisualElements.forEach(ve => this.selectedVisuals.delete(ve.id.toKey()));
        break;
    }
  }

  @autobind storeContextClickPosition(event: React.MouseEvent<any>) {
    this.contextClickPosition = this.getCoordinateConverter().convertClientToSVG(event.clientX, event.clientY);
  }

  @autobind handleEditConditionalFormat(evt: any, data: { id: string }, target: any): void {
    const visualAttributeId: VisualAttributeId = VisualAttributeId.fromKey(data.id);
    const allowedAttributesForConditon: VisualAttributeId[] = Array.from(this.props.viewModel.getVisualTable(visualAttributeId.visualTableId).visualAttributeDefinitions.values()).map(vAtt => vAtt.id);
    const allowedAttributesForCondition = [visualAttributeId];
    const initialFormats = this.props.viewModel.getVisualAttributeDefinition(visualAttributeId).conditionalFormats.map(icf => new ConditionalFormat(icf.operand, icf.filterExpression, icf.styles));
    showConditionalFormatDialog(true, this.props.viewInfo.id, visualAttributeId, allowedAttributesForCondition, initialFormats);
  }

  @autobind handleSort(evt: any, data: { id: string, ascending: boolean }, target: any): void {
    const visualAttributeId: VisualAttributeId = VisualAttributeId.fromKey(data.id);
    log.debug("sort chart column " + visualAttributeId.attributeName + (data.ascending ? " ascending" : " descending"));
    const autoLayoutOptions = this.props.viewModel.autoLayoutOptions;
    if (autoLayoutOptions?.autolayout && !autoLayoutOptions?.preserveOrdering) {
      showConfirmationDialog("You have activated 'Continuous Autolayout'\nbut 'Preserve Order of Elements' is not selected\n so Autolayout will revert your sort.\n\nShall I activate it?",
          () => {
            const newProps: AutoLayoutOptions = {...this.props.viewModel.autoLayoutOptions, preserveOrdering: true};
            Dispatcher.dispatch(new ChangeAutoLayoutPropertiesAction(this.props.viewModel.id, newProps));
            Dispatcher.dispatch(new SortChartColumnAction(this.props.viewModel.id, visualAttributeId, data.ascending));
          });
    }
    Dispatcher.dispatch(new SortChartColumnAction(this.props.viewModel.id, visualAttributeId, data.ascending));
  }

  @autobind handleRemoveTable(evt: any, data: { id: string }, target: any): void {
    log.debug("remove table " + data);
    Dispatcher.dispatch(new RemoveTableFromViewAction(this.props.viewModel.id, VisualTableId.fromKey(data.id)));
  }

  @autobind handleRemoveAttribute(evt: any, data: { id: string }, target: any): void {
    log.debug("remove attribute " + data);
    Dispatcher.dispatch(new RemoveAttributeFromViewAction(this.props.viewModel.id, VisualAttributeId.fromKey(data.id), undefined, false));
  }

  @autobind handleDuplicateSelectedElements(evt: any, data: { id: string }, target: any): void {
    log.debug("duplicate selected elements " + data);
    const selectedNodes: Array<ElementId> = Array.from(this.selectedVisuals.add(data.id)).map((vidString: VisualElementIdString) => VisualElementId.fromKey(vidString).elementId);
    const uniqueSelectedIds = [...new Set(selectedNodes)];
    const y = Math.max(...selectedNodes.map(elementId => {
      const visual = this.props.viewModel.getVisualById(elementId);
      return visual ? visual.y + visual.height : null;
    }).filter(val => val !== null));

    duplicateElements(uniqueSelectedIds, this.props.viewInfo.id, y);
  }


  @autobind handleDeleteSelectedElements(evt: any, data: { id: string }, target: any): void {
    log.debug("delete selected elements " + data);
    const selectedNodes: Array<ElementId> = Array.from(this.selectedVisuals.add(data.id)).map((vidString: VisualElementIdString) => VisualElementId.fromKey(vidString).elementId);
    deleteElementsAndDispatch(selectedNodes);
  }

  @autobind handleRemoveTextbox(evt: any, data: { id: string }, target: any) {
    log.debug("remove textbox " + data);
    Dispatcher.dispatch(new RemoveTextboxFromViewAction(this.props.viewModel.id, data.id));
  }

  @autobind handleNewElement(evt: any, data: { id: string }, target: any): void {
    log.debug("new element " + data.id);
    const visualElementId = VisualElementId.fromKey(data.id);
    const visualElement: VisualBaseElement = this.props.viewModel.getVisualElement(visualElementId);
    if (visualElement && visualElement.visualTable) {
      const targetVisualTableId = visualElement.visualTable.id;
      if (visualElement.parent) {
        const sourceElementId: ElementId = (visualElement.parent.id as VisualElementId).elementId;
        this.newElementOrChildElementInDiagram(targetVisualTableId, sourceElementId);
      } else {
        this.newElementOrChildElementInDiagram(targetVisualTableId);
      }
    } else {
      showErrorDialog(true, "Could not find Visual Element with id:" + data.id);
    }
  }

  @autobind handleNewTopLevelElement(evt: any, data: { id: string }, target: any): void {
    log.debug("new element " + data.id);
    const targetVisualTableId = VisualTableId.fromKey(data.id);
    this.newElementOrChildElementInDiagram(targetVisualTableId);
  }

  @autobind handleNewChildElement(evt: any, data: { id: string }, target: any): void {
    log.debug("new child element " + data.id);
    const visualElementId = VisualElementId.fromKey(data.id);
    const visualElement: VisualBaseElement = this.props.viewModel.getVisualElement(visualElementId);
    if (visualElement instanceof VisualValueChartElement && visualElement.level.childLevel) {
      const targetVisualTableId = visualElement.level.childLevel.id;
      this.newElementOrChildElementInDiagram(targetVisualTableId, visualElement.id.elementId);
    } else {
      showMessageDialog(true, "New Child Element not possible on last Level of Value Chart");
    }
  }

  @autobind handleNewTextbox(evt: any, data: { id: string }, target: any): void {
    log.debug("create new textbox " + data);
    Dispatcher.dispatch(new NewTextboxAction(this.props.viewModel.id, this.contextClickPosition.x, this.contextClickPosition.y));
  }

  render(): JSX.Element {
    renderLog.debug("Rendering DiagramComponent " + this.props.viewModel.name);
    const animate = this.props.viewModel.animationCount > this.renderedAnimationCount;
    this.renderedAnimationCount = this.props.viewModel.animationCount;
    const viewerContext = new ViewerContext(
        this.onBeginDrag,
        this.onEndDirectEdit,
        this.props.viewModel.id,
        this.props.viewModel.type,
        this.getScale(),
        animate,
        this.state.directEditElementId,
        this.state.directEditVisualTableId,
        this.props.windowIndex,
        this.props.windowPath,
        this.getCoordinateConverter());

    let trackingRect = null;
    if (this.state.tracking) {
      const rect = new Rectangle(this.state.start, this.state.end);
      trackingRect = <DragRect rect={rect}/>;
    }

    // animate is not observable thus does not trigger rerender, thus can be reset here
    const connect = this.props.connectDropTarget ? this.props.connectDropTarget : identity;

    const visualChartColumns: VisualChartColumn[] = this.props.viewModel.visualChartColumns !== undefined ? Array.from(this.props.viewModel.visualChartColumns.values()) : [];
    const visualValueChartLevels: VisualBaseTable[] = this.props.viewModel.valueChart !== undefined ? this.props.viewModel.valueChart.levels : [];
    const visualColumns: (VisualChartColumn | VisualBaseTable)[] = ([].concat(visualChartColumns)).concat(visualValueChartLevels);
    const viewContext = new ViewContext(this.props.viewModel.id, this.props.viewModel.type, this.props.windowIndex);

    const DynamicAttributeHeaderContextMenu = createDynamicAttributeHeaderContextMenu(this.props.windowIndex);
    const contextMenuId = "cm_dg_DiagramComponent" + this.props.windowIndex;
    return (
        <ViewContextProvider value={viewContext}>
          <div className="editor" data-testselector={"Editor" + this.props.windowIndex}
               data-testviewtype={ViewType[this.props.viewInfo.type]}>
            {connect(
                <div style={{height: "100%", width: "100%"}}>
                  {trackingRect}
                  <ContextMenuTrigger id={contextMenuId}
                                      data-id={this.props.viewModel.id}
                                      holdToDisplay={-1}
                                      attributes={{
                                        "data-testselector": "contextmenutrigger",
                                        style: {width: "100%", height: "100%"},
                                        "onContextMenu": this.storeContextClickPosition
                                      } as any}>
                    <PanZoomScrollableSVGComponent zoomPointProvider={this.determineZoomPointFromSelection}
                                                   ref={this.coordinateConverterRef}
                                                   boundsProvider={this.props.viewModel}
                                                   onVisibleWindowChanged={this.onVisibleWindowChanged}
                                                   onMouseDown={this.onMouseDown}
                                                   onMouseMove={this.onMouseMove}
                                                   onMouseUp={this.onMouseUp}
                                                   onMouseLeave={this.onMouseLeave}
                                                   onTouchStart={this.onTouchStart}
                                                   onTouchMove={this.onTouchMove}
                                                   onTouchEnd={this.onTouchEnd}
                                                   onTouchCancel={this.onTouchCancel}
                                                   onDoubleClick={this.onDoubleClick}
                                                   windowIndex={this.props.windowIndex}
                    >
                      <SVGEmbeddedIcons/>
                      {this.renderContent(viewerContext, this.props.viewModel.animationCount)}
                    </PanZoomScrollableSVGComponent>
                  </ContextMenuTrigger>
                </div>
            )}

            {visualColumns.map((table: VisualBaseTable | VisualChartColumn) =>
                <Portal key={table.id.toKey()}>
                  <ContextMenu id={"cm_dg_TableHeaderComponent" + viewContextId(viewContext) + table.id.toKey()}
                               onHide={this.onHide} className={"metusContextMenu"}
                               key={"cm_dg_TableHeaderComponent" + this.props.windowIndex + table.id.toKey()}>
                    <MenuItem preventClose={false} onClick={this.handleRemoveTable}
                              attributes={{className: "contextmenu-option--remove"} as any}>Remove</MenuItem>
                    <AttributeSelectionSubMenuComponent
                        viewId={this.props.viewModel.id}
                        visualTableId={table.id}
                        visualAttributeDefinitionIds={Array.from(table.visualAttributeDefinitions.values()).map(vattDef => vattDef.id)}
                        onAddAttribute={this.handleAddAttributeToView}
                        onRemoveAttribute={this.handleRemoveAttributeFromView}
                    />
                  </ContextMenu>
                </Portal>
            )}


            <Portal key="attributeMenu">
              <DynamicAttributeHeaderContextMenu onEditConditionalFormat={this.handleEditConditionalFormat}
                                                 onRemoveAttribute={this.handleRemoveAttribute}
                                                 onSort={this.handleSort}
                                                 sortable={isDiagramDerivative(this.props.viewModel.type)}/>
              <ContextMenu id={"cm_dg_DiagramComponent" + this.props.windowIndex} className={"metusContextMenu"}>
                <MenuItem key="CreateNewTextbox" onClick={this.handleNewTextbox}
                          attributes={{className: "contextmenu-option--createnewtextbox"} as any}>Create New
                  Textbox</MenuItem>
              </ContextMenu>
              <ContextMenu id={"cm_dg_TextBoxcomponent" + this.props.windowIndex} className={"metusContextMenu"}>
                <MenuItem key="Remove" onClick={this.handleRemoveTextbox}
                          attributes={{className: "contextmenu-option--removetextbox"} as any}>Remove</MenuItem>
              </ContextMenu>
              <ContextMenu id={"cm_dg_TableHeaderComponent" + this.props.windowIndex} className={"metusContextMenu"}>
                <MenuItem onClick={this.handleRemoveTable}
                          attributes={{className: "contextmenu-option--remove"} as any}>Remove</MenuItem>
              </ContextMenu>
              <ContextMenu id={"cm_dg_NestedElementComponent" + this.props.windowIndex} className={"metusContextMenu"}>
                <MenuItem key="DeleteElement" onClick={this.handleDeleteSelectedElements}
                          attributes={{className: "contextmenu-option--deleteelement"} as any}>Delete
                  Element(s)</MenuItem>
                <MenuItem key="DuplicateElements" onClick={this.handleDuplicateSelectedElements}
                          attributes={{className: "contextmenu-option--duplicateelementd"} as any}>Duplicate
                  Element(s)</MenuItem>
              </ContextMenu>
              <ContextMenu id={"cm_dg_NestedElementComponentValueChart" + this.props.windowIndex}
                           className={"metusContextMenu"}>
                <MenuItem key="NewElement" onClick={this.handleNewElement}
                          attributes={{className: "contextmenu-option--newelement"} as any}>New
                  Element</MenuItem>
                <MenuItem key="NewChildElement" onClick={this.handleNewChildElement}
                          attributes={{className: "contextmenu-option--newchildelement"} as any}>New Child
                  Element</MenuItem>
                <MenuItem key="DeleteElement" onClick={this.handleDeleteSelectedElements}
                          attributes={{className: "contextmenu-option--deleteelement"} as any}>Delete
                  Element(s)</MenuItem>
                <MenuItem key="DuplicateElements" onClick={this.handleDuplicateSelectedElements}
                          attributes={{className: "contextmenu-option--duplicateelementd"} as any}>Duplicate
                  Element(s)</MenuItem>
              </ContextMenu>
              <ContextMenu id={"cm_dg_NestedElementComponentValueChartEmpty" + this.props.windowIndex}
                           className={"metusContextMenu"}>
                <MenuItem key="NewElement" onClick={this.handleNewTopLevelElement}
                          attributes={{className: "contextmenu-option--newelement"} as any}>New
                  Element</MenuItem>
              </ContextMenu>
              <ContextMenu id={"cm_dg_AttributeValue" + this.props.windowIndex} className={"metusContextMenu"}>
                <MenuItem key="Open" onClick={this.handleOpen}
                          attributes={{className: "contextmenu-option--open"} as any}>Open</MenuItem>
              </ContextMenu>
            </Portal>
          </div>
        </ViewContextProvider>
    );
  }


  public getCoordinateConverter(): SVGScalingConverter {
    return this.coordinateConverterRef.current as SVGScalingConverter;
  }

  public getScrollController(): ScrollController {
    return this.coordinateConverterRef.current as ScrollController;
  }


  private isVirtualized(): boolean {
    return !this.state.exporting && this.props.viewModel.virtualRenderingActive;
  }

  /**
   * handles element creation in this diagram, special logic for direct edit mode and selection because the element is not yet in the model
   * @param targetVisualTableId
   * @param sourceElementId
   * @param y
   */
  private newElementOrChildElementInDiagram(targetVisualTableId: VisualTableId, sourceElementId?: ElementId, y?: number): void {
    let result;
    // MO-4077 check if column is filtered or continuous autolayout is active and issue an error if yes
    if (this.props.viewModel.autoLayoutOptions?.autolayout) {
      showMessageDialog(true, "New Elements cannot be created when continuous autolayout is active.\nPlease deactivate it.");
      return;
    } else {
      const filteredVisualTableIds = Array.from(this.props.viewModel.viewerFilters.keys()).map(attIdString => VisualAttributeId.fromKey(attIdString).visualTableId);
      if (filteredVisualTableIds.find(filteredVisualTableId => filteredVisualTableId.toKey() === targetVisualTableId.toKey())) {
        showMessageDialog(true, "New Elements cannot be created when a filter is active on the table.\nPlease deactivate it.");
        return;
      }
    }
    if (sourceElementId) {
      // connect new element
      result = createChildElement(targetVisualTableId.tableId, sourceElementId).then((result: ToggleConnectionActionPayload) => {
        // remember to activate direct edit mode as soon as element appears
        this.setState(state => {
          log.debug("Direct Edit Mode activated for " + result.targetElementId + ", " + targetVisualTableId.toKey());
          return {
            ...state,
            directEditElementId: result.targetElementId,
            directEditVisualTableId: targetVisualTableId
          };
        });
      });
    } else {
      result = newElement(targetVisualTableId.tableId, undefined, y).then(visualElementId => {
        // remember to activate direct edit mode as soon as element appears
        this.setState(state => {
          log.debug("Direct Edit Mode activated for " + visualElementId + ", " + targetVisualTableId.toKey());
          return {
            directEditElementId: visualElementId,
            directEditVisualTableId: targetVisualTableId
          };
        });
      });
    }
    return result;
  }


  @autobind
  private onHide(): void {
    // Here remove/add all attributes at once
  }

  private renderContent(viewerContext: ViewerContext, animationCount: number): JSX.Element {
    let result: JSX.Element;
    if (this.props.viewModel.tableIds.length > 0) {
      // pass visibleSVGRect only if virtualized
      const visibleSVGRect = this.isVirtualized() ? this.state.visibleSVGRect : undefined;
      result = <g className="graph">
        {this.props.viewModel.textBoxes.map(textboxModel => {
          return <VisualTextBoxComponent key={textboxModel.id} viewModel={textboxModel} viewerContext={viewerContext}/>
        })}
        {this.props.viewModel.visualChartColumns.size > 0 ?
            <React.Fragment>
              <ConnectionsComponent visualConnections={this.props.viewModel.connections}
                                    animationCount={animationCount}/>
              <ChartColumnsComponent viewerContext={viewerContext}
                                     visibleSVGRect={visibleSVGRect}
                                     viewerFilters={this.props.viewModel.viewerFilters}
                                     filterTextsValidities={this.props.viewModel.filterTextsValidities}
                                     tables={this.props.viewModel.visualChartColumns}
                                     animationCount={animationCount}/>
            </React.Fragment>
            : null}
        {this.props.viewModel.valueChart ?
            <ValueChartComponent viewerContext={viewerContext}
                                 viewModel={this.props.viewModel.valueChart}
                                 visibleSVGRect={visibleSVGRect}
                                 viewerFilters={this.props.viewModel.viewerFilters}
                                 isHeaderExpanded={this.props.viewModel.isHeaderExpanded}
                                 filterTextsValidities={this.props.viewModel.filterTextsValidities}/> : null}
      </g>;
    } else {
      result = this.props.viewModel.valueChart ?
          <EmptyValueChartIcon style={{width: "100%", height: "100%"}} data-testselector={"ConstructionHelper"}/> :
          <EmptyChartIcon style={{width: "100%", height: "100%"}} data-testselector={"ConstructionHelper"}/>;
    }

    return result;
  }

  private getScale(): number {
    const coordinateConverter = this.getCoordinateConverter();
    return coordinateConverter ? coordinateConverter.scale : 1.0;
  }

  /**
   * @return set of all visual element id strings of elements selected in this diagram, DO NOT MODIFY THE RETURNED SET
   */
  getSelectedVisuals(): Set<VisualElementIdString> {
    return this.selectedVisuals;
  }

  @autobind
  private onTouchStart(e: React.TouchEvent<SVGElement>): void {
    this.logEvent("touchstart: ", e);
    if (e.touches.length === 1) {
      const touchPoint = e.changedTouches[0];
      this.stopPropagation(e, this.processMouseDown(new Point(touchPoint.clientX, touchPoint.clientY), e.ctrlKey, 0, e.target));
    }
    this.mouseState.inTouch = true;
  }

  @autobind
  private onMouseDown(e: React.MouseEvent<any>): void {
    this.mouseState.mouseButtons = e.buttons;
    if (isLeftBtnPressed(e) || isRightBtnPressed(e)) {
      this.processMouseDown(new Point(e.clientX, e.clientY), e.ctrlKey, e.buttons, e.target);
    }
  }

  @autobind
  private onTouchMove(e: React.TouchEvent<SVGElement>): void {
    this.logEvent("touchmove: ", e);
    if (e.touches.length === 1) {
      const touchPoint = e.changedTouches[0];
      this.stopPropagation(e, this.processMouseMove(new Point(touchPoint.clientX, touchPoint.clientY), e.ctrlKey));
    }
  }

  @autobind
  private onMouseMove(e: any): void {
    const clientPos = new Point(e.clientX, e.clientY);
    log.trace("onMouseMove, dragging = " + this.mouseState.dragging, "buttons = " + this.mouseState.mouseButtons);
    if (this.mouseState.dragging && this.mouseState.posAtMouseDown && this.mouseState.posAtMouseDown.client) {
      // handled by react dnd, make sure to set moving state so selection on mouse up will not be processed
      const delta = clientPos.minus(this.mouseState.posAtMouseDown.client);
      log.trace("UpdateTablePosition: Movement of mouse", delta);
      if (!delta.isZero()) {
        this.mouseState.moving = true;
        if (this.mouseState.clickedNode === undefined) {
          // click on svg, start tracking rect
          this.updateTrackingRect(clientPos, true);
        }
      }
    }
    this.logEvent("mousemove", e);
    this.stopPropagation(e, this.processMouseMove(clientPos, e.ctrlKey));
  }

  @autobind
  private onTouchEnd(e: React.TouchEvent<SVGElement>): void {
    this.logEvent("touchend: ", e);
    // make sure this does not conflict with pan/pinch events
    if (e.touches.length <= 1 && this.mouseState.inTouch) {
      const touchPoint = e.changedTouches[0];
      this.stopPropagation(e, this.processMouseUp(new Point(touchPoint.clientX, touchPoint.clientY), e.ctrlKey));
    }
  }

  @autobind
  private onMouseLeave(e: any): void {
    this.logEvent("mouseleave", e);
    // catch mouse left svg
    this.clearDragState();
  }

  @autobind
  private onTouchCancel(e: React.TouchEvent<SVGElement>): void {
    this.logEvent("Touch Cancel: ", e);
    this.clearDragState();
  }

  @autobind
  private onDoubleClick(e: any): void {
    // double click on empty diagram area
    // TODO: verify if this works instead of comparing with svg
    if (e.target === e.currentTarget) {
      // ODER if (target.nodeName === "svg") {
      const point = this.getCoordinateConverter().convertClientToSVG(e.clientX, e.clientY);
      const viewTable = this.findTableColumn(point);
      if (viewTable) {
        log.debug("Create new Element in Column " + viewTable.header.name);
        this.newElementOrChildElementInDiagram(viewTable.id, undefined, point.y);
      }
    }
  }

  private stopPropagation<T>(e: React.SyntheticEvent<T>, prevent: boolean = true): void {
    if (prevent) {
      e.stopPropagation();
    }
  }

  @autobind
  private onMouseUp(e: React.MouseEvent<any>): void {
    if ((this.mouseState.mouseButtons & 1) > 0) {
      this.logEvent("mouseup", e);
      this.stopPropagation(e, this.processMouseUp(new Point(e.clientX, e.clientY), e.ctrlKey));
    } else if ((this.mouseState.mouseButtons & 2) > 0) {
      this.logEvent("mouseup right button", e);
      this.processMouseUp(new Point(e.clientX, e.clientY), e.ctrlKey);
    }
  }

  private handleSvgOnMouseDown(clientPos: Point): void {
    this.mouseState.posAtMouseDown = {
      client: clientPos,
      node: new Point(0, 0)
    };
    this.mouseState.dragging = true;
    log.trace("mousedown on chart background: dragging at position", this.mouseState.posAtMouseDown);
  }

  private processMouseDown(clientPos: Point, ctrlPressed: boolean, buttons: number, target: any): boolean {
    let handled = false;
    this.clearDragState();
    this.mouseState.mouseButtons = buttons;
    const nodeId: VisualElementId = this.getVisualNodeId(target);
    if (nodeId) {
      this.handleNodeOnMouseDown(nodeId, clientPos, ctrlPressed);
      handled = true;
    } else if (target.nodeName === "svg") {
      // clicked somewhere on diagram => clear selection
      // necessary to not conflict with react-dnd logic
      this.handleSvgOnMouseDown(clientPos);
      handled = true;
    }
    return handled;
  }

  private processMouseMove(clientPos: Point, ctrlPressed: boolean): boolean {
    let handled = false;
    if (this.state.tracking) {
      // update tracking rect
      this.updateTrackingRect(clientPos, true);
      handled = true;
    }
    return handled;
  }

  private handleNodeOnMouseDown(nodeId: VisualElementId, clientPos: Point, ctrlPressed: boolean): void {
    const node = this.props.viewModel.getVisualElement(nodeId);
    if (node) {
      this.mouseState.clickedNode = node.id;
      const alreadySelected = node.selection === SelectionKind.Primary; // this.getSelectedVisuals().has(node.id.toKey());
      if (!alreadySelected || ctrlPressed) {
        const vidString = this.mouseState.clickedNode.toKey();
        // select current node, replace selection if no ctrl key, otherwise extend selection and toggle
        if (!ctrlPressed) {
          // replace selection by this visual element
          this.selectedVisuals = new Set<VisualElementIdString>([vidString]);
        } else {
          // toggle
          if (this.selectedVisuals.has(vidString)) {
            this.selectedVisuals.delete(vidString);
          } else {
            this.selectedVisuals.add(vidString);
          }
        }
        Dispatcher.dispatch(new SelectElementsAction([this.mouseState.clickedNode.elementId], ctrlPressed, true));
      }
      this.mouseState.dragging = true;
      this.mouseState.posAtMouseDown = {
        client: clientPos,
        node: new Point(node.x, node.y)
      };
      log.trace("mousedown on node: dragging at position", this.mouseState.posAtMouseDown);
    } else {
      log.warn("Internal Error: could not find visual node id, this should not happen");
    }
  }

  private clearDragState(): void {
    this.mouseState.reset();
    if (this.state.tracking) {
      this.setState((state, props) => ({...state, tracking: false}));
    }
  }

  private updateTrackingRect(clientPos: Point, tracking: boolean): void {
    const start = new Point(this.mouseState.posAtMouseDown.client.x, this.mouseState.posAtMouseDown.client.y);
    const end = new Point(clientPos.x, clientPos.y);
    this.setState((state, props) => ({...state, start: start, end: end, tracking: tracking}));
  }

  @autobind
  private onVisibleWindowChanged(scale: number, width: number, height: number, visibleSVGRect: Rect): void {
    log.debug("Virtual SVG Rect: " + JSON.stringify(visibleSVGRect));
    // avoid infinite recursion
    // and only update if rerender theshold reached
    log.debug("onVisibleWindowChanged scale:" + scale + ", pos: " + width + "," + height + ", rect: " + JSON.stringify(visibleSVGRect));
    if ((!shallowEqual(visibleSVGRect, this.state.visibleSVGRect) && isRerenderThresholdReached(this.state.visibleSVGRect, visibleSVGRect)) || Math.abs(this.state.scale - scale) > 0.0001) {
      log.debug("onVisibleWindowChanged state update scale: " + scale + ", pos: " + width + "," + height + ", rect: " + JSON.stringify(visibleSVGRect));
      this.setState(state => {
        return {...state, visibleSVGRect: visibleSVGRect, scale: scale};
      });
    }
  }

  public convertDeltaToSVG(d: number): number {
    return d * (1.0 / this.getScale());
  }

  private findTableColumn(clientPos: Point): VisualChartColumn | undefined {
    const result: VisualChartColumn = Array.from(this.props.viewModel.visualChartColumns.values()).filter((t: VisualChartColumn) => t.header.x <= clientPos.x && clientPos.x <= t.header.x + t.header.width)[0];
    return result;
  }

  /**
   * @param target dom target node to find table id for
   * @returns node id associated with current svg element (= attribute data-nodeid) or {undefined} if none found
   */
  private getVisualNodeId(target: any): VisualElementId | null {
    let element = target;
    let nodeData: string;
    while ((nodeData = element.getAttribute("data-nodeid")) == null && element.parentNode && element.parentNode.getAttribute) {
      element = element.parentNode;
    }
    log.trace("getVisualNodeId", target, nodeData);
    return VisualElementId.fromKey(nodeData);
  }

  private processMouseUp(clientPos: Point, ctrlPressed: boolean): boolean {
    if (this.state.tracking) {
      this.updateTrackingRect(clientPos, false);
      const svgStart = this.getCoordinateConverter().convertClientToSVG(this.state.start.x, this.state.start.y);
      const svgEnd = this.getCoordinateConverter().convertClientToSVG(this.state.end.x, this.state.end.y);
      // select contained elements
      const nodes: VisualElementId[] = this.props.viewModel.getElementVisualsInRectangle(new Rectangle(svgStart, svgEnd));
      let newSelectedVisual: Set<VisualElementIdString> = new Set<VisualElementIdString>(nodes.map(vid => vid.toKey()));

      if (ctrlPressed) {
        // add existing selection
        newSelectedVisual = new Set<VisualElementIdString>([...Array.from(this.selectedVisuals.values()), ...Array.from(newSelectedVisual.values())]);
      }

      const selectedElementIds: ElementId[] = nodes.map(vid => vid.elementId);
      if (selectedElementIds.length > 0) {
        Dispatcher.dispatch(new SelectElementsAction(selectedElementIds, ctrlPressed, false));
      }

      this.selectedVisuals = newSelectedVisual;
    } else if (!this.mouseState.clickedNode && !ctrlPressed) {
      Dispatcher.dispatch(new ClearSelectionAction());
    }
    this.clearDragState();
    return true;
  }

  private logEvent(evtName: string, e: any): void {
    eventLog.debug(evtName + ", nodeid: " + this.getVisualNodeId(e.target), e.target);
  }

  /**
   * determine the point which should stay in the viewable area when zoomed
   * @return {Point} point or undefined, if no specific point is determined
   */
  @autobind
  private determineZoomPointFromSelection(): Point {
    let result: Point = undefined;
    if (this.selectedVisuals.size > 0) {
      // calculate containing rectangle of all nodes
      const rectangles = Array.from(this.selectedVisuals.values()).map(visualNodeId => this.props.viewModel.getVisualElement(VisualElementId.fromKey(visualNodeId))).filter(n => n !== undefined).map(n => Rectangle.from(n.x, n.y, n.width, n.height));
      result = ZoomHelper.determineZoomPoint(...rectangles);
    }
    log.debug("Zoom Point: ", result);
    return result;
  }

  @autobind
  private onBeginDrag(itemType: Identifier, itemId: VisualElementId): EditorDragHandlerResult {
    // all selected visuals + currently dragged node
    const visualsToMove: Set<VisualElementIdString> = this.selectedVisuals.add(itemId.toKey());

    /* Synch selected visuals with model. */
    const iter = Array.from(visualsToMove).filter(vidstring => this.props.viewModel.getVisualElement(VisualElementId.fromKey(vidstring)) !== undefined);
    this.selectedVisuals = new Set<VisualElementIdString>(iter);
    let selectedSourceTableIds;
    if (itemType === DragTypes.VISUAL_ELEMENTS) {
      const tableIds: TableId[] = iter.map((visualElementId) => {
        return this.props.viewModel.getVisualElement(VisualElementId.fromKey(visualElementId)).visualTable.id.tableId
      });
      selectedSourceTableIds = new Set<TableId>(tableIds);
    }

    // set dnd source feedback, DragLayer will render it
    Dispatcher.dispatch(new DndSourceFeedbackAction(this.createSourceFeedback(this.selectedVisuals)));

    return {
      viewId: this.props.viewModel.id,
      windowPath: this.props.windowPath,
      windowIndex: this.props.windowIndex,
      selectedVisuals: this.selectedVisuals,
      selectedSourceTableIds,
      itemType: itemType
    };
  }

  /**
   * called if direct edit is terminated
   */
  @autobind
  private onEndDirectEdit(): void {
    this.setState(state => ({directEditElementId: undefined, directEditVisualTableId: undefined}));
  }


  private createSourceFeedback(visualsToMove: Set<VisualElementIdString>): JSX.Element {
    const nodeFeedback = [];
    visualsToMove.forEach(vidstring => {
      const nodeModel: VisualBaseElement = this.props.viewModel.getVisualElement(VisualElementId.fromKey(vidstring));
      const {
        x,
        y,
        width,
        height
      } = this.getCoordinateConverter().convertSVGToClient(nodeModel.x, nodeModel.y, nodeModel.width, nodeModel.height);
      const style = {
        transform: `translate(${x}px, ${y}px)`,
        width: `${width}px`,
        height: `${height}px`,
        position: "absolute"
      };
      // cast necessary because of typescript error position: string not compatible
      nodeFeedback.push(<div key={nodeModel.id.toKey()} className="drag-box-source-feedback"
                             style={style as CSSProperties}/>);
    });
    return <div>{nodeFeedback}</div>;
  }

  componentDidMount(): void {
    log.debug("register at DiagramStore");
    // changes in model are handled by mobx, but this is an action targeted to be executed by the view
    diagramStore.register(this.storeChanged);
    // initially set this.selectedVisuals from primary selection ...
    this.selectedVisuals = new Set<VisualElementIdString>();
    const veids = modelStore.primarySelection
        .forEach(elementId => this.props.viewModel.getVisualElementsByElementId(elementId)
            .forEach(visualElement => this.selectedVisuals.add(visualElement.id.toKey())));
    // ... and keep it in sync when the primary selection changes
    const primarySelectionObservable: ObservableSet<ElementId> = modelStore.primarySelection as ObservableSet<ElementId>;
    this._primarySelectionObserverDisposer = observe(primarySelectionObservable, this.onPrimarySelectionChanged);
  }

  componentWillUnmount(): void {
    log.debug("deregister from DiagramStore");
    diagramStore.deregister(this.storeChanged);
    this._primarySelectionObserverDisposer && this._primarySelectionObserverDisposer();
  }

  componentDidUpdate(prevProps: DiagramComponentProps, prevState: DiagramComponentState): void {
    log.debug("prevstate: " + JSON.stringify(prevState));
    if (this.state.exporting !== undefined && prevState.exporting === undefined) {
      log.debug("scheduling export handler");
      setTimeout(this.handleExportAfterUpdateFinished);
    }
  }

  @autobind handleExportAfterUpdateFinished(): void {
    log.info("executing export handler");
    log.debug("component did update after export action, do export now");
    switch (this.state.exporting) {
      case "printView":
        printDiagramInNewWindow(this.props.viewInfo.id);
        break;
      case "exportView":
        saveDiagramAsSvg(this.props.viewInfo.name);
        break;
      default:
        throw new Error("Export Action cannot be handled");
    }
    showMessageDialog(false);
    this.setState(state => ({exporting: undefined}));
  }

  @autobind storeChanged(reason: string): void {
    // changes in model are handled by mobx, but this is an action targeted to be executed by the view
    // the export logic is executed in componentDidUpdate
    // vM: since no action available in notification, check if current view is active, since print and export should only target active view
    if (viewManagerRegistry.viewManager.activeViewId === this.props.viewInfo.id) {
      switch (reason) {
        case "printView":
          this.setState(state => ({exporting: reason}));
          break;
        case "exportView":
          this.setState(state => ({exporting: reason}));
          break;
      }
    }
  }

  @autobind
  private handleAddAttributeToView(viewId: ViewId, visualAttributeId: VisualAttributeId): void {
    addAttributeToView(viewId, visualAttributeId, {x: 0.0, y: 0.0}, false, true);
  }

  @autobind
  private handleRemoveAttributeFromView(viewId: ViewId, visualAttributeId: VisualAttributeId): void {
    Dispatcher.dispatch(new RemoveAttributeFromViewAction(viewId, visualAttributeId, undefined, false));
  }

  @autobind handleOpen(evt: any, data: AttributeContextMenuData, target: any): void {
    log.debug("open " + data.attributeName + ", value: " + data.attributeValue);
    window.open(data.attributeValue);
  }
}

export const
    DiagramComponent = DropTarget<DiagramComponentProps>([DragTypes.CORE_ATTRIBUTE_DEFINITION, DragTypes.CORE_TABLE, DragTypes.VIEW, DragTypes.RESIZE_HANDLE, DragTypes.VISUAL_ELEMENTS, DragTypes.ATTRIBUTE_COLUMN_MOVE, DragTypes.VISUAL_TABLE_COLUMN_MOVE], diagramDropTarget, collect)(DiagramComponentNoDnd);
