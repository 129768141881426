// lib imports
import * as React from "react";
import Log from "../../../common/utils/Logger";
import {Direction} from "../../../common/utils/Direction";
import {ResizeTableAction} from "../../actions/DiagramActions";
import {ViewerContext} from "../../utils/ViewerContext";
import {VisualAttributeIdString} from "../../../core/utils/Core";
import {AttributeDefinitionsComponent} from "../chart/AttributeDefinitionsComponent";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {VisualValueChartLevel} from "../../models/valuechart/VisualValueChartLevel";
import {VisualHeader} from "../../../commonviews/models/VisualHeader";
import {observer} from "mobx-react";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {getLevelDefaultColor, getLevelDefaultTextColor} from "../../../core/utils/LevelColorUtil";
import autobind from "autobind-decorator";
import {SVGTextBoxComponent} from "../../../common/components/SVGTextBoxComponent";
import {HandleComponent} from "../common/HandleComponent";
import {TableColumnHeaderComponentDnd} from "../../../commonviews/components/TableColumnHeaderComponentDndSpec";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

interface LocalProps {
  viewerContext: ViewerContext;
  levels: VisualValueChartLevel[];
  /** if true, attributes and filter line will be displayed */
  isAttributeVisible: boolean;
  viewerFilters: Map<VisualAttributeIdString, string>;
  filterTextsValidities: Map<VisualAttributeIdString, boolean>;
}

interface LocalPropsLevel {
  viewerContext: ViewerContext;
  level: VisualValueChartLevel;
  viewerFilters: Map<VisualAttributeIdString, string>;
  filterTextsValidities: Map<VisualAttributeIdString, boolean>;
  /** if true, attributes and filter line will be displayed */
  isAttributeVisible: boolean;
}

interface LocalPropsBlock {
  viewerContext: ViewerContext;
  level: VisualValueChartLevel;
  /**
   * true if the main interaction block, other blocks are just mirrors without function
   */
  isMainBlock: boolean;
  mirrorTransform: string;
  attDefinitionTransform: string;
  viewerFilters: Map<VisualAttributeIdString, string>;
  filterTextsValidities: Map<VisualAttributeIdString, boolean>;
  /** if true, attributes and filter line will be displayed */
  isAttributeVisible: boolean;

}

class ValueChartLevelHeaderBlock extends React.Component<LocalPropsBlock> {
  render(): JSX.Element {
    const level = this.props.level;
    const levelColor = getLevelDefaultColor(level.index, level.levelCount);
    // customize EAST handle to be in middle ot title area, active on whole title area right border
    const customizedHandleInfo = {
      EAST: {
        y: level.header.y,
        height: DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT
      }
    };
    const textColor = getLevelDefaultTextColor(level.index, level.levelCount);
    return <g transform={this.props.mirrorTransform} data-testselector={"table/" + level.header.name}>
      <TableColumnHeaderComponentDnd viewContext={this.props.viewerContext.getViewContext()} header={level.header}
                                     id={level.id} index={this.props.level.index} backgroundColor={levelColor}
                                     textColor={textColor}/>
      {this.props.isMainBlock ? <HandleComponent scale={this.props.viewerContext.scale}
                                                 EAST={this.onDragHandle}
                                                 visualModel={level.header}
                                                 visualId={this.props.level.id}
                                                 customizedHandleInfo={customizedHandleInfo}
      /> : undefined}
      {this.props.isAttributeVisible ? <g transform={this.props.attDefinitionTransform}>
        <AttributeDefinitionsComponent viewerContext={this.props.viewerContext}
                                       table={this.props.level}
                                       viewerFilters={this.props.viewerFilters}
                                       filterTextsValidities={this.props.filterTextsValidities}
                                       data-value={this.props.level.id.toKey()}/>
      </g> : null}
      {this.props.children}
    </g>;
  }

  @autobind
  private onDragHandle(direction: Direction, dx: number, dy: number): void {
    Dispatcher.dispatch(new ResizeTableAction(this.props.viewerContext.viewId, this.props.level.id, direction, dx, dy));
  }
}

@observer
class ValueChartLevelHeaderComponent extends React.Component<LocalPropsLevel, any> {
  constructor(props: LocalPropsLevel) {
    super(props);
  }

  render(): JSX.Element[] {
    renderLog.debug("Rendering ValueChartLevelHeaderComponent");
    const level = this.props.level;
    return this.renderNestedHeaders(level, 0);
  }

  private renderNestedHeaders(level: VisualValueChartLevel, xOffset: number): JSX.Element[] {
    const result: JSX.Element[] = [];
    const header: VisualHeader = level.header;
    const y = header.y;
    const x = 0; // level.index * (DiagramVisualConstants.VC_LEVEL_INSET + DiagramVisualConstants.VC_GAP);
    const attDefinitionTransform = `translate(${x},${y})`;
    for (let index = 0; index < level.columns; index++) {
      const headerOffset = level.header.width + DiagramVisualConstants.VC_GAP;
      const mirrorXOffset = headerOffset * index;
      const nestedHeaders = level.childLevel ? this.renderNestedHeaders(level.childLevel, 0) : undefined;
      const mirrorTransform = `translate(${mirrorXOffset},0)`;
      result.push(<ValueChartLevelHeaderBlock key={"block" + index} mirrorTransform={mirrorTransform}
                                              viewerContext={this.props.viewerContext}
                                              isMainBlock={index === 0}
                                              level={level}
                                              attDefinitionTransform={attDefinitionTransform}
                                              viewerFilters={this.props.viewerFilters}
                                              filterTextsValidities={this.props.filterTextsValidities}
                                              isAttributeVisible={this.props.isAttributeVisible}
      >
        {nestedHeaders}
      </ValueChartLevelHeaderBlock>);
    }
    return result;
  }
}

// App pure component
export class ValueChartHeaderComponent extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    log.debug("Rendering ValueChartHeaderComponent");
    let result: JSX.Element;
    if (this.props.levels.length > 0) {
      result = <ValueChartLevelHeaderComponent level={this.props.levels[0]}
                                               viewerContext={this.props.viewerContext}
                                               viewerFilters={this.props.viewerFilters}
                                               filterTextsValidities={this.props.filterTextsValidities}
                                               isAttributeVisible={this.props.isAttributeVisible}/>;
    } else {
      // no levels available ==> render dummy text
      result = <SVGTextBoxComponent text="Please drag Tables here" width={200}
                                    height={DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + 2 * DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH}
                                    wrapLines={true}/>;
    }
    return result;
  }
}