import {ModelLocationType, ViewType} from "../../common/constants/Enums";
import {modelStore} from "../../core/stores/ModelStore";
import {viewManagerRegistry} from "../models/ViewManager";
import {configurationStore} from "../../core/stores/ConfigurationStore";

export function createDeepLink(viewId: string, rewriteUrl: boolean = false): string {
  let result = null;
  const viewInfo = viewManagerRegistry.viewManager.getViewInfoById(viewId);
  if (viewInfo != null) {
    const baseUrl = rewriteUrl ? configurationStore.getMetusOnlineUrl() : `${window.location.origin}${window.location.pathname}`;
    const modelLocation = rewriteUrl ? ModelLocationType[ModelLocationType.server] : modelStore.modelInfo.locationString;
    const modelName = rewriteUrl ? configurationStore.modelMeta.name : modelStore.modelInfo.name;
    result = `${baseUrl}#/project/${modelLocation}/${modelName}/view/${ViewType[viewInfo.type]}/${viewInfo.id}`;
  }
  return encodeURI(result);
}

export function getFilterTooltipText(filterAtSupport: boolean): string {
  return filterAtSupport ? `Filter condition is case sensitive.<BR/>
Text is matched as prefix, *Text as contained.<BR/>
Use @ to filter the chart to only show elements connected<BR/>
to elements of this column matching the filter.<BR/>
Examples: car, *car, !car, !*car, &lt;5, >7, =5, &lt;>7, >&lt;3;5, >5@.<BR/>
` :
      `Filter condition is case sensitive.<BR/>
Text is matched as prefix, *Text as contained.<BR/>
Examples: car, *car, !car, !*car, &lt;5, >7, =5, &lt;>7, >&lt;3;5.<BR/>
`;
}
