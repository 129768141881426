import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class MenuToLeftIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <polygon points="8.843 4.3 7.43 5.717 13.729 12.002 7.43 18.288 8.843 19.704 16.56 12.002"/>
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(MenuToLeftIcon);