/* CloseEditorControl.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, August 2018
 */
import * as React from "react";
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";

interface LocalProps {
  onClick: () => void;
}

export class CloseEditorControl extends React.Component<LocalProps> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    return <IconButton onClick={this.props.onClick} data-testselector="CloseEditorButton">
      <Close/>
    </IconButton>;
  }
}

