import {ElementId, ElementObject, VisualTableId} from "../../core/utils/Core";
import {modelStore} from "../../core/stores/ModelStore";
import {elementIdsToPath, ElementPath, leafElementId} from "./MatrixCoreDefinitions";
import {FilteredHierarchy} from "./FilteredHierarchy";

export interface Leaf {
  isLeaf: boolean;
}

export interface Level {
  level: number;
}

export type LeafAndLevel = Leaf & Level;

export interface HierarchyElement {
  level: number;
  element: ElementObject;
  /**
   * full path to this element including all ids of parent elements and this element's id too, this elements id is at the last index
   */
  path: ElementId[];
  isLeaf: boolean;
}

function* connectedElementIterator(elementPath: ElementPath, remainingLevels: VisualTableId[], level: number): IterableIterator<HierarchyElement> {
  const leafId = leafElementId(elementPath);
  const element = modelStore.getElement(leafId);
  const skipChildren = yield {isLeaf: remainingLevels.length === 0, level, element, path: elementPath};
  if (!skipChildren) {
    if (remainingLevels.length > 0) {
      const connectedElementIdsToTable = modelStore.getConnectedElementIdsToTable(leafId, remainingLevels[0].tableId);
      for (const connectedElementId of connectedElementIdsToTable) {
        const path = elementIdsToPath(...elementPath, connectedElementId);
        yield* connectedElementIterator(path, remainingLevels.slice(1), level + 1);
      }
    }
  }
}

export function* hierarchyElements(hierarchy: FilteredHierarchy): IterableIterator<HierarchyElement> {
  let elements: ElementObject[];
  if (hierarchy.tables.length > 0) {
    elements = modelStore.getElementsForTable(hierarchy.tables[0].tableId);
  } else {
    elements = [];
  }
  if (hierarchy.tables.length > 1) {
    const tableId = hierarchy.tables[0];
    const nextLevelTableId = hierarchy.tables[1];
    for (const element of elements) {
      yield* connectedElementIterator([element.id], hierarchy.tables.slice(1), 0) as any;
    }
  } else {
    // zero column tables => elements is empty, nothing yielded
    // one column tables => yield the elements of the first column table
    for (const element of elements) {
      yield {isLeaf: true, level: 0, element, path: [element.id]};
    }
  }
}

/** Iterate over row or column hierarchy and return only those elements, which match the filter or have a child which
 * matches the filter.
 *
 * @param hierarchy
 * @param underlyingIterator
 */
export const filterElements = (hierarchy: FilteredHierarchy, underlyingIterator: IterableIterator<HierarchyElement>): IterableIterator<HierarchyElement> => {
  const elementsFiltered = markElementsFiltered(hierarchy, underlyingIterator,);
  return revealParent(elementsFiltered);
}

export function* filterElementsWithoutRevealingParents(hierarchy: FilteredHierarchy, underlyingIterator: IterableIterator<HierarchyElement>): IterableIterator<HierarchyElement> {
  let itResult;
  let blockedBelowLevel = undefined;
  const filters = hierarchy.getFilterForAllTables();
  while (!(itResult = underlyingIterator.next()).done) {
    const element = itResult.value;
    // only proceed if parent is not filtered
    if (blockedBelowLevel === undefined || element.level <= blockedBelowLevel) {
      const tableId = hierarchy.tables[element.level];
      const filter = filters[element.level];
      const isFilterMatch = filter ? filter.matches([element.element.id]).length === 1 : true;
      if (isFilterMatch) {
        blockedBelowLevel = undefined;
        yield element;
      } else {
        // remember this level in order to ignore all upcoming elements of sublevels
        blockedBelowLevel = element.level;
      }
    }
  }
}

/** Iterate over an underlying iterator and map each element to a HierarchyFilterResult which references the element and
 * contains a flag isFilterMatch.
 *
 * @param hierarchy
 * @param underlyingIterator an underyling iterator which iterates HierarchyElements in a specific order
 */
export function* markElementsFiltered(hierarchy: FilteredHierarchy, underlyingIterator: IterableIterator<HierarchyElement>): IterableIterator<HierarchyFilterResult<HierarchyElement>> {
  let itResult;
  const filters = hierarchy.getFilterForAllTables();
  while (!(itResult = underlyingIterator.next()).done) {
    const element = itResult.value;
    const tableId = hierarchy.tables[element.level];
    const filter = filters[element.level];
    if (filter) {
      const isFilterMatch = filter.matches([element.element.id]).length === 1;
      yield { element, isFilterMatch };
    } else {
      yield { element, isFilterMatch:true };
    }
  }
}

/**
 * yields max leaf elements or empty non-leaf elements of underlying iterator
 * @param iterator
 * @param max
 * @return boolean indicating if there are more elements left in the underyling iterator
 */
export function* limitLeafElements<T extends LeafAndLevel>(iterator: IterableIterator<T>, max: Number): IterableIterator<T> {
  let count = 0;
  let itResult;
  // track empty non-leaf elements
  let lastNonLeafLevel = -1;
  while (!(itResult = iterator.next()).done && count < max) {
    const e = itResult.value;
    if (e.isLeaf) {
      count += 1;
      lastNonLeafLevel = -1;
    } else {
      if (e.level <= lastNonLeafLevel) {
        // the element returned in the last call was an empty non-leaf element
        count += 1;
        if (count === max) {
          break;
        }
      }
      lastNonLeafLevel = e.level;
    }
    yield e as T;
  }
  return !itResult.done; /* are more elements */
}

/** Divides the element of the underlying interator in pages and returns the elements within a certain page.
 *
 * @param underlyingIterator
 * @param pageSize
 * @param pageIndex zero based index of the requested block
 */
export function* elementsInPage<T extends LeafAndLevel>(underlyingIterator: IterableIterator<T>, pageSize: number, pageIndex: number): IterableIterator<T> {
  const firstIgnoredCount = pageSize * pageIndex;
  const max = pageSize * pageIndex + pageSize;
  const iterator = limitLeafElements(underlyingIterator, max);
  let count = 0;
  let isSkippingFirstPages = firstIgnoredCount > 0;
  let itResult;
  // store the hierarchy which might be needed if the first
  // element of any page with index > 0 is not on the top level
  let hierarchy:T[] = [];
  let lastNonLeafLevel = -1;
  while (!(itResult = iterator.next()).done && count < max) {
    const e: T = itResult.value;
    // check if the element returned in the last call was a non-leaf element without children
    if (isSkippingFirstPages) {
      if (e.level <= lastNonLeafLevel) {
        count += 1;
        isSkippingFirstPages = count < firstIgnoredCount;
      }
    }
    if (isSkippingFirstPages) {
      if (e.isLeaf) {
        count += 1;
        lastNonLeafLevel = -1;
      } else {
        lastNonLeafLevel = e.level;
        const targetSize = e.level + 1;
        // remember the hierarchy if needed for first element
        while (hierarchy.length > targetSize - 1) {
          hierarchy.shift();
        }
        hierarchy.unshift(e);
      }
      if (count === firstIgnoredCount) {
        isSkippingFirstPages = false;
      }
    } else {
      if (hierarchy) {
        // before emitting the first element, restore the hierarchy up to the necessary level
        let parent;
        while ((parent = hierarchy.pop()) && parent.level < e.level) {
          yield parent;
        }
        hierarchy = undefined;
      }
      yield e;
    }
  }
  const hasMore: boolean = itResult.done ? itResult.value : true;
  return hasMore;
}


export class HierarchyFilterResult<T> {
  element: T;
  isFilterMatch: boolean; // true if filter matches
}

/**
 * Iterates over the elements of an underlying iterator, which delivers elements of type HierarchyFilterResult.
 *
 * @param underlyingIterator
 * @return a generator which iterates each element of the underyling iterator if isFilterMatch is true or of it has
 * a child where isFilterMatch is true.
 *
 */
export function* revealParent<T extends Level>(underlyingIterator: IterableIterator<HierarchyFilterResult<T>>): IterableIterator<T> {
  let itResult ;
  let hierarchy:T[] = [];
  while (!(itResult = underlyingIterator.next()).done) {
    const filterResult:HierarchyFilterResult<T> = itResult.value;
    const element:T = filterResult.element;
    hierarchy = hierarchy.filter(it => it.level < element.level);
    if (filterResult.isFilterMatch) {
      let parent;
      while ((parent = hierarchy.shift()) !== undefined) {
        yield parent;
      }
      yield filterResult.element;
    }
    else {
      // remember the hierarchy if needed later
      hierarchy.push(element);
    }
  }
}
