import {updateAttributeValueForElement} from "./core/actions/CoreAsyncActionCreators";
import Log from "./common/utils/Logger";
import {Message} from "./websocketApi";
import {ModelDirtyAction, SetWriteLockAction} from "./core/actions/CoreActions";
import {Dispatcher} from "./common/utils/Dispatcher";
import {configurationStore} from "./core/stores/ConfigurationStore";

const log = Log.logger("WebSocketClient");


function getCookie(name): string {
  const pattern = RegExp(name + "=.[^;]*");
  const matched = document.cookie.match(pattern);
  if (matched) {
    const cookie = matched[0].split("=");
    return decodeURIComponent(cookie[1]);
  }
  return null;
}


export function createWebSocketConnection(modelName: string): void {
  const signedCookie = getCookie("metus_session");

  if (signedCookie === null) {
    log.error("WebSocket: there is no session cookie, therefore not opening web socket connection");
    return;
  }

  // Create WebSocket connection.
  const socket = new WebSocket("ws://" + window.location.hostname + ":8080");

  // Register listener to the servers open event. When server opens the connection, send 'initial' message.
  socket.addEventListener("open", function (event: Event): void {
    log.info("WebSocket: connection to server opened, now sending initial message", event);
    const message: Message = {
      kind: "initial",
      signedCookie,
      modelName
    };

    socket.send(JSON.stringify(message));
    log.debug("WebSocket: sending message of kind " + message.kind, message);
  });

  // Register listener for messages from the server
  socket.addEventListener("message", function (event: MessageEvent): void {
    log.debug("WebSocket: message from server", event.data);
    let message: Message;

    try {
      message = JSON.parse(event.data);
    } catch {
      log.debug("WebSocket: invalid message", message);
      return;
    }

    switch (message.kind) {
        // server side websocket, shouldn't return any initial message, so nothing happens in this case
      case "initial":
        break;
      case "acknowledgementInitial":
        log.info("Websocket: server is now connected and will send updates");
        break;
      case "error":
        log.error("Websocket: server sent an error message", message);
        break;
      case "valueUpdate":
        log.debug("WebSocket: got result from server", message);
        if (message.isFormula) {
          updateAttributeValueForElement({
            name: message.attributeName,
            value: message.newValue
          }, message.elementId, message.tableId);
        }
        break;
      case "writeLock":
        log.debug("WebSocket: write lock from other user", message);
        const markDirty = configurationStore.modelMeta.multiuserMode === "merge" && configurationStore.writeLock.locked === "self";
        Dispatcher.dispatch(new SetWriteLockAction({locked: message.locked, committed: message.committed, changeSetId: message.changeSetId}));
        if (markDirty) {
          Dispatcher.dispatch(new ModelDirtyAction());
        }
        break;
      case "dirty":
        log.debug("WebSocket: dirty message arrived");
        Dispatcher.dispatch(new ModelDirtyAction());
        break;
    }
  });

  window.onbeforeunload = function (): void { // close the websocket on refreshing
    socket.onclose = function (): void {
    }; // disable onclose handler first
    log.info("WebSocket: closing the socket!");
    socket.close();
  };

}
