// import autobind from "autobind-decorator";
import {saveAs} from "file-saver";
// import {ZoomHelper} from "../../common/utils/ZoomHelper";
// import {createDeepLink} from "../../common/utils/Util";
import Log from "../../common/utils/Logger";
import {ViewId} from "../../core/utils/Core";

const log = Log.logger("DiagramExport");

/** padding around svg diagram to enabe moving elements outside of original bounds of container */
const PRINT_PADDING_WIDTH: number = 50;

export function saveDiagramAsSvg(nameOfExportedFile: string): void {
  const name: string = nameOfExportedFile + ".svg";
  const {svgXml} = createExportSvgString();
  if (svgXml != null) {
    const blob = new Blob([svgXml], {type: "image/svg+xml"});
    saveAs(blob, name);
  } else {
    log.error("Did not find svg to export in DOM");
    throw Error("Diagram Export as SVG failed");
  }
}

function getActiveSVG(): SVGSVGElement {
  return document.querySelector("[data-active=\"true\"] .editor-container > svg");
}

function createExportSvgString(widthCm?: number, heightCm?: number): { svgXml: string, scaledX: number, scaledY: number } {
  const svg: SVGSVGElement = getActiveSVG(); // query dom
  log.debug("Found active SVG", svg);
  if (svg) {
    // serialize svg content nodes without enclosing svg since this is created with a different scaling and viewBox
    // must be serialized to avoid escape problems, see MO-1997
    const serializer = new XMLSerializer();
    let serialized = "";
    svg.childNodes.forEach(node => serialized += serializer.serializeToString(node));

    // embed svg content into new svg in new window, set size to bit smaller than A4 landscape for ppt import
    const rect: SVGRect = svg.getBBox();

    // calculate aspect ratio if size should be max which fits into 20x15
    // const {scaledX, scaledY} = new ZoomHelper(1, widthCm, heightCm).adaptRatio(rect.width, rect.height);
    // const viewBoxString: string = `${rect.x - PRINT_PADDING_WIDTH} ${rect.y - PRINT_PADDING_WIDTH} ${rect.width + PRINT_PADDING_WIDTH * 2} ${rect.height + PRINT_PADDING_WIDTH * 2}`;

    // Powerpoint (Version 1902) doesn't render the adapted ratio correctly. The lines get way thicker than they should. Changing the stroke-width values/units did not help,
    // thus we simply take the raw pixel values from the svg instead of converting it to a A4 format. (more infos at https://wush.net/jira/apollo/browse/MO-1958)
    const {scaledX, scaledY} = {scaledX: rect.width, scaledY: rect.height};
    const viewBoxRect = {
      x: rect.x - 10,
      y: rect.y - 10,
      width: rect.width + 20,
      height: rect.height + 20
    };
    const viewBoxString: string = `${viewBoxRect.x} ${viewBoxRect.y} ${viewBoxRect.width} ${viewBoxRect.height}`;
    const svgXml: string = `<?xml version="1.0" encoding="UTF-8" ?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${scaledX}" height="${scaledY}" viewBox="${viewBoxString}" version="1.1" >
      <style>
      .handle {
        display:none;
       }
       .clickableAreaFiller {
        display:none;
       }
      </style>` + serialized + "</svg>";
    return {svgXml, scaledX, scaledY};
  } else {
    log.error("Could not find active svg");
  }
  return {svgXml: null, scaledX: 0, scaledY: 0};
}

export function printDiagramInNewWindow(viewId: ViewId): void {
  const {svgXml} = createExportSvgString();
  if (svgXml != null) {
    // VM20170803 data uris are size limited and dont work anymore, thus try blob urls
    const blobUrl = URL.createObjectURL(new Blob([svgXml], {type: "image/svg+xml"}));
    // window title cannot be set without creating a html title tag which is not what we want
    // save as will save the plain svg code
    log.debug("Open Url for print", blobUrl);
    window.open(blobUrl, "height=768,width=1024,left=100,top=100,location=yes,menubar=yes,resizable=yes,scrollbars=yes,properties=yes");
  } else {
    log.error("Did not find svg to print in DOM");
    throw Error("Diagram Printing failed");
  }
}
