import {FilterProperties} from "../../commonviews/components/properties/FilterPropertiesComponent";
import {MatrixModel} from "../models/MatrixModel";
import {matrixStore} from "../stores/MatrixStore";
import {HeaderLayoutProperties} from "../../commonviews/components/properties/HeaderLayoutPropertiesComponent";
import Log from "../../common/utils/Logger";
import {
  IMetusPropertiesProviderContext,
  MetusPropertiesProvider
} from "../../commonviews/contexts/IMetusPropertiesProviderContext";
import {ColumnLimitProperties} from "../components/properties/filter/ColumnLimitComponent";

const log = Log.logger("matrix.propertiesprovider");

export const matrixHeaderlayoutPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): HeaderLayoutProperties => {
  log.debug("Providing header layout properties for selection", context.activeViewId);
  let result: HeaderLayoutProperties = null;
  const view: MatrixModel = matrixStore.getMatrixById(context.activeViewId);

  if (view instanceof MatrixModel && !view.isStructuredTable && !view.isTable) {
    result = {
      activeViewId: context.activeViewId,
      isVertical: view.attributeHeaderRotation !== 0,
      height: view.attributeHeaderHeight,
      width: undefined
    };
  }

  return result;
};

export const matrixFilterPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): FilterProperties => {
  let result: FilterProperties = null;
  const matrix: MatrixModel = matrixStore.getMatrixById(context.activeViewId);
  if (matrix) {
    result = {
      activeViewId: context.activeViewId,
      showConnectedOnly: matrix.showConnectedOnly
    } as FilterProperties;
  }

  return result;
};

export const columnLimitPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): ColumnLimitProperties => {
  let result: ColumnLimitProperties = null;
  const matrix: MatrixModel = matrixStore.getMatrixById(context.activeViewId);
  if (matrix) {
    result = {
      activeViewId: context.activeViewId,
      maxNumberOfColumnsPerPage: matrix.maxColumns
    } as ColumnLimitProperties;
  }

  return result;
};
