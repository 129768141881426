import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class MemoAttributeIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M5,5 L5,19 L19,19 L19,11 L13,5 L5,5 Z M6,6.001 L12,6.001 L12,12 L18,12 L18,18.001 L6,18.001 L6,6.001 Z M13,6.415 L17.586,11 L13,11 L13,6.415 Z"/> ;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(MemoAttributeIcon);