import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../../common/icons/menu/MetusSvgIcon";

class FilteredExpressionIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return  <g id="icon/filter/filterActive">
      <polygon id="FilteredExpressionIcon" points="6 7 11 11.999 11 18 13 16 13 11.999 18 7"></polygon>
    </g>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(FilteredExpressionIcon);