/* EmptyValueChartIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";
import theme from "../../theme/Theme";

class EmptyValueChartIcon extends MetusSvgIcon {


  svg(): JSX.Element {
    const highlightColor = theme.metus.selection.fill
    return <React.Fragment>
      <style type="text/css">{`
        .st0{display:none;}
        .st1{display:inline;fill:#EDEDED;}
        .st2{fill:#D7D7D7;}
        .st3{fill:#A8A8A8;}
        .st4{fill:#EDEDED;}
        .st5{fill:#F6F6F6;}
        .st6{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:11.9781,11.9781;}
        .st7{opacity:0.25;fill:#A8A8A8;}
        .st8{fill:none;stroke:#A8A8A8;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
        .st9{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:12;}
        .st10{display:inline;}
        .st11{fill:#CEE6FE;}
        .st12{fill:${highlightColor};}
        .st13{display:inline;fill:${highlightColor};}
        .st14{display:inline;fill:none;stroke:#24496E;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
        .st15{}
        .st16{font-size:30px;}
        .st17{fill:#D4D4D4;}
        .st18{fill:none;stroke:#A8A8A8;stroke-width:3;stroke-miterlimit:10;}
        .st19{fill:#24496E;}
        .st20{fill:#FFFFFF;}
        .st21{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-miterlimit:10;}
        .st22{fill:#CEE6FE;stroke:${highlightColor};stroke-linecap:round;stroke-miterlimit:10;}
        .st23{fill:#D6C365;}
        .st24{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.0034,12.0034;}
        .st25{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.1971,12.1971;}
        .st26{fill:none;stroke:#D6C365;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.0853,12.0853;}
        .st27{display:inline;fill:#24496E;}
        .st28{opacity:0.8;}
        .st29{opacity:0.3;}
        .st30{fill:#AEBCC9;}
        .st31{opacity:0.81;fill:#D6C365;}
        .st32{opacity:0.2;fill:#9CCDFD;}
      `}</style>
      <g id="BG" className="st0">
        <rect className="st1" width="1043.7" height="868.33"/>
      </g>
      <g id="Ebene_1">
        <g>
          <g>
            <path className="st2" d="M214.21,690.4c22.57,1.09,631.21,0.73,645.14-0.34c7.61-0.59,12.85-3.39,13.8-12.71
				c1.09-10.63,2.34-433.7,1.86-440.69c-0.48-7.01-1.84-13.57-13.11-13.75c-24.31-0.38-618.94,0.79-643.21,2.14
				c-11.11,0.62-15.71,5.13-15.71,14.16c0,3.57-2.1,429.29-1.15,438.15C202.71,685.49,208.86,690.14,214.21,690.4z"/>
            <path className="st3" d="M214.24,689.71c46.89,0.27,93.88,0.34,140.81,0.38l140.85,0.01l281.69-0.46
				c23.47-0.08,46.95-0.14,70.42-0.35l8.78-0.13c2.88-0.03,5.77-0.28,8.34-1.25c1.28-0.48,2.46-1.16,3.44-2.06
				c0.98-0.9,1.74-2.02,2.3-3.26c0.56-1.24,0.92-2.59,1.15-3.98c0.25-1.39,0.25-2.8,0.3-4.26c0.26-11.71,0.34-23.46,0.45-35.19
				c0.38-46.94,0.59-93.89,0.8-140.84l0.47-140.85l0.13-70.42l0-35.21l-0.02-8.8l-0.03-4.4c-0.02-0.72-0.02-1.42-0.05-2.14
				c-0.05-0.72-0.11-1.44-0.2-2.15c-0.32-2.81-1.04-5.62-2.89-7.51c-1.82-1.91-4.61-2.7-7.4-2.92c-1.39-0.12-2.86-0.08-4.33-0.1
				l-4.4-0.02l-8.8-0.02l-17.6-0.01l-35.21,0.02l-70.42,0.1l-140.85,0.31l-281.69,1c-23.47,0.11-46.95,0.21-70.41,0.48
				c-2.93,0.06-5.87,0.07-8.78,0.17c-2.93,0.04-5.79,0.23-8.52,0.97c-2.7,0.73-5.24,2.1-6.76,4.36c-1.56,2.23-2.05,5.11-2.08,7.94
				l-0.17,35.21l-0.64,140.85l-0.52,140.85l-0.15,70.42l0.03,35.21l0.05,8.8l0.06,4.39c0.01,0.74,0.05,1.43,0.07,2.15
				c0,0.36,0.09,0.7,0.13,1.05c0.05,0.35,0.09,0.7,0.19,1.04c0.58,2.76,1.98,5.33,3.99,7.29c1.01,0.97,2.18,1.78,3.45,2.37
				C211.5,689.35,212.88,689.69,214.24,689.71z M214.18,691.08c-1.56-0.13-3.07-0.58-4.45-1.24c-1.4-0.64-2.68-1.51-3.8-2.56
				c-2.22-2.11-3.77-4.91-4.44-7.89c-0.11-0.37-0.16-0.75-0.22-1.12c-0.05-0.38-0.15-0.75-0.15-1.13c-0.03-0.75-0.07-1.52-0.08-2.25
				l-0.06-4.41l-0.06-8.81l-0.06-35.22l0.1-70.43l0.42-140.85l0.54-140.85l0.14-35.21c-0.03-3,0.42-6.25,2.27-8.94
				c0.89-1.34,2.12-2.44,3.45-3.28c1.35-0.84,2.81-1.41,4.29-1.83c2.96-0.81,6.01-1.02,8.94-1.07c2.96-0.1,5.88-0.12,8.82-0.18
				c23.48-0.29,46.95-0.42,70.43-0.55c93.9-0.48,187.8-0.73,281.7-0.96l140.85-0.26l70.42-0.08l35.21-0.01l17.61,0.01l8.8,0.02
				l4.41,0.02c1.47,0.02,2.93-0.01,4.46,0.11c1.51,0.12,3.04,0.41,4.52,0.93c1.46,0.54,2.89,1.36,4,2.54
				c1.12,1.16,1.91,2.59,2.41,4.06c0.51,1.47,0.77,2.98,0.95,4.47c0.09,0.75,0.15,1.49,0.2,2.23c0.04,0.74,0.04,1.51,0.06,2.25
				l0.03,4.41l0.02,8.81l-0.01,35.21l-0.14,70.42l-0.46,140.85c-0.2,46.95-0.4,93.9-0.77,140.85c-0.11,11.74-0.18,23.47-0.44,35.23
				c-0.05,1.47-0.06,3-0.32,4.5c-0.24,1.5-0.63,3-1.27,4.43c-0.64,1.42-1.54,2.77-2.72,3.85c-1.17,1.08-2.57,1.88-4.02,2.43
				c-2.92,1.1-5.98,1.35-8.94,1.38l-8.82,0.13c-23.48,0.24-46.95,0.32-70.43,0.42c-93.9,0.35-187.8,0.37-281.7,0.41l-140.85-0.07
				l-70.43-0.14l-35.21-0.14C237.67,691.47,225.96,691.42,214.18,691.08z"/>
          </g>
          <g>
            <path className="st4" d="M862.64,280.42c-22.65-1.09-597.01-0.76-610.99,0.32c-7.64,0.59-12.9,3.38-13.85,12.71
				c-1.09,10.63-2.33,377.29-1.85,384.28c0.48,7.01,1.85,13.57,13.16,13.75c24.4,0.38,584.69-0.76,609.06-2.11
				c11.15-0.62,15.77-5.13,15.77-14.15c0-3.57,2.09-372.88,1.13-381.74C874.19,285.33,868.02,280.68,862.64,280.42z"/>
            <path className="st3" d="M862.61,281.11c-43.12-0.27-86.34-0.33-129.5-0.38l-129.54-0.02l-259.07,0.41l-64.76,0.25l-16.19,0.13
				l-8.09,0.11c-1.35,0.02-2.66,0.07-3.98,0.13c-1.31,0.11-2.6,0.29-3.85,0.6c-2.49,0.59-4.78,1.79-6.28,3.74
				c-1.51,1.95-2.25,4.47-2.55,7.04c-0.13,1.29-0.16,2.6-0.19,3.96l-0.09,4.04l-0.13,8.09c-0.52,43.17-0.71,86.35-0.94,129.53
				l-0.51,129.53l-0.14,64.77l0,32.38l0.03,8.09c0.01,1.35,0.02,2.71,0.05,4c0.07,1.33,0.2,2.65,0.42,3.93
				c0.42,2.54,1.32,5,3.18,6.57c1.83,1.59,4.38,2.27,6.95,2.47c1.27,0.11,2.63,0.08,3.98,0.1l4.04,0.02l8.09,0.02l16.19,0.01
				l32.38-0.02l64.77-0.09l129.54-0.29l259.07-0.95l64.76-0.37l16.19-0.16l8.07-0.17c1.34-0.04,2.7-0.04,4.03-0.11
				c1.32-0.08,2.63-0.22,3.91-0.45c2.56-0.46,5.06-1.33,6.98-2.94c1.93-1.59,3.06-3.96,3.48-6.5c0.23-1.27,0.29-2.56,0.29-3.91
				l0.02-4.05l0.04-8.1l0.69-129.54l0.56-129.54l0.16-64.77l-0.04-32.38l-0.07-8.09c-0.07-2.71,0.07-5.36-0.79-7.83
				c-0.78-2.46-2.2-4.74-4.17-6.41C867.65,282.27,865.15,281.14,862.61,281.11z M862.68,279.74c2.88,0.25,5.54,1.5,7.7,3.29
				c2.17,1.8,3.76,4.27,4.63,6.94c0.43,1.33,0.74,2.73,0.79,4.12c0.08,1.41,0.07,2.73,0.1,4.09l0.08,8.1l0.06,32.39l-0.11,64.77
				l-0.45,129.54l-0.58,129.54l-0.04,8.1l-0.02,4.05c0,1.35-0.01,2.79-0.24,4.19c-0.39,2.8-1.74,5.67-4.02,7.57
				c-2.27,1.91-5.07,2.86-7.8,3.37c-1.37,0.26-2.75,0.41-4.13,0.5c-1.37,0.07-2.71,0.07-4.07,0.11l-8.12,0.18l-16.2,0.18
				l-64.77,0.44c-86.36,0.44-172.72,0.67-259.08,0.88l-129.54,0.24l-64.77,0.07l-32.39,0.01l-16.19-0.02l-8.1-0.03l-4.05-0.02
				c-1.34-0.01-2.7,0.02-4.11-0.1c-2.75-0.23-5.69-0.95-7.96-2.91c-1.13-0.96-1.99-2.21-2.58-3.53c-0.6-1.32-0.94-2.71-1.18-4.08
				c-0.23-1.38-0.36-2.74-0.43-4.11c-0.04-1.4-0.05-2.74-0.06-4.09l-0.03-8.1l0-32.39l0.14-64.77l0.49-129.54
				c0.23-43.18,0.41-86.36,0.92-129.54l0.13-8.1l0.09-4.05c0.03-1.34,0.07-2.73,0.2-4.12c0.32-2.75,1.11-5.6,2.9-7.94
				c0.9-1.15,2.03-2.13,3.29-2.85c1.26-0.72,2.61-1.22,3.98-1.55c1.37-0.34,2.75-0.53,4.13-0.65c1.37-0.07,2.75-0.12,4.1-0.14
				l8.1-0.11l16.2-0.14l64.77-0.31c86.36-0.29,172.72-0.32,259.08-0.34l129.54,0.09l64.77,0.14l32.39,0.14
				C841.07,279.36,851.84,279.41,862.68,279.74z"/>
          </g>
          <g>
            <path className="st5" d="M558.13,414.65c-22.65,1.17-261.94,0.92-275.92-0.23c-7.64-0.63-12.9-3.63-13.85-13.63
				c-1.09-11.4-1-34.98-0.52-42.48c0.48-7.52,1.85-14.56,13.16-14.75c24.4-0.41,249.62,0.71,273.99,2.15
				c11.15,0.66,15.77,5.5,15.77,15.18c0,3.83,0.76,30.25-0.2,39.75C569.67,409.39,563.5,414.37,558.13,414.65z"/>
            <path className="st3" d="M558.16,415.34c-7.55,0.37-15.05,0.56-22.58,0.62l-22.56,0.18l-45.12,0.19
				c-30.08,0.07-60.15,0.07-90.23,0.01c-30.08-0.08-60.15-0.14-90.24-0.74c-1.88-0.06-3.77-0.1-5.7-0.25
				c-1.92-0.19-3.87-0.53-5.75-1.24c-1.87-0.71-3.66-1.85-4.99-3.42c-1.35-1.55-2.23-3.43-2.77-5.32c-0.55-1.89-0.79-3.87-0.92-5.74
				c-0.16-1.89-0.26-3.78-0.35-5.66c-0.18-3.77-0.27-7.54-0.33-11.3c-0.09-7.54-0.1-15.06,0.19-22.61c0.09-1.89,0.2-3.79,0.48-5.71
				c0.29-1.91,0.74-3.87,1.7-5.68c0.94-1.81,2.46-3.39,4.29-4.33c1.82-0.97,3.81-1.4,5.76-1.59c1.98-0.17,3.82-0.1,5.71-0.14
				l5.65-0.03l11.28-0.02l22.56,0.02l45.11,0.15l90.23,0.55l45.11,0.43c15.04,0.25,30.07,0.44,45.13,0.93
				c1.89,0.09,3.76,0.14,5.66,0.25c1.92,0.15,3.84,0.42,5.73,0.96c1.88,0.53,3.74,1.34,5.34,2.58c1.6,1.23,2.86,2.92,3.63,4.76
				c0.78,1.84,1.14,3.81,1.26,5.74c0.1,1.96,0.05,3.79,0.1,5.68l0.15,11.28c0.05,7.53,0.11,15.05-0.32,22.6
				c-0.13,1.88-0.3,3.83-0.86,5.7c-0.54,1.87-1.38,3.66-2.53,5.25C565.72,412.57,562.2,414.94,558.16,415.34z M558.09,413.97
				c1.74-0.08,3.52-0.59,5.02-1.58c1.51-0.96,2.83-2.24,3.86-3.71c1.04-1.47,1.8-3.14,2.28-4.88c0.49-1.74,0.64-3.55,0.75-5.42
				c0.36-7.46,0.23-14.99,0.14-22.5l-0.23-11.27c-0.07-1.86-0.03-3.81-0.16-5.59c-0.15-1.8-0.52-3.56-1.19-5.16
				c-0.68-1.6-1.73-2.99-3.1-4.04c-1.36-1.06-3.01-1.77-4.73-2.25c-1.73-0.48-3.54-0.74-5.37-0.87c-1.85-0.1-3.74-0.16-5.6-0.24
				c-30.03-0.8-60.12-0.99-90.19-1.3l-90.22-0.61l-45.11-0.18l-22.55-0.04l-11.28,0.01l-5.63,0.03c-1.87,0.04-3.79-0.02-5.57,0.13
				c-1.79,0.17-3.56,0.56-5.09,1.38c-1.54,0.8-2.76,2.05-3.57,3.59c-0.82,1.54-1.25,3.31-1.52,5.11c-0.27,1.81-0.38,3.67-0.47,5.53
				c-0.29,7.48-0.29,15-0.18,22.5c0.06,3.75,0.16,7.5,0.34,11.25c0.09,1.87,0.2,3.74,0.36,5.6c0.13,1.87,0.37,3.66,0.87,5.39
				c0.5,1.72,1.27,3.34,2.41,4.64c1.12,1.32,2.62,2.27,4.27,2.9c1.66,0.63,3.45,0.95,5.27,1.12c1.82,0.14,3.7,0.18,5.57,0.23
				c15.02,0.31,30.06,0.37,45.09,0.46l45.11,0.2c30.07,0.09,60.15,0.12,90.22,0.09C497.97,414.46,528.08,414.34,558.09,413.97z"/>
          </g>
          <g>
            <path className="st5" d="M558.12,481.33c-22.65,1.29-263.15,0.97-277.13-0.25c-7.64-0.67-12.89-3.88-13.84-14.62
				c-1.08-12.24-1.39-24.89-0.56-35.1c0.66-8.06,6.19-15.81,17.5-16.03c24.4-0.46,246.86-2.04,271.22-0.52
				c11.15,0.7,15.55,7.64,15.54,18.03c0,4.11,0.66,23.24-0.31,33.44C569.67,475.67,563.49,481.02,558.12,481.33z"/>
            <path className="st3" d="M558.16,482.01c-3.74,0.23-7.45,0.32-11.17,0.44c-3.72,0.12-7.44,0.17-11.16,0.21l-22.31,0.2l-44.62,0.2
				c-29.74,0.07-59.48,0.08-89.23,0.01c-29.74-0.07-59.48-0.17-89.24-0.7l-5.59-0.15c-1.85-0.07-3.76-0.1-5.67-0.39
				c-1.9-0.29-3.83-0.79-5.61-1.72c-1.78-0.93-3.35-2.33-4.45-4c-2.23-3.38-2.77-7.4-3.03-11.09c-0.31-3.72-0.53-7.44-0.69-11.17
				c-0.29-7.46-0.31-14.93,0.26-22.4c0.28-3.85,1.65-7.72,4.18-10.75c2.5-3.07,6.17-5.07,9.97-5.84c1.89-0.4,3.9-0.49,5.72-0.49
				l5.58-0.08l11.15-0.13l22.31-0.21l44.61-0.34l89.23-0.43c29.75-0.06,59.48-0.18,89.24,0.44c1.86,0.04,3.73,0.14,5.6,0.2
				c1.84,0.06,3.78,0.14,5.68,0.58c1.89,0.4,3.76,1.09,5.44,2.12c1.68,1.03,3.14,2.45,4.23,4.1c2.2,3.32,2.89,7.28,3,11.05
				l0.14,11.17c0.05,7.44,0.12,14.89-0.45,22.36c-0.21,3.75-1.28,7.63-3.52,10.78c-1.11,1.58-2.5,2.97-4.13,4.03
				C562.03,481.07,560.16,481.84,558.16,482.01z M558.08,480.64c1.72-0.06,3.44-0.65,4.92-1.63c1.48-0.98,2.75-2.28,3.76-3.75
				c2.04-2.96,3.01-6.53,3.17-10.17c0.27-3.68,0.32-7.39,0.34-11.09c0.01-3.71-0.03-7.42-0.1-11.13l-0.25-11.13
				c-0.17-3.59-0.87-7.22-2.8-10.11c-0.96-1.44-2.21-2.65-3.69-3.55c-1.48-0.9-3.14-1.51-4.88-1.87c-1.73-0.39-3.53-0.46-5.39-0.52
				c-1.85-0.06-3.69-0.16-5.55-0.19c-14.85-0.31-29.72-0.33-44.59-0.39l-44.61,0l-89.22,0.36l-44.61,0.3l-22.3,0.19l-11.15,0.12
				l-5.57,0.08c-1.89,0-3.63,0.08-5.39,0.45c-3.48,0.7-6.74,2.49-8.98,5.22c-2.27,2.71-3.53,6.2-3.79,9.76
				c-0.58,7.36-0.56,14.79-0.25,22.19c0.16,3.7,0.39,7.4,0.7,11.1c0.14,1.87,0.32,3.64,0.74,5.39c0.41,1.74,1.05,3.41,2.01,4.85
				c0.96,1.44,2.26,2.6,3.79,3.4c1.54,0.8,3.27,1.26,5.04,1.52c1.77,0.27,3.61,0.29,5.46,0.36l5.56,0.14
				c14.86,0.25,29.73,0.33,44.6,0.42l44.61,0.21c29.74,0.1,59.48,0.13,89.22,0.08C498.63,481.19,528.41,481.07,558.08,480.64z"/>
          </g>
          <g>
            <path className="st5" d="M583.73,416.07c22.66-1.15,261.95,0.35,275.92,1.65c7.64,0.72,12.87,3.96,13.76,14.7
				c1.01,12.24,2.1,27.35,1.57,35.4c-0.53,8.07-1.94,15.62-13.26,15.76c-24.4,0.31-251.19,0.68-275.55-0.99
				c-11.14-0.77-15.51-7.73-15.45-18.12c0.03-4.11-0.54-23.25,0.48-33.44C572.15,421.66,578.35,416.34,583.73,416.07z"/>
            <path className="st3" d="M583.69,415.38c7.47-0.34,14.91-0.51,22.35-0.54l22.33-0.08l44.66,0.03c29.77,0.08,59.55,0.23,89.32,0.44
				c29.78,0.23,59.54,0.46,89.33,1.18c1.86,0.05,3.73,0.11,5.6,0.19c1.88,0.09,3.8,0.21,5.71,0.65c1.9,0.42,3.8,1.13,5.45,2.32
				c1.66,1.16,2.98,2.8,3.86,4.58c1.81,3.57,2.01,7.58,2.29,11.22c0.29,3.71,0.57,7.43,0.8,11.15c0.24,3.72,0.43,7.45,0.55,11.18
				c0.11,3.73,0.17,7.49-0.12,11.26c-0.15,1.88-0.36,3.78-0.81,5.67c-0.46,1.88-1.18,3.78-2.43,5.41c-1.23,1.63-3.01,2.85-4.89,3.52
				c-1.89,0.69-3.86,0.93-5.79,0.97c-29.8,0.24-59.55,0.25-89.33,0.3l-89.32-0.07c-29.78-0.1-59.54-0.15-89.33-0.94
				c-1.86-0.05-3.74-0.16-5.6-0.23c-1.85-0.07-3.78-0.15-5.68-0.6c-1.89-0.4-3.76-1.09-5.45-2.13c-1.68-1.04-3.14-2.46-4.23-4.1
				c-2.2-3.32-2.88-7.29-2.98-11.06l-0.08-11.18c-0.01-7.45-0.04-14.91,0.56-22.38c0.23-3.76,1.31-7.63,3.57-10.78
				c1.12-1.57,2.52-2.96,4.16-4.01C579.81,416.31,581.68,415.54,583.69,415.38z M583.76,416.75c-1.72,0.04-3.45,0.63-4.93,1.6
				c-1.48,0.98-2.76,2.26-3.78,3.73c-2.06,2.96-3.04,6.52-3.22,10.17c-0.28,3.68-0.36,7.39-0.39,11.1
				c-0.03,3.71-0.01,7.43,0.04,11.14c0.43,7.33-0.94,15.31,2.97,21.26c0.96,1.44,2.22,2.65,3.69,3.56c1.48,0.9,3.15,1.52,4.88,1.88
				c1.73,0.4,3.53,0.47,5.4,0.54c1.85,0.07,3.69,0.18,5.55,0.22c14.86,0.39,29.75,0.49,44.63,0.63l44.65,0.24l89.31,0.14
				c29.77-0.03,59.55-0.02,89.3-0.24c1.79-0.04,3.58-0.26,5.21-0.86c1.63-0.58,3.07-1.57,4.1-2.93c1.04-1.35,1.7-3.01,2.12-4.74
				c0.42-1.74,0.63-3.56,0.78-5.39c0.29-3.66,0.23-7.36,0.13-11.07c-0.12-3.71-0.32-7.41-0.56-11.12
				c-0.24-3.71-0.52-7.41-0.83-11.12c-0.17-1.86-0.25-3.72-0.56-5.48c-0.3-1.77-0.78-3.51-1.57-5.07c-0.78-1.56-1.89-2.92-3.3-3.91
				c-1.4-1-3.06-1.63-4.8-2.01c-1.74-0.4-3.55-0.52-5.39-0.6c-1.85-0.08-3.7-0.14-5.56-0.18c-14.87-0.36-29.76-0.51-44.64-0.67
				l-44.65-0.43c-29.77-0.25-59.54-0.43-89.31-0.54C643.28,416.52,613.47,416.48,583.76,416.75z"/>
          </g>
          <g>
            <path className="st5" d="M861.03,415.34c-22.65,1.17-261.94,0.92-275.92-0.23c-7.64-0.63-12.9-3.63-13.85-13.63
				c-1.09-11.4-1-34.98-0.52-42.48c0.48-7.52,1.85-14.56,13.16-14.75c24.4-0.41,249.62,0.71,273.99,2.15
				c11.15,0.66,15.77,5.5,15.77,15.18c0,3.83,0.76,30.25-0.2,39.75C872.57,410.08,866.4,415.06,861.03,415.34z"/>
            <path className="st3" d="M861.06,416.03c-7.55,0.37-15.05,0.56-22.58,0.62l-22.56,0.18l-45.12,0.18
				c-30.08,0.07-60.15,0.07-90.23,0.01c-30.08-0.08-60.15-0.14-90.24-0.74c-1.88-0.06-3.77-0.1-5.7-0.25
				c-1.92-0.19-3.87-0.53-5.75-1.24c-1.87-0.71-3.66-1.85-4.99-3.42c-1.35-1.55-2.23-3.43-2.77-5.32c-0.55-1.89-0.79-3.87-0.92-5.74
				c-0.16-1.89-0.26-3.78-0.35-5.66c-0.18-3.77-0.27-7.54-0.33-11.3c-0.09-7.54-0.1-15.06,0.19-22.61c0.09-1.89,0.2-3.79,0.48-5.71
				c0.29-1.91,0.74-3.87,1.7-5.68c0.94-1.81,2.46-3.39,4.29-4.33c1.82-0.97,3.81-1.4,5.76-1.59c1.98-0.17,3.82-0.1,5.71-0.14
				l5.64-0.03l11.28-0.02l22.56,0.02l45.11,0.15l90.23,0.55l45.11,0.43c15.04,0.25,30.07,0.44,45.13,0.93
				c1.89,0.09,3.76,0.14,5.66,0.25c1.92,0.15,3.84,0.42,5.73,0.96c1.88,0.53,3.74,1.34,5.34,2.58c1.6,1.23,2.86,2.92,3.63,4.76
				c0.78,1.84,1.14,3.81,1.26,5.74c0.1,1.96,0.05,3.79,0.1,5.68l0.15,11.28c0.05,7.53,0.11,15.05-0.32,22.6
				c-0.13,1.88-0.3,3.83-0.86,5.7c-0.54,1.87-1.38,3.66-2.53,5.25C868.62,413.26,865.1,415.63,861.06,416.03z M860.99,414.66
				c1.74-0.08,3.52-0.59,5.02-1.58c1.51-0.96,2.83-2.24,3.86-3.71c1.04-1.47,1.8-3.14,2.28-4.88c0.49-1.74,0.64-3.55,0.75-5.42
				c0.36-7.46,0.23-14.99,0.14-22.5l-0.23-11.27c-0.07-1.86-0.03-3.81-0.16-5.59c-0.15-1.8-0.52-3.56-1.19-5.16
				c-0.68-1.6-1.73-2.99-3.1-4.04c-1.36-1.06-3.01-1.77-4.73-2.25c-1.73-0.48-3.54-0.74-5.37-0.87c-1.85-0.1-3.74-0.16-5.6-0.24
				c-30.03-0.8-60.12-0.99-90.19-1.3l-90.22-0.61l-45.11-0.18l-22.55-0.04l-11.28,0.01l-5.63,0.03c-1.87,0.04-3.79-0.02-5.57,0.13
				c-1.79,0.17-3.56,0.56-5.09,1.38c-1.54,0.8-2.76,2.05-3.57,3.59c-0.82,1.54-1.25,3.31-1.52,5.11c-0.27,1.81-0.38,3.67-0.47,5.53
				c-0.29,7.48-0.29,15-0.18,22.5c0.06,3.75,0.16,7.5,0.34,11.25c0.09,1.87,0.2,3.74,0.36,5.6c0.13,1.87,0.37,3.66,0.87,5.39
				c0.5,1.72,1.27,3.34,2.41,4.64c1.12,1.32,2.62,2.27,4.27,2.9c1.66,0.63,3.45,0.95,5.27,1.12c1.82,0.14,3.7,0.18,5.57,0.23
				c15.02,0.31,30.06,0.37,45.09,0.46l45.11,0.2c30.07,0.09,60.15,0.12,90.22,0.09C800.87,415.15,830.98,415.03,860.99,414.66z"/>
          </g>
          <g>
            <path className="st5" d="M558.13,552.16c-22.65,1.17-261.94,0.92-275.92-0.23c-7.64-0.63-12.9-3.63-13.85-13.63
				c-1.09-11.4-1-34.98-0.52-42.48c0.48-7.52,1.85-14.56,13.16-14.75c24.4-0.41,249.62,0.71,273.99,2.15
				c11.15,0.66,15.77,5.5,15.77,15.18c0,3.83,0.76,30.25-0.2,39.75C569.67,546.9,563.5,551.88,558.13,552.16z"/>
            <path className="st3" d="M558.16,552.84c-7.55,0.37-15.05,0.56-22.58,0.62l-22.56,0.18l-45.12,0.18
				c-30.08,0.07-60.15,0.07-90.23,0.01c-30.08-0.08-60.15-0.14-90.24-0.74c-1.88-0.06-3.77-0.1-5.7-0.25
				c-1.92-0.19-3.87-0.53-5.75-1.24c-1.87-0.71-3.66-1.85-4.99-3.42c-1.35-1.55-2.23-3.43-2.77-5.32c-0.55-1.89-0.79-3.87-0.92-5.74
				c-0.16-1.89-0.26-3.78-0.35-5.66c-0.18-3.77-0.27-7.54-0.33-11.3c-0.09-7.54-0.1-15.06,0.19-22.61c0.09-1.89,0.2-3.79,0.48-5.71
				c0.29-1.91,0.74-3.87,1.7-5.68c0.94-1.81,2.46-3.39,4.29-4.33c1.82-0.97,3.81-1.4,5.76-1.59c1.98-0.17,3.82-0.1,5.71-0.14
				l5.65-0.03l11.28-0.02l22.56,0.02l45.11,0.15l90.23,0.55l45.11,0.43c15.04,0.25,30.07,0.44,45.13,0.93
				c1.89,0.09,3.76,0.14,5.66,0.25c1.92,0.15,3.84,0.42,5.73,0.96c1.88,0.53,3.74,1.34,5.34,2.58c1.6,1.23,2.86,2.92,3.63,4.76
				c0.78,1.84,1.14,3.81,1.26,5.74c0.1,1.96,0.05,3.79,0.1,5.68l0.15,11.28c0.05,7.53,0.11,15.05-0.32,22.6
				c-0.13,1.88-0.3,3.83-0.86,5.7c-0.54,1.87-1.38,3.66-2.53,5.25C565.72,550.08,562.2,552.45,558.16,552.84z M558.09,551.47
				c1.74-0.08,3.52-0.59,5.02-1.58c1.51-0.96,2.83-2.24,3.86-3.71c1.04-1.47,1.8-3.14,2.28-4.88c0.49-1.74,0.64-3.55,0.75-5.42
				c0.36-7.46,0.23-14.99,0.14-22.5l-0.23-11.27c-0.07-1.86-0.03-3.81-0.16-5.59c-0.15-1.8-0.52-3.56-1.19-5.16
				c-0.68-1.6-1.73-2.99-3.1-4.04c-1.36-1.06-3.01-1.77-4.73-2.25c-1.73-0.48-3.54-0.74-5.37-0.87c-1.85-0.1-3.74-0.16-5.6-0.24
				c-30.03-0.8-60.12-0.99-90.19-1.3l-90.22-0.61l-45.11-0.18l-22.55-0.04l-11.28,0.01l-5.63,0.03c-1.87,0.04-3.79-0.02-5.57,0.13
				c-1.79,0.17-3.56,0.56-5.09,1.38c-1.54,0.8-2.76,2.05-3.57,3.59c-0.82,1.54-1.25,3.31-1.52,5.11c-0.27,1.81-0.38,3.67-0.47,5.53
				c-0.29,7.48-0.29,15-0.18,22.5c0.06,3.75,0.16,7.5,0.34,11.25c0.09,1.87,0.2,3.74,0.36,5.6c0.13,1.87,0.37,3.66,0.87,5.39
				c0.5,1.72,1.27,3.34,2.41,4.64c1.12,1.32,2.62,2.27,4.27,2.9c1.66,0.63,3.45,0.95,5.27,1.12c1.82,0.14,3.7,0.18,5.57,0.23
				c15.02,0.31,30.06,0.37,45.09,0.46l45.11,0.2c30.07,0.09,60.15,0.12,90.22,0.09C497.97,551.97,528.08,551.84,558.09,551.47z"/>
          </g>
          <g>
            <path className="st5" d="M558.12,621.18c-22.65,1.33-261.94,1.31-275.92,0.04c-7.64-0.69-12.89-4.02-13.84-15.13
				c-1.08-12.66-2.24-28.29-1.76-36.63c0.49-8.35,1.86-16.18,13.17-16.4c24.4-0.48,251.19-2.3,275.55-0.73
				c11.15,0.72,15.55,7.9,15.54,18.66c0,4.26,0.66,24.05-0.31,34.61C569.67,615.32,563.49,620.86,558.12,621.18z"/>
            <path className="st3" d="M558.16,621.86c-7.52,0.4-14.99,0.62-22.48,0.69l-22.46,0.23l-44.93,0.25c-29.95,0.11-59.9,0.14-89.85,0.11
				c-29.95-0.06-59.89-0.1-89.86-0.69c-1.88-0.05-3.74-0.09-5.66-0.21c-1.91-0.1-3.86-0.4-5.75-1.01c-1.88-0.62-3.72-1.62-5.16-3.06
				c-1.45-1.44-2.48-3.23-3.17-5.07c-0.69-1.85-1.07-3.77-1.3-5.68c-0.21-1.93-0.33-3.75-0.49-5.63c-0.3-3.73-0.58-7.47-0.82-11.21
				c-0.24-3.74-0.44-7.49-0.57-11.25c-0.11-3.75-0.18-7.53,0.11-11.32c0.14-1.89,0.35-3.8,0.8-5.7c0.45-1.89,1.15-3.8,2.37-5.46
				c1.2-1.66,2.95-2.94,4.84-3.65c1.89-0.73,3.87-1,5.81-1.05c29.97-0.44,59.9-0.65,89.86-0.88l89.85-0.49
				c29.95-0.08,59.89-0.22,89.87,0.44l5.64,0.23c1.9,0.08,3.86,0.37,5.73,0.95c1.88,0.58,3.68,1.5,5.22,2.76
				c1.55,1.26,2.78,2.89,3.66,4.64c1.78,3.55,2.22,7.49,2.21,11.28l0.14,11.22c0.04,7.5,0.09,14.99-0.48,22.51
				c-0.24,3.79-1.31,7.66-3.51,10.86c-1.1,1.6-2.47,3.02-4.09,4.12C562.05,620.88,560.18,621.68,558.16,621.86z M558.08,620.49
				c1.72-0.06,3.46-0.69,4.92-1.7c1.47-1.02,2.73-2.34,3.73-3.84c2.01-3.01,2.99-6.61,3.18-10.27c0.5-7.42,0.38-14.91,0.27-22.38
				l-0.23-11.24c-0.02-1.83-0.16-3.65-0.49-5.42c-0.33-1.77-0.83-3.49-1.64-5.06c-0.79-1.56-1.87-2.97-3.22-4.06
				c-1.35-1.09-2.93-1.9-4.62-2.41c-1.7-0.52-3.47-0.79-5.31-0.85l-5.58-0.21c-14.94-0.36-29.93-0.34-44.89-0.41l-44.92,0.03
				l-89.84,0.43c-29.94,0.22-59.9,0.4-89.82,0.82c-1.8,0.05-3.6,0.3-5.22,0.93c-1.63,0.62-3.04,1.65-4.06,3.04
				c-1.02,1.38-1.66,3.06-2.08,4.81c-0.41,1.75-0.62,3.58-0.76,5.43c-0.28,3.69-0.22,7.41-0.11,11.14
				c0.12,3.73,0.34,7.46,0.58,11.19c0.25,3.73,0.54,7.46,0.84,11.19c0.16,1.85,0.29,3.76,0.49,5.56c0.22,1.81,0.58,3.6,1.21,5.26
				c0.62,1.66,1.52,3.2,2.75,4.41c1.22,1.22,2.78,2.07,4.46,2.62c3.38,1.11,7.15,0.96,10.88,1.11c14.95,0.31,29.93,0.36,44.9,0.44
				l44.92,0.17c29.95,0.07,59.89,0.07,89.84-0.01C498.21,621.12,528.2,620.98,558.08,620.49z"/>
          </g>
          <g>
            <path className="st5" d="M279.48,622.16c22.9-1.33,264.79-1.27,278.92,0c7.72,0.69,13.03,4.02,13.99,15.13
				c1.09,12.66,2.27,28.29,1.78,36.63c-0.49,8.35-1.88,16.18-13.31,16.4c-24.66,0.47-253.91,2.27-278.54,0.68
				c-11.27-0.72-15.72-7.91-15.71-18.66c0-4.26-0.67-24.05,0.3-34.61C267.8,628.02,274.05,622.48,279.48,622.16z"/>
            <path className="st3" d="M279.44,621.48c7.58-0.4,15.12-0.61,22.68-0.69l22.67-0.22l45.33-0.24c30.22-0.1,60.44-0.13,90.65-0.09
				c30.22,0.06,60.43,0.11,90.67,0.69c1.89,0.04,3.78,0.1,5.69,0.19c1.92,0.07,3.88,0.33,5.8,0.88c1.91,0.55,3.8,1.46,5.33,2.84
				c1.54,1.37,2.68,3.13,3.43,4.98c0.76,1.85,1.19,3.79,1.45,5.71c0.25,1.95,0.35,3.8,0.52,5.69c0.31,3.77,0.6,7.54,0.85,11.31
				c0.25,3.78,0.46,7.56,0.59,11.35c0.12,3.79,0.19,7.6-0.09,11.42c-0.14,1.91-0.35,3.83-0.8,5.75c-0.45,1.91-1.17,3.84-2.4,5.5
				c-1.22,1.67-2.99,2.94-4.9,3.65c-1.91,0.73-3.91,0.99-5.86,1.05c-30.24,0.44-60.44,0.63-90.66,0.87l-90.65,0.48
				c-30.22,0.08-60.43,0.21-90.67-0.44l-5.69-0.23c-1.89-0.05-3.88-0.29-5.78-0.82c-1.9-0.52-3.76-1.36-5.36-2.57
				c-1.61-1.2-2.95-2.77-3.9-4.52c-1.92-3.52-2.46-7.51-2.48-11.34l-0.14-11.33c-0.05-7.56-0.11-15.13,0.47-22.71
				c0.23-3.82,1.31-7.73,3.54-10.96c1.11-1.61,2.51-3.04,4.15-4.14C275.51,622.46,277.4,621.65,279.44,621.48z M279.52,622.85
				c-1.74,0.06-3.49,0.68-4.98,1.7c-1.49,1.02-2.77,2.35-3.78,3.85c-2.04,3.03-3.03,6.67-3.21,10.36
				c-0.5,7.48-0.37,15.05-0.26,22.58l0.24,11.33c0.03,1.84,0.2,3.67,0.58,5.45c0.35,1.78,0.93,3.49,1.78,5.04
				c1.68,3.13,4.72,5.31,8.19,6.2c1.74,0.48,3.52,0.69,5.4,0.74l5.63,0.21c30.18,0.55,60.41,0.41,90.62,0.37l90.64-0.41
				c30.21-0.21,60.44-0.39,90.63-0.8c1.82-0.05,3.63-0.29,5.27-0.92c1.65-0.61,3.08-1.65,4.11-3.05c1.04-1.39,1.69-3.09,2.11-4.85
				c0.42-1.77,0.62-3.61,0.76-5.47c0.28-3.72,0.21-7.48,0.1-11.24c-0.13-3.76-0.35-7.52-0.6-11.29c-0.25-3.76-0.55-7.52-0.87-11.28
				c-0.17-1.87-0.29-3.79-0.52-5.6c-0.24-1.82-0.66-3.61-1.34-5.26c-0.68-1.65-1.66-3.16-2.97-4.31c-1.3-1.17-2.91-1.95-4.63-2.44
				c-1.73-0.49-3.55-0.73-5.4-0.8c-1.86-0.09-3.75-0.14-5.63-0.18c-15.09-0.3-30.2-0.36-45.31-0.44l-45.32-0.17
				c-30.21-0.07-60.43-0.08-90.64,0C339.92,622.23,309.66,622.37,279.52,622.85z"/>
          </g>
          <g>
            <path className="st5" d="M583.73,553.64c22.66-1.19,261.95,0.36,275.92,1.71c7.64,0.74,12.87,4.1,13.76,15.21
				c1.01,12.67,2.1,28.31,1.57,36.64c-0.53,8.35-1.94,16.16-13.26,16.31c-24.4,0.32-251.19,0.71-275.55-1.03
				c-11.14-0.79-15.51-8-15.45-18.76c0.03-4.26-0.54-24.06,0.48-34.61C572.15,559.42,578.35,553.92,583.73,553.64z"/>
            <path className="st3" d="M583.69,552.95c7.52-0.36,14.99-0.52,22.49-0.55l22.47-0.08l44.93,0.04c29.95,0.08,59.9,0.24,89.85,0.47
				c29.95,0.25,59.89,0.48,89.86,1.26c1.88,0.06,3.74,0.12,5.65,0.25c1.91,0.12,3.85,0.42,5.74,1.04c1.88,0.63,3.71,1.64,5.14,3.09
				c1.45,1.44,2.47,3.24,3.14,5.09c0.68,1.86,1.05,3.78,1.27,5.69c0.2,1.93,0.31,3.75,0.46,5.63c0.28,3.74,0.54,7.48,0.76,11.22
				c0.22,3.74,0.4,7.49,0.51,11.25c0.09,3.75,0.14,7.53-0.17,11.32c-0.15,1.89-0.37,3.8-0.82,5.7c-0.46,1.89-1.17,3.8-2.4,5.45
				c-1.21,1.66-2.97,2.92-4.86,3.62c-1.9,0.72-3.88,0.97-5.82,1.02c-29.98,0.25-59.91,0.26-89.86,0.31l-89.85-0.08
				c-29.95-0.11-59.89-0.16-89.87-1.02l-5.64-0.26c-1.9-0.09-3.86-0.4-5.72-0.99c-1.87-0.59-3.67-1.52-5.21-2.79
				c-1.54-1.27-2.76-2.9-3.64-4.66c-1.76-3.56-2.19-7.5-2.15-11.29l-0.08-11.22c0-7.5-0.02-14.99,0.6-22.51
				c0.26-3.79,1.35-7.65,3.56-10.85c1.1-1.59,2.49-3.01,4.12-4.1C579.79,553.91,581.67,553.12,583.69,552.95z M583.76,554.32
				c-1.72,0.05-3.46,0.66-4.93,1.67c-1.48,1.01-2.74,2.33-3.75,3.82c-2.03,3-3.02,6.6-3.23,10.26c-0.53,7.41-0.45,14.91-0.39,22.38
				l0.17,11.24c0.01,1.83,0.14,3.65,0.47,5.42c0.32,1.77,0.81,3.5,1.61,5.06c0.79,1.57,1.85,2.98,3.2,4.08
				c1.34,1.1,2.92,1.92,4.61,2.44c1.7,0.53,3.46,0.81,5.3,0.89l5.58,0.25c14.94,0.45,29.92,0.53,44.89,0.7l44.92,0.26l89.84,0.14
				c29.94-0.03,59.91-0.02,89.83-0.25c1.8-0.04,3.6-0.27,5.22-0.89c1.63-0.61,3.05-1.63,4.07-3.02c1.03-1.38,1.68-3.06,2.1-4.8
				c0.42-1.75,0.64-3.58,0.79-5.42c0.3-3.68,0.26-7.41,0.17-11.14c-0.1-3.73-0.3-7.46-0.52-11.19c-0.23-3.73-0.5-7.46-0.79-11.19
				c-0.15-1.85-0.27-3.76-0.46-5.56c-0.21-1.81-0.56-3.6-1.19-5.27c-0.61-1.67-1.51-3.21-2.73-4.43c-1.22-1.23-2.77-2.09-4.44-2.65
				c-3.37-1.13-7.15-1-10.87-1.18c-14.95-0.4-29.93-0.55-44.9-0.73l-44.92-0.45c-29.95-0.26-59.89-0.45-89.84-0.56
				C643.63,554.08,613.64,554.03,583.76,554.32z"/>
          </g>
          <g>
            <path className="st5" d="M861.03,552.85c-22.65,1.17-261.94,0.92-275.92-0.23c-7.64-0.63-12.9-3.63-13.85-13.63
				c-1.09-11.4-1-34.98-0.52-42.48c0.48-7.52,1.85-14.56,13.16-14.75c24.4-0.41,249.62,0.71,273.99,2.15
				c11.15,0.66,15.77,5.5,15.77,15.18c0,3.83,0.76,30.25-0.2,39.75C872.57,547.59,866.4,552.57,861.03,552.85z"/>
            <path className="st3" d="M861.06,553.53c-7.55,0.37-15.05,0.56-22.58,0.62l-22.56,0.18l-45.12,0.19
				c-30.08,0.07-60.15,0.07-90.23,0.01c-30.08-0.08-60.15-0.14-90.24-0.74c-1.88-0.06-3.77-0.1-5.7-0.25
				c-1.92-0.19-3.87-0.53-5.75-1.24c-1.87-0.71-3.66-1.85-4.99-3.42c-1.35-1.55-2.23-3.43-2.77-5.32c-0.55-1.89-0.79-3.87-0.92-5.74
				c-0.16-1.89-0.26-3.78-0.35-5.66c-0.18-3.77-0.27-7.54-0.33-11.3c-0.09-7.54-0.1-15.06,0.19-22.61c0.09-1.89,0.2-3.79,0.48-5.71
				c0.29-1.91,0.74-3.87,1.7-5.68c0.94-1.81,2.46-3.39,4.29-4.33c1.82-0.97,3.81-1.4,5.76-1.59c1.98-0.17,3.82-0.1,5.71-0.14
				l5.64-0.03l11.28-0.02l22.56,0.02l45.11,0.15l90.23,0.55l45.11,0.43c15.04,0.25,30.07,0.44,45.13,0.93
				c1.89,0.09,3.76,0.14,5.66,0.25c1.92,0.15,3.84,0.42,5.73,0.96c1.88,0.53,3.74,1.34,5.34,2.58c1.6,1.23,2.86,2.92,3.63,4.76
				c0.78,1.84,1.14,3.81,1.26,5.74c0.1,1.96,0.05,3.79,0.1,5.68l0.15,11.28c0.05,7.53,0.11,15.05-0.32,22.6
				c-0.13,1.88-0.3,3.83-0.86,5.7c-0.54,1.87-1.38,3.66-2.53,5.25C868.62,550.77,865.1,553.14,861.06,553.53z M860.99,552.16
				c1.74-0.08,3.52-0.59,5.02-1.58c1.51-0.96,2.83-2.24,3.86-3.71c1.04-1.47,1.8-3.14,2.28-4.88c0.49-1.74,0.64-3.55,0.75-5.42
				c0.36-7.46,0.23-14.99,0.14-22.5l-0.23-11.27c-0.07-1.86-0.03-3.81-0.16-5.59c-0.15-1.8-0.52-3.56-1.19-5.16
				c-0.68-1.6-1.73-2.99-3.1-4.04c-1.36-1.06-3.01-1.77-4.73-2.25c-1.73-0.48-3.54-0.74-5.37-0.87c-1.85-0.1-3.74-0.16-5.6-0.24
				c-30.03-0.8-60.12-0.99-90.19-1.3l-90.22-0.61l-45.11-0.18l-22.55-0.04l-11.28,0.01l-5.63,0.03c-1.87,0.04-3.79-0.02-5.57,0.13
				c-1.79,0.17-3.56,0.56-5.09,1.38c-1.54,0.8-2.76,2.05-3.57,3.59c-0.82,1.54-1.25,3.31-1.52,5.11c-0.27,1.81-0.38,3.67-0.47,5.53
				c-0.29,7.48-0.29,15-0.18,22.5c0.06,3.75,0.16,7.5,0.34,11.25c0.09,1.87,0.2,3.74,0.36,5.6c0.13,1.87,0.37,3.66,0.87,5.39
				c0.5,1.72,1.27,3.34,2.41,4.64c1.12,1.32,2.62,2.27,4.27,2.9c1.66,0.63,3.45,0.95,5.27,1.12c1.82,0.14,3.7,0.18,5.57,0.23
				c15.02,0.31,30.06,0.37,45.09,0.46l45.11,0.2c30.07,0.09,60.15,0.12,90.22,0.09C800.87,552.66,830.98,552.53,860.99,552.16z"/>
          </g>
        </g>
        <g>
          <path className="st6" d="M371.65,197.1c-55.41,0.14-137.15-0.58-156.64-0.97c-28.53-0.57-44.5,6.86-46.44,44.14
			c-1.94,37.28-5.2,400.19-0.62,441.88c3.88,35.32,17.76,37.01,48.8,38.97c38.91,2.46,624.53-3.46,649.83-3.46
			c36.86,0,40.46-20.23,40.46-59.47c0-25.81,2.98-390.83,3.77-416.32c1-32.3-7.98-47.45-42.91-47.45
			C832.97,194.41,517.13,195.13,371.65,197.1z"/>
        </g>
      </g>
      <g id="Ebene_8">
        <g>
          <path className="st3" d="M858.79,115.56c0.13-1,0.5-1.93,0.94-2.83c0.44-0.9,0.95-1.77,1.54-2.6c1.18-1.64,2.72-3.11,4.59-4.05
			c1.91-0.96,4.08-1.26,6.18-0.9c2.08,0.35,4.17,1.46,5.41,3.36l-1.77,0.18c1.34-3.43,3.04-6.91,5.84-9.64
			c1.39-1.35,3.05-2.47,4.91-3.14c1.85-0.66,3.82-0.94,5.76-0.82c1.95,0.13,3.87,0.66,5.61,1.58c1.74,0.93,3.25,2.29,4.39,3.88
			c2.28,3.19,3.19,7.35,2.24,11.22l-1.91-0.63c1.15-2.81,3.78-4.72,6.54-5.45c2.77-0.77,5.89-0.47,8.39,1.09
			c2.48,1.53,4.14,4.05,4.96,6.7c0.41,1.33,0.62,2.71,0.63,4.09l-0.03,1.03l-0.11,1.03l-0.19,1.02c-0.09,0.33-0.18,0.66-0.16,1.03
			l-1.32-0.37c0.35-0.55,0.31-1.21,0.39-1.82l0.02-0.94l-0.05-0.93c-0.11-1.24-0.37-2.46-0.82-3.6c-0.83-2.29-2.3-4.35-4.34-5.59
			c-1.99-1.24-4.58-1.51-6.89-0.88c-2.32,0.61-4.41,2.15-5.3,4.31l-1.68-0.56c0.83-3.27,0.06-6.92-1.9-9.7
			c-1.93-2.82-5.2-4.55-8.62-4.77c-1.7-0.12-3.44,0.1-5.03,0.72c-1.59,0.6-3.01,1.59-4.22,2.81c-2.43,2.46-4,5.72-5.36,9.02
			l-0.68,1.65l-0.95-1.49c-0.88-1.37-2.44-2.26-4.13-2.56c-1.69-0.3-3.5-0.07-5.03,0.69c-1.57,0.78-2.9,2.01-3.96,3.46
			c-0.53,0.73-1,1.51-1.4,2.33c-0.4,0.82-0.75,1.66-1.22,2.48L858.79,115.56z"/>
        </g>
        <g>
          <path className="st3" d="M73.27,207.45c0.35-1.3,1.24-2.33,2.11-3.3c0.9-0.96,1.93-1.79,3.05-2.47c2.24-1.37,4.86-2.16,7.52-2.14
			s5.29,0.84,7.53,2.24c2.25,1.4,4.08,3.46,5.23,5.86l-1.78,0.8c-0.51-1.26-0.55-2.67-0.28-3.89c0.26-1.24,0.75-2.35,1.27-3.41
			c1.1-2.09,2.3-4,3.71-5.88c1.4-1.85,3.04-3.6,5.03-4.97c1-0.67,2.09-1.24,3.25-1.65c1.17-0.38,2.4-0.6,3.63-0.63
			c2.48-0.07,4.86,0.68,6.99,1.67c2.15,0.99,4.07,2.31,5.91,3.66c1.86,1.33,3.68,2.91,5.07,4.85c0.7,0.97,1.28,2.08,1.55,3.32
			c0.07,0.31,0.12,0.62,0.13,0.94c0.04,0.32,0,0.64-0.03,0.96c-0.01,0.32-0.14,0.63-0.21,0.94c-0.1,0.3-0.25,0.58-0.28,0.93
			l-1.22-0.63c0.38-0.37,0.4-0.91,0.44-1.37c0-0.24,0.01-0.48-0.04-0.72c-0.02-0.24-0.09-0.48-0.16-0.71
			c-0.29-0.94-0.88-1.8-1.53-2.61c-1.32-1.66-3-3.02-4.82-4.31c-1.81-1.31-3.65-2.56-5.62-3.49c-1.96-0.92-4.04-1.58-6.13-1.53
			c-1.04,0.02-2.07,0.2-3.07,0.52c-0.99,0.34-1.93,0.82-2.82,1.41c-1.77,1.19-3.3,2.78-4.64,4.52c-1.33,1.74-2.55,3.66-3.58,5.58
			c-1,1.9-1.6,4.07-0.84,5.8l-1.59,0.72c-1.01-2.06-2.67-3.78-4.61-5.02c-1.94-1.24-4.21-1.97-6.5-2.01
			c-2.29-0.04-4.59,0.63-6.57,1.81c-0.99,0.59-1.91,1.32-2.7,2.15c-0.83,0.82-1.39,1.81-2.17,2.71L73.27,207.45z"/>
        </g>
      </g>
      <g id="Ebene_2">
      </g>
      <g id="Ebene_3">
        <g>
          <text transform="matrix(1 0 0 1 176.4529 120.3271)">
            <tspan x="0" y="0" className="st12 st15 st16">Drag &amp; drop a table</tspan>
            <tspan x="0" y="38" className="st12 st15 st16">to build a Value Chart.</tspan>
          </text>
        </g>
      </g>
      <g id="Ebene_6">
        <g>
          <g>
            <path className="st3" d="M762.03,68.36c0.09-0.32,0.31-0.49,0.51-0.66l0.62-0.53l1.24-1.06c0.82-0.71,1.68-1.38,2.52-2.07
				c1.69-1.37,3.5-2.58,5.14-4.01l5.05-4.12c0.83-0.7,1.7-1.35,2.63-1.93l1.38-0.88c0.23-0.15,0.46-0.3,0.68-0.45l0.34-0.23
				c0.1-0.09,0.27-0.1,0.27-0.31l0.86,1.07c-0.22-0.05-0.25,0.13-0.35,0.21c-0.09,0.1-0.19,0.2-0.27,0.31l-0.53,0.65
				c-0.36,0.41-0.78,0.76-1.2,1.1c-0.87,0.66-1.75,1.29-2.6,1.97l-5.14,4.02c-1.74,1.3-3.34,2.78-5.04,4.14
				c-0.85,0.67-1.68,1.37-2.55,2.03l-1.29,0.99l-0.65,0.5c-0.22,0.16-0.42,0.34-0.76,0.36L762.03,68.36z"/>
          </g>
          <g>
            <path className="st3" d="M764.98,53.11c0.31,0.02,0.47,0.19,0.65,0.34l0.53,0.47l1.06,0.93c0.72,0.62,1.39,1.28,2.08,1.91
				c1.38,1.29,2.62,2.72,4.05,3.96l4.15,3.85c0.7,0.63,1.37,1.3,1.96,2.05l0.91,1.1c0.15,0.19,0.3,0.37,0.46,0.54l0.23,0.27
				c0.09,0.07,0.11,0.23,0.31,0.19l-0.95,0.99c0.04-0.21-0.13-0.2-0.22-0.29c-0.1-0.06-0.2-0.13-0.31-0.19l-0.64-0.36
				c-0.41-0.26-0.76-0.57-1.1-0.9c-0.67-0.67-1.3-1.37-1.99-2.01l-4.05-3.95c-1.32-1.35-2.79-2.54-4.16-3.83
				c-0.68-0.65-1.38-1.28-2.04-1.96l-1-1l-0.5-0.5c-0.17-0.16-0.35-0.32-0.38-0.63L764.98,53.11z"/>
          </g>
        </g>
      </g>
      <g id="Ebene_9">
        <g>
          <path className="st3" d="M335.91,778.51c0.05-2.27,0.81-4.46,1.74-6.52c0.95-2.06,2.28-3.93,3.8-5.59c3.05-3.34,7.07-5.76,11.45-6.95
			c2.19-0.58,4.46-0.9,6.78-0.73c2.29,0.19,4.56,0.76,6.63,1.78c2.07,1.02,3.98,2.45,5.39,4.36c1.41,1.9,2.27,4.22,2.39,6.58
			l-1.95-0.03c0.23-2.5,1.12-4.95,2.57-7c1.45-2.05,3.39-3.72,5.58-4.88c2.19-1.16,4.64-1.85,7.13-1.97
			c2.48-0.13,5.04,0.36,7.29,1.43c4.5,2.1,7.99,5.75,10.66,9.72c2.68,4,4.67,8.37,6.3,12.83l-1.8-0.23
			c1.98-2.92,5.16-4.99,8.66-5.56c3.48-0.55,7.29,0.53,9.79,3.02c2.53,2.43,4.01,5.68,4.56,9.02c0.27,1.67,0.29,3.39,0.03,5.07
			c-0.04,0.42-0.16,0.83-0.25,1.25c-0.08,0.4-0.2,0.86-0.47,1.32c-0.14,0.23-0.32,0.48-0.63,0.66c-0.17,0.09-0.29,0.15-0.57,0.17
			c-0.26,0.01-0.52-0.1-0.67-0.22c-0.31-0.26-0.46-0.53-0.54-0.88c-0.04-0.17-0.07-0.37,0.02-0.63c0.07-0.26,0.34-0.55,0.62-0.64
			c0.36-0.1,0.74,0.11,0.84,0.48c0.1,0.36-0.11,0.74-0.48,0.84c0.09-0.03,0.19-0.17,0.19-0.24c0.01-0.07-0.02-0.06-0.01-0.03
			c0,0.05,0.06,0.16,0.07,0.15c0.01,0-0.04-0.04-0.11-0.04c-0.07,0.01,0-0.01,0-0.02c0.03-0.02,0.11-0.11,0.17-0.23
			c0.13-0.25,0.21-0.59,0.27-0.98c0.06-0.38,0.15-0.76,0.17-1.15c0.15-1.54,0.06-3.09-0.25-4.6c-0.66-2.98-2.03-5.88-4.24-7.91
			c-2.17-2.05-5.25-2.94-8.21-2.48c-2.96,0.48-5.69,2.23-7.38,4.71l-1,1.46l-0.6-1.67c-1.57-4.36-3.49-8.61-6.04-12.43
			c-2.54-3.8-5.8-7.2-9.88-9.12c-3.99-1.95-8.88-1.63-12.81,0.45c-1.97,1.03-3.72,2.51-4.95,4.33c-1.25,1.82-2,3.95-2.18,6.15
			l-1.74-0.03c-0.14-2.01-0.88-3.93-2.08-5.53c-1.21-1.6-2.9-2.84-4.72-3.76c-1.83-0.92-3.87-1.44-5.91-1.62
			c-2.03-0.17-4.15,0.11-6.15,0.63c-4.03,1.06-7.77,3.29-10.61,6.36c-1.41,1.53-2.66,3.24-3.53,5.13c-0.92,1.88-1.4,3.9-1.98,5.93
			l-0.01,0.03c-0.11,0.37-0.49,0.58-0.85,0.47C336.11,779.1,335.91,778.82,335.91,778.51z"/>
        </g>
      </g>
      <g id="Ebene_5">
      </g>
      <g id="Ebene_10">
      </g>
    </React.Fragment>;
  }

  viewBox(): string {
    return "60 60 1043.7 868.33";
  }
}

export default withStyles(svgIconStyles)(EmptyValueChartIcon);
