import * as React from "react";

import {Classifier} from "../../../common/utils/ClassifierLogger";
import Log from "../../../common/utils/Logger";
import {LayoutAlgorithm} from "../../../api/api";
import {VisualTableId, VisualTableIdString} from "../../../core/utils/Core";
import {AutolayoutAction, ChangeAutoLayoutPropertiesAction} from "../../actions/DiagramActions";
import {createStyles, FormControlLabel, Theme, withStyles, WithStyles} from "@material-ui/core";
import {
  AutoLayoutOptionProperties,
  AutoLayoutOptions,
  splitTablesIntoSubtrees
} from "../../utils/autolayout/AutoLayoutOptions";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {StyleRules} from "@material-ui/core/styles";
import PropertiesButton from "../../../properties/components/PropertiesButton";
import PropertiesSectionLayout from "../../../properties/components/PropertiesSectionLayout";
import {MetusCheckbox} from "../../../common/components/MetusCheckboxComponent";
import LeftSubtreeIcon from "../../../properties/icons/LeftSubtreeIcon";
import RightSubtreeIcon from "../../../properties/icons/RightSubtreeIcon";
import autobind from "autobind-decorator";
import {BaseSectionProperties} from "../../../properties/model/PropertiesModel";
import {metusFontSizes} from "../../../common/theme/Theme";
import {observer} from "mobx-react";

const renderLog = Log.logger("properties", Classifier.render);
const log = Log.logger("properties");


const styles = (theme: Theme): StyleRules => createStyles({
  tableSelection: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1) + "px",
    marginBottom: theme.spacing(1) + "px",
    fontSize: metusFontSizes.sidebarSecondaryText,
  },
  table: {
    marginLeft: 2 * theme.spacing(1) + "px",
  },
  tableDiv: {
    backgroundColor: "white",
    margin: theme.spacing(1) + "px",
    padding: theme.spacing(1) + "px",
    paddingTop: (theme.spacing(1) + 5) + "px",
    border: "1px solid black"
  },
  divider: {
    color: "white",
    borderStyle: "solid",
    borderWidth: "0px",
    borderBottomWidth: "1px",
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    marginTop: theme.spacing(3) + "px",
    alignSelf: "center",
  },
  label: {
    alignSelf: "flex-start", // label to the left instead of centered
  },
  hr: {
    margin: 0,
    border: "none",
    marginBlockEnd: "0.5em",
    borderStyle: "inset",
    borderWidth: "1px",
    "&:hover": {
      height: "5px",
      cursor: "pointer",
      backgroundColor: theme.metus.dialog.buttonFill,
    },
  },
  hrActive: {
    height: "5px",
    backgroundColor: theme.metus.dialog.buttonFill,
  },
  hrInactive: {
    height: "2px",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },

});

export interface AutoLayoutProperties extends AutoLayoutOptionProperties, BaseSectionProperties {
}

interface LocalState {
}

type StyledLocalProps = AutoLayoutProperties & WithStyles<typeof styles>;

@observer
class AutoLayoutPropertiesComponent extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    /* Handler for checkboxes */
    this.preserveOrdering = this.preserveOrdering.bind(this);
    this.autolayout = this.autolayout.bind(this);
  }

  private createAutolayoutOptions(): AutoLayoutOptions {
    return {
      algorithm: this.props.algorithm,
      preserveOrdering: this.props.preserveOrdering,
      autolayout: this.props.autolayout,
      selectedTable: this.props.selectedTable
    };
  }

  private preserveOrdering(checked: boolean): void {
    const options = this.createAutolayoutOptions();
    options.preserveOrdering = checked;
    this.layout(options);
  }

  private autolayout(checked: boolean): void {
    const options = this.createAutolayoutOptions();
    options.autolayout = checked;
    this.layout(options, false);
  }

  @autobind
  private checkTable(value: VisualTableIdString): void {
    const options = this.createAutolayoutOptions();

    const {leftTables, rightTables} = splitTablesIntoSubtrees(value, this.props.allSortedTables);
    options.selectedTable = value ? VisualTableId.fromKey(value) : undefined;

    this.layout(options, false /*do layout*/);
  }

  @autobind
  private layout(options: AutoLayoutOptions, isDoLayout: boolean = true): void {
    log.debug("Setting layout properties", options);

    if (isDoLayout) {
      Dispatcher.dispatch(new AutolayoutAction(this.props.activeViewId, options));
    } else {
      Dispatcher.dispatch(new ChangeAutoLayoutPropertiesAction(this.props.activeViewId, options));
    }

  }


  render(): JSX.Element {
    renderLog.debug("Rendering AutoLayoutPropertiesComponent");
    const isAlgorithmDefined = this.props.algorithm === LayoutAlgorithm.DAGRE;
    return <PropertiesSectionLayout>
      <FormControlLabel label="Continuous Autolayout"
                        checked={this.props.autolayout}
                        onChange={(ev, checked: boolean): void => this.autolayout(checked)}
                        control={<MetusCheckbox/>}
                        className={this.props.classes.label}/>

      {isAlgorithmDefined && this.renderOptions()}

      {<PropertiesButton
          onClick={(e): void => {
            this.layout(this.createAutolayoutOptions());
          }}>Autolayout now</PropertiesButton>}
    </PropertiesSectionLayout>;
  }

  renderOptions(): JSX.Element {
    const hasTables = (this.props.leftTables.length + this.props.rightTables.length) > 1;

    return (
        <span>
          <FormControlLabel
              label="Preserve order of Elements"
              checked={this.props.preserveOrdering}
              control={<MetusCheckbox/>}
              onChange={(ev, checked: boolean): void => this.preserveOrdering(checked)}
          />

          <hr className={this.props.classes.divider}/>
          <p>If you want to layout a diamond as two subtrees, please assign components to the left or right subtree by
            clicking on a divider.</p>

          {hasTables && <div className={this.props.classes.tableDiv}>
            <hr className={`${this.props.classes.hr} ${this.backgroundColor(undefined)}`}
                onClick={(ev): void => this.checkTable(undefined)}/>

            {this.props.leftTables.map((tableId) => <React.Fragment key={"left" + tableId}>
              <div key={`left_${tableId}`} className={this.props.classes.tableSelection}>
                    <LeftSubtreeIcon/>
                    <span className={this.props.classes.table}>{this.getNameForTable(tableId)}</span>
                  </div>
                  <hr className={`${this.props.classes.hr} ${this.backgroundColor(tableId)}`}
                      onClick={(ev): void => this.checkTable(tableId)}/>
                </React.Fragment>
            )}

            {this.props.rightTables.map((tableId) => <React.Fragment key={"right" + tableId}>
              <div key={`right_${tableId}`} className={this.props.classes.tableSelection}>
                    <RightSubtreeIcon/>
                    <span>{this.getNameForTable(tableId)}</span>
                  </div>
                  <hr className={`${this.props.classes.hr} ${this.backgroundColor(tableId)}`}
                      onClick={(ev): void => this.checkTable(tableId)}/>
                </React.Fragment>
            )}
          </div>}
      </span>
    );
  }

  private backgroundColor(tableId: VisualTableIdString): any {
    return tableId === (this.props.selectedTable ? this.props.selectedTable.toKey() : undefined) ? this.props.classes.hrActive : this.props.classes.hrInactive;
  }

  private getNameForTable(tableId: VisualTableIdString): string {
    let result = tableId;
    const tableNameAndId = this.props.tableNames.find(t => t.id === tableId);
    if (tableNameAndId) {
      result = tableNameAndId.name;
    }
    return result;
  }
}

export default withStyles(styles)(AutoLayoutPropertiesComponent);

