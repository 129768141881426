import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";

class MatrixIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path
        d="M42.779,10 C45.658,10 48,12.342 48,15.221 L48,15.221 L48,41 C48,43.757 45.757,46 43,46 L43,46 L13.221,46 C10.342,46 8,43.658 8,40.779 L8,40.779 L8,15 C8,12.243 10.243,10 13,10 L13,10 Z M46,19 L18,19 L18,44 L43,44 C44.654,44 46,42.654 46,41 L46,41 L46,19 Z M44,35 L44,40 C44,41.104 43.104,42 42,42 L42,42 L36,42 L36,35 L44,35 Z M36,28 L36,35 L28,35 L28,28 L36,28 Z M28,21 L28,28 L20,28 L20,21 L28,21 Z M18,12 L13,12 C11.346,12 10,13.346 10,15 L10,15 L10,19 L18,19 L18,12 Z"/>;
  }

  viewBox(): string {
    return "0 0 56 56";
  }
}

export default withStyles(svgIconStyles)(MatrixIcon);