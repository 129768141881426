import {IStaticFilterMatcher} from "./IStaticFilterMatcher";

export class OrStaticFilterMatcher implements IStaticFilterMatcher {
  private _ops: IStaticFilterMatcher[];

  constructor(...ops:IStaticFilterMatcher[]) {
    this._ops = ops;
  }

  valueSatisfiesFilterCondition(value: any): boolean {
    let result = true;
    for(let i=0; i < this._ops.length; i++) {
      const currentOp = this._ops[i];
      result = currentOp.valueSatisfiesFilterCondition(value);
      if (result) {
        break;
      }
    }
    return result;
  }

}
