/* VariantsIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";

class VariantsIcon extends MetusSvgIcon {

  svg(): JSX.Element {
    return <g id="co-/-variants" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
          d="M39,9 L41,9 L41,7 L39,7 L39,9 Z M39,13 L41,13 L41,11 L39,11 L39,13 Z M39,17 L41,17 L41,15 L39,15 L39,17 Z M39,21 L41,21 L41,19 L39,19 L39,21 Z M39,25 L41,25 L41,23 L39,23 L39,25 Z M39,29 L41,29 L41,27 L39,27 L39,29 Z M39,33 L41,33 L41,31 L39,31 L39,33 Z M39,37 L41,37 L41,35 L39,35 L39,37 Z M39,41 L41,41 L41,39 L39,39 L39,41 Z M39,45 L41,45 L41,43 L39,43 L39,45 Z M39,49 L41,49 L41,47 L39,47 L39,49 Z M39,53 L41,53 L41,51 L39,51 L39,53 Z M39,57 L41,57 L41,55 L39,55 L39,57 Z M39,61 L41,61 L41,59 L39,59 L39,61 Z M39,65 L41,65 L41,63 L39,63 L39,65 Z M39,69 L41,69 L41,67 L39,67 L39,69 Z M39,73 L41,73 L41,71 L39,71 L39,73 Z M7,41 L9,41 L9,39 L7,39 L7,41 Z M11,41 L13,41 L13,39 L11,39 L11,41 Z M15,41 L17,41 L17,39 L15,39 L15,41 Z M19,41 L21,41 L21,39 L19,39 L19,41 Z M23,41 L25,41 L25,39 L23,39 L23,41 Z M27,41 L29,41 L29,39 L27,39 L27,41 Z M31,41 L33,41 L33,39 L31,39 L31,41 Z M35,41 L37,41 L37,39 L35,39 L35,41 Z M43,41 L45,41 L45,39 L43,39 L43,41 Z M47,41 L49,41 L49,39 L47,39 L47,41 Z M51,41 L53,41 L53,39 L51,39 L51,41 Z M55,41 L57,41 L57,39 L55,39 L55,41 Z M59,41 L61,41 L61,39 L59,39 L59,41 Z M63,41 L65,41 L65,39 L63,39 L63,41 Z M67,41 L69,41 L69,39 L67,39 L67,41 Z M71,41 L73,41 L73,39 L71,39 L71,41 Z"
          id="Fill-5" fillOpacity="0.5" fill="#FFFFFF"></path>
      <path
          d="M57.5,59 C63.29,59 68,54.065 68,48 L64,48 C64,51.86 61.084,55 57.5,55 C51.71,55 47,59.935 47,66 L51,66 C51,62.14 53.916,59 57.5,59 Z"
          id="Path" fillOpacity="0.5" fill="#FFFFFF"></path>
      <polygon id="Path" fillOpacity="0.5" fill="#FFFFFF"
               points="24.328 21.5 30.414 27.586 27.586 30.414 21.5 24.328 15.414 30.414 12.586 27.586 18.672 21.5 12.586 15.414 15.414 12.586 21.5 18.672 27.586 12.586 30.414 15.414"></polygon>
      <polygon id="Path" fill="#FFFFFF" points="12 66 30 66 30 48 12 48"></polygon>
      <path d="M59,11 C53.4766667,11 49,15.4766667 49,21 C49,26.5233333 53.4766667,31 59,31 C64.5233333,31 69,26.5233333 69,21 C69,15.4766667 64.5233333,11 59,11 Z" id="Path" fill="#FFFFFF"></path>
    </g>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(VariantsIcon);
