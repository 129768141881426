import {AttributeDefinition, AttributeFormatType} from "../../../api/api";
import {AttributeDefinitionImpl, TableId} from "../Core";
import {modelStore} from "../../stores/ModelStore";

/**
 * Created by P.Bernhard on 05.10.2017.
 */

export interface IFilterMatcher {
  filterTextMatches(filterText: string, table: TableId, attributeName: string): boolean;

  valueSatisfiesFilterCondition(value: any, filterText: string, table: TableId, attributeName: string): boolean;

  filterIsAllowedInTable(tableId: TableId, attributeName: string): boolean;
}

export class NonePseudoMatcher implements IFilterMatcher {
  public filterTextMatches(filterText: string, table: TableId, attributeName: string): boolean {
    throw new Error("Method must not be implemented.");
  }

  public valueSatisfiesFilterCondition(value: number, filterText: string, table: TableId, attributeName: string): boolean {
    throw new Error("Method not implemented.");
  }

  public filterIsAllowedInTable(tableId: string, attributeName: string): boolean {
    throw new Error("Method not implemented.");
  }
}

export let nonePseudoMatcher: NonePseudoMatcher = new NonePseudoMatcher();


class AllPseudoMatcher implements IFilterMatcher {
  public filterTextMatches(filterText: string, table: TableId, attributeName: string): boolean {
    throw new Error("Method must not be implemented.");
  }

  public valueSatisfiesFilterCondition(value: number, filterText: string, table: TableId, attributeName: string): boolean {
    throw new Error("Method not implemented.");
  }

  public filterIsAllowedInTable(tableId: string, attributeName: string): boolean {
    throw new Error("Method not implemented.");
  }
}

export let allPseudoMatcher: AllPseudoMatcher = new AllPseudoMatcher();


export abstract class FilterMatcher<T> implements IFilterMatcher {
  public filterTextMatches(filterText: string, table: TableId, attributeName: string): boolean {
    return filterText.match(this.getMatcherRegex(table, attributeName)) !== null;
  }

  protected abstract getMatcherRegex(table: TableId, attributeName: string): string;

  /**
   * filter text must match for this method! -> Call filterTextMatches() before
   */
  public abstract valueSatisfiesFilterCondition(value: T, filterText: string, table: TableId, attributeName: string): boolean;

  public abstract filterIsAllowedInTable(tableId: TableId, attributeName: string): boolean;
}


export abstract class NumberFilterMatcher extends FilterMatcher<number> {
  protected matcherRegexBeforeNumber: string = "^";
  protected matcherRegexAfterNumber: string = "$";

  public filterIsAllowedInTable(tableId: TableId, attributeName: string): boolean {
    const type: AttributeFormatType = modelStore.getAttributeFormatType(tableId, attributeName);
    return modelStore.tableHasAttributeDefinition(tableId, attributeName) && type !== null && type === "Double";
  }

  protected getMatcherRegex(table: TableId, attributeName: string): string {
    return this.matcherRegexBeforeNumber + getNumberRegex(this.getViewAttributeDefinition(table, attributeName)) + this.matcherRegexAfterNumber;
  }

  protected getViewAttributeDefinition(table: TableId, attributeName: string): AttributeDefinition {
    const attributeDefinition: AttributeDefinition = modelStore.getAttributeDefinition(table, attributeName);
    const viewAttributeDefinition: AttributeDefinition = new AttributeDefinitionImpl(attributeDefinition.type, attributeDefinition.formatType, attributeDefinition.pattern, attributeDefinition.editable, attributeDefinition.editPattern);

    return viewAttributeDefinition;
  }

  protected generateOperands(filterTextNumberPart: string): number[] {
    const filterTextNumber: number = Number.parseFloat(filterTextNumberPart.replace(",", "."));

    return [filterTextNumber];
  }
}


// = number
class EqualNumberFilterMatcher extends NumberFilterMatcher {
  // noinspection JSUnusedGlobalSymbols
  protected matcherRegexBeforeNumber: string = this.matcherRegexBeforeNumber + "={1}";

  public valueSatisfiesFilterCondition(value: number, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: number[] = this.generateOperands(filterText);
    return value === operand;
  }

  protected generateOperands(filterText: string): number[] {
    filterText = filterText.substring(1);
    return super.generateOperands(filterText);
  }
}

export let equalNumberFilterMatcher: EqualNumberFilterMatcher = new EqualNumberFilterMatcher();


// >= number
class GreaterEqualNumberFilterMatcher extends NumberFilterMatcher {
  // noinspection JSUnusedGlobalSymbols
  protected matcherRegexBeforeNumber: string = this.matcherRegexBeforeNumber + "(?:>=|=>)";

  public valueSatisfiesFilterCondition(value: number, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: number[] = this.generateOperands(filterText);
    return value >= operand;
  }

  protected generateOperands(filterText: string): number[] {
    filterText = filterText.substring(2);
    return super.generateOperands(filterText);
  }
}

export let greaterEqualNumberFilterMatcher: GreaterEqualNumberFilterMatcher = new GreaterEqualNumberFilterMatcher();


// <= number
class LessEqualNumberFilterMatcher extends NumberFilterMatcher {
  // noinspection JSUnusedGlobalSymbols
  protected matcherRegexBeforeNumber: string = this.matcherRegexBeforeNumber + "(?:<=|=<)";

  public valueSatisfiesFilterCondition(value: number, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: number[] = this.generateOperands(filterText);
    return value <= operand;
  }

  protected generateOperands(filterText: string): number[] {
    filterText = filterText.substring(2);
    return super.generateOperands(filterText);
  }
}

export let lessEqualNumberFilterMatcher: LessEqualNumberFilterMatcher = new LessEqualNumberFilterMatcher();


// < number
class LessNumberFilterMatcher extends NumberFilterMatcher {
  // noinspection JSUnusedGlobalSymbols
  protected matcherRegexBeforeNumber: string = this.matcherRegexBeforeNumber + "<";

  public valueSatisfiesFilterCondition(value: number, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: number[] = this.generateOperands(filterText);
    return value < operand;
  }

  protected generateOperands(filterText: string): number[] {
    filterText = filterText.substring(1);
    return super.generateOperands(filterText);
  }
}

export let lessNumberFilterMatcher: LessNumberFilterMatcher = new LessNumberFilterMatcher();


// > number
class GreaterNumberFilterMatcher extends NumberFilterMatcher {
  // noinspection JSUnusedGlobalSymbols
  protected matcherRegexBeforeNumber: string = this.matcherRegexBeforeNumber + ">";

  public valueSatisfiesFilterCondition(value: number, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: number[] = this.generateOperands(filterText);
    return value > operand;
  }

  protected generateOperands(filterText: string): number[] {
    filterText = filterText.substring(1);
    return super.generateOperands(filterText);
  }
}

export let greaterNumberFilterMatcher: GreaterNumberFilterMatcher = new GreaterNumberFilterMatcher();


// != number
class UnequalNumberFilterMatcher extends NumberFilterMatcher {
  // noinspection JSUnusedGlobalSymbols
  protected matcherRegexBeforeNumber: string = this.matcherRegexBeforeNumber + "<>";

  public valueSatisfiesFilterCondition(value: number, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: number[] = this.generateOperands(filterText);
    return value !== operand;
  }

  protected generateOperands(filterText: string): number[] {
    filterText = filterText.substring(2);
    return super.generateOperands(filterText);
  }
}

export let unequalNumberFilterMatcher: UnequalNumberFilterMatcher = new UnequalNumberFilterMatcher();

// => number <=
class InBetweenNumberFilterMatcher extends NumberFilterMatcher {
  protected getMatcherRegex(table: TableId, attributeName: string): string {
    return this.matcherRegexBeforeNumber + "><" + getNumberRegex(this.getViewAttributeDefinition(table, attributeName)) + ";" + getNumberRegex(this.getViewAttributeDefinition(table, attributeName)) + this.matcherRegexAfterNumber;
  }

  public valueSatisfiesFilterCondition(value: number, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const operand: number[] = this.generateOperands(filterText);
    return value >= operand[0] && value <= operand[1];
  }

  protected generateOperands(filterText: string): number[] {
    filterText = filterText.substring(2);

    return [Number.parseFloat(filterText.split(";")[0].replace(",", ".")), Number.parseFloat(filterText.split(";")[1].replace(",", "."))];
  }
}

export let inBetweenNumberFilterMatcher: InBetweenNumberFilterMatcher = new InBetweenNumberFilterMatcher();


export abstract class StringFilterMatcher extends FilterMatcher<string> {
  protected matcherRegex: string = ".*";

  public filterIsAllowedInTable(tableId: TableId, attributeName: string): boolean {
    const type: AttributeFormatType = modelStore.getAttributeFormatType(tableId, attributeName);
    return modelStore.tableHasAttributeDefinition(tableId, attributeName) && type !== null && type === "String";
  }

  protected getMatcherRegex(table: TableId, attributeName: string): string {
    return this.matcherRegex;
  }

  protected abstract generateOperands(filterText: string): string[];
}


// = string
class EqualStringFilterMatcher extends StringFilterMatcher {
  protected matcherRegex: string = "^" + "=[^<>]" + this.matcherRegex + "$";

  public valueSatisfiesFilterCondition(value: string, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: string[] = this.generateOperands(filterText);
    return value === operand;
  }

  protected generateOperands(filterText: string): string[] {
    return [filterText.substring(1)];
  }
}

export let equalStringFilterMatcher: EqualStringFilterMatcher = new EqualStringFilterMatcher();


// >= string
class GreaterEqualStringFilterMatcher extends StringFilterMatcher {
  protected matcherRegex: string = "^" + "(?:>=|=>)" + this.matcherRegex + "$";

  public valueSatisfiesFilterCondition(value: string, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: string[] = this.generateOperands(filterText);
    return value >= operand;
  }

  protected generateOperands(filterText: string): string[] {
    return [filterText.substring(2)];
  }
}

export let greaterEqualStringFilterMatcher: GreaterEqualStringFilterMatcher = new GreaterEqualStringFilterMatcher();


// <= string
class LessEqualStringFilterMatcher extends StringFilterMatcher {
  protected matcherRegex: string = "^" + "(?:<=|=<)" + this.matcherRegex + "$";

  public valueSatisfiesFilterCondition(value: string, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: string[] = this.generateOperands(filterText);
    return value <= operand;
  }

  protected generateOperands(filterText: string): string[] {
    return [filterText.substring(2)];
  }
}

export let lessEqualStringFilterMatcher: LessEqualStringFilterMatcher = new LessEqualStringFilterMatcher();


// < string
class LessStringFilterMatcher extends StringFilterMatcher {
  protected matcherRegex: string = "^" + "<[^>=]" + this.matcherRegex + "$";

  public valueSatisfiesFilterCondition(value: string, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: string[] = this.generateOperands(filterText);
    return value < operand;
  }

  protected generateOperands(filterText: string): string[] {
    return [filterText.substring(1)];
  }
}

export let lessStringFilterMatcher: LessStringFilterMatcher = new LessStringFilterMatcher();


// > string
class GreaterStringFilterMatcher extends StringFilterMatcher {
  protected matcherRegex: string = "^" + ">[^<=]" + this.matcherRegex + "$";

  public valueSatisfiesFilterCondition(value: string, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: string[] = this.generateOperands(filterText);
    return value > operand;
  }

  protected generateOperands(filterText: string): string[] {
    return [filterText.substring(1)];
  }
}

export let greaterStringFilterMatcher: GreaterStringFilterMatcher = new GreaterStringFilterMatcher();


// != string
class UnequalStringFilterMatcher extends StringFilterMatcher {
  protected matcherRegex: string = "^" + "<>" + this.matcherRegex + "$";

  public valueSatisfiesFilterCondition(value: string, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: string[] = this.generateOperands(filterText);
    return value !== operand;
  }

  protected generateOperands(filterText: string): string[] {
    return [filterText.substring(2)];
  }
}

export let unequalStringFilterMatcher: UnequalStringFilterMatcher = new UnequalStringFilterMatcher();


// string does not start with (ignore case)
class DoesNotStartWithCaseInsensitiveStringFilterMatcher extends StringFilterMatcher {
  protected matcherRegex: string = "^" + "![^*]" + this.matcherRegex + "$";

  public valueSatisfiesFilterCondition(value: string, filterText: string, table: TableId, attributeName: string): boolean {
    if (value === undefined || value === null) {
      return true;
    }
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: string[] = this.generateOperands(filterText);
    return !value.toLowerCase().startsWith(operand.toLowerCase());
  }

  protected generateOperands(filterText: string): string[] {
    return [filterText.substring(1)];
  }
}


export let doesNotStartWithCaseInsensitiveStringFilterMatcher: DoesNotStartWithCaseInsensitiveStringFilterMatcher = new DoesNotStartWithCaseInsensitiveStringFilterMatcher();


// string starts with
class StartsWithCaseInsensitiveStringFilterMatcher extends StringFilterMatcher {
  protected matcherRegex: string = "^" + "[^*<>!=]" + this.matcherRegex + "$";

  public valueSatisfiesFilterCondition(value: string, filterText: string, table: TableId, attributeName: string): boolean {
    let result = false;
    if (this.filterTextMatches(filterText, table, attributeName)) {
      const [operand]: string[] = this.generateOperands(filterText);
      if (value !== undefined && value !== null) {
        result = value.toLowerCase().startsWith(operand.toLowerCase());
      }
    }
    return result;
  }

  protected generateOperands(filterText: string): string[] {
    return [filterText];
  }
}

export let startsWithCaseInsensitiveStringFilterMatcher: StartsWithCaseInsensitiveStringFilterMatcher = new StartsWithCaseInsensitiveStringFilterMatcher();


// string contains
class ContainsCaseInsensitiveStringFilterMatcher extends StringFilterMatcher {
  protected matcherRegex: string = "^" + "\\*" + this.matcherRegex + "$";

  public valueSatisfiesFilterCondition(value: string, filterText: string, table: TableId, attributeName: string): boolean {
    if (value === undefined || value === null) {
      return false;
    }

    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: string[] = this.generateOperands(filterText);
    return value.toLowerCase().indexOf(operand.toLowerCase()) >= 0;
  }

  protected generateOperands(filterText: string): string[] {
    return [filterText.substring(1)];
  }
}

export let containsCaseInsensitiveStringFilterMatcher: ContainsCaseInsensitiveStringFilterMatcher = new ContainsCaseInsensitiveStringFilterMatcher();


// string not contains
class ContainsNotCaseInsensitiveStringFilterMatcher extends StringFilterMatcher {
  protected matcherRegex: string = "^" + "!\\*" + this.matcherRegex + "$";

  public valueSatisfiesFilterCondition(value: string, filterText: string, table: TableId, attributeName: string): boolean {
    if (value === undefined || value === null) {
      return true;
    }

    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const [operand]: string[] = this.generateOperands(filterText);
    return value.toLowerCase().indexOf(operand.toLowerCase()) < 0;
  }

  protected generateOperands(filterText: string): string[] {
    return [filterText.substring(2)];
  }
}

export let containsNotCaseInsensitiveStringFilterMatcher: ContainsNotCaseInsensitiveStringFilterMatcher = new ContainsNotCaseInsensitiveStringFilterMatcher();


// string is in between value1 and value2
class InBetweenStringFilterMatcher extends StringFilterMatcher {
  protected matcherRegex: string = "^" + "><" + this.matcherRegex + ";" + this.matcherRegex + "$";

  public valueSatisfiesFilterCondition(value: string, filterText: string, table: TableId, attributeName: string): boolean {
    if (!this.filterTextMatches(filterText, table, attributeName))
      return false;

    const operand: string[] = this.generateOperands(filterText);
    return value >= operand[0] && value <= operand[1];
  }

  protected generateOperands(filterText: string): string[] {
    return [filterText.split(";")[0], filterText.split(";")[1]];
  }
}

export let isInBetweenStringFilterMatcher: InBetweenStringFilterMatcher = new InBetweenStringFilterMatcher();


function getAllStringFilterMatcher(): StringFilterMatcher[] {
  return [equalStringFilterMatcher,
    greaterEqualStringFilterMatcher,
    lessEqualStringFilterMatcher,
    lessStringFilterMatcher,
    greaterStringFilterMatcher,
    unequalStringFilterMatcher,
    doesNotStartWithCaseInsensitiveStringFilterMatcher,
    startsWithCaseInsensitiveStringFilterMatcher,
    containsCaseInsensitiveStringFilterMatcher,
    containsNotCaseInsensitiveStringFilterMatcher,
    isInBetweenStringFilterMatcher];
}

function getAllNumberFilterMatcher(): NumberFilterMatcher[] {
  return [equalNumberFilterMatcher,
    greaterEqualNumberFilterMatcher,
    greaterNumberFilterMatcher,
    lessEqualNumberFilterMatcher,
    lessNumberFilterMatcher,
    unequalNumberFilterMatcher,
    inBetweenNumberFilterMatcher];
}

/**
 *
 *
 * @param filterText The text must only contain the filter part, no NodeCollector parts!
 * @param table Table id
 * @param attributeName attribute name
 * @returns A to the text matching filter. Null, if no filter could be found.
 */
export function getFilterMatcher(filterText: string, table: TableId, attributeName: string): IFilterMatcher {
  const attributeFormatType: AttributeFormatType = modelStore.getAttributeFormatType(table, attributeName);
  if (!modelStore.getAttributeDefinition(table, attributeName)) {
    return null;
  }

  if (attributeFormatType === "String") {
    for (const matcher of getAllStringFilterMatcher())
      if (matcher.filterTextMatches(filterText, table, attributeName))
        return matcher;
  } else if (attributeFormatType === "Double") {
    /* Trim whitespaces if number format. */
    filterText = filterText.replace(/\s/, "");
    const allNumberFilterMatchers = getAllNumberFilterMatcher();
    for (const matcher of getAllNumberFilterMatcher()) {
      if (matcher.filterTextMatches(filterText, table, attributeName))
        return matcher;
    }
  }

  return undefined;
}

//noinspection JSUnusedGlobalSymbols
/**
 * Checks if a filter text leads to a valid filter
 *
 * @param filterText The text must only contain the filter part, no NodeCollector parts!
 * @param table Table id
 * @param attributeName attribute name
 */
export function isValidFilterText(filterText: string, table: TableId, attributeName: string): boolean {
  return getFilterMatcher(filterText, table, attributeName) !== null;
}

export function test_getNumberFilterMatchers(filterText: string, table: TableId, attributeName: string): IFilterMatcher[] {
  const attributeFormatType: AttributeFormatType = modelStore.getAttributeFormatType(table, attributeName);
  const matchers: IFilterMatcher[] = [];

  if (attributeFormatType === "String") {
    for (const matcher of getAllStringFilterMatcher())
      if (matcher.filterTextMatches(filterText, table, attributeName))
        matchers.push(matcher);
  } else if (attributeFormatType === "Double") {
    for (const matcher of getAllNumberFilterMatcher())
      if (matcher.filterTextMatches(filterText, table, attributeName))
        matchers.push(matcher);
  }

  return matchers;
}

function getNumberRegex(definition: AttributeDefinition): string {
  let matcherRegex: string = "-?[0-9]+(?:";
  if (definition.editPattern) {
    if (definition.editPattern.search(",") >= 0)
      matcherRegex += ",";
    else if (definition.editPattern.search("\\.") >= 0)
      matcherRegex += ".";
    else
      throw new Error("A number pattern must have a '.' or a ','");
  } else {
    // default: english
    matcherRegex += ".";
  }
  matcherRegex += "[0-9]{1,})?";

  return matcherRegex;
}
