import {modelStore} from "../../core/stores/ModelStore";
import autobind from "autobind-decorator";
import {
  computed,
  IObservableSetInitialValues,
  IReactionDisposer,
  IReactionOptions,
  ISetDidChange,
  isObservableArray,
  isObservableObject,
  isObservableProp,
  isObservableSet,
  Lambda,
  ObservableSet,
  observe,
  reaction
} from "mobx";
import {ElementId, ElementObject} from "../../core/utils/Core";
import Log from "../../common/utils/Logger";
import {ColDef} from "ag-grid-community/dist/lib/entities/colDef";
import {IMatrixRowElements} from "./MatrixRowElements";
import {ElementPath, ElementPathKey, elementPathKeyToPath, leafElementId} from "./MatrixCoreDefinitions";
import {Validate} from "../../common/utils/Validate";
import {OnRowDataUpdateCallback, UpdateScheduler} from "./UpdateScheduler";
import {IMatrixCellValueProvider, MatrixCellValueProviderFactory} from "./MatrixCellValueProvider";
import {isMatrixColumn} from "./MatrixModel";
import {MatrixCellConfiguration} from "./MatrixCellConfiguration";
import {convertStringValueToNumber, renderPrefilledEditValue} from "../../common/utils/FormatUtil";
import {AttributeDefinition} from "../../api/api";

const log = Log.logger("RowDataUpdater");

export type OnMetusSelectionChangedCallback = (elementId: ElementId, isPrimaryUpdate: boolean) => void;

/**
 * array of matrix rows is passed as data to ag-grid
 */
export class MatrixRow {
  elementId: ElementId;
  dataPath: ElementPath;
  // dynamically key value pairs for row attribute values (attName, attValue)
  // and matrix cell values (columnLeafElementId, boolean) are added
}

export interface IColumnHolder {
  leafColumnDefinitions: ColDef[];
}

/**
 * This class is the interface to the AG-Grid API. The goal is to create, delete and update
 * rows fine grained and to avoid costly grid updates as follows:
 *
 <li>Changes in the row hierarchy like additions of elements to row tables, filtering, new or deleted connections between row tables and changes in the row configuration (additions/deletetions/reordering of row tables):
 <ul>
 <li>MatrixRowElements.ids will update the row ids list with minimum changes using mobx</li>
 <li>RowDataUpdater will observe the MatrixRowElements.ids and call onRowDataChanged with a transaction for ag-grid batching row additions and deletions</li>
 </ul>
 </li>

 <li>Changes in the column hierarchy like additions of elements to column tables, filtering, new or deleted connections between column tables and changes in the column configuration (additions/deletetions/reordering of column tables):
 <ul>
 <li>trackRow creates a reaction for each row which calls createRow which accesses the columnDefinitions, so will re-evaluate creating new cell values in the MatrixRow and call onUpdateCallback for each row</li>
 </ul>
 </li>

 <li>Changes in the cellConfiguration:
 <ul>
 <li>The computed property cellValueProvider creates a IMatrixCellValueProvider on changes in the cellConfiguration</li>
 <li>trackRow creates a reaction for each row which calls createRow which accesses the cellValueProvider, so will re-evaluate and call onUpdateCallback for each row when cellValueProvider changes</li>
 </ul>
 </li>

 <li>Changes in any attribute or observable accessed creating a MatrixRow, changes in row attribute configuration (additions/deletions of row attributes):
 <ul>
 <li>the reaction created for each row will fire if any value or connection changes which was accessed inside the createRow methodand call onUpdateCallback for the affected row when some tracked observable changes</li>
 </ul>
 </li>

 <li>Metus Primary or Secondary Selection Changes:</li>
 <ul>
 <li>RowDataUpdater will observe the selection and update ag-grid calling onMetusSelectionChangedCallback</li>
 <li>
 </ul>
 </li>
 *
 */
export class RowDataUpdater {
  private _idToRowUpdateReactionDisposer: Map<ElementPathKey, IReactionDisposer>;
  private _rowsObserverDisposer: Lambda;
  private _cellConfigurationObserverDisposeLambda: Lambda;
  private _primarySelectionObserverDisposer: Lambda;
  private _secondarySelectionObserverDisposer: Lambda;
  /**
   * gathers updates and schedules them cumulated
   */
  private scheduler: UpdateScheduler;

  /**
   * create a new row data updater
   * @param _matrixRowElements observable set of row paths in matrix
   * @param columnHolder holder of observable property leafColumnDefinitions
   * @param cellConfiguration cellConfiguration
   * @param onUpdateCallback called with  batched transactions of row add/remove/update
   * @param onMetusSelectionChangedCallback called when selection was changed
   * @param delayInMs 0: onUpdateCallback is called with a batched transaction gathering all changes done in the current event execution, otherwise gathers all in the time frame from first change for delayInMs, -1: no batching is done at all
   */
  constructor(private _matrixRowElements: IMatrixRowElements,
              private columnHolder: IColumnHolder,
              private cellConfiguration: MatrixCellConfiguration,
              private onUpdateCallback: OnRowDataUpdateCallback,
              private onMetusSelectionChangedCallback: OnMetusSelectionChangedCallback,
              delayInMs: number = 0) {

    log.debug("Create row data updater", _matrixRowElements.ids.size, columnHolder.leafColumnDefinitions.length);
    this.scheduler = new UpdateScheduler(onUpdateCallback, delayInMs);
    this._idToRowUpdateReactionDisposer = new Map<ElementPathKey, IReactionDisposer>();
    Validate.isTrue(isObservableObject(cellConfiguration), "cellConfiguration must be an observable object");
    Validate.isTrue(isObservableProp(columnHolder, "leafColumnDefinitions") || isObservableArray(columnHolder.leafColumnDefinitions), "ColumnHolder must have an observable property 'leafColumnDefinitions' or it must be an observable array to make column additions work");
    Validate.isTrue(isObservableSet(_matrixRowElements.ids), "matrixRowElements.ids must be an observable set to make row updates work");
  }

  public initializeAndCreateMatrixRows(): MatrixRow[] {
    this.dispose();
    log.debug("Reinitializing RowDataUpdater Model");
    const result = Array.from(this._matrixRowElements.ids.values()).map((rowElementId: ElementPathKey) => this.trackRow(rowElementId));
    this.createRowsObserver();
    this.createSelectionObserver();
    log.debug("RowDataUpdater: Created MatrixModel Rows", result);
    return result;
  }

  private createSelectionObserver(): void {
    const primarySelectionObservable: ObservableSet<ElementId> = modelStore.primarySelection as ObservableSet<ElementId>;
    this._primarySelectionObserverDisposer = observe(primarySelectionObservable, this.selectionChangedObserverListener.bind(this, true));
    const secondarySelectionObservable: ObservableSet<ElementId> = modelStore.secondarySelection as ObservableSet<ElementId>;
    this._secondarySelectionObserverDisposer = observe(secondarySelectionObservable, this.selectionChangedObserverListener.bind(this, false));
  }

  /**
   * cell value provider is computed from matrix cell configuration so updates automatically
   */
  @computed
  private get cellValueProvider(): IMatrixCellValueProvider {
    log.debug("Reevaluationg Cell Value Provider");
    return MatrixCellValueProviderFactory(this.cellConfiguration);
  }

  private selectionChangedObserverListener(isPrimaryUpdate:boolean, event: ISetDidChange): any {
    switch (event.type) {
      case "add":
        log.debug("Element added to selection", event, isPrimaryUpdate);
        this.onMetusSelectionChangedCallback(event.newValue, isPrimaryUpdate);
        break;
      case "delete":
        log.debug("Element removed from selection", event, isPrimaryUpdate);
        this.onMetusSelectionChangedCallback(event.oldValue, isPrimaryUpdate);
        break;
    }
  }

  private createRowsObserver(): void {
    const observable: IObservableSetInitialValues<ElementPathKey> = this._matrixRowElements.ids as any;
    this._rowsObserverDisposer = observe(observable, event => {
      log.debug("RowDataUpdater: MatrixModel row elements changed, checking for adding or removal of elements", event);
      switch (event.type as any) {
        case "add":
          log.debug("Set add occurred");
          const addedElementPathKey: ElementPathKey = (event as any).newValue;
          log.debug("RowDataUpdater: Add row element ", addedElementPathKey);
          const addedRow = this.trackRow(addedElementPathKey);
          this.scheduler.rowsAdded(addedRow);
          break;
        case "remove":
        case "delete":
          log.debug("Set remove occurred");
          const removedElementPathKey: ElementPathKey = (event as any).oldValue;
          log.debug("RowDataUpdater: Remove row element ", removedElementPathKey);
          const removedRow = this.untrackRow(removedElementPathKey);
          this.scheduler.rowsRemoved(removedRow);
          break;
          // code used when ids was an array
          // case "splice":
          // log.debug("Array splice occurred");
          // const rowsToAdd = event.added.map(newElementId => this.trackRow(newElementId));
          // const rowsToRemove = event.removed.map(removedElementId => this.untrackRow(removedElementId));
          // log.debug("RowDataUpdater: Add row elements", rowsToAdd);
          // this.onUpdateCallback({add: rowsToAdd, remove: rowsToRemove});
          // break;
        case "update":
          // update does not make sense in sets
          log.warn("Set update occurred, but ignored");
          break;
      }
    });
  }

  private trackRow(elementPathKey: ElementPathKey): MatrixRow {
    let result: MatrixRow = undefined;
    // note: it is not guaranteed, that create row is called synchronously
    // in unit tests it is called immediately, but in the application not
    // however, if it is not called snchronously, and this method returns result === undefined
    // then the first run triggers the effect, so that an update occurrs.
    // therefore it seems safe to return an object with the elementId from
    // this method
    const lastHolder = {};
    const opts: IReactionOptions = {};
    const iRowUpdateReactionDisposer = reaction(() => result = this.createRow(lastHolder, elementPathKey),
        this.rowUpdated,
        opts);
    this._idToRowUpdateReactionDisposer.set(elementPathKey, iRowUpdateReactionDisposer);
    // it there is no result yet, then call this.updated also for the first result of this.createRow,
    // otherwise all fields would remain empty if a new row is added
    opts.fireImmediately = result === undefined;
    const dataPath = elementPathKeyToPath(elementPathKey);
    const elementId = leafElementId(dataPath);
    return result || {elementId, dataPath};
  }

  private untrackRow(elementPathKey: ElementPathKey): MatrixRow {
    const toBeDisposed = this._idToRowUpdateReactionDisposer.get(elementPathKey);
    if (!toBeDisposed) {
      log.error("RowDataUpdater: Row should be tracked but missing disposer", elementPathKey);
    } else {
      toBeDisposed();
      this._idToRowUpdateReactionDisposer.delete(elementPathKey);
    }
    const dataPath = elementPathKeyToPath(elementPathKey);
    const elementId = leafElementId(dataPath);
    return {elementId, dataPath};
  }

  @autobind
  private rowUpdated(row: MatrixRow): void {
    log.debug(`RowDataUpdater: Row updated ${row.elementId}:\n${JSON.stringify(row)}`);
    this.scheduler.rowsUpdated(row);
  }

  private equals(o1: any, o2: any): boolean {
    let result = true;
    for (const key in o1) {
      if (o1[key] !== o2[key]) {
        result = false;
        break;
      }
    }
    return result;
  }

  /**
   * creates the row data passed to ag-grid; for every column it contains the column value,
   * mapping column element id --> matrix cell value for matrix columns and colDef.field to attribute value for row attribute columns.
   * All observables accesses here are tracked and changes trigger an update of row data.
   * @param lastHolder
   * @param elementPathKey
   */
  private createRow(lastHolder: any, elementPathKey: ElementPathKey): MatrixRow {
    // element must be observable
    const dataPath = elementPathKeyToPath(elementPathKey);
    const elementId = leafElementId(dataPath);
    const elementObject: ElementObject = modelStore.getElement(elementId);

    const result: MatrixRow = {elementId, dataPath};

    if (elementObject) {
      this.columnHolder.leafColumnDefinitions.forEach((colDef: ColDef) => {
        if (isMatrixColumn(colDef)) {
          // matrix cell value
          const columnElementId = colDef.field;
          result[columnElementId] = this.cellValueProvider.getCellValue(elementId, columnElementId);
        } else {
          // row attribute value
          const tableId = modelStore.getTableForElement(elementObject.id);
          const attributeDefinition = modelStore.getAttributeDefinition(tableId, colDef.field);
          result[colDef.field] = this.calculateCellValueFromInternalValue(elementObject[colDef.field], attributeDefinition);
        }
      });
    }

    const isSame = this.equals(result, lastHolder.last || {});
    if (isSame) {
      log.debug("RowDataUpdater: no change for element path", elementPathKey);
    } else {
      lastHolder.last = result;
      log.debug("RowDataUpdater: Created or updated Row", result);
    }
    return lastHolder.last;
  }

  private calculateCellValueFromInternalValue(internalValue: string | number, attributeDefinition: AttributeDefinition): string | number {
    let retVal: string | number;

    // to make ag-grid aware of the type, so e.g. charting, sum and other aggration works.
    if (attributeDefinition && attributeDefinition.formatType === "Double") {
      const value = typeof (internalValue) === "string" ? convertStringValueToNumber(internalValue, attributeDefinition) : internalValue;

      if (value === undefined || value === null) {
        retVal = "";
      } else if (typeof (value) === "number") {
        retVal = value;
      } else {
        retVal = renderPrefilledEditValue(<string>internalValue, attributeDefinition);
      }
    } else {
      retVal = renderPrefilledEditValue(<string>internalValue, attributeDefinition);
    }

    return retVal;
  }

  public dispose(): void {
    log.debug("Dispose RowDataUpdater scheduler and reactions");
    this.scheduler.dispose();
    for (const reactionDisposer of Array.from(this._idToRowUpdateReactionDisposer.values())) {
      reactionDisposer();
    }
    this._idToRowUpdateReactionDisposer.clear();
    this._rowsObserverDisposer && this._rowsObserverDisposer();
    this._rowsObserverDisposer = undefined;
    this._primarySelectionObserverDisposer && this._primarySelectionObserverDisposer();
    this._primarySelectionObserverDisposer = undefined;
    this._secondarySelectionObserverDisposer && this._secondarySelectionObserverDisposer();
    this._secondarySelectionObserverDisposer = undefined;
  }

}

