import * as React from "react";
import {createStyles, TextField, Theme, withStyles, WithStyles} from "@material-ui/core";
import {OutlinedTextFieldProps} from "@material-ui/core/TextField";
import {StyleRules} from "@material-ui/core/styles/withStyles";


const styles = (theme: Theme):StyleRules => createStyles({
  textFieldRoot: {
    width: "100%",
  },
  inputRoot: {
    margin: 0,
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.8)",
  },
  focused: {
    "& fieldset": {
      borderWidth: "1px !important",
      borderColor:  "rgba(0,0,0,0.25) !important",
    }
  },
  select: {
    padding: "0px 20px 0px 5px",
    margin: 2,
    fontSize: "12px",
    backgroundColor: "white !important", /* overwrite focused style, which is a light gray*/
    height: 18,
  },
  notchedOutline: {
    borderRadius: 0,
    border: "1px solid rgba(0,0,0,0.25)",
  },
  selectMenuPaperRoot: {
    borderRadius: 1,
  }
});


interface LocalState {
}

type StyledLocalProps = OutlinedTextFieldProps & WithStyles<typeof styles>;

class TextFieldComponent extends React.Component<StyledLocalProps, LocalState> {

  render(): JSX.Element {
    const {classes, ...props} = this.props;
    return <TextField
        className={this.props.classes.textFieldRoot}
        variant="outlined"
        {...props}
        InputProps={{
          name: "matrixModeSelect",
          id: "outlined-matrixModeSelect",
          classes: {
            input: this.props.classes.select,
            focused: this.props.classes.focused,
            root: this.props.classes.inputRoot,
            notchedOutline: this.props.classes.notchedOutline} }}/>;
  }
}

export default withStyles(styles)(TextFieldComponent);