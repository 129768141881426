import * as React from "react";
import Log from "../utils/Logger";
import {Classifier} from "../utils/ClassifierLogger";

type ToolbarLocation = "left" | "right";

interface LocalProps {
  icon: React.ReactNode;
  subtitle?: string;
}

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);


interface MetusSidebarEntryState {
  activeItem: number;
}

class MetusSidebarEntry extends React.Component<LocalProps, MetusSidebarEntryState> {

}

export default MetusSidebarEntry;
