// lib imports
import * as React from "react";
import Log from "../../../common/utils/Logger";
import TreeView from "../TreeView";
import {
  transformViewHierarchyAndViewInfosIntoTreeItems,
  TreeListItem
} from "../../../core/models/TreeViewModelTransformation";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {metusStore} from "../../stores/MetusStore";
import {observer} from "mobx-react";
import {CockpitData, OpenView} from "../../../api/api";
import {ViewType} from "../../../common/constants/Enums";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
}


@observer
class CockpitsNavigationContainer extends React.Component<LocalProps, {}> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering Cockpits Sidebar entry");

    const cockpitListItems: TreeListItem[] = transformViewHierarchyAndViewInfosIntoTreeItems(metusStore.cockpitNavigationTree, metusStore.allViewInfos, this.createOpenViewsFromCockpitData());

    return <React.Fragment>
      <TreeView listItems={cockpitListItems} type={"cockpits"}/>
    </React.Fragment>;
  }

  private createOpenViewsFromCockpitData(): OpenView[] {
    const openViews: OpenView[] = [];
    const cockpitData: CockpitData = metusStore.getCurrentCockpitData();
    if (cockpitData) {
      openViews.push({
        id: cockpitData.id,
        type: ViewType.Cockpit,
        windowPath: undefined
      });
    }
    return openViews;
  }

}

export default CockpitsNavigationContainer;
