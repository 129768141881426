import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {Dispatcher} from "../utils/Dispatcher";
import {ShowDialogAction} from "../actions/InteractionStateActions";
import autobind from "autobind-decorator";

interface LocalProps {
  open: boolean;
  message: string;
  onConfirmed: () => void;
  onRejected?: () => void;
}

type StyledLocalProps = LocalProps;

export class ConfirmationDialog extends React.Component<StyledLocalProps, any> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    return (
        <Dialog
            open={this.props.open}
            onClose={this.handleRejected}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
          <DialogContent>
            {this.props.message.split("\n").map((line, index) =>
                <DialogContentText id="alert-dialog-description" key={index}>{line}</DialogContentText>)}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleConfirmed} color="primary">Yes</Button>
            <Button onClick={this.handleRejected} color="secondary">No</Button>
          </DialogActions>
        </Dialog>
    );
  }

  @autobind
  private handleRejected(): void {
    Dispatcher.dispatch(new ShowDialogAction(false));
    this.props.onRejected && this.props.onRejected();
  }

  @autobind
  private handleConfirmed(): void {
    Dispatcher.dispatch(new ShowDialogAction(false));
    this.props.onConfirmed();
  }
}

