import * as React from "react";
import {
  Button,
  Checkbox,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import _ from "lodash";
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import MetusDialog from "./MetusDialog";
import autobind from "autobind-decorator";
import {hideDialog} from "../utils/CommonDialogUtil";
import {StyleRules} from "@material-ui/core/styles";
import MetusTextField from "./MetusTextField";
import {UUID} from "../../api/api";

const styles = (theme: Theme): StyleRules =>
    createStyles({
      allListOption: {
        "& > span": {
          fontWeight: "bold",
        },
      },

      listContainer: {
        display: "flex",
        flexDirection: "column"
      },

      root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 400,
        paddingTop: 0
      },

      buttonContainer: {
        display: "flex",
        paddingTop: "12px",
        paddingBottom: "8px",
        paddingRight: "10px"
      },

      buttonContained: {
        width: "auto",
        minWidth: "100px",
        backgroundColor: theme.metus.dialog.buttonFill,
        height: "auto",
        marginLeft: "auto", // align to the right if there is only one button
        padding: "5px"
      },
    });

export type ObjectIdAndTitle = {
  id: string;
  title: string;
};
const KEY_ENTER = 13;
const KEY_TAB = 9;

interface LocalProps {
  title: string;
  open: boolean;
  /**
   * if true, the component will not be shown as dialog on a separate layer, but just as div with an apply button
   */
  usePopupStyle: boolean;
  /**
   * objects which can be selected, by id and title; title will be displayed, id will be sent in selection changed callback
   */
  listObjects: ObjectIdAndTitle[];

  /**
   * ids of already selected objects, these will be check-marked
   */
  selectedObjectIds: string[];
  /**
   * sent if a checkmark is toggled for the use case where changes should happen immediately and without
   * requiring the user to click on the submit button
   * @param id id of toggled object
   * @param checked true if checkmark was set, false if not
   */
  onSelectionChanged?: (id: string, checked: boolean) => void;
  /**
   * sent when ok is pressed
   * @param selectedObjectIds: all selected object ids
   */
  onSubmit?: (toCreate: ObjectIdAndTitle[], toDelete: string[], selectedObjectIds: string[]) => void;

  /**
   * called if dialog was closed without submitting the changes
   */
  onDiscard?: () => void;

  /**
   * called if a new object is created to create an id for it
   */
  generateIdCallback: () => UUID;
}

interface LocalState {
  listObjects: ObjectIdAndTitle[];
  selectedObjectIds: string[];
  newObjectTitle: string,
}


type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

/**
 * dialog showing a list of objects with a checkmark;
 * toggling a checkmark will call the callback, which must update the selectedObjectIds property to reflect the new state
 */
export class ListSelectionDialog extends React.Component<StyledLocalProps, LocalState> {
  // elementIds of the elements which connections have been changed
  toToggle: string[];

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {
      listObjects: [...props.listObjects],
      selectedObjectIds: [...props.selectedObjectIds],
      newObjectTitle: null,
    };
    this.toToggle = [];
  }

  render(): JSX.Element {
    let result;

    const list = this.renderList();

    if (this.props.usePopupStyle) {
      // use key handler to determine end of editing
      result =
          <div onKeyDown={this.onKeyDown} className={this.props.classes.listContainer}>
            {list}
            <div className={this.props.classes.buttonContainer}>
              <Button
                  data-testselector={"SubmitButton"}
                  classes={{contained: this.props.classes.buttonContained}}
                  variant={"contained"}
                  type="button"
                  onClick={this.onSubmit}>{"OK"}
              </Button>
            </div>
          </div>;
    } else {
      // wrap dialog around
      result = <MetusDialog
          open={this.props.open}
          onClose={this.onDiscard}
          onPrimaryButtonPressed={this.onSubmit}
          title={this.props.title}>
        {list}
      </MetusDialog>;
    }

    return result;
  }

  private renderList(): JSX.Element {
    const listObjectIds = this.state.listObjects.map(({id}) => id);
    const areAllObjectsSelected = _.isEqual(_.sortBy(this.state.selectedObjectIds), _.sortBy(listObjectIds));

    return <List className={this.props.classes.root}>
      {this.state.listObjects.length > 0 &&
      <ListItem
          style={{display: "flex", flexDirection: "row", alignItems: "center"}}
          onClick={(): void => {
            if (areAllObjectsSelected) {
              this.setState({selectedObjectIds: []})
            } else {
              this.setState({selectedObjectIds: listObjectIds})
            }
          }}>
        <ListItemIcon>
          <Checkbox
              edge="start"
              checked={areAllObjectsSelected}
              tabIndex={-1}
              disableRipple
              inputProps={{'aria-labelledby': 'checkbox-list-label-select-all'}}
          />
        </ListItemIcon>
        <ListItemText id={'checkbox-list-label-select-all'} primary="All"/>
      </ListItem>
      }

      {this.state.listObjects.map(objectAndTitle => {
        const value = objectAndTitle.id;
        const labelId = `checkbox-list-label-${value}`;
        return (
            <ListItem
                key={value}
                role={undefined}
                dense
                button
                onClick={(): void => this.onSelectionChanged(value)}>
              <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={this.state.selectedObjectIds.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{'aria-labelledby': labelId}}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={objectAndTitle.title}/>
              <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(): void => {
                      this.onSelectionOptionDelete(value)
                    }}>
                  <DeleteIcon/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
        );
      })}

      {this.state.newObjectTitle !== null ? (
          <ListItem role={undefined} dense>
            <MetusTextField
                id="new-object-title"
                autoFocus={true}
                value={this.state.newObjectTitle}
                placeholder={"Name"}
                onChange={(evt) => {
                  this.setState({newObjectTitle: evt.target.value})
                }}
                onKeyPress={evt => {
                  if (evt.key === 'Enter') {
                    this.onAddNewObject()
                  }
                }}
            />
            <ListItemSecondaryAction>
              <IconButton
                  aria-label="add-icon"
                  disabled={this.state.newObjectTitle.length === 0}
                  onClick={this.onAddNewObject}>
                <DoneIcon/>
              </IconButton>
              <IconButton
                  edge="end"
                  aria-label="cancel-icon"
                  onClick={() => {
                    this.setState({newObjectTitle: null})
                  }}>
                <CloseIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
      ) : (
          <ListItem
              data-testselector={"newValue"}
              role={undefined}
              dense
              button
              onClick={() => {
                this.setState({newObjectTitle: ""})
              }}>
            <ListItemIcon>
              <AddIcon/>
            </ListItemIcon>
            <ListItemText primary="new Value"/>
          </ListItem>
      )}
    </List>;
  }

  @autobind
  private onAddNewObject(): void {
    const newObject = {
      id: this.props.generateIdCallback(),
      title: this.state.newObjectTitle,
    };
    this.setState(state => ({
      listObjects: [
        ...state.listObjects,
        newObject
      ],
      newObjectTitle: null,
    }));
  }

  @autobind
  private onSelectionOptionDelete(id: string): void {
    const listObjects = this.state.listObjects.filter(({id: objectId}) => id !== objectId)
    const selectedObjectIds = this.state.selectedObjectIds.filter(selectedId => id !== selectedId);
    this.setState(state => ({listObjects, selectedObjectIds}));
  }

  @autobind
  private onSelectionChanged(id: string): void {
    const selectedObjectIds = [...this.state.selectedObjectIds];
    const checked: boolean = !selectedObjectIds.includes(id);
    if (checked) {
      selectedObjectIds.push(id);
    } else {
      const idx = selectedObjectIds.indexOf(id);
      selectedObjectIds.splice(idx, 1);
    }
    // update connections that have to be toggled afterwards
    // if a connection already has been changed it gets removed from the list
    const index = this.toToggle.indexOf(id);
    if (index !== -1) {
      this.toToggle.splice(index, 1);
    } else {
      this.toToggle.push(id);
    }
    this.setState({selectedObjectIds});
    this.props.onSelectionChanged && this.props.onSelectionChanged(id, checked);
  }

  @autobind
  private onSubmit(): void {
    if (!this.props.usePopupStyle) {
      hideDialog();
    }
    // compare initial props and current state in order to extract the nesseccary actions to get to the desired state
    const toDelete: string[] = this.props.listObjects.filter(listObj => !this.state.listObjects.includes(listObj)).map(listObj => listObj.id);
    const toCreate: ObjectIdAndTitle[] = this.state.listObjects.filter(listObj => !this.props.listObjects.includes(listObj));
    const connectionsToDelete = this.props.selectedObjectIds.filter(selObj => !this.state.selectedObjectIds.includes(selObj));
    const connectionsToCreate = this.state.selectedObjectIds.filter(selObj => !this.props.selectedObjectIds.includes(selObj));
    const toToggle = connectionsToCreate.concat(connectionsToDelete).filter(toT => !toDelete.includes(toT));
    this.props.onSubmit && this.props.onSubmit(toCreate, toDelete, toToggle);
  }

  @autobind
  private onDiscard(): void {
    hideDialog();
    this.props.onDiscard && this.props.onDiscard();
  }

  @autobind onKeyDown(event): void {
    if (this.finishedEditingPressed(event)) {
      this.onSubmit();
    }
  }

  getCharCodeFromEvent(event): number {
    event = event || window.event;
    return (typeof event.which === "undefined") ? event.keyCode : event.which;
  }

  finishedEditingPressed(event) {
    const charCode = this.getCharCodeFromEvent(event);
    return charCode === KEY_ENTER || charCode === KEY_TAB;
  }
}

export default withStyles(styles)(ListSelectionDialog);

