import {IStaticFilterMatcher} from "./IStaticFilterMatcher";

export class ContainsCaseInsensitiveStringStaticFilterMatcher implements IStaticFilterMatcher {
  private _operand: string;

  constructor(operand: string) {
    this._operand = operand;
  }

  valueSatisfiesFilterCondition(value: any): boolean {
    let result = false;
    if (value !== null && value !== undefined && this._operand !== null && this._operand !== undefined) {
      result = value.toLowerCase().indexOf(this._operand.toLowerCase()) >= 0;
    }
    return result;
  }

}
