import {FilteredHierarchy} from "./FilteredHierarchy";
import {observable} from "mobx";
import {AttributeId, AttributeIdString, TableId, VisualAttributeId, VisualTableId} from "../../core/utils/Core";
import Log from "../../common/utils/Logger";
import {map, primitive, serializable} from "serializr";
import {createAttributeFilterForMatcher, IFilter} from "../../core/utils/filter/Filter";
import {getFilterMatcher} from "../../core/utils/filter/FilterMatcher";
import {NAME_ATT_NAME} from "../../api/api";
import {ConnectedToSelectionFilter} from "./ConnectedToSelectionFilter";

const log = Log.logger("model");

export class RowFilteredHierarchy extends FilteredHierarchy {
  @observable private readonly _uniformFilterExpressionValidityMap: Map<string, boolean>;
  get showConnectedOnly(): boolean {
    return this._showConnectedOnly;
  }

  set showConnectedOnly(value: boolean) {
    this._showConnectedOnly = value;
  }
  @serializable @observable private _showConnectedOnly: boolean;
  /** filterDefinition expressions as string per visual attribute */
  @serializable(map(primitive())) @observable private readonly _attributeIdKeyToFilterExpression: Map<AttributeIdString, string>;

  constructor(tableIds: VisualTableId[] = [], attributeIds: AttributeId[] = []) {
    super(tableIds, attributeIds);
    this._attributeIdKeyToFilterExpression = new Map();
    this._uniformFilterExpressionValidityMap = new Map();
    this._showConnectedOnly = false;
  }

  get uniformFilterExpressionValidityMap(): Map<string, boolean> {
    return this._uniformFilterExpressionValidityMap;
  }

  get attributeIdKeyToFilterExpression(): Map<AttributeIdString, string> {
    return this._attributeIdKeyToFilterExpression;
  }

  public setFilterExression(attributeId: AttributeId, expression: string): void {
    this.attributeIdKeyToFilterExpression.set(attributeId.toKey(), expression);
  }



  public getFilterExression(attributeId: AttributeId): string {
    return this.attributeIdKeyToFilterExpression.get(attributeId.toKey());
  }

  public resetFilter():void {
    this.attributeIdKeyToFilterExpression.clear();
  }

  public setFilter(tableId: TableId, filterExpression: string, attributeName: string = NAME_ATT_NAME): void {
    this.setFilterExression(new AttributeId(tableId, attributeName), filterExpression);
  }

  public setFilterForAllTables(filterExpression: string, attributeName: string = NAME_ATT_NAME): void {
    this.tables.forEach(visualTableId => {
      this.setFilterExression(new AttributeId(visualTableId.tableId, attributeName), filterExpression);
    });
    this._uniformFilterExpressionValidityMap.set(attributeName, this.isUniformFilterExpressionValid(attributeName));
  }

  // compares all filterexpressions of attributes with the given name
  // if all of them are the same it returns the filterDefinition expression
  // if one of them doesn't match it return an empty string
  public getUniformFilterExpression(attributeName: string): string {
    const attributeIds = this.tables.map(tableId => {
      return new AttributeId(tableId.tableId, attributeName);
    });
    const filterExpression = this.getFilterExression(attributeIds.pop());
    attributeIds.forEach(attId => {
      if (filterExpression !== this.getFilterExression(attId)) {
        return "";
      }
    });
    return filterExpression || "";
  }

  public isUniformFilterExpressionValid(attributeName: string): boolean {
    const filterExpression = this.getUniformFilterExpression(attributeName);
    if (filterExpression.length > 0) {
      const matched = this.tables.map(table => {
        return !!getFilterMatcher(filterExpression, table.tableId, attributeName);
      });
      return !matched.includes(false);
    } else {
      return true;
    }
  }

  getFilterForTable(tableId: TableId): IFilter[] {
    const result: IFilter[] = [];
    Array.from(this.attributeIdKeyToFilterExpression.entries())
        .map(([key, filter]) => {
          return [AttributeId.fromKey(key as string), filter];
        })
        .filter(([attributeId, filter]) => typeof attributeId === "string" || (attributeId as AttributeId).tableId === tableId)
        .forEach(([attributeId, filter]) => {
          const attributeName = (attributeId as AttributeId).attributeName;
          const filterExpression = this.getFilterExression(attributeId as AttributeId);
          if (filterExpression) {
                // TODO: use SimpleMatcher?

                const matcher = getFilterMatcher(filterExpression, tableId, attributeName);
                const filter = createAttributeFilterForMatcher(attributeName, tableId, filterExpression, matcher);
                if (filter) {
                  result.push(filter);
                }
              }
            }
        );
    if (this.showConnectedOnly) {
      result.push(new ConnectedToSelectionFilter());
    }
    return result;
  }

  public removeAttribute(id: VisualAttributeId | AttributeId): boolean {
    const attKey = id.toKey();
    this._attributeIdKeyToFilterExpression.delete(attKey);
    return super.removeAttribute(id);
  }
}


