import StoreBase from "./StoreBase";
import {Cursor} from "../constants/Enums";
import {DndTargetFeedbackAction, InteractionStateActions} from "../actions/InteractionStateActions";

import Log from "../utils/Logger";
import {Classifier} from "../utils/ClassifierLogger";
import {ServerRequestInfo} from "../actions/BaseAction";
import {ClientLocation} from "../utils/Geometry";
import {action, computed, observable} from "mobx";

const log = Log.logger("InteractionStore", Classifier.action);

interface DialogAndMessage {
  errorMessage: string,
  dialog: JSX.Element,
}

export class InteractionStore extends StoreBase {
  /** feedback rendered when dragging but no target accepts it yet */
  private _dragSourceFeedback: JSX.Element;
  /** feedback rendered when a target is hovered which might accept the drag */
  private _dropTargetFeedback: JSX.Element | string;
  /**
   * if drop target feedback should not move with the mouse, this is the client position to use
   */
  @observable private _dropTargetFeedbackLocation?: ClientLocation;

  /** cursor shown when drag in progress, defaults to NO_DROP allowed */
  @observable private _dropTargetCursor: Cursor = Cursor.NO_DROP;

  @observable private _loading: number = 0;

  // authentication state variables
  /** holds the original request info if during a reqest authentication is needed */
  @observable private _originalRequest: ServerRequestInfo;
  /** true if user/pwd was sent for authentication, but not determined yet if valid  */
  @observable private _isAuthenticationInProgress: boolean = false;
  /** true if authentication failed since during authentication in progress another authentication needes was signalled */
  @observable private _isAuthenticationFailed: boolean = false;
  /** array of dialogs to show; Only the last dialog is shown */
  @observable private _dialogs: DialogAndMessage[] = [];

  constructor() {
    super(false);
  }

  get dialogs(): DialogAndMessage[] {
    return this._dialogs;
  }

  get dropTargetCursor(): Cursor {
    return this._dropTargetCursor;
  }

  get dropTargetFeedback(): JSX.Element | string {
    return this._dropTargetFeedback;
  }

  get dropTargetFeedbackLocation(): ClientLocation {
    return this._dropTargetFeedbackLocation;
  }

  get dragSourceFeedback(): JSX.Element {
    return this._dragSourceFeedback;
  }

  get originalRequest(): ServerRequestInfo {
    return this._originalRequest;
  }

  get isAuthenticationInProgress(): boolean {
    return this._isAuthenticationInProgress;
  }

  get isAuthenticationFailed(): boolean {
    return this._isAuthenticationFailed;
  }

  reset(): void {
    this._dialogs = [];
    this._dragSourceFeedback = undefined;
    this._dropTargetFeedback = undefined;
    this._dropTargetFeedbackLocation = undefined;
    this._dropTargetCursor = Cursor.NO_DROP;
    this._loading = 0;
    this._isAuthenticationInProgress = false;
    this._isAuthenticationFailed = false;
    this._originalRequest = undefined;
  }

  @action
  accept(actionParam: InteractionStateActions): void {
    log.debug("InteractionStore accepting " + actionParam.type);
    switch (actionParam.type) {
      case "show_loading":
        // processing and notification is done here
        this._loading++;
        if (this._loading === 1) {
          log.debug("InteractionStore accepted " + actionParam.type, actionParam);
          // changed from 0 to 1
          this.notify(actionParam.type);
        }
        break;
      case "hide_loading":
        // processing and notification is done here
        this._loading--;
        log.debug("InteractionStore accepted " + actionParam.type, actionParam);
        if (this._loading === 0) {
          // changed from 1 to 0
          this.notify(actionParam.type);
        }
        break;
      case "dndSourceFeedback":
        this._dragSourceFeedback = actionParam.payload;
        break;
      case "dndTargetFeedback":
        const {cursor, targetFeedback, targetFeedbackLocation} = (actionParam as DndTargetFeedbackAction).payload;
        this._dropTargetFeedback = targetFeedback;
        this._dropTargetFeedbackLocation = targetFeedbackLocation;
        if (cursor) {
          this._dropTargetCursor = cursor;
        }
        break;
      case "dndClearDragState":
        this._dragSourceFeedback = undefined;
        this._dropTargetFeedback = undefined;
        this._dropTargetFeedbackLocation = undefined;
        // default cursor is forbidden if no drop target feedback is given
        this._dropTargetCursor = Cursor.NO_DROP;
        break;
      case "mosaicWindowDragStart":
        this._dropTargetCursor = Cursor.MOVE;
        break;
      case "authenticationNeeded":
        // a request was sent which needs authentication, so this is interrupted for login, then continued afterwards
        if (this._isAuthenticationInProgress) {
          // if already in progress, the login credentials did not work, thus reset in progress and set failed
          this._isAuthenticationFailed = true;
          this._isAuthenticationInProgress = false;
        } else {
          this._originalRequest = actionParam.payload;
        }
        break;
      case "authenticationInProgress":
        if (this._isAuthenticationInProgress) {
          this._originalRequest = undefined;
          this._isAuthenticationFailed = true;
        } else {
          this._isAuthenticationInProgress = actionParam.payload;
        }
        break;
      case "authenticated":
        this._isAuthenticationInProgress = false;
        this._isAuthenticationFailed = false;
        this._originalRequest = undefined;
        break;
      case "showDialog":
        log.debug("Show Dialog", actionParam.payload);
        if (actionParam.payload.show) {
          this._dialogs.push({errorMessage: undefined, dialog: actionParam.payload.dialog});
          this._dialogs[this._dialogs.length - 1].dialog.key = this._dialogs.length - 1;
        } else {
          this._dialogs.pop();
        }
        this.notify(actionParam.type);
        break;
      case "showErrorDialog":
        log.debug("showErrorDialog", actionParam.payload);
        let payload = actionParam.payload;
        if (actionParam.payload.show) {
          this._dialogs.push({errorMessage: payload.message, dialog: payload.dialog});
          this._dialogs[this._dialogs.length - 1].dialog.key = this._dialogs.length - 1;
        } else {
          this._dialogs.pop();
        }
        break;
      case "setAuthenticationFailed":
        log.debug("setAuthenticationFailed", actionParam.payload);
        this._isAuthenticationFailed = actionParam.payload;
        this._isAuthenticationInProgress = false;
        break;
    }

  }

  @computed get isLoading(): boolean {
    return this._loading !== 0;
  }

  @computed get isRequestNeedingAuthentication(): boolean {
    return this._originalRequest !== undefined;
  }

  @computed get isLoginDialogVisible(): boolean {
    return this.isRequestNeedingAuthentication || this.isAuthenticationInProgress;
  }
}

// singleton
// export default new InteractionStore();
const interactionStore = new InteractionStore();
export {interactionStore};
