import {SVGScalingConverter} from "../../../common/utils/CoordinateUtil";
import {VisualBaseTable} from "../../models/common/CommonDiagramTypes";
import {ColumnUpdate, ColumnUpdateMode} from "../../models/common/ContainerColumnLayout";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import * as React from "react";
import DnDInsertAttributeIcon from "../../../common/icons/constructionHelper/DnDInsertAttributeIcon";
import {Point} from "../../../common/utils/Geometry";
import {VisualValueChartLevel} from "../../models/valuechart/VisualValueChartLevel";
import {VisualValueChart} from "../../models/valuechart/VisualValueChart";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {DndTargetFeedbackAction} from "../../../common/actions/InteractionStateActions";
import {Cursor, ViewType} from "../../../common/constants/Enums";
import {DropTargetConnector, DropTargetMonitor, DropTargetSpec, XYCoord} from "react-dnd";
import {DiagramModel} from "../../models/DiagramModel";
import DragTypes, {default as DragType} from "../../../common/constants/DragTypes";
import {NodeProps} from "../../../workbench/components/TreeListItemComponent";
import {AttributeId, TableId, VisualAttributeId, VisualElementId, VisualTableId} from "../../../core/utils/Core";
import {ElementSourceDragInfo, VisualTableMovingItem} from "../../../common/utils/CoreDropTypes";
import {
  MoveAttributeColumnAction,
  MoveElementsAction,
  MoveTableColumnAction,
  ScrollCoordinates,
  ScrollViewAction
} from "../../actions/DiagramActions";
import {VisualElementSourceDragInfo} from "../../../commonviews/constants/DiagramDragDropTypes";
import DnDInsertTableValueChartIcon from "../../../common/icons/constructionHelper/DnDInsertTableValueChartIcon";
import DnDInsertTableChartIcon from "../../../common/icons/constructionHelper/DnDInsertTableChartIcon";
import Log from "../../../common/utils/Logger";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {DiagramComponentProps} from "../../models/DiagramComponentProps";
import {AttributeMovingItem} from "../../../commonviews/components/AttributeHeaderComponent";
import {Validate} from "../../../common/utils/Validate";
import {addAttributeToView, addTableToDiagram} from "../../../commonviews/actions/SharedViewAsyncActions";
import {dragTypeAdapterManager} from "../../../commonviews/utils/DragTypeAdapterManager";
import {SourceDragInfo} from "../../../common/utils/DragTypeAdapter";
import {FeedbackHelper} from "../../../common/utils/DragDropHelper";
import {moveElementsBetweenTables} from "../../../core/actions/CoreAsyncActionCreators";

const dndLog = Log.logger("diagram", Classifier.dnd);

/**
 * drag drop needs this for feedback coordinate transformation
 */
export interface CoordinateConverterProvider {
  getCoordinateConverter(): SVGScalingConverter;
  getScrollController(): ScrollController;
  convertDeltaToSVG(d: number): number;
}

export interface ScrollController {
  calculateNewScrollCoordinatesOnMouseHover(mousePosition: { x: number, y: number }): ScrollCoordinates;
  getCurrentScrollCoordinates(): ScrollCoordinates;
}

function getCoordinateConverter(component: CoordinateConverterProvider): SVGScalingConverter {
  return component.getCoordinateConverter();
}

function getVisualTableColumnAtMouseLocation(diagram, targetPosition1: { x: number; y: number }) {
  // currently only implemented for charts
  const visualTables = diagram.visualTables;
  const focusedTable = visualTables.find(visualTable => visualTable.bounds.x <= targetPosition1.x && targetPosition1.x <= visualTable.bounds.x + visualTable.bounds.width);
  return focusedTable;
}

function getVisualTableForTableAtMouseLocation(diagram: DiagramModel, tableId: TableId, targetPosition1: { x: number; y: number }) {
  // sort by y height ascending so the smallest is searched first in case of nested tables
  const visualTables = diagram.getVisualTablesForTable(tableId).sort((t1, t2) => t1.header.height - t2.header.height);
  const focusedTable = visualTables.find(visualTable => visualTable.header.x <= targetPosition1.x && targetPosition1.x <= visualTable.header.x + visualTable.header.width && visualTable.header.y <= targetPosition1.y && targetPosition1.y <= visualTable.header.y + visualTable.header.height);
  return focusedTable;
}

interface CustomDragState {
  initialClientOffset?: XYCoord;
  initialScrollCoordinates?: ScrollCoordinates;
}

const diagramDropTargetCreator = (customState: CustomDragState): DropTargetSpec<DiagramComponentProps> => ({

  hover(props: DiagramComponentProps, monitor: DropTargetMonitor, component: CoordinateConverterProvider): void {
    dndLog.debug("Hover", monitor.getItemType(), monitor.getItem());
    const diagram: DiagramModel = props.viewModel;
    const mousePosition: XYCoord = monitor.getClientOffset() ? monitor.getClientOffset() : {x: 100, y: 100};
    switch (monitor.getItemType()) {
      case DragTypes.CORE_TABLE:
        const isOverCurrent = monitor.isOver({shallow: true});
        if (isOverCurrent) {
          const tableItem: NodeProps = monitor.getItem() as NodeProps;
          dndLog.debug("Setting Table target feedback");
          Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.COPY, targetFeedbackIcon(diagram.type)));
        }
        break;
      case DragTypes.CORE_ATTRIBUTE_DEFINITION:
        const attItem: NodeProps = monitor.getItem() as NodeProps;
        dndLog.debug("Setting Attribute target feedback");
        const attributeId: AttributeId = AttributeId.fromKey(attItem.id);

        // Comes from other View thus initial position irrelevant
        const targetPosition1: { x: number, y: number } = getCoordinateConverter(component).convertClientToSVG(mousePosition.x, mousePosition.y);

        if (props.viewModel.tableIds.indexOf(attributeId.tableId) !== -1) {
          const tableAtMouseLocation = getVisualTableForTableAtMouseLocation(diagram, attributeId.tableId, targetPosition1);
          if (tableAtMouseLocation) {
            createAttributeInsertTargetFeedback(
                getCoordinateConverter(component),
                tableAtMouseLocation,
                attributeId.attributeName,
                targetPosition1.x);
          }
        } else {
          Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.NO_DROP, `can not add, because table for attribute is not on the diagram`));
        }
        break;
      case DragTypes.VIEW:
        dndLog.debug("Setting View target feedback");
        const item: NodeProps = monitor.getItem() as NodeProps;
        Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.MOVE, <img
            src={require("../../../common/images/metusChart.svg")} width="400" alt="Chart"/>));
        break;
      case DragTypes.VISUAL_ELEMENTS:
        dndLog.debug("Setting visual elements target feedback cursor");
        if (customState.initialClientOffset !== monitor.getInitialClientOffset()) {
          // a new drag started, saving current scroll coordinates for initial client offset
          customState.initialClientOffset = monitor.getInitialClientOffset();
          customState.initialScrollCoordinates = component.getScrollController().getCurrentScrollCoordinates();
        }
        if (props.viewModel.type === ViewType.Chart) {
          dndLog.debug("Setting visual elements target feedback for Charts");
          const visualElementItem: VisualElementSourceDragInfo = monitor.getItem() as VisualElementSourceDragInfo;
          const selectedSourceTableId = visualElementItem.selectedSourceTableIds.values().next().value;
          const scrollCoordinates = component.getScrollController().calculateNewScrollCoordinatesOnMouseHover(mousePosition);
          if (scrollCoordinates) {
            Dispatcher.dispatch(new ScrollViewAction(props.windowIndex, scrollCoordinates));
          }
          const mousePositionInSVG: { x: number, y: number } = getCoordinateConverter(component).convertClientToSVG(mousePosition.x, mousePosition.y);
          const tableAtMouseLocation = getVisualTableColumnAtMouseLocation(diagram, mousePositionInSVG);
          // only show table droptarget feedback if there is a single table as source and the table is not the same table the target table
          if (visualElementItem.selectedSourceTableIds.size === 1 && tableAtMouseLocation && tableAtMouseLocation.id.tableId !== selectedSourceTableId) {
            dndLog.debug("Setting visual elements target feedback moving elements to another table");
            const clientTableBounds = component.getCoordinateConverter().convertSVGToClient(tableAtMouseLocation.bounds.x, tableAtMouseLocation.bounds.y, tableAtMouseLocation.bounds.width, tableAtMouseLocation.bounds.height);
            const visibleSVGRect = component.getCoordinateConverter().getVisibleSVGRect();
            const clientVisibleSVGRect = component.getCoordinateConverter().convertSVGToClient(visibleSVGRect.x, visibleSVGRect.y, visibleSVGRect.width, visibleSVGRect.height);
            const feedbackY = Math.max(tableAtMouseLocation.bounds.y, visibleSVGRect.y);
            const feedbackHeight = tableAtMouseLocation.bounds.y > visibleSVGRect.y ? visibleSVGRect.height - (tableAtMouseLocation.bounds.y - visibleSVGRect.y) : clientVisibleSVGRect.height;
            const box = FeedbackHelper.createTargetFeedbackBoxFromBounds(0, 0, clientTableBounds.width, feedbackHeight);
            const feedbackPosition: Point = getCoordinateConverter(component).convertSVGPointToClient(tableAtMouseLocation.bounds.x, feedbackY);
            Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.MOVE, box, feedbackPosition));
          } else {
            dndLog.debug("Setting visual elements target feedback for moving elements position");
            Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.MOVE));
          }
        }
        break;
      case DragTypes.RESIZE_HANDLE:
        dndLog.debug("Setting resize target feedback cursor");
        Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.EW_RESIZE));
        break;
      case DragTypes.VISUAL_TABLE_COLUMN_MOVE:
        dndLog.debug("Setting target feedback for visual table column move");
        Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.MOVE));
        break;

      case DragTypes.ATTRIBUTE_COLUMN_MOVE:
        // Comes from other View thus initial position irrelevant
        const targetPosition: { x: number, y: number } = getCoordinateConverter(component).convertClientToSVG(mousePosition.x, mousePosition.y);
        const attributeMovingItem: AttributeMovingItem = monitor.getItem() as AttributeMovingItem;

        const table = diagram.getVisualTable(attributeMovingItem.attributeId.visualTableId);
        if (table) {
          const dx = monitor.getClientOffset().x - monitor.getInitialClientOffset().x;
          createAttributeInsertTargetFeedback(getCoordinateConverter(component), table, attributeMovingItem.attributeId.attributeName, dx);
        }
        break;
      default:
        dndLog.error("Hovering with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
        break;
    }
  },

  canDrop(props: DiagramComponentProps, monitor: DropTargetMonitor): boolean {
    dndLog.debug("Hover", monitor.getItemType(), monitor.getItem());
    const diagram: DiagramModel = props.viewModel;
    const mousePosition: XYCoord = monitor.getClientOffset() ? monitor.getClientOffset() : {x: 100, y: 100};
    let result: boolean = false;
    switch (monitor.getItemType()) {
      default:
        result = true;
        break;
      case DragTypes.RESIZE_HANDLE:
      case DragTypes.VISUAL_TABLE_COLUMN_MOVE:
      case DragTypes.ATTRIBUTE_COLUMN_MOVE:
        result = true;
        break;

      case DragTypes.CORE_ATTRIBUTE_DEFINITION:
        const attItem: NodeProps = monitor.getItem() as NodeProps;
        const attributeId: AttributeId = AttributeId.fromKey(attItem.id);
        // Comes from other View thus initial position irrelevant
        result = props.viewModel.tableIds.indexOf(attributeId.tableId) !== -1;
        break;
    }
    return result;
  },
  drop(props: DiagramComponentProps, monitor: DropTargetMonitor, component: CoordinateConverterProvider): Object {
    dndLog.debug("Dropped " + monitor.getItemType().toString());
    // avoid NPE accessing monitor.getClient
    if (monitor.canDrop()) {
      let result = undefined;
      const viewInfo = props.viewInfo;
      const viewId = viewInfo.id;
      // there is no mousePosition when Test Backend is being used
      const mousePosition: XYCoord = monitor.getClientOffset() ? monitor.getClientOffset() : {x: 100, y: 100};
      // Comes from other View thus initial position irrelevant
      const targetPosition: { x: number, y: number } = getCoordinateConverter(component).convertClientToSVG(mousePosition.x, mousePosition.y);
      let dx: number;
      let dy: number;
      switch (monitor.getItemType()) {
        case DragTypes.CORE_TABLE:
          const hasDroppedOnChild = monitor.didDrop();
          if (!hasDroppedOnChild) {
            dndLog.debug(`Adding table to diagram ${viewInfo.name}`);
            result = {
              viewInfo,
              targetPosition,
              windowPath: props.windowPath,
              windowIndex: props.windowIndex,
            };
          }
          break;
        case DragTypes.VISUAL_TABLE_COLUMN_MOVE:
          const dragItem: VisualTableMovingItem = monitor.getItem() as VisualTableMovingItem;
          const tableId = dragItem.tableId;
          const dx2 = monitor.getClientOffset().x - monitor.getInitialClientOffset().x;
          const convertedDx = component.convertDeltaToSVG(dx2);
          if (props.viewModel.tableIds.includes(tableId) && viewId === dragItem.viewId) {
            result = {viewId, dx2};
            Dispatcher.dispatch(new MoveTableColumnAction(viewId, convertedDx, 0, new VisualTableId(dragItem.tableId, dragItem.visualId)));
          } else {
            addTableToDiagram(viewId, dragItem.tableId, {x: targetPosition.x, y: targetPosition.y});
            result = {viewId, targetPosition};
          }
          break;
        case DragTypes.CORE_ATTRIBUTE_DEFINITION:
          const diagram: DiagramModel = props.viewModel;
          const attItem: NodeProps = monitor.getItem() as NodeProps;
          const attributeId: AttributeId = AttributeId.fromKey(attItem.id);
          dndLog.debug(`Attribute dropped, creating action`, attributeId);

          // Comes from other View thus initial position irrelevant
          const targetPosition1: { x: number, y: number } = getCoordinateConverter(component).convertClientToSVG(mousePosition.x, mousePosition.y);

          Validate.isTrue(props.viewModel.tableIds.indexOf(attributeId.tableId) !== -1, "Table for Attribute is not contained in this view, this should have been captured by canDrop");
          const tableAtMouseLocation = getVisualTableForTableAtMouseLocation(diagram, attributeId.tableId, targetPosition1);
          if (tableAtMouseLocation) {
            const visualAttributeId = new VisualAttributeId(tableAtMouseLocation.id, attributeId.attributeName);
            addAttributeToView(viewInfo.id, visualAttributeId, targetPosition, false, false);
          }
          break;

        case DragTypes.VIEW:
          dndLog.debug(`Adding model part to ${viewId}`);
          result = {
            viewId,
            targetPosition,
            windowPath: props.windowPath,
            windowIndex: props.windowIndex,
          };
          break;
        case DragTypes.RESIZE_HANDLE:
          dndLog.debug(`Dragging Resize Handle at ${viewId}`);
          [dx, dy] = [monitor.getClientOffset().x - monitor.getInitialClientOffset().x, monitor.getClientOffset().y - monitor.getInitialClientOffset().y];
          result = {viewId, dx, dy};
          break;
        case DragTypes.VISUAL_ELEMENTS:
          if (props.viewModel.type === ViewType.Chart) {
            const visualElementItem: VisualElementSourceDragInfo = monitor.getItem() as VisualElementSourceDragInfo;
            const elementSourceDragInfo: ElementSourceDragInfo = dragTypeAdapterManager.adapt(monitor.getItem() as SourceDragInfo, DragType.CORE_ELEMENTS) as ElementSourceDragInfo;
            const selectedElementIds = elementSourceDragInfo.selectedElements.add(visualElementItem.sourceElementId);
            const selectedSourceTableId = visualElementItem.selectedSourceTableIds.values().next().value;
            const coordinatConverter = getCoordinateConverter(component);
            const mousePositionInSVG: { x: number, y: number } = coordinatConverter.convertClientToSVG(mousePosition.x, mousePosition.y);
            const visualTableAtMouseLocation = getVisualTableColumnAtMouseLocation(props.viewModel, mousePositionInSVG);
            if (visualElementItem.selectedSourceTableIds.size === 1 && visualTableAtMouseLocation && visualTableAtMouseLocation.id.tableId !== selectedSourceTableId) {
              dndLog.debug(`Drag Moving Visual Elments to a differnent table ${viewId}`);
              moveElementsBetweenTables(Array.from(selectedElementIds), selectedSourceTableId, visualTableAtMouseLocation.id.tableId, false, mousePositionInSVG.y);
            } else {
              dndLog.debug(`Drag Moving Visual Elments to a differnent x position ${viewId}`);
              [dx, dy] = [0, monitor.getClientOffset().y - monitor.getInitialClientOffset().y];
              const item: VisualElementSourceDragInfo = monitor.getItem() as VisualElementSourceDragInfo;
              const scrollDy = component.getScrollController().getCurrentScrollCoordinates().scrollTop - customState.initialScrollCoordinates.scrollTop;
              const visualsToMove = Array.from(item.selectedVisuals).map((key: string) => VisualElementId.fromKey(key));
              Dispatcher.dispatch(new MoveElementsAction(props.viewModel.id, 0, component.convertDeltaToSVG(dy + scrollDy), visualsToMove));
              result = {viewId, dx, dy};
            }
          }
          break;
        case DragTypes.ATTRIBUTE_COLUMN_MOVE:
          const dx1 = monitor.getClientOffset().x - monitor.getInitialClientOffset().x;
          const attributeMovingItem: AttributeMovingItem = monitor.getItem() as AttributeMovingItem;
          Dispatcher.dispatch(new MoveAttributeColumnAction(viewId, dx1, 0, attributeMovingItem.attributeId));
          result = {viewId, dx1};
          break;
        default:
          dndLog.error("Drop with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
          break;
      }
      dndLog.debug("DiagramComponent.drop returning " + result, result);
      return result;
    }
  }
});

// needed to maintain a local state during DnD
export const diagramDropTarget = diagramDropTargetCreator({});


/**
 * create insert bar during drag operations of attributes or attribute headers
 */
function createAttributeInsertTargetFeedback(converter: SVGScalingConverter, table: VisualBaseTable, attributeName: string, dx: number): void {
  dndLog.debug("Setting target feedback for attribute move");
  let targetFeedback: JSX.Element;
  let targetFeedbackDiagramPosition: Point;

  const columnUpdate = calcColumnUpdate(table, attributeName, dx);

  if (columnUpdate.mode !== ColumnUpdateMode.MOVE) {
    const scale = converter ? converter.scale : 1.0;
    targetFeedback = <React.Fragment>
      <div style={{
        borderLeft: "3px solid red",
        height: scale * table.attributeHeaderHeight
      }}/>
      <DnDInsertAttributeIcon style={{fontSize: 1500}}/>
    </React.Fragment>;

    targetFeedbackDiagramPosition = calcTargetFeedbackDiagramPosition(table, attributeName, columnUpdate);
  }

  Dispatcher.dispatch(
      new DndTargetFeedbackAction(
          Cursor.MOVE,
          targetFeedback,
          targetFeedbackDiagramPosition ? converter.convertSVGPointToClient(targetFeedbackDiagramPosition.x, targetFeedbackDiagramPosition.y) : undefined));
}

function calcColumnUpdate(table: VisualBaseTable, attributeName: string, dx: number): ColumnUpdate {
  let retVal: ColumnUpdate;
  const att = table.getAttribute(attributeName);
  if (att) {
    // move attribute header
    const updatedChartColumn = {name: attributeName, x: att.header.x + dx, width: 0};
    retVal = table.attributeColumnLayout.createChartColumnUpdate(updatedChartColumn);
  } else {
    // add new attribute
    const updatedChartColumn = {name: attributeName, x: dx, width: DiagramVisualConstants.DEFAULT_ATT_WIDTH};
    retVal = table.attributeColumnLayout.createChartColumnUpdateForNewColumn(updatedChartColumn);
  }
  return retVal;
}

function calcYCoordinate(table: VisualBaseTable, attributeName: string): number {
  let retVal;
  const att = table.getAttribute(attributeName);
  if (att) {
    retVal = att.header.y;
  } else {
    retVal = DiagramVisualConstants.TABLE_HEADER_YPOS + DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH;
  }
  return retVal;
}

function calcTargetFeedbackDiagramPosition(table: VisualBaseTable, attributeName: string, columnUpdate: ColumnUpdate): Point {
  let retVal: Point;

  const y = calcYCoordinate(table, attributeName);
  if (columnUpdate.target) {
    if (columnUpdate.mode === ColumnUpdateMode.INSERT_BEFORE) {
      retVal = new Point(columnUpdate.target.x - DiagramVisualConstants.ATTRIBUTE_HEADER_GAP / 2 - 1, y);
    } else if (columnUpdate.mode === ColumnUpdateMode.INSERT_AFTER) {
      retVal = new Point(columnUpdate.target.x + columnUpdate.target.width + DiagramVisualConstants.ATTRIBUTE_HEADER_GAP / 2, y);
    }
  } else {
    // if no target column found (e.g. empty column), position at header x and fixed y
    retVal = new Point(table.header.x, y);
  }

  if (table instanceof VisualValueChartLevel) {
    // if relative position, calculate absolute position
    const dx = (table.parent as VisualValueChart).bounds.x;
    const dy = table.header.y;
    retVal = retVal.add(dx, dy);
  }

  return retVal;
}

function targetFeedbackIcon(viewType: ViewType): JSX.Element {
  let retVal: JSX.Element;
  if (ViewType.ValueChart === viewType) {
    retVal = <DnDInsertTableValueChartIcon style={{fontSize: 1500}}/>;
  } else if (ViewType.Chart === viewType) {
    retVal = <DnDInsertTableChartIcon style={{fontSize: 1500}}/>;
  } else {
    retVal = <img src={require("../../images/tableColumn.svg")} width="150" alt="Table Column"/>;
  }
  return retVal;
}

export function collect(connect: DropTargetConnector, monitor: DropTargetMonitor): Object {
  return {
    connectDropTarget: connect.dropTarget()
  };
}
