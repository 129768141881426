/* ZoomControl.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Oktober 2019
 */
import * as React from "react";
import {Button, createStyles, Theme, Tooltip, Typography, withStyles, WithStyles} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import autobind from "autobind-decorator";
import ZoomInIcon from "../../../common/icons/editor/ZoomInIcon";
import {EditorState, metusStore} from "../../stores/MetusStore";
import {StyleRules} from "@material-ui/core/styles";
import ZoomOutIcon from "../../../common/icons/editor/ZoomOutIcon";
import {observer} from "mobx-react";
import numeral from "numeral";

interface LocalProps {
  windowIndex: number;
  onZoom: (scale: number) => void;
}

interface ZoomControlState {
  scale: number;
}

const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    minWidth: "0px",
  },
  text: {
    padding: "0px",
  },
  typographyZoomfactor: {
    fontSize: "14px",
  },
});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

@observer
class ZoomControl extends React.Component<StyledLocalProps, ZoomControlState> {
  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {scale: 1.0};
  }

  render(): JSX.Element {
    const displayValue = numeral(this.state.scale).format("0%");
    const {classes} = this.props;
    const editorState: EditorState = metusStore.getEditorStateByWindowIndex(this.props.windowIndex);

    if (this.state.scale !== editorState.scale) {
      const scale = editorState.scale;
      this.setState({scale});
    }

    return <React.Fragment>
      <IconButton onClick={() => this.handleZoomOut(editorState.isZoomOutOfLowerBounds)} aria-label="zoom out"
                  data-testselector="ZoomOutButton">
        <ZoomOutIcon/>
      </IconButton>
      <Tooltip title="Reset" enterDelay={500}>
        <Button onClick={this.reset} classes={{root: classes.root, text: classes.text}}>
          <Typography className={classes.typographyZoomfactor}>{displayValue}</Typography>
        </Button>
      </Tooltip>
      <IconButton onClick={() => this.handleZoomIn(editorState.isZoomOutOfUpperBounds)}
                  data-testselector="ZoomIntButton">
        <ZoomInIcon/>
      </IconButton>
    </React.Fragment>;
  }

  @autobind
  private reset(): void {
    this.setState({scale: 1.0});
    this.props.onZoom(1.0);
  }

  @autobind
  private handleZoomIn(isZoomOutOfUpperBounds: boolean): void {
    if (!isZoomOutOfUpperBounds) {
      const scale = this.state.scale + 0.1;
      this.setState({scale});
      this.props.onZoom(scale);
    }
  }

  @autobind
  private handleZoomOut(isZoomOutOfLowerBounds: boolean): void {
    if (!isZoomOutOfLowerBounds) {
      const scale = this.state.scale - 0.1;
      this.setState({scale});
      this.props.onZoom(scale);
    }
  }

}

export default withStyles(styles)(ZoomControl);
