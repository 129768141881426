import * as React from "react";
import {createStyles, SvgIcon, Theme, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";

export const svgIconStyles = (theme: Theme): StyleRules => createStyles({
  root: {
    fontFamily: "Myriad Pro Light Italic, sans-serif;"
  }
});

interface LocalProps {
  style?: any;
  onClick?: ()=>void;
}

type StyledLocalProps = LocalProps & WithStyles<typeof svgIconStyles>;

export class MetusSvgIcon extends React.PureComponent<StyledLocalProps> {
  render(): JSX.Element {
    return <SvgIcon {...this.props} viewBox={this.viewBox()} className={this.props.classes.root}>{this.svg()}</SvgIcon>;
  }

  svg(): JSX.Element {
    return <g/>;
  }

  viewBox(): string {
    return "0 0 0 0";
  }
}
