// lib imports
import * as React from "react";

import Log from "../../../common/utils/Logger";
import TreeView from "../TreeView";
import {
  transformViewHierarchyAndViewInfosIntoTreeItems,
  TreeListItem
} from "../../../core/models/TreeViewModelTransformation";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {metusStore} from "../../stores/MetusStore";
import {observer} from "mobx-react";
import {OpenView} from "../../../api/api";
import {ViewInfo} from "../../../commonviews/models/ViewInfo";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
}


@observer
class ViewsNavigationContainer extends React.Component<LocalProps, {}> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    const viewInfos: ViewInfo[] = metusStore.allViewInfos;
    renderLog.debug("Rendering Views Sidebar entry", viewInfos.length > 0 && viewInfos[0].name);

    const openViews: OpenView[] = metusStore.openViews();
    /* Merge active view info into open views. */
    openViews.forEach(openView => openView.isActive = openView.id === metusStore.activeViewId)

    const webViewInfos: TreeListItem[] = transformViewHierarchyAndViewInfosIntoTreeItems(metusStore.webViewWithFolderNavigationTree, viewInfos, openViews);

    return <React.Fragment>
      <TreeView listItems={webViewInfos} type={"views"}/>
    </React.Fragment>;
  }
}

export default ViewsNavigationContainer;
