import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class FolderIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return  <polygon transform="translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) " points="7.5 9.5 12.4995823 14.5 17.5 9.5"></polygon>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(FolderIcon);