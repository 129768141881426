import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";



class StructuredTableIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return  <path d="M14,32 L14,39 C14,39.551 14.448,40 15,40 L15,40 L18,40 L18,39 C18,37.346 19.346,36 21,36 L21,36 L45,36 C46.654,36 48,37.346 48,39 L48,39 L48,43 C48,44.654 46.654,46 45,46 L45,46 L21,46 C19.346,46 18,44.654 18,43 L18,43 L18,42 L15,42 C13.346,42 12,40.654 12,39 L12,39 L12,32 L14,32 Z M45,38 L21,38 C20.448,38 20,38.449 20,39 L20,39 L20,43 C20,43.551 20.448,44 21,44 L21,44 L45,44 C45.552,44 46,43.551 46,43 L46,43 L46,39 C46,38.449 45.552,38 45,38 L45,38 Z M45.879,10 C47.05,10 48,10.95 48,12.121 L48,12.121 L48,16.879 C48,18.05 47.05,19 45.879,19 L45.879,19 L14,19 L14,26 C14,26.551 14.448,27 15,27 L15,27 L18,27 L18,26 C18,24.346 19.346,23 21,23 L21,23 L45,23 C46.654,23 48,24.346 48,26 L48,26 L48,30 C48,31.654 46.654,33 45,33 L45,33 L21,33 C19.346,33 18,31.654 18,30 L18,30 L18,29 L15,29 C13.346,29 12,27.654 12,26 L12,26 L12,19 L10.121,19 C8.95,19 8,18.05 8,16.879 L8,16.879 L8,12.121 C8,10.95 8.95,10 10.121,10 L10.121,10 Z M45,25 L21,25 C20.448,25 20,25.449 20,26 L20,26 L20,30 C20,30.551 20.448,31 21,31 L21,31 L45,31 C45.552,31 46,30.551 46,30 L46,30 L46,26 C46,25.449 45.552,25 45,25 L45,25 Z"/>;
  }

  viewBox(): string {
    return "0 0 56 56";
  }
}

export default withStyles(svgIconStyles)(StructuredTableIcon);