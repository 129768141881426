import {CSSProperties} from "react";
import {AttributeType, ElementId, TableId, ViewId, VisualId, VisualTableIdString} from "../core/utils/Core";
import {MosaicNode} from "react-mosaic-component";
import {MosaicPath} from "react-mosaic-component/lib/types";
import {CockpitIconType, ViewType} from "../common/constants/Enums";
import {VisualTextBox} from "../diagram/models/common/VisualTextBox";
import {EntityStateMergeConflict} from "./mergeModelApi";

export type ExtTableId = string;
export type ExtVisualTableIdString = string;
export type UUID = string;

export const NAME_ATT_NAME = "name";
export const ID_ATT_NAME = "id";

export type GeneralizedAttributeData = { name: string };
/**
 * possible header rotation degrees as number type
 */
export type RotationDegree = 0 | 90;

/** for now to ease deserialization of polymorphic types use a type string matching the deserialization class name */
// noinspection JSUnusedGlobalSymbols
export type VisualType = "VisualValueChart" | "VisualChartColumn" | "VisualTextBox";

/**
 * return only children which are of type ChartTableData i.e. serialized chart columns, as migration aid for non universal diagram code
 * @param {DiagramData} data
 * @returns {ChartTableData[]}
 */
export function chartChildren(data: DiagramData): ChartTableData[] {
  // filter out all VisualChartColumns by removing all VisualTextBoxes and VisualValueCharts
  return data.children.filter(c => !c.hasOwnProperty("type") && !c.hasOwnProperty("text")) as ChartTableData[];
}

/**
 * return only children which are of type ChartTableData i.e. serialized chart columns, as migration aid for non universal diagram code
 * @param {DiagramData} data
 * @returns {ChartTableData[]}
 */
export function valueChartChildren(data: DiagramData): GeneralizedTableAndAttributeData[][] {
  const visualValueChartData: VisualValueChartData[] = data.children.filter(c => c.hasOwnProperty("type") && c["type"] === "VisualValueChartData") as VisualValueChartData[];
  return visualValueChartData.map(data => data.tables);

}

export interface VisualTableIdData {
  tableId: ExtTableId;
  visualId: UUID;
}

export interface VisualElementIdData {
  elementId: ExtElementId;
  visualId: UUID;
}

/**
 * generalized data format to unify table/attribute configuration in different views
 */
export interface GeneralizedTableAndAttributeData {
  attributes: GeneralizedAttributeData[];
  id: ExtTableId | VisualTableIdData;
}

export interface DiagramData {
  /** TODO: id should not be optional for loading web views */
  id?: ViewId;
  /** TODO: should be mandatory, but tables must be read for compatibility reasons too, so for now optional */
  children?: (ChartTableData | VisualValueChartData | VisualTextBox)[];
  /** @deprecated  REPLACED BY CHILDREN */
  tables?: ChartTableData[];
  autoLayoutOptions?: ExtAutoLayoutOptions;
  filterData?: FilterData[]; // classic charts dont have filter data, but web charts do
  attributeHeaderRotation?: RotationDegree;
  attributeHeaderHeight?: number;
  isHeaderExpanded?: boolean;
}

export enum LayoutAlgorithm {
  DAGRE,
  D3FORCE,
  WEBCOLA
}

export interface ExtAutoLayoutOptions {
  /** layout algorithm/library to use */
  algorithm: LayoutAlgorithm;
  selectedTable: VisualTableIdString
  preserveOrdering: boolean;
  /** if true, chart will automatically layout with these options every time it changes */
  autolayout: boolean;
}

export interface VisualValueChartLevelData extends GeneralizedTableAndAttributeData {
  id: VisualTableIdData;
  columns: number;
  titleWidth: number;
  contentDx: number;
  contentDy: number;
  header: HeaderData;
  attributes: ChartAttributeData[];
}

export interface VisualValueChartElementData {
  visualId: VisualElementIdData;
  title: string;
  styles: CSSProperties;
  levelIndex: number;
  children: VisualValueChartElementData[];
}

export interface VisualTextBoxData {
  id: VisualId,
  x: number,
  y: number,
  width: number,
  height: number,
  text: string
}

export interface VisualValueChartData {
  id: UUID;
  type: "VisualValueChartData";
  tables: VisualValueChartLevelData[];
  children: VisualValueChartElementData[];
}

export interface HeaderData {
  name: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ChartTableData extends HeaderData, GeneralizedTableAndAttributeData {
  /** optional since not available in <= v2.1 diagrams and classic charts */
  type?: "VisualChartColumnData";
  displayName: string;
  attributes: ChartAttributeData[];
  visualElements: VisualElementEntry[];
  id: {
    tableId: ExtTableId;
    visualId: string;
  };
}

export interface ConditionalFormatData {
  attributeName: string;
  filterExpression: string;
  styles: CSSProperties;
}

export interface ChartAttributeData {
  name: string;
  displayName: string;
  x: number;
  y: number;
  width: number;
  height: number;
  conditionalFormats?: ConditionalFormatData[];
}

export interface Table {
  tableId?: TableId;
  attributeValues: AttributeValues;
}

export interface AttributeValues {
  generatedId: UUID[];

  [other: string]: any[];
}

// action/code

export type AttributeFormatType = "Double" | "Date" | "String";

export interface BaseAttributeDefinition {
  name?: string; // attribute name
  type: AttributeType; // attribute type
  formatType?: AttributeFormatType; // in Metus Classic plain attributes are stored as string attributes, thus number and date are defined by their format type
  pattern?: string; // pattern for numbers or dates in Java SimpleDateFormat/SimpleNumberFormat format
  editable?: boolean; // flag if the attribute value might be edited by the user. Currently only formula attribute values are not editable, in the future this will be used for user based access restrictions too
  editPattern?: string;
  viewable?: boolean; // true if user has view permission for this atttribute
}

export interface ReferenceAttributeDefinition extends BaseAttributeDefinition {
  referencedAttributeName: string;
  referencedTableId: TableId;
}

export function isReferenceAttributeDefinition(attDef: AttributeDefinition): attDef is ReferenceAttributeDefinition {
  return (attDef as ReferenceAttributeDefinition).referencedAttributeName !== undefined;
}

export interface FormulaAttributeDefinition extends BaseAttributeDefinition {
  formula: string;
}

export type AttributeDefinition = BaseAttributeDefinition | ReferenceAttributeDefinition | FormulaAttributeDefinition;

export interface TableAttributeDefinitions {
  tableId: ExtTableId;
  attributes: AttributeDefinition[];
}

export interface TableAttributesPerTable extends Map<ExtTableId, AttributeDefinition[]> {
}

export type ExtElementId = string;

export type ElementIdsMap = Map<ElementId, ElementId>;

export type VisualElementEntry = [ExtElementId, number, number, number, number]; // external id, x, y, width, height

export interface Connection {
  sourceElementId: ElementId;
  targetElementId: ElementId;
  strength: number;
  comment?: string;
}

export interface CreateConnectionBody {
  uuid: string;
  sourceElementId: ElementId;
  targetElementId: ElementId;
  strength: number;
  comment?: string;
}

export interface UpdateConnectionBody {
  strength: number;
  comment?: string;
}

export interface ToggleConnectionsBody {
  uuid: string;
  sourceUuids: string[];
  targetUuid: string;
}

export type ChartStyles = Array<ChartStyle>;

export type StyleObject = CSSProperties;

//noinspection JSUnusedGlobalSymbols
export type TableData = ExtElementId[];

export interface AttributeStyle {
  name: string;
  styles: StyleObject;
}

export interface ElementStyle {
  id: string;
  styles?: StyleObject;
  attributeValues?: Array<AttributeStyle>;
}

export interface ChartStyle {
  visualTableId: string;
  styles?: StyleObject;
  attributeHeader?: Array<{
    name: string,
    styles: StyleObject
  }>;
  elements?: Array<ElementStyle>;
}

//noinspection JSUnusedGlobalSymbols
export interface DefaultStyles {
  tableHeader: StyleObject;
  visualElement: StyleObject;
  attributeValue: StyleObject;
  attributeHeader: StyleObject;
}

export const emptyStyles: DefaultStyles = {tableHeader: {}, visualElement: {}, attributeHeader: {}, attributeValue: {}};

export interface Folder {
  name: string;
  children: Folder[];
}

//noinspection JSUnusedGlobalSymbols
export interface FolderEntry {
  uuid: UUID;
  name: string;
  type: "folder";
  children: (FolderEntry | TableEntry | WebViewEntry)[];
}

export interface TableEntry {
  name: string;
  type: "table";
  uuid: UUID;
}

export interface WebViewListEntry {
  id: string;
  name: string;
  type: string;
  props?: Map<string, string>;
}

//noinspection JSUnusedGlobalSymbols
export type WebViewList = WebViewListEntry[];

export interface WebViewEntry {
  name: string;
  type: "view";
  uuid: UUID;
  viewType: string;
  props?: any;
}

export type HierarchyEntry = FolderEntry | WebViewEntry | TableEntry;
// type guard
export const isFolderEntry = (entry: (HierarchyEntry)): entry is FolderEntry => {
  return (entry as any).children !== undefined;
};

export const isViewEntry = (entry: (HierarchyEntry)): entry is WebViewEntry => {
  return entry.type === "view";
};

// -------------
// legacy structured table view support, pre-serializr
// -------------
export interface TreeGridData {
  id: ViewId;
  tables: TreeGridTableData[];
  filterData?: FilterData[]; // tables dont have filter data but web tree grids do have
}

export interface TreeGridTableData extends GeneralizedTableAndAttributeData {
  id: string;
  name: string;
  index: number;
  attributes: TreeGridAttributeData[];
}

export interface TreeGridAttributeData extends GeneralizedAttributeData {
  name: string;
  index: number;
}

/**
 * extract the external table id from a potential visual table id
 * @param {VisualTableIdData | ExtTableId} id
 * @returns {ExtTableId}
 */
export function toExtTableId(id: VisualTableIdData | ExtTableId): ExtTableId {
  return typeof id === "string" ? id : id.tableId;
}

export type VisualTable = [TableId, string];

export interface FilterData {
  visualTables: VisualTable[];
  attribute: string;
  filterText: string;
}

export interface ResultMessage {
  messages: string[];
}

export interface CockpitData {
  id?: ViewId;
  windowArrangement: MosaicNode<number>;
  openViews: OpenView[];
  cockpitInfo?: CockpitInfo;
}

export interface CockpitInfo {
  name?: string;
  iconColor: string;
  iconType: CockpitIconType;
  isDirty?: boolean;
  id?: ViewId;
}

export interface OpenView {
  id: ViewId;
  type: ViewType;
  windowPath: MosaicPath;
  isActive?: boolean;
}

export type MultiuserMode = "merge" | "lock";

/**
 * additional information concerning the currently selected model/project, e.g. if the project supports merging
 */
export interface ModelMeta {
  name: string;
  version: string;
  multiuserMode: MultiuserMode;
}

export interface WriteResult<T> {
  commandId: string;
  json?: T;
}

export interface WriteLock {
  locked: LockedType;
  changeSetId?: string;
  committed?: boolean; // if coming from WebSocket it marks if the changeset was committed or discarded from the other user
}

export enum LockedType {
  None = "none",
  Self = "self",
  Other = "other",
}

export interface Workspace {
  id: string;
  name: string;
  isLocked: boolean;
}

export type WorkspaceJSON = Workspace[]


export enum IEntityStateMergeResultAction {
  Commit = "Commit",
  Reject = "Reject",
  ModifyAndCommit = "ModifyAndCommit"
}

export interface IEntityStateMergeConflict {
  action: IEntityStateMergeResultAction,
  modifiedModelEntity?: IModelEntity,
  semanticConflict?: IEntityState,
  entityState: IEntityState
}

export interface IEntityState {
  conflictingStateId?: string,
  modelEntity: IModelEntity,
  entityStateId: string;
}

export interface IModelEntity {
  id: string;
  name?: string;
}

export interface FolderEntity extends IModelEntity {
  type: "folder";
  parentId: string;
}

export interface AttributeValueEntity extends IModelEntity {
  type: "attributeValue";
  value: any;
}

export type EntityStateTypes = FolderEntity | AttributeValueEntity;

export enum CommitStrategy {
  Discard = "Discard",
  CommitIfConflictFree = "CommitIfConflictFree",
  CommitMine = "CommitMine",
  CommitTheirs = "CommitTheirs"
}

export enum CommitResultType {
  ConflictDescription = "ConflictDescription",
  CommitStatistic = "CommitStatistic",
  ChangesetDiscarded = "ChangesetDiscarded",
  NoCommit = "NoCommit"
}

export interface ConflictDescription {
  type: CommitResultType.ConflictDescription;
  conflicts: EntityStateMergeConflict[];
}

export interface CommitStatistic {
  type: CommitResultType.CommitStatistic;
  revisionVersion: number; // the version of the revision which was created
  entityStatesWrittenCount: number; //
  // resolvedConflictsCount: number
  // ...
}

export interface DiscardInfo {
  type: CommitResultType.ChangesetDiscarded;
}

export interface NoCommit {
  type: CommitResultType.NoCommit;
}

export type CommitResult = ConflictDescription | CommitStatistic | DiscardInfo | NoCommit;

/** ATTENTION: THIS MUST FIT THE RestServer response parameter naming */
export interface ViewBody {
  uuid: string;
  name: string;
  content?: string;
  type?: string;
  props?: any;
  parentId?: string;
  /* ViewType */
}
