import * as React from "react";
import {DragLayer, DragLayerCollector, DragLayerMonitor, XYCoord} from "react-dnd";
import DragTypes from "../../common/constants/DragTypes";
import {DirectionHelper} from "../../common/utils/Direction";
import {HandleItem} from "../../diagram/components/common/HandleComponent";
import {DndClearDragStateAction} from "../../common/actions/InteractionStateActions";
import {Cursor} from "../../common/constants/Enums";

import Log from "../../common/utils/Logger";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {ClientLocation} from "../../common/utils/Geometry";

const log = Log.logger("MetusDragLayer");
const renderLog = Log.logger("common", Classifier.render);

function getHandleStyles(props: CustomDragLayerProps): any {
  const {differenceFromInitialOffset, initialClientOffset, item} = props;
  if (!differenceFromInitialOffset || !item || !item.visualRect) {
    return {
      display: "none"
    };
  }
  const handleItem: HandleItem = item as HandleItem;
  const scale = handleItem.scale;
  const {x: dx, y: dy} = props.differenceFromInitialOffset;
  const newBounds = DirectionHelper.resize(handleItem.direction, dx, dy, handleItem.visualRect.x, handleItem.visualRect.y, handleItem.visualRect.width * scale, handleItem.visualRect.height * scale);
  const handleCenter = DirectionHelper.getHandleCenterPosition(handleItem.direction, handleItem.visualRect.x, handleItem.visualRect.y, handleItem.visualRect.width, handleItem.visualRect.height);
  const transform = `translate(${initialClientOffset.x + ((newBounds.x - handleCenter.x) * handleItem.scale)}px, ${initialClientOffset.y + (newBounds.y - handleCenter.y) * handleItem.scale}px)`;
  return {
    transform: transform,
    backgroundColor: "rgb(68, 67, 67)",
    borderColor: "#F96816",
    border: "solid",
    color: "white",
    fontSize: 15,
    paddingTop: 2,
    paddingRight: 2,
    paddingBottom: 2,
    paddingLeft: 2,
    width: newBounds.width,
    height: newBounds.height
  };
}

interface CustomDragLayerProps {
  // ReactDnd collector properties
  item?: any;
  itemType?: string;
  initialClientOffset?: XYCoord;
  differenceFromInitialOffset?: XYCoord;
  clientOffset?: XYCoord;
  isDragging?: boolean;

  // other properties
  /**
   * will be shown at the current mouse position
   */
  dragSourceFeedback: JSX.Element;
  /**
   * drop target feedback to render, undefined if none
   */
  dropTargetFeedback?: JSX.Element | string;
  /**
   * client position where drop target feedback will be rendered; if undefined, the current mouse position will be used.
   */
  dropTargetFeedbackLocation?: ClientLocation;
  /**
   * cursor to use; currently does not work reliably
   */
  dropTargetCursor: string;
}

/** if you want to test layout/visualization of jsx, insert it here and it will be permanently shown on drag layer */
const DEBUG = null;

class MetusDragLayerNoDnd extends React.Component<CustomDragLayerProps, any> {

  constructor(props: CustomDragLayerProps) {
    super(props);
  }

  componentDidUpdate(prevProps: CustomDragLayerProps): void {
    // clear drag state if isDragging changes from dragging to not dragging
    if (!this.props.isDragging && prevProps.isDragging) {
      log.debug("MetusDragLayer: clearing drag state");
      Dispatcher.dispatch(new DndClearDragStateAction());
    }
  }

  render(): JSX.Element {
    renderLog.debug("Rendering MetusDragLayer");
    // globally set cursor if dragging and if is any
    this.setDragCursor();

    if (!DEBUG && !this.props.isDragging) {
      return null;
    }

    let dropTargetFeedback: JSX.Element = null;
    let dragSourceFeedback: JSX.Element = null;

    const sourceFeedbackLocation = this.props.differenceFromInitialOffset || {x: 0, y: 0};
    const dragSourceTransform = `translate(${sourceFeedbackLocation.x}px, ${sourceFeedbackLocation.y}px)`;
    const targetFeedbackLocation = this.props.dropTargetFeedbackLocation|| this.props.clientOffset || {x: 0, y: 0};
    const dropTargetTransform = `translate(${targetFeedbackLocation.x}px, ${targetFeedbackLocation.y}px)`;
    if (DEBUG) {
      dropTargetFeedback = <div style={{position: "absolute", transform: dropTargetTransform}}>{DEBUG}</div>;
    } else {
      // TODO refactor handle feedback to target component
      if (this.props.itemType === DragTypes.RESIZE_HANDLE) {
        const handleStyles = getHandleStyles(this.props);
        // revert dragSourceTransform of whole drag layer
        dropTargetFeedback = <div style={handleStyles}/>;
      } else {
        if (typeof this.props.dropTargetFeedback === "string") {
          dropTargetFeedback =
              <div className="drag-box-message-feedback"
                   style={{transform: dropTargetTransform}}>{this.props.dropTargetFeedback}</div>;
        } else if (this.props.dropTargetFeedback) {
          dropTargetFeedback =
              <div style={{position: "absolute", transform: dropTargetTransform}}>{this.props.dropTargetFeedback}</div>;
        }
        if (this.props.dragSourceFeedback) {
          dragSourceFeedback =
              <div style={{position: "absolute", transform: dragSourceTransform}}>{this.props.dragSourceFeedback}</div>;
        }
      }
    }
    log.debug("MetusDragLayer: rendering drag source feedback", dragSourceFeedback);
    log.debug("MetusDragLayer: rendering drop target feedback", dropTargetFeedback);

    // put source and target feedback on top of each other with absolute positioning,
    // thus a relative div is nested above
    return <div className="drag-layer">
      <div style={{position: "relative"}}>
        {dragSourceFeedback}
        {dropTargetFeedback}
      </div>
    </div>;
  }

  private setDragCursor(): void {
    // if not dragging, set cursor to auto
    // else show target feedback
    const cursor = (this.props.isDragging ? this.props.dropTargetCursor : Cursor.AUTO);
    log.debug("MetusDragLayer: setting body cursor to '" + cursor + "'");
    document.body.style.setProperty("cursor", cursor);
  }
}

const collector: DragLayerCollector<any,any> = function (monitor: DragLayerMonitor): Object {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    sourceClientOffset: monitor.getSourceClientOffset(),
    initialSourceClientOffset: monitor.getInitialSourceClientOffset(),
    initialClientOffset: monitor.getInitialClientOffset(),
    differenceFromInitialOffset: monitor.getDifferenceFromInitialOffset(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging()
  };
};


export const MetusDragLayer = DragLayer(collector)(MetusDragLayerNoDnd);
export default MetusDragLayer;