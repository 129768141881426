/* CockpitsIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class EditedIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M20,14.768 C15.119,11.078 8.881,11.078 4,14.768 L4,21 L20,21 L20,14.768 Z M16,6 C16,8.209 14.209,10 12,10 C9.791,10 8,8.209 8,6 C8,3.791 9.791,2 12,2 C14.209,2 16,3.791 16,6 Z"></path>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(EditedIcon);
