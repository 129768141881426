/**
 * adapter registry which can adapt different dragtypes
 * e.g. VISUAL_ELEMENTS to ELEMENTS
 * @author Marco van Meegen
 */
import {default as DragType} from "../../common/constants/DragTypes";
import {Validate} from "../../common/utils/Validate";
import {visualElementToElementAdapter, visualTableToTableAdapter} from "../constants/DiagramDragDropTypes";
import {DragTypeAdapter, DragTypeAdapterManager, SourceDragInfo} from "../../common/utils/DragTypeAdapter";

class MetusDragTypeAdapterManager implements DragTypeAdapterManager {
  /** maps a target type to adapters which can convert sth to the target type */
  private adapterRegistry: Map<DragType, Array<DragTypeAdapter>> = new Map();

  constructor(...adapters: DragTypeAdapter[]) {
    this.reset();
    if (adapters) {
      adapters.forEach(a => this.register(a));
    }
  }
  /**
   * will return all drag types which can be converted to one of the given types,
   * including the given types themselves.
   *
   * Usage:
   * DropTarget(DragTypesAdapterFactory.getCompatibleTypes(DragType.ELEMENTS),...)
   * will return ELEMENTS, VISUAL_ELEMENTS
   * @param targetTypes one or more identifier
   * @returns list of all compatible identifiers, no duplicates
   */
  getCompatibleTypes(...targetTypes: DragType[]): DragType[] {
    const resultSet = new Set<DragType>(targetTypes);
    targetTypes.forEach(t => {
      const adapters = this.adapterRegistry.get(t);
      if (adapters) {
        adapters.forEach(a => resultSet.add(a.from));
      }
    });
    return Array.from(resultSet);
  }


  /** register a new drag type adapter. Throws an error if an adapter for the same from/to types is already registered */
  register(adapter: DragTypeAdapter): void {
    Validate.notNull(adapter);
    Validate.notNull(adapter.from);
    Validate.notNull(adapter.to);
    Validate.notNull(adapter.adapt);
    Validate.isTrue(adapter.from !== adapter.to);
    let fromAdapters: Array<DragTypeAdapter> = this.adapterRegistry.get(adapter.to);
    if (!fromAdapters) {
      fromAdapters = [];
      this.adapterRegistry.set(adapter.to, fromAdapters);
    }
    if (fromAdapters.find(a => a.from === adapter.from)) {
      throw Error("An adapter '" + adapter.from + " => " + adapter.to + "' is already registered !");
    } else {
      fromAdapters.push(adapter);
    }
  }

  /** clear all registered adapters */
  reset(): void {
    this.adapterRegistry = new Map();
  }

  /**
   * adapts the given drag source info to the info of the given type
   * returns undefined if no suitable adapter found
   * @param object object to adapt
   * @param toType target type
   * @returns  object converted to suitable SourceDragInfo for toType, the object itself if from = to
   */
  adapt(object: SourceDragInfo, toType: DragType): SourceDragInfo {
    let result;
    if (object.type !== toType) {
      const adapter = this.findAdapter(object.type, toType);
      if (adapter) {
        result = adapter.adapt(object);
      } else {
        result = undefined;
      }
    } else {
      // same types ==> identity
      result = object;
    }
    return result;
  }

  private findAdapter(fromType: DragType, toType: DragType): DragTypeAdapter {
    const fromAdapters: Array<DragTypeAdapter> = this.adapterRegistry.get(toType);
    return fromAdapters && fromAdapters.find(a => a.from === fromType);
  }

}

export const dragTypeAdapterManager = new MetusDragTypeAdapterManager(visualElementToElementAdapter, visualTableToTableAdapter);


