import {TableId} from "../../core/utils/Core";
import {AttributeFormatType, UUID} from "../../api/api";
import {generateUUID} from "../../common/utils/IdGenerator";
import {observable} from "mobx";
import {identifier, list, object, primitive, serializable} from "serializr";
import {FilterRule, IFilterRule} from "./FilterRule";


export class FilterDefinition {

  @serializable(identifier()) private readonly _id: UUID;
  @serializable(list(object(FilterRule))) @observable private _filterRules: IFilterRule[];
  @serializable(list(primitive())) @observable private _tableIds: TableId[] = [];
  @serializable @observable private _attributeName?: string;
  // store attribute Type in order to detect if formatType of an attribute has been changed
  @serializable @observable private _attributeType?: AttributeFormatType;

  constructor(tableIds: TableId[] = [], attributeName?: string, attributeType?: AttributeFormatType, ...filterRules: IFilterRule[]) {
    this._id = generateUUID();
    this._tableIds = tableIds;
    this._attributeName = attributeName;
    this._attributeType = attributeType;
    this._filterRules = filterRules || [];
  }

  get id(): string {
    return this._id;
  }

  get attributeName(): string {
    return this._attributeName;
  }

  set attributeName(attributeName:string) {
    this._attributeName = attributeName;
  }

  get attributeType(): AttributeFormatType {
    return this._attributeType;
  }

  get tableIds(): TableId[] {
    return this._tableIds;
  }

  set tableIds(tableIds:TableId[]) {
    this._tableIds = tableIds;
  }

  get filterRules(): IFilterRule[] {
    return this._filterRules;
  }

  set filterRules(value: IFilterRule[]) {
    this._filterRules = value;
  }
}