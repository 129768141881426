import {observable} from "mobx";
import {ViewId} from "../../core/utils/Core";
import {PersistencyState, ViewType} from "../../common/constants/Enums";

export class ViewInfo {
  @observable id: ViewId;
  @observable type: ViewType;
  @observable name: string; // TODO remove name from viewInfo
  @observable persistencyState: PersistencyState;
  @observable viewVersion: number;

  constructor(type: ViewType, id: ViewId, name: string, persistencyState: PersistencyState, viewVersion: number) {
    this.type = type;
    this.id = id;
    this.name = name;
    this.persistencyState = persistencyState;
    this.viewVersion = viewVersion;
  }
}