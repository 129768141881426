/* SaveCockpitDialog.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import Log from "../../common/utils/Logger";
import {createStyles, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {openCockpit, saveView} from "../actions/ViewManagerAsyncActionCreators";
import {ViewType} from "../../common/constants/Enums";
import MetusDialog from "../../common/components/MetusDialog";
import {CockpitActionPayload} from "../stores/MetusStore";
import {ActionBase} from "../../common/actions/BaseAction";
import {ViewId} from "../../core/utils/Core";
import {showDialog} from "../../common/utils/CommonDialogUtil";
import {showNewCockpitDialog} from "./NewCockpitDialog";
import {COCKPIT_VERSION} from "../../common/constants/ViewVersions";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
  open: boolean;
  closeAction: ActionBase<CockpitActionPayload>;
}

interface LocalState {
}

const styles = (theme: Theme): StyleRules => createStyles({
  button: {
    margin: theme.spacing(1),
  },
});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class SaveCockpitDialog extends React.Component<StyledLocalProps, LocalState> {
  readonly cockpitId: ViewId;
  readonly cockpitName: string;

  constructor(props: StyledLocalProps) {
    super(props);

    const payload: CockpitActionPayload = props.closeAction.payload;
    this.cockpitId = payload.id;
    this.cockpitName = payload.name;
  }

  getDialogContent(): JSX.Element {
    return <Typography variant={"body2"}>
      You have changed the current cockpit. How do you want to handle your new creation?
    </Typography>;
  }

  render(): JSX.Element {
    const {classes} = this.props;

    return <MetusDialog
        data-testselector="SaveCockpitDialog"
        title="Save changes?"
        open={this.props.open}
        onClose={this.handleClose}
        primaryButtonName={"Save As new Cockpit"}
        onPrimaryButtonPressed={this.handleSaveAs}
        aux1ButtonName={"Discard Changes"}
        onAux1ButtonPressed={this.handleDiscard}
        aux2ButtonName={"Save Changes"}
        onAux2ButtonPressed={this.handleSave}
    >
      {this.getDialogContent()}
    </MetusDialog>;
  }

  @autobind
  private handleClose(): void {
    showSaveCockpitDialog(false);
  }

  @autobind
  private handleDiscard(): void {
    this.handleClose();
    Dispatcher.dispatch(this.props.closeAction);

    const cockpitInfo = this.props.closeAction.payload.cockpitToOpen;
    if (cockpitInfo) {
      openCockpit(cockpitInfo.id, cockpitInfo.iconColor, cockpitInfo.iconType);
    }
  }

  @autobind
  private handleSave(): void {
    // TODO version: add viewInfo to this component, to be able to pass correct cockpit version here
    saveView(ViewType.Cockpit, this.cockpitId, this.cockpitName, COCKPIT_VERSION, false);
    this.handleDiscard();
  }

  @autobind
  private handleSaveAs(): void {
    this.handleClose();
    showNewCockpitDialog(true);
  }

}

const StyledSaveCockpitDialog = withStyles(styles)(SaveCockpitDialog);
export default StyledSaveCockpitDialog;

export function showSaveCockpitDialog(display: boolean, closeAction?: ActionBase<CockpitActionPayload>): void {
  showDialog(display, <StyledSaveCockpitDialog open={display} closeAction={closeAction}/>);
}

