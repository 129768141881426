import {VisualObjectBase} from "../VisualObject";
import {VisualChartElement} from "./VisualChartElement";
import {ElementId, TableId, VisualAttributeId, VisualElementIdString, VisualTableId} from "../../../core/utils/Core";
import {VisualHeader} from "../../../commonviews/models/VisualHeader";
import Log from "../../../common/utils/Logger";
import {VisualAttributeDefinition} from "../common/VisualAttributeDefinition";
import {Direction} from "../../../common/utils/Direction";
import {computed, observable} from "mobx";
import {VisualBaseTable} from "../common/CommonDiagramTypes";
import {Rect, Rectangle} from "../../../common/utils/Geometry";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {RotationDegree} from "../../../api/api";
import {ContainerColumnLayout} from "../common/ContainerColumnLayout";
import {list, map, object, serializable} from "serializr";

const log = Log.logger("model");

export class VisualChartColumn extends VisualObjectBase<VisualChartElement, VisualTableId> implements VisualBaseTable {
  @serializable(list(object(VisualChartElement))) @observable protected _children: VisualChartElement[] = [];
  get children(): VisualChartElement[] {
    return this._children;
  }
  @serializable(object(VisualTableId)) @observable public id: VisualTableId;
  @serializable(object(VisualHeader)) @observable header: VisualHeader;
  /** maps a plain attribute name string (not an attribute id) to an attribute of this table */
  @serializable(map(object(VisualAttributeDefinition))) @observable visualAttributeDefinitions: Map<string, VisualAttributeDefinition>;
  /** maps nodes by visual node id */
  @serializable(map(object(VisualChartElement))) @observable visualElements: Map<VisualElementIdString, VisualChartElement>;
  @serializable @observable attributeHeaderRotation: RotationDegree = 0;
  @serializable @observable attributeHeaderHeight: number = DiagramVisualConstants.TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT;

  /** maps nodes by model node id */
  @observable private nodesByNodeId: Map<ElementId, VisualChartElement>;
  @observable isFilterVisible: boolean;

  /** not observable, since effects are exposed by manipulating observable header coordinates */
  public attributeColumnLayout: ContainerColumnLayout;

  constructor(id: TableId, name: string = null, displayName: string = null, x: number = 0, y: number = 0, width: number = 0, height: number = undefined) {
    super(undefined, new VisualTableId(id));
    this.header = new VisualHeader(name, x, y, width, height);
    this.isFilterVisible = false;
    this.visualAttributeDefinitions = new Map<string, VisualAttributeDefinition>();
    this.visualElements = new Map<VisualElementIdString, VisualChartElement>();
    this.nodesByNodeId = new Map<ElementId, VisualChartElement>();
    this.attributeColumnLayout = new ContainerColumnLayout(this.header, Array.from(this.visualAttributeDefinitions.values()).map(a => a.header));
    this.updateHeaderHeight();
  }

  getNodeForId(id: ElementId): VisualChartElement {
    return this.nodesByNodeId.get(id);
  }

  public setHeaderRotation(rotation: RotationDegree, height: number): void {
    this.attributeHeaderRotation = rotation;
    this.attributeHeaderHeight = height;
    this.updateHeaderHeight();
  }

  addNode(visualElement: VisualChartElement): VisualChartColumn {
    log.debug("Adding node", visualElement);
    visualElement.parent = this;
    this.visualElements.set(visualElement.id.toKey(), visualElement);
    this.nodesByNodeId.set(visualElement.id.elementId, visualElement);
    this.children.push(visualElement);
    return this;
  }

  removeNodeForId(id: ElementId): void {
    const visualElement = this.getNodeForId(id);
    this.nodesByNodeId.delete(id);
    if (visualElement) {
      this.removeChildById(visualElement.id);
    }
  }

  /**
   * calculate chart column layout after any changes which can affect size and position
   */
  public updateHeaderHeight(): void {
    // add 3 to avoid rounded corner overlap with filter/att rect
    this.header.height = 3 * DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH + DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + this.attributeHeaderHeight + DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC;
    if (this.isFilterVisible) {
      this.header.height += DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH + DiagramVisualConstants.TABLE_HEADER_FILTER_HEADER_HEIGHT;
    }
  }

  addAttributeColumn(gAttribute: VisualAttributeDefinition): VisualChartColumn {
    this.visualAttributeDefinitions.set(gAttribute.header.name, gAttribute);
    this.attributeColumnLayout.addColumn(gAttribute.header);
    return this;
  }

  updateAttributeColumn(visualAttributeId: VisualAttributeId, dx: number, dy: number): void {
    const attribute = this.getAttribute(visualAttributeId.attributeName);
    if (attribute !== undefined) {
      this.attributeColumnLayout.updateColumn(attribute.header, dx);
    }
  }

  removeAttributeColumn(attributeName: string): void {
    this.visualAttributeDefinitions.delete(attributeName);
    this.attributeColumnLayout.removeColumn(attributeName);
  }

  getAttribute(attributeName: string): VisualAttributeDefinition {
    return this.visualAttributeDefinitions.get(attributeName);
  }

  get allAttributeNames(): string[] {
    return Array.from(this.visualAttributeDefinitions.keys()).sort();
  }

  get filteredNodes(): VisualChartElement[] {
    return Array.from(this.visualElements.values()).filter(n => n.visible);
  }

  public resizeTable(d: Direction, dx: number): void {
    this.attributeColumnLayout.resizeContainer(d, dx);
  }

  public get elements(): VisualChartElement[] {
    return Array.from(this.visualElements.values());
  }

  /**
   * bounds of a chart column are defined by union of header + all elements + all attribute headers
   */
  @computed.struct get internalBounds(): Rect {
    const headers: VisualHeader[] = Array.from(this.visualAttributeDefinitions.values()).map(def => def.header);
    const result = Rectangle.union(this.header.bounds, ...headers.map(h => h.bounds), ...this.children.map(c => c.bounds));
    return result ? result.toJS : undefined;
  }

  get x(): number {
    return this.header.x;
  }

  get y(): number {
    return this.header.y;
  }

  get width(): number {
    return this.header.width;
  }

  get height(): number {
    return this.header.height;
  }

  public toggleFilterLine(): boolean {
    this.isFilterVisible = !this.isFilterVisible;
    // update header size
    this.updateHeaderHeight();
    return this.isFilterVisible;
  }
}
