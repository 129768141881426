// lib imports
import "es6-shim";

const PASSIVE_EVENTS: (keyof DocumentEventMap)[] = ["wheel"];

const checkType = (type, options): any => {
  if (!PASSIVE_EVENTS.includes(type)) return null;

  const modOptions = {
    boolean: {
      capture: options,
      passive: false,
    },
    object: {
      ...options,
      passive: false,
    },
  };

  return modOptions[typeof options];
};


/**
 * Since Chrome 75 several event listeners on root level are active by default and cannot be overridden.
 * Since React captures all events on root level, we need a hack to force some event handlers to be passive
 * to preventDefault().
 */
export function patchPassiveEventListeners(): void {
  console.info("Patching Event Listeners to enable React using them passively: " + PASSIVE_EVENTS.toString());
  const anydoc = document as any;
  const addEventListener = anydoc.addEventListener.bind();
  anydoc.addEventListener = (type, listener, options, wantsUntrusted): void => (
      addEventListener(type, listener, checkType(type, options) || options, wantsUntrusted)
  );

  const removeEventListener = anydoc.removeEventListener.bind();
  anydoc.removeEventListener = (type, listener, options): void => (
      removeEventListener(type, listener, checkType(type, options) || options)
  );
}