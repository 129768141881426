import {observer} from "mobx-react";
import * as React from "react";
import {Component} from "react";
import {ICellRendererParams} from "ag-grid-community";
import Log from "../../../common/utils/Logger";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {ElementId} from "../../../core/utils/Core";
import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
  DropTargetSpec
} from "react-dnd";
import {FeedbackHelper} from "../../../common/utils/DragDropHelper";
import {DndSourceFeedbackAction, DndTargetFeedbackAction} from "../../../common/actions/InteractionStateActions";
import {Cursor} from "../../../common/constants/Enums";
import {ElementSourceDragInfo} from "../../../common/utils/CoreDropTypes";
import {dragTypeAdapterManager} from "../../../commonviews/utils/DragTypeAdapterManager";
import {SourceDragInfo} from "../../../common/utils/DragTypeAdapter";
import DragTypes, {default as DragType} from "../../../common/constants/DragTypes";
import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core";
import * as _ from "lodash";
import {StyleRules} from "@material-ui/core/styles";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {AttributeDefinition} from "../../../api/api";
import {toggleConnections} from "../../../core/services/CoreDataServices";
import {calcFontSizeFromScale, cellContainer, isWordWrap, wordWrapCSS} from "./common";

const classNames = require("classnames");

const log = Log.logger("MatrixComponent");
const dndLog = Log.logger("MatrixComponent", Classifier.dnd);

interface MatrixCellRendererComponentProps extends ICellRendererParams {
  connectDropTarget?: ConnectDropTarget;
  connectDragSource?: ConnectDragSource;
}

const styles = (theme: Theme): StyleRules => createStyles({
  cellValueSpan: {
    minWidth: "10px",
    minHeight: "10px",
    display: "inline-block",
    // by setting width to 100% the area for drag&drop becomes larger and
    // extends the area with text
    width: "100%",
    marginLeft: 4,
  },
  wordWrapCSS,
  cellContainer
});

type StyledLocalProps = MatrixCellRendererComponentProps & WithStyles<typeof styles>;

/**
 * inner renderer for tree column, provides drag&drop support
 * @author Marco van Meegen
 */
@observer
class MatrixTreeColumnInnerCellRendererComponentNoDnd extends React.Component<StyledLocalProps> {
  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    const rowElementId = this.getRowElementId();
    const colElementId = this.getColElementId();
    let cellValue = this.props.data[colElementId];
    log.debug("Rendering Matrix Cell", rowElementId, colElementId, cellValue);
    if (Array.isArray(cellValue)) {
      cellValue = cellValue.join(";");
    }
    const className = classNames(this.props.classes.cellValueSpan, { [this.props.classes.wordWrapCSS]: isWordWrap(this.props)});
    let result = <span className={className}
                       data-testselector={"ElementName/" + cellValue + "/"}
                       data-tip={cellValue}
                       style={{fontSize: calcFontSizeFromScale(this.props.context)}}>
        {cellValue}
    </span>;
    result = this.props.connectDropTarget === undefined ? result : this.props.connectDropTarget(result);
    return this.props.connectDragSource === undefined ? result : this.props.connectDragSource(result);
  }

  private getColElementId(): ElementId {
    return this.props.colDef.field;
  }

  private getRowElementId(): ElementId {
    return this.props.data.elementId;
  }

  private getAttributeDefinition(): AttributeDefinition {
    const attributeDefinition = this.props.context.getAttributeDefinition(this.props.node.data.tableId, this.props.colDef.headerName);
    return attributeDefinition;
  }

}

function targetCollect(connect: DropTargetConnector, monitor: DropTargetMonitor): Object {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

const dropTargetSpec: DropTargetSpec<StyledLocalProps> = {
  hover(props: StyledLocalProps, monitor: DropTargetMonitor, component: Component<StyledLocalProps, any>): void {
    const box = FeedbackHelper.createTargetFeedbackBox(component, monitor);
    const image = <img src={require("../../../common/images/connectElements.svg")} width="200"
                       style={{transform: "translate(-50%,-50%)", position: "absolute"}} alt="connect elements"/>;
    dndLog.debug("Setting target feedback");
    Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.ALIAS, <div
        style={{position: "absolute"}}>{[box, image]} </div>));
  },

  drop(props: StyledLocalProps, monitor: DropTargetMonitor, component: MatrixTreeColumnInnerCellRendererComponentNoDnd): void {
    dndLog.debug("Drop CORE_ELEMENTS, newOrDeleteConnections");
    const item: ElementSourceDragInfo = dragTypeAdapterManager.adapt(monitor.getItem() as SourceDragInfo, DragType.CORE_ELEMENTS) as ElementSourceDragInfo;
    if (item) {
      const selectedNodeIds = item.selectedElements.add(item.sourceElementId);
      toggleConnections(Array.from(selectedNodeIds), props.node.data.elementId);
    } else {
      dndLog.error("Drop with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
    }
  }
};

function sourceCollect(connect: DragSourceConnector, monitor: DragSourceMonitor): Object {
  return {
    connectDragSource: connect.dragSource()
  };
}

const setEnableRangeSelection = (props: StyledLocalProps, enable:boolean):void => {
  // @ts-ignore
  const gridOptions = props.api?.gridOptionsWrapper?.gridOptions;
  if (gridOptions) {
    gridOptions.enableRangeSelection = enable;
  }
}

const dragSourceSpec: DragSourceSpec<StyledLocalProps, ElementSourceDragInfo> = {
  beginDrag(props: StyledLocalProps, monitor: DragSourceMonitor, component: React.Component<StyledLocalProps, any>): ElementSourceDragInfo {
    // disable range selection in order to prevent scrolling, see MO-3927 Draggen eines Elements über den View hinaus muss das Scrollen stoppen
    setEnableRangeSelection(props, false);
    // optional: send a source feedback, e.g. default gray box using the DragAndDropHelper.tsx
    Dispatcher.dispatch(new DndSourceFeedbackAction(FeedbackHelper.createSourceFeedbackBox((props as any).reactContainer)));
    let selectedElements;
    if (props.context) {
      selectedElements = props.context.getSelectedRowIds();
    } else {
      dndLog.error("No function getSelectedRowIds in Matrix context found, not supporting multiple selection");
      selectedElements = new Set<ElementId>();
      selectedElements.add(props.node.data.elementId);
    }

    return {
      sourceElementId: props.node.data.elementId,
      type: DragTypes.CORE_ELEMENTS,
      selectedElements: selectedElements
    };
  }
,
  endDrag(props: StyledLocalProps, monitor: DragSourceMonitor, component: React.Component<StyledLocalProps, any>):void {
    setEnableRangeSelection(props, true);
  }
};

const MatrixTreeColumnInnerCellRendererStyled: any = withStyles(styles)(MatrixTreeColumnInnerCellRendererComponentNoDnd);

export default _.flow(DragSource(DragTypes.CORE_ELEMENTS, dragSourceSpec, sourceCollect),
    DropTarget(dragTypeAdapterManager.getCompatibleTypes(DragTypes.CORE_ELEMENTS), dropTargetSpec, targetCollect))(MatrixTreeColumnInnerCellRendererStyled);


