/* ToggleFullscreenControl.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, August 2018
 */
import * as React from "react";
import {IconButton} from "@material-ui/core";
import EnterFullScreenIcon from "../../../common/icons/editor/EnterFullScreenIcon";
import autobind from "autobind-decorator";
import ExitFullScreenIcon from "../../../common/icons/editor/ExitFullScreenIcon";

interface LocalProps {
  isFullScreen: boolean;
  onClick: () => void;
}

export class ToggleFullscreenControl extends React.Component<LocalProps> {
  render(): JSX.Element {
    return <IconButton onClick={this.toggleFullscreen} data-testselector="FullscreenButton">
      {this.props.isFullScreen ? <ExitFullScreenIcon/> : <EnterFullScreenIcon/>}
    </IconButton>;
  }

  @autobind
  private toggleFullscreen(): void {
    this.props.onClick();
  }

}
