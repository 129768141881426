import {FilterDefinition} from "../../matrix/models/FilterDefinition";
import {modelStore} from "../../core/stores/ModelStore";

export const createFilterDescription = (filterDefinition: FilterDefinition): { headerName: string, isActive: boolean, tooltip: string } => {
  const isActive = filterDefinition.attributeName !== null && filterDefinition.attributeName !== undefined;
  let headerName: string;
  let tooltip: string;
  if (!isActive) {
    headerName = "inactive";
    tooltip = "Invalid filter: there is no attribute chosen";
  } else {
    headerName = filterDefinition.attributeName;
    if (filterDefinition.tableIds.length === 0) {
      headerName = "inactive";
      tooltip = "Invalid filter: there is no table chosen";
    } else {
      const tableCount = filterDefinition.tableIds.length;
      const tables = filterDefinition.tableIds.map(tableId => modelStore.getTableName(tableId)).join(",");
      tooltip = tableCount === 1 ? `${tables}.${filterDefinition.attributeName}` : `(${tables}).${filterDefinition.attributeName}`;
    }
  }
  return {
    headerName,
    isActive,
    tooltip
  }
}