/* EnterFullScreenIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Oktober 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class EnterFullScreenIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <g xmlns="http://www.w3.org/2000/svg" id="fullscreen" stroke="none" strokeWidth="1">
      <path d="M14,4 L14,6 L18,6 L18,10 L20,10 L20,4 L14,4 Z M4,4 L4,10 L6,10 L6,6 L10,6 L10,4 L4,4 Z M18,14 L18,18 L14,18 L14,20 L20,20 L20,14 L18,14 Z M4,14 L4,20 L10,20 L10,18 L6,18 L6,14 L4,14 Z"/>
    </g>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(EnterFullScreenIcon);
