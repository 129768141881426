import {IFilter} from "../../core/utils/filter/Filter";
import {ElementId} from "../../core/utils/Core";

export class ElementFilter implements IFilter {
  constructor(private _elementIdsFiltered: ElementId[]) {
  }

  matches(nodeIds: ElementId[]): ElementId[] {
    return nodeIds.filter(nodeId => this._elementIdsFiltered.indexOf(nodeId) === -1);
  }

}