import * as React from "react";
import Log from "../../../common/utils/Logger";
import {createStyles, Slider, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {BaseSectionProperties} from "../../../properties/model/PropertiesModel";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {Autorenew} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";
import PropertiesButton from "../../../properties/components/PropertiesButton";
import PropertiesSectionLayout from "../../../properties/components/PropertiesSectionLayout";
import {
  ChangeAttributeHeaderHeightAction,
  ChangeAttributeHeaderWidthAction,
  RotateAttributeHeadersAction
} from "../../actions/SharedViewActions";
import {observer} from "mobx-react";

const log = Log.logger("properties");
const renderLog = Log.logger("properties", Classifier.render);

const styles = (theme: Theme): StyleRules => createStyles({
  slider: {
    width: "100%",
  }
});


export interface HeaderLayoutProperties extends BaseSectionProperties {
  isVertical: boolean;
  height: number;
  width: number;
}

type StyledLocalProps = HeaderLayoutProperties & WithStyles<typeof styles>;

interface LocalState {
}

@observer
class HeaderLayoutPropertiesComponent extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    this.toggleIsVertical = this.toggleIsVertical.bind(this);
    this.setHeight = this.setHeight.bind(this);
    this.setWidth = this.setWidth.bind(this);
  }


  private toggleIsVertical(e: any): void {
    Dispatcher.dispatch(new RotateAttributeHeadersAction(this.props.activeViewId));
  }

  private setHeight(value: number): void {
    Dispatcher.dispatch(new ChangeAttributeHeaderHeightAction(this.props.activeViewId, value));
  }

  private setWidth(value: number): void {
    Dispatcher.dispatch(new ChangeAttributeHeaderWidthAction(this.props.activeViewId, value));
  }

  render(): JSX.Element {
    renderLog.debug("Rendering HeaderLayoutPropertiesComponent");
    return <PropertiesSectionLayout>
      <PropertiesButton onClick={this.toggleIsVertical}
                        icon={<Autorenew />}>Rotate</PropertiesButton>

      <div className={this.props.classes.slider}>
        <Typography>Height</Typography>
        <Slider
          id="height"
          value={this.props.height}
          disabled={!this.props.isVertical}
          min={40}
          max={400}
          onChange={(evt, value: number) => this.setHeight(value)}
        />
      </div>
      {this.props.width !== undefined &&
       <div className={this.props.classes.slider}>
          <Typography>Width</Typography>
          <Slider
              id="width"
              value={this.props.width}
              disabled={!this.props.isVertical}
              min={40}
              max={400}
              onChange={(evt, value: number) => this.setWidth(value)}
          />
        </div>
      }
    </PropertiesSectionLayout>;
  }
}

export default withStyles(styles)(HeaderLayoutPropertiesComponent);