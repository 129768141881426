import {ElementId, TableId, ViewId, VisualElementId, VisualElementIdString} from "../../core/utils/Core";
import DragType from "../../common/constants/DragTypes";
import {ElementSourceDragInfo, TableDragInfo, VisualTableDragInfo} from "../../common/utils/CoreDropTypes";
import {DragTypeAdapter, SourceDragInfo} from "../../common/utils/DragTypeAdapter";

export interface VisualElementSourceDragInfo extends SourceDragInfo {
  sourceElementId: ElementId;
  selectedSourceTableIds: Set<TableId>;
  viewId: ViewId;
  selectedVisuals: Set<VisualElementIdString>;
  type: DragType.VISUAL_ELEMENTS;
}

export interface VisualElementTargetDropInfo {
  targetElementId: ElementId;
}

function adaptVisualElementsToElements(o: VisualElementSourceDragInfo): ElementSourceDragInfo {
  const selectedElementIds: Set<ElementId> = new Set(Array.from(o.selectedVisuals).map((vid: VisualElementIdString) => VisualElementId.fromKey(vid).elementId));
  return {sourceElementId: o.sourceElementId, type: DragType.CORE_ELEMENTS, selectedElements: selectedElementIds};
}

function adaptVisualTablesToTables(o: VisualTableDragInfo): TableDragInfo {
  return {tableId: o.tableId, isColumnHierarchy: o.isColumnHierarchy, type: DragType.CORE_TABLE};
}


export const visualElementToElementAdapter = new DragTypeAdapter(DragType.VISUAL_ELEMENTS, DragType.CORE_ELEMENTS, adaptVisualElementsToElements);
export const visualTableToTableAdapter = new DragTypeAdapter(DragType.VISUAL_TABLE_COLUMN_MOVE, DragType.CORE_TABLE, adaptVisualTablesToTables);
