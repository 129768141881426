import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "./MetusSvgIcon";


class CheckboxIcon extends MetusSvgIcon {

  svg(): JSX.Element {
    return <g id="check_L-1b" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M4,20 L20,20 L20,4 L4,4 L4,20 Z M5,19 L19,19 L19,5 L5,5 L5,19 Z" id="Fill-1-Copy" fillOpacity="0.5"
            fill="#000000"></path>
      <polygon id="Fill-1" fill="#3A9BFC" points="21.549 4 12.483 13.066 8.31356179 8.90168125 6.54556179 10.6696813 12.483 16.601 23.317 5.768"></polygon>
    </g>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(CheckboxIcon);