import {computed, observable, untracked} from "mobx";
import {AttributeId, isNameAttribute, TableId, ViewId, VisualAttributeId, VisualTableId} from "../../core/utils/Core";
import {generateUUID} from "../../common/utils/IdGenerator";
import {modelStore} from "../../core/stores/ModelStore";
import {
  elementsInPage,
  filterElementsWithoutRevealingParents,
  HierarchyElement,
  hierarchyElements
} from "./HierarchyElementGenerators";
import {
  CellContextMenuEvent,
  CellValueChangedEvent,
  ChartModel,
  ColDef,
  ColGroupDef,
  ICellEditorParams,
  RowNode,
  ValueFormatterParams,
  ValueParserParams
} from "ag-grid-community";
import Log from "../../common/utils/Logger";
import {AbstractColDef, IsColumnFuncParams} from "ag-grid-community/dist/lib/entities/colDef";
import {AttributeDefinition, isReferenceAttributeDefinition, RotationDegree} from "../../api/api";
import {MatrixColumnHeaderClass, MatrixVisualConstants} from "./common/MatrixVisualConstants";
import {custom, list, map, object, primitive, serializable, serialize} from "serializr";
import {
  getCellStyleInGradientMode,
  getCellStyleWithoutGradientBackground,
  styleForCellsWithConditionalFormat
} from "./common/MatrixCellSharedFunctions";
import {ViewType} from "../../common/constants/Enums";
import {TableHierarchyHeaderModel} from "../../commonviews/models/TableHierarchyHeaderModel";
import CustomTreeGridFloatingFilter from "../components/CustomTreeGridFloatingFilter";
import {MatrixCellConfiguration} from "./MatrixCellConfiguration";
import {ColumnState} from "ag-grid-community/dist/lib/columnController/columnController";
import {
  CellEditorType,
  CellRendererType,
  elementPathKeyToLeafElementId,
  elementPathToKey,
  JoinCellEditorParams,
  ReferenceAttributeCellEditorParams
} from "./MatrixCoreDefinitions";
import {getColorIndex, getNumberOfAvailableColors} from "../../core/utils/LevelColorUtil";
import {RowFilteredHierarchy} from "./RowFilteredHierarchy";
import {ColumnFilteredHierarchy} from "./ColumFilteredHierarchy";
import {FilteredHierarchy} from "./FilteredHierarchy";
import autobind from "autobind-decorator";
import {
  calculateFormattedValue,
  calculateInternalConnectionStrength,
  calculateInternalValue,
  convertStringValueToNumber,
  LOCALE_KEY_DISPLAY,
  renderDisplayValue,
  validateInternalNumberAndCreateNumeral
} from "../../common/utils/FormatUtil";
import numeral from "numeral";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {SelectElementsAction} from "../../core/actions/CoreActions";
import {TableAndAttributeData} from "../actions/MatrixAsyncActionCreators";
import {jsonObjectAsString} from "../../common/utils/SerializrUtils";
import {ComponentSelectorResult} from "ag-grid-community/dist/lib/components/framework/userComponentFactory";
import {DeserializedModel} from "../../commonviews/models/DeserializedModel";
import {ConditionalFormat} from "../../commonviews/models/ConditionalFormat";
import {attributeValueComparator} from "../../core/utils/comparator/AttributeValueComparator";

const log = Log.logger("model");


/**
 * custom enum serializer which serializes the name instead of the value
 */
const enumName = custom(enumNameSerializer, enumNameDeserializer);

function enumNameSerializer(value: ViewType): string {
  return ViewType[value];
}

function enumNameDeserializer(value: string): ViewType {
  return ViewType[value];
}

export interface MatrixCellConfigurationPayload {
  joinTableId?: VisualTableId;
  joinTableAttributeName?: string;
  showCount?: boolean;
}

export const extractLeafColumnDefsFromFromGroupedColumnDefs = (columnDefs: AbstractColDef[]): ColDef[] => {
  const result = [];
  const deepTraverseColumDefs = (colDef: any): void => {
    if (colDef.field) {
      result.push(colDef);
    } else if (colDef.children) {
      colDef.children.forEach(child => deepTraverseColumDefs(child));
    }
  };
  columnDefs.forEach(columnDef => deepTraverseColumDefs(columnDef));
  return result;
};

/**
 * @return true if colDef is a matrix column as opposed to row attribute column
 * @param colDef
 * @param acceptGroups if true GroupColumns of MatrixColumns will be identified too
 */
export function isMatrixColumn(colDef: ColDef, acceptGroups: boolean = false): boolean {
  return (colDef.refData && colDef.refData.type === "matrixColumn") || (acceptGroups && colDef.headerClass && (colDef.headerClass as Array<String>).includes("matrixColumnHeader"));
}

/**
 * @return true if columns is name row column (and currently the only tree column)
 * @param colDef
 */
export function isNameColumn(colDef: ColDef): boolean {
  return isNameAttribute(colDef.field);
}


export class MatrixColumnProperties {
  columnTreeDepth?: number;
  width?: number;
  rotation?: number;
  displayMode?: MatrixDisplayMode;
}

export function createMatrixColumnDefs(cellConfiguration: MatrixCellConfiguration, iterator: Iterator<HierarchyElement>, props: MatrixColumnProperties = {}): { colDefs: AbstractColDef[], areMoreLeafElements: boolean } {

  const {
    columnTreeDepth = 0,
    width = MatrixVisualConstants.MATRIX_DEFAULT_ROTATED_HEADER_WIDTH,
    rotation = MatrixVisualConstants.MATRIX_DEFAULT_HEADER_ROTATION,
    displayMode = "PercentAndGradient"
  } = props;

  const result: AbstractColDef[] = [];
  const colGroupStack: ColGroupDef[] = [];
  const isChainMatrix = cellConfiguration.isJoinTable();
  const isPercentDisplayMode = displayMode === "PercentAndGradient" || displayMode === "Percent";
  let cellRenderer: CellRendererType;
  let cellEditor: CellEditorType;
  let cellEditorParams: ICellEditorParams = undefined;

  if (isChainMatrix) {
    cellEditor = "MatrixConnectionsCellEditorComponent";
    cellRenderer = "ChainMatrixCellRendererComponent";
    cellEditorParams = {
      joinTableAttributeId: cellConfiguration.joinTableId ? new AttributeId(cellConfiguration.joinTableId.tableId, cellConfiguration.joinTableAttributeName) : undefined
    } as JoinCellEditorParams;
  } else {
    cellEditor = "ConnectionStrengthCellEditorComponent";
    cellRenderer = isPercentDisplayMode
        ? "PercentCellRendererComponent"
        : displayMode === "Decimal" ? "DecimalCellRendererComponent" : "MatrixBubbleCellRendererComponent";
  }

  let itResult;
  while (!(itResult = iterator.next()).done) {
    const hierarchyElement: HierarchyElement = itResult.value;
    const element = hierarchyElement.element;
    const elementPathKey = elementPathToKey(hierarchyElement.path);
    // TODO: uncomment and fix tests, in order to color the ColDefs correctly
    // TODO: add tests for ColDef coloring (i.e. check if colDef headerClasses are set correctly
    let matrixStyleClassNumber = undefined;
    try {
      matrixStyleClassNumber = getColorIndex(hierarchyElement.level, columnTreeDepth, getNumberOfAvailableColors(), false);
    } catch (e) {
      log.warn("Could not get color index of MatrixColumnDefinition", e);
    }
    const headerClasses: MatrixColumnHeaderClass[] = ["matrixColumnHeader", ("matrixColumnHeaderColorIndex" + matrixStyleClassNumber) as MatrixColumnHeaderClass];

    if (hierarchyElement.isLeaf) {
      if (rotation === 90) {
        headerClasses.push("matrixColumnHeaderRotated");
      }

      const colDef: ColDef = {
        headerName: element["name"],
        headerTooltip: element["name"],
        headerClass: headerClasses,
        width: rotation === 90 ? MatrixVisualConstants.MATRIX_DEFAULT_ROTATED_HEADER_WIDTH : MatrixVisualConstants.BASE_HEADER_WIDTH,
        field: element.id,
        // setting colId to elementPathKey since it has to be unique, otherwise ag-grid concats a "_"+n to every id e.g. elIdXY_1 and elIdXY_2
        colId: elementPathKey,
        columnGroupShow: "open",
        editable: params => {
          return elementPathKeyToLeafElementId(params.node.id) !== params.colDef.field;
        },
        chartDataType: "series",
        filter: true,
        refData: {
          type: "matrixColumn"
        },
        valueFormatter: valueFormatterColumn,
        valueParser: valueParserColumn,
        cellRenderer,
        cellEditor,
        cellEditorParams: cellEditorParams,
        cellStyle: displayMode === "PercentAndGradient" || displayMode === "Decimal" ? getCellStyleInGradientMode : getCellStyleWithoutGradientBackground,
        onCellContextMenu: onCellContextMenu,
        menuTabs: ['generalMenuTab'],
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
      };


      if (colGroupStack.length > 0) {
        colGroupStack[0].children.push(colDef);
      } else {
        result.push(colDef);
      }

    } else {

      const groupDef: ColGroupDef = {
        groupId: elementPathKey,
        headerName: element["name"],
        headerTooltip: element["name"],
        headerClass: headerClasses,
        marryChildren: true,
        columnGroupShow: "open",
        children: [],
        openByDefault: true,
      };

      setColumnGroupShowPlaceHolders(groupDef, rotation, width, hierarchyElement.level, columnTreeDepth);

      // if level jumped up, leave only valid parents on stack
      const targetSize = hierarchyElement.level + 1;
      while (colGroupStack.length > targetSize - 1) {
        colGroupStack.shift();
      }

      // either add to parent group or to result
      if (colGroupStack.length > 0) {
        colGroupStack[0].children.push(groupDef);
      } else {
        result.push(groupDef);
      }

      colGroupStack.unshift(groupDef);
      if (colGroupStack.length !== targetSize) {
        throw `Expected stack size to be ${targetSize} but it is ${colGroupStack.length}. Please check the input iterator of hierarchy elements.`;
      }

    }

  }

  return {colDefs: result, areMoreLeafElements: itResult.value};
}

function setColumnGroupShowPlaceHolders(colDef: ColGroupDef, rotation: number, width: number, level: number, columnTreeDepth: number): void {
  const headerClasses: MatrixColumnHeaderClass[] = Array.from(colDef.headerClass as MatrixColumnHeaderClass[]);
  headerClasses.push("columnGroupShowPlaceholder");
  let lastNode: ColGroupDef = colDef;
  for (let i = 1; i < columnTreeDepth - (level + 1); i++) {
    const placeHolderColDef: ColGroupDef = {
      headerName: "",
      headerClass: headerClasses,
      groupId: colDef.groupId + "PlaceholderLevel" + i,
      columnGroupShow: "closed",
      children: []
    }
    lastNode.children.push(placeHolderColDef)
    lastNode = placeHolderColDef;
  }
  const placeHolderLeafColDef: ColDef = {
    headerName: rotation === 90 ? colDef.headerName : "",
    colId: colDef.groupId + "PlaceholderLevel" + columnTreeDepth,
    headerClass: rotation === 90 ? [...headerClasses, "matrixColumnHeaderRotated"] : headerClasses,
    columnGroupShow: "closed",
    width: rotation === 90 ? MatrixVisualConstants.MATRIX_DEFAULT_ROTATED_HEADER_WIDTH : MatrixVisualConstants.BASE_HEADER_WIDTH,
  };
  lastNode.children.push(placeHolderLeafColDef);
}

export type MatrixDisplayMode = "PercentAndGradient" | "Percent" | "Bubble" | "Decimal";


/**
 * The Matrix Model:
 * <ul>
 *   <li>contains the row table and attribute configuration (rowHierarchy)</li>
 *   <li>column table configuration (columnHierarchy)</li>
 *   <li>column filterDefinition configuration</li>
 *   <li>cell value configuration</li>
 * </ul>
 * everything else is derived
 */
export class MatrixModel implements DeserializedModel<Object> {
  private _attributeColumnAdded: boolean = false;

  get attributeColumnAdded(): boolean {
    return this._attributeColumnAdded;
  }

  private static MAX_COLUMNS: number = 100;
  // do not serialize, use id from server resource
  private _id: ViewId;

  @serializable private version: number = 1;
  @serializable @observable public attributeHeaderRotation: RotationDegree = MatrixVisualConstants.MATRIX_DEFAULT_HEADER_ROTATION;

  @serializable @observable private _attributeHeaderHeight: number = MatrixVisualConstants.MATRIX_DEFAULT_ROTATED_HEADER_HEIGHT;
  @serializable @observable private _attributeHeaderWidth: number = MatrixVisualConstants.MATRIX_DEFAULT_ROTATED_HEADER_WIDTH;

  @serializable(object(RowFilteredHierarchy)) @observable private readonly _rowHierarchy: RowFilteredHierarchy;
  @serializable(object(ColumnFilteredHierarchy)) @observable private readonly _columnHierarchy: ColumnFilteredHierarchy;

  @serializable(enumName) @observable private readonly _viewType: ViewType;
  @serializable @observable private _displayMode: MatrixDisplayMode = "PercentAndGradient";

  @observable private _columnBlockIndex: number = 0;
  @serializable @ observable private _maxColumns: number = MatrixModel.MAX_COLUMNS;

  /**
   * defines the logic what will be displayed in matrix cells and how it is edited
   */
  @serializable(object(MatrixCellConfiguration)) @observable private readonly _cellConfiguration: MatrixCellConfiguration;

  @serializable(list(jsonObjectAsString())) private _sortModel: { colId: string, sort: string }[];

  @serializable(list(jsonObjectAsString())) private _columnState: ColumnState[];

  @serializable(list(jsonObjectAsString())) private _columnGroupState: { groupId: string, open: boolean }[];

  @serializable @observable private _filterIsShown: boolean;

  @serializable(list(jsonObjectAsString())) @observable private _chartModels: ChartModel[] = [];

  @serializable(list(primitive())) @observable private _wordWrappingColumIds: string[] = [];

  set attributeColumnAdded(value: boolean) {
    this._attributeColumnAdded = value;
  }

  constructor(id: ViewId = generateUUID(), viewType: ViewType = ViewType.Matrix) {
    this._id = id;
    this._viewType = viewType;
    if (viewType === ViewType.StructuredTable || viewType === ViewType.Table) {
      this.attributeHeaderRotation = 0;
    }
    this._rowHierarchy = new RowFilteredHierarchy();
    this._columnHierarchy = new ColumnFilteredHierarchy();
    this._cellConfiguration = new MatrixCellConfiguration();
    this._filterIsShown = false;
  }

  get chartModels(): ChartModel[] {
    return this._chartModels;
  }

  set chartModels(value: ChartModel[]) {
    this._chartModels = value;
  }

  get filterIsShown(): boolean {
    return this._filterIsShown;
  }

  set filterIsShown(value) {
    this._filterIsShown = value;
  }

  get sortModel(): { colId: string; sort: string }[] {
    return this._sortModel;
  }

  set sortModel(value: { colId: string; sort: string }[]) {
    this._sortModel = value;
  }

  get columnGroupState(): { groupId: string, open: boolean }[] {
    return this._columnGroupState;
  }

  set columnGroupState(value: { groupId: string, open: boolean }[]) {
    this._columnGroupState = value;
  }

  get columnState(): ColumnState[] {
    return this._columnState;
  }

  set columnState(newState: ColumnState[]) {
    this._columnState = newState;
  }

  get id(): ViewId {
    return this._id;
  }

  set id(newValue) {
    this._id = newValue;
  }

  public get hasColumnHierarchy(): boolean {
    return this.viewType === ViewType.ChainMatrix || this.viewType === ViewType.Matrix;
  }

  get isStructuredTable(): boolean {
    return this._viewType === ViewType.StructuredTable;
  }

  get isTable(): boolean {
    return this._viewType === ViewType.Table;
  }

  get viewType(): ViewType {
    return this._viewType;
  }

  get showConnectedOnly(): boolean {
    return this.rowHierarchy.showConnectedOnly;
  }

  set showConnectedOnly(newValue: boolean) {
    this.rowHierarchy.showConnectedOnly = newValue;
  }

  get tables(): VisualTableId[] {
    return [...this.rowHierarchy.tables, ...this.columnHierarchy.tables];
  }

  public getVisualAttributeIdsForAttributeName(attributeName: string): VisualAttributeId[] {
    const visualAttributeIdsForAttributeName: VisualAttributeId[] = [];

    for (let visualTableId of this.tables) {
      if (modelStore.tableHasAttributeDefinition(visualTableId.tableId, attributeName)) {
        const visualAttributeId: VisualAttributeId = new VisualAttributeId(visualTableId, attributeName);
        visualAttributeIdsForAttributeName.push(visualAttributeId);
      }
    }

    return visualAttributeIdsForAttributeName;
  }

  get columnHierarchy(): ColumnFilteredHierarchy {
    return this._columnHierarchy;
  }

  get rowHierarchy(): RowFilteredHierarchy {
    return this._rowHierarchy;
  }

  // return an array of unique attribute names
  get rowAttributeNames(): string[] {
    return Array.from(new Set(this._rowHierarchy.attributeIds.map(attributeId => attributeId.attributeName)));
  }

  get rowAttributes(): AttributeId[] {
    return this._rowHierarchy.attributeIds;
  }

  public get cellConfiguration(): MatrixCellConfiguration {
    return this._cellConfiguration;
  }

  /** attribute header height if rotated */
  get attributeHeaderHeight(): number {
    return this._attributeHeaderHeight;
  }

  /** space to push attribute headers down if headers are rotated */
  set attributeHeaderHeight(newHeight: number) {
    this._attributeHeaderHeight = newHeight;
  }

  /** attribute header height if rotated of lowest level of column headers not including group headers */
  @computed get leafHeaderHeight(): number {
    return this.attributeHeaderRotation === 90 ? this.attributeHeaderHeight : MatrixVisualConstants.BASE_GROUPHEADER_HEIGHT;
  }

  /** full header height if no elements present in rows */
  @computed get fullHeaderHeightIfEmpty(): number {
    const groupHeight = this.hasColumnHierarchy ? MatrixVisualConstants.BASE_GROUPHEADER_HEIGHT : 0;
    return this.leafHeaderHeight + groupHeight + MatrixVisualConstants.FLOATING_FILTER_HEIGHT;
  }

  /** attribute header width if rotated */
  @computed
  get attributeHeaderWidth(): number {
    return this.attributeHeaderRotation === 90 ? this._attributeHeaderWidth : MatrixVisualConstants.BASE_HEADER_WIDTH;
  }

  /** space to push attribute headers down if headers are rotated */
  set attributeHeaderWidth(newWidth: number) {
    this._attributeHeaderWidth = newWidth;
  }

  public rotateColumnHeaders(): void {
    if (this.attributeHeaderRotation === 0) {
      this.attributeHeaderRotation = 90;
    } else {
      this.attributeHeaderRotation = 0;
    }
  }

  public toggleTableFilter(): void {
    this._columnHierarchy.isFilterFieldVisible = !this._columnHierarchy.isFilterFieldVisible;
  }

  // TODO: find way to do this without mobx complaining
  //  Error: [mobx] Computed values are not allowed to cause side effects by changing observables that are already being observed. Tried to modify: TableHierarchyHeaderModel@230._levels
  // @computed.struct
  public get columnHierarchyHeaderModel(): TableHierarchyHeaderModel {
    return this.createHeaderModel(this.columnHierarchy);
  }

  // TODO: find way to do this without mobx complaining
  //  Error: [mobx] Computed values are not allowed to cause side effects by changing observables that are already being observed. Tried to modify: TableHierarchyHeaderModel@230._levels
  // @computed.struct
  public get rowHierarchyHeaderModel(): TableHierarchyHeaderModel {
    return this.createHeaderModel(this.rowHierarchy);
  }

  /**
   * looks up table ids in model store and creates a header model for them to display a hierarchical header
   * @param hierarchy is either row or column hierarchy
   */
  private createHeaderModel(hierarchy: FilteredHierarchy): TableHierarchyHeaderModel {
    const visualTableIds: VisualTableId[] = hierarchy.tables;
    const result: TableHierarchyHeaderModel = new TableHierarchyHeaderModel(200);
    visualTableIds.forEach(visualTableId => {
      const filters = hierarchy.getFilterForTable(visualTableId.tableId)
      result.addTable(visualTableId, modelStore.getTableName(visualTableId.tableId) || "Undefined Table", filters.length > 0);
    });
    return result;
  }

// conditionalFormats: ConditionalFormat[] = [new ConditionalFormat(new VisualAttributeId(new VisualTableId("7IYsJgWbQIayg9hH31MiJu", null), "Kosten"), ">5", {background: '#7c86da'})];
  @serializable(map(list(object(ConditionalFormat)))) conditionalFormatsByAttributeName: Map<string, ConditionalFormat[]> = new Map<string, ConditionalFormat[]>();


  public getConditionalFormats(attributeName: string): ConditionalFormat[] {
    if (this.conditionalFormatsByAttributeName.has(attributeName)) {
      return this.conditionalFormatsByAttributeName.get(attributeName);
    }

    return [];
  }

  public setColumnDefinitions(attributeName: string, conditionalFormats: ConditionalFormat[]) {
    this.conditionalFormatsByAttributeName.set(attributeName, conditionalFormats);
  }


  /**
   * column definitions for row attributes
   */
  public get rowColumnDefinitions(): ColDef[] {
    let retVal: ColDef[] = [];
    // row attribute ids start with "r."
    if (this.rowHierarchy.tables.length > 0) {
      retVal = this.rowAttributeNames
          .map((attName: string) => {
            let result;
            const baseDefinition: ColDef = {
              autoHeight: this.enableAutoHeightFlag(attName, this._rowHierarchy),
              editable: isEditable,
              valueFormatter,
              valueParser,
              headerName: attName,
              headerTooltip: attName,
              field: attName,
              colId: attName,
              filter: true,
              comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                return this.comparator(valueA, valueB, nodeA, nodeB, isInverted, attName)
              },
              cellEditorSelector: getCellEditor,
              floatingFilterComponentFramework: CustomTreeGridFloatingFilter,
              floatingFilterComponentParams: {
                // the filterDefinition value should not trigger an update in the column definitions, because this would trigger an update for all rows, which is not needed
                // the filterDefinition change already is triggering an update for all affected rows in the createRows function (MatrixRowElements)
                // triggering another update in here, leads to an update transaction with both deletes and updates, thus throwing an error when trying to update rows which also have been deleted
                value: untracked(() => {
                  return (attributeName: string) => this._rowHierarchy.getUniformFilterExpression(attributeName);
                }),
                idParentTreeGrid: this._id,
                columnName: attName,
                uniformFilterExpressionValidityMap: this._rowHierarchy.uniformFilterExpressionValidityMap,
                suppressFilterButton: true
              },
              onCellContextMenu: onCellContextMenu,
              menuTabs: ['generalMenuTab', 'filterMenuTab'],
              cellStyle: params => styleForCellsWithConditionalFormat(params, this.getConditionalFormats(attName),
                  params.node ? this.getAttributeDefinitionByNodeLevel(params.node.level, attName) : undefined)
            }

            if (isNameAttribute(attName)) {
              // name is a group column
              const innerRenderer: CellRendererType = "MatrixTreeColumnInnerCellRendererComponent";
              result = {
                ...baseDefinition,
                width: MatrixVisualConstants.BASE_NAME_ATT_HEADER_WIDTH,
                chartDataType: "category",
                showRowGroup: true,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: {
                  suppressCount: true,
                  innerRenderer
                }
              };
            } else {
              const cellRenderer: CellRendererType = "AttributeCellRendererComponent";
              result = {
                ...baseDefinition,
                cellRenderer,
                chartDataType: "series",
                width: MatrixVisualConstants.BASE_HEADER_WIDTH
              };
            }
            return result;
          });

    }

    return retVal;
  }

  private comparator(valueA, valueB, nodeA: RowNode, nodeB: RowNode, isInverted: boolean, attName: string): number {
    if (nodeA === undefined || nodeB === undefined) {
      return 0;
    }
    const attDefA: AttributeDefinition = this.getAttributeDefinitionByNodeLevel(nodeA.level, attName);
    const attDefB: AttributeDefinition = this.getAttributeDefinitionByNodeLevel(nodeB.level, attName);

    if (attDefA === undefined || attDefB === undefined) {
      return 0;
    }

    const a = calculateInternalValue(valueA, attDefA);
    const b = calculateInternalValue(valueB, attDefB);


    if (attDefA.formatType === attDefB.formatType) {
      return attributeValueComparator(a, b, attDefA.formatType, true);
    } else {
      // This should never be the case, because ag-grid only compares within one level and all rows of a column are of the same type
      return attributeValueComparator(a, b, "String", true);
    }
  }

  /**
   * column definitions for matrix columns
   */
  private get matrixColumnDefinitions(): { colDefs: ColDef[], areMoreLeafElements: boolean } {
    log.debug("Recomputing column definitions for matrix");
    const elementIterator = hierarchyElements(this._columnHierarchy);
    const filteredIterator = filterElementsWithoutRevealingParents(this._columnHierarchy, elementIterator);
    const limitedIterator = elementsInPage(filteredIterator, this.maxColumns, this.columnBlockIndex);
    const {colDefs, areMoreLeafElements} = createMatrixColumnDefs(this.cellConfiguration, limitedIterator, {
      columnTreeDepth: this._columnHierarchy.tables.length,
      rotation: this.attributeHeaderRotation,
      width: this.attributeHeaderWidth,
      displayMode: this.displayMode
    });
    return {colDefs, areMoreLeafElements};
  }

  @computed.struct get columnDefinitions(): { colDefs: ColDef[], areMoreLeafElements: boolean } {
    const stColumns = this.rowColumnDefinitions;
    const columDefsWithLimitInfo = this.matrixColumnDefinitions;
    const all = [...stColumns, ...columDefsWithLimitInfo.colDefs];
    return {colDefs: all, areMoreLeafElements: columDefsWithLimitInfo.areMoreLeafElements};
  }

  @computed
  public get leafColumnDefinitions(): ColDef[] {
    return extractLeafColumnDefsFromFromGroupedColumnDefs(this.columnDefinitions.colDefs);
  }


  removeTable(tableId: TableId) {
    // remove table from rowHierarchy
    const visualRowTableIdsToRemove = this._rowHierarchy.tables.filter(visualTableId => visualTableId.tableId === tableId);
    for (let visualTableId of visualRowTableIdsToRemove) {
      this._rowHierarchy.removeTable(visualTableId);
    }

    // remove table from columnHierarchy
    const visualColumnTableIdsToRemove = this._columnHierarchy.tables.filter(visualTableId => visualTableId.tableId === tableId);
    for (let visualTableId of visualColumnTableIdsToRemove) {
      this._columnHierarchy.removeTable(visualTableId);
    }

    // remove table from cellConfiguration
    if (this.cellConfiguration.joinTableId && this.cellConfiguration.joinTableId.tableId === tableId) {
      this.cellConfiguration.joinTableId = undefined;
      this.cellConfiguration.joinTableAttributeName = undefined;
    }
  }

  removeAttribute(attId: AttributeId) {
    this._rowHierarchy.removeAttribute(attId);
    this._columnHierarchy.removeAttribute(attId);
    if (this.cellConfiguration.joinTableId && this.cellConfiguration.joinTableId.tableId === attId.tableId && this.cellConfiguration.joinTableAttributeName === attId.attributeName) {
      this.cellConfiguration.joinTableId = undefined;
      this.cellConfiguration.joinTableAttributeName = undefined;
    }
  }

  get columnAttributes(): AttributeId[] {
    return this._columnHierarchy.attributeIds;
  }

  /**
   * extract all tables and their attributes used in this view, these will be loaded when the view is shown
   * @return list of table ids and their associated attribute ids
   */
  get tablesAndAttributes(): { id: ViewId, tables: TableAndAttributeData[] } {
    const cellConfiguration = this.cellConfiguration;
    const tableIds = [...this.rowHierarchy.tables, ...this.columnHierarchy.tables];

    const tableAndAttributes = tableIds.map(visualTableId => {
      // filterDefinition column attributes for the given table
      const attributes = this.columnHierarchy.attributeIds
          .filter(attribute => attribute.tableId === visualTableId.tableId)
          .map(attribute => attribute.attributeName);
      // and add row attributes which do not exist already
      this.rowAttributeNames.forEach(attributeName => {
        if (attributes.indexOf(attributeName) === -1) {
          attributes.push(attributeName);
        }
      });
      return {tableId: visualTableId.tableId, attributes};
    });

    // add join table and attribute
    if (cellConfiguration.joinTableId) {
      const attributes: string[] = [];
      if (cellConfiguration.joinTableAttributeName) {
        attributes.push(cellConfiguration.joinTableAttributeName);
      }
      tableAndAttributes.push({tableId: cellConfiguration.joinTableId.tableId, attributes});
    }

    const result = tableAndAttributes.map(({tableId, attributes}) => ({
      id: tableId,
      attributes: attributes.filter(name => modelStore.tableHasAttributeDefinition(tableId, name)).map(name => ({
        name
      }))
    }));

    return {id: this.id, tables: result};
  }

  get displayMode(): MatrixDisplayMode {
    return this._displayMode;
  }

  set displayMode(value: MatrixDisplayMode) {
    this._displayMode = value;
  }

  get maxColumns(): number {
    return this._maxColumns;
  }

  set maxColumns(value: number) {
    this._maxColumns = value;
  }

  get columnBlockIndex(): number {
    return this._columnBlockIndex;
  }

  set columnBlockIndex(value: number) {
    this._columnBlockIndex = value;
  }

  @autobind
  public getTableIdByNodeLevel(level: number): TableId {
    return this.rowHierarchy.tables[level]?.tableId;
  }

  @autobind
  public getAttributeDefinitionByNodeLevel(level: number, attributeName: string): AttributeDefinition {
    let retVal: AttributeDefinition;

    const visualTableId = this.rowHierarchy.tables[level];
    if (visualTableId) {
      retVal = modelStore.getAttributeDefinition(visualTableId.tableId, attributeName);
    } else {
      const msg = `No table found for node level ${level}.`;
      log.warn(msg)
    }

    return retVal;
  }


  private _loadedSerializedModel: JSON;

  public setLoadedSerializedModel(serializedViewModel: Object): void {
    this._loadedSerializedModel = JSON.parse(JSON.stringify(serializedViewModel));
  }

  public getLoadedSerializedModel(): JSON {
    return this._loadedSerializedModel;
  }

  public getCurrentModelAsJSON(): JSON {
    return JSON.parse(JSON.stringify(serialize(this)));
  }

  public refreshLoadedSerializedModel(): void {
    this._loadedSerializedModel = this.getCurrentModelAsJSON();
  }

  public setWordWrapOnColumn(columnId, isWordWrap: boolean) {
    if (isWordWrap) {
      if (!this.getWordWrapOnColumn(columnId)) {
        this._wordWrappingColumIds.push(columnId);
      }
    } else {
      const existingIndex = this._wordWrappingColumIds.indexOf(columnId);
      if (existingIndex !== -1) {
        this._wordWrappingColumIds.splice(existingIndex, 1);
      }
    }
  }

  public getWordWrapOnColumn(columnId): boolean {
    return this._wordWrappingColumIds.indexOf(columnId) !== -1;
  }

  private enableAutoHeightFlag(attributeName: string, rowHierarchy: RowFilteredHierarchy): boolean {
    const enableAutoHeightFlagForWordWrapping = this._wordWrappingColumIds.indexOf(attributeName) !== -1
    return enableAutoHeightFlagForWordWrapping || this.enableAutoHeightFlagForImages(attributeName, rowHierarchy);
  }

  private enableAutoHeightFlagForImages(attributeName: string, rowHierarchy: RowFilteredHierarchy) {
    const filteredAttributeIds: AttributeId[] = rowHierarchy.attributeIds.filter(attributeId => attributeId.attributeName === attributeName);

    let attributeDefinition: AttributeDefinition;
    for (const attributeId of filteredAttributeIds) {
      attributeDefinition = modelStore.getAttributeDefinition(attributeId.tableId, attributeName);
      if (attributeDefinition && attributeDefinition.type === "Image") {
        return true;
      }
    }

    return false;
  }
}

function valueFormatter(params: ValueFormatterParams): string {
  if (Number.isNaN(params.value) || "NaN" === params.value) {
    return "";
  }

  let retVal = params.value;

  if (params.node) {
    const attributeDefinition = params.context.getAttributeDefinitionByNodeLevel(params.node.level, params.colDef.field);
    if (attributeDefinition && (params.value || params.value === 0)) {
      // For display we parse the internal value
      const internalValue = calculateInternalValue(params.value, attributeDefinition);
      retVal = renderDisplayValue(internalValue, attributeDefinition);
    }
  }

  return retVal;
}

function valueFormatterColumn(params: ValueFormatterParams): string {
  if (Number.isNaN(params.value) || "NaN" === params.value) {
    return "";
  }

  let retVal = params.value;

  if (params.value !== undefined && params.value !== null) {
    const n: Numeral = validateInternalNumberAndCreateNumeral(params.value.toString());
    if (n !== undefined) {
      /* Set locale for formatting the parsed number. */
      numeral.locale(LOCALE_KEY_DISPLAY);
      retVal = calculateFormattedValue("#0,##########", n);
    }
  }

  return retVal;
}

function valueParser(params: ValueParserParams): string | number {
  let retVal = params.oldValue;

  if (hasValueChanged(params)) {
    retVal = params.newValue;

    const attributeDefinition = params.context.getAttributeDefinitionByNodeLevel(params.node.level, params.colDef.field);

    if (attributeDefinition.formatType === "Double") {
      // This is a precaution.
      const decimalDelimiter = numeral.localeData(LOCALE_KEY_DISPLAY).delimiters.decimal;
      retVal = (<string>retVal).replace(".", decimalDelimiter);

      let value = convertStringValueToNumber(calculateInternalValue(retVal, attributeDefinition), attributeDefinition);

      if (!value && value !== 0) {
        retVal = NaN;
      } else if (typeof (value) === "number") {
        retVal = value;
      }
    }
  }

  return retVal;
}

function valueParserColumn(params: ValueParserParams): string | number {
  let retVal = params.oldValue;

  if (hasValueChanged(params)) {
    retVal = params.newValue;

    // This is a precaution.
    const decimalDelimiter = numeral.localeData(LOCALE_KEY_DISPLAY).delimiters.decimal;
    retVal = (<string>retVal).replace(".", decimalDelimiter);

    let value = calculateInternalConnectionStrength(retVal);

    if (!value && value !== 0) {
      retVal = NaN;
    } else if (typeof (value) === "number") {
      retVal = value;
    }
  }

  return retVal;
}

export function hasValueChanged(params: ValueParserParams | CellValueChangedEvent): boolean {
  return !(params.oldValue === params.newValue
      || "" + params.oldValue === params.newValue
      || (Number.isNaN(params.oldValue) && "NaN" === params.newValue)
      || (Number.isNaN(params.oldValue) && Number.isNaN(params.newValue))
      || params.oldValue === undefined && params.newValue === null
      || params.oldValue === null && params.newValue === undefined
      || params.oldValue === "" && params.newValue === undefined
  );
}

export function isEditable(params: IsColumnFuncParams): boolean {
  let retVal: boolean = false;
  const attributeDefinition = params.context.getAttributeDefinitionByNodeLevel(params.node.level, params.colDef.field);
  if (attributeDefinition) {
    retVal = attributeDefinition.editable;
  }
  return retVal;
}


export function getCellEditor(params: ICellEditorParams): ComponentSelectorResult {
  const attributeDefinition = params.context.getAttributeDefinitionByNodeLevel(params.node.level, params.colDef.field);
  const tableId = params.context.getTableIdByNodeLevel(params.node.level);
  if (isReferenceAttributeDefinition(attributeDefinition)) {
    return {
      component: "MatrixConnectionsCellEditorComponent",
      params: {referenceAttributeId: new AttributeId(attributeDefinition.referencedTableId, attributeDefinition.referencedAttributeName)} as ReferenceAttributeCellEditorParams
    };
  }
  return null;
}

function onCellContextMenu(cellContextMenuEvent: CellContextMenuEvent) {
  // set primary selection, the internal (AG-Grid) selection will be updated by onMetusSelectionChanged
  if (cellContextMenuEvent.node) {
    const nodeData = cellContextMenuEvent.node.data;
    const elementId = nodeData.elementId;
    const event = cellContextMenuEvent.event;
    if (!modelStore.primarySelection.has(elementId)) {
      let newSelection;
      // extend selection if clicked with ctrl, replace otherwise
      if (event["ctrlKey"]) {
        newSelection = [...modelStore.primarySelection, elementId];
      } else {
        newSelection = [elementId];
      }
      Dispatcher.dispatch(new SelectElementsAction(newSelection, false, false));
    }
  }
}