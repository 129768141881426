import {ElementId, ElementObject, TableId, ViewId} from "../utils/Core";
import {ActionBase} from "../../common/actions/BaseAction";
import {ElementIdsMap, UUID, WriteLock} from "../../api/api";


export class SelectElementsAction extends ActionBase<{ elementIds: ElementId[], extend: boolean, toggle: boolean }> {
  type: "selectelements" = "selectelements";

  constructor(elementIds: ElementId[], extend: boolean, toggle: boolean, groupId?: UUID) {
    super({elementIds, extend, toggle}, null, groupId);
  }
}

export class ClearSelectionAction extends ActionBase<undefined> {
  type: "clearselection" = "clearselection";

  constructor() {
    super(undefined);
  }
}

export class DeleteElementsAction extends ActionBase<ElementId[]> implements ServerWritingAction {
  type: "deleteElements" = "deleteElements";

  constructor(public commandId: string, elementIds: ElementId[]) {
    super(elementIds);
  }
}

export interface MoveElementsToTablePayload {
  previousToNewElementIdsMap: ElementIdsMap;
  sourceTableId: TableId;
  targetTableId: TableId;
  lostAttributeNames: string[];
  isCopy: boolean;
  y?: number;
}

export class MoveElementsToTableAction extends ActionBase<MoveElementsToTablePayload> implements ServerWritingAction {
  type: "moveElementsToTable" = "moveElementsToTable";

  constructor(public commandId: string, moveElementsToTablePayload: MoveElementsToTablePayload, groupId: UUID) {
    super(moveElementsToTablePayload, null, groupId);
  }
}

export class DeleteTablesAction extends ActionBase<TableId[]> implements ServerWritingAction {
  type: "deleteTables" = "deleteTables";

  constructor(public commandId: string, tableIds: TableId[], groupId: UUID) {
    super(tableIds, null, groupId);
  }
}

export interface AttributesToDelete {
  tableIds: TableId[];
  attributeNames: string[][];
}

export class DeleteAttributesAction extends ActionBase<AttributesToDelete> implements ServerWritingAction {
  type: "deleteAttributes" = "deleteAttributes";

  constructor(public commandId: UUID, attributes: AttributesToDelete, groupId: string) {
    super(attributes, null, groupId);
  }
}

export class NewAttributeAction extends ActionBase<string> implements ServerWritingAction {
  type: "newAttribute" = "newAttribute";

  constructor(public commandId: string, attributeName: string, groupId: string) {
    super(attributeName, null, groupId);
  }
}

export class NewFolderAction extends ActionBase<string> implements ServerWritingAction {
  type: "newFolder" = "newFolder";

  constructor(public commandId: string, folderName: string, folderId: string, groupId: string) {
    super(folderName, folderId, groupId);
  }
}

export interface ServerWritingAction {
  // contains the command ID from the command which ran on the server
  commandId: string;
}

export class SetWriteLockAction extends ActionBase<WriteLock> {
  type: "setWriteLock" = "setWriteLock";

  constructor(writeLock:WriteLock) {
    super(writeLock);
  }
}

export class ModelDirtyAction extends ActionBase<void> {
  type: "modelDirty" = "modelDirty";

  constructor() {
    super();
  }
}

/**
 * creates a new element in the store, action creator caused side effects on server before
 */
export class NewElementAction extends ActionBase<{ elementId: ElementId, tableId: TableId, name: string, x?: number, y?: number }> implements ServerWritingAction {
  type: "newElement" = "newElement";

  constructor(public commandId: string, elementId: ElementId, name: string, tableId: TableId, x?: number, y?: number, groupId?: string) {
    super({elementId, tableId, name, x, y}, elementId, groupId);
  }
}

/**
 * duplicates a new element in the store
 */
export class DuplicateElementsAction extends ActionBase<{ originalElementIds: Array<ElementId>, newElementIds: Array<ElementId>, viewId: ViewId, newY: number}> implements ServerWritingAction {
  type: "duplicateElements" = "duplicateElements";

  constructor(public commandId: string, originalElementIds: Array<ElementId>, newElementIds: Array<ElementId>, viewId: ViewId, newY: number = null) {
    super({originalElementIds, newElementIds, viewId, newY});
  }
}

/**
 * updates attribute values for the given element
 */
export class UpdateAttributeValuesAction extends ActionBase<ElementObject> implements ServerWritingAction {
  type: "updateAttributeValues" = "updateAttributeValues";

  constructor(public commandId: UUID, tableId: TableId, element: ElementObject) {
    super(element, tableId);
  }
}

export type CoreSyncActions =
    SelectElementsAction
    | ClearSelectionAction
    | DeleteElementsAction
    | DeleteTablesAction
    | DeleteAttributesAction
    | NewAttributeAction
    | NewFolderAction
    | SetWriteLockAction
    | UpdateAttributeValuesAction
    | NewElementAction
    | ModelDirtyAction
    | MoveElementsToTableAction
    ;
