/* ColorWheelIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, November 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../../icons/menu/MetusSvgIcon";


class ColorWheelIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
         .cls-cw-1{fill:#83d9ff;}
         .cls-cw-2{fill:#3fefef;}
         .cls-cw-3{fill:#1dd1d9;}
         .cls-cw-4{fill:#17b5ba;}
         .cls-cw-5{fill:#9be058;}
         .cls-cw-6{fill:#c0ff66;}
         .cls-cw-7{fill:#ffdf46;}
         .cls-cw-8{fill:#fc3e81;}
         .cls-cw-9{fill:#f01054;}
         .cls-cw-10{fill:#7884ea;}
         .cls-cw-11{fill:#1685fb;}
         .cls-cw-12{fill:#43a3f9;}
         .cls-cw-13{fill:#64bdff;}
         .cls-cw-14{fill:#4a5be5;}
         .cls-cw-15{fill:#1cc939;}
         .cls-cw-16{fill:#364ec6;}
         .cls-cw-17{fill:#f9b200;}
         .cls-cw-18{fill:#fd6096;}
     `}</style>
      <g id="color_wheel_icon">
        <path className="cls-cw-1"
              d="M0,12A12,12,0,1,1,12,24,12,12,0,0,1,0,12Zm12,7.18A7.18,7.18,0,1,0,4.82,12,7.19,7.19,0,0,0,12,19.18Z"/>
        <path className="cls-cw-2" d="M12,24V19.18a7.24,7.24,0,0,0,2.6-.49l1.65,4.54A12,12,0,0,1,12,24Z"/>
        <path className="cls-cw-3" d="M14.6,18.69a7.13,7.13,0,0,0,2.23-1.38l3.1,3.7a12.18,12.18,0,0,1-3.68,2.22Z"/>
        <path className="cls-cw-4" d="M16.83,17.31a7.22,7.22,0,0,0,1.57-2.06l4.18,2.41A12.15,12.15,0,0,1,19.93,21Z"/>
        <path className="cls-cw-5"
              d="M19,10.32l4.76-.83A12.21,12.21,0,0,1,24,12a12.82,12.82,0,0,1-.11,1.65l-4.76-.84A7,7,0,0,0,19,10.32Z"/>
        <path className="cls-cw-6" d="M18,8.08,22.2,5.67a12.21,12.21,0,0,1,1.54,3.82L19,10.32A7,7,0,0,0,18,8.08Z"/>
        <path className="cls-cw-7"
              d="M16.39,6.32l3.1-3.69a12.1,12.1,0,0,1,2.71,3L18,8.08A7.34,7.34,0,0,0,16.39,6.32Z"/>
        <path className="cls-cw-8" d="M8,.67A12.06,12.06,0,0,1,12,0V4.82a7.31,7.31,0,0,0-2.31.38Z"/>
        <path className="cls-cw-9" d="M4.51,2.63A11.84,11.84,0,0,1,8,.67H8L9.69,5.2A7.2,7.2,0,0,0,7.61,6.32Z"/>
        <path className="cls-cw-10" d="M1.8,5.67a12.1,12.1,0,0,1,2.71-3l3.1,3.69A7.34,7.34,0,0,0,6,8.08Z"/>
        <path className="cls-cw-11"
              d="M.11,13.65l4.76-.84a7.2,7.2,0,0,0,.73,2.44L1.42,17.66A11.83,11.83,0,0,1,.11,13.65Z"/>
        <path className="cls-cw-12"
              d="M1.42,17.66,5.6,15.25a7.22,7.22,0,0,0,1.57,2.06L4.07,21A12.15,12.15,0,0,1,1.42,17.66Z"/>
        <path className="cls-cw-13"
              d="M4.07,21l3.1-3.7A7.13,7.13,0,0,0,9.4,18.69L7.75,23.23A12.18,12.18,0,0,1,4.07,21Z"/>
        <path className="cls-cw-14" d="M.26,9.49A12.21,12.21,0,0,1,1.8,5.67L6,8.08a7,7,0,0,0-1,2.24Z"/>
        <path className="cls-cw-15" d="M18.4,15.25a7.2,7.2,0,0,0,.73-2.44l4.76.84a11.83,11.83,0,0,1-1.31,4Z"/>
        <path className="cls-cw-16"
              d="M0,12A12.21,12.21,0,0,1,.26,9.49L5,10.32a7,7,0,0,0-.15,2.49l-4.76.84A12.82,12.82,0,0,1,0,12Z"/>
        <path className="cls-cw-17"
              d="M14.31,5.2,16,.67a11.84,11.84,0,0,1,3.53,2l-3.1,3.69A7.2,7.2,0,0,0,14.31,5.2Z"/>
        <path className="cls-cw-18" d="M12,4.82V0a12.06,12.06,0,0,1,4,.67L14.31,5.2A7.31,7.31,0,0,0,12,4.82Z"/>
      </g>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(ColorWheelIcon);
