import {AttributeId, ElementId, TableId, ViewId, VisualAttributeId, VisualTableId} from "../../core/utils/Core";
import {baseurl, load} from "../../commonviews/actions/RESTCallActionCreatorsBase";
import {deserialize} from "serializr";
import {
  AttributeDefinition,
  ExtTableId,
  GeneralizedAttributeData,
  NAME_ATT_NAME,
  Table,
  TreeGridAttributeData,
  TreeGridData,
  UUID
} from "../../api/api";
import {MatrixModel} from "../models/MatrixModel";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {
  getAttributeValuesForTable,
  getAttributeValuesForTablesWithReferencedAttributes,
  getConnectionsForTables,
} from "../../core/actions/CoreAsyncActionCreators";
import {Target, ViewType} from "../../common/constants/Enums";
import {modelStore} from "../../core/stores/ModelStore";
import {generateUUID} from "../../common/utils/IdGenerator";
import {matrixStore} from "../stores/MatrixStore";
import {
  createConnection,
  deleteConnection,
  updateConnection
} from "../../core/services/CoreDataServices";
import {ChangeCellConfigurationAction, LoadMatrixAction} from "./MatrixAsyncActions";
import {LoadActionType} from "../../common/actions/BaseAction";
import {AddAttributeToViewAction, AddTableToViewAction} from "../../core/actions/CoreAsyncActions";
import {showConfirmationDialog} from "../../common/utils/CommonDialogUtil";
import {UpdateMaxNumberOfColumnsPerPageAction} from "./MatrixActions";
import {DeserializedModel} from "../../commonviews/models/DeserializedModel";

export async function changeCellConfiguration(viewId: ViewId, joinTableId: TableId, joinTableAttributeName: string, showCount: boolean): Promise<void> {
  const groupId = generateUUID();
  Dispatcher.dispatch(new ChangeCellConfigurationAction(viewId, new VisualTableId(joinTableId), joinTableAttributeName, showCount, groupId));
  if (joinTableId) {
    // make sure to first load table, then connections, otherwise connections will have non-existing elements
    await getAttributeValuesForTable(viewId, joinTableId, [joinTableAttributeName || NAME_ATT_NAME], groupId);
    await getConnectionsForTables(viewId, [joinTableId], groupId);
  }
}

/**
 * creates a matrix view from a raw table and opens it
 * @param tableId
 * @param groupId
 */
export function loadTable(tableId: TableId, groupId: UUID): Promise<MatrixModel> {
  const matrixModel: MatrixModel = buildMatrixModelForTableView(tableId);
  return loadAndDispatchMatrixData(matrixModel, tableId, groupId);
}

/**
 * create a matrix definition for a table containing all attributes as columns
 * @param tableId
 */
function buildMatrixModelForTableView(tableId: TableId): MatrixModel {
  const attributeDefinitions: AttributeDefinition[] = modelStore.attributeDefinitions.get(tableId);
  const model = new MatrixModel(tableId, ViewType.Table);
  model.rowHierarchy.tables.push(new VisualTableId(tableId));
  model.rowHierarchy.attributeIds = attributeDefinitions.map(attDef => new AttributeId(tableId, attDef.name));
  return model;
}

export interface TableAndAttributeData {
  attributes: GeneralizedAttributeData[];
  id: ExtTableId;
}

export function loadAttributeValuesAndConnections(tableAndAttributeData: { id: ViewId, tables: TableAndAttributeData[] }, groupId: UUID): Promise<any> {
  const viewId = tableAndAttributeData.id;
  return getAttributeValuesForTablesWithReferencedAttributes(viewId, tableAndAttributeData.tables, groupId)
      .then((attrValues: Table[]) => {
        const requestedTableIds = tableAndAttributeData.tables.map(table => table.id);
        return getConnectionsForTables(viewId, requestedTableIds, groupId);
      });
}

/**
 * loads all the data into the model store which is needed by the matrix model and dispatches all loaded data to the store(s)
 * @param matrixModel
 * @param viewId
 * @param groupId
 */
function loadAndDispatchMatrixData(matrixModel: MatrixModel, viewId: ViewId, groupId: UUID): Promise<any> {
  Dispatcher.dispatch(new LoadMatrixAction(matrixModel, viewId, groupId));
  return loadAttributeValuesAndConnections(matrixModel.tablesAndAttributes, groupId);
}

export async function loadMatrix(viewId: ViewId, groupId: UUID): Promise<MatrixModel> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/webviews/${viewId}`;
  const matrixModel = await loadAndDeserialize<MatrixModel>(MatrixModel, "loadMatrix", viewId, url, groupId);
  // the id was used from the serialized diagram data. Since
  // MO-2687 extend scripting API to access web view data and web view hierarchy
  // the external id used to address the resource is used.
  matrixModel.id = viewId;
  await loadAndDispatchMatrixData(matrixModel, viewId, groupId);
  return matrixModel;
}

/**
 * MO-2164 migrate legacy structured tables to new matrix implementation
 * @param serializedView json of old TreeGridData structure
 * @return migrated Matrix Model
 */
export function migrateLegacyStructuredTable(serializedView: TreeGridData): MatrixModel {
  const m = new MatrixModel(serializedView.id, ViewType.StructuredTable);
  m.rowHierarchy.tables.push(...serializedView.tables.map(t => new VisualTableId(t.id)));
  const attributes: TreeGridAttributeData[] = [];
  // flatten attribute data and remove duplicates
  serializedView.tables.forEach(t => t.attributes.forEach(a => {
    if (!attributes.find(attData => attData.name === a.name)) {
      attributes.push(a);
    }
  }));
  // clear name attribute, this will be included in legacy attribute data
  m.rowHierarchy.attributeIds = [];
  // sort by index
  attributes.sort((att1, att2) => att1.index - att2.index);
  m.rowHierarchy.tables.forEach((tableId) => {
    m.rowHierarchy.attributeIds.push(...attributes.map(att => new AttributeId(tableId.tableId, att.name)));
  });
  return m;
}

export async function loadAndDeserialize<T extends DeserializedModel<Object>>(modelSchema: new () => T, loadType: LoadActionType, viewId: ViewId, url: string, groupId: string): Promise<T> {
  const serializedViewModel: any = await load(loadType, viewId, url, true, groupId);

  let deserializedView: T;
  if (serializedViewModel.version) {
    deserializedView = deserialize(modelSchema, serializedViewModel);
  } else {
    deserializedView = migrateLegacyStructuredTable(serializedViewModel) as any as T;
  }

  deserializedView.setLoadedSerializedModel(serializedViewModel);

  return deserializedView;
}

export function addTableToMatrix(viewId: ViewId, tableId: TableId, tableName: string, index?: number, target: Target = Target.ROW): Promise<any> {
  const groupId = generateUUID();
  const visualTableId = new VisualTableId(tableId);
  Dispatcher.dispatch(new AddTableToViewAction(viewId, visualTableId, index, groupId, target));
  return addAttributeToMatrix(viewId, new VisualAttributeId(visualTableId, NAME_ATT_NAME), {
    x: 0,
    y: 0
  }, undefined, undefined, target === Target.COLUMN).then(() => {
    return getConnectionsForTables(viewId, [tableId], groupId);
  });
}

export function addAttributeToMatrix(viewId: ViewId, visualAttributeId: VisualAttributeId, requestedCoords: { x: number; y: number }, initial: boolean = false, addToRight: boolean = false, isColumn: boolean = false, groupId: UUID = undefined): Promise<Table[]> {
  if (groupId === undefined) {
    groupId = generateUUID();
  }
  const tableId: TableId = visualAttributeId.visualTableId.tableId;
  const attributeName: string = visualAttributeId.attributeName;
  loadAttributeValuesForOtherTablesInHierarchy(viewId, attributeName, tableId, requestedCoords, initial, addToRight, isColumn, groupId);
  Dispatcher.dispatch(new AddAttributeToViewAction(viewId, visualAttributeId, requestedCoords, initial, addToRight, isColumn, groupId));
  return getAttributeValuesForTable(viewId, tableId, [attributeName], groupId);
}

function loadAttributeValuesForOtherTablesInHierarchy(viewId: ViewId, attName: string, tableId: string, requestedCoords: { x: number; y: number }, initial: boolean, addToRight: boolean, isColumn: boolean, groupId: UUID) {
  const matrix = matrixStore.getMatrixById(viewId);
  // get set of unique tableIds
  const hierarchy = isColumn ? matrix.columnHierarchy : matrix.rowHierarchy;
  const tableIds = new Set(hierarchy.tables.map(vTiD => vTiD.tableId));
  // remove the origin table from the set
  tableIds.delete(tableId);
  tableIds.forEach(tId => {
    if (modelStore.getAttributeDefinition(tId, attName)) {
      // dummy visual id
      const vAttId = new VisualAttributeId(new VisualTableId(tId), attName);
      Dispatcher.dispatch(new AddAttributeToViewAction(viewId, vAttId, requestedCoords, initial, addToRight, isColumn, groupId));
      getAttributeValuesForTable(viewId, tId, [attName], groupId);
    }
  });
}

/**
 * updates a connection if the matrix cell value was changed
 * @param rowElementId
 * @param columnElementId
 * @param newValue new value for connection strength, undefined means no connection at all
 */
export function changeStandardCellConnection(rowElementId: ElementId, columnElementId: ElementId, newValue: number | undefined) {
  if (newValue !== undefined && newValue !== null && typeof newValue === "number") {
    if (modelStore.isConnected(rowElementId, columnElementId)) {
      // strength changed from one value to another --> just update this
      updateConnection(rowElementId, columnElementId, newValue);
    } else {
      createConnection(rowElementId, columnElementId, newValue);
    }
  } else if (newValue === undefined) {
    deleteConnection(rowElementId, columnElementId);
  }
}

export function updateMaxNumberOfColumnsPerPage(viewId: ViewId, newMaxNumberOfColumnsPerPage: number): void {
  const matrix = matrixStore.getMatrixById(viewId);
  const prevMaxNumberOfColumnsPerPage = matrix.maxColumns;
  const updateFunction = function () {
    const updateColumnLimitAction = new UpdateMaxNumberOfColumnsPerPageAction(viewId, newMaxNumberOfColumnsPerPage);
    Dispatcher.dispatch(updateColumnLimitAction);
  }
  if (newMaxNumberOfColumnsPerPage > prevMaxNumberOfColumnsPerPage) {
    showConfirmationDialog("By increasing the number of maximum columns the performance of the matrix is affected. " +
        "It can slow down and even lead to a crashes. \nAre you sure you want to change it?", updateFunction, () => {
      return;
    })
  } else if (newMaxNumberOfColumnsPerPage < prevMaxNumberOfColumnsPerPage) {
    updateFunction();
  }

}
