import Action from "../../common/actions/BaseAction";
import StoreBase from "../../common/stores/StoreBase";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
// noinspection ES6UnusedImports
import {action, observable} from "mobx";
import myHistory from "../../workbench/utils/MyHistory";
import {ModelInfo} from "../../core/models/ModelInfo";
import {ModelLocationType} from "../../common/constants/Enums";

const log = Log.logger("ModelSelectionStore", Classifier.action);

// TODO: refactor login/modelselection to SessionStore
// separate page and pass modelName and type to ModelStore
export class ModelSelectionStore extends StoreBase {
  @observable public modelNames: string[];
  @observable public selectedModel: ModelInfo;
  @observable public selectedModelLocation: ModelLocationType;
  @observable public user: string = undefined;

  constructor() {
    super(false);
    this.reset();
  }

  @action accept(actionParam: Action): void {
    log.debug("ModelSelectionStore accepting", actionParam);
    switch (actionParam.type) {
      case "selectmodellocation":
        log.debug("ModelSelectionStore: Model location selected", actionParam.payload);
        this.reset();
        this.selectedModelLocation = actionParam.payload;
        this.notify(actionParam.type);
        break;
      case "resetModelLocation":
        log.debug("ModelSelectionStore: Resetting model location");
        this.reset();
        myHistory.push("/");
        break;
      case "modellist":
        log.debug("ModelSelectionStore: Got list of all models", actionParam.payload);
        this.modelNames = actionParam.payload;
        this.notify(actionParam.type);
        break;
      case "selectmodel":
        log.debug("ModelSelectionStore: Model selected", actionParam.payload);
        this.selectedModel = actionParam.payload;
        if (this.selectedModel !== null) {
          // necessary to keep selectedModelLocation in sync when loading via direct link
          this.selectedModelLocation = this.selectedModel.location;
        }
        this.notify(actionParam.type);
        break;
      case "authenticated":
        log.debug("ModelSelectionStore: User authenticated", actionParam.payload);
        this.user = actionParam.payload;
        this.notify(actionParam.type);
        break;
      case "loadUserName":
        log.debug("ModelSelectionStore: User Name loaded", actionParam.payload);
        if (actionParam.payload && actionParam.payload.userName) {
          this.user = actionParam.payload.userName;
          this.notify(actionParam.type);
        }
        break;
    }
  }

  reset(keepCurrentModel: boolean = false): void {
    if (!keepCurrentModel) {
      this.selectedModel = undefined;
      this.selectedModelLocation = undefined;
      this.modelNames = [];
    }
  }

  isLiveServer(): boolean {
    return this.selectedModelLocation === ModelLocationType.liveserver;
  }
}

const modelSelectionStore = new ModelSelectionStore();
export {modelSelectionStore};
