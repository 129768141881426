// lib imports
import * as React from "react";
import {Rectangle} from "../utils/Geometry";
/**
 * Drag Rectangle for Selection
 * @author Marco van Meegen
 *
 */
interface LocalProps {
  rect: Rectangle;
}

export class DragRect extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    return <div className="dragrect" style={{
      top: this.props.rect.y,
      left: this.props.rect.x,
      width: this.props.rect.width,
      height: this.props.rect.height
    }}/>;
  }
}



