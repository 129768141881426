import "../../common/css/metusapp.css";
import * as React from "react";
import "react-loading-bar/dist/index.css";
import Log from "../../common/utils/Logger";
import {modelSelectionStore} from "../../modelselection/stores/ModelSelectionStore";
import myHistory, {createRoute} from "../utils/MyHistory";
import NavigationSidebarComponent from "./navigation/NavigationSidebarComponent";
import MainLayoutComponent from "./MainLayoutComponent";
import KeyVisual from "../../common/components/KeyVisual";
import MetusWorkbenchContainer from "./MetusWorkbenchContainer";
import ProjectSelectionComponent from "../../modelselection/components/ProjectSelectionComponent";
import {Classifier} from "../../common/utils/ClassifierLogger";
import theme, {startScreenTheme} from "../../common/theme/Theme";
import MetusMainNavigationComponent from "../../modelselection/components/MainNavigationComponent";
import {MosaicNode} from "react-mosaic-component";
import autobind from "autobind-decorator";
import {resetModelCache} from "../../modelselection/actions/ModelAsyncActionCreators";
import {action} from "mobx";
import {observer} from "mobx-react";
import MetusSidebarEntry from "../../common/components/MetusSidebarEntry";
import {Menu} from "@material-ui/icons";
import MetusSidebar from "../../common/components/MetusSidebar";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {WindowArrangementChangedAction} from "../actions/ViewManagerActions";
import CockpitHeaderComponent from "./CockpitHeaderComponent";
import {JssProvider} from "react-jss";
import {metusJss} from "../MetusJssProvider";
import {setStateToLocalStorage} from "../utils/MetusLocalStorage";
import {metusStore} from "../stores/MetusStore";
import {MuiThemeProvider} from "@material-ui/core/styles";
import * as _ from "lodash";
import {metusPropertiesDerivedModel} from "./properties/MetusPropertiesModel";
import {ModelInfo} from "../../core/models/ModelInfo";
import {ModelLocationType} from "../../common/constants/Enums";


const log = Log.logger("AppContainer");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
  location?: any;
}

interface SidebarContainerProps {
  onClosedChanged?: (isClosed: boolean) => void;
  closed?: boolean;
}

// SidebarAdapter and LeftMenuContainer implement the same logic, i.e. translating from
// closed and onClosedChanged to onVisibleEntryChange and visibleEntryIndex
// How could this be shared?
class SidebarAdapter extends React.Component<SidebarContainerProps, undefined> {

  constructor(props: SidebarContainerProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering AppContainer", this.state);

    return <MetusSidebar type={"menu"} onVisibleEntryChange={this.onVisibleEntryChange}
                         visibleEntryIndex={this.props.closed ? null : 0}>
      <MetusSidebarEntry icon={<Menu/>}>
        {this.props.children}
      </MetusSidebarEntry>
    </MetusSidebar>;
  }

  @autobind
  private onVisibleEntryChange(entry: number): void {
    const isClosed = entry === null;
    this.props.onClosedChanged(isClosed);
  }

}


@observer
class AppContainer extends React.Component<LocalProps, undefined> {

  constructor(props: LocalProps) {
    super(props);
    this.openModel = _.debounce(this.openModel, 500).bind(this);
  }

  // debounced function, autobind does not work
  // MO-2654 Server - app crashes when opening a project from cloud
  private openModel(selectedModelName: string): void {
    log.debug("AppContainer: Model selected", selectedModelName);
    const modelInfo = new ModelInfo(modelSelectionStore.selectedModelLocation, selectedModelName);
    const newPath = createRoute(modelInfo, "app");
    myHistory.push(newPath);
  }

  @autobind @action
  private onChange(windowArrangement: MosaicNode<number>): void {
    Dispatcher.dispatch(new WindowArrangementChangedAction(windowArrangement));
  }

  @autobind
  private onResetModelCache(selectedModelName: string): void {
    const modelInfo = new ModelInfo(modelSelectionStore.selectedModelLocation, selectedModelName);
    resetModelCache(modelInfo);
  }

  @autobind
  private saveWindowArrangement(): void {
    setStateToLocalStorage(metusStore.openViews(), metusStore.windowArrangement);
  }

  componentDidMount(): void {
    window.addEventListener("blur", this.saveWindowArrangement);
  }

  componentWillUnmount(): void {
    window.removeEventListener("blur", this.saveWindowArrangement);
  }


  render(): JSX.Element {
    renderLog.debug("Rendering AppContainer", this.state);
    const selectedLocation: ModelLocationType = modelSelectionStore.selectedModelLocation;
    const modelNames: string[] = modelSelectionStore.modelNames;
    let selectedModelName: string = undefined;
    let isStartScreen: boolean = true;
    if (modelSelectionStore.selectedModel) {
      selectedModelName = modelSelectionStore.selectedModel.name;
      isStartScreen = false;
    }

    let leftMenuContainer = null;
    let contentArea = null;
    let showProperties: boolean = false;

    if (modelSelectionStore.selectedModel !== undefined) {
      leftMenuContainer = <NavigationSidebarComponent modelLocation={selectedLocation}/>;
      contentArea = <MetusWorkbenchContainer windowArrangement={metusStore.windowArrangement}
                                             onChange={this.onChange}
                                             getViewInfo={metusStore.getViewInfoByWindowIndex}
                                             createNode={metusStore.createNode}
                                             isFullscreen={metusStore.isFullscreen}/>;
      showProperties = true;
    } else {
      leftMenuContainer =
          <SidebarAdapter>
            <MetusMainNavigationComponent location={selectedLocation} modelName={selectedModelName}/>
            <ProjectSelectionComponent modelLocation={selectedLocation}
                                       onModelSelected={this.openModel}
                                       onResetModelCache={this.onResetModelCache}
                                       modelNames={modelNames}/>
          </SidebarAdapter>;
      contentArea = <KeyVisual/>;
    }


    return <JssProvider jss={metusJss}><MuiThemeProvider theme={isStartScreen ? startScreenTheme : theme}>
      <MainLayoutComponent leftSidebar={leftMenuContainer}
                           showRightSidebar={!metusStore.isFullscreen && showProperties}
                           showLeftSidebar={!metusStore.isFullscreen}
                           propertiesModel={metusPropertiesDerivedModel}>
        {metusStore.currentCockpit &&
        <CockpitHeaderComponent cockpitId={metusStore.currentCockpit.id}
                                cockpitName={metusStore.currentCockpit.name}
                                backgroundColor={metusStore.currentCockpit.iconColor}
                                iconType={metusStore.currentCockpit.iconType}
                                isDirty={metusStore.currentCockpit.isDirty}/>}
        {contentArea}
      </MainLayoutComponent>
    </MuiThemeProvider>
    </JssProvider>;

  }

}

export default AppContainer;
