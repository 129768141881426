/* CockpitsIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "./MetusSvgIcon";

class CockpitsIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <g>
        <path
            d="M20,7 L20,17 L30,17 L30,29 L8,29 L8,7 L20,7 Z M28,19 L20,19 L20,27 L28,27 L28,19 Z M18,19 L10,19 L10,27 L18,27 L18,19 Z M18,9 L10,9 L10,17 L18,17 L18,9 Z M28,6 L28,9 L31,9 L31,11 L28,11 L28,14 L26,14 L26,11 L23,11 L23,9 L26,9 L26,6 L28,6 Z"
            />
      </g>
    </React.Fragment>
  }

  viewBox(): string {
    return "0 0 36 36";
  }
}

export default withStyles(svgIconStyles)(CockpitsIcon);
