import * as React from "react";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {OutlinedTextFieldProps} from "@material-ui/core/TextField";
import {StyleRules} from "@material-ui/core/styles/withStyles";
import TextFieldComponent from "./TextFieldComponent";


const styles = (theme: Theme):StyleRules => createStyles({
  selectMenuPaperRoot: {
    borderRadius: 1,
  }
});


interface LocalState {
}

type StyledLocalProps = OutlinedTextFieldProps & WithStyles<typeof styles>;

class DropDownComponent extends React.Component<StyledLocalProps, LocalState> {

  render(): JSX.Element {
    const {classes, ...props1} = this.props;
    const SelectProps = Object.assign({MenuProps: {PaperProps: {classes: {rounded: this.props.classes.selectMenuPaperRoot}}}}, this.props.SelectProps);
    return <TextFieldComponent
        select
        classes={classes}
        {...props1}
        SelectProps={SelectProps}/>;
  }
}

export default withStyles(styles)(DropDownComponent);