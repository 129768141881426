import {createStyles, FormLabel, MenuItem, TextField, Theme, withStyles} from "@material-ui/core";
import {TableId} from "../../core/utils/Core";
import * as React from "react";
import {modelStore} from "../../core/stores/ModelStore";
import {AttributeDefinition, NAME_ATT_NAME} from "../../api/api";
import {StyleRules} from "@material-ui/core/styles";
import {WithStyles} from "@material-ui/styles";
import autobind from "autobind-decorator";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const DropDownMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = (theme: Theme): StyleRules => {
  return createStyles({
    selectRoot: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
      display: "grid",
      alignItems: "center"
    },
  });
}

interface LocalProps {
  tableId?: TableId,
  attributeDefinitionName?: String
  customTableLabel?: String,
  customTableHelperText?: String,
  customAttDefLabel?: String,
  customAttDefHelperText?: String,
  onTableChange: any,
  onAttributeChange: any,
}

interface LocalState {
  tableId: TableId,
  attributeDefinitionName: String
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class AttributeDefinitionSelectionContainer extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {
      tableId: props.tableId,
      attributeDefinitionName: props.attributeDefinitionName || NAME_ATT_NAME,
    };
    props.onTableChange(this.state);
  }

  render(): JSX.Element {
    const tableNameByTableId: Map<TableId, string> = modelStore.tableNameByTableId;
    let attributes: AttributeDefinition[];
    if (this.state.tableId !== undefined && this.state.tableId !== null && this.state.tableId !== "")
      attributes = modelStore.getTableAttributeDefinitions([this.state.tableId])[0].attributes;
    else
      attributes = [];

    const fontWeightTargetAttribute = this.state.attributeDefinitionName !== null ? this.props.classes.fontWeightMedium : this.props.classes.fontWeightRegular;

    return <div className={this.props.classes.selectRoot}>
      <FormLabel
          style={{marginRight: "20px", gridRow: 1, gridColumn: 1}}>{this.props.customTableLabel || "Table:"}</FormLabel>
      <TextField
          style={{gridRow: 1, gridColumn: 2}}
          id="table-selector"
          select
          value={this.state.tableId}
          onChange={this.handleTableSelect}
          helperText={this.props.customTableHelperText || "Please select a Table"}
      >
        {(Array.from(tableNameByTableId.keys())).sort((tableId1: TableId, tableId2: TableId): number => {
          const tableName1: string = tableNameByTableId.get(tableId1).toLowerCase();
          const tableName2: string = tableNameByTableId.get(tableId2).toLowerCase();
          if (tableName1 < tableName2)
            return -1;
          else if (tableName1 > tableName2)
            return 1;

          return 0;
        }).map(tableId => (
            <MenuItem
                key={tableId}
                value={tableId}
                // style={{fontWeightTargetTable}}
            >
              {tableNameByTableId.get(tableId)}
            </MenuItem>
        ))}
      </TextField>
      <FormLabel style={{
        marginRight: "20px",
        gridRow: 2,
        gridColumn: 1
      }}>{this.props.customAttDefLabel || "Attribute:"}</FormLabel>
      <TextField
          style={{gridRow: 2, gridColumn: 2}}
          id="table-selector"
          select
          value={this.state.attributeDefinitionName}
          onChange={this.handleAttributeDefinitionSelect}
          helperText={this.props.customAttDefHelperText || "Please select an Attribute"}
      >
        {attributes.map(tableAttributeDefinitions => (
            <MenuItem
                key={tableAttributeDefinitions.name}
                value={tableAttributeDefinitions.name}
                className={fontWeightTargetAttribute}>
              {tableAttributeDefinitions.name}
            </MenuItem>
        ))}
      </TextField>
    </div>
  }

  @autobind
  private handleTableSelect(event: any): void {
    if (this.state.tableId !== event.target.value) {
      this.setState({tableId: event.target.value, attributeDefinitionName: NAME_ATT_NAME});
      this.props.onTableChange({tableId: event.target.value, attributeDefinitionName: NAME_ATT_NAME});
    }

  }

  @autobind
  private handleAttributeDefinitionSelect(event: any): void {
    if (this.state.attributeDefinitionName !== event.target.value) {
      this.setState({attributeDefinitionName: event.target.value})
      this.props.onAttributeChange(event.target.value);
    }
  }
}

export default withStyles(styles)(AttributeDefinitionSelectionContainer);