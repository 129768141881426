import StoreBase from "../../common/stores/StoreBase";

import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {action, observable} from "mobx";
import {LockedType, ModelMeta, WriteLock} from "../../api/api";
import {CoreActions, SelectModelAction} from "../actions/CoreAsyncActions";
import {LoadActions} from "../../modelselection/actions/ModelAsyncActionCreators";
import {NavigationActions} from "../actions/NavigationActions";
import {ModelDirtyAction, SetWriteLockAction} from "../actions/CoreActions";
import {ModelLocationType} from "../../common/constants/Enums";
import {ModelInfo} from "../models/ModelInfo";

const log = Log.logger("ConfigurationStore", Classifier.action);

/**
 * configures the client to the specific capabilities of the currently selected server,
 * e.g. scalable server can write, classic server cannot.
 */
export interface ServerConfiguration {
  canWrite: boolean;
  canCopyViewLink: boolean;
  canCopyCockpitLink: boolean;
  canDownloadSVG: boolean;
  canPrint: boolean;
  haveTestData: boolean;
  canUndo: boolean;
  metusOnlineUrl: string;
}

export class ConfigurationStore extends StoreBase {
  @observable public configuration: ServerConfiguration;
  private _modelMeta: ModelMeta;
  private _modelLocation: ModelLocationType;
  @observable private _writeLock: WriteLock;

  constructor() {
    super(false);
    this.reset();
  }

  reset(): void {
    this.configuration = {
      canWrite: false,
      canCopyViewLink: false,
      canCopyCockpitLink: false,
      canDownloadSVG: false,
      canPrint: false,
      haveTestData: false,
      canUndo: false,
      metusOnlineUrl: undefined
    }
    this._writeLock = {locked: LockedType.None};
    this._modelMeta = undefined;
  }

  /**
   * @param modelMeta
   */
  public initModelMeta(modelMeta: ModelMeta): void {
    this._modelMeta = modelMeta;
  }

  /**
   * @param modelMeta
   */
  public init(serverConfiguration: ServerConfiguration): void {
    this.reset();
    this.configuration = serverConfiguration;
  }

  public getMetusOnlineUrl(): string {
    return this.configuration.metusOnlineUrl;
  }

  public canLink(): boolean {
    // if not in-memory, copy link uses the base url and is always possible
    // if in-memory the online url must be configured in the msd file, so metus online embedded knows which server to use
    return this._modelLocation !== ModelLocationType.memory || (this.configuration.metusOnlineUrl ? true: false);
  }

  @action
  accept(actionParam: CoreActions | LoadActions | SelectModelAction | NavigationActions | SetWriteLockAction | ModelDirtyAction): void {
    log.debug("ConfigurationStore accepting " + actionParam.type);
    switch (actionParam.type) {
      case "selectmodel":
        // TODO: currently this fakes the logic calculating config settings from model location
        // once rest call to loadServerConfiguration is implemented this can be removed
        const modelInfo: ModelInfo = actionParam.payload;
        this._modelLocation = modelInfo.location;
        break;
      case "loadModelMeta":
        log.debug(`ConfigurationStore: meta model loaded ${actionParam.payload}`);
        const modelMeta = actionParam.payload;
        this.initModelMeta(modelMeta);
        break;
      case "loadServerConfiguration":
        log.debug(`ConfigurationStore: ServerConfiguration loaded ${actionParam.payload}`);
        const serverConfiguration = actionParam.payload;
        this.init(serverConfiguration);
        break;
      case "setWriteLock":
        const writeLock = actionParam.payload;
        let newLock;
        log.debug("Set Write Lock", writeLock);
        if (this._modelMeta.multiuserMode === "merge") {
          if (writeLock.locked === LockedType.Self) {
            newLock = writeLock;
          } else {
            if (this._writeLock?.changeSetId === writeLock.changeSetId) {
              // somebody else has locked or committed my current workspace
              newLock = {locked: LockedType.None};
            }
          }
        } else {
          newLock = writeLock;
        }
        if (newLock) {
          this._writeLock = newLock;
        }
        break;
    }

  }

  get writeLock(): WriteLock {
    return this._writeLock;
  }

  canWriteToServer(): boolean {
    return this.configuration.canWrite && (this._modelLocation !== ModelLocationType.liveserver || this._writeLock.locked === LockedType.Self);
  }

  canDeleteProject(): boolean {
    return this._modelLocation === ModelLocationType.liveserver && this.writeLock.locked === LockedType.Self;
  }

  get modelMeta(): ModelMeta {
    return this._modelMeta;
  }

  public isLockedFromOtherUser(): boolean {
    return configurationStore.writeLock.locked === LockedType.Other && configurationStore.modelMeta.multiuserMode === "lock";
  }

  /**
   * force the browser to show warning if unloaded ?
   */
  public isMessageDisplayedBeforeUnload(): boolean {
    // only for server write since otherwise not much is lost on unload
    // suppress for embedded since not relevant there
    return this.canWriteToServer() && !(this._modelLocation === ModelLocationType.memory);
  }
}

// singleton
// export default new InteractionStore();
const configurationStore = new ConfigurationStore();
export {configurationStore};
