import {ElementId, TableId, VisualAttributeId, VisualTableId} from "../../../core/utils/Core";
import {VisualHeader} from "../../../commonviews/models/VisualHeader";
import {VisualValueChartElement} from "./VisualValueChartElement";
import {Direction} from "../../../common/utils/Direction";
import {computed, observable} from "mobx";
import {VisualAttributeDefinition} from "../common/VisualAttributeDefinition";
import {VisualBaseTable} from "../common/CommonDiagramTypes";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {VisualObjectBase} from "../VisualObject";
import {ContainerColumnLayout, SizeListener} from "../common/ContainerColumnLayout";
import {RotationDegree} from "../../../api/api";
import {VisualValueChartMetadata} from "./VisualValueChartMetadata";
import {
  AdditionalPropArgs,
  custom,
  identifier,
  list,
  map,
  object,
  PropSchema,
  reference,
  serializable
} from "serializr";
import {VisualValueChart} from "./VisualValueChart";

const handleAfterDeserializeIdentifier: AdditionalPropArgs = {
  afterDeserialize: function (callback: (err: any, value: any) => void, error: any, newValue: any, jsonValue: any, jsonParentValue: any, propNameOrIndex: string | number | symbol, context: any, propDef: PropSchema): void {
    context.target.id = VisualTableId.fromKey(newValue);
    callback(null, newValue);
  }
};

export const serializer = (vvc: VisualValueChartElement): string => {
  return vvc.identifier;
}

export const deserializer = (json: string): VisualValueChartElement => {
  /* Lookup elements in afterDeserializeChildren in VisualValueChart */
  return null;
}

/**
 * for each table dragged into a ValueChart a level is created containing the associated table and layout informations.
 * The level is added as a visual child object to the chart.
 * It has no visual children, since level children are owned by the value chart and are owned by their parent elements. Root eleem
 *
 */
export class VisualValueChartLevel extends VisualObjectBase<undefined, VisualTableId> implements VisualBaseTable, SizeListener {
  // TODO fix problem with self reference in serializable @serializable(list(object(VisualValueChartElement)))
  @observable protected _children: any[] = [];
  get children(): any[] {
    return this._children;
  }
  @serializable(identifier(handleAfterDeserializeIdentifier)) private identifier: string;
  @serializable(list(custom(serializer, deserializer))) @observable _elements: VisualValueChartElement[];
  // TODO fix problem with self reference in serializable @serializable(reference(VisualValueChartLevel))
  @observable public parentLevel: VisualValueChartLevel;
  // TODO fix problem with self reference in serializable @serializable(reference(VisualValueChartLevel))
  @observable public childLevel: VisualValueChartLevel;

  /** number of columns this level should have */
  @serializable @observable public columns: number;
  /** title text should be limited to this number, -1: no limit */
  @serializable @observable public titleWidth: number;
  /** content area offset */
  @serializable @observable public contentDx: number;
  @serializable @observable public contentDy: number;
  /** header information shared by other visuals: title, bounds, default style */
  @serializable(object(VisualHeader)) @observable public header: VisualHeader;
  @serializable(map(object(VisualAttributeDefinition))) @observable public visualAttributeDefinitions: Map<string, VisualAttributeDefinition> = new Map();

  /* NOT SERIALIZABLE */
  @observable public id: VisualTableId;
  /** chart the level belongs to */
  @observable public visualValueChartMetadata: VisualValueChartMetadata;
  @observable isFilterVisible: boolean;
  @observable isAttributeVisible: boolean;
  @observable attributeHeaderRotation: RotationDegree = 0;
  @observable attributeHeaderHeight: number = DiagramVisualConstants.TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT;

  /** not observable, since effects are exposed by manipulating observable header coordinates */
  public attributeColumnLayout: ContainerColumnLayout;

  /**
   * temporary state to avoid endless loop
   */
  private inResize: boolean = false;

  constructor(
      visualValueChartMetadata: VisualValueChartMetadata,
      id: VisualTableId,
      title: string,
      columns: number,
      parentLevel: VisualValueChartLevel = undefined,
      isFilterVisible: boolean,
      isAttributeVisible: boolean = true) {

    super(visualValueChartMetadata as any, id);

    this.identifier = id ? id.toKey() : undefined;
    this.columns = columns;
    this.parentLevel = parentLevel;
    if (parentLevel) {
      parentLevel.childLevel = this;
    }
    this.visualValueChartMetadata = visualValueChartMetadata;
    this.isFilterVisible = isFilterVisible;
    this.isAttributeVisible = isAttributeVisible;
    this.contentDx = DiagramVisualConstants.VC_LEVEL_INSET;
    this.contentDy = 0;
    this.titleWidth = -1;
    this._elements = [];
    this.visualAttributeDefinitions = new Map();

    this.header = new VisualHeader(title, DiagramVisualConstants.VC_LEVEL_INSET * this.index, DiagramVisualConstants.VC_LEVEL_HEADER_HEIGHT * this.index, DiagramVisualConstants.DEFAULT_TABLE_WIDTH, DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT);
    this.attributeColumnLayout = new ContainerColumnLayout(this.header, Array.from(this.visualAttributeDefinitions.values()).map(a => a.header));
    this.attributeColumnLayout.addListener("LEVEL " + this.header.name, this);
  }

  get allAttributeNames(): string[] {
    return Array.from(this.visualAttributeDefinitions.keys()).sort();
  }

  get tableId(): TableId {
    return this.id.tableId;
  }

  /** zero-based level index, zero is topmost level */
  public get index(): number {
    return this.parentLevel ? this.parentLevel.index + 1 : 0;
  }

  /**
   * @return height this levels header eats up not including nested headers and not including the rounded border margin
   */
  @computed get levelHeaderHeightNotIncludingNested(): number {
    let height = DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH * 2 + DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT
    if (this.isAttributeVisible) {
      height += this.attributeHeaderHeight + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH;
    }
    if (this.isFilterVisible) {
      height += DiagramVisualConstants.TABLE_HEADER_FILTER_HEADER_HEIGHT + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH;
    }
    return height;
  }

  public get elements(): VisualValueChartElement[] {
    return this._elements;
  }

  public removeAllChildren(): void {
    this._elements = [];
  }

  public addVisualAttributeDefinition(visualAttributeDefinition: VisualAttributeDefinition): void {
    this.visualAttributeDefinitions.set(visualAttributeDefinition.header.name, visualAttributeDefinition);
    this.attributeColumnLayout.addColumn(visualAttributeDefinition.header);
  }

  /**
   *  @param {VisualAttributeId} visualAttributeId
   * @returns {boolean} true if VisualAttributeId was successfully removed
   */
  public removeVisualAttributeDefinition(visualAttributeId: VisualAttributeId): boolean {
    const visualAttributeDefinition = this.visualAttributeDefinitions.get(visualAttributeId.attributeName);
    this.visualAttributeDefinitions.delete(visualAttributeId.attributeName);
    this.attributeColumnLayout.removeColumn(visualAttributeId.attributeName);
    return visualAttributeDefinition !== undefined;
  }

  /**
   *
   * @param {string} attributeName name of attribute to retrieve
   * @returns {VisualAttributeDefinition} attribute definition for the given attribute name, undefined if none found
   */
  getAttribute(attributeName: string): VisualAttributeDefinition {
    return this.visualAttributeDefinitions.get(attributeName);
  }

  updateAttributeColumn(visualAttributeId: VisualAttributeId, dx: number, dy: number): void {
    const attribute = this.getAttribute(visualAttributeId.attributeName);
    if (attribute !== undefined) {
      this.attributeColumnLayout.updateColumn(attribute.header, dx);
    }
  }

  /**
   *  @param elementId
   *  @return first visual element for the given element or undefined if none
   */
  getFirstVisualElementByElementId(elementId: ElementId): VisualValueChartElement {
    const result = this.elements.filter(ve => ve.id.elementId === elementId);
    return result.length > 0 ? result[0] : undefined;
  }

  /**
   * resize value chart leaf level, all other levels will calculate their size automatically
   * @param {Direction} d
   * @param {number} dx width to resize
   * @param {VisualValueChartLevel} levelToIgnore if resizeTable was called by level size change, do not resize this again, just do leaf calculation
   */
  public resizeTable(d: Direction, dx: number, levelToIgnore: VisualValueChartLevel = undefined): void {
    // descend to leaf level and divide by column count
    let currentLevel: VisualValueChartLevel = this;
    let factor = 1;
    while (currentLevel.childLevel) {
      currentLevel = currentLevel.childLevel;
      factor = factor * currentLevel.columns;
    }

    // resize leaf level by dx / factor
    const header = currentLevel.header;
    if (currentLevel !== levelToIgnore) {
      currentLevel.attributeColumnLayout.resizeContainer(d, dx / factor);
    }
    // lay out table headers, this will resize all upper layers and their attributes to fit
    this.visualValueChartMetadata.layout();
    (this.parent as VisualValueChart).layout();
  }

  /**
   * @returns {number} number of levels defined in value chart
   */
  get levelCount(): number {
    return this.visualValueChartMetadata.levelCount;
  }

  get x(): number {
    return this.header.x;
  }

  get y(): number {
    return this.header.y;
  }

  get width(): number {
    return this.header.width;
  }

  public computeWidth(): number {
    let result: number;
    if (!this.childLevel) {
      result = this.header.width;
    } else {
      const cl = this.childLevel;
      // all children currently have same size
      const childWidth = this.childLevel.computeWidth();
      const g = DiagramVisualConstants.VC_GAP;
      // calculate content box size first
      const contentWidth = cl.columns * (childWidth + g) + g;
      result = this.contentDx + contentWidth + g;
    }
    return result;
  }

  public toggleFilterLine(): boolean {
    this.isFilterVisible = !this.isFilterVisible;
    return this.isFilterVisible;
  }

  public setHeaderRotation(rotation: RotationDegree, height: number): void {
    this.attributeHeaderRotation = rotation;
    this.attributeHeaderHeight = height;
  }

  onContainerSizeChanged(oldSize: number, newSize: number): void {
    this.resizeTable(Direction.EAST, newSize - oldSize, this);
  }
}
