import {list, map, object, primitive, serializable} from "serializr";
import {observable} from "mobx";
import {
  AttributeId,
  ElementId,
  TableId,
  VisualAttributeId,
  VisualTableId,
  VisualTableIdString
} from "../../core/utils/Core";
import {IFilter} from "../../core/utils/filter/Filter";
import {CombinedFilter} from "./CombinedFilter";
import {ElementFilter} from "./ElementFilter";

/**
 * a filtered hierarchy using T as attribute identifier, T can be string or AttributeId,
 * latter used if attributes are associated to a table.
 * string attributes will apply to all tables
 */
export abstract class FilteredHierarchy {
  /**
   * tables defining hierarchy
   */
  @serializable(list(object(VisualTableId))) @observable private _tables: VisualTableId[];

  /**
   * attributes shown in the hierarchy
   */
  @serializable(list(object(AttributeId))) @observable private _attributeIds: AttributeId[];


  /** maps tables to hidden elements of this level */
  @serializable(map(list(primitive()))) @observable private _tableIdToElementFilter: Map<VisualTableIdString, ElementId[]>;

  /**
   * true if the filter fields are visible in the header
   */
  @serializable @observable _isFilterFieldVisible: boolean;

  constructor(tableIds: VisualTableId[] = [], attributeIds: AttributeId[] = []) {
    this._tables = tableIds;
    this._attributeIds = attributeIds;
    this._tableIdToElementFilter = new Map();
    this._isFilterFieldVisible = false;
  }

  get tables(): VisualTableId[] {
    return this._tables;
  }

  get attributeIds(): AttributeId[] {
    return this._attributeIds;
  }

  get isFilterFieldVisible(): boolean {
    return this._isFilterFieldVisible;
  }

  set isFilterFieldVisible(value: boolean) {
    this._isFilterFieldVisible = value;
  }

  set attributeIds(attributeIds: AttributeId[]) {
    this._attributeIds = attributeIds;
  }

  get tableIdToElementFilter(): Map<VisualTableIdString, ElementId[]> {
    return this._tableIdToElementFilter;
  }

  //TODO: implement validation
  public isUniformFilterExpressionValid(attributeName: string): boolean {
    return true;
  }

  /**
   * remove the table with the given visual id from the hierarchy
   * @param vid visual id to remove
   */
  public removeTable(vid: VisualTableId): boolean {
    let result: boolean = false;
    const index = this._tables.findIndex(t => t.tableId === vid.tableId && t.visualId === vid.visualId);
    if (index !== -1) {
      this._tables.splice(index, 1);
      result = true;
    }
    if (this._tables.findIndex(vTId => vTId.tableId === vid.tableId) === -1) {
      this._tableIdToElementFilter.delete(vid.tableId);
      const attributesToRemove = this.attributeIds.filter(attId => attId.tableId === vid.tableId);
      for (let attId of attributesToRemove) {
        this.removeAttribute(attId);
      }
    }
    return result;
  }

  public removeAttribute(id: VisualAttributeId | AttributeId): boolean {
    let result: boolean = false;
    let idx = -1;
    if (id instanceof VisualAttributeId) {
      idx = this._attributeIds.findIndex(ca => ca.tableId === id.visualTableId.tableId && ca.attributeName === id.attributeName);
    } else {
      idx = this._attributeIds.findIndex(ca => ca.tableId === id.tableId && ca.attributeName === id.attributeName);
    }
    if (idx !== -1) {
      const attKey = this._attributeIds[idx].toKey();
      this._attributeIds.splice(idx, 1);
      result = true;
    }
    return result;
  }

  /**
   * returns either elementFilter or simpleFilter or combined filter, if both are defined
   * @param visualTableId
   */
  public getFilter(visualTableId: VisualTableId): IFilter {
    let result = undefined;
    const elementFilter = this.getElementFilter(visualTableId);
    const simpleFilter = this.getAttributeFilter(visualTableId)
    if (elementFilter && simpleFilter) {
      result = new CombinedFilter([elementFilter, simpleFilter]);
    } else if (simpleFilter) {
      result = simpleFilter;
    } else if (elementFilter) {
      result = elementFilter;
    }
    return result;
  }

  public getFilterForAllTables():IFilter[] {
    return this.tables.map(tableId => this.getFilter(tableId))
  }

  getElementsToFilter(visualTableId: VisualTableId): ElementId[] {
    let result = this.tableIdToElementFilter.get(visualTableId.toKey());
    if (!result) {
      result = observable([]);
      this.tableIdToElementFilter.set(visualTableId.toKey(), result);
    }
    return result;
  }

  addElementToFilter(visualTableId: VisualTableId, elementId: ElementId): void {
    if (!this.getElementsToFilter(visualTableId).find(eid => eid === elementId)) {
      this.getElementsToFilter(visualTableId).push(elementId);
    }
  }

  removeElementFromFilter(visualTableId: VisualTableId, elementId: string): void {
    const elementFilter = this.getElementsToFilter(visualTableId);
    const index = elementFilter.indexOf(elementId);
    if (index !== -1) {
      elementFilter.splice(index, 1);
    }
  }

  public getAttributeFilter(visualTableId: VisualTableId): IFilter {
    const allFilters = this.getFilterForTable(visualTableId.tableId);
    return allFilters.length === 0 ? undefined : allFilters.length === 1 ? allFilters[0] : new CombinedFilter(allFilters);
  }

  public getElementFilter(visualTableId: VisualTableId): IFilter {
    let result = undefined;
    const elementFilter = this.getElementsToFilter(visualTableId);
    if (elementFilter.length > 0) {
      result = new ElementFilter(elementFilter);
    }
    return result;
  }


  public abstract getFilterForTable(tableId:TableId):IFilter[];
}