// /* MetusTextField.tsx
//  * Copyright (C) METUS GmbH - All Rights Reserved
//  * Unauthorized copying of this file, via any medium is strictly prohibited
//  * Proprietary and confidential
//  * Written by georg.bogner, Dezember 2018
//  */
import * as React from "react";
import Log from "../utils/Logger";
import {Classifier} from "../utils/ClassifierLogger";
import {createStyles, TextField, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import {StandardTextFieldProps} from "@material-ui/core/TextField";

const log = Log.logger("common");
const renderLog = Log.logger("common", Classifier.render);

const styles = (theme: Theme): StyleRules => createStyles({
  inputError: {
    // fontSize: "21px",
    fontWeight: 400,
    color: "#de323c",
    padding: 0
  },
});

type StyledLocalProps = StandardTextFieldProps & WithStyles<typeof styles>;

class MetusTextField extends React.Component<StyledLocalProps> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering MetusTextField", this.props);
    const {error, ...textFieldProps} = this.props;
    const input = error ? this.props.classes.inputError : this.props.classes.input;

    return <TextField {...textFieldProps} error={error} InputProps={{classes: {input}, ...textFieldProps.InputProps}}/>;
  }
}

export default withStyles(styles)(MetusTextField);
