/* LinkedList.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2021
 */
import * as _ from "lodash";
import {observable} from "mobx";


export class Node<T> {
  @observable public value: T;
  @observable public next?: Node<T>;

  constructor(value: T, next?: Node<T>) {
    this.value = value;
    this.next = next;
  }
}

export type IEqualsFunction<T> = (a: T, b: T) => boolean;

export default class LinkedList<T> {
  @observable private head: Node<T>;

  constructor(value: T | T[] = undefined, private equalsFn: IEqualsFunction<T> = _.isEqual) {
    if (!(value === undefined || value === null)) {
      if (Array.isArray(value)) {
        this.insertValuesAtEndOfList(value);
      } else {
        this.insertHead(value);
      }
    }
  }

  public getHead(): Node<T> {
    return this.head;
  }

  public getAt(index: number): Node<T> {
    let counter = 0;
    let node = this.head;
    while (node) {
      if (counter === index) {
        return node;
      }

      counter++;
      node = node.next;
    }
    return undefined;
  }

  public getTail(): Node<T> {
    if (!this.head) {
      return null;
    }

    let node = this.head;
    while (node) {
      if (!node.next) {
        return node;
      }
      node = node.next;
    }
  }

  public getNextValue(value: T): T {
    let retVal: T = undefined;
    const index = this.indexOf(value);
    if (index > -1) {
      retVal = this.getAt(index).next?.value;
    }
    return retVal;
  }

  public getPreviousValue(value: T): T {
    let retVal: T = undefined;
    const index = this.indexOf(value);
    if (index > 0) {
      retVal = this.getAt(index - 1)?.value;
    }
    return retVal;
  }

  public insertHead(value: T): void {
    this.head = new Node(value, this.head);
  }

  public insertAt(value: T, index: number): void {
    if (!this.head) {
      this.head = new Node(value);
      return;
    }

    if (index === 0) {
      this.insertHead(value);
      return;
    }

    const previous = this.getAt(index - 1) || this.getTail();
    const node = new Node(value, previous.next);
    previous.next = node;
  }

  public insertTail(value: T): void {
    const last = this.getTail();

    if (last) {
      // There are some existing nodes in our chain
      last.next = new Node(value);
    } else {
      // The chain is empty!
      this.head = new Node(value);
    }
  }

  public insertValuesAtEndOfList(values: T[]): void {
    values.forEach(data => this.insertTail(data));
  }

  public removeHead(): void {
    if (!this.head) {
      return;
    }

    this.head = this.head.next;
  }

  public removeAt(index: number): void {
    if (!this.head) {
      return;
    }

    if (index === 0) {
      this.head = this.head.next;
      return;
    }

    const previous = this.getAt(index - 1);
    if (!previous || !previous.next) {
      return;
    }
    previous.next = previous.next.next;
  }

  public removeTail(): void {
    if (!this.head) {
      return;
    }

    if (!this.head.next) {
      this.head = null;
      return;
    }

    let current = this.head;
    let next = this.head.next;
    while (next.next) {
      current = next;
      next = next.next;
    }
    current.next = null;
  }

  public remove(value: T) {
    const index = this.indexOf(value);
    return this.removeAt(index);
  }

  indexOf(value: T): number {
    let current = this.head;

    for (let i = 0; i < this.size() && current != null; i++) {
      if (this.equalsFn(value, current.value)) {
        return i;
      }
      current = current.next;
    }

    return -1;
  }

  public size(): number {
    let counter = 0;
    let node = this.head;

    while (node) {
      counter++;
      node = node.next;
    }

    return counter;
  }

  public isEmpty(): boolean {
    return this.size() === 0;
  }

  public clear(): void {
    this.head = null;
  }

  public toArray(): T[] {
    return Array.from(this).map(node => node.value);
  }

  public filter(predicate: (value: T) => boolean): LinkedList<T> {
    const array = this.toArray();
    const filtered = array.filter(predicate);
    return new LinkedList(filtered);
  }

  public forEach(fn: (node: Node<T>, index?: number) => void): void {
    let node = this.head;
    let counter = 0;
    while (node) {
      fn(node, counter);
      node = node.next;
      counter++;
    }
  }

  * [Symbol.iterator]() {  // a shorthand for [Symbol.iterator]: function*()
    let node = this.head;
    while (node) {
      yield node;
      node = node.next;
    }
  }

}

