import * as React from "react";

import {Classifier} from "../../../common/utils/ClassifierLogger";
import Log from "../../../common/utils/Logger";
import {BaseSectionProperties} from "../../../properties/model/PropertiesModel";
import {createStyles, FormControl, Select, TextField, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import PropertiesSectionLayout from "../../../properties/components/PropertiesSectionLayout";
import PropertiesButton from "../../../properties/components/PropertiesButton";
import autobind from "autobind-decorator";
import {modelStore} from "../../../core/stores/ModelStore";
import {action} from "mobx";
import {generateUUID} from "../../../common/utils/IdGenerator";
import {observer} from "mobx-react";

const renderLog = Log.logger("properties", Classifier.render);
const log = Log.logger("properties");

const styles = (theme: Theme): StyleRules => createStyles({});

export interface TestDataCreationProperties extends BaseSectionProperties {
  tables: [string, string][];
}

type StyledLocalProps = TestDataCreationProperties & WithStyles<typeof styles>;

interface LocalState {
  table1: string;
  table2: string;
  count1: number;
  count2: number;
  countConnections: number;
}

@observer
class TestDataCreationComponent extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    const table = this.props.tables.length > 0 ? this.props.tables[0][0] : null;
    this.state = {
      table1: table,
      table2: table,
      count1: 50,
      count2: 50,
      countConnections: 50,
    };
  }


  render(): JSX.Element {
    renderLog.debug("Rendering TestDataCreationComponent");
    const tables = [modelStore.tableNameByTableId];

    return <PropertiesSectionLayout>
      <FormControl className={this.props.classes.formControl}>
        <Select
            native
            value={this.state.table1}
            onChange={this.setValue.bind(this, "table1")}
            inputProps={{
              name: "table1",
              id: "table1-select",
            }}>
          { this.props.tables.map( (keyValue) => <option value={keyValue[0]}>{keyValue[1]}</option>) }
        </Select>
      </FormControl>

      <TextField value={this.state.count1} onChange={this.setValue.bind(this, "count1")}/>

      <PropertiesButton onClick={this.createElements.bind(this, "table1")}>Create Elements</PropertiesButton>

      <FormControl className={this.props.classes.formControl}>
        <Select
            native
            value={this.state.table2}
            onChange={this.setValue.bind(this, "table2")}
            inputProps={{
              name: "table2",
              id: "table2select",
            }}>
          { this.props.tables.map( (keyValue) => <option value={keyValue[0]}>{keyValue[1]}</option>) }
        </Select>
      </FormControl>

      <TextField value={this.state.count2} onChange={this.setValue.bind(this, "count2")}/>

      <PropertiesButton onClick={this.createElements.bind(this, "table2")}>Create Elements</PropertiesButton>


      <TextField value={this.state.countConnections} onChange={this.setValue.bind(this, "countConnections")}/>
      <PropertiesButton onClick={this.createConnections}>Create Connections</PropertiesButton>

    </PropertiesSectionLayout>;
  }

  private setValue(key:keyof LocalState, o:any):void {
    const newState = { [key]: o.target.value};
    console.log("SetValue", o, newState);
    this.setState(newState as any);
  }

  @action
  private createElements(tableNo:"table1" | "table2"):void {
    const tableId = tableNo === "table1" ? this.state.table1 : this.state.table2;
    const count = tableNo === "table1" ? this.state.count1 : this.state.count2;
    for (let i=0; i< count; i++) {
      const elementId = generateUUID();
      const name = elementId;
      modelStore.newElement(tableId, elementId, name);
    }
  }

  @autobind
  @action
  private createConnections():void {

    const e1s = modelStore.getElementsForTable(this.state.table1).map(e=>e.id);
    const e2s = modelStore.getElementsForTable(this.state.table2).map(e=>e.id);
    const cs = [];
    console.log(`Creating ${this.state.countConnections} connections rows x columns = ${e1s.length} x ${e2s.length}`);
    const start = performance.now();
    for (let i=0; i< this.state.countConnections; i++) {
      cs.push([e1s[Math.floor(Math.random() * e1s.length)], e2s[Math.floor(Math.random() * e2s.length)]]);
    }
    cs.forEach( c => modelStore.toggleConnections([c[0]], c[1]) );
    console.log(` ... done in ${performance.now() - start} ms`);
  }

}

export default withStyles(styles)(TestDataCreationComponent);
