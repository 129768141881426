import * as React from "react";
import {Identifier} from "dnd-core";
import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
  DropTargetSpec
} from "react-dnd";
import DragTypes from "../../common/constants/DragTypes";
import {getEmptyImage} from "react-dnd-html5-backend";
import {AttributeId, TableId} from "../../core/utils/Core";
import Log from "../../common/utils/Logger";
import {CockpitIconType, Cursor, Target, TreeItemType, ViewType} from "../../common/constants/Enums";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {
  moveTreeItemToFolder,
  moveTreeItemToNewPosition,
  openCockpit,
  openViewAndLoadIfNecessary,
  saveView
} from "../actions/ViewManagerAsyncActionCreators";
import {DndTargetFeedbackAction} from "../../common/actions/InteractionStateActions";
import {Validate} from "../../common/utils/Validate";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {MosaicPath} from "react-mosaic-component";
import {CockpitActionPayload, metusStore} from "../stores/MetusStore";
import {createStyles, ListItem, ListItemIcon, ListItemText, Theme, Typography} from "@material-ui/core";
import {getCockpitIcon} from "./NewCockpitDialog";
import {CloseCockpitAction} from "../actions/ViewManagerActions";
import autobind from "autobind-decorator";
import {optionalHandler} from "../../common/utils/FunctionUtil";
import {modelStore} from "../../core/stores/ModelStore";
import {StyleRules, withStyles, WithStyles, WithTheme} from "@material-ui/styles";
import * as _ from "lodash";
import {showSaveCockpitDialog} from "./SaveCockpitDialog";
import reactCSS from "reactcss";
import {addTableToDiagram} from "../../commonviews/actions/SharedViewAsyncActions";
import {ViewInfo} from "../../commonviews/models/ViewInfo";
import {addTableToMatrix} from "../../matrix/actions/MatrixAsyncActionCreators";
import {
  calcMovingDirection,
  findPredecessorId,
  findSubtreeWhereItemWithIdLivesIn,
  findSuccessorId
} from "../../core/models/ListItemHierarchy";
import {MoveTreeItemToNewPositionPayload} from "../../core/actions/NavigationPayloads";

const log = Log.logger("workbench");
const dndLog = Log.logger("workbench", Classifier.dnd);
const renderLog = Log.logger("workbench", Classifier.render);

const styles = (theme: Theme): StyleRules => createStyles({
  cockpitItemText: {
    marginLeft: 15
  },
  viewItemText: {
    marginLeft: 66
  },
  folderItemText: {
    fontStyle: "normal",
    fontWeight: 400,
    marginLeft: 66
  },
  attributeItemText: {
    fontStyle: "normal",
    fontWeight: 400,
    marginLeft: 66
  },
  icon: {
    position: "absolute", marginLeft: 34,
  },
  expandCollapseIcon: {
    position: "absolute",
    marginLeft: 8,
  },
  listItemHoverBackground: {
    "&:hover":{
      backgroundColor:  theme.metus.navigation.fill
    }
  },
});


export interface NodeProps {
  id: any;
  name: string;
  type: TreeItemType;
  level: number;
  viewType?: ViewType;
  svgIconColor?: string;
  svgIconType?: CockpitIconType;
  icon?: JSX.Element;
  isHighLighted?: boolean;
  isActive?: boolean;
  disabled: boolean;
}

export interface TreeListItemProps extends NodeProps {
  dragIds: (string | AttributeId)[];
  primaryElement: string | JSX.Element; // if null, primaryText is used. Otherwise can be any html element which wraps the text
  leftAvatar: any;
  expandCollapseIcon: any;
  tableIndex?: number;
  onExpandCollapseIconClick: any;
  onTouchTap: () => void;
  onClick?: any;
  onDoubleClick?: any;
}

interface DnDProps {
  connectDragSource?: ConnectDragSource;
  connectDragPreview?: ConnectDragPreview;
  connectDropTarget?: ConnectDropTarget;
  markBorderTop?: boolean;
  markBorderBottom: boolean;
}

type LocalProps = TreeListItemProps & DnDProps & WithTheme<Theme> & WithStyles<typeof styles>;

class TreeListItemComponentNoDnd extends React.Component<LocalProps, any> {
  private get leftIcon(): JSX.Element {
    let retVal: JSX.Element;
    if (!(this.props.svgIconType === undefined || this.props.svgIconColor === undefined)) {
      const cockpitIcon: JSX.Element = getCockpitIcon(this.props.svgIconType);
      retVal = <cockpitIcon.type {...cockpitIcon.props}
                                 style={{fontSize: 60, backgroundColor: `${this.props.svgIconColor}`}}/>;
    } else {
      retVal = this.props.icon;
    }
    return retVal;
  }

  componentDidMount(): void {
    let isImageDefined = true;
    try {
      // check if image is available
      const i = Image;
    } catch (e) {
      if (e.name === "ReferenceError") {
        // getting here in dnd unit tests only
        isImageDefined = false;
      }
    }
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    // note: this component can be used without dnd, so connectDragPreview may be undefined
    if (isImageDefined && this.props.connectDragPreview !== undefined) {
      this.props.connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true
      });
    }
  }

  @autobind
  private handleListItemClick(e: any): void {
    if (this.props.onExpandCollapseIconClick) {
      this.props.onExpandCollapseIconClick();
      e.stopPropagation();
    }
  }

  render(): JSX.Element {
    renderLog.debug("Rendering TreeListItemComponent");
    const dataTestselector = TreeItemType[this.props.type] + "/" + this.props.name + "/";
    const {classes, expandCollapseIcon, markBorderTop, markBorderBottom} = this.props;
    const navTheme = this.props.theme.metus.navigation;
    const label: string | JSX.Element = this.props.primaryElement === null ? this.props.name : this.props.primaryElement;
    const separator = "2px solid rgb(255, 153, 0)"

    // this style object is needed for styles which depend on properties
    const styles = reactCSS({
      "default": {
        box: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          backgroundColor: this.props.disabled ? navTheme.disabled.fill : this.props.isHighLighted ? navTheme.active.secondaryFill : undefined,
          cursor: this.props.disabled ? navTheme.disabled.cursor : "inherit"
        },

        active: {
          borderLeft: this.props.isActive ? "3px solid " + navTheme.active.highlight : undefined,
        },
        listItem: {
          padding: this.props.viewType === ViewType.Cockpit ? "0px 24px 0px 0px" : "2px 24px 2px 0px",
          marginLeft: navTheme.levelIndent * Math.max(0, this.props.level - 1),
        },
        listItemIcon: this.props.viewType !== ViewType.Cockpit ? {root: classes.icon} : undefined,
        listItemText: this.getListItemTextStyle(classes),
      }
    });

    let additionalAttributes = {};
    if (this.props.disabled) {
      additionalAttributes = {"data-tip": "This view was edited with a newer incompatible version of Metus, so you are not allowed to open it."};
    }

    let result = (
        /* list item wrapper */
        <div onClick={optionalHandler(this.props.onClick)}
             onDoubleClick={optionalHandler(this.props.onDoubleClick)}
             style={styles.box as any} {...additionalAttributes}>
          {/* is active bar */}
          <div style={styles.active}/>
          {/* list item */}
          <ListItem data-testselector={dataTestselector} style={styles.listItem} divider={true} classes={{root: classes.listItemHoverBackground}}>
            {/* expand collapse icon */}
            {expandCollapseIcon &&
                <ListItemIcon onClick={this.handleListItemClick} classes={{root: classes.expandCollapseIcon}}>
                  {expandCollapseIcon}
                </ListItemIcon>}
            <React.Fragment>
              {/* cockpit, view, table or folder icon */}
              <ListItemIcon classes={styles.listItemIcon}>{this.leftIcon}</ListItemIcon>
              {/* cockpit, view, table or folder label */}
              <Typography variant="body1" classes={styles.listItemText} component="div">
                <ListItemText primary={label} disableTypography={true}/>
              </Typography>
            </React.Fragment>
          </ListItem>
        </div>
    );

    if (this.props.connectDropTarget) {
      result = this.props.connectDropTarget(result);
    }

    if (this.props.connectDragSource) {
      result = this.props.connectDragSource(result);
    }

    return result;
  }

  private getListItemTextStyle(classes: any) {
    let retVal = {root: undefined};
    switch (this.props.type) {
      case TreeItemType.Attribute:
        retVal.root = classes.attributeItemText;
        break;
      case TreeItemType.Folder:
        retVal.root = classes.folderItemText;
        break;
      case TreeItemType.Table:
        retVal.root = classes.viewItemText;
        break;
      case TreeItemType.View:
        if (this.props.viewType === ViewType.Cockpit) {
          retVal.root = classes.cockpitItemText;
        } else {
          retVal.root = classes.viewItemText;
        }
        break;
    }
    return retVal;
  }

}

/**
 * Implements the drag source contract.
 */

interface DropResult {
  viewInfo?: ViewInfo;
  moveTarget?: { id: string; viewType?: ViewType; itemType: TreeItemType; level: number };
  targetPosition?: { x: number, y: number };
  tableIndex?: number;
  isColumn?: boolean
}

const dragTreeListItemSourceSpec: DragSourceSpec<any, TreeListItemProps> = {
  beginDrag(props: TreeListItemProps): TreeListItemProps {
    dndLog.debug("List item begin drag");
    return {tableIndex: -1, ...props};
  },

  endDrag(sourceItemProps: TreeListItemProps, monitor: DragSourceMonitor, component: TreeListItemComponentNoDnd): void {
    dndLog.debug("List item end Drag");

    if (monitor.didDrop()) {

      if (Object.entries(monitor.getDropResult()).length !== 0) {
        const dropResult: DropResult = monitor.getDropResult();

        if (dropResult.moveTarget?.id !== undefined) {
          dndLog.debug("Moving item", dropResult.moveTarget.id, sourceItemProps.id);

          if (dropResult.moveTarget.itemType === TreeItemType.View) {
            /* Possible cases:
                1. Cockpit is dropped on a cockpit (always first level)
                2. View is dropped on a view (same or different level)
                3. Folder is dropped on a view (same or different level)
            */
            const payload: MoveTreeItemToNewPositionPayload = {
              sourceId: sourceItemProps.id,
              sourceParentId: findSubtreeWhereItemWithIdLivesIn(metusStore.cockpitAndViewHierarchy, sourceItemProps.id)?.id,
              previousOfSourceId: findPredecessorId(metusStore.cockpitAndViewHierarchy, sourceItemProps.id),
              nextOfSourceId: findSuccessorId(metusStore.cockpitAndViewHierarchy, sourceItemProps.id),
              targetId: dropResult.moveTarget.id,
              targetParentId: findSubtreeWhereItemWithIdLivesIn(metusStore.cockpitAndViewHierarchy, dropResult.moveTarget.id)?.id,
              previousOfTargetId: findPredecessorId(metusStore.cockpitAndViewHierarchy, dropResult.moveTarget.id),
              nextOfTargetId: findSuccessorId(metusStore.cockpitAndViewHierarchy, dropResult.moveTarget.id),
              isCockpit: dropResult.moveTarget.viewType === ViewType.Cockpit,
              headToTail: calcMovingDirection(metusStore.cockpitAndViewHierarchy, sourceItemProps.id, dropResult.moveTarget.id)
            };
            moveTreeItemToNewPosition(payload);
          } else {
            /* Possible cases:
                1. View is dropped on a folder (same or different level)
                2. Folder is dropped on a folder (same or different level)
                3. Table is dropped on a folder (same or different level)
                4. Table is dropped on a table in root
            */
            moveTreeItemToFolder(sourceItemProps.id, dropResult.moveTarget.id);
          }

        } else {
          const item: TreeListItemProps = sourceItemProps;
          const viewInfo = dropResult.viewInfo;
          for (let i = 0; i < item.dragIds.length; i++) {
            switch (item.type) {
              case TreeItemType.Table:
                if (!viewInfo) {
                  log.debug("Table dropped as view, creating action");
                  const dropResultView: { windowPath: MosaicPath, windowIndex: number } = monitor.getDropResult() as { windowPath: MosaicPath, windowIndex: number };
                  saveCurrentlyOpenView(dropResultView.windowIndex);
                  openViewAndLoadIfNecessary(item.id, item.viewType, dropResultView.windowPath);
                } else {
                  log.debug(`Table dropped into ${viewInfo.type} ${viewInfo.name}, creating action`);
                  const tableId = item.dragIds[i] as TableId;
                  const tableName = item.name;
                  switch (viewInfo.type) {
                    case ViewType.ChainMatrix:
                    case ViewType.Matrix:
                    case ViewType.StructuredTable:
                    case ViewType.Table:
                      addTableToMatrix(viewInfo.id, tableId, tableName, dropResult.tableIndex, dropResult.isColumn ? Target.COLUMN : Target.ROW);
                      break;
                    default:
                      addTableToDiagram(viewInfo.id, tableId, dropResult.targetPosition);
                  }
                }
                break;

              case TreeItemType.View:
                if (sourceItemProps.viewType === ViewType.Cockpit) {
                  if (metusStore.currentCockpit && metusStore.currentCockpit.isDirty) {
                    const payload: CockpitActionPayload = {
                      id: metusStore.currentCockpit.id,
                      name: metusStore.currentCockpit.name,
                      cockpitToOpen: {
                        id: item.id,
                        iconColor: item.svgIconColor,
                        iconType: item.svgIconType
                      },
                    };
                    showSaveCockpitDialog(true, new CloseCockpitAction(payload));
                  } else {
                    Dispatcher.dispatch(new CloseCockpitAction({}));
                    openCockpit(item.id, item.svgIconColor, item.svgIconType);
                  }
                  break;
                } else {
                  log.debug(`View dropped, creating action`, item.id);
                  const dropResultView: { windowPath: MosaicPath, windowIndex: number } = monitor.getDropResult() as { windowPath: MosaicPath, windowIndex: number };
                  saveCurrentlyOpenView(dropResultView.windowIndex);
                  openViewAndLoadIfNecessary(item.id, item.viewType, dropResultView.windowPath);
                  break;
                }
            }
          }
        }
      }
    }
  }
};

function collectDragSource(connect: DragSourceConnector, monitor: DragSourceMonitor): Object {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}

function collectDropTarget(connect: DropTargetConnector, monitor: DropTargetMonitor, targetProps: TreeListItemProps): Object {
  const isView = targetProps.type === TreeItemType.View;
  const topDown = ((monitor?.getClientOffset()?.y - monitor?.getInitialClientOffset()?.y) > 0) && monitor?.getItem()?.level === targetProps.level;

  return {
    connectDropTarget: connect.dropTarget(),
    markBorderTop: isView && monitor.isOver() && monitor.canDrop() && !topDown,
    markBorderBottom: isView && monitor.isOver() && monitor.canDrop() && topDown,
  };
}

const dropTreeListItemTargetSpec: DropTargetSpec<TreeListItemProps> = {
  canDrop(targetItemProps: TreeListItemProps, monitor: DropTargetMonitor): boolean {
    const sourceItemProps: TreeListItemProps = monitor.getItem() as TreeListItemProps;
    const result = calculateCanDrop(sourceItemProps, targetItemProps);
    dndLog.debug(`${sourceItemProps.id} can ${result ? "" : "not "}be dropped onto ${targetItemProps.id}`);
    return result;
  },

  hover(targetItemProps: TreeListItemProps, monitor: DropTargetMonitor, component: React.Component<TreeListItemProps, any>): void {
    const sourceItemProps: TreeListItemProps = monitor.getItem() as TreeListItemProps;
    const canBeDropped = calculateCanDrop(sourceItemProps, targetItemProps);
    dndLog.debug("Hovering: source, target, canDrop", sourceItemProps.id, targetItemProps.id, canBeDropped);
    if (canBeDropped) {
      const message = getMessageForTargetFeedback(sourceItemProps, targetItemProps);
      const cursor = (sourceItemProps.type === TreeItemType.View && targetItemProps.type === TreeItemType.View) ?
          Cursor.ROW_RESIZE : Cursor.MOVE;
      Dispatcher.dispatch(new DndTargetFeedbackAction(cursor, message));
    } else {
      Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.NO_DROP, "Item can not be dropped here."));
    }
  },

  drop(targetItemProps: TreeListItemProps, monitor: DropTargetMonitor, component: React.Component<TreeListItemProps, any>): Object {
    let retVal: DropResult = {
      moveTarget: {
        id: targetItemProps.type === TreeItemType.Table ? modelStore.tableHierarchy.id : targetItemProps.id,
        itemType: targetItemProps.type,
        level: targetItemProps.level,
        viewType: targetItemProps.type === TreeItemType.Folder ? undefined : targetItemProps.viewType,
      }
    };
    dndLog.info("TreeListItem.drop move target", retVal);
    return retVal;
  }
};

function calculateCanDrop(sourceProps: TreeListItemProps, targetProps: TreeListItemProps): boolean {
  let result = false;

  if (sourceProps.id !== targetProps.id) {
    const conditionMoveToFolder = targetProps.type === TreeItemType.Folder;
    const conditionMoveToNewPosition = targetProps.type === TreeItemType.View;
    // Allow dropping a table on an other table only from a subtree to root.
    const conditionMoveTableToRootFolder = (targetProps.level === 1 && sourceProps.level > 1) && targetProps.type === TreeItemType.Table;

    result = conditionMoveToFolder || conditionMoveTableToRootFolder || conditionMoveToNewPosition;
  }

  return result;
}

function getMessageForTargetFeedback(sourceProps: TreeListItemProps, targetProps: TreeListItemProps): string {
  let retVal: string = null;
  const sourceName = sourceProps.name;

  if (targetProps.type === TreeItemType.Folder) {
    const targetName = targetProps.name;
    retVal = `Move '${sourceName}' to folder '${targetName}'`;
  } else if (targetProps.level === 1 && targetProps.type === TreeItemType.Table) {
    retVal = `Move '${sourceName}' to root folder`;
  } else if (targetProps.type === TreeItemType.View) {
    retVal = sourceProps.name;
  }

  return retVal;
}

function saveCurrentlyOpenView(windowIndex: number): void {
  const viewInfo = metusStore.getViewInfoByWindowIndex(windowIndex);
  if (viewInfo) {
    saveView(viewInfo.type, viewInfo.id, viewInfo.name, viewInfo.viewVersion, false);
  }
}

// maps tree list item to proper drag type
function typeMapper(props: TreeListItemProps): Identifier {
  switch (props.type) {
    case TreeItemType.View:
      return DragTypes.VIEW;
    case TreeItemType.Table:
      return DragTypes.CORE_TABLE;
    case TreeItemType.Attribute:
      return DragTypes.CORE_ATTRIBUTE_DEFINITION;
    case TreeItemType.Folder:
      return DragTypes.CORE_FOLDER;
  }
  Validate.fail("No DragType defined for listItemType " + props.type);
}

export const StyledTreeListItemComponent = withStyles(styles, {withTheme: true})(TreeListItemComponentNoDnd);
export const
    TreeListItemComponent = _.flow(DragSource<TreeListItemProps>(typeMapper, dragTreeListItemSourceSpec, collectDragSource),
        DropTarget<TreeListItemProps>([DragTypes.CORE_TABLE, DragTypes.VIEW, DragTypes.CORE_FOLDER], dropTreeListItemTargetSpec, collectDropTarget))(StyledTreeListItemComponent);
