import Action from "../actions/BaseAction";
import {Dispatcher} from "../utils/Dispatcher";

type StoreListener = (reason: any, ...args: any[]) => void;

abstract class StoreBase {
  private _listeners: Set<StoreListener> = new Set();
  private _dispatchToken: string;
  public readonly isUndoRelevant: boolean;

  protected constructor(isUndoRelevant: boolean = true) {
    this._dispatchToken = Dispatcher.registerStore(this);
    this.isUndoRelevant = isUndoRelevant;
  }

  abstract accept(action: Action): void;

  public register(listener: StoreListener): void {
    this._listeners.add(listener);
  }

  public deregister(listener: StoreListener): void {
    this._listeners.delete(listener);
  }

  public deregisterAll(): void {
    this._listeners.clear();
  }

  // reason: any debugging help to get an idea what was the change reason
  // der eine Aussage über die Art der Änderung macht
  protected notify(reason: string, ...args: any[]): void {
    this._listeners.forEach(listener => listener(reason, ...args));
  }

  /** reset store to initial state
   * if keepCurrentModel is passed and true, the current model will stay */
  abstract reset(keepCurrentModel?: boolean): void;

  public get dispatchToken(): string {
    return this._dispatchToken;
  }

  public set dispatchToken(_token: string) {
    this._dispatchToken = _token;
  }

}

export default StoreBase;