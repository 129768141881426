import * as React from "react";
import "../../common/css/metusapp.css";
import {ViewId} from "../../core/utils/Core";
import {getLeaves, MosaicNode, MosaicPath, MosaicWindow, MosaicWithoutDragDropContext} from "react-mosaic-component";
import {ViewContainer} from "./ViewContainer";
import * as _ from "lodash";
import "react-mosaic-component/react-mosaic-component.css";
import {saveView} from "../actions/ViewManagerAsyncActionCreators";
import {ActivateViewAction, UpdateWindowPathAction} from "../actions/ViewManagerActions";
import {EMPTY_PATH, metusStore} from "../stores/MetusStore";
import {Dispatcher} from "../../common/utils/Dispatcher";
import autobind from "autobind-decorator";
import {observer} from "mobx-react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import ViewActivationComponent from "./ViewActivationComponent";
import {ViewToolbar} from "./ViewToolbar";
import {ViewInfo} from "../../commonviews/models/ViewInfo";
import {viewManagerRegistry} from "../../commonviews/models/ViewManager";
import {configurationStore} from "../../core/stores/ConfigurationStore";
import {MosaicWindowDragStartAction} from "../../common/actions/InteractionStateActions";

const renderLog = Log.logger("workbench", Classifier.render);
const log = Log.logger("MetusWorkbenchContainer");

const EMPTY_ARRAY: any[] = [];

interface MetusWorkbenchProps {
  windowArrangement: MosaicNode<number>;
  getViewInfo: (windowIndex: number) => ViewInfo;
  onChange: (currentNode: MosaicNode<number>) => void;
  createNode: () => number;
  isFullscreen: boolean;
}

interface WindowProps {
  viewInfo: ViewInfo;
  windowIndex: number;
  numberOfOpenWindows: number;
  createNode: () => number;
  isFullScreen: boolean;
}


@observer
class MetusWorkbenchContainer extends React.Component<MetusWorkbenchProps> {
  constructor(props: MetusWorkbenchProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering MetusWorkbenchContainer ");
    return <div className="react-mosaic-metus-app" data-testselector="workbenchContainer">
      <MosaicWithoutDragDropContext<number>
          renderTile={this.renderTile}
          value={this.props.windowArrangement}
          onChange={this.props.onChange}
          resize={{minimumPaneSizePercentage: 5}}
          className="no-theme"
      />
    </div>;
  }

  @autobind
  private renderTile(windowIndex: number, windowPath: MosaicPath): JSX.Element {
    log.debug("Mosaic called back renderTile", windowIndex, windowPath);

    this.sendUpdateWindowPathActionIfPathHasChangedInMosaic(windowIndex, windowPath);

    const viewInfo = this.props.getViewInfo(windowIndex);
    return <WorkbenchWindowComponent viewInfo={viewInfo}
                                     windowIndex={windowIndex}
                                     numberOfOpenWindows={getLeaves(this.props.windowArrangement).length}
                                     createNode={this.props.createNode}
                                     isFullScreen={this.props.isFullscreen}/>;
  }

  private sendUpdateWindowPathActionIfPathHasChangedInMosaic(windowIndex: number, windowPath: MosaicPath) {
    const existingPath = metusStore.getEditorStateByWindowIndex(windowIndex).windowPath;
    const existing = JSON.stringify(existingPath);
    const current = JSON.stringify(windowPath);
    log.debug("Comparing known path and mosaic path for window index", windowIndex, existing, current);
    if (existing !== current) {
      const payload = {windowIndex, windowPath};
      Dispatcher.dispatch(new UpdateWindowPathAction(payload));
    }
  }

  @autobind
  private beforeUnload(e: BeforeUnloadEvent): string {
    const openViews = viewManagerRegistry.viewManager.getOpenViewInfos();

    // since we do not (yet) have a dirty state, show message if any view is open and we are in writer mode
    if (openViews.length > 0 && configurationStore.isMessageDisplayedBeforeUnload()) {
      // vM20200727 beforeunload for security reasons works very restricted,
      // the return value only influences THAT a popup is shown, it is not possible to change text or anything else ;-(
      // see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      // and https://stackoverflow.com/questions/24081699/why-onbeforeunload-event-is-not-firing/24081747
      //
      // So the best we can do here is try to satisfy all browsers
      // the message is ignored, just returning a value will make the default popup appear in most browsers
      e.preventDefault();
      const returnValue = "Please close all Editor Windows before exiting to ensure no changes are lost!";
      e.returnValue = returnValue;
      return returnValue;
    } else {
      // not returning a value will not show the popup, that is what we want here
    }
  }

  componentDidMount(): void {
    window.addEventListener("beforeunload", this.beforeUnload);
  }

  componentWillUnmount(): void {
    window.removeEventListener("beforeunload", this.beforeUnload);
  }


}

export default MetusWorkbenchContainer;

@observer
class WorkbenchWindowComponent extends React.Component<WindowProps> {
  render(): JSX.Element {
    const editorState = metusStore.getEditorStateByWindowIndex(this.props.windowIndex);
    let windowPathAsObservable;
    let windowPathAsArray;
    if (this.props.numberOfOpenWindows === 1) {
      windowPathAsArray = windowPathAsObservable = EMPTY_PATH;
    } else {
      windowPathAsObservable = editorState.windowPath || EMPTY_PATH;
      windowPathAsArray = editorState.windowPathAsArray() || EMPTY_PATH;
    }
    // trace(true);
    renderLog.debug("Rendering WorkbenchWindowComponent", this.props.viewInfo, windowPathAsArray);
    const pathClassName = windowPathAsArray.length === 0 ? "first" : windowPathAsArray.join("_");
    return <ViewActivationComponent key={this.props.windowIndex}
                                    viewId={this.props.viewInfo ? this.props.viewInfo.id : null}
                                    activeViewId={metusStore.activeViewId}
                                    handleSetActiveAction={this.onActivateView}>
      <MosaicWindow<number>
          title={this.props.viewInfo ? this.props.viewInfo.name : ""}
          path={windowPathAsArray}
          toolbarControls={<ViewToolbar viewInfo={this.props.viewInfo} windowIndex={this.props.windowIndex}
                                        windowPath={windowPathAsObservable}
                                        numberOfOpenWindows={this.props.numberOfOpenWindows}
                                        isFullScreen={this.props.isFullScreen}/>}
          createNode={this.props.createNode}
          className={pathClassName + " " + "metus-mosaic-window"}
          onDragStart={this.handleDragStart}
      >
        <div className="metus-window" data-testselector={pathClassName}>
          <ViewContainer viewInfo={this.props.viewInfo} windowPath={windowPathAsObservable} editorState={editorState}
                         windowIndex={this.props.windowIndex}/>
        </div>
      </MosaicWindow>
    </ViewActivationComponent>;
  }

  private handleDragStart(): void {
    Dispatcher.dispatch(new MosaicWindowDragStartAction());
  }

  @autobind
  private onActivateView(viewId: ViewId): void {
    if (viewId !== metusStore.activeViewId) {
      Dispatcher.dispatch(new ActivateViewAction(viewId));
    }
  }

  @autobind
  private saveOnFocusLost(): void {
    const viewInfo = this.props.viewInfo;
    if (viewInfo) {
      saveView(viewInfo.type, viewInfo.id, viewInfo.name, viewInfo.viewVersion, false);
    }
  }

  componentDidMount(): void {
    window.addEventListener("blur", this.saveOnFocusLost);
  }

  componentWillUnmount(): void {
    window.removeEventListener("blur", this.saveOnFocusLost);
  }
}




