import * as React from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {attributeTypeItems} from "../../common/constants/Enums";
import {AttributeType, TableId} from "../../core/utils/Core";
import {AttributeDefinition, AttributeFormatType} from "../../api/api";
import {modelStore} from "../../core/stores/ModelStore";
import MetusTextField from "../../common/components/MetusTextField";
import autobind from "autobind-decorator";
import {normalizeName} from "../../core/utils/NameNormalizer";
import {hideDialog, showDialog} from "../../common/utils/CommonDialogUtil";
import {editAttribute, newAttribute} from "../../core/services/CoreDataServices";
import EditAttributeDefinitionFormatTypeComponent from "./EditAttributeDefinitionFormatTypeComponent";
import EditFormulaComponent from "./EditFormulaComponent";
import AttributeDefinitionSelectionContainer from "./AttributeDefinitionSelectionContainer";
import BaseDialog, {dialogContentStyles} from "../../common/components/BaseDialog";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);
const MD_DATE_FORMAT_PATTERN: string = "dd.MM.yyyy";
const MD_NUMBER_DEFAULT_FORMAT_PATTERN: string = "0,00";

interface LocalProps {
  open: boolean;
  tableId: TableId;
  attributeName?: string;
}

interface LocalState {
  attributeType: AttributeType;
  attributeFormatType: AttributeFormatType;
  attributeName: string;
  formatString: string;
  formulaString: string;
  targetTableId: TableId;
  targetTableIdIsSet: boolean;
  targetAttributeName: string;
  targetAttributeNameIsSet: boolean;
  open: boolean;
  errorMessage: string;
  invalidTextFieldId: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const DropDownMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type StyledLocalProps = LocalProps & WithStyles<typeof dialogContentStyles>;

class NewAttributeDialog extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    const attDef: AttributeDefinition = modelStore.getAttributeDefinition(this.props.tableId, this.props.attributeName);
    this.state = {
      attributeType: attDef ? attDef.type : "String",
      attributeFormatType: attDef ? attDef.formatType : "String",
      attributeName: attDef ? attDef.name : "",
      formatString: attDef ? attDef.pattern : "",
      formulaString: attDef ? attDef["formula"] : "",
      targetTableId: attDef ? attDef["referencedTableId"] : "",
      targetTableIdIsSet: attDef !== undefined && attDef["referencedTableId"] !== undefined,
      targetAttributeName: attDef ? attDef["referencedAttributeName"] : "",
      targetAttributeNameIsSet: attDef !== undefined && attDef["referencedAttributeName"] !== undefined,
      open: this.props.open,
      errorMessage: undefined,
      invalidTextFieldId: undefined,
    };
  }

  private reset(): void {
    this.setState(prevState => {
      return {
        attributeType: "String",
        attributeFormatType: "String",
        attributeName: "",
        formatString: undefined,
        formulaString: "",
        targetTableId: "",
        targetTableIdIsSet: false,
        targetAttributeName: "",
        targetAttributeNameIsSet: false,
        open: this.props.open,
        errorMessage: undefined,
        invalidTextFieldId: undefined,
      };
    });
  }

  private isError(textFieldId: string): boolean {
    return !!this.state.errorMessage && this.state.invalidTextFieldId === textFieldId;
  }

  render(): JSX.Element {
    renderLog.debug("Rendering NewAttributeDialog");
    const {classes} = this.props;
    const paperProps = !!this.state.errorMessage || !this.state.targetTableIdIsSet || !this.state.targetAttributeNameIsSet ? classes.dialogPaperError : classes.dialogPaper;
    const title = !this.props.attributeName ? "Create a new attribute for table " + modelStore.getTableName(this.props.tableId) : "Edit Attribute"

    return <BaseDialog
        title={title}
        onSubmit={this.handleSubmit}
        open={this.props.open}
        dialogContent={this.dialogContent()}
    >
    </BaseDialog>
  }

  @autobind
  private handleAttributeTypeSelect(event: any): void {
    if (this.state.attributeType !== event.target.value) {
      this.setState({attributeType: event.target.value, attributeFormatType: "String"});
    }
  }

  @autobind
  private handleAttributeFormatTypeSelect(formatTypeAndMessage: { attributeFormatType: AttributeFormatType, formatString: string }): void {
    if (this.state.attributeFormatType !== formatTypeAndMessage.attributeFormatType) {
      this.setState({
        attributeFormatType: formatTypeAndMessage.attributeFormatType,
        errorMessage: undefined,
        invalidTextFieldId: undefined,
        formatString: formatTypeAndMessage.formatString,
      });
    }
  }

  @autobind
  private handleReferenceTableSelect(tableIdAndAttributeName: { tableId: TableId, attributeDefinitionName: string }): void {
    if (this.state.targetTableId !== tableIdAndAttributeName.tableId) {
      this.setState({
        targetTableId: tableIdAndAttributeName.tableId,
        targetAttributeName: tableIdAndAttributeName.attributeDefinitionName
      });
    }
  }

  @autobind
  private handleReferenceAttributeSelect(attributeName: string): void {
    if (this.state.targetAttributeName !== attributeName) {
      this.setState({targetAttributeName: attributeName});
    }
  }

  @autobind
  private handleChangeAttributeName(event: any): void {
    const attributeName = event.target.value;
    log.debug("attributeName " + attributeName);
    this.setState(prevState => {
      return {attributeName, errorMessage: undefined, invalidTextFieldId: undefined};
    });
  }

  @autobind
  private handleChangeFormatString(formatString: string): void {
    log.debug("formatString " + formatString);
    this.setState(prevState => {
      return {formatString, errorMessage: undefined, invalidTextFieldId: undefined};
    });
  }

  @autobind
  private handleChangeFormulaString(formulaString: string): void {
    this.setState({formulaString: formulaString});
  }

  @autobind
  private handleSubmit(): void {
    if (this.state.attributeType === "Derived" && this.state.targetTableId === null && this.state.targetAttributeName === null) {
      this.setState({targetTableIdIsSet: false, targetAttributeNameIsSet: false});
    } else if (this.state.attributeType === "Derived" && this.state.targetTableId === null) {
      this.setState({targetTableIdIsSet: false});
    } else if (this.state.attributeType === "Derived" && this.state.targetAttributeName === null) {
      this.setState({targetAttributeNameIsSet: false});
    } else if (!this.state.attributeName) {
      this.setState({invalidTextFieldId: "attributeName", errorMessage: "Please enter an attribute name"});
    } else if ((!this.state.formatString || this.state.formatString === "") && (this.state.attributeFormatType === "Double" || this.state.attributeFormatType === "Date")) {
      this.setState({invalidTextFieldId: "formatString", errorMessage: "Please enter a format string"});
    } else {
      const normalizedAttributename: string = normalizeName(this.state.attributeName);
      if (this.props.attributeName) {
        editAttribute(this.props.tableId,
            this.props.attributeName,
            normalizedAttributename,
            this.state.attributeType,
            this.state.attributeFormatType,
            this.state.formatString,
            this.state.targetTableId,
            this.state.targetAttributeName,
            this.state.formulaString);
      } else {
        newAttribute(this.props.tableId,
            normalizedAttributename,
            this.state.attributeType,
            this.state.attributeFormatType,
            this.state.formatString,
            this.state.targetTableId,
            this.state.targetAttributeName,
            this.state.formulaString);
      }
      this.reset();
      hideDialog();
    }
  }

  @autobind
  private dialogContent() {
    const attributeNameInput = (
        <MetusTextField style={{gridRow: 1, gridColumn: 1, maxWidth: "150px"}}
                        error={this.isError("attributeName")}
                        autoFocus
                        id="attributeName"
                        placeholder="Attribute Name"
                        value={this.state.attributeName}
                        onChange={this.handleChangeAttributeName}
                        helperText={"Attribute Type: " + this.state.attributeType}
        />);

    const attributeTypeCheckBoxes = (
          <RadioGroup
              style={{gridRow: 2, gridColumn: 1}}
              name="attributeType"
              value={this.state.attributeType}
              onChange={this.handleAttributeTypeSelect}>
            {attributeTypeItems.map(ati => {
              return <FormControlLabel key={ati.name}
                                       data-testselector={`listitem-${ati.type}`}
                                       classes={{label: this.props.classes.formControlLabel}}
                                       value={ati.type}
                                       label={ati.name}
                                       control={<Radio/>}/>;
            })}
          </RadioGroup>
    );

    const formatComponent = (<div style={{gridRow: 2, gridColumn: 2}}>
      <EditAttributeDefinitionFormatTypeComponent
          onFormatTypeChange={this.handleAttributeFormatTypeSelect}
          onFormatStringChange={this.handleChangeFormatString}
          formatStringMissing={this.isError("formatString")}
          attributeFormatType={this.state.attributeFormatType}
          formatString={this.state.formatString}></EditAttributeDefinitionFormatTypeComponent>
    </div>);
    const style = this.props.attributeName ? {gridRow: 2, gridColumn: 1} : {gridRow: 2, gridColumn: 2}
    let editingComponents = undefined;
    switch (this.state.attributeType) {
      case "String":
        editingComponents = <span style={style}>{formatComponent}</span>;
        break;
      case "Formula":
        editingComponents = (<span style={style}>{formatComponent}{<EditFormulaComponent
            formula={this.state.formulaString}
            onChange={this.handleChangeFormulaString}
        />}</span>);
        break;
      case "Derived":
        editingComponents = <span style={style}><AttributeDefinitionSelectionContainer
            tableId={this.state.targetTableId}
            attributeDefinitionName={this.state.targetAttributeName}
            customTableLabel={"Target Table:"}
            customTableHelperText={"Select a Table"}
            customAttDefLabel={"Target Attribute:"}
            customAttDefHelperText={"Select an Attribute"}
            onTableChange={this.handleReferenceTableSelect}
            onAttributeChange={this.handleReferenceAttributeSelect}
        /></span>;
        break;
      default:
        break;
    }
    return (<span style={{display: "grid"}}>
      {attributeNameInput}
      {!this.props.attributeName ? attributeTypeCheckBoxes : undefined}
      {editingComponents}
    </span>);
  }
}

const StyledNewAttributeDialog = withStyles(dialogContentStyles)(NewAttributeDialog);
export default StyledNewAttributeDialog;

export function showCreateNewAttributeDialog(display: boolean, tableId: TableId, attributeName?: string): void {
  showDialog(display, <StyledNewAttributeDialog open={display}
                                                attributeName={attributeName}
                                                tableId={tableId}/>);
}
