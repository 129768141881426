import {VisualTextBox} from "../../models/common/VisualTextBox";
import React from "react";
import EditableTextComponent from "../../../common/components/EditableTextComponent";
import {SVGTextBoxComponent} from "../../../common/components/SVGTextBoxComponent";
import autobind from "autobind-decorator";
import {observer} from "mobx-react";
import {ResizeVisualObjectAction, UpdateTextboxTextAction} from "../../actions/DiagramActions";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {ContextMenuTrigger} from "react-contextmenu";
import {ViewerContext} from "../../utils/ViewerContext";
import {ClickableAreaFiller} from "../../../common/components/ClickableAreaFiller";
import {Direction} from "../../../common/utils/Direction";
import {CustomizedHandleInfo, HandleComponent} from "./HandleComponent";


interface VisualTextBoxProps {
  viewModel: VisualTextBox,
  viewerContext: ViewerContext
}

@observer
export class VisualTextBoxComponent extends React.Component<VisualTextBoxProps> {
  constructor(props: VisualTextBoxProps) {
    super(props);
  }

  @autobind
  updateText(text: string) {
    Dispatcher.dispatch(new UpdateTextboxTextAction(this.props.viewModel.parent.id, this.props.viewModel.id, text));
  }

  @autobind collect(props: any): { id: string } {
    return {id: this.props.viewModel.id};
  }

  @autobind handleResized(direction: Direction, dx: number, dy: number): void {
    Dispatcher.dispatch(new ResizeVisualObjectAction(this.props.viewModel.parent.id, this.props.viewModel.id, direction, dx, dy));
  }

  @autobind getCustomizedHandleInfo(): { [K in keyof typeof Direction]?: CustomizedHandleInfo } {
    // Only here to make the area in which the the the hover gets triggered bigger, so it is easier to resize the testbox
    // Probably should be the default behaviour but since it will be refactored anyways it didn't get implemented yet
    const customizedHandleInfo = {
      EAST: {
        y: this.props.viewModel.y,
        height: this.props.viewModel.height
      },
      WEST: {
        y: this.props.viewModel.y,
        height: this.props.viewModel.height
      },
    };

    return customizedHandleInfo;
  }

  render(): JSX.Element {
    let element = <SVGTextBoxComponent
        text={this.props.viewModel.text}
        width={this.props.viewModel.width}
        height={this.props.viewModel.height}
        wrapLines={true}/>;

    // always render rect to force group size to width and height, otherwise it adjusts to containing text and is not clickable on empty text
    const filler = <ClickableAreaFiller style={{fill: "transparent", stroke: "black", strokeWidth: "2px"}}
                                        height={this.props.viewModel.height} width={this.props.viewModel.width}/>;
    const contextMenuId = "cm_dg_TextBoxcomponent" + this.props.viewerContext.windowIndex;
    element = <g>{filler}{element}</g>;

    const customizedHandleInfo = this.getCustomizedHandleInfo();

    return <g>
      <g transform={"translate(" + this.props.viewModel.x + ", " + this.props.viewModel.y + ")"}>
        <ContextMenuTrigger id={contextMenuId}
                            data-id={this.props.viewModel.id}
                            collect={this.collect}
                            holdToDisplay={-1}
                            renderTag={"g"}
                            attributes={{"data-testselector": "contextmenutrigger"} as any}>
          <EditableTextComponent onUpdate={this.updateText}
                                 textElement={element}></EditableTextComponent>
        </ContextMenuTrigger>
      </g>
      <HandleComponent scale={this.props.viewerContext.scale}
                       defaultCallback={this.handleResized}
                       visualModel={this.props.viewModel}
                       customizedHandleInfo={customizedHandleInfo}
                       visualId={this.props.viewModel.id}/>
    </g>;
  }
}