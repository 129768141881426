import {ViewId} from "../../core/utils/Core";
import Log from "../../common/utils/Logger";
import {computed} from "mobx";
import {Validate} from "../../common/utils/Validate";

const log = Log.logger("PropertiesModel");

interface IOptions {
  getType(): string;

  getProperties(): string;
}

/**
 * a propertiesProvider is a function which extracts the properties for the corresponding section from the context.
 * If it returns props !== null, the section will be visible and show the retrieved properties.
 *
 * Ensure your propertiesProvider's output is based on mobx observables for auto-update to work!
 */
export type PropertiesProvider<C> = (context: C) => BaseSectionProperties;

/**
 * A TabDefinition defines a Tab which may be visible in the Toolbox (also called Properties) Sidebar.
 * The "Toolbox" or Properties Sidebar shows several Tabs, each constructed from the corresponding TabDefinition and represented
 * by the specified icon.
 *
 * Selecting a tab shows all sections contained in the tab; each section can be expanded or collapsed.
 * A tab is visible if at least one section is visible.
 *
 */
export interface TabDefinition<C> {
  id: string;
  label: string;
  icon: JSX.Element;
  sections: SectionDefinition<C>[];
}

/**
 * A section definition defines a section of a tab.
 * <BR>The reactComponent will render the section content.
 * <BR>A section will ONLY be rendered if at least one of the associated propertiesProvider applied to the active view
 * returns properties (a result !== null)
 * <BR>These properties will be passed as input to the reactComponent.
 */
export interface SectionDefinition<C> {

  /** id, MUST be unique among all sections */
  id: string;
  /** label displayed in section header */
  label: string;
  /** optional icon, null if no icon needed */
  icon: any;
  /** react component to render this section */
  reactComponent: any;
  propertiesProvider: PropertiesProvider<C>[]; /** list of properties provider*/
}

/**
 * Tab representing the runtime model for the TabDefinition.
 * It will automatically update its visibility dependent on visibility of its sections using mobx.
 * Thus observer components will update automatically.
 */
export class Tab<C> {
  /** id, MUST be unique among all tab definitions */
  readonly tabDefinitionId: string;
  readonly sections: Section<C>[];

  constructor(tabDefinitionId: string, sections: Section<C>[]) {
    this.tabDefinitionId = tabDefinitionId;
    this.sections = sections;
  }

  @computed get visible(): boolean {
    let result = false;
    // some cannot be used since mobx will not recompute result if not all values are accessed
    this.sections.forEach(section => result = result || section.visible);
    log.debug("Recomputing tab " + this.tabDefinitionId + " visibility: " + result);
    return result;
  }
}

/**
 * section created from section definition.
 * The properties are the result of the propertiesProvider applied to the active view
 * and supplied to the react component for this section as component properties.
 *
 * A section is visible iff one of its propertiesProviders applied to the context return !== null.
 * Visibility and properties will automatically update using mobx when dependent values change,
 * using mobx computed values.
 *
 */
export class Section<C> {
  readonly sectionDefinitionId: string;
  readonly propertiesProviders: PropertiesProvider<C>[];
  readonly context: C;

  constructor(sectionDefinitionId: string, propertiesProviders: PropertiesProvider<C>[], context: C) {
    this.sectionDefinitionId = sectionDefinitionId;
    this.propertiesProviders = propertiesProviders;
    this.context = context;
  }

  @computed.struct get properties(): BaseSectionProperties {
    log.debug("Recomputing Properties for Section " + this.sectionDefinitionId);
    let result = null;
    // check all providers if they return a result !== null and return props provided
    this.propertiesProviders.forEach(provider => {
      const props = provider(this.context);
      if (props) {
        Validate.isTrue(result === null, "There must only be one provider returning properties, two are not allowed for section " + this.sectionDefinitionId);
        result = props;
      }
    })
    log.debug("Properties are ", result);
    return result;
  }

  @computed get visible(): boolean {
    const result = this.properties !== null;
    log.debug("Recomputing section " + this.sectionDefinitionId + " visibility: " + result);
    return result;
  }
}

export interface BaseSectionProperties {
  activeViewId: ViewId;
}

/**
 * Model of the Toolbox Tab and Sections UI.
 *
 * The model is defined by meta information configured via method setTabDefinitions().
 *
 * Once set, the tab and section models will be created reflecting properties of the active view (or in the future selection).
 *
 * All tabs and sections are derived as computed values and thus automatically updated by mobx on any changes of relevant properties in stores.
 *
 * So this model is not a store since it does not accept actions; it automatically updates on changes of the context affecting visibility of sections and tabs.
 */
export class PropertiesModel<C> {
  private tabDefinitions: TabDefinition<C>[] = [];
  private context: C;
  private idToTabDefinition: Map<string, TabDefinition<C>>;
  private idToSectionDefinition: Map<string, SectionDefinition<C>>;

  constructor() {
  }

  /**
   * define the available tabs, their corresponding sections + providers which provide the properties for the section.
   * This is the maximum of visible tabs and sections; a section will be shown if one of it's properties providers returns an object != null,
   * this object is passed as props to the corresponding React Component.
   * @param tabDefinitions Tab Definitions define the structure of the Toolbox and all section components and their providers
   * @param context context passed to properties providers to determine their properties; must be observable to facilitiy automatic update on changes
   */
  public init(tabDefinitions: TabDefinition<C>[], context: C): void {
    log.debug("Tab Definitions set ", tabDefinitions);
    log.debug("Context set ", context);

    Validate.notNull(tabDefinitions);
    Validate.notNull(context);
    this.context = context;
    this.tabDefinitions = tabDefinitions;
    this.idToTabDefinition = new Map<string, TabDefinition<C>>();
    this.idToSectionDefinition = new Map<string, SectionDefinition<C>>();

    tabDefinitions && tabDefinitions.forEach(tabDefinition => {
      this.idToTabDefinition[tabDefinition.id] = tabDefinition;
      tabDefinition.sections.forEach(sectionDefinition => this.idToSectionDefinition[sectionDefinition.id] = sectionDefinition);
    });
  }

  public getTabDefinition(id: string): TabDefinition<C> {
    return this.idToTabDefinition[id];
  }

  public getSectionDefinition(id: string): SectionDefinition<C> {
    return this.idToSectionDefinition[id];
  }

  public getTabForSection(tabs: Tab<C>[], sectionId: string): Tab<C> {
    return tabs.filter(tab => tab.sections.find(sp => sp.sectionDefinitionId === sectionId)).pop();
  }

  /**
   * main entry to build up the Toolbox (properties sidebar).
   * For each tab definition a tab will be created; for each tab all section definitions will create a section if
   * a propertiesProvider for this section definition returns a result !== null.
   */
  @computed.struct
  public get tabs(): Tab<C>[] {
    log.debug("Recomputing tabs");
    /*
      map tree of tab- and section definitions to tree of corresponding Tabs and Sections.
    */
    const result = this.tabDefinitions
        .map((tabDefinition: TabDefinition<C>) => {
          const sections = tabDefinition.sections
              .map((sectionDefinition: SectionDefinition<C>) => {
                return new Section<C>(sectionDefinition.id,
                    sectionDefinition.propertiesProvider, this.context);
              })
          return new Tab<C>(tabDefinition.id, sections);
        });
    log.debug("Resulting tabs: ", result);
    return result;
  }
}
