import * as React from "react";
import AutoLayoutPropertiesComponent from "../../../diagram/components/properties/AutoLayoutPropertiesComponent";
import {TabDefinition} from "../../../properties/model/PropertiesModel";
import HeaderLayoutPropertiesComponent
  from "../../../commonviews/components/properties/HeaderLayoutPropertiesComponent";
import ShareActionsPropertiesComponent
  from "../../../commonviews/components/properties/ShareActionsPropertiesComponent";
import ValueChartPropertiesComponent from "../../../diagram/components/properties/ValueChartPropertiesComponent";
import FilterPropertiesComponent from "../../../commonviews/components/properties/FilterPropertiesComponent";
import UndoActionsComponent from "../../../undoredo/components/UndoActionsComponent";
import {CloudDownload, Palette, SdStorage, Share, Undo} from "@material-ui/icons";
import TestDataCreationComponent from "../../../commonviews/components/properties/TestDataCreationComponent";
import MatrixCellConfigurationPropertiesComponent
  from "../../../matrix/components/properties/MatrixCellConfigurationPropertiesComponent";
import MatrixFilterPropertiesComponent
  from "../../../matrix/components/properties/filter/MatrixFilterPropertiesComponent";
import FilterToolbarIcon from "../../../properties/icons/FilterToolbarIcon";
import DownloadActionsPropertiesComponent
  from "../../../commonviews/components/properties/DownloadActionsPropertiesComponent";
import {undoActionsPropertiesProvider} from "../../../undoredo/properties/PropertiesProvider";
import {
  downloadActionsPropertiesProvider,
  shareActionsPropertiesProvider,
  testDataCreationPropertiesProvider
} from "../../../commonviews/properties/PropertiesProvider";
import {
  autoLayoutPropertiesProvider,
  valueChartPropertiesProvider
} from "../../../diagram/properties/PropertiesProvider";
import {
  matrixCellConfigurationPropertiesProvider,
  matrixColumnFilterPropertiesProvider
} from "../../../matrix/properties/PropertiesProvider";
import {
  columnLimitPropertiesProvider,
  matrixFilterPropertiesProvider,
  matrixHeaderlayoutPropertiesProvider
} from "../../../matrix/properties/MatrixSharedPropertiesProvider";
import {
  diagramFilterPropertiesProvider,
  diagramHeaderlayoutPropertiesProvider
} from "../../../diagram/properties/DiagramSharedPropertiesProvider";
import {IMetusPropertiesProviderContext} from "../../../commonviews/contexts/IMetusPropertiesProviderContext";
import ColumnLimitComponent from "../../../matrix/components/properties/filter/ColumnLimitComponent";

export const toolboxTabDefinitions: TabDefinition<IMetusPropertiesProviderContext>[] = [
  {
    id: "Layout",
    label: "Layout",
    icon: <Palette/>,
    sections: [
      {
        id: "autolayout",
        label: "Auto Layout",
        icon: null,
        reactComponent: AutoLayoutPropertiesComponent,
        propertiesProvider: [autoLayoutPropertiesProvider]
      },
      {
        id: "headerlayout",
        label: "Header Layout",
        icon: null,
        reactComponent: HeaderLayoutPropertiesComponent,
        propertiesProvider: [matrixHeaderlayoutPropertiesProvider, diagramHeaderlayoutPropertiesProvider]
      },
      {
        id: "valueChartLayout",
        label: "Value Chart Layout",
        icon: null,
        reactComponent: ValueChartPropertiesComponent,
        propertiesProvider: [valueChartPropertiesProvider]
      },
    ]
  },

  {
    id: "FilterProperties",
    label: "Filter",
    icon: <FilterToolbarIcon/>,
    sections: [
      {
        id: "showConnectedElements",
        label: "Show connected",
        icon: null,
        reactComponent: FilterPropertiesComponent,
        propertiesProvider: [matrixFilterPropertiesProvider, diagramFilterPropertiesProvider]
      },
      {
        id: "matrixColumnFilter",
        label: "Column Filter",
        icon: null,
        reactComponent: MatrixFilterPropertiesComponent,
        propertiesProvider: [matrixColumnFilterPropertiesProvider]
      },
      {
        id: "matrixColumnLimit",
        label: "Column Limit",
        icon: null,
        reactComponent: ColumnLimitComponent,
        propertiesProvider: [columnLimitPropertiesProvider]
      }
    ]
  },

  {
    id: "ShareActions",
    label: "Share",
    icon: <Share/>,
    sections: [
      {
        id: "shareActions",
        label: "Share Actions",
        icon: null,
        reactComponent: ShareActionsPropertiesComponent,
        propertiesProvider: [shareActionsPropertiesProvider]
      },
    ]
  },

  {
    id: "DownloadActions",
    label: "Download",
    icon: <CloudDownload/>,
    sections: [
      {
        id: "downloadActions",
        label: "Download Actions",
        icon: null,
        reactComponent: DownloadActionsPropertiesComponent,
        propertiesProvider: [downloadActionsPropertiesProvider]
      },
    ]
  },

  {
    id: "UndoActions",
    label: "Undo",
    icon: <Undo/>,
    sections: [
      {
        id: "undoActions",
        label: "Undo / Redo",
        icon: null,
        reactComponent: UndoActionsComponent,
        propertiesProvider: [undoActionsPropertiesProvider]
      },
    ]
  },

  {
    id: "ToBeDefined",
    label: "Other Sections",
    icon: <SdStorage/>,
    sections: [
      {
        id: "matrixCellConfiguration",
        label: "Matrix Cell Configuration",
        icon: null,
        reactComponent: MatrixCellConfigurationPropertiesComponent,
        propertiesProvider: [matrixCellConfigurationPropertiesProvider]
      },
      {
        id: "testDataCreation",
        label: "Test Data",
        icon: null,
        reactComponent: TestDataCreationComponent,
        propertiesProvider: [testDataCreationPropertiesProvider]
      },
    ]
  }
];

