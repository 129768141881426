/* ProcessesIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import {withStyles} from "@material-ui/core";
import * as React from "react";

class ProcessesIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
        .st0proc{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;fill-opacity:0.5;}
        .st1proc{display:none;fill:#FFFFFF;}
        .st2proc{fill:#FFFFFF;}
     `}</style>
      <g>
        <g>
          <path id="Combined-Shape_4_" className="st0proc" d="M47.83,24.84v8.42H32.15v-8.42C36.93,22.08,43.04,22.08,47.83,24.84z M43.87,16.58
			c0,2.15-1.74,3.89-3.89,3.89c-2.15,0-3.89-1.74-3.89-3.89c0-2.15,1.74-3.89,3.89-3.89C42.13,12.69,43.87,14.43,43.87,16.58z"/>
          <path id="Combined-Shape_5_" className="st0proc" d="M66.9,58.86v8.42H51.23v-8.42C56.01,56.09,62.12,56.09,66.9,58.86z M62.95,50.6
			c0,2.15-1.74,3.89-3.89,3.89s-3.89-1.74-3.89-3.89c0-2.15,1.74-3.89,3.89-3.89S62.95,48.45,62.95,50.6z"/>
          <path id="Combined-Shape_3_" className="st0proc" d="M28.77,58.88v8.42H13.09v-8.42C17.87,56.11,23.99,56.11,28.77,58.88z M24.82,50.62
			c0,2.15-1.74,3.89-3.89,3.89s-3.89-1.74-3.89-3.89c0-2.15,1.74-3.89,3.89-3.89S24.82,48.47,24.82,50.62z"/>
          <path className="st1proc" d="M59.91,40.13C59.91,40.13,59.91,40.13,59.91,40.13c0-0.23-0.19-0.42-0.42-0.42H40.41l0-4.07
			c0-0.23-0.19-0.42-0.42-0.42h0c-0.23,0-0.42,0.19-0.42,0.42l0,4.07H20.49c-0.13,0-0.24,0.06-0.32,0.16
			c-0.08,0.08-0.13,0.18-0.13,0.3v4.36c0,0.23,0.19,0.42,0.42,0.42s0.42-0.19,0.42-0.42v-3.98h38.21v3.99
			c0,0.23,0.19,0.42,0.42,0.42s0.42-0.19,0.42-0.42v-4.36C59.92,40.16,59.91,40.15,59.91,40.13z"/>
        </g>
        <g>
          <path className="st2proc" d="M59.66,39.71h-0.41h-0.41H21.16h-0.37h-0.44c-0.17,0-0.3,0.13-0.3,0.3v4.52c0,0.17,0.13,0.3,0.3,0.3h0.81
			c0.17,0,0.3-0.13,0.3-0.3v-3.4h37.08v3.4c0,0.17,0.13,0.3,0.3,0.3h0.81c0.17,0,0.3-0.13,0.3-0.3v-4.52
			C59.96,39.85,59.82,39.71,59.66,39.71z"/>
          <path className="st2proc"
                d="M40.69,35.13c0-0.16-0.13-0.29-0.29-0.29h-0.83c-0.16,0-0.29,0.13-0.29,0.29v4.58h1.41V35.13z"/>
        </g>
      </g>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(ProcessesIcon);
