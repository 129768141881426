export class DiagramVisualConstants {
  // graph header is built as follows (think vertically) TABLE_HEADER_YPOS TABLE_HEADER_HEIGHT HEADER_GAP ATTRIBUTE_HEADER_SPACE_NOT_ROTATED HEADER_GAP FILTER_SPACE HEADER_GAP DEFAULT_NODE_HEIGHT DEFAULT_GAP_BETWEEN_NODES ...
  public static readonly TABLE_HEADER_YPOS: number = 0;
  /**
   * width a visual table column takes when dropped into a diagram
   */
  public static readonly DEFAULT_TABLE_WIDTH: number = 150;
  /**
   * table headers cannot be resized lower than this size
   */
  public static readonly MINIMUM_TABLE_WIDTH: number = DiagramVisualConstants.DEFAULT_TABLE_WIDTH;
  public static readonly DEFAULT_ELEMENT_HEIGHT: number = 20;

  /**
   * left inset by which value chart level elements are nested inside its parent element
   */
  public static readonly VC_LEVEL_INSET: number = 18;

  /**
   * width a visual attribute takes when dropped into a diagram
   */
  public static readonly DEFAULT_ATT_WIDTH: number = DiagramVisualConstants.DEFAULT_TABLE_WIDTH;

  /**
   * minimum attribute width, attributes cannot be resized below this width
   */
  public static readonly MINIMUM_ATT_WIDTH: number = 4;

  /**
   * gap between visual attribute columns in header
   */
  public static readonly ATTRIBUTE_HEADER_GAP: number = 1;

  /**
   * height a visual attribute takes
   */
  public static readonly DEFAULT_ATT_HEIGHT: number = DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT;

  /** space to push attribute headers down if headers are not rotated */
  public static readonly ATTRIBUTE_HEADER_SPACE_NOT_ROTATED: number = 15;
  /**
   * vertical gap between newly created elements in a chart
   */
  public static readonly DEFAULT_GAP_BETWEEN_ELEMENTS: number = 10;

  /**
   * vertical and horizontal gap between elements and margin to surrounding box
   * VALUE CHART is built as follows (l = level, nl = next level, e = element to draw)
   * vertically: VC_GAP l.header.height VC_GAP l.contentDy contentHeight
   * with contentHeight = (#lines*(max of line nested height + VC_GAP) + VC_GAP) + VC_GAP
   * horizontally: VC_GAP + contentDx + contentWidth
   * with contentWidth = leaf level ? l.width: nl.columns * (nestedWidth + VC_GAP) + VC_GAP
   */
  public static readonly VC_GAP: number = 0;

  /**
   * header title row content height, content must define padding itself
   */
  public static TABLE_HEADER_TITLE_HEIGHT: number = 24;
  /**
   * attribute header title row content height, content must define padding itself
   */
  public static TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT: number = 24;
  /**
   * pixels the margin lines take up
   */
  public static TABLE_HEADER_MARGIN_WIDTH: number = 1;
  /**
   * attribute header filter row content height, content must define padding itself
   */
  public static TABLE_HEADER_FILTER_HEADER_HEIGHT: number = 24;

  /**
   * gap between header area and elements area of a diagram (vc + chart)
   */
  public static HEADER_ELEMENT_VERTICAL_GAP: number = 20;
  /**
   * radius of rounded corners of the table header
   */
  public static TABLE_HEADER_ROUNDED_ARC: number = 3;

  /**
   * height of a value chart level header without filter line
   */
  public static readonly VC_LEVEL_HEADER_HEIGHT_NO_FILTER: number = DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + 3 * DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH + DiagramVisualConstants.TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT;

  /**
   * height of a value chart level header fully expanded
   */
  public static readonly VC_LEVEL_HEADER_HEIGHT: number = DiagramVisualConstants.VC_LEVEL_HEADER_HEIGHT_NO_FILTER + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH + DiagramVisualConstants.TABLE_HEADER_FILTER_HEADER_HEIGHT;

  /**
   * height of table header with attribute line, but no filter line
   */
  public static readonly TABLE_HEADER_HEIGHT_NO_FILTER: number = DiagramVisualConstants.VC_LEVEL_HEADER_HEIGHT_NO_FILTER;

  /**
   * height of table header with attribute line, but no filter line
   */
  public static readonly TABLE_HEADER_HEIGHT_NO_ATTRIBUTES: number = DiagramVisualConstants.TABLE_HEADER_HEIGHT_NO_FILTER + DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + 2 * DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH;

  /**
   * level header height without filter
   */
  public static readonly VC_LEVEL_HEADER_HEIGHT_WITH_ARC_NO_FILTER: number = DiagramVisualConstants.VC_LEVEL_HEADER_HEIGHT_NO_FILTER + DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC;

  /**
   * default chart rotated header height
   */
  public static readonly DEFAULT_ROTATED_HEADER_HEIGHT: number = 105;

  /**
   * half size of drag handles
   */
  public static readonly HANDLE_RADIUS: number = 3;

  /**
   * horizontal gap between matrix header section
   */
  public static readonly MATRIX_HEADER_SECTION_GAP: number = 20;
}
