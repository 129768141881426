import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";



class ChartIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return  <path d="M34,35 C34,34.449 34.448,34 35,34 L46,34 C46.552,34 47,34.449 47,35 L47,41 C47,41.551 46.552,42 46,42 L35,42 C34.448,42 34,41.551 34,41 L34,35 Z M34,15 C34,14.449 34.448,14 35,14 L46,14 C46.552,14 47,14.449 47,15 L47,21 C47,21.551 46.552,22 46,22 L35,22 C34.448,22 34,21.551 34,21 L34,15 Z M35,24 L46,24 C47.657,24 49,22.657 49,21 L49,15 C49,13.343 47.657,12 46,12 L35,12 C33.343,12 32,13.343 32,15 L32,17 C28.909,17 27.663,19.809 26.564,22.286 C25.263,25.219 24.307,27 22,27 L22,25 C22,23.343 20.657,22 19,22 L10,22 C8.343,22 7,23.343 7,25 L7,31 C7,32.657 8.343,34 10,34 L19,34 C20.657,34 22,32.657 22,31 L22,29 C24.307,29 25.263,30.781 26.564,33.714 C27.663,36.191 28.909,39 32,39 L32,41 C32,42.657 33.343,44 35,44 L46,44 C47.657,44 49,42.657 49,41 L49,35 C49,33.343 47.657,32 46,32 L35,32 C33.343,32 32,33.343 32,35 L32,37 C30.297,37 29.52,35.443 28.393,32.903 C27.625,31.174 26.729,29.172 25.137,28 C26.729,26.828 27.625,24.826 28.393,23.097 C29.52,20.557 30.297,19 32,19 L32,21 C32,22.657 33.343,24 35,24 L35,24 Z"/>;
  }

  viewBox(): string {
    return "0 0 56 56";
  }
}

export default withStyles(svgIconStyles)(ChartIcon);