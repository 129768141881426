/* OverwriteWriteLockDialog.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as React from "react";
import Log from "../../common/utils/Logger";
import {createStyles, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import {Classifier} from "../../common/utils/ClassifierLogger";
import MetusDialog from "../../common/components/MetusDialog";
import {showDialog} from "../../common/utils/CommonDialogUtil";
import {acquireWriteLock} from "../../modelselection/actions/ModelAsyncActionCreators";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
  open: boolean;
}

interface LocalState {
}

const styles = (theme: Theme): StyleRules => createStyles({
  button: {
    margin: theme.spacing(1),
  },
});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class OverwriteWriteLockDialog extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  getDialogContent(): JSX.Element {
    return <Typography variant={"body2"}>
      Another user has acquired the write lock. Do you want to take it away?
    </Typography>;
  }

  render(): JSX.Element {
    return <MetusDialog
        data-testselector="OverwriteWriteLockDialog"
        title="Overwrite Write Lock?"
        open={this.props.open}
        onClose={this.handleClose}
        primaryButtonName={"Overwrite"}
        onPrimaryButtonPressed={this.handleOverwrite}
        aux1ButtonName={"Cancel"}
        onAux1ButtonPressed={this.handleClose}>
      {this.getDialogContent()}
    </MetusDialog>;
  }

  @autobind
  private handleClose(): void {
    showOverwriteWriteLockDialog(false);
  }

  @autobind
  private handleOverwrite(): void {
    acquireWriteLock(true /*force*/);
    this.handleClose();
  }
}

const StyledOverwriteWriteLockDialog = withStyles(styles)(OverwriteWriteLockDialog);
export default StyledOverwriteWriteLockDialog;

export function showOverwriteWriteLockDialog(display: boolean): void {
  showDialog(display, <StyledOverwriteWriteLockDialog open={display}/>);
}

