/* FormatUtil.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, November 2017
 */
import moment, {Moment} from "moment";
import {AttributeDefinition} from "../../api/api";
import numeral from "numeral";
import {convertMetus2NumeralPattern, FormatPattern, NUMERAL_PATTERN_DECIMAL_DELIMITER} from "./FormatPatternConversion";

export const INVALID_NUMBER_PREFIX: string = "NaN: ";
export const INVALID_DATE_PREFIX: string = "Invalid Date: ";
export const LOCALE_KEY_DISPLAY = "metus-display";
const INTERNAL_DATE_FORMAT_PATTERN: string = "YYYYMMDD";
const LOCALE_KEY_INTERNAL = "metus-internal";

interface FormattedValue {
  displayValue: string;
  prefilledEditValue: string;
}

export function registerLocales(thousandsDelimiter: string = ".", decimalDelimiter: string = ",") {
  const rawLocale: NumeralJSLocale = {
    delimiters: {
      thousands: undefined,
      decimal: undefined,
    },
    abbreviations: {
      thousand: "k",
      million: "m",
      billion: "b",
      trillion: "t",
    },
    ordinal: function (number) {
      return undefined;
    },
    currency: {
      symbol: undefined,
    }
  }

  // this is for parsing purposes
  numeral.register("locale", LOCALE_KEY_INTERNAL, {
    ...rawLocale,
    delimiters: {thousands: "", decimal: NUMERAL_PATTERN_DECIMAL_DELIMITER}
  });

  // this is for display purposes
  numeral.register("locale", LOCALE_KEY_DISPLAY, {
    ...rawLocale,
    delimiters: {thousands: thousandsDelimiter, decimal: decimalDelimiter}
  });
}

/**
 * Validates and transforms the users input into the internal format according to the attributes attributeDefinition.
 * In cases of invalid user input the internal value is simply the user input itself prefixed with an error hint.
 * @param {string} value The user input.
 * @param {AttributeDefinition} attributeDefinition The attributes attributeDefinition.
 * @returns {string} The attributes internal value.
 */
export function calculateInternalValue(value: string | number, attributeDefinition: AttributeDefinition): string {
  let retVal: any = value;

  if (typeof (value) === "number") {
    retVal = value.toString();
  } else if (typeof (value) === "string") {
    if (value !== "") {
      value = value.trim();

      retVal = value;

      if (!isPrefixed(value) && attributeDefinition.formatType === "Double") {
        const decimalDelimiter = numeral.localeData(LOCALE_KEY_DISPLAY).delimiters.decimal;
        const modifiedUserValue = value.replace(decimalDelimiter, NUMERAL_PATTERN_DECIMAL_DELIMITER);
        retVal = validateUserNumber(value) ? convertStringValueToNumber(modifiedUserValue, attributeDefinition).toString() : INVALID_NUMBER_PREFIX + value;
      } else if (!isPrefixed(value) && attributeDefinition.formatType === "Date") {
        const m: Moment = moment(value, [attributeDefinition.pattern.toUpperCase(), moment.ISO_8601, moment.RFC_2822], true);
        retVal = m.isValid() ? m.format(INTERNAL_DATE_FORMAT_PATTERN) : INVALID_DATE_PREFIX + value;
      }
    }
  }

  return retVal;
}

export function calculateInternalConnectionStrength(value: any): string | number {
  let retVal: string | number = value;

  if (value !== "") {
    value = value.trim();
    retVal = value;

    const decimalDelimiter = numeral.localeData(LOCALE_KEY_DISPLAY).delimiters.decimal;
    const modifiedUserValue = value.replace(decimalDelimiter, NUMERAL_PATTERN_DECIMAL_DELIMITER);

    const n = validateInternalNumberAndCreateNumeral(modifiedUserValue);
    if (n !== undefined) {
      retVal = n.value();
    }

  } else {
    retVal = 0;
  }

  return retVal;
}

export function renderDisplayValue(rawValue: string, attributeDefinition: AttributeDefinition): string {
  return formatInternalValue(rawValue, attributeDefinition).displayValue;
}

export function renderPrefilledEditValue(rawValue: string, attributeDefinition: AttributeDefinition): string {
  return formatInternalValue(rawValue, attributeDefinition).prefilledEditValue;
}

export function convertStringValueToNumber(internalValue: string, attributeDefinition: AttributeDefinition): string | number {
  let retVal: string | number = internalValue;

  if ((attributeDefinition.type === "String" || attributeDefinition.type === "Formula") && attributeDefinition.formatType === "Double") {
    const n = validateInternalNumberAndCreateNumeral(internalValue);
    if (n !== undefined) {
      retVal = n.value();
    }
  }

  return retVal;
}

/**
 * Formats an attributes internal value according to the attributes definition.
 * @param {string} rawValue The internal value.
 * @param {AttributeDefinition} attributeDefinition The attributes definition.
 * @returns {FormattedValue} The formatted value for both displaying and editing.
 */
function formatInternalValue(rawValue: string, attributeDefinition: AttributeDefinition): FormattedValue {
  let displayValue: string;
  let prefilledEditValue: string;

  if (rawValue === null || rawValue === undefined) {
    return {displayValue: "", prefilledEditValue: null};
  }

  displayValue = rawValue;

  if ((attributeDefinition.type === "String" || attributeDefinition.type === "Formula") && attributeDefinition.formatType === "Double") {
    const n: Numeral = validateInternalNumberAndCreateNumeral(rawValue);

    if (n !== undefined) {
      /* Set locale for formatting the parsed number. */
      numeral.locale(LOCALE_KEY_DISPLAY);

      displayValue = calculateFormattedValue(attributeDefinition.pattern, n);
      if (attributeDefinition.editPattern) {
        prefilledEditValue = calculateFormattedValue(attributeDefinition.editPattern, n);
      }
    } else {
      if (rawValue.search(INVALID_NUMBER_PREFIX) >= 0) {
        prefilledEditValue = rawValue.substr(INVALID_NUMBER_PREFIX.length);
      }
    }

  } else if (attributeDefinition.formatType === "Date") {
    const {displayDate, valid} = getDisplayDate(rawValue, attributeDefinition.pattern);
    displayValue = displayDate;

    if (!valid) {
      prefilledEditValue = rawValue.substr(INVALID_DATE_PREFIX.length);
    }
  }

  prefilledEditValue = prefilledEditValue ? prefilledEditValue : displayValue;

  return {displayValue, prefilledEditValue};
}

export function calculateFormattedValue(pattern: string, n: Numeral): string {
  const formatPattern: FormatPattern = convertMetus2NumeralPattern(pattern);
  return formatPattern.prefix + n.format(formatPattern.purePattern) + formatPattern.suffix;
}

/**
 * Trys to create a valid date by means of the moment library from the internal string representation.
 * We check for ISO 8601 and RFC 2822 dates.
 * If conversion fails we return the raw value.
 *
 * @param rawDateValue The internal value delivered from the backend
 * @param pattern The format pattern for the value to display
 */
function getDisplayDate(rawDateValue: string, pattern: string): { displayDate: string, valid: boolean } {
  let displayDate = rawDateValue;
  let valid = false;

  let m: Moment = moment(rawDateValue, [moment.ISO_8601, moment.RFC_2822], true);

  if (m.isValid()) {
    displayDate = m.format(pattern.toUpperCase());
    valid = true;
  }

  return {displayDate, valid};
}

/**
 * Checks whether a string matches a number format.
 * @param {string} s The string to check.
 * @returns {boolean}
 */
function validateUserNumber(s: string): boolean {
  const decimalDelimiter = numeral.localeData(LOCALE_KEY_DISPLAY).delimiters.decimal;
  const regExp = new RegExp(`^-?[0-9]+(?:\\${decimalDelimiter}[0-9]+)?$`);
  return regExp.test(s);
}

export function validateInternalNumberAndCreateNumeral(s: string): Numeral {
  let retVal: Numeral;

  if (/^-?[0-9]+(?:\.[0-9]+)?(?:[eE]-?[0-9]+)?$/.test(s)) {
    const internalNumber = checkAndFormatExponentialRepresentation(s);
    /* Set locale for parsing the raw value as a number. */
    numeral.locale(LOCALE_KEY_INTERNAL);
    retVal = numeral(internalNumber);
  }

  return retVal;
}

function checkAndFormatExponentialRepresentation(internalNumber: string): string {
  let retVal = internalNumber;

  internalNumber = internalNumber.toLowerCase();

  let array: string[] = internalNumber.split("e-")

  // string is a number in exponential representation
  if (array.length === 2) {
    retVal = `${array[0]}e-${array[1]}`;
  } else {
    // string is a number in exponential representation
    let array: string[] = internalNumber.split("e")
    if (array.length === 2) {
      retVal = `${array[0]}e+${array[1]}`;
    }
  }

  return retVal;
}

function isPrefixed(value: string): boolean {
  return value.search(INVALID_NUMBER_PREFIX) >= 0 || value.search(INVALID_DATE_PREFIX) >= 0
}


export function toPascalCaseWithSpaces(input: string): string {
  let result = input;

  result = result.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
  if (result.length > 0) {
    result = result[0].toUpperCase() + result.substr(1);
  }
  return result;
}

export interface NumberPatternDescription {
  positivePrefix: string,
  negativePrefix: string,
  suffix: string,
  minIntDigits: number,
  minFractDigits: number,
  useThousandSeparator: boolean,
}

export enum Order {
  "dd/MM/yyyy",
  "MM/dd/yyyy",
  "yyyy/MM/dd"
}

export enum Year {
  "yy",
  "yyyy",
}

export interface DatePatternDescription {
  order: Order,
  year: Year,
  separator: string,
}

export function generateNumberPatternFromNumberPatternDescription(patternDescription: NumberPatternDescription): string {
  let pattern: string = "";
  if (patternDescription.useThousandSeparator) {
    pattern += "#.";
    for (let i = 0; i < 3 - patternDescription.minIntDigits; i++)
      pattern += "#";
  } else {
    pattern += "#";
  }
  for (let i = 0; i < patternDescription.minIntDigits; i++) {
    pattern += "0";
  }
  pattern += ",";
  for (let i = 0; i < patternDescription.minFractDigits; i++) {
    pattern += "0";
  }
  if (patternDescription.minFractDigits === 0) {
    pattern += "###";
  }
  pattern += patternDescription.suffix;
  pattern = patternDescription.positivePrefix + pattern // + ";" + patternDescription.negativePrefix + pattern;
  return pattern;
}

// converts the DatePatternDescription into a Java SimpleDateFormat pattern string
export function generateDatePatternFromDatePatternDescription(datePatternDescription: DatePatternDescription): string {
  let pattern = Order[datePatternDescription.order];
  // replaceAll function is not available in the chromium version of metus embedded, so older functions have to be used to replace the strings
  pattern = pattern.split("/").join(datePatternDescription.separator);
  pattern = pattern.split("yyyy").join(Year[datePatternDescription.year]);
  return pattern;
}
