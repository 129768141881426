/*
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Metus Team
 */
import * as React from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {
  createStyles,
  Accordion,
  Theme,
  withStyles,
  WithStyles,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import {ArrowDropDown} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";

const log = Log.logger("properties");
const renderLog = Log.logger("properties", Classifier.render);

const styles = (theme: Theme):StyleRules => createStyles({
  expansionPanelDetails : {
    flexDirection: "column",
    paddingLeft: 0,
    paddingRight: 0,
  },
  expansionPanelSummary : {
    height: theme.spacing(7) + "px",
    minHeight: theme.spacing(7) + "px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  expansionPanel: {
    backgroundColor: "inherit",
    borderBottom: "1px solid rgba(0,0,0,0.5)"
  },
  expansionPanelExpanded: {
    margin: "0px",
    borderBottom: "1px solid " + theme.metus.properties.iconDivider
  },
  summaryExpanded: {
    minHeight: "6px !important",
  },
});

interface LocalProps {
  id: string;
  summaryComponent: JSX.Element;
  expanded: boolean;
  sectionChangeHandler?: (sectionId: string) => void;

}

interface LocalState {
}


type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class MetusExpansionPanelComponent extends React.PureComponent<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    return <Accordion
        square={true}
        elevation={0}
        defaultExpanded={this.props.expanded}
        onChange={this.handleSectionChange}
        classes={{root: this.props.classes.expansionPanel, expanded: this.props.classes.expansionPanelExpanded}}>
      <AccordionSummary
          expandIcon={<ArrowDropDown/>}
          classes={{root: this.props.classes.expansionPanelSummary, expanded: this.props.classes.summaryExpanded}}>
        {this.props.summaryComponent}
      </AccordionSummary>
      <AccordionDetails classes={{root: this.props.classes.expansionPanelDetails}}>
        {this.props.children}
      </AccordionDetails>
    </Accordion>;
  }

  @autobind
  private handleSectionChange(): void {
    log.debug("AccordionSummary clicked");
    if (this.props.sectionChangeHandler) {
      this.props.sectionChangeHandler(this.props.id);
    }
  }

}

export default withStyles(styles)(MetusExpansionPanelComponent);