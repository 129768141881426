import * as React from "react";

import {Classifier} from "../../../common/utils/ClassifierLogger";
import Log from "../../../common/utils/Logger";
import {createDeepLink} from "../../utils/Util";
import {BaseSectionProperties} from "../../../properties/model/PropertiesModel";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {FileCopy} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";
import PropertiesSectionLayout from "../../../properties/components/PropertiesSectionLayout";
import PropertiesButton from "../../../properties/components/PropertiesButton";
import autobind from "autobind-decorator";
import {ViewId} from "../../../core/utils/Core";
import {observer} from "mobx-react";
import {ModelLocationType} from "../../../common/constants/Enums";

const renderLog = Log.logger("properties", Classifier.render);
const log = Log.logger("properties");

const styles = (theme: Theme): StyleRules => createStyles({
  // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a flash,
  // so some of these are just precautions. However in IE the element
  // is visible whilst the popup box asking the user for permission for
  // the web page to copy to the clipboard.
  //
  copyTextArea: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "2em",
    height: "2em",
    padding: 0,
    border: "none",
    outline: "none",
    boxShadow: "none",
    background: "transparent"
  }
});

export interface ShareActionsProperties extends BaseSectionProperties {
  modelLocation: ModelLocationType;
  cockpitId: ViewId;
  canActivateViewLink: boolean;
  canActivateCockpitLink: boolean;
}

type StyledLocalProps = ShareActionsProperties & WithStyles<typeof styles>;

interface LocalState {
  link?: string;
}

@observer
class ShareActionsPropertiesComponent extends React.Component<StyledLocalProps, LocalState> {
  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    renderLog.debug("Rendering ShareActionsPropertiesComponent");
    const isActiveView = this.props.activeViewId !== null;

    return <PropertiesSectionLayout>
      <PropertiesButton onClick={() => this.copyLink(this.props.activeViewId)}
                        disabled={!isActiveView || !this.props.canActivateViewLink}
                        icon={<FileCopy/>}
                        style={{justifyContent: "left", paddingLeft: "10px"}}
      >Copy View Link</PropertiesButton>

      {this.props.cockpitId !== undefined &&
      <PropertiesButton onClick={() => this.copyLink(this.props.cockpitId)}
                        disabled={!this.props.canActivateCockpitLink}
                        icon={<FileCopy/>}
                        style={{justifyContent: "left", paddingLeft: "10px"}}
      >Copy Cockpit Link</PropertiesButton>
      }

      {this.state.link && <textarea className={this.props.classes.copyTextArea}
                                    ref={ShareActionsPropertiesComponent.setRef}
                                    onCopy={this.onCopy}
                                    defaultValue={this.state.link}/>}
    </PropertiesSectionLayout>;
  }

  @autobind
  private copyLink(viewId: ViewId): void {
    const link = createDeepLink(viewId, this.isInMemoryMode());
    log.debug("Setting state for copying link", link);
    this.setState(state => ({link}));
  }

  @autobind
  private onCopy(event: any): void {
    log.debug("Copying to clipboard the url of the active view", this.state.link);
    event.clipboardData.setData("text/plain", this.state.link);
    event.stopPropagation();
    event.preventDefault();
    this.setState(oldState => ({link: null}));
  }

  private static setRef(newText: any): void {
    if (newText) {
      newText.select();
      // execCommand 'copy' only works during user interaction
      const result = document.execCommand("copy");
      log.debug("Exec Copy Command result:", result);
    }
  }

  private isInMemoryMode(): boolean {
    return this.props.modelLocation === ModelLocationType.memory;
  }
}

export default withStyles(styles)(ShareActionsPropertiesComponent);
