import {DiagramVisualConstants} from "../constants/DiagramVisualConstants";
import {VisualTableId} from "../../core/utils/Core";
import {VisualHeader} from "./VisualHeader";

export class TableHierarchyHeaderModel {
  private _levels: { id: VisualTableId, header: VisualHeader, isFiltered: boolean }[];
  private _width: number;

  constructor(width: number = 200) {
    this._levels = [];
    this._width = width;
  }

  public layout(): void {
    const totalHeight = this.height;
    let headerX: number = 0;
    let headerY: number = DiagramVisualConstants.VC_GAP;
    let headerWidth = this._width;
    this._levels.forEach(level => {
      level.header.x = headerX;
      level.header.y = headerY;
      level.header.height = totalHeight - headerY;
      level.header.width = headerWidth - headerX;
      headerX += DiagramVisualConstants.VC_LEVEL_INSET + DiagramVisualConstants.VC_GAP;
      headerY += DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH * 2 + DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT;
    });
  }

  /**
   * determine full height of model
   */
  public get height() {
    return Math.max(this._levels.reduce((sum, level) => DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH * 2 + DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + sum, 0), DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH * 2 + DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT);
  }

  get size(): number {
    return this._levels.length;
  }

  set width(newWidth: number) {
    this._width = newWidth;
    this.layout();
  }

  get width(): number {
    return this._width;
  }

  addTable(id: VisualTableId, title: string, isFiltered: boolean = false): VisualHeader {
    const index = this._levels.length;
    const header: VisualHeader = new VisualHeader(title, DiagramVisualConstants.VC_LEVEL_INSET * index, DiagramVisualConstants.VC_LEVEL_HEADER_HEIGHT * index, DiagramVisualConstants.DEFAULT_TABLE_WIDTH, DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT);
    this._levels.push({id, header, isFiltered});
    this.layout();
    return header;
  }

  getLevelHeaderAndIds(): { id: VisualTableId, header: VisualHeader }[] {
    return this._levels;
  }

  getLevelHeaderAndId(index: number): { id: VisualTableId, header: VisualHeader, isFiltered:boolean } {
    return this._levels[index];
  }
}
