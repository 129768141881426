// lib imports
import * as React from "react";
import Log from "../../common/utils/Logger";
import {SVGTextBoxComponent} from "../../common/components/SVGTextBoxComponent";
import {isNameAttribute, VisualAttributeId, VisualAttributeIdString} from "../../core/utils/Core";
import {ContextMenuTrigger} from "react-contextmenu";
import * as _ from "lodash";
import EditableTextComponent from "../../common/components/EditableTextComponent";
import {
  ConnectDragPreview,
  ConnectDragSource,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec
} from "react-dnd";
import DragTypes from "../../common/constants/DragTypes";
import {FeedbackHelper} from "../../common/utils/DragDropHelper";
import {DndSourceFeedbackAction} from "../../common/actions/InteractionStateActions";
import {VisualHeader} from "../models/VisualHeader";
import {observer} from "mobx-react";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {DiagramVisualConstants} from "../constants/DiagramVisualConstants";
import {RotationDegree} from "../../api/api";
import {Classifier} from "../../common/utils/ClassifierLogger";
import autobind from "autobind-decorator";
import {SVGClickableIcon} from "../../common/components/iconbase/SVGClickableIcon";
import identity from "lodash/fp/identity";
import {modelStore} from "../../core/stores/ModelStore";
import {getFilterTooltipText} from "../utils/Util";
import {CommonVisualConstants} from "../../common/constants/CommonVisualConstants";
import {ExtendedContextMenuTriggerType} from "../types/ContextMenuInterfaces";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);


/**
 * list of Attribute columns
 * @author Marco van Meegen
 *
 */
interface LocalProps {
  attributeHeader: VisualHeader;
  attributeId: VisualAttributeId;
  onFilterChange?: (value: string) => void;
  onFilterToggle?: () => void;
  filterText: string;
  supportsAtFilter?: boolean;
  filterTextIsValid: boolean;
  attributeHeaderRotation: RotationDegree;
  attributeTitleHeight: number;
  windowIndex?: number;
  drawSeparator: boolean;
  isFilterVisible: boolean;
}

interface DndProps {
  /** injected react-dnd monitor properties */
  connectDragSource?: ConnectDragSource;
  connectDragPreview?: ConnectDragPreview;
  isDragging?: boolean;
}

type LocalAndDndProps = LocalProps & DndProps;

interface LocalState {
  filterText: string;
  filterTextIsValid: boolean;
}

// ContextMenuTrigger props are passed to collect function
function collect(props: LocalAndDndProps): { id: VisualAttributeIdString, disabled: boolean } {
  return {id: props["data-id"], disabled: props["disabled"] || false};
}

@observer
export class AttributeHeaderComponentNoDnd extends React.Component<LocalAndDndProps, LocalState> {
  constructor(props: LocalAndDndProps) {
    super(props);
    this.state = {
      filterText: props.filterText,
      filterTextIsValid: props.filterTextIsValid
    };

    // debounce filter event since it is expensive, MUST BE DONE HERE, DOES NOT WORK INLINE
    this.onFilterChangeDebounced = _.debounce(this.onFilterChangeDebounced, 500).bind(this);
  }

  componentWillReceiveProps(newProps: LocalAndDndProps): void {
    if (newProps.filterText !== this.state.filterText || newProps.filterTextIsValid !== this.state.filterTextIsValid)
      this.setState((state: LocalState) => {
        return {...state, filterText: newProps.filterText, filterTextIsValid: newProps.filterTextIsValid};
      });
  }

  render(): JSX.Element {
    const attributeHeader = this.props.attributeHeader;
    renderLog.debug("Rendering AttributeHeaderComponent", attributeHeader.name);

    const rotation = this.props.attributeHeaderRotation;

    // add filterline if visible
    const filterActive: boolean = !!this.state.filterText;
    const filterHeight = this.props.isFilterVisible ? DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH + DiagramVisualConstants.TABLE_HEADER_FILTER_HEADER_HEIGHT : 0;
    const transform = `translate(${attributeHeader.x},${attributeHeader.y})`;
    // coordinates for text box are calculated in a way they are valid in the rotated coordinate system
    let text;
    let attributeTitleHeight;
    if (rotation === 0) {
      attributeTitleHeight = DiagramVisualConstants.TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT;
      const widthToIcon = Math.max(attributeHeader.width - CommonVisualConstants.TABLE_HEADER_ICON_WIDTH, 0);
      text = <SVGTextBoxComponent text={attributeHeader.name}
                                  style={{...attributeHeader.textStyles, cursor: "move"}}
                                  width={widthToIcon}
                                  height={DiagramVisualConstants.TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT}
                                  wrapLines={true}/>;
    } else {
      // rotation will be around upper left corner of the transformed space
      attributeTitleHeight = this.props.attributeTitleHeight;
      const textTransform = `translate(0, ${attributeTitleHeight}) rotate(-90)`;
      text = <g transform={textTransform}>
        <SVGTextBoxComponent text={attributeHeader.name}
                             style={{...attributeHeader.textStyles, cursor: "move"}}
                             width={attributeTitleHeight}
                             height={attributeHeader.width} wrapLines={true}/>
      </g>;
    }
    // rect fill must be transparent instead of none, because with none events pass through and edit mode not possible
    // on empty text
    const connect = this.props.connectDragSource ? this.props.connectDragSource : identity;
    const filterIconPath = filterActive ? "#icon/filter/filterActive" : "#icon/filter/filterEmpty";

    let filterLine: JSX.Element = undefined;
    if (this.props.isFilterVisible) {
      filterLine = this.renderFilterLine(attributeHeader, attributeTitleHeight, filterActive);
    }

    // rect is needed to size <g> to defined rect, since otherwise it will be only text length and drag drop does only work where text is, fill: none wont work either, thus use transparent
    // holdToDisplay set to -1 otherwise mouse down is not passed to parent
    const separatorHeight = attributeTitleHeight + filterHeight + DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC + 2 * DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH;
    const tableName = modelStore.getTableName(this.props.attributeId.visualTableId.tableId);

    const ContextMenuTriggerWithDisabledProperty = ContextMenuTrigger as ExtendedContextMenuTriggerType<{disabled: boolean}>;
    return connect(
        <g data-testselector={"attribute/" + this.props.attributeHeader.name}>
          <g transform={transform}
             data-testselector={this.props.attributeHeader.name}
             data-attributeid={this.props.attributeId.toKey()}>
            {this.props.drawSeparator ?
                <line x1={attributeHeader.width + 1} y1={1} x2={attributeHeader.width + 1}
                      y2={separatorHeight}
                      style={{stroke: "#808080", strokeWidth: "1px"}}/> : undefined}
            <ContextMenuTriggerWithDisabledProperty
                id={"cm_dg_AttributeHeaderComponent" + (this.props.windowIndex || 0)}
                data-id={this.props.attributeId.toKey()}
                renderTag="g"
                collect={collect}
                holdToDisplay={-1}
                disabled={isNameAttribute(this.props.attributeHeader.name)}
                attributes={{"data-testselector": `contextmenutrigger/attribute/${tableName}/${this.props.attributeId.attributeName}`} as any}>
              <rect width={attributeHeader.width} height={separatorHeight} className="clickableAreaFiller"/>
              <SVGClickableIcon href={filterIconPath}
                                testselector={"filterToggle"}
                                x={attributeHeader.width - CommonVisualConstants.TABLE_HEADER_ICON_WIDTH}
                                onClick={this.toggleAttributeFilter}/>
              {text}
            </ContextMenuTriggerWithDisabledProperty>
          </g>
          {filterLine}
        </g>);
  }

  @autobind updateFilter(value: string): void {
    this.setState((state: LocalState) => {
      return {...state, filterText: value};
    });
    this.onFilterChangeDebounced(value);
  }

  @autobind
  private clearFilter(evt: React.MouseEvent<any>): void {
    this.updateFilter(undefined);
    // vM 20201222 click here should not propagate to text field triggering edit mode
    evt.stopPropagation();
  }

  /**
   * render the filterline under the visual attribute tile
   * @param attributeHeader
   * @param attributeTitleHeight
   * @param filterActive
   */
  private renderFilterLine(attributeHeader: VisualHeader, attributeTitleHeight: number, filterActive: boolean): JSX.Element {
    const filterTransform = `translate(${attributeHeader.x},${attributeHeader.y + attributeTitleHeight + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH})`;
    const widthToIcon = Math.max(attributeHeader.width - CommonVisualConstants.TABLE_HEADER_ICON_WIDTH, 0);
    const filterRectStyle = {
      fill: (this.state.filterTextIsValid ? "#FAFAFA" : "#FF0000")
    };

    const filterLine = <EditableTextComponent onUpdate={this.updateFilter}
                                              updateOnKeyPress={true}
                                              textElement={<g data-testselector="filter" data-tip={getFilterTooltipText(this.props.supportsAtFilter)}
                                                              transform={filterTransform}>
                                                <rect x="1"
                                                      width={attributeHeader.width - (this.props.drawSeparator ? 1 : 2) * DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH}
                                                      height={DiagramVisualConstants.TABLE_HEADER_FILTER_HEADER_HEIGHT}
                                                      style={filterRectStyle}/>
                                                <SVGTextBoxComponent
                                                    text={this.state.filterText}
                                                    width={filterActive ? widthToIcon : attributeHeader.width}
                                                    height={DiagramVisualConstants.TABLE_HEADER_FILTER_HEADER_HEIGHT}
                                                    style={{...attributeHeader.textStyles, fill: "#FAFAFA"}}
                                                    wrapLines={true}/>
                                                {filterActive ?
                                                    <SVGClickableIcon href="#icon/delete_dkl_s"
                                                                      x={attributeHeader.width - CommonVisualConstants.TABLE_HEADER_ICON_WIDTH}
                                                                      onClick={this.clearFilter}/> : undefined}
                                              </g>}
                                              isReplaceTextElement={true}
                                              editOnSingleClick={true}/>;
    return filterLine;
  }

  @autobind
  private toggleAttributeFilter(): void {
    if (this.props.onFilterToggle) {
      this.props.onFilterToggle();
    }
  }

  /**
   * will be debounced in constructor, thus no autobind
   * @param value
   */
  private onFilterChangeDebounced(value: string): void {
    if (this.props.onFilterChange) {
      this.props.onFilterChange(value);
    }
  }
}

const connectDnd = (connect: DragSourceConnector, monitor: DragSourceMonitor): DndProps => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
});

export interface AttributeMovingItem {
  attributeId: VisualAttributeId;
}

const dragAttributeHeaderSourceSpec: DragSourceSpec<LocalProps, AttributeMovingItem> = {
  beginDrag(props: LocalProps, monitor: DragSourceMonitor, component: AttributeHeaderComponentNoDnd): AttributeMovingItem {
    Dispatcher.dispatch(new DndSourceFeedbackAction(FeedbackHelper.createSourceFeedbackBox(component)));
    return {
      attributeId: props.attributeId
    };
  }
};

export const AttributeHeaderComponent = DragSource<LocalAndDndProps>(DragTypes.ATTRIBUTE_COLUMN_MOVE, dragAttributeHeaderSourceSpec, connectDnd)(AttributeHeaderComponentNoDnd);


