import {ActionBase} from "../../common/actions/BaseAction";
import {ElementId, ViewId, VisualTableId} from "../../core/utils/Core";
import {UUID} from "../../api/api";
import {MatrixDisplayMode} from "../models/MatrixModel";
import {ReorderTablesAction} from "../../commonviews/actions/SharedViewActions";

export interface MatrixTableActionPayload {
  visualTableId: VisualTableId;
  index: number;
  isColumn: boolean;
}

export interface UpdateElementFilterActionPayload {
  visualTableId: VisualTableId;
  type: "add" | "remove";
  elementId: ElementId;
}


export class UpdateElementFilterAction extends ActionBase<UpdateElementFilterActionPayload> {
  type: "updateElementFilter" = "updateElementFilter";

  constructor(viewId: ViewId, visualTableId: VisualTableId, type: "add" | "remove", elementId: ElementId, groupId?: UUID) {
    super({visualTableId: visualTableId, elementId, type}, viewId, groupId);
  }
}

export class SaveQuickFilterTextAction extends ActionBase<{ columnName: string, filterText: string}> {
  type: "saveQuickFilterText" = "saveQuickFilterText";

  constructor(viewId: ViewId, columnName: string, filterText: string, groupId?: UUID) {
    super({columnName, filterText}, viewId, groupId);
  }
}

export class UpdateDisplayModeAction extends ActionBase<MatrixDisplayMode> {
  type: "updateDisplayMode" = "updateDisplayMode";

  constructor(viewId: ViewId, displayMode:MatrixDisplayMode, groupId?: UUID) {
    super(displayMode, viewId, groupId);
  }
}

export type ScrollDirection = "next" | "previous";

export class ScrollPageAction extends ActionBase<ScrollDirection> {
  type: "scrollPage" = "scrollPage";

  constructor(viewId: ViewId, scrollDirection:ScrollDirection, groupId?: UUID) {
    super(scrollDirection, viewId, groupId);
  }
}

export class ShowFilterAction extends ActionBase<boolean> {
  type: "showFilter" = "showFilter";

  constructor(viewId: ViewId, showFilter: boolean, groupId?: UUID) {
    super(showFilter, viewId, groupId);
  }
}

export class UpdateMaxNumberOfColumnsPerPageAction extends ActionBase<number> {
  type: "updateMaxNumberOfColumnsPerPage" = "updateMaxNumberOfColumnsPerPage";

  constructor(viewId: ViewId, newMaxNumberOfColumnsPerPage: number, groupId?: UUID) {
    super(newMaxNumberOfColumnsPerPage, viewId, groupId);
  }
}

export class SetWordWrapOnColumnAction extends ActionBase<{columnId: string, isWordWrap:boolean}> {
  type: "setWordWrapOnColumn" = "setWordWrapOnColumn";

  constructor(viewId: ViewId, columnId:string, isWordWrap:boolean, groupId?: UUID) {
    super({columnId, isWordWrap}, viewId, groupId);
  }
}

export type MatrixSyncActions =
    | UpdateElementFilterAction
    | ReorderTablesAction
    | UpdateDisplayModeAction
    | ScrollPageAction
    | SaveQuickFilterTextAction
    | ShowFilterAction
    | UpdateMaxNumberOfColumnsPerPageAction
    | SetWordWrapOnColumnAction;
