// lib imports
import * as React from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {getLevelDefaultColor, getLevelDefaultTextColor} from "../../core/utils/LevelColorUtil";
import {observer} from "mobx-react";
import {TableHierarchyHeaderModel} from "../models/TableHierarchyHeaderModel";
import {ViewContext} from "../contexts/ViewContext";
import {default as ReactDnD, DropTarget} from "react-dnd";
import DragTypes from "../../common/constants/DragTypes";
import {tableHierarchyHeaderComponentDropSpec} from "./TableHierarchyHeaderComponentDropSpec";
import {identity} from "../../common/utils/FunctionUtil";
import {EmptyTableHeaderComponent} from "./EmptyTableHeaderComponent";
import {VisualTableId} from "../../core/utils/Core";
import {VisualHeader} from "../models/VisualHeader";
import {TableColumnHeaderComponentDnd} from "./TableColumnHeaderComponentDndSpec";
import {ViewType} from "../../common/constants/Enums";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

export interface TableHierarchyHeaderComponentProps {
  viewContext: ViewContext;
  /** list of table names and ids to display */
  viewModel: TableHierarchyHeaderModel;
  connectDropTarget?: ReactDnD.ConnectDropTarget;
  isColumnHierarchy: boolean;
}

@observer
export class TableHierarchyHeaderComponentNoDnd extends React.Component<TableHierarchyHeaderComponentProps, any> {
  constructor(props: TableHierarchyHeaderComponentProps) {
    super(props);
  }

  render(): React.ReactNode {
    log.debug("Rendering TableHierarchyHeaderComponent");
    let result: React.ReactElement;
    const connectDropTarget = this.props.connectDropTarget || identity;
    let dragText = "";
    if (this.props.viewContext.viewType === ViewType.StructuredTable) {
      dragText = "Drag Tables here";
    } else {
      dragText = "Drag " + (this.props.isColumnHierarchy ? "Column" : "Row") + " Tables here";
    }
    if (this.props.viewModel.size > 0) {
      result = this.renderNestedHeaders(0, 0);
    } else {
      // no levels available ==> render dummy text
      result = <EmptyTableHeaderComponent text={dragText} width={this.props.viewModel.width}
                                          height={this.props.viewModel.height}/>;
    }
    return connectDropTarget(<g
        data-testselector={"TableHierarchy" + (this.props.isColumnHierarchy ? "Column" : "Row")}>{result}</g>);
  }

  private renderNestedHeaders(levelIndex: number, xOffset: number): React.ReactElement {
    const nestedHeaders = levelIndex < this.props.viewModel.size - 1 ? this.renderNestedHeaders(levelIndex + 1, 0) : undefined;
    const result: React.ReactElement = <TableHierarchyLevelComponent key={"block" + levelIndex}
                                                                     viewContext={this.props.viewContext}
                                                                     viewModel={this.props.viewModel.getLevelHeaderAndId(levelIndex)}
                                                                     isColumnHierarchy={this.props.isColumnHierarchy}
                                                                     index={levelIndex}
                                                                     levelCount={this.props.viewModel.size}>
      {nestedHeaders}
    </TableHierarchyLevelComponent>;
    return result;
  }
}

interface TableHierarchyLevelComponentProps {
  viewContext: ViewContext;
  index: number;
  isColumnHierarchy: boolean;
  levelCount: number;
  viewModel: { id: VisualTableId, header: VisualHeader, isFiltered: boolean };
}

class TableHierarchyLevelComponent extends React.Component<TableHierarchyLevelComponentProps> {
  render(): JSX.Element {
    const {header, id, isFiltered} = this.props.viewModel;
    const index = this.props.index;
    const levelColor = getLevelDefaultColor(index, this.props.levelCount);
    const textColor = getLevelDefaultTextColor(index, this.props.levelCount);
    return <g data-testselector={"table/" + header.name}>
      <TableColumnHeaderComponentDnd viewContext={this.props.viewContext} header={header}
                                     id={id} index={index} backgroundColor={levelColor}
                                     isColumnHierarchy={this.props.isColumnHierarchy} textColor={textColor} isFiltered={isFiltered}/>
      {this.props.children}
    </g>;
  }
}

function collect(connect: ReactDnD.DropTargetConnector, monitor: ReactDnD.DropTargetMonitor): Object {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
  };
}

export const TableHierarchyHeaderComponent = DropTarget([DragTypes.CORE_TABLE, DragTypes.VISUAL_TABLE_COLUMN_MOVE], tableHierarchyHeaderComponentDropSpec, collect)(TableHierarchyHeaderComponentNoDnd);
