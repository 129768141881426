/* Core.ts.<extension>
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by MeegenM, Mai 2017
 */

import {AttributeFormatType, BaseAttributeDefinition, NAME_ATT_NAME} from "../../api/api";
import {object, serializable} from "serializr";

let mocked: boolean = false;
const idseparator: string = "#";

/** generate mostly unique ids for various usages */
export function generateId(): string {
  let idstr;
  if (!mocked) {
    // TODO use IdGenerator here for implementation without including a dependency here
    // always start with a letter (for DOM friendlyness)
    idstr = String.fromCharCode(Math.floor((Math.random() * 25) + 65));
    do {
      // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
      const ascicode = Math.floor((Math.random() * 42) + 48);
      if (ascicode < 58 || ascicode > 64) {
        // exclude all chars between : (58) and @ (64)
        idstr += String.fromCharCode(ascicode);
      }
    } while (idstr.length < 32);

  } else {
    idstr = "MOCKED_GID";
  }
  return (idstr);
}

export function mockGenerateId(value: boolean): void {
  mocked = value;
}

/** string representation of attribute id of form tableId<idseparator>attributeName */
export type AttributeIdString = string;
/** string consisting of node id + "." + attribute name */
export type AttributeValueIdString = string;
export type VisualAttributeIdString = string;
/** TODO: use uid currently server artificial id is used as id; ATTENTION: used as Map key, thus must be string */
export type ElementId = string;
/** Table UUID, used as map key */
export type TableId = string;
/** View UUID */
export type ViewId = string;
export type FolderId = string;
export type VisualId = string;
/** ATTENTION: used as Map key, thus must be string */
export type VisualTableIdString = string;

/** tables can appear more than once in a diagram, thus a visual id is needed to address correct visualization */
export class VisualTableId {
  private static NO_VISUAL_ID: string = "NO_VISUAL_ID";
  @serializable tableId: TableId;
  @serializable visualId: string;

  constructor(tableId: TableId, visualId: string = null) {
    this.tableId = tableId;
    this.visualId = visualId != null ? visualId : generateId();
  }

  toKey(): string {
    return this.tableId + idseparator + this.visualId;
  }

  hasNoVisualId(): boolean {
    return this.visualId === VisualTableId.NO_VISUAL_ID;
  }

  static fromKey(key: string): VisualTableId {
    if (key === null) {
      return null;
    }
    const [tableId, visualId]: string[] = key.split(idseparator);
    return new VisualTableId(tableId, visualId);
  }

  static noVisualId(tableId: TableId): VisualTableId {
    return new VisualTableId(tableId, VisualTableId.NO_VISUAL_ID);
  }
}

export class AttributeId {
  @serializable tableId: TableId;
  @serializable attributeName: string;

  constructor(table: TableId, attributeName: string) {
    this.tableId = table;
    this.attributeName = attributeName;
  }

  toKey(): AttributeIdString {
    return this.attributeName + idseparator + this.tableId;
  }

  static fromKey(key: AttributeIdString): AttributeId {
    if (key === undefined) {
      return undefined;
    }
    const [attributeName, tableId]: string[] = key.split(idseparator);
    return new AttributeId(tableId, attributeName);
  }
}

export class VisualAttributeId {
  @serializable(object(VisualTableId)) visualTableId: VisualTableId;
  @serializable attributeName: string;

  constructor(visualTable: VisualTableId, attributeName: string) {
    this.visualTableId = visualTable;
    this.attributeName = attributeName;
  }

  static noVisualId(tableId: TableId, attributeName: string): VisualAttributeId {
    return new VisualAttributeId(VisualTableId.noVisualId(tableId), attributeName);
  }

  //noinspection JSUnusedGlobalSymbols
  toAttributeId(): AttributeId {
    return new AttributeId(this.visualTableId.tableId, this.attributeName);
  }

  toKey(): VisualAttributeIdString {
    return this.attributeName + idseparator + this.visualTableId.toKey();
  }

  static fromKey(key: VisualAttributeIdString): VisualAttributeId {
    if (key === null) {
      return null;
    }
    const [attributeName, tableId, visualId]: string[] = key.split(idseparator);
    return new VisualAttributeId(new VisualTableId(tableId, visualId), attributeName);
  }
}

export function isVisualIdObject(visualId): visualId is VisualAttributeId | VisualTableId | VisualElementId {
  return (visualId as VisualAttributeId).toKey !== undefined;
}

export class AttributeValueId {
  elementId: ElementId;
  attributeName: string;

  constructor(elementId: ElementId, attributeName: string) {
    this.elementId = elementId;
    this.attributeName = attributeName;
  }

  toKey(): AttributeValueIdString {
    return this.attributeName + idseparator + this.elementId;
  }

  static fromKey(key: AttributeValueIdString): AttributeValueId {
    if (key === null) {
      return null;
    }
    const [attributeName, tableId]: string[] = key.split(idseparator);
    return new AttributeValueId(tableId, attributeName);
  }

}

export interface ElementObject {
  id: ElementId;

  // <att> other att values
  [attributeName: string]: any;
}

export interface ElementValues {
  [attributeName: string]: any;
}

/** nodes can appear more than once in a diagram, thus a visual node id is needed to address correct visualization */
export class VisualElementId {
  @serializable visualId: string;
  @serializable elementId: ElementId;

  /**
   *
   * @param elementId id of core element
   * @param visualId visual id to differentiate between different visualisation of same core element, if null, a unique id is generated
   */
  constructor(elementId: ElementId, visualId: string = null) {
    this.elementId = elementId;
    this.visualId = visualId != null ? visualId : generateId();
  }

  toKey(): VisualElementIdString {
    return this.visualId + idseparator + this.elementId;
  }

  static fromKey(key: VisualElementIdString): VisualElementId {
    if (key === null) {
      return null;
    }
    const [visualId, elementId]: string[] = key.split(idseparator);
    return new VisualElementId(elementId, visualId);
  }

  equals(vid: VisualElementId): boolean {
    return this.visualId === vid.visualId && this.elementId === vid.elementId;
  }
}

/** ATTENTION: used as Map key, thus must be string */
export type VisualElementIdString = string;

/**
 * @param {VisualTableId | VisualElementId | VisualId} id
 * @returns {VisualId} the visual id part of specialized visual ids having additional information
 */
export function visualId(id: VisualTableId | VisualElementId | VisualId | VisualAttributeId): VisualId {
  if (id.hasOwnProperty("visualId")) {
    return id["visualId"];
  } else if (id instanceof VisualAttributeId) {
    return id.visualTableId.visualId;
  } else {
    return id as VisualId;
  }
}

/** string of shape: <source id> -> <target id> */
export type EdgeId = string;

export enum SelectionKind {
  None, Primary, Secondary
}


export type ViewAction = "CreateOnServer" | "SaveAndDelete" | "TransformClassicToWeb";
export type AttributeType =
    "String"
    | "Memo" // action/assignment
    | "Link" // action/language
    | "Image" // image/image
    | "Attachment" // file/attachment
    | "Derived" // editor/wrap-text
    | "Formula";

/** meta information about this attribute */
export class AttributeDefinitionImpl implements BaseAttributeDefinition {
  public type: AttributeType;
  public formatType: AttributeFormatType;
  public pattern: string;
  public editPattern: string;
  public editable: boolean;

  /**
   * attribute meta information
   * @param type metus attribute type (number, date do not exist, but are defined by the format type
   * @param formatType defines if string, number or date
   * @param pattern pattern for date or string while displaying
   * @param editable true, if user may edit this attribute's values
   * @param editPattern pattern for date or string when editing
   */
  constructor(type: AttributeType, formatType: AttributeFormatType, pattern: string, editable: boolean, editPattern: string = undefined) {
    this.type = type;
    this.formatType = formatType;
    this.pattern = pattern;
    this.editable = editable;
    this.editPattern = editPattern;
  }
}

export function isNameAttribute(attName: string): boolean {
  return attName === NAME_ATT_NAME;
}

export type Attribute = {
  name: string;
  value: string;
};