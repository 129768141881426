import * as React from "react";

/**
 * component containing a collection of svg icons which can be referenced using their id
 * Usage:
 * <use href={#filter.id} x={x} width={width} height={height}/>
 */
export class SVGEmbeddedIcons extends React.Component<{}, undefined> {
  constructor(props: undefined) {
    super(props);
  }

  render(): JSX.Element {
    return <defs>
      <g id="icon/filter/filterActive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon id="Fill-1" fill="#3A9BFC" points="6 7 11 11.999 11 18 13 16 13 11.999 18 7"></polygon>
      </g>
      <g id="icon/filter/filterEmpty" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.8">
        <polygon id="Fill-1-Copy" fill="#000000" points="6 7 11 11.999 11 18 13 16 13 11.999 18 7"></polygon>
      </g>
      <g id="icon/options/options" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.5">
        <path
            d="M10.5,17 C10.5,17.828 11.172,18.5 12,18.5 C12.828,18.5 13.5,17.828 13.5,17 C13.5,16.172 12.828,15.5 12,15.5 C11.172,15.5 10.5,16.172 10.5,17 Z M12,10.5 C11.172,10.5 10.5,11.172 10.5,12 C10.5,12.828 11.172,13.5 12,13.5 C12.828,13.5 13.5,12.828 13.5,12 C13.5,11.172 12.828,10.5 12,10.5 M13.5,7 C13.5,7.828 12.828,8.5 12,8.5 C11.172,8.5 10.5,7.828 10.5,7 C10.5,6.172 11.172,5.5 12,5.5 C12.828,5.5 13.5,6.172 13.5,7"
            id="Fill-6" fill="#000000"></path>
      </g>
      <g id="icon/table/tableHeader" stroke="black" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.5">
        <path
            d="M11,8 L19,8 L19,6 L11,6 L11,8 Z M5,8 L9,8 L9,6 L5,6 L5,8 Z M11,13 L19,13 L19,11 L11,11 L11,13 Z M5,13 L9,13 L9,11 L5,11 L5,13 Z M11,18 L19,18 L19,16 L11,16 L11,18 Z M5,18 L9,18 L9,16 L5,16 L5,18 Z"
            id="Fill-1-Copy" fill="#FFFFFF"></path>
      </g>
      <g id="icon/delete_dkl_s" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.5">
        <polygon id="Fill-1" fill="#000000"
                 points="16.629 6.65 11.993 11.286 7.357 6.65 6.65 7.357 11.286 11.993 6.65 16.629 7.357 17.337 11.993 12.701 16.629 17.337 17.336 16.629 12.701 11.993 17.336 7.357"></polygon>
      </g>
    </defs>;
  }
}
