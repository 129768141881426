import Action, {ActionBase} from "../../common/actions/BaseAction";
import {ServerWritingAction} from "../../core/actions/CoreActions";
import {observable} from "mobx";


/**
 * dummy action to group actions which must be undone/redone as atomic unit, e.g. loading of a view with several async server loadings,
 * only used internally by UndoStore
 */
export class ActionGroup extends ActionBase<Action[]> implements ServerWritingAction {
  type: "action_group" = "action_group";
  @observable public actions: Action[];

  constructor(action: Action, ...actions: Action[]) {
    /* Action groups are recordable but not undoable. */
    super(null, undefined, action.groupId, true, undefined, true /*isCompleteUndoStep*/);
    this.actions = [action, ...actions];
  }

  public push(action: Action): void {
    this.actions.push(action);
  }

  public get commandId(): string {
    const firstServerWritingAction: ServerWritingAction = this.actions && this.actions.find(a => (a as any as ServerWritingAction).commandId) as any as ServerWritingAction;
    return firstServerWritingAction ? firstServerWritingAction.commandId : undefined;
  }

  public set commandId(newValue: string) {
    // no op
  }

  /**
   * a group is only undoable if all members are
   */
  public get undoable(): boolean {
    let result: boolean = true;
    this.actions.forEach(action => result = result && action.undoable);
    return result;
  }
}