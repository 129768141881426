import {VisualAttributeDefinition} from "./VisualAttributeDefinition";
import {VisualHeader} from "../../../commonviews/models/VisualHeader";
import {Direction, DirectionHelper} from "../../../common/utils/Direction";
import {SelectionKind, VisualAttributeId, VisualElementId, VisualTableId} from "../../../core/utils/Core";
import {IBoundedVisual, VisualObject} from "../VisualObject";
import {VisualBaseAttributeValue} from "./VisualBaseAttributeValue";
import {CSSProperties} from "react";
import {ContainerColumnLayout} from "./ContainerColumnLayout";
import {RotationDegree} from "../../../api/api";
import {Validate} from "../../../common/utils/Validate";

/**
 * interface unifies value chart level and chart columns
 */
export interface VisualBaseTable {
  id: VisualTableId;
  visualAttributeDefinitions: Map<string, VisualAttributeDefinition>;
  attributeHeaderRotation: RotationDegree;
  attributeHeaderHeight: number;
  attributeColumnLayout: ContainerColumnLayout;

  /**
   * TODO: rename to getVisualAttributeDefinition
   * @param attributeName
   */
  getAttribute(attributeName: string): VisualAttributeDefinition;

  allAttributeNames: string[];
  updateAttributeColumn(visualAttributeId: VisualAttributeId, dx: number, dy: number): void;

  header: VisualHeader;

  resizeTable(d: Direction, dx: number): void;
  readonly elements: (VisualBaseElement)[];
  isFilterVisible: boolean;

  /**
   * toggle visibility of filter line in visual table header
   * @return visibility after toggle, true = visible
   */
  toggleFilterLine(): boolean;
}

/**
 * type guard for visualization of tables
 * @param object
 */
export function isVisualBaseTable(object: any): object is VisualBaseTable {
  return object.id !== undefined && object.visualAttributeDefinitions !== undefined && object.header !== undefined;
}

export interface VisualBaseElement extends VisualObject<VisualElementId>, IBoundedVisual {
  title: string;
  selection: SelectionKind;
  x: number;
  y: number;
  width: number;
  height: number;
  visualTable: VisualBaseTable;
  attributeValues: Map<string, VisualBaseAttributeValue>;
  styles: CSSProperties;
  /** determines if element is filtered and thus not visible */
  visible: boolean;
  readonly rectStyles: CSSProperties;
  readonly textStyles: CSSProperties;
}

/**
 * type guard for visual elements
 * @param object
 */
export function isVisualBaseElement(object: any): object is VisualBaseElement {
  return object.id !== undefined && object.visualTable !== undefined && object.attributeValues !== undefined;
}

export function resizeAttributeInTable(table: VisualBaseTable, visualId: VisualAttributeId, d: Direction, dx: number, dy: number): void {
  const attribute = table.getAttribute(visualId.attributeName);
  Validate.notNull(attribute);
  const header = attribute.header;
  const resized = DirectionHelper.resize(d, dx, dy, header.x, header.y, header.width, header.height);
  const deltaX = resized.x - header.x;
  // header.x = resized.x;
  const deltaY = resized.y - header.y;
  // header.y = resized.y;
  header.width = Math.max(resized.width, 5);
  header.height = Math.max(resized.height, 5);
  table.updateAttributeColumn(visualId, deltaX, deltaY);
}


