/**
 * Created by MeegenM on 06.02.2017.
 */
// lib imports
import React, {CSSProperties} from "react";
import {TextWrappingHelper, TruncationStyle} from "../utils/TextWrappingHelper";
import {observer} from "mobx-react";
// HACK to prevent problem with inconsistent module default behavior
// see https://github.com/alexreardon/memoize-one/issues/37#issuecomment-439392141
import memoizeOne from "memoize-one";
import _ from "lodash";


/**
 * SVG Text contained in a box and auto-wrapping and truncating with Ellipses. Features:
 * <ul>
 *   <li>supports forced line breaks using \n and/or \r in text</li>
 *   <li>Text can be horizontally left/center/right aligned</li>
 *   <li>Text can be vertically top/center/bottom aligned</li>
 *   <li>If text does not fit in the box, it will be truncated using ellipsis</li>
 *   <li>Truncation can be done at the end of text or in the middle</li>
 * </ul>
 * @author Marco van Meegen
 *
 */

interface LocalProps {
  text: string;
  width: number;
  height: number;
  x?: number;
  y?: number;
  truncation?: TruncationStyle;
  style?: CSSProperties;
  /**
   * if true, lines are wrapped automatically, EXPENSIVE CALCULATAION !
   */
  wrapLines: boolean;
}

interface LineSplitInfo {
  lines: string[];
  svgTextStyle: any;
  xOffset: number;
  yOffset: number;
  lineHeight: number;
}

const SIDE_MARGIN: number = 6;
const TOP_MARGIN: number = 1;

function splitLines(text: string, width: number, height: number, truncation: TruncationStyle, style: CSSProperties): LineSplitInfo {
  const textWrappingHelper = new TextWrappingHelper(text, width, height, SIDE_MARGIN, TOP_MARGIN, truncation, style);
  // console.log("splitLines called with " + text);
  return {
    lines: textWrappingHelper.wrapLines(),
    svgTextStyle: textWrappingHelper.calculatedTextStyle,
    xOffset: textWrappingHelper.calculatedOffsets().xOffset,
    yOffset: textWrappingHelper.calculatedOffsets().yOffset,
    lineHeight: textWrappingHelper.lineHeight
  };
}

@observer
export class SVGTextBoxComponent extends React.Component<LocalProps, any> {

  constructor(props: LocalProps) {
    super(props);
  }

  private memoizedSplitLines: (text: string, width: number, height: number, truncation: TruncationStyle, style: CSSProperties) => LineSplitInfo = memoizeOne(splitLines, _.isEqual);

  render(): JSX.Element {
    // console.log("SVGTextBoxComponent rendered: " + this.props.text);
    let result: JSX.Element;
    const contentAvailableWidth = this.props.width - 2 * SIDE_MARGIN;
    const contentAvailableHeight = this.props.height - 2 * TOP_MARGIN;
    if (this.props.wrapLines) {
      const splitInfo: LineSplitInfo = this.memoizedSplitLines(this.props.text, this.props.width, this.props.height, this.props.truncation, this.props.style);
      const xOffset: number = (this.props.x || 0) + splitInfo.xOffset;
      const yOffset: number = (this.props.y || 0) + splitInfo.yOffset;
      result = <text style={splitInfo.svgTextStyle} width={contentAvailableWidth + "px"}
                     height={contentAvailableHeight + "px"} data-tip={this.props.text}>
        {splitInfo.lines.map((lineContent, index) => (
            <tspan key={index} x={xOffset} y={`${(index + 1) * splitInfo.lineHeight + yOffset}px`}>
              {lineContent}
            </tspan>
        ))}
      </text>;
    } else {
      // text width calculation for line break is very expensive, so in easy cases avoid it; this doubles the performance opening Performance_XXL 07_.._Product_structure
      let xOffset: number = (this.props.x || 0) + SIDE_MARGIN;

      if(this.props.style && this.props.style.textAlign === "right") {
        // aligned content doesn't need text width but box width, which doesn't need calculation
        // text anchor = right is set later
        xOffset = this.props.width - SIDE_MARGIN;
      } else if (this.props.style && this.props.style.textAlign === "center") {
        xOffset = this.props.width / 2 + SIDE_MARGIN;
      }

      const yOffset: number = (this.props.y || 0) + this.props.height / 2;
      // vM clipping text in svg is nearly impossible, you would need clipping paths with unique id or url-encoded inline clippaths which do not work in all browsers; a nested svg is very simple and effective
      // see https://stackoverflow.com/questions/6691674/how-can-i-limit-or-clip-text-in-svg
      result = <svg x={0} y={0} width={this.props.width} height={this.props.height}>
        <text style={TextWrappingHelper.convertTextStyle(this.props.style, true)} x={xOffset} y={yOffset}
              width={contentAvailableWidth + "px"}
              height={contentAvailableHeight + "px"}
              data-tip={this.props.text}>
          {this.props.text}
        </text>
      </svg>;

    }
    return result;
  }
}

