/* FormatPatternConversion.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, April 2020
 */

export const NUMERAL_PATTERN_DECIMAL_DELIMITER = ".";
const NUMERAL_PATTERN_THOUSANDS_DELIMITER = ",0";
const MD_PATTERN_DECIMAL_DELIMITER = ",";
const MD_PATTERN_THOUSANDS_DELIMITER = ".";

export interface FormatPattern {
  purePattern: string,
  prefix: string,
  suffix: string
}

export function convertMetus2NumeralPattern(metusPattern: string): FormatPattern {
  const prefixAndPattern = calculatePrefix(metusPattern.trim());
  let prefix = prefixAndPattern.prefix;
  let {suffix, pattern} = calculateSuffix(prefixAndPattern.pattern);

  /* Thousands delimiter pattern has to be converted from '#.xxx' to 'xxx,0' */
  let {numeralThousandsDelimiterPattern, postMetusThousandsDelimiterPattern} = splitByMetusThousandsDelimiter(pattern);

  const purePattern = splitByDecimalDelimiter(postMetusThousandsDelimiterPattern, numeralThousandsDelimiterPattern);

  return {prefix, purePattern, suffix};
}

export function calculatePrefix(pattern: string): { prefix: string, pattern: string } {
  let retVal = {prefix: undefined, pattern};

  const index = calculateMinIndex(pattern.indexOf("#"), pattern.indexOf("0"));
  retVal.prefix = pattern.substring(0, index);
  retVal.pattern = pattern.substring(index);

  return retVal;
}

export function calculateSuffix(pattern: string): { suffix: string, pattern: string } {
  let retVal = {suffix: undefined, pattern};

  if (pattern.endsWith("%")) {
    retVal.pattern = pattern;
    retVal.suffix = "";
  } else {
    const index = calculateMaxIndex(pattern.lastIndexOf("#"), pattern.lastIndexOf("0"));
    retVal.pattern = pattern.substring(0, index + 1);
    retVal.suffix = pattern.substring(index + 1);
  }

  return retVal;
}

function splitByDecimalDelimiter(postMetusThousandsDelimiterPattern: string, numeralThousandsDelimiterPattern: string): string {
  const patternArray = postMetusThousandsDelimiterPattern.split(MD_PATTERN_DECIMAL_DELIMITER);

  let preThousandsDelimiterPattern;
  if (patternArray[0].match(/^#+$/g)) {
    preThousandsDelimiterPattern = "0";
  } else {
    preThousandsDelimiterPattern = replaceAllHashes(patternArray[0], "");
  }

  let retVal = preThousandsDelimiterPattern + numeralThousandsDelimiterPattern;

  let decimalPlaces = patternArray[1];
  if (decimalPlaces) {
    if (decimalPlaces.startsWith("#")) {
      retVal += "[" + NUMERAL_PATTERN_DECIMAL_DELIMITER + "]" + setSquareBrackets(decimalPlaces);
    } else {
      retVal += NUMERAL_PATTERN_DECIMAL_DELIMITER + setSquareBrackets(decimalPlaces);
    }
    retVal = replaceAllHashes(retVal, "0");
  }

  return retVal;
}

function splitByMetusThousandsDelimiter(metusPattern: string): { numeralThousandsDelimiterPattern: string, postMetusThousandsDelimiterPattern: string } {
  let retVal = {numeralThousandsDelimiterPattern: "", postMetusThousandsDelimiterPattern: metusPattern};

  const metusPatternArray = metusPattern.split(MD_PATTERN_THOUSANDS_DELIMITER);
  if (metusPatternArray[1] !== undefined && metusPatternArray[1] !== null) {
    retVal = {
      numeralThousandsDelimiterPattern: NUMERAL_PATTERN_THOUSANDS_DELIMITER,
      postMetusThousandsDelimiterPattern: metusPatternArray[1]
    };
  }

  return retVal;
}

function setSquareBrackets(decimalPlaces: string): string {
  let retVal = decimalPlaces;

  const index = decimalPlaces.indexOf("#");
  if (index > -1) {
    const part1 = decimalPlaces.slice(0, index);
    const part2 = decimalPlaces.slice(index);
    retVal = part1 + "[" + part2 + "]";
  }

  return retVal;
}

function replaceAllHashes(pattern: string, replaceValue: string): string {
  let retVal = pattern;
  while (retVal.indexOf("#") > -1) {
    retVal = retVal.replace("#", replaceValue);
  }
  return retVal;
}

function calculateMinIndex(indexHash: number, indexZero: number): number {
  let retVal: number;
  if (indexHash > -1 && indexZero > -1) {
    retVal = Math.min(indexHash, indexZero);
  } else if (indexHash < -1 && indexZero < -1) {
    throw new Error("Unknown format pattern.")
  } else {
    retVal = indexHash > -1 ? indexHash : indexZero;
  }
  return retVal;
}

function calculateMaxIndex(index1: number, index2: number): number {
  let retVal: number;
  if (!(index1 < -1 && index2 < -1)) {
    retVal = Math.max(index1, index2);
  } else {
    throw new Error("Unknown format pattern.")
  }
  return retVal;
}
