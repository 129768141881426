import * as React from "react";
import {SubMenu} from "react-contextmenu";
import {AttributeDefinition, NAME_ATT_NAME} from "../../api/api";
import {ViewId, VisualAttributeId, VisualTableId} from "../../core/utils/Core";
import autobind from "autobind-decorator";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {MetusCheckbox} from "../../common/components/MetusCheckboxComponent";
import Log from "../../common/utils/Logger";
import {modelStore} from "../../core/stores/ModelStore";
import {observer} from "mobx-react";
import {compareAttributeDefinitions} from "../../common/utils/CompareAttributeDefinitions";

interface AttributeSelectionSubMenuComponentProps {
  viewId: ViewId;
  visualTableId: VisualTableId;
  visualAttributeDefinitionIds: VisualAttributeId[];
  onAddAttribute: (viewId: ViewId, visualAttributeId: VisualAttributeId) => void;
  onRemoveAttribute: (viewId: ViewId, visualAttributeId: VisualAttributeId) => void;
}

const log = Log.logger("TreeGridComponent");

/**
 * displays a menu of all attributes of a given table, the visualAttributeDefinitions passed are checked.
 * Changing check state will add/remove the attribute to/from view
 */
@observer
export class AttributeSelectionSubMenuComponent extends React.Component<AttributeSelectionSubMenuComponentProps> {
  constructor(props: AttributeSelectionSubMenuComponentProps) {
    super(props);
  }

  render(): JSX.Element {
    const sortedAttributeDefinitions = Array.from(modelStore.getTableAttributeDefinitions([this.props.visualTableId.tableId])[0].attributes).sort(compareAttributeDefinitions);
    return (<SubMenu title={<span>Remove/Add attributes&nbsp;&nbsp;</span>}>
      <List style={{"maxHeight": 400, "overflow": "auto"}}>
        {sortedAttributeDefinitions.map((attributeDefinition: AttributeDefinition) =>
            <AttributeSelectionMenuItemComponent
                key={this.props.visualTableId.toKey() + attributeDefinition.name}
                attributeName={attributeDefinition.name}
                checked={this.getVisualAttributeId(attributeDefinition.name) !== undefined}
                onSelectionChanged={this.onSelectionChanged}
            />
        )}
      </List>
    </SubMenu>);
  }

  private getVisualAttributeId(attributeName: string): VisualAttributeId {
    return this.props.visualAttributeDefinitionIds.find(vattId => vattId.attributeName === attributeName);
  }

  @autobind
  private onSelectionChanged(attributeName: string, checked: boolean): void {
    let visualAttributeId: VisualAttributeId;
    visualAttributeId = new VisualAttributeId(this.props.visualTableId, attributeName);
    if (!this.getVisualAttributeId(attributeName)) {
      this.props.onAddAttribute(this.props.viewId, visualAttributeId);
    } else {
      this.props.onRemoveAttribute(this.props.viewId, visualAttributeId);
    }
  }
}

interface AttributeSelectionMenuItemComponentProps {
  attributeName: string;
  checked: boolean;
  /**
   * called when a menu item is checked or unchecked
   * @param attName
   * @param checked
   */
  onSelectionChanged(attName: string, checked: boolean): void;
}

@observer
class AttributeSelectionMenuItemComponent extends React.Component<AttributeSelectionMenuItemComponentProps> {
  constructor(props: AttributeSelectionMenuItemComponentProps) {
    super(props);
  }

  render(): JSX.Element {
    const name = this.props.attributeName;
    const fittingAttributeName = name.length > 23 ? name.substring(0, 9) + " ... " + name.substring(name.length - 9, name.length) : name;
    return (
        <ListItem>
          <FormControlLabel
              label={fittingAttributeName}
              value={name}
              checked={this.props.checked}
              control={<MetusCheckbox value={this.props.attributeName}/>}
              onChange={(ev: React.FormEvent<HTMLInputElement>, checked: boolean): void => this.props.onSelectionChanged(ev.currentTarget.value, checked)}
              disabled={this.props.attributeName === NAME_ATT_NAME}
              data-testselector={"AttributeSelectionMenuItemComponent/" + this.props.attributeName + "/"}
          />
        </ListItem>
    );
  }
}