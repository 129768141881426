import * as React from "react";
import Log from "../utils/Logger";
import {Classifier} from "../utils/ClassifierLogger";
import {Button, createStyles, Dialog, IconButton, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {Cancel, Close} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

const styles = (theme: Theme): StyleRules => createStyles({
  dialogPaper: {
    padding: "20px 35px",
    backgroundColor: theme.metus.dialog.fill,
    color: theme.metus.dialog.secondaryText,
  },
  typographyTitle: {
    fontSize: "20px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 32, // see marginBottom of header
  },
  buttonContained: {
    width: "auto",
    minWidth: "140px",
    backgroundColor: theme.metus.dialog.buttonFill,
    height: "auto",
    marginLeft: "auto", // align to the right if there is only one button
  },
  buttonOutlined: {
    width: "auto",
    border: "2px solid " + theme.metus.dialog.buttonFill,
    color: theme.metus.dialog.buttonFill,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    color: theme.metus.dialog.primaryText,
    marginBottom: 32, // see marginTop of buttons
  },
  closeIconButton: {
    padding: 0,
  },
  closeIcon: {
    height: "24px",
    width: "24px",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderRadius: "4px",
    marginTop: 32,
    paddingTop: 8,
    paddingBottom: 8,
  },
  cancelIconContainer: {
    alignSelf: "center",
    paddingLeft: "16px",
  },
  cancelIcon: {
    height: "40px",
    width: "40px",
  },
  errorTextContainer: {
    alignSelf: "center",
    paddingLeft: "20px",
  },
  typographyErrorText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "inherit",
  },
});

interface LocalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  errorMessage?: string;
  primaryButtonName?: string; // default is OK
  onPrimaryButtonPressed?: () => void;
  aux1ButtonName?: string;
  onAux1ButtonPressed?: () => void;
  aux2ButtonName?: string;
  onAux2ButtonPressed?: () => void;
  headerColor?: string;
  primaryButtonDisabled?:boolean;
  additionalButtonBarEntry?:React.ReactElement;
}

interface LocalState {
}


type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class MetusDialog extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering MetusDialog", this.props);
    const color = this.props.headerColor ? this.props.headerColor : "#FFFFFF";
    return <Dialog
        data-testselector={this.props["data-testselector"]}
        classes={{paper: this.props.classes.dialogPaper}}
        disableBackdropClick={true}
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby={"alert-dialog-title"}
        aria-describedby={"alert-dialog-description"}>

      {/* Close button */}
      <div className={this.props.classes.header} style={{backgroundColor: `${color}`}}>
        <Typography className={this.props.classes.typographyTitle}>{this.props.title}</Typography>
        <IconButton onClick={this.props.onClose}
                    data-testselector="closeDialogButton"
                    classes={{root: this.props.classes.closeIconButton}}>
          <Close classes={{root: this.props.classes.closeIcon}}/>
        </IconButton>
      </div>

      {/* Content */}
      {this.props.children}

      {/* Actions */}
      <div className={this.props.classes.buttons}>
        {this.props.additionalButtonBarEntry}
        {this.props.aux1ButtonName &&
        <Button classes={{outlined: this.props.classes.buttonOutlined}}
                variant={"outlined"}
                onClick={(event): void => {
                  if (this.props.onAux1ButtonPressed) {
                    this.props.onAux1ButtonPressed();
                  }
                }}
                >{this.props.aux1ButtonName}</Button>}

        {this.props.aux2ButtonName &&
        <Button classes={{outlined: this.props.classes.buttonOutlined}}
                style={{marginLeft: 5}}
                variant={"outlined"}
                onClick={(event): void => {
                  if (this.props.onAux2ButtonPressed) {
                    this.props.onAux2ButtonPressed();
                  }
                }}>{this.props.aux2ButtonName}</Button>}

        {this.props.onPrimaryButtonPressed && <Button
            data-testselector={"SubmitButton"}
            classes={{contained: this.props.classes.buttonContained}}
            variant={"contained"}
            type="button"
            onClick={(event): void => this.props.onPrimaryButtonPressed()}
            disabled={this.props.primaryButtonDisabled}>{this.props.primaryButtonName || "OK"}</Button>}
      </div>

      {/* Error footer */}
      {this.props.errorMessage &&
      <div
          className={this.props.classes.footer}>
        <div className={this.props.classes.cancelIconContainer}>
          <Cancel classes={{root: this.props.classes.cancelIcon}}/>
        </div>
        <div className={this.props.classes.errorTextContainer}>
          <Typography className={this.props.classes.typographyErrorText}>{this.props.errorMessage}
          </Typography>
        </div>
      </div>}
    </Dialog>;
  }
}

export default withStyles(styles)(MetusDialog);
