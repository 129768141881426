// lib imports
import * as React from "react";

import Log from "../../../common/utils/Logger";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {Button, createStyles, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import AddIcon from "../../../common/icons/menu/AddIcon";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

const styles = (theme: Theme): StyleRules => createStyles({
  buttonBar: {
    display: "flex",
    minHeight: "64px",
    borderBottom: "1px solid " + theme.metus.navigation.iconDivider,
    alignItems: "center", // vertical alignment
    justifyContent: "space-between",
    height: "64px",
    padding: "0px 16px 0px 16px",
    backgroundColor: theme.metus.navigation.fill,
    color: theme.metus.navigation.secondaryText,
  },
  button: {
    padding: "0px",
    minWidth: "24px", // overwrite MUIButton default
    minHeight: "24px",
    color: theme.metus.navigation.secondaryText,
    "&:hover": {
      backgroundColor: theme.metus.navigation.active,
    }
  },
  sidebarEntryTitle: {
    color: theme.metus.navigation.primaryText,
  },
});


interface LocalProps {
  onNewElement: () => void;
  title: string;
  showButton: boolean;
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;


class NavigationHeaderBar extends React.Component<StyledLocalProps, {}> {
  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering NavigationHeaderBar");
    const testSelector = "createElement-" + this.props.title;
    return <div className={this.props.classes.buttonBar}>
      <Typography variant="h6" classes={{h6: this.props.classes.sidebarEntryTitle}}>{this.props.title}</Typography>
      {this.props.showButton !== undefined && this.props.showButton !== false &&
      <Button className={this.props.classes.button}
              data-testselector={testSelector}
              id={testSelector}
              onClick={this.props.onNewElement}>
        <AddIcon/>
      </Button>
      }
    </div>;
  }

}

export default withStyles(styles)(NavigationHeaderBar);
