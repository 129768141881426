import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../../common/icons/menu/MetusSvgIcon";

class FilteredAttributeIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <g id="icon/attribute/p">
      <path d="M10,7.0003 L10,9.0003 L6,9.0003 L6,17.0003 L17,17.0003 L17,13.9993 L19,13.9993 L19,19.0003 L4,19.0003 L4,7.0003 L10,7.0003 Z M21,5 L21,12 L12,12 L12,5 L21,5 Z" id="Combined-Shape"></path>
    </g>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(FilteredAttributeIcon);