import {Dispatcher} from "../../common/utils/Dispatcher";
import Log from "../../common/utils/Logger";
import Action, {ActionBase} from "../../common/actions/BaseAction";
import {undoStore} from "../stores/UndoStore";
import {action} from "mobx";
import {TableId} from "../../core/utils/Core";
import {generateUUID} from "../../common/utils/IdGenerator";
import {baseurl} from "../../commonviews/actions/RESTCallActionCreatorsBase";
import {modelStore} from "../../core/stores/ModelStore";
import {put} from "../../core/utils/RESTCallUtil";
import {WriteResult} from "../../api/api";
import {ServerWritingAction} from "../../core/actions/CoreActions";
import {SimpleAsyncFunctionSerializer} from "../../common/utils/SynchronizationUtil";

const log = Log.logger("UndoRedo");

const dispatchAction = (action: Action): void => Dispatcher.dispatch(action);

type CommandType = "undo" | "redo";

function runCommandOnServer(commandType: CommandType, commandId: string): Promise<void | WriteResult<any>> {
  const uuid: TableId = generateUUID();
  // create element on server
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/commandstack/${commandType}/${commandId}`;
  return put(null, url, {"content-type": "application/json"});
}

function runCommandOnServerIfNecessary(action: ActionBase<any>, commandType: CommandType): Promise<WriteResult<any>> {
  let result;
  log.debug("Undo/Redo command on Server", action);
  const commandId = (action as any as ServerWritingAction).commandId;
  if (commandId) {
    result = runCommandOnServer(commandType, commandId);
  } else {
    result = Promise.resolve({commandId: null});
  }

  return result;
}

// make sure that all redo and undo requests are serialized so that the right order
// is always ensured
let promiseSerializer = new SimpleAsyncFunctionSerializer();

function undoRedoInternal(actions, undoStoreCallback, commandType: CommandType): Promise<any> {
  log.debug("Undo/Redo", actions);
  let result: Promise<WriteResult<null>> = Promise.resolve(null as WriteResult<null>);
  actions.forEach(action => {
    result = result.then((r) => runCommandOnServerIfNecessary(action, commandType));
  });
  // r is undefined if command failed on server and there is no WriteResult
  return result.then(r => r !== undefined && undoStoreCallback());
}

function undoInternal(): Promise<any> {
  return promiseSerializer.execSerialized( () => {
    const undoActions = undoStore.nextUndoStepActions();
    // calling undo on undoStore directly, since it is not possible to dispatch an undo action and then running the action replay
    // as part of the handling of the undo action in the undo store (no nested dispatching!)
    return undoRedoInternal(undoActions, undoStore.undo, "undo");
  });
}

function redoInternal(): Promise<any> {
  return promiseSerializer.execSerialized( () => {
    const redoActions = undoStore.nextRedoStepActions();
    // see comment in undoInternal
    return undoRedoInternal(redoActions, undoStore.redo, "redo");
  });
}

export const undo = action("Undo", undoInternal);
export const redo = action("Redo", redoInternal);
