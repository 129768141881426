import * as React from "react";
import * as ReactDOM from "react-dom";
import {DropTargetMonitor} from "react-dnd";

export class FeedbackHelper {
  /**
   * create a yellow targeting box feedback for the bounding box of the targeted component following the mouse cursor during drag move
   * @param component components boundingClientRect defines box size and position
   * @param monitor monitor to provide client offset so box will be suited for drop target feedback positioning
   * @returns element which can be used as target feedback highlighting the targeted component
   */
  static createTargetFeedbackBox(component: React.Component<any, any>, monitor: DropTargetMonitor): JSX.Element {
    const domNode = ReactDOM.findDOMNode(component);
    const rect = (domNode as Element).getBoundingClientRect();
    const offset = monitor.getClientOffset();
    const [x, y, width, height] = [rect.left, rect.top, rect.width, rect.height];
    const transform = `translate(${x - offset.x}px, ${y - offset.y}px)`;
    const box = <div className="drag-box-target-feedback" style={{
      transform: transform,
      width: width,
      height: height
    }}/>;
    return box;
  }

  /**
   * create a yellow targeting box feedback for the given bounds
   * @param y
   * @param x
   * @param width
   * @param height
   * @returns element which can be used as target feedback highlighting the targeted component
   */
  static createTargetFeedbackBoxFromBounds(x: number, y: number, width: number, height: number): JSX.Element {
    return <div className="drag-box-target-feedback" style={{
      position: "absolute",
      transform: `translate(${x}px, ${y}px)`,
      width: width,
      height: height
    }}></div>;
  }

  /**
   * create a gray box source feedback for the bounding box of the targeted component
   * @param component components boundingClientRect defines box size and position
   * @param title optional text to be displayed inside box
   * @returns element which can be used as target feedback highlighting the targeted component
   */
  static createSourceFeedbackBox(component: React.Component<any, any>, title?: string): JSX.Element {
    const domNode = ReactDOM.findDOMNode(component);
    const rect = (domNode as Element).getBoundingClientRect();
    const [x, y, width, height] = [rect.left, rect.top, rect.width, rect.height];
    const transform = `translate(${x}px, ${y}px)`;
    const box = <div className="drag-box-source-feedback" style={{
      transform: transform,
      width: width,
      height: height
    }}>{title}</div>;
    return box;
  }

}
