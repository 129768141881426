// lib imports
import * as React from "react";
import {ChangeEvent} from "react";

import Log from "../../../common/utils/Logger";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import TextFieldComponent from "../../../common/components/materialuiderived/TextFieldComponent";
import {metusStore} from "../../stores/MetusStore";
import autobind from "autobind-decorator";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {SetViewHierarchyFilterAction} from "../../../core/actions/NavigationActions";
import FilterIcon from "../../../common/icons/FilterIcon";
import {observer} from "mobx-react";
import NavigationHeaderBar from "./NavigationHeaderBar";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  filterLine: {
    display: "flex",
    minHeight: "50px",
    alignItems: "center",
    backgroundColor: theme.metus.navigation.fill,
    borderTop: "1px solid black",
  },
  textFieldInputRoot: {
    color: theme.metus.navigation.filterText,
    // TODO: invalid Filter different Text Color
    backgroundColor: theme.metus.navigation.fill,
  },
  textFieldSelect: {
    backgroundColor: theme.metus.navigation.fill + " !important",
    fontSize: 14,
  },
  filterIcon: {
    marginLeft: 6,
    marginRight: 6,
    fill: theme.metus.navigation.filterText,
  },
  childrenContainer: {
    overflowY: "auto",
    flexGrow: 1,
    backgroundColor: theme.metus.navigation.active.secondaryFill,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.metus.navigation.fill,
      outline: '1px slategrey'
    },'&::-webkit-scrollbar': {
      width: '0.4em'
    },
  }
});


interface LocalProps {
  onNewElement: () => void;
  title: string;
  showButton: boolean;
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

@observer
class AttachFilterBarComponent extends React.Component<StyledLocalProps, {}> {
  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering AttachFilterBarComponent");
    return <div className={this.props.classes.root}>
      <NavigationHeaderBar title={this.props.title} onNewElement={this.props.onNewElement}
                           showButton={this.props.showButton}/>
      <div className={this.props.classes.childrenContainer}>
        {this.props.children}
      </div>
      <div className={this.props.classes.filterLine}>
        <FilterIcon classes={{root: this.props.classes.filterIcon}}/>
        <TextFieldComponent
            classes={{inputRoot: this.props.classes.textFieldInputRoot, select: this.props.classes.textFieldSelect}}
            placeholder={"Enter filter text"}
            value={metusStore.viewHierarchyFilter}
            onChange={this.viewHierarchyFilterChanged}
            variant="outlined"
        />
      </div>
    </div>;
  }

  @autobind
  private viewHierarchyFilterChanged(event: ChangeEvent<{ value: string }>) {
    const newFilterText = event.target.value;
    Dispatcher.dispatch(new SetViewHierarchyFilterAction(newFilterText));
  }

}

export default withStyles(styles)(AttachFilterBarComponent);
