import {BoundedVisualObjectBase, VisualObject} from "../VisualObject";
import {observable} from "mobx";
import {DiagramModel} from "../DiagramModel";
import {generateId, VisualId} from "../../../core/utils/Core";


export class VisualTextBox extends BoundedVisualObjectBase<never, VisualId> implements VisualObject {

  @observable private _text: string = "";

  constructor(parent: DiagramModel, x: number = 0, y: number = 0, height: number = 150, width: number = 150) {
    super(parent, generateId(), x, y, width, height);
  }

  get text() {
    return this._text
  }

  set text(newText: string) {
    this._text = newText;
  }
}