import {FilterProperties} from "../../commonviews/components/properties/FilterPropertiesComponent";
import {DiagramModel} from "../models/DiagramModel";
import {diagramStore} from "../stores/DiagramStore";
import {ValueChartDiagramModel} from "../models/valuechart/ValueChartDiagramModel";
import {HeaderLayoutProperties} from "../../commonviews/components/properties/HeaderLayoutPropertiesComponent";

import Log from "../../common/utils/Logger";
import {
  IMetusPropertiesProviderContext,
  MetusPropertiesProvider
} from "../../commonviews/contexts/IMetusPropertiesProviderContext";

const log = Log.logger("diagram.propertiesprovider");

export const diagramHeaderlayoutPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): HeaderLayoutProperties => {
  log.debug("Providing diagram header layout properties for selection", context.activeViewId);

  let result: HeaderLayoutProperties = null;
  const diagram: DiagramModel = diagramStore.getDiagramForId(context.activeViewId);
  if (diagram) {
    result = {
      activeViewId: context.activeViewId,
      isVertical: diagram.attributeHeaderRotation !== 0,
      height: diagram.attributeHeaderHeight,
      width: undefined
    };
  }

  return result;
};
export const diagramFilterPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): FilterProperties => {
  let result: FilterProperties = null;
  const diagram: DiagramModel = diagramStore.getDiagramForId(context.activeViewId);
  if (diagram && diagram instanceof ValueChartDiagramModel) {
    result = {
      activeViewId: context.activeViewId,
      showConnectedOnly: diagram.showConnectedOnly,
    } as FilterProperties;
  }

  return result;
};