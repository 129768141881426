import * as React from "react";

import {BrandedImageComponent} from "./BrandedImageComponent";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";

const styles = (theme: Theme):StyleRules => createStyles({
  root: {
    height: "100%",
    backgroundColor: theme.metus.navigation.fill
  },
  slogan: {
    marginLeft: "20px",
    overflow: "hidden",
    position: "absolute",
    fontSize: "64px",
    fontWeight: 500,
    lineHeight: "80px",
    color: "white",
    zIndex: 1000
  }
});

interface LocalProps {
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

interface LocalState {
}

class KeyVisual extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  // vM20210218 MO-3901 slogan removed:       <p className={this.props.classes.slogan}>Create your<br/>Winning Product</p>
  render(): JSX.Element {
    return <div className={this.props.classes.root}>
      <BrandedImageComponent src="keyvisual.png" style={{objectFit: "contain", width: "100%"}}/>
    </div>;
  }
}


export default withStyles(styles)(KeyVisual);