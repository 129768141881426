import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class FolderIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M1,2 L1,21 L23,21 L23,4 L10,4 L8,2 L1,2 Z M3.596,13.146 L11.996,7.146 L14.206,8.725 L5.806,14.725 L3.596,13.146 Z M6.666,15.34 L15.066,9.339 L17.306,10.94 L8.906,16.939 L6.666,15.34 Z M9.767,17.554 L18.167,11.554 L20.396,13.146 L11.996,19.147 L9.767,17.554 Z"/> ;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(FolderIcon);