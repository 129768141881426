/* EditableTextComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as React from "react";
import {MouseEvent} from "react";
import Log from "../utils/Logger";
import * as ReactDOM from "react-dom";
import {createStyles, Modal, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import ReactTooltip from "react-tooltip";

const log = Log.logger("EditableTextComponent");
const renderLog = log;
const ENTER: number = 13;
const ESC: number = 27;

export const extractTextFromTextElement = (textElement: React.ReactElement<any>): string => {
  let result = null;
  if (textElement && textElement.props) {
    if (textElement.props.text) {
      result = textElement.props.text;
    } else if (typeof (textElement.props.children) === "string") {
      result = textElement.props.children;
    } else if (Array.isArray(textElement.props.children)) {
      for (const child of textElement.props.children) {
        result = extractTextFromTextElement(child);
        if (result !== null) {
          break;
        }
      }
    } else if (textElement.props.children) {
      result = extractTextFromTextElement(textElement.props.children);
    }
  }
  return result;
};

const styles = (theme: Theme): StyleRules => createStyles({
  textElement: {
    "&:hover": {
      boxShadow: "0 0 0 1px #ccc", // html
      strokeWidth: "2 !important", // svg
      cursor: "text", // svg  + html
    }
  },
  input: {
    width: "100%",
  }
});

interface LocalProps {
  textElement: React.ReactElement<any>; // text is in child element or text property
  onUpdate: (value: string) => void;
  onCancel?: () => void;
  onEdit?: () => string;
  inputWrapper?: React.ReactElement<any>; //
  isReplaceTextElement?: boolean; // replaces the text element with an input field if ture, otherwise adds input field as child of the text element
  isStartInEditMode?: boolean; // default false
  updateOnKeyPress?: boolean; // calls onUpdate on every key press instead of on return and when leaving the field
  onClick?: (e: MouseEvent<any>, ctrlKeyPressed?: boolean) => void; // this component stops all click events from bubbling to parent components. use this to get called back if a click occurred which is not part of a double click
  minWidth: number; // minimum width => if text element is smaller, the Edit field will be broader and extend on the right side
  editOnSingleClick?: boolean;
  scale?: number;
}

interface LocalState {
  isEditing: boolean;
  isStartedInEditingModeAndRefNotYetSet: boolean;
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

/** Features:
 * adds double click handler to text element and adds style to the text element, so that there will be visual feedback on hovering
 * retrieves editable text from text element
 * switches to modal input mode if editing is activated and places input field over the text element
 * calls callback when new values are set, either on complete or on every key press
 * stops all (single) click events from bubbling to parents, use onClick if you want to be called back for single clicks
 */
class EditableTextComponent extends React.Component<StyledLocalProps, LocalState> {
  private timeoutId: any;
  private position: { left: number, top: number, width: number, height: number };
  public static defaultProps: Partial<StyledLocalProps> = {
    isReplaceTextElement: false,
    isStartInEditMode: false,
    editOnSingleClick: false,
    onCancel: (): void => {
    }
  };
  private ref: EditableTextComponent;

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {
      isEditing: false,
      isStartedInEditingModeAndRefNotYetSet: this.props.isStartInEditMode,
    };
    this.position = {top: 50, left: 50, width: 200, height: 64};
    this.timeoutId = null;
  }

  @autobind onClick(e: MouseEvent<any>): void {
    if (this.props.onClick) {
      renderLog.debug("Click detected");
      const ctrlKey: boolean = e.ctrlKey;
      e.stopPropagation();
      if (this.timeoutId === null) {
        renderLog.debug("Click is possibly first click of double click, deferring");
        const timeBetweenClickAndDoubleClickRecognition = 300;
        this.timeoutId = setTimeout(() => {
          // TODO: Analyse why we loose informations on event.
          this.props.onClick(e, ctrlKey);
          this.timeoutId = null;
        }, timeBetweenClickAndDoubleClickRecognition);
      }
    }

    if (this.props.editOnSingleClick === true) {
      this.setState({isEditing: true});
      // MO-2539 hide tooltip on edit start
      ReactTooltip.hide();
    }
  }

  @autobind onDoubleClick(): void {
    renderLog.debug("EditableTextComponent is entering edit mode");
    if (this.timeoutId !== null) {
      log.debug("Cancelling deferred click");
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    this.setState({isEditing: true});
    // MO-2539 hide tooltip on edit start
    ReactTooltip.hide();
  }

  @autobind onBlur(event: any): void {
    renderLog.debug("EditableTextComponent is leaving edit mode");
    const value: string = (event.target as HTMLInputElement).value;
    this.props.onUpdate(value);
    this.setState({isEditing: false});
  }

  componentDidUpdate(prevProps: Readonly<StyledLocalProps>, prevState: Readonly<any>, snapshot?: any): void {
    // TODO how can this work with getDerivedStateFromProps ?
    if (!prevProps.isStartInEditMode && this.props.isStartInEditMode) {
      this.setState(state => ({isEditing: true}));
    }
  }

  @autobind handleKeyPressed(event: any): void {
    const value: string = (event.target as HTMLInputElement).value;
    if (this.state.isEditing) {
      switch (event.keyCode) {
        case ENTER:
          this.props.onUpdate(value);
          this.setState({isEditing: false});
          break;
        case ESC:
          this.cancel();
          this.props.onCancel();
          break;
        default:
          if (this.props.updateOnKeyPress) {
            this.props.onUpdate(value);
          }
      }
    }
  }

  @autobind
  private setRef(ref: EditableTextComponent): void {
    renderLog.debug("EditableTextComponent got reference", ref);
    if (ref !== null) {
      // retrieving the position at this point in time would not always yield the correct result
      this.ref = ref;
      if (this.state.isStartedInEditingModeAndRefNotYetSet) {
        this.setState(oldState => ({isEditing: true, isStartedInEditingModeAndRefNotYetSet: false}));
      }
    }
  }

  @autobind
  private cancel(): void {
    this.setState({isEditing: false});
  }

  @autobind
  private copy(event: any): void {
    event.clipboardData.setData("text/plain", (event.target as HTMLInputElement).value);
    event.stopPropagation();
  }


  getDimensionsofTextElementDOMNode(): {
    left: number, top: number, width: number, height: number
  } {
    // not recommended during render, but there is no other way to get the correct coordinates
    // of the textElement
    // Note: if you had done this in componentDidMount, the coordinates might have changed in the  meantime,
    // e.g. because the editor has been activated and due to the border the dom node has been moved
    if (this.ref) {
      const el = (ReactDOM.findDOMNode(this.ref) as Element).getBoundingClientRect();
      // sometimes the position and dimensions of the underlying text Element are 0
      if (el.top !== 0 && el.height !== 0) {
        renderLog.debug("EditableTextComponent is updating position from underlying text Element");
        let width = el.width;
        if (this.props.minWidth) {
          width = Math.max(this.props.minWidth, width);
        }
        this.position = {top: el.top, left: el.left, width, height: el.height};
      }
    }
    return this.position;

  }

  render(): JSX.Element {
    return this.state.isEditing ? this.renderEditing() : this.renderNonEditing();
  }

  private get scale(): number {
    let retVal = 1;
    if (this.props.scale !== undefined && this.props.scale !== 0 && this.props.scale < 1) {
      retVal = 1 / this.props.scale;
    }
    return retVal;
  }

  renderEditing(): JSX.Element {
    renderLog.debug("EditableTextComponent rendering input element");

    const textElement = this.props.textElement;
    let text: string;

    if (typeof this.props.onEdit === "function") {
      text = this.props.onEdit();
    } else {
      text = extractTextFromTextElement(textElement);
    }

    // it is now the best time to retrieve the dimensions of the underlying textComponent
    const dimensions = this.getDimensionsofTextElementDOMNode();
    // if textElement is a SVG Element, the props.width and height are better suited
    dimensions.width = textElement.props.width || dimensions.width;
    dimensions.height = textElement.props.height || dimensions.height;
    dimensions.width = dimensions.width * this.scale;
    dimensions.height = dimensions.height * this.scale;
    renderLog.debug("EditableTextComponent dimensions and text", dimensions, text);
    
    const input = <Modal key="editableText" open={true}><input
        data-testselector="Input"
        style={{position: "fixed", ...dimensions}}
        autoFocus
        onFocus={(e: any): void => {
          const inputField: any = e.target;
          inputField.select();
        }}
        className={this.props.classes.input}
        defaultValue={text}
        onKeyUp={this.handleKeyPressed}
        onBlur={this.onBlur}/></Modal>;
    const inputWrapped = this.props.inputWrapper !== undefined ?
        <this.props.inputWrapper.type {...this.props.inputWrapper.props}>{input}</this.props.inputWrapper.type> : input;
    return this.props.isReplaceTextElement ? inputWrapped : React.cloneElement(textElement, {}, [textElement.props.children, inputWrapped]);
  }

  renderNonEditing(): JSX.Element {
    renderLog.debug("EditableTextComponent rendering non editing field");
    return React.cloneElement(this.props.textElement,
        {
          onClick: this.onClick,
          onDoubleClick: this.onDoubleClick,
          className: this.props.classes.textElement,
          ref: this.setRef
        });
  }

  @autobind
  private paste(event: any): void {
    // default will do here (event.target as HTMLInputElement).value = event.clipboardData.getData("text");
  }

  @autobind
  private selectText(event: any): void {
    (event.target as HTMLInputElement).select();
  }
}

export default withStyles(styles)(EditableTextComponent);
