import {Attribute, ElementId, ElementObject, TableId, ViewId} from "../utils/Core";
import {
  AttributeDefinition,
  AttributeValues,
  Connection,
  ExtTableId,
  FilterData,
  GeneralizedAttributeData,
  GeneralizedTableAndAttributeData,
  Table,
  toExtTableId,
  UUID,
  VisualTable
} from "../../api/api";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {DeleteElementsAction, UpdateAttributeValuesAction} from "./CoreActions";
import Log from "../../common/utils/Logger";
import {modelStore} from "../stores/ModelStore";
import {observable} from "mobx";
import {sequential} from "../../common/utils/SynchronizationUtil";
import {
  deleteElements,
  getLostAttributes,
  loadAttributeValuesForTable,
  loadConnections,
  loadTableFolderTree,
  moveElementsToTable,
  newElement,
  saveTable,
  toggleConnections,
  updateAttributeValues,
  UPPER_LIMIT_OF_QUERY_PARAMS
} from "../services/CoreDataServices";
import {normalizeName} from "../utils/NameNormalizer";
import {generateUUID} from "../../common/utils/IdGenerator";
import {LoadConnectionsAction, LoadConnectionsPayload, ToggleConnectionActionPayload} from "./CoreAsyncActions";
import {showConfirmationDialog} from "../../common/utils/CommonDialogUtil";
import _ from "lodash";

const log = Log.logger("CoreAsyncActionCreatores");

export function syncFilterData(allFilterData: FilterData[] = []): FilterData[] {
  const filterDataNotDeletedInModel: FilterData[] = [];

  allFilterData.forEach((filterData: FilterData) => {

    const visualTablesNotDeletedInModel: VisualTable[] = filterData.visualTables.filter((visualTable: VisualTable) => {
      return modelStore.tableNameByTableId.has(visualTable[0]);
    });

    if (visualTablesNotDeletedInModel.length > 0) {
      let visualTableNotDeletedInModelHasAttribute: boolean = false;

      for (const visualTableNotDeletedInModel of visualTablesNotDeletedInModel)
        if (modelStore.tableHasAttributeDefinition(visualTableNotDeletedInModel[0], filterData.attribute)) {
          visualTableNotDeletedInModelHasAttribute = true;
          break;
        }

      if (visualTableNotDeletedInModelHasAttribute) {
        filterData.visualTables = visualTablesNotDeletedInModel;
        filterDataNotDeletedInModel.push(filterData);
      }
    }
  });

  return filterDataNotDeletedInModel;
}

export function createChildElement(tableId: TableId, sourceElementId: ElementId): Promise<ToggleConnectionActionPayload> {
  return newElement(tableId)
      .then(elementId => {
        return elementId && toggleConnections([sourceElementId], elementId);
      });
}


// TODO: why are here 3 different action creators for doing the same thing ? --> should be only one
/**
 * Updates attributes on server and update stores.
 * @param {Attribute[]} attributes
 * @param {string} elementId
 * @param {TableId} tableId
 * @returns {Promise<any>}
 */
export function updateAttributeValuesForElementAndTable(attributes: Attribute[], elementId: ElementId, tableId: TableId): Promise<ElementObject> {
  return updateAttributeValues(attributes, elementId, tableId)
      .then(writeResponse => {
        if (writeResponse) {
          const element: ElementObject = observable({id: elementId});
          attributes.forEach(attribute => {
            element[attribute.name] = attribute.value;
          });
          Dispatcher.dispatch(new UpdateAttributeValuesAction(writeResponse.commandId, tableId, element));
          return element;
        }
      });
}

export function updateAttributeValueForElement(attribute: Attribute, elementId: ElementId, tableId: TableId): ElementObject {
  const element: ElementObject = observable({id: elementId});
  element[attribute.name] = attribute.value;
  // TODO: concept for server updates coming via web socket: recordable, undoable?
  Dispatcher.dispatch(new UpdateAttributeValuesAction(null, tableId, element));
  return element;
}

/* MO-2490 race condition not loading all connections if getConnectionsForTable is executed in parallel, thus make sure they are executed in sequence */
export const getConnectionsForTables = sequential(getUnsynchronizedConnectionsForTables);

export function getUnsynchronizedConnectionsForTables(viewId: ViewId, requestedTableIds: ExtTableId[], groupId: UUID): Promise<Connection[]> {
  const connectionsPromise: Promise<Connection[]>[] = [Promise.resolve(modelStore.connections)];
  // determine new table ids
  const newTableIdsPotentialDuplicates = requestedTableIds.filter((requestedTable) => {
    return !modelStore.tablesWithConnectionsLoaded.includes(requestedTable);
  });
  // remove duplicates
  const newTableIds = Array.from(new Set(newTableIdsPotentialDuplicates));

  if (newTableIds.length > 0) {
    connectionsPromise.push(loadConnections(newTableIds, modelStore.tablesWithConnectionsLoaded, groupId));
  }

  return Promise.all(connectionsPromise)
      .then((connectionsArray: Connection[][]) => {
        const connections: Connection[] = [];
        connectionsArray.forEach(_connections => {
          // TODO: tests seem to fail often here, security check for undefined, but should always be defined
          if (_connections) {
            connections.push(..._connections);
          }
        });
        const payload: LoadConnectionsPayload = {connections, newTableIds};
        Dispatcher.dispatch(new LoadConnectionsAction(payload, viewId, groupId, true, true));
        return connections;
      });
}

function createConnectionsFromEndpoints(endpoints: [ElementId, ElementId][]): Connection[] {
  const retVal: Connection[] = [];
  endpoints.forEach(endpoint => retVal.push({
    sourceElementId: endpoint[0],
    targetElementId: endpoint[1],
    strength: 1
  }));
  return retVal;
}

export function getAttributeValuesForTablesWithReferencedAttributes(viewId: ViewId, tables: GeneralizedTableAndAttributeData[], groupId: UUID, isDispatch: boolean = true): Promise<Table[]> {
  const resultForView: Promise<Table[]> = getAttributeValuesForTables(viewId, tables, groupId, isDispatch);

  const numTablesWithoutRefTables: number = tables.length;

  const attributeNamesByTableId: Map<TableId, string[]> = new Map<TableId, string[]>();
  tables.forEach(table => {
    attributeNamesByTableId.set(toExtTableId(table.id), table.attributes.map(attribute => attribute.name));
  });

  const refGeneralizedTableAndAttributeData: Map<TableId, GeneralizedTableAndAttributeData> = new Map<TableId, GeneralizedTableAndAttributeData>();

  tables.forEach(table => {
    const tableId: TableId = toExtTableId(table.id);
    const attributes: GeneralizedAttributeData[] = table.attributes;
    const attributeNames = attributes.map(attribute => attribute.name);

    attributeNames.forEach((attributName: string) => {
      let attributeDefinition: AttributeDefinition = modelStore.getAttributeDefinition(tableId, attributName);
      if (attributeDefinition.type == "Derived") {
        const refTableId: TableId = attributeDefinition["referencedTableId"];
        const refAttName: string = attributeDefinition["referencedAttributeName"];
        if (refTableId && refAttName) {

          if (!(attributeNamesByTableId.has(refTableId) && attributeNamesByTableId.get(refTableId).includes(refAttName))) {
            let generalizedTableAndAttributeData: GeneralizedTableAndAttributeData;

            if (refGeneralizedTableAndAttributeData.has(refTableId)) {
              generalizedTableAndAttributeData = refGeneralizedTableAndAttributeData.get(refTableId);
              generalizedTableAndAttributeData.attributes.push({name: refAttName});
              attributeNamesByTableId.get(refTableId).push(refAttName);
            } else {
              generalizedTableAndAttributeData = {id: refTableId, attributes: [{name: refAttName}]};
              refGeneralizedTableAndAttributeData.set(refTableId, generalizedTableAndAttributeData);
              attributeNamesByTableId.set(refTableId, [refAttName]);
            }
          }
        }
      }
    });
  });

  getAttributeValuesForTables(null, Array.from(refGeneralizedTableAndAttributeData.values()), groupId, true);

  return resultForView;
}

/**
 * utility to load needed tables and attributes from server if not already in model store
 * @param {ViewId} viewId view to which resulting attributes should be dispatched
 * @param {GeneralizedTableAndAttributeData[]} tables generalized structure for tables and attributes matching grid, chart and value chart serialization formats
 * @param {UUID} groupId groupId for undo
 * @param {boolean} isDispatch true if an action should be dispatched after loading
 * @returns {Promise<Table[]>} promise which resolves when information is loaded for all tables
 */
export function getAttributeValuesForTables(viewId: ViewId, tables: GeneralizedTableAndAttributeData[], groupId: UUID, isDispatch: boolean = true): Promise<Table[]> {
  const promises: Promise<Table[]>[] = [];
  tables.forEach(table => {
    const tableId = toExtTableId(table.id);
    const attributes: GeneralizedAttributeData[] = table.attributes;
    const attributeNames = attributes.map(attribute => attribute.name);
    promises.push(getAttributeValuesForTable(viewId, tableId, attributeNames, groupId, false));
  });

  return Promise.all(promises)
      .then((tablesArrayArray: Table[][]) => {
        const tables: Table[] = [];
        tablesArrayArray.forEach(_tables => {
          tables.push(_tables[0]);
        });
        isDispatch && Dispatcher.dispatch({
          type: "loadAttributeValues", groupId, payload: tables, resourceId: viewId,
          undoable: true
        });
        return tables;
      });
}

/**
 * vM20190304 MO-1342
 * merges all values from toMerge into target inplace; respect the case that length and attribute count of target and toMerge do not match,
 * but generatedId order matches (powerviewer newly created elements are not known by rest service retrieving existing values
 * @param target target where attribute values will be merged
 * @param toMerge values to merge
 */
function mergeValues(target: AttributeValues, toMerge: AttributeValues): void {
  toMerge.generatedId.forEach((id, index) => {
    if (target.generatedId[index] === id) {
      // add all attribute values to target
      Object.keys(toMerge).forEach(attributeName => {
        if (target[attributeName] === undefined) {
          target[attributeName] = [];
        }
        target[attributeName][index] = toMerge[attributeName][index];
      });
    } else if (target.generatedId.length <= index) {
      Object.keys(toMerge).forEach(attributeName => {
        if (target[attributeName] === undefined) {
          target[attributeName] = [];
        }
        target[attributeName].push(toMerge[attributeName][index]);
      });

    } else {
      log.warn("Internal Error: Could not match generatedId " + id);
    }
  });
}

export function getAttributeValuesForTable(viewId: ViewId, tableId: TableId, attributeNames: string[], groupId: UUID, isDispatch: boolean = true, force: boolean = false): Promise<Table[]> {
  const attributeValuesPromise: Promise<AttributeValues>[] = [];

  const attributeValues: [AttributeValues, string[]] = modelStore.getAttributeValuesForTable(tableId, attributeNames);

  if (attributeValues[0].generatedId.length > 0 && !force) {
    attributeValuesPromise.push(Promise.resolve(attributeValues[0]));
    const missingAttributesNames = attributeValues[1];
    if (missingAttributesNames.length > 0) {
      const chunksOfNames: string[][] = _.chunk(missingAttributesNames, UPPER_LIMIT_OF_QUERY_PARAMS);
      attributeValuesPromise.push(...chunksOfNames.map(names => loadAttributeValuesForTable(tableId, names, groupId)));
    }
  } else {
    const chunksOfNames: string[][] = _.chunk(attributeNames, UPPER_LIMIT_OF_QUERY_PARAMS);
    attributeValuesPromise.push(...chunksOfNames.map(names => loadAttributeValuesForTable(tableId, names, groupId)));
  }

  return Promise.all(attributeValuesPromise)
      .then((attributeValues: AttributeValues[]) => {
        const allAttributeValues: AttributeValues = {generatedId: []};
        attributeValues.forEach(attributeValues => {
          if (attributeValues) {
            mergeValues(allAttributeValues, attributeValues);
          }
        });
        return [{tableId, attributeValues: allAttributeValues}];
      })
      .then(tables => {
        if (isDispatch) {
          Dispatcher.dispatch({
            type: "loadAttributeValues", payload: tables, resourceId: viewId,
            undoable: true, groupId
          });
        }
        return tables;
      });
}

export function deleteElementsAndDispatch(elementIds: ElementId[]): Promise<ElementId[]> {
  return deleteElements(elementIds)
      .then(writeResponse => {
        if (writeResponse) {
          Dispatcher.dispatch(new DeleteElementsAction(writeResponse.commandId, elementIds));
          return elementIds;
        }
      });
}

export function createNewTable(name: string, parentId?: string): void {
  const normalizedName = normalizeName(name);
  const tableId: TableId = generateUUID();
  saveTable(normalizedName, tableId, true, parentId).then(writeResult => {
    if (writeResult) {
      loadTableFolderTree(generateUUID());
    }
  });
}

export async function moveElementsBetweenTables(elementIdsToMove: ElementId[], sourceTableId: TableId, targetTableId: TableId, isCopy: boolean, y?: number): Promise<void> {
  const lostAttributes: string[] = await getLostAttributes(sourceTableId, targetTableId) as string[];
  if (lostAttributes.length > 0) {
    showConfirmationDialog("The following attributes cannot be transfered: " + lostAttributes.join(", ")
        + "\nYou still want to continue?", () => {
      moveElementsToTable(elementIdsToMove, sourceTableId, targetTableId, isCopy, lostAttributes, y);
    });
  } else {
    await moveElementsToTable(elementIdsToMove, sourceTableId, targetTableId, isCopy, [], y);
  }
}
