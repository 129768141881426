import * as React from "react";
import {SvgIcon, Theme} from "@material-ui/core";
import {createStyles, StyleRules, withStyles, WithStyles} from "@material-ui/styles";

const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    fill: theme.palette.secondary[700],
    height: "24px",
    width: "24px",
  },
});

interface LocalProps {
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class AddCellContentHeaderIcon extends React.Component<StyledLocalProps, any> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    return <SvgIcon viewBox="0 0 24 24" className={this.props.classes.root}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.5">
        <path
            d="M8,3 L8,8 L3,8 L3,21 L8,21 L9,21 L21,21 L21,3 L8,3 Z M9,8.001 L20,8.001 L20,4 L9,4 L9,8.001 Z M3.999,20 L8,20 L8,9 L3.999,9 L3.999,20 Z"
            fill="#000000"></path>
      </g>
    </SvgIcon>;
  }
}

export default withStyles(styles)(AddCellContentHeaderIcon);