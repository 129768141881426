import Log from "../../common/utils/Logger";
import {observer} from "mobx-react";
import * as React from "react";
import {CSSProperties} from "react";
import autobind from "autobind-decorator";
import {VisualTableId, VisualTableIdString} from "../../core/utils/Core";
import {ViewContext, viewContextId} from "../contexts/ViewContext";
import {VisualHeader} from "../models/VisualHeader";
import identity from "lodash/fp/identity";
import {DiagramVisualConstants} from "../constants/DiagramVisualConstants";
import {ContextMenuTrigger} from "react-contextmenu";
import {SVGClickableIcon} from "../../common/components/iconbase/SVGClickableIcon";
import {SVGTextBoxComponent} from "../../common/components/SVGTextBoxComponent";
import {Color} from "csstype";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {ConnectDragPreview, ConnectDragSource, ConnectDropTarget} from "react-dnd";
import {showMessageDialog} from "../../common/utils/CommonDialogUtil";
import {CommonVisualConstants} from "../../common/constants/CommonVisualConstants";

const log = Log.logger("TableColumnHeaderComponent");
const renderLog = Log.logger("TableColumnHeaderComponent", Classifier.render);

export interface TableColumnHeaderComponentProps {
  viewContext: ViewContext;
  id: VisualTableId;
  /** index in hierarchy where table is located, 0 is root */
  index: number;
  /**
   * true if in matrix and column hierarchy, false if in matrix and row hierarchy, undefined if not in matrix
   */
  isColumnHierarchy?: boolean;
  header: VisualHeader;
  backgroundColor: Color;
  textColor?: Color;
  isFiltered?: boolean;
}

export interface TableColumnHeaderComponentDndProps {
  /** injected react-dnd monitor properties */
  connectDragSource?: ConnectDragSource;
  connectDragPreview?: ConnectDragPreview;
  connectDropTarget?: ConnectDropTarget;
  isDragging?: boolean;
}

export type TableColumnHeaderComponentLocalAndDndProps =
    TableColumnHeaderComponentProps
    & TableColumnHeaderComponentDndProps;

// App pure component
@observer
export class TableColumnHeaderComponentNoDnd extends React.Component<TableColumnHeaderComponentLocalAndDndProps> {
  // no typings for ContextMenuTrigger available, thus use any
  private contextMenuRef: React.RefObject<any> = React.createRef();

  constructor(props: TableColumnHeaderComponentLocalAndDndProps) {
    super(props);
  }

  @autobind openContextMenu(e: React.MouseEvent): void {
    this.contextMenuRef.current.handleContextClick(e);
  }

  @autobind showToolboxFilterInfoDialog(): void {
    showMessageDialog(true, "Matrix column filtering is controlled on the filter toolbox");
  }

  render(): JSX.Element {

    const collect = (props: TableColumnHeaderComponentProps): { id: VisualTableIdString } => {
      return {id: props["data-id"]};
    };
    const contextMenuId = "cm_dg_TableHeaderComponent" + viewContextId(this.props.viewContext) + this.props.id.toKey();

    renderLog.debug("Rendering ChartColumnHeaderComponent");
    const viewHeader: VisualHeader = this.props.header;

    const transform = `translate(${viewHeader.x},${viewHeader.y})`;
    const connectDragSource = this.props.connectDragSource || identity;
    const connectDropTarget = this.props.connectDropTarget || identity;
    // vM20181204 using webpack for bundling svgicons with 'use' did not work
    const rectStyle: CSSProperties = {fill: this.props.backgroundColor as string, stroke: "#808080"};
    const textWidth = viewHeader.width - 2 * (CommonVisualConstants.TABLE_HEADER_ICON_WIDTH + CommonVisualConstants.TABLE_HEADER_ICON_GAP);
    let textStyle = viewHeader.textStyles;
    if (this.props.textColor) {
      textStyle = {...viewHeader.textStyles, color: this.props.textColor};
    }
    let filterIconPath;
    if (this.props.isColumnHierarchy) {
      filterIconPath = this.props.isFiltered ?  "#icon/filter/filterActive" : "#icon/filter/filterEmpty";
    }
    // holdToDisplay set to -1 otherwise mouse down is not passed to parent
    return connectDragSource(connectDropTarget(<g className={"gtable"} transform={transform}
                                                  data-tableid={this.props.id.toKey()}
                                                  data-testselector={"tableHeader/" + viewHeader.name}>
      <ContextMenuTrigger id={contextMenuId}
                          ref={this.contextMenuRef}
                          data-id={this.props.id.toKey()}
                          renderTag="g"
                          collect={collect}
                          holdToDisplay={-1}
                          attributes={{"data-testselector": "contextmenutrigger"} as any}>
        <rect width={viewHeader.width} height={viewHeader.height} style={rectStyle}
              rx={2 * DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC}
              ry={2 * DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC}/>
        <SVGClickableIcon href="#icon/table/tableHeader"/>
        <SVGClickableIcon href="#icon/options/options"
                          x={viewHeader.width - CommonVisualConstants.TABLE_HEADER_ICON_WIDTH}
                          onClick={this.openContextMenu}/>
        { filterIconPath ? <SVGClickableIcon href={filterIconPath}
                                             x={viewHeader.width - 2 * CommonVisualConstants.TABLE_HEADER_ICON_WIDTH}
                                             onClick={this.showToolboxFilterInfoDialog}
                          /> : null }
        <SVGTextBoxComponent text={viewHeader.name}
                             x={CommonVisualConstants.TABLE_HEADER_ICON_WIDTH + CommonVisualConstants.TABLE_HEADER_ICON_GAP}
                             style={textStyle}
                             width={textWidth} height={DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT}
                             wrapLines={true}/>
      </ContextMenuTrigger>
    </g>));
  }
}
