/**
 * collects updates to ag-grid and queues scheduling it
 */
import {RowDataTransaction} from "ag-grid-community";
import {MatrixRow} from "./RowDataUpdater";
import autobind from "autobind-decorator";
import Log from "../../common/utils/Logger";

const log = Log.logger("UpdateScheduler");

export type OnRowDataUpdateCallback = (arg: RowDataTransaction) => void;

/**
 * UpdateScheduler batches row insertion/deletions/updates into a RowDataTransaction and sends it after a configurable delay to ag-grid
 * @author Marco van Meegen
 */

export class UpdateScheduler {
  private rowDataTransaction: RowDataTransaction;
  /**
   * set when schedule
   */
  private pending: boolean;

  private setTimeoutHandler: any;

  /**
   * create a scheduler which gathers changes and sends them batched to the updateCallback with setTimeout(delay)
   * @param updateCallback called when batched update is sent
   * @param delayInMs delay after which the batched updates are sent measured from the first occuring rows* call, 0 = batches everything until this exeuction is finished, -1 = passes all events through directly
   */
  constructor(private updateCallback: OnRowDataUpdateCallback, private delayInMs: number) {
    this.reset();
    this.delayInMs = delayInMs;
    this.updateCallback = updateCallback;
  }

  private reset(): void {
    this.rowDataTransaction = {add: [], remove: [], update: []};
    this.pending = false;
    this.setTimeoutHandler = undefined;
  }

  /**
   * schedule created rows
   * @param rows
   */
  public rowsAdded(...rows: MatrixRow[]): void {
    if (rows.length > 0) {
      this.rowDataTransaction.add.push(...rows);
      this.schedule();
    }
  }

  /**
   * schedule deleted rows
   * @param rows
   */
  public rowsRemoved(...rows: MatrixRow[]): void {
    if (rows.length > 0) {
      this.rowDataTransaction.remove.push(...rows);
      this.schedule();
    }
  }

  /**
   * schedule updated rows
   * @param rows
   */
  public rowsUpdated(...rows: MatrixRow[]): void {
    if (rows.length > 0) {
      this.rowDataTransaction.update.push(...rows);
      this.schedule();
    }
  }

  private schedule(): void {
    if (!this.pending) {
      this.pending = true;
      if (this.delayInMs !== -1) {
        this.setTimeoutHandler = setTimeout(this.sendUpdate, this.delayInMs);
      } else {
        this.sendUpdate();
      }
    }
  }

  /**
   * will be triggered by setTimeout after current execution is finished
   */
  @autobind
  private sendUpdate(): void {
    const transaction = this.rowDataTransaction;
    this.reset();
    log.debug("Sending Update with " + transaction.add.length + " added, " + transaction.remove.length + " removed, " + transaction.update.length + " updated rows");
    // restart change gathering from scratch
    // send the batched update
    this.updateCallback(transaction);
  }

  /**
   * disposes any pending timeouts
   */
  public dispose(): void {
    if (this.setTimeoutHandler) {
      clearTimeout(this.setTimeoutHandler);
    }
    this.reset();
  }
}