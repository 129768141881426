import {
  ATTRIBUTE_VERSION,
  FOLDER_VERSION,
  getVersionOfViewType,
  TABLE_VERSION,
} from "../../common/constants/ViewVersions";
import {
  OpenView, TableAttributesPerTable
} from "../../api/api"
import {PersistencyState, TreeItemType, ViewType} from "../../common/constants/Enums";
import {AttributeId, AttributeType, TableId, ViewId} from "../utils/Core";
import {ListItemHierarchy} from "./ListItemHierarchy";
import {ViewInfo} from "../../commonviews/models/ViewInfo";
import {NodeProps} from "../../workbench/components/TreeListItemComponent";

export const ROOT_FOLDER_NAME = "root";

export interface TreeListItem extends NodeProps {
  childIndices?: number[];
  parentIndex?: number;
  attributeType?: AttributeType;
  shouldRender?: boolean;
  isDirty?: boolean;
}

export function transformTablesAndFoldersIntoTreeItems(root: ListItemHierarchy, tableAttributes: TableAttributesPerTable = new Map(), viewInfos: ViewInfo[] = []): TreeListItem[] {
  return new Transformer(tableAttributes, viewInfos).transform(root);
}

export function transformViewHierarchyAndViewInfosIntoTreeItems(itemTree: ListItemHierarchy, viewInfos: ViewInfo[], openViews: OpenView[] = []): TreeListItem[] {
  return new Transformer(null, viewInfos, openViews).transform(itemTree);
}

class Transformer {
  constructor(public tableAttributes: TableAttributesPerTable, public viewInfos: ViewInfo[], public openViews: OpenView[] = []) {
  }

  public transform(root: ListItemHierarchy): TreeListItem[] {
    const result: TreeListItem[] = [];
    if (root) {
      this.transfom_rek(result, root);
    }
    return result;
  }

  private transfom_rek(collector: TreeListItem[], listItemHierarchy: ListItemHierarchy, depth: number = 0, parentIndex: number = -1): void {
    // create new tree item
    const newTreeListItem: TreeListItem = {
      id: listItemHierarchy.id || listItemHierarchy.name,
      type: listItemHierarchy.type,
      name: listItemHierarchy.name,
      level: depth,
      isDirty: false,
      disabled: false
    };

    if (listItemHierarchy.type === TreeItemType.View) {
      newTreeListItem.isHighLighted = this.isOpenView(listItemHierarchy);
      newTreeListItem.isActive = this.isActiveView(listItemHierarchy);
    } else if (listItemHierarchy.type === TreeItemType.Folder && listItemHierarchy.name !== ROOT_FOLDER_NAME && listItemHierarchy.children?.size() > 0) {
      this.highlightFolder(newTreeListItem, listItemHierarchy);
    }

    if (listItemHierarchy.viewType !== undefined) {
      newTreeListItem.viewType = listItemHierarchy.viewType;
    }

    if (listItemHierarchy.svgIconColor) {
      newTreeListItem.svgIconColor = listItemHierarchy.svgIconColor;
      newTreeListItem.svgIconType = listItemHierarchy.svgIconType;
    }

    if (listItemHierarchy.type === TreeItemType.Folder) {
      newTreeListItem.disabled = listItemHierarchy.viewVersion > FOLDER_VERSION;
    } else if (listItemHierarchy.type === TreeItemType.Attribute) {
      // not sure if this is needed
      newTreeListItem.disabled = listItemHierarchy.viewVersion > ATTRIBUTE_VERSION;
    } else if (listItemHierarchy.type === TreeItemType.Table) {
      newTreeListItem.disabled = listItemHierarchy.viewVersion > TABLE_VERSION;
    } else if (listItemHierarchy.viewVersion > getVersionOfViewType(listItemHierarchy.viewType)) {
      newTreeListItem.disabled = true;
    }

    // merge persistency state from viewInfo into treeListItem
    const viewInfo: ViewInfo = this.findViewInfoById(listItemHierarchy.id);
    if (viewInfo !== undefined) {
      newTreeListItem.isDirty = viewInfo.persistencyState === PersistencyState.New;
      if (viewInfo.name !== null) {
        // refactoring needed, see MO-1299
        newTreeListItem.name = viewInfo.name;
      }
    }

    // mui tree id is its index within the array
    const newIndex = collector.length;

    const removed = viewInfo === undefined && listItemHierarchy.type === TreeItemType.View;
    if (!removed) {
      // add new tree item as child to its parent
      if (parentIndex !== -1) {
        const existingChildren = collector[parentIndex].childIndices || [];
        existingChildren.push(newIndex);
        collector[parentIndex].childIndices = existingChildren;
        newTreeListItem.parentIndex = parentIndex;
      }
      // add new tree item to array
      collector.push(newTreeListItem);
    }

    if (listItemHierarchy.type === TreeItemType.Table) {
      this.addAttributesIntoTree(newTreeListItem, newIndex, collector);
    } else if (listItemHierarchy.children) { // add all children
      listItemHierarchy.children
          .forEach(node => {
            this.transfom_rek(collector, node.value, depth + 1, newIndex);
          });
    }
  }

  private addAttributesIntoTree(parentTable: TreeListItem, parentIndex: number, currentTree: TreeListItem[]): void {
    const tableId = parentTable.id as TableId;
    if (this.tableAttributes.has(tableId)) {
      for (const attribute of this.tableAttributes.get(tableId)) {
        if (attribute.viewable) {
          const newTreeItem: TreeListItem = {
            id: new AttributeId(tableId, attribute.name),
            type: TreeItemType.Attribute,
            name: attribute.name,
            level: parentTable.level + 1,
            attributeType: attribute.type,
            parentIndex: parentIndex,
            isDirty: false,
            disabled: false // todo version: can attributes be disabled?
          };

          const newIndex = currentTree.length;
          const existingChildren = parentTable.childIndices || [];
          existingChildren.push(newIndex);
          parentTable.childIndices = existingChildren;

          currentTree.push(newTreeItem);
        }
      }
    }
  }

  private highlightFolder(newTreeListItem: TreeListItem, listItemHierarchy: ListItemHierarchy): void {
    if (listItemHierarchy.children?.size() > 0) {
      for (const node of listItemHierarchy.children) {
        if (node.value.type === TreeItemType.View && this.isOpenView(node.value)) {
          newTreeListItem.isHighLighted = true;
          break;
        } else if (node.value.type === TreeItemType.Folder) {
          this.highlightFolder(newTreeListItem, node.value);
        }
      }
    }
  }

  private findViewInfoById(id: string): ViewInfo {
    return this.viewInfos.find(vi => vi.id === id);
  }

  private findOpenViewById(id: ViewId): OpenView {
    return this.openViews.find(openView => openView.id === id);
  }

  private isOpenView(listItemHierarchy: ListItemHierarchy): boolean {
    const openView: OpenView = this.findOpenViewById(listItemHierarchy.id);
    return openView !== undefined
  }

  private isActiveView(listItemHierarchy: ListItemHierarchy): boolean {
    let retVal: boolean;
    const openView: OpenView = this.findOpenViewById(listItemHierarchy.id);

    if (openView !== undefined && openView.isActive) {
      retVal = true;
    } else {
      if (openView !== undefined && openView.type !== ViewType.Cockpit) {
        retVal = false;
      }
    }

    return retVal;
  }

}

export const viewHierarchy2ViewInfos = function (root: ListItemHierarchy): ViewInfo[] {
  const result = [];
  addToCollector(result, root);
  return result;
};

const addToCollector = function (collector: ViewInfo[], current: ListItemHierarchy): void {
  if (current.type === TreeItemType.View && current.viewType !== ViewType.WebViewHierarchy) {
    const newViewInfo: ViewInfo = new ViewInfo(current.viewType, current.id, current.name, PersistencyState.Loadable, current.viewVersion);
    collector.push(newViewInfo);
  }
  current.children && current.children.forEach(node => addToCollector(collector, node.value));
};
