/* EmptyStructuredTableIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";
import theme from "../../theme/Theme";

class EmptyStructuredTableIcon extends MetusSvgIcon {

  svg(): JSX.Element {
    const highlightColor = theme.metus.selection.fill
    return <React.Fragment>
      <style type="text/css">{`
        .st0{display:none;}
        .st1{display:inline;fill:#EDEDED;}
        .st2{fill:#D7D7D7;}
        .st3{fill:#A8A8A8;}
        .st4{fill:#EDEDED;}
        .st5{fill:#F6F6F6;}
        .st6{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:11.9781,11.9781;}
        .st7{opacity:0.25;fill:#A8A8A8;}
        .st8{fill:none;stroke:#A8A8A8;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
        .st9{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:12;}
        .st10{display:inline;}
        .st11{fill:#CEE6FE;}
        .st12{fill:${highlightColor};}
        .st13{display:inline;fill:${highlightColor};}
        .st14{display:inline;fill:none;stroke:#24496E;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
        .st15{}
        .st16{font-size:30px;}
        .st17{fill:#D4D4D4;}
        .st18{fill:none;stroke:#A8A8A8;stroke-width:3;stroke-miterlimit:10;}
        .st19{fill:#24496E;}
        .st20{fill:#FFFFFF;}
        .st21{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-miterlimit:10;}
        .st22{fill:#CEE6FE;stroke:${highlightColor};stroke-linecap:round;stroke-miterlimit:10;}
        .st23{fill:#D6C365;}
        .st24{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.0034,12.0034;}
        .st25{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.1971,12.1971;}
        .st26{fill:none;stroke:#D6C365;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.0853,12.0853;}
        .st27{display:inline;fill:#24496E;}
        .st28{opacity:0.8;}
        .st29{opacity:0.3;}
        .st30{fill:#AEBCC9;}
        .st31{opacity:0.81;fill:#D6C365;}
        .st32{opacity:0.2;fill:#9CCDFD;}
      `}</style>
      <g id="BG" className="st0">
      </g>
      <g id="Ebene_1">
      </g>
      <g id="Ebene_8">
      </g>
      <g id="Ebene_2">
      </g>
      <g id="Ebene_3">
        <g className="st0">
          <g className="st10">
            <path className="st11" d="M322.12,133.8c-0.16-6.13-5.06-11.07-11.19-11.29c-12.23-0.43-48.16,0.3-61.3,0.56
				c-20.68,0.41-52.58,0.01-66.28,0.07c-6.39,0.03-11.56,5.19-11.57,11.57l-0.07,32.33c-0.01,5.59,4.42,10.16,10,10.31
				c31.32,0.82,97.25-1.68,128.76-2.96c7.05-0.29,12.54-6.18,12.36-13.23L322.12,133.8z"/>
            <path className="st12" d="M321.1,133.83c-0.15-1.84-0.76-3.61-1.74-5.12c-0.48-0.77-1.08-1.44-1.74-2.05
				c-0.63-0.64-1.39-1.13-2.16-1.58c-0.79-0.43-1.63-0.74-2.5-0.96c-0.44-0.08-0.88-0.19-1.32-0.22c-0.44-0.04-0.89-0.08-1.41-0.08
				c-2-0.06-4.01-0.08-6.03-0.09c-8.06-0.03-16.13,0.08-24.2,0.2l-24.22,0.46c-8.07,0.2-16.18,0.32-24.26,0.36
				c-8.08,0.03-16.17,0.07-24.25-0.01c-8.06-0.05-16.2-0.15-24.18-0.15c-3.56,0.05-6.98,2.15-8.67,5.29
				c-0.85,1.58-1.29,3.3-1.25,5.16l-0.01,6.06l-0.03,24.23l0,1.51c0.02,0.49,0.01,0.89,0.08,1.33c0.09,0.87,0.36,1.7,0.7,2.5
				c0.71,1.58,1.89,2.97,3.37,3.88c0.73,0.47,1.53,0.83,2.37,1.04c0.41,0.14,0.85,0.18,1.27,0.25c0.44,0.03,0.84,0.07,1.38,0.07
				l6.04,0.1c8.06,0.08,16.13,0,24.2-0.11c16.14-0.25,32.28-0.7,48.42-1.23l48.43-1.6c1.05-0.03,1.91-0.05,2.81-0.22
				c0.89-0.17,1.76-0.44,2.59-0.81c1.66-0.74,3.14-1.88,4.29-3.3c1.15-1.42,1.97-3.12,2.35-4.91c0.19-0.9,0.28-1.81,0.26-2.75
				l-0.05-3.03l-0.2-12.12l-0.1-6.06C321.33,137.86,321.34,135.84,321.1,133.83z M323.15,133.78c-0.17,2.02,0.01,4.04,0.09,6.06
				l0.28,6.05l0.54,12.11l0.13,3.03c0.06,1.09-0.02,2.23-0.23,3.33c-0.39,2.21-1.38,4.31-2.77,6.07c-1.39,1.76-3.2,3.19-5.24,4.14
				c-1.02,0.47-2.1,0.82-3.2,1.04c-1.09,0.23-2.28,0.27-3.24,0.31c-16.15,0.65-32.3,1.14-48.45,1.67
				c-16.15,0.5-32.31,0.92-48.48,1.13c-8.08,0.09-16.17,0.16-24.27,0.06l-6.07-0.11c-2.13,0.03-4.53-0.56-6.41-1.8
				c-1.93-1.2-3.47-3-4.39-5.07c-0.45-1.04-0.79-2.14-0.91-3.27c-0.08-0.55-0.08-1.15-0.1-1.67l0.01-1.51l0.08-24.23l0.02-6.06
				c-0.05-2.16,0.47-4.46,1.53-6.42c1.07-1.97,2.65-3.65,4.55-4.83c1.9-1.18,4.13-1.86,6.37-1.92c16.25-0.09,32.34,0.02,48.5,0.01
				c8.08-0.01,16.13-0.1,24.21-0.28l24.24-0.39c8.08-0.1,16.16-0.18,24.26-0.13c2.03,0.02,4.05,0.04,6.09,0.1
				c0.49,0,1.04,0.04,1.61,0.1c0.56,0.05,1.11,0.17,1.66,0.28c1.09,0.28,2.15,0.68,3.14,1.22
				C320.73,124.88,323.26,129.37,323.15,133.78z"/>
          </g>
          <path className="st13" d="M211.1,136.81l11.95-0.4l-0.4,9.56H211.1V136.81z M188.89,155.01l11.44,0.46l0.4-9.56l-11.84-0.06V155.01z
			 M222.84,153.51l-11.88-1.03l-0.4,11.2l11.49,0.23L222.84,153.51z M204.07,136.62v27.4l3.05-0.23l-0.45-27.17H204.07z"/>
        </g>
        <g className="st0">
          <path className="st16" d="M145.89,117c-4.55-2.99-9.1-5.97-13.65-8.96"/>
          <path className="st16" d="M165.74,110.89c-3.62-5.48-6.23-11.32-7.76-17.31"/>
          <path className="st16" d="M196.43,106.95c-2.99-4.26-4.23-9.41-3.45-14.34"/>
          <path className="st16" d="M219.34,107.21c0.63-5.55,0.59-11.16-0.13-16.71"/>
          <path className="st16" d="M250.1,109.28c-0.48-5.13-0.41-10.29,0.2-15.41"/>
          <path className="st16" d="M272.44,108.56c1.58-5.24,3.16-10.48,4.75-15.73"/>
          <path className="st16" d="M322.87,106.75c1.58-5.24,3.16-10.48,4.75-15.73"/>
          <path className="st16" d="M297.21,111.53c1.13-6.79,3.12-13.53,5.94-20.03"/>
          <path className="st16" d="M345.58,110.31c2.51-5.49,6.25-10.55,10.97-14.83"/>
          <path className="st16" d="M370.81,115.04c4.2-3.84,8.41-7.69,12.61-11.53"/>
          <path className="st16" d="M383.2,133.57c6.26-0.38,12.52-0.76,18.78-1.14"/>
          <path className="st16" d="M381.81,151.74c7.37,0.82,14.77,1.47,22.18,1.96"/>
          <path className="st16" d="M382.31,167.97c5.62,2.34,11.59,4.08,17.71,5.15"/>
          <path className="st16" d="M378.3,183.86c3.25,3.7,7.27,6.9,11.8,9.41"/>
          <path className="st16" d="M357.5,186.1c1.81,5.77,4.24,11.4,7.26,16.79"/>
          <path className="st16" d="M299.51,192.7c0.96,4.54,1.91,9.08,2.87,13.63"/>
          <path className="st16" d="M275.86,191.58c-0.77,6.91-1.55,13.82-2.32,20.74"/>
          <path className="st16" d="M234.99,191.25c-0.48,5.4-0.96,10.79-1.44,16.19"/>
          <path className="st16" d="M199.95,191.66c-3.8,5.8-6.69,12.04-8.55,18.49c0.06-0.54,0.12-1.09,0.18-1.63"/>
          <path className="st16" d="M339.98,207.63c-1.93-6.66-4.8-12.91-8.49-18.52c0.37,0.4,0.75,0.8,1.12,1.2"/>
          <path className="st16" d="M157.39,187.37c-6.48,3.71-12.82,9.29-18.19,13.19"/>
          <path className="st16" d="M141.16,163.78c-7.04,0.24-14.96,1.38-21.88,2.13"/>
          <path className="st16" d="M141.05,136.16c-8.02-2.8-12.27-4.3-20.62-6.02"/>
        </g>
      </g>
      <g id="Ebene_6">
      </g>
      <g id="Ebene_9">
        <g>
          <path className="st3" d="M69.73,508.6c0.13-1,0.5-1.93,0.94-2.83c0.44-0.9,0.95-1.77,1.54-2.6c1.18-1.64,2.72-3.11,4.59-4.05
			c1.91-0.96,4.08-1.26,6.18-0.9c2.08,0.35,4.17,1.46,5.41,3.36l-1.77,0.18c1.34-3.43,3.04-6.91,5.84-9.64
			c1.39-1.35,3.05-2.47,4.91-3.14c1.85-0.66,3.82-0.94,5.76-0.82c1.95,0.13,3.87,0.66,5.61,1.58c1.74,0.93,3.25,2.29,4.39,3.88
			c2.28,3.19,3.19,7.35,2.24,11.22l-1.91-0.63c1.15-2.81,3.78-4.72,6.54-5.45c2.77-0.77,5.89-0.47,8.39,1.09
			c2.48,1.53,4.14,4.05,4.96,6.7c0.41,1.33,0.62,2.71,0.63,4.09l-0.03,1.03l-0.11,1.03l-0.19,1.02c-0.09,0.33-0.18,0.66-0.16,1.03
			l-1.32-0.37c0.35-0.55,0.31-1.21,0.39-1.82l0.02-0.94l-0.05-0.93c-0.11-1.24-0.37-2.46-0.82-3.6c-0.83-2.29-2.3-4.35-4.34-5.59
			c-1.99-1.24-4.58-1.51-6.89-0.88c-2.32,0.61-4.41,2.15-5.3,4.31l-1.68-0.56c0.83-3.27,0.06-6.92-1.9-9.7
			c-1.93-2.82-5.2-4.55-8.62-4.77c-1.7-0.12-3.44,0.1-5.03,0.72c-1.59,0.6-3.01,1.59-4.22,2.81c-2.43,2.46-4,5.72-5.36,9.02
			l-0.68,1.65l-0.95-1.49c-0.88-1.37-2.44-2.26-4.13-2.56c-1.69-0.3-3.5-0.07-5.03,0.69c-1.57,0.78-2.9,2.01-3.96,3.46
			c-0.53,0.73-1,1.51-1.4,2.33c-0.4,0.82-0.75,1.66-1.22,2.48L69.73,508.6z"/>
        </g>
        <g>
          <path className="st3" d="M73.27,207.7c0.35-1.3,1.24-2.33,2.11-3.3c0.9-0.96,1.93-1.79,3.05-2.47c2.24-1.37,4.86-2.16,7.52-2.14
			s5.29,0.84,7.53,2.24c2.25,1.4,4.08,3.46,5.23,5.86l-1.78,0.8c-0.51-1.26-0.55-2.67-0.28-3.89c0.26-1.24,0.75-2.35,1.27-3.41
			c1.1-2.09,2.3-4,3.71-5.88c1.4-1.85,3.04-3.6,5.03-4.97c1-0.67,2.09-1.24,3.25-1.65c1.17-0.38,2.4-0.6,3.63-0.63
			c2.48-0.07,4.86,0.68,6.99,1.67c2.15,0.99,4.07,2.31,5.91,3.66c1.86,1.33,3.68,2.91,5.07,4.85c0.7,0.97,1.28,2.08,1.55,3.32
			c0.07,0.31,0.12,0.62,0.13,0.94c0.04,0.32,0,0.64-0.03,0.96c-0.01,0.32-0.14,0.63-0.21,0.94c-0.1,0.3-0.25,0.58-0.28,0.93
			l-1.22-0.63c0.38-0.37,0.4-0.91,0.44-1.37c0-0.24,0.01-0.48-0.04-0.72c-0.02-0.24-0.09-0.48-0.16-0.71
			c-0.29-0.94-0.88-1.8-1.53-2.61c-1.32-1.66-3-3.02-4.82-4.31c-1.81-1.31-3.65-2.56-5.62-3.49c-1.96-0.92-4.04-1.58-6.13-1.53
			c-1.04,0.02-2.07,0.2-3.07,0.52c-0.99,0.34-1.93,0.82-2.82,1.41c-1.77,1.19-3.3,2.78-4.64,4.52c-1.33,1.74-2.55,3.66-3.58,5.58
			c-1,1.9-1.6,4.07-0.84,5.8l-1.59,0.72c-1.01-2.06-2.67-3.78-4.61-5.02c-1.94-1.24-4.21-1.97-6.5-2.01
			c-2.29-0.04-4.59,0.63-6.57,1.81c-0.99,0.59-1.91,1.32-2.7,2.15c-0.83,0.82-1.39,1.81-2.17,2.71L73.27,207.7z"/>
        </g>
        <g>
          <path className="st3" d="M846.87,93.13c-1.05-1.66-2.36-3.13-3.99-4.18c-1.62-1.04-3.54-1.64-5.43-1.6c-3.82,0.06-7.47,2.65-9.02,6.14
			l-1.76-0.75c0.58-1.45,0.08-3.25-0.74-4.91c-0.84-1.67-1.86-3.37-2.96-4.89c-1.11-1.53-2.38-2.93-3.86-3.97
			c-1.49-1.03-3.13-1.64-4.94-1.65c-3.59-0.08-7.29,1.6-9.66,4.34c-1.15,1.28-1.73,3.11-2.23,4.94c-0.47,1.86-0.8,3.78-1.15,5.72
			l-1.85-0.22c0.15-2.76,0.1-5.54-0.2-8.28c-0.3-2.73-0.86-5.44-1.89-7.92c-1.03-2.47-2.6-4.7-4.83-5.97
			c-2.33-1.31-4.92-2.19-7.59-2.39c-1.33-0.13-2.67-0.09-3.99,0.1c-1.32,0.17-2.62,0.51-3.86,0.99c-2.48,0.94-4.7,2.52-6.25,4.64
			c-1.57,2.12-2.54,4.69-3.1,7.33c-0.56,2.65-0.75,5.42-0.57,8.11l-1.96,0.33c-0.56-2.08-1.14-4.11-2.03-5.95
			c-0.87-1.85-2.12-3.42-3.77-4.46c-1.67-1.05-3.69-1.62-5.74-2.15c-2.05-0.52-4.12-0.9-6.18-0.95c-2.05-0.05-4.11,0.21-5.95,1.01
			c-1.83,0.78-3.4,2.16-4.56,3.83c-1.15,1.68-1.91,3.65-2.14,5.69c-0.12,1.02-0.11,2.05,0.04,3.06c0.08,0.51,0.18,1.01,0.34,1.5
			c0.13,0.49,0.34,0.97,0.65,1.38c0.23,0.31,0.16,0.75-0.15,0.97c-0.31,0.23-0.75,0.16-0.97-0.15c-0.05-0.07-0.09-0.14-0.11-0.22
			c-0.33-1.11-0.83-2.14-1.01-3.28c-0.22-1.12-0.28-2.28-0.19-3.42c0.16-2.29,0.92-4.52,2.15-6.49c1.22-1.97,3.06-3.59,5.21-4.57
			c2.14-0.96,4.48-1.32,6.74-1.26c2.27,0.05,4.48,0.46,6.61,0.99c2.12,0.54,4.32,1.15,6.31,2.41c2.01,1.26,3.51,3.21,4.46,5.26
			c0.98,2.06,1.57,4.2,2.13,6.3l-1.75,0.29c-0.18-2.91,0.03-5.8,0.63-8.64c0.61-2.83,1.66-5.64,3.44-8.04
			c1.78-2.41,4.33-4.21,7.08-5.24c1.38-0.53,2.81-0.91,4.27-1.09c1.46-0.2,2.94-0.25,4.4-0.1c2.94,0.23,5.79,1.2,8.33,2.64
			c2.68,1.53,4.47,4.16,5.58,6.84c1.11,2.72,1.69,5.57,2,8.42c0.3,2.85,0.35,5.72,0.19,8.57l-1.65-0.19
			c0.35-1.94,0.7-3.89,1.19-5.84c0.25-0.98,0.53-1.96,0.92-2.92c0.38-0.95,0.91-1.95,1.62-2.76c1.35-1.62,3.07-2.88,4.97-3.76
			c1.89-0.88,3.98-1.38,6.11-1.34c1.05,0.02,2.15,0.15,3.18,0.51c1.03,0.34,1.98,0.86,2.85,1.46c1.73,1.23,3.11,2.8,4.3,4.44
			c1.19,1.67,2.19,3.35,3.11,5.2c0.44,0.93,0.83,1.92,1.03,3.01c0.21,1.08,0.16,2.29-0.27,3.4l-1.67-0.71
			c0.94-2.06,2.46-3.83,4.32-5.09c1.86-1.27,4.08-2.04,6.37-2.07c2.29-0.03,4.53,0.69,6.42,1.92c1.87,1.22,3.51,2.94,4.26,5.07
			c0.13,0.36-0.06,0.75-0.42,0.88C847.37,93.52,847.04,93.39,846.87,93.13z"/>
        </g>
        <g>
          <text transform="matrix(1 0 0 1 203.1699 86.2224)">
            <tspan x="0" y="0" className="st12 st15 st16">Drag &amp; drop a table</tspan>
            <tspan x="0" y="38" className="st12 st15 st16">to build a Structured Table.</tspan>
          </text>
        </g>
        <g>
          <g>
            <path className="st3" d="M155.17,623.94c0.09-0.32,0.31-0.49,0.51-0.66l0.62-0.53l1.24-1.06c0.82-0.71,1.68-1.38,2.52-2.07
				c1.69-1.37,3.5-2.58,5.14-4.01l5.05-4.12c0.83-0.7,1.7-1.35,2.63-1.93l1.38-0.88c0.23-0.15,0.46-0.3,0.68-0.45l0.34-0.23
				c0.1-0.09,0.27-0.1,0.27-0.31l0.86,1.07c-0.22-0.05-0.25,0.13-0.35,0.21c-0.09,0.1-0.19,0.2-0.27,0.31l-0.53,0.65
				c-0.36,0.41-0.78,0.76-1.2,1.1c-0.87,0.66-1.75,1.29-2.6,1.97l-5.14,4.02c-1.74,1.3-3.34,2.78-5.04,4.14
				c-0.85,0.67-1.68,1.37-2.55,2.03l-1.29,0.99l-0.65,0.5c-0.22,0.16-0.42,0.34-0.76,0.36L155.17,623.94z"/>
          </g>
          <g>
            <path className="st3" d="M158.13,608.69c0.31,0.02,0.47,0.19,0.65,0.34l0.53,0.47l1.06,0.93c0.72,0.62,1.39,1.28,2.08,1.91
				c1.38,1.29,2.62,2.72,4.05,3.96l4.15,3.85c0.7,0.63,1.37,1.3,1.96,2.05l0.91,1.1c0.15,0.19,0.3,0.37,0.46,0.54l0.23,0.27
				c0.09,0.07,0.11,0.23,0.31,0.19l-0.95,0.99c0.04-0.21-0.13-0.2-0.22-0.29c-0.1-0.06-0.2-0.13-0.31-0.19l-0.64-0.36
				c-0.41-0.26-0.76-0.57-1.1-0.9c-0.67-0.67-1.3-1.37-1.99-2.01l-4.05-3.95c-1.32-1.35-2.79-2.54-4.16-3.83
				c-0.68-0.65-1.38-1.28-2.04-1.96l-1-1l-0.5-0.5c-0.17-0.16-0.35-0.32-0.38-0.63L158.13,608.69z"/>
          </g>
        </g>
        <g>
          <path className="st2" d="M243.36,304.79c0.48,6.35,1.85,12.3,13.14,12.46c24.36,0.36,567.38-0.35,591.7-1.56
			c11.13-0.55,14.16-4.78,15.74-12.81c1.18-5.99,1.53-16.25,0.32-23.27c-1.26-7.33-7.05-11.59-12.41-11.83
			c-22.61-1-579.67-1.03-593.63-0.07c-7.63,0.53-12.87,3.06-13.82,11.5C243.31,288.85,242.87,298.46,243.36,304.79z"/>
          <path className="st3" d="M244.04,304.74c0.14,1.69,0.34,3.36,0.81,4.92c0.46,1.56,1.23,2.99,2.38,4.04c1.15,1.06,2.63,1.74,4.19,2.15
			c1.58,0.4,3.24,0.55,4.9,0.58l20.55,0.01l41.13,0l82.26-0.09l164.52-0.33l164.52-0.41c27.42-0.09,54.84-0.18,82.26-0.38
			l20.56-0.19c3.43-0.03,6.85-0.07,10.27-0.16c3.41-0.12,6.88,0.03,10.11-0.61c3.24-0.57,6.31-1.96,8.02-4.64
			c1.73-2.64,2.4-6.05,2.89-9.34c0.45-3.34,0.63-6.74,0.65-10.13c-0.01-3.39-0.15-6.79-0.69-10.09c-0.49-3.21-2.02-6.22-4.42-8.34
			c-1.2-1.05-2.6-1.87-4.09-2.4c-1.51-0.57-3.03-0.63-4.78-0.65c-6.82-0.14-13.69-0.17-20.54-0.23l-41.13-0.18l-82.26-0.17
			l-164.52-0.08l-164.52-0.02l-82.26,0.09l-20.56,0.08l-10.28,0.09c-3.43,0.06-6.84-0.04-10.09,0.73c-1.61,0.37-3.17,0.96-4.49,1.87
			c-1.33,0.9-2.38,2.16-3.08,3.61c-0.71,1.45-1.1,3.08-1.29,4.73c-0.19,1.69-0.37,3.4-0.53,5.1c-0.31,3.4-0.54,6.81-0.67,10.23
			C243.71,297.94,243.71,301.37,244.04,304.74z M242.67,304.84c-0.28-6.93-0.02-13.79,0.55-20.65c0.14-1.71,0.31-3.42,0.49-5.14
			c0.2-1.75,0.61-3.52,1.4-5.17c0.78-1.64,2-3.12,3.52-4.16c1.51-1.06,3.23-1.7,4.95-2.11c3.47-0.84,6.98-0.73,10.39-0.82
			l10.29-0.14l20.57-0.19c27.42-0.27,54.84-0.23,82.26-0.3l164.53-0.08l164.53,0.15l82.26,0.21l41.13,0.2
			c6.86,0.05,13.7,0.09,20.58,0.23c0.87,0.03,1.7,0.01,2.64,0.12c0.91,0.11,1.81,0.32,2.67,0.63c1.73,0.61,3.32,1.55,4.69,2.75
			c2.74,2.41,4.47,5.86,5,9.39c0.56,3.46,0.7,6.93,0.71,10.39c-0.02,3.46-0.21,6.92-0.68,10.37c-0.24,1.73-0.56,3.45-1.03,5.15
			c-0.47,1.7-1.12,3.4-2.14,4.94c-1.01,1.54-2.45,2.83-4.07,3.69c-1.62,0.88-3.36,1.37-5.1,1.7c-3.51,0.67-6.95,0.51-10.38,0.63
			c-3.44,0.09-6.87,0.13-10.3,0.15l-20.57,0.18l-82.26,0.45l-164.53,0.53l-164.53,0.23L318,318.23l-41.13-0.03
			c-6.86,0-13.71-0.01-20.58-0.13c-1.76-0.05-3.53-0.23-5.27-0.7c-1.73-0.47-3.46-1.27-4.82-2.57c-1.38-1.29-2.25-3.01-2.73-4.74
			C242.97,308.33,242.79,306.57,242.67,304.84z"/>
        </g>
        <g>
          <path className="st2" d="M863.83,488.47c-0.51-7.01-1.97-13.57-13.9-13.73c-25.74-0.35-564.07,1.47-589.77,2.85
			c-11.76,0.63-16.63,5.15-16.62,14.17c0,3.57-1.02,13.33,0,22.19c0.94,8.15,7.46,12.79,13.13,13.04
			c23.89,1.07,577.07,0.03,591.81-1.06c8.06-0.6,13.6-3.4,14.6-12.72C864.21,502.58,864.34,495.46,863.83,488.47z"/>
          <g>
            <path className="st3" d="M863.14,488.52c-0.14-1.69-0.32-3.37-0.73-4.97c-0.4-1.59-1.05-3.1-2.06-4.31c-2.02-2.47-5.4-3.38-8.67-3.6
				c-1.64-0.11-3.38-0.05-5.09-0.06l-5.14,0l-10.29,0l-41.17,0.07l-82.35,0.25l-164.69,0.68l-164.69,0.78
				c-27.45,0.15-54.9,0.31-82.34,0.58l-20.58,0.25c-3.43,0.03-6.85,0.11-10.27,0.2l-5.12,0.14c-1.68,0.11-3.35,0.28-4.97,0.62
				c-3.22,0.65-6.38,1.98-8.26,4.5c-0.94,1.24-1.56,2.73-1.88,4.3c-0.16,0.79-0.26,1.6-0.32,2.41c-0.03,0.41-0.04,0.82-0.04,1.23
				l-0.03,1.31c-0.33,6.89-0.74,13.69-0.03,20.43c0.27,3.27,1.58,6.36,3.82,8.68c2.21,2.31,5.3,3.85,8.45,4.01
				c6.8,0.19,13.7,0.19,20.55,0.25l41.17,0.13l82.34,0.04l164.69-0.22l164.69-0.31l82.34-0.27l20.58-0.14l10.29-0.12
				c1.72-0.02,3.42-0.07,5.11-0.13c1.68-0.13,3.35-0.35,4.95-0.78c1.6-0.43,3.13-1.07,4.43-2.03c1.31-0.95,2.35-2.22,3.08-3.68
				c0.73-1.46,1.16-3.07,1.41-4.72c0.22-1.65,0.37-3.39,0.54-5.08C863.48,502.17,863.81,495.32,863.14,488.52z M864.51,488.42
				c0.37,6.9,0.22,13.81-0.39,20.67c-0.16,1.72-0.29,3.4-0.51,5.16c-0.26,1.74-0.7,3.49-1.5,5.12c-0.79,1.63-1.98,3.09-3.46,4.18
				c-1.47,1.09-3.16,1.8-4.88,2.27c-1.72,0.47-3.47,0.71-5.21,0.85c-1.74,0.08-3.48,0.14-5.18,0.16l-10.3,0.18l-20.59,0.25
				c-27.45,0.34-54.9,0.39-82.35,0.5l-164.7,0.41l-164.7,0.15l-82.35-0.07l-41.18-0.15c-6.87-0.06-13.71-0.06-20.62-0.26
				c-3.7-0.19-7.15-1.95-9.66-4.56c-2.54-2.61-4.02-6.18-4.3-9.73c-0.73-6.92-0.3-13.87,0.03-20.68c0.04-0.82,0.01-1.69,0.07-2.6
				c0.06-0.89,0.17-1.78,0.35-2.67c0.37-1.77,1.08-3.52,2.2-5c1.12-1.49,2.62-2.64,4.24-3.46c1.62-0.83,3.35-1.34,5.08-1.7
				c1.73-0.36,3.48-0.54,5.22-0.65l5.16-0.14c3.44-0.09,6.88-0.17,10.31-0.2l20.59-0.24l82.35-0.66l164.69-0.89l164.69-0.58
				l82.35-0.2l41.18-0.04l10.3,0.01l5.15,0.03c1.72,0.03,3.42-0.01,5.2,0.13c1.76,0.14,3.54,0.46,5.24,1.12
				c1.7,0.65,3.31,1.71,4.47,3.17c1.18,1.45,1.88,3.19,2.29,4.92C864.23,484.95,864.39,486.69,864.51,488.42z"/>
          </g>
        </g>
        <g>
          <path className="st5" d="M848.46,388.3c-23.61,1.09-499.09,0.43-513.67-0.66c-7.97-0.6-12.67-3.53-14.45-12.81
			c-1.69-8.79-2.24-14.36-0.58-23.89c1.21-6.98,6.28-13.67,18.08-13.84c25.44-0.36,481.88,1.09,507.29,2.46
			c11.62,0.63,17.83,5.33,19.58,14.27c1.41,7.17,0.82,12.41-0.17,21.33C863.62,383.37,854.06,388.04,848.46,388.3z"/>
          <path className="st3" d="M848.49,388.98c-24.24,0.68-48.41,0.59-72.62,0.69l-72.6,0.11l-145.2-0.05c-48.4-0.08-96.8-0.12-145.2-0.38
			c-24.2-0.14-48.39-0.22-72.61-0.58c-3.01-0.09-6.11-0.01-9.21-0.66c-1.55-0.32-3.09-0.82-4.53-1.61
			c-1.44-0.78-2.73-1.87-3.73-3.17c-2.01-2.61-2.92-5.76-3.47-8.77c-0.56-2.98-1.07-6-1.32-9.07c-0.27-3.07-0.21-6.17,0.09-9.23
			c0.15-1.53,0.35-3.05,0.6-4.56c0.22-1.5,0.57-3.1,1.16-4.58c1.13-2.98,3.15-5.67,5.8-7.5c2.64-1.86,5.76-2.84,8.86-3.24
			c1.55-0.21,3.14-0.25,4.64-0.24l4.54-0.02l9.08-0.02l18.15,0l36.3,0.05l72.6,0.17l145.19,0.51c48.4,0.22,96.8,0.41,145.19,0.81
			l36.3,0.36l18.15,0.24c6.05,0.09,12.1,0.17,18.17,0.38c3.01,0.01,6.15,0.31,9.17,1.08c3.01,0.77,6,2.1,8.36,4.28
			c2.38,2.16,3.94,5.09,4.73,8.12c0.74,3.04,1.1,6.07,1.16,9.16c0.05,3.08-0.2,6.12-0.52,9.14l-0.51,4.52
			c-0.14,1.55-0.6,3.15-1.34,4.55c-1.47,2.84-3.9,5.02-6.56,6.59c-1.34,0.79-2.76,1.43-4.23,1.93
			C851.61,388.47,850.09,388.86,848.49,388.98z M848.42,387.61c2.87-0.07,5.76-1.14,8.27-2.63c2.51-1.51,4.73-3.57,6.02-6.14
			c0.65-1.28,1.03-2.64,1.15-4.1l0.44-4.51c0.27-3,0.48-5.99,0.38-8.96c-0.1-2.95-0.51-5.95-1.24-8.76c-0.79-2.78-2.2-5.34-4.3-7.24
			c-2.09-1.92-4.79-3.12-7.61-3.83c-2.85-0.71-5.77-0.98-8.81-0.99c-6.02-0.19-12.07-0.27-18.12-0.35l-18.14-0.21l-36.29-0.32
			L625,338.72l-145.19-0.56l-72.6-0.2l-36.3-0.06l-18.15,0l-9.07,0.02l-4.53,0.02c-1.53-0.01-2.97,0.03-4.43,0.22
			c-2.89,0.37-5.73,1.27-8.07,2.93c-2.36,1.63-4.15,3.99-5.18,6.68c-0.53,1.35-0.85,2.73-1.07,4.22c-0.25,1.48-0.44,2.96-0.59,4.44
			c-0.29,2.97-0.33,5.95-0.07,8.91c0.25,2.97,0.76,5.93,1.32,8.88c0.53,2.91,1.41,5.76,3.14,7.99c1.71,2.25,4.36,3.54,7.2,4.1
			c2.85,0.59,5.85,0.53,8.88,0.61c24.18,0.34,48.39,0.38,72.58,0.5l145.19,0.4l145.19,0.14
			C751.65,387.96,800.09,387.9,848.42,387.61z"/>
        </g>
        <g>
          <path className="st5" d="M851.64,456.31c-23.61,1.19-503.48,0.48-518.05-0.68c-7.97-0.63-12.88-3.72-14.44-13.74
			c-0.86-5.54-1.06-13.76-0.61-17.97c0.81-7.56,6.44-14.85,18.23-15.04c25.44-0.41,484.12-1.5,509.52-0.05
			c11.62,0.66,17.77,7.22,18.59,16.94c0.36,4.21,0.36,11.09-0.29,16.41C863.53,450.99,857.24,456.03,851.64,456.31z"/>
          <path className="st3" d="M851.67,456.99c-24.19,0.72-48.3,0.62-72.45,0.73l-72.43,0.12l-144.85-0.05
			c-48.28-0.08-96.57-0.14-144.85-0.38c-24.14-0.13-48.28-0.24-72.43-0.55c-3.02-0.04-6.03-0.09-9.08-0.18
			c-3.04-0.06-6.23-0.41-9.2-1.73c-1.47-0.67-2.85-1.62-3.97-2.83c-1.11-1.21-1.96-2.61-2.59-4.08c-1.25-2.94-1.7-6.06-2.01-9.09
			c-0.3-3.04-0.44-6.08-0.45-9.13c-0.01-1.52,0.02-3.05,0.12-4.59c0.09-1.53,0.3-3.14,0.76-4.66c0.89-3.05,2.63-5.88,5.05-7.99
			c2.41-2.12,5.41-3.47,8.49-4.1c1.54-0.33,3.1-0.49,4.66-0.53l4.54-0.06l9.06-0.06l18.11-0.09l36.21-0.14l72.42-0.21l144.85-0.26
			c48.28-0.04,96.57-0.09,144.85,0.05c24.14,0.12,48.28,0.17,72.44,0.55c3.03,0.08,6.03,0.06,9.13,0.37c3.08,0.35,6.17,1.2,8.91,2.8
			c2.75,1.57,5.04,3.97,6.48,6.79c1.47,2.81,2.1,5.97,2.25,9.03c0.17,3.05,0.17,6.08,0.05,9.12c-0.06,1.52-0.16,3.04-0.31,4.55
			c-0.16,1.5-0.32,3.06-0.74,4.57c-0.77,3.02-2.36,5.86-4.61,8.05c-1.12,1.1-2.43,2.01-3.85,2.69
			C854.83,456.39,853.28,456.86,851.67,456.99z M851.61,455.62c2.84-0.05,5.61-1.45,7.64-3.5c2.05-2.04,3.49-4.68,4.18-7.5
			c0.38-1.4,0.52-2.86,0.64-4.36c0.12-1.49,0.18-2.98,0.22-4.48c0.06-2.99,0.01-6-0.22-8.96c-0.11-1.49-0.3-2.91-0.66-4.31
			c-0.37-1.39-0.86-2.75-1.52-4c-1.31-2.53-3.33-4.64-5.82-6.05c-2.47-1.43-5.32-2.21-8.22-2.53c-2.9-0.28-5.95-0.27-8.96-0.34
			c-24.12-0.36-48.27-0.38-72.41-0.47l-144.85-0.09l-144.85,0.2l-72.42,0.18l-36.21,0.13l-18.1,0.09l-9.05,0.06l-4.51,0.05
			c-1.46,0.04-2.92,0.19-4.34,0.49c-2.84,0.58-5.54,1.81-7.69,3.69c-2.16,1.87-3.73,4.4-4.53,7.15c-0.42,1.38-0.61,2.79-0.7,4.28
			c-0.1,1.48-0.13,2.97-0.12,4.47c0.02,2.99,0.17,5.99,0.47,8.96c0.31,2.96,0.76,5.91,1.89,8.55c0.56,1.32,1.31,2.53,2.26,3.56
			c0.95,1.02,2.11,1.83,3.39,2.41c2.59,1.15,5.55,1.51,8.52,1.55c2.99,0.08,6.02,0.13,9.03,0.17c24.13,0.28,48.27,0.36,72.41,0.47
			l144.85,0.42l144.85,0.13C755.06,456.01,803.39,455.97,851.61,455.62z"/>
        </g>
        <g>
          <path className="st2" d="M850.93,248.31c-26.8,1.31-571.4,0.35-587.95-0.94c-9.04-0.7-14.62-4.12-16.4-15.21
			c-0.98-6.13-1.21-15.22-0.7-19.88c0.92-8.36,7.3-16.43,20.69-16.64c28.87-0.45,549.43-1.49,578.27,0.13
			c13.19,0.74,20.17,7.99,21.11,18.76c0.41,4.66,0.41,12.27-0.32,18.16C864.42,242.42,857.29,248,850.93,248.31z"/>
          <g>
            <path className="st3" d="M850.96,248.99c-27.4,0.76-54.72,0.65-82.08,0.76l-82.06,0.1l-164.11-0.11
				c-54.7-0.11-109.41-0.19-164.11-0.48c-27.35-0.15-54.7-0.29-82.06-0.62l-10.27-0.18c-3.44-0.08-7-0.28-10.41-1.53
				c-1.69-0.63-3.32-1.54-4.69-2.78c-1.37-1.23-2.47-2.74-3.27-4.36c-1.62-3.24-2.23-6.79-2.61-10.21
				c-0.38-3.44-0.56-6.88-0.59-10.33c-0.01-1.73,0.01-3.45,0.11-5.19c0.09-1.73,0.31-3.54,0.82-5.26c0.99-3.45,2.98-6.63,5.73-8.97
				c2.74-2.35,6.13-3.84,9.6-4.54c1.74-0.36,3.5-0.54,5.26-0.59l5.14-0.06l10.26-0.07l20.51-0.1l41.03-0.14l82.05-0.21l164.11-0.23
				c54.7-0.03,109.41-0.06,164.11,0.1c27.35,0.13,54.7,0.2,82.07,0.61c3.42,0.07,6.83,0.11,10.31,0.33
				c3.48,0.29,6.99,1.09,10.17,2.68c3.19,1.57,5.97,4.08,7.79,7.16c1.84,3.07,2.73,6.61,2.96,10.09c0.24,3.45,0.25,6.89,0.13,10.33
				c-0.06,1.72-0.17,3.44-0.33,5.16c-0.16,1.7-0.35,3.45-0.8,5.17c-0.88,3.41-2.66,6.62-5.25,9.06c-1.28,1.22-2.77,2.23-4.38,2.99
				C854.52,248.34,852.77,248.86,850.96,248.99z M850.9,247.62c1.61-0.04,3.23-0.44,4.72-1.12c1.49-0.7,2.85-1.66,4.03-2.8
				c2.38-2.28,4.02-5.29,4.81-8.5c0.4-1.6,0.57-3.26,0.69-4.96c0.13-1.69,0.2-3.38,0.23-5.08c0.06-3.39,0-6.8-0.3-10.15
				c-0.26-3.31-1.17-6.53-2.83-9.31c-1.66-2.78-4.15-5.02-7.08-6.46c-2.92-1.46-6.2-2.2-9.52-2.46c-3.35-0.2-6.78-0.24-10.19-0.31
				c-27.33-0.38-54.69-0.42-82.04-0.52l-164.11-0.14l-164.11,0.18l-82.05,0.18l-41.03,0.13l-20.51,0.09l-10.25,0.06l-5.11,0.06
				c-1.67,0.04-3.32,0.21-4.94,0.55c-3.24,0.65-6.33,2.01-8.81,4.14c-2.48,2.11-4.29,4.98-5.19,8.12c-0.47,1.57-0.67,3.19-0.76,4.87
				c-0.1,1.68-0.12,3.37-0.11,5.07c0.04,3.39,0.23,6.79,0.61,10.15c0.39,3.35,0.98,6.68,2.45,9.6c0.72,1.45,1.69,2.76,2.87,3.82
				c1.18,1.06,2.6,1.86,4.12,2.42c1.52,0.56,3.15,0.9,4.8,1.11c1.65,0.23,3.34,0.23,5.03,0.3l10.24,0.17
				c27.34,0.31,54.69,0.41,82.04,0.54l164.11,0.51l164.11,0.2C741.51,248.03,796.26,248.03,850.9,247.62z"/>
          </g>
        </g>
        <g>
          <path className="st5" d="M851.53,598.82c-23.61,1.11-502.22,0.87-516.8-0.22c-7.97-0.6-13.45-3.44-14.44-12.91
			c-1.13-10.79-1.04-17.12-0.54-24.23c0.5-7.12,1.93-13.79,13.72-13.96c25.44-0.38,489.38,0.67,514.79,2.04
			c11.62,0.63,16.44,5.21,16.44,14.37c0,3.63,0.79,12.65-0.21,21.64C863.56,593.83,857.13,598.55,851.53,598.82z"/>
          <path className="st3" d="M851.56,599.5c-24.35,0.7-48.62,0.63-72.94,0.75l-72.91,0.17l-145.83,0.07
			c-48.61-0.04-97.22-0.04-145.83-0.25c-24.31-0.12-48.6-0.18-72.92-0.51c-1.53-0.04-3.03-0.04-4.58-0.1
			c-1.54-0.04-3.09-0.13-4.65-0.39c-1.55-0.25-3.11-0.63-4.61-1.27c-1.49-0.63-2.91-1.53-4.07-2.72c-2.37-2.4-3.39-5.68-3.79-8.77
			c-0.35-3.08-0.6-6.09-0.77-9.15c-0.35-6.12-0.21-12.23,0.38-18.37c0.19-1.55,0.46-3.12,1.02-4.65c0.54-1.53,1.4-3.01,2.6-4.19
			c1.19-1.19,2.69-2.01,4.22-2.55c1.54-0.52,3.12-0.81,4.69-0.93c1.59-0.13,3.1-0.09,4.62-0.11l4.56-0.03l9.12-0.02l18.23-0.02
			l36.46,0.02l72.91,0.11l145.83,0.38c48.61,0.18,97.22,0.33,145.83,0.67l36.46,0.32c12.16,0.12,24.3,0.24,36.47,0.51l4.57,0.13
			c1.51,0.02,3.07,0.12,4.61,0.32c3.07,0.41,6.23,1.19,8.93,3.03c1.33,0.93,2.51,2.13,3.34,3.54c0.84,1.4,1.36,2.96,1.64,4.53
			c0.3,1.55,0.32,3.18,0.32,4.66l0.14,4.54c0.1,3.04,0.14,6.1,0.04,9.16c-0.05,1.53-0.12,3.06-0.25,4.58
			c-0.06,0.76-0.14,1.53-0.22,2.29c-0.08,0.76-0.19,1.56-0.38,2.32c-0.69,3.08-2.32,5.97-4.67,8.1c-1.17,1.07-2.51,1.95-3.96,2.59
			C854.73,598.94,853.17,599.38,851.56,599.5z M851.49,598.13c1.42-0.02,2.86-0.36,4.19-0.94c1.32-0.6,2.55-1.42,3.61-2.42
			c2.13-1.98,3.59-4.64,4.2-7.49c0.32-1.4,0.39-2.92,0.51-4.42c0.1-1.5,0.16-3.01,0.18-4.52c0.05-3.02-0.03-6.04-0.17-9.06
			l-0.2-4.57c-0.02-1.56-0.08-2.96-0.37-4.38c-0.28-1.4-0.74-2.75-1.45-3.93c-0.7-1.19-1.69-2.19-2.84-2.98
			c-2.32-1.58-5.23-2.32-8.15-2.69c-1.47-0.19-2.95-0.27-4.47-0.3l-4.54-0.12c-12.12-0.25-24.29-0.36-36.43-0.47l-36.45-0.28
			l-145.82-0.7l-145.82-0.43l-72.91-0.13l-36.45-0.03l-18.23,0.01l-9.11,0.02l-4.55,0.03c-1.52,0.02-3.05-0.02-4.5,0.1
			c-2.9,0.22-5.84,1-7.81,2.96c-2,1.94-2.78,4.87-3.12,7.79c-0.59,5.97-0.74,12.04-0.36,18.06c0.18,3.01,0.44,6.05,0.79,9.02
			c0.38,2.89,1.33,5.74,3.31,7.74c1.96,2.01,4.79,3.01,7.68,3.45c1.45,0.24,2.94,0.33,4.44,0.36c1.49,0.06,3.03,0.06,4.54,0.09
			c24.29,0.3,48.6,0.33,72.9,0.43l145.82,0.28l145.82,0.01C754.3,598.57,802.95,598.47,851.49,598.13z"/>
        </g>
        <g>
          <path className="st5" d="M851.51,663.29c-23.61,1.11-502.22,0.51-516.8-0.59c-7.97-0.6-11.99-3.65-14.45-12.97
			c-1.82-6.9-2.36-13.38-1.86-20.51c0.5-7.15,1.92-13.85,13.71-14.02c25.44-0.38,491-1.38,516.41,0.01
			c11.62,0.63,16.23,6.79,16.23,15.99c0,3.64,0.7,9.74-0.29,18.78C863.54,658.29,857.11,663.03,851.51,663.29z"/>
          <path className="st3" d="M851.54,663.98c-24.26,0.69-48.45,0.6-72.68,0.71l-72.65,0.12l-145.3-0.03c-48.43-0.07-96.87-0.11-145.3-0.35
			c-24.22-0.13-48.43-0.22-72.66-0.54c-3.03-0.07-6.05-0.06-9.16-0.29c-1.55-0.16-3.12-0.43-4.65-0.94
			c-1.53-0.51-3.01-1.29-4.26-2.36c-2.54-2.17-3.93-5.19-4.89-8.12c-0.94-2.97-1.57-5.95-2.04-9c-0.46-3.04-0.65-6.12-0.65-9.19
			c0-1.53,0.05-3.07,0.16-4.6c0.1-1.53,0.24-3.07,0.53-4.62c0.3-1.54,0.75-3.11,1.55-4.56c0.79-1.45,1.96-2.73,3.36-3.63
			c2.83-1.81,6.12-2.23,9.2-2.3c48.45-0.32,96.87-0.38,145.31-0.51l145.3-0.23c48.43-0.03,96.87-0.08,145.3,0.06l36.33,0.18
			c12.11,0.07,24.21,0.16,36.34,0.36l4.56,0.12c1.53,0.06,3.02,0.02,4.58,0.18c3.08,0.31,6.24,1.04,8.98,2.74
			c2.76,1.66,4.85,4.43,5.79,7.47c0.49,1.52,0.75,3.08,0.86,4.64c0.06,0.77,0.06,1.58,0.06,2.32c0,0.74,0.03,1.49,0.06,2.25
			c0.12,3.02,0.19,6.08,0.08,9.13c-0.05,1.52-0.14,3.05-0.28,4.57c-0.15,1.51-0.24,3.04-0.61,4.59c-0.7,3.06-2.33,5.94-4.67,8.06
			c-1.16,1.07-2.5,1.94-3.94,2.58C854.7,663.41,853.15,663.85,851.54,663.98z M851.48,662.61c1.42-0.02,2.85-0.36,4.17-0.94
			c1.32-0.6,2.54-1.42,3.59-2.41c2.13-1.97,3.59-4.62,4.21-7.45c0.32-1.4,0.4-2.9,0.52-4.4c0.11-1.5,0.18-3,0.2-4.5
			c0.06-3-0.06-5.99-0.22-9.02c-0.04-0.76-0.08-1.52-0.09-2.29c-0.01-0.78-0.03-1.48-0.1-2.21c-0.12-1.45-0.41-2.87-0.84-4.22
			c-0.86-2.71-2.63-5.03-5.06-6.48c-2.41-1.48-5.3-2.17-8.21-2.44c-1.44-0.15-2.99-0.11-4.49-0.17l-4.52-0.11
			c-12.09-0.18-24.2-0.26-36.31-0.31L768,615.52l-145.3-0.1l-145.3,0.17c-48.43,0.11-96.87,0.16-145.29,0.46
			c-2.92,0.05-5.91,0.49-8.26,2c-1.16,0.75-2.11,1.78-2.78,3c-0.68,1.22-1.09,2.61-1.36,4.04c-0.28,1.43-0.41,2.91-0.51,4.41
			c-0.1,1.49-0.15,2.99-0.16,4.48c-0.01,2.99,0.2,5.97,0.65,8.92c0.46,2.94,1.1,5.9,2,8.7c0.91,2.78,2.22,5.49,4.36,7.3
			c2.13,1.84,5.01,2.58,7.92,2.87c2.94,0.21,5.98,0.2,9,0.27c24.2,0.29,48.42,0.35,72.64,0.45l145.3,0.38l145.3,0.12
			C754.63,662.97,803.1,662.91,851.48,662.61z"/>
        </g>
        <g>
          <path className="st5" d="M331.89,682.91c23.87-0.99,505.17,0.1,519.91,1.12c8.05,0.56,13.43,3.2,14.62,11.91
			c0.9,6.56,0.93,15.94,0.44,22.48c-0.49,6.56-8.71,10.57-18.19,10.68c-25.72,0.3-488,0.85-513.68-0.45
			c-11.75-0.59-16.41-6.24-16.43-14.68c-0.01-3.34-1.06-11.47,0.26-18.87C320.16,687.52,326.23,683.14,331.89,682.91z"/>
          <path className="st3" d="M331.86,682.22c24.27-0.62,48.48-0.51,72.72-0.58l72.7-0.03l145.4,0.18c48.47,0.12,96.93,0.21,145.4,0.48
			c24.23,0.14,48.46,0.26,72.7,0.56c3.03,0.04,6.05,0.08,9.11,0.17c3.06,0.06,6.21,0.37,9.24,1.5c1.5,0.57,2.96,1.4,4.17,2.53
			c1.22,1.12,2.16,2.53,2.79,4.03c0.65,1.49,1.02,3.06,1.24,4.62c0.21,1.53,0.35,3.06,0.47,4.58c0.41,6.11,0.4,12.19-0.03,18.31
			c-0.12,1.69-0.78,3.33-1.76,4.66c-0.98,1.34-2.24,2.43-3.6,3.28c-2.73,1.71-5.79,2.63-8.87,3.13c-1.54,0.25-3.1,0.37-4.65,0.41
			l-4.55,0.03l-9.09,0.05l-18.18,0.06l-36.35,0.08l-72.7,0.12l-145.4,0.1c-48.47-0.01-96.93,0-145.4-0.16
			c-24.23-0.13-48.46-0.2-72.71-0.53l-9.12-0.22c-3.07-0.12-6.22-0.59-9.16-1.82c-1.46-0.62-2.86-1.45-4.07-2.54
			c-1.22-1.07-2.19-2.41-2.9-3.86c-0.69-1.46-1.12-3.02-1.31-4.59c-0.11-0.78-0.16-1.57-0.16-2.35c-0.01-0.78-0.02-1.48-0.06-2.25
			c-0.32-6.03-0.67-12.2,0.69-18.3c0.77-3.04,2.42-5.91,4.82-7.98c1.19-1.04,2.54-1.89,4-2.49
			C328.7,682.75,330.26,682.34,331.86,682.22z M331.92,683.59c-1.43,0.02-2.86,0.32-4.2,0.87c-1.33,0.57-2.57,1.37-3.65,2.33
			c-2.18,1.92-3.65,4.56-4.34,7.37c-0.62,2.85-0.79,5.88-0.79,8.86c0,3,0.22,5.99,0.43,9.03c0.05,0.74,0.08,1.57,0.1,2.3
			c0.02,0.73,0.08,1.45,0.19,2.16c0.2,1.42,0.62,2.79,1.21,4.05c1.2,2.53,3.43,4.43,6.08,5.51c2.65,1.1,5.58,1.54,8.55,1.65
			l9.05,0.21c24.22,0.3,48.46,0.34,72.69,0.44l145.39,0.2l145.39-0.04l72.7-0.09l36.35-0.07l18.17-0.05l9.08-0.04l4.54-0.03
			c1.48-0.03,2.95-0.14,4.41-0.38c2.9-0.46,5.77-1.34,8.2-2.87c2.42-1.49,4.37-3.84,4.56-6.59c0.43-5.98,0.43-12.04,0-18.03
			c-0.27-2.99-0.44-6.02-1.59-8.62c-0.56-1.3-1.35-2.48-2.37-3.42c-1.02-0.95-2.25-1.65-3.59-2.16c-2.69-1-5.67-1.31-8.65-1.36
			c-3-0.08-6.04-0.12-9.07-0.16c-24.22-0.26-48.46-0.36-72.69-0.47l-145.39-0.51l-145.39-0.27
			C428.83,683.37,380.33,683.4,331.92,683.59z"/>
        </g>
        <g>
          <path className="st3" d="M268.41,305.78c0.22,1.53,0.23,3.07,0.25,4.61l0.08,4.61l0.15,9.22l0.3,18.44
			c0.19,12.29,0.14,24.58,0.39,36.87l0.52,36.87c0.04,1.52-0.01,3.13,0.16,4.47c0.19,1.39,0.7,2.73,1.38,3.96
			c1.38,2.49,3.69,4.42,6.28,5.76c1.32,0.63,2.7,1.13,4.14,1.45c1.46,0.3,2.88,0.44,4.44,0.48l4.61,0.2l2.3,0.1
			c0.77,0.01,1.53,0.13,2.31-0.01l0.01,0c0.38-0.07,0.75,0.18,0.82,0.56s-0.18,0.75-0.56,0.82c-0.1,0.02-0.21,0.01-0.31-0.01
			c-0.76-0.2-1.53-0.1-2.3-0.11l-2.31,0.02l-4.61,0.02c-1.5,0.02-3.18-0.03-4.75-0.33c-1.58-0.32-3.12-0.84-4.59-1.51
			c-2.91-1.4-5.53-3.58-7.16-6.46c-0.81-1.43-1.41-3-1.63-4.65c-0.21-1.7-0.13-3.18-0.17-4.72l-0.39-36.87
			c-0.07-12.29-0.4-24.58-0.54-36.87l-0.2-18.44l-0.1-9.22l-0.05-4.61c-0.02-1.54-0.05-3.07,0.14-4.61
			c0.04-0.38,0.39-0.65,0.77-0.61C268.12,305.23,268.37,305.48,268.41,305.78z"/>
        </g>
        <g>
          <path className="st3" d="M268.35,363.15c0.28-0.2,0.57-0.2,0.85-0.22l0.85-0.05l1.71-0.09c1.14-0.07,2.28-0.08,3.42-0.12
			c2.28-0.06,4.56,0.08,6.84-0.06l6.84-0.2c1.14-0.05,2.28-0.04,3.42,0.07l1.71,0.14c0.29,0.03,0.57,0.04,0.86,0.06l0.43,0.02
			c0.14-0.01,0.29,0.08,0.43-0.08l0.03-0.03c0.39-0.46,1.07-0.52,1.54-0.14s0.52,1.07,0.14,1.54c-0.39,0.46-1.07,0.52-1.54,0.14
			c-0.05-0.04-0.1-0.09-0.13-0.13c-0.15-0.17-0.29-0.05-0.43-0.05c-0.14,0.03-0.28,0.05-0.43,0.08l-0.85,0.2
			c-0.57,0.11-1.14,0.13-1.71,0.14c-1.14,0-2.28-0.05-3.42-0.02l-6.84,0.07c-2.28-0.03-4.56,0.17-6.84,0.23
			c-1.14,0.02-2.28,0.07-3.42,0.06l-1.71,0l-0.86,0c-0.29,0-0.57,0.02-0.86-0.18l-0.01,0c-0.38-0.26-0.48-0.78-0.22-1.16
			C268.2,363.28,268.27,363.21,268.35,363.15z"/>
        </g>
        <g>
          <path className="st3" d="M275.53,519.4c0.27,4.39,0.14,8.78,0.17,13.18l-0.01,13.17l-0.02,26.35c0.04,17.57-0.27,35.13-0.25,52.7
			l-0.11,52.7l-0.03,13.17l0,6.56c0.05,2.07,0.42,4.14,1.54,5.7c1.1,1.57,2.92,2.57,4.92,3.11c2.02,0.57,4.15,0.68,6.35,0.8
			l6.58,0.41l3.29,0.2c1.1,0.04,2.19,0.21,3.29,0.09l0.01,0c0.38-0.04,0.72,0.23,0.77,0.62c0.04,0.38-0.23,0.73-0.62,0.77
			c-0.08,0.01-0.15,0-0.23-0.01c-1.09-0.24-2.19-0.14-3.29-0.19l-3.29-0.06l-6.58-0.15c-2.18-0.01-4.49-0.1-6.74-0.68
			c-1.13-0.28-2.25-0.69-3.31-1.3c-1.05-0.61-2.01-1.44-2.73-2.46c-1.46-2.05-1.87-4.5-1.93-6.77l0.02-6.61l0.06-13.18l0.24-52.7
			l0.09-52.7l0.12-26.35l0.06-13.18l0.03-6.59c0.01-2.2,0.02-4.39,0.21-6.59l0-0.01c0.03-0.38,0.37-0.66,0.75-0.62
			C275.25,518.79,275.51,519.06,275.53,519.4z"/>
        </g>
        <g>
          <path className="st3" d="M274.96,571.65c0.28-0.2,0.57-0.2,0.85-0.22l0.85-0.05l1.71-0.09c1.14-0.07,2.28-0.07,3.42-0.12
			c2.28-0.06,4.56,0.08,6.84-0.06l6.84-0.2c1.14-0.05,2.28-0.04,3.42,0.07l1.71,0.14c0.29,0.03,0.57,0.04,0.86,0.06l0.43,0.03
			c0.14-0.01,0.29,0.08,0.43-0.08l0.03-0.03c0.39-0.46,1.07-0.52,1.54-0.13c0.46,0.39,0.52,1.07,0.13,1.54s-1.07,0.52-1.54,0.13
			c-0.05-0.04-0.1-0.09-0.13-0.13c-0.15-0.17-0.29-0.05-0.43-0.05c-0.14,0.03-0.28,0.05-0.43,0.08l-0.85,0.2
			c-0.57,0.11-1.14,0.13-1.71,0.14c-1.14,0-2.28-0.05-3.42-0.02l-6.84,0.06c-2.28-0.03-4.56,0.17-6.84,0.22
			c-1.14,0.02-2.28,0.07-3.42,0.06l-1.71,0l-0.86,0c-0.29,0-0.57,0.02-0.86-0.18l-0.01,0c-0.38-0.26-0.48-0.78-0.22-1.16
			C274.81,571.77,274.88,571.7,274.96,571.65z"/>
        </g>
        <g>
          <path className="st3" d="M302.32,644.46c-0.29,0.2-0.57,0.19-0.85,0.2l-0.85,0.03l-1.71,0.06c-1.14,0.05-2.28,0.03-3.42,0.05
			c-2.28,0.02-4.56-0.16-6.84-0.07l-6.84,0.07c-1.14,0.03-2.28,0-3.42-0.13l-1.71-0.17c-0.28-0.03-0.57-0.06-0.85-0.08l-0.43-0.03
			c-0.14,0.01-0.29-0.09-0.43,0.08l-0.03,0.03c-0.4,0.45-1.08,0.5-1.54,0.11c-0.45-0.4-0.5-1.08-0.11-1.54
			c0.4-0.45,1.08-0.5,1.54-0.11c0.05,0.04,0.09,0.09,0.13,0.14c0.14,0.18,0.29,0.05,0.43,0.06c0.14-0.02,0.29-0.04,0.43-0.07
			l0.85-0.18c0.57-0.1,1.14-0.11,1.71-0.11c1.14,0.03,2.28,0.09,3.42,0.08l6.84,0.06c2.28,0.07,4.56-0.09,6.84-0.1
			c1.14,0,2.28-0.02,3.42,0.01l1.71,0.03l0.85,0.02c0.28,0.01,0.57-0.01,0.85,0.2l0.01,0.01c0.38,0.27,0.46,0.79,0.2,1.16
			C302.47,644.33,302.4,644.4,302.32,644.46z"/>
        </g>
        <g>
          <path className="st3" d="M256.71,290.71c0.23-0.2,0.47-0.2,0.7-0.21l0.7-0.04l1.41-0.07c0.94-0.06,1.88-0.05,2.82-0.08
			c1.88-0.04,3.76,0.12,5.64,0.01l5.64-0.13c0.94-0.04,1.88-0.02,2.82,0.1l1.41,0.16c0.24,0.03,0.47,0.05,0.71,0.07l0.35,0.03
			c0.12-0.01,0.24,0.08,0.35-0.08l0.02-0.04c0.39-0.56,1.16-0.69,1.72-0.3c0.56,0.39,0.69,1.16,0.3,1.72s-1.16,0.69-1.72,0.3
			c-0.12-0.09-0.23-0.2-0.31-0.32c-0.12-0.18-0.24-0.05-0.35-0.05c-0.12,0.03-0.23,0.04-0.35,0.08l-0.7,0.19
			c-0.47,0.1-0.94,0.12-1.41,0.13c-0.94-0.02-1.88-0.07-2.82-0.05l-5.64-0.01c-1.88-0.05-3.76,0.12-5.64,0.15
			c-0.94,0.01-1.88,0.04-2.82,0.02l-1.41-0.02l-0.7-0.01c-0.23,0-0.47,0.02-0.71-0.19l-0.01-0.01c-0.38-0.33-0.42-0.9-0.1-1.27
			C256.65,290.77,256.68,290.74,256.71,290.71z"/>
        </g>
        <g>
          <g>
            <path className="st3" d="M284.15,221.2c-0.24,0.19-0.48,0.18-0.71,0.18l-0.71,0.01l-1.41,0.02c-0.94,0.02-1.88-0.03-2.82-0.03
				c-1.88-0.04-3.75-0.28-5.63-0.24l-5.64-0.1c-0.94,0-1.88-0.06-2.81-0.22l-1.4-0.21c-0.23-0.04-0.47-0.07-0.7-0.1l-0.35-0.04
				c-0.12,0.01-0.23-0.09-0.35,0.07l-0.03,0.03c-0.41,0.54-1.19,0.65-1.73,0.23s-0.65-1.19-0.23-1.73c0.41-0.54,1.19-0.65,1.73-0.23
				c0.12,0.09,0.22,0.21,0.3,0.33c0.11,0.18,0.23,0.06,0.35,0.07c0.12-0.02,0.24-0.03,0.35-0.06l0.71-0.16
				c0.47-0.08,0.94-0.08,1.41-0.07c0.94,0.05,1.88,0.15,2.81,0.17l5.63,0.24c1.88,0.13,3.76,0.03,5.64,0.08
				c0.94,0.03,1.88,0.03,2.82,0.09l1.41,0.08l0.7,0.04c0.23,0.01,0.47,0,0.7,0.22l0.01,0.01c0.36,0.34,0.38,0.91,0.04,1.28
				C284.22,221.14,284.18,221.17,284.15,221.2z"/>
          </g>
          <g>
            <path className="st3" d="M273,208.49c0.2,0.24,0.19,0.47,0.19,0.71l0.02,0.7l0.05,1.41c0.04,0.94,0.02,1.88,0.03,2.82
				c0.01,1.88-0.19,3.76-0.11,5.64l0.03,5.64c0.02,0.94-0.02,1.88-0.16,2.82l-0.18,1.41c-0.03,0.23-0.06,0.47-0.08,0.7l-0.04,0.35
				c0.01,0.12-0.09,0.23,0.07,0.35l0.03,0.03c0.55,0.4,0.67,1.18,0.27,1.73c-0.4,0.55-1.18,0.67-1.73,0.27
				c-0.55-0.4-0.67-1.18-0.27-1.73c0.09-0.12,0.2-0.23,0.32-0.31c0.18-0.12,0.06-0.23,0.06-0.35c-0.02-0.12-0.04-0.24-0.07-0.35
				l-0.17-0.71c-0.09-0.47-0.1-0.94-0.1-1.41c0.03-0.94,0.1-1.88,0.1-2.82l0.11-5.64c0.09-1.88-0.06-3.76-0.05-5.64
				c0.01-0.94-0.01-1.88,0.03-2.82l0.04-1.41l0.02-0.7c0-0.23-0.01-0.47,0.2-0.7l0.01-0.01c0.33-0.37,0.9-0.41,1.28-0.07
				C272.94,208.42,272.97,208.46,273,208.49z"/>
          </g>
        </g>
        <g>
          <path className="st3" d="M265.65,503.14c0.23-0.2,0.47-0.2,0.7-0.21l0.7-0.04l1.41-0.07c0.94-0.06,1.88-0.05,2.82-0.08
			c1.88-0.04,3.76,0.12,5.64,0.01l5.64-0.13c0.94-0.04,1.88-0.02,2.82,0.1l1.41,0.16c0.24,0.03,0.47,0.05,0.71,0.07l0.35,0.03
			c0.12-0.01,0.24,0.08,0.35-0.08l0.02-0.04c0.39-0.56,1.16-0.69,1.72-0.3c0.56,0.39,0.69,1.16,0.3,1.72
			c-0.39,0.56-1.16,0.69-1.72,0.3c-0.12-0.09-0.23-0.2-0.31-0.32c-0.12-0.18-0.24-0.05-0.35-0.05c-0.12,0.03-0.23,0.04-0.35,0.08
			l-0.7,0.19c-0.47,0.1-0.94,0.12-1.41,0.13c-0.94-0.02-1.88-0.07-2.82-0.05l-5.64-0.01c-1.88-0.05-3.76,0.12-5.64,0.15
			c-0.94,0.01-1.88,0.04-2.82,0.02l-1.41-0.02l-0.7-0.01c-0.23,0-0.47,0.02-0.71-0.19l-0.01-0.01c-0.38-0.33-0.42-0.9-0.1-1.27
			C265.59,503.2,265.62,503.17,265.65,503.14z"/>
        </g>
        <g>
          <path className="st26" d="M715.79,776.09c52.72-0.28,130.49,0.26,149.03,0.61c27.15,0.51,42.32-7.19,44.06-45.64
			c1.74-38.45-0.39-494.58-4.86-537.57c-3.79-36.41-17-38.12-46.54-40.07c-37.03-2.44-594.18,5.14-618.24,5.2
			c-35.07,0.09-38.43,20.96-38.33,61.43c0.07,26.62,2.47,484.93,1.79,511.22c-0.86,33.32,7.72,48.91,40.96,48.82
			C276.89,780.01,577.38,778.47,715.79,776.09z"/>
        </g>
        <g>
          <g>
            <path className="st3" d="M930.65,91.92c-1.53,0.21-3.06,0.27-4.6,0.4c-1.53,0.11-3.07,0.17-4.6,0.25l-9.21,0.47l0.83-1.15
				c1.12,3.94,1.93,7.9,2.77,11.86c0.84,3.95,1.64,7.9,2.65,11.77c0.26,0.95,0.51,1.97,0.82,2.8c0.32,0.85,0.73,1.38,1.41,1.66
				c1.39,0.57,3.57,0.16,5.56,0.09l12.09-0.78l-0.94,0.86c1.04-6.84,1.34-13.81,0.74-20.71l-0.11-1.21l1.21,0.16
				c4.19,0.54,8.59-0.7,12.54-2.48c1.85-0.88,3.53-2.32,4.52-4.07c0.49-0.87,0.78-1.82,0.74-2.73c-0.01-0.46-0.11-0.9-0.29-1.31
				c-0.16-0.39-0.47-0.86-0.76-1.34c-0.58-0.94-1.19-1.86-1.84-2.72c-0.65-0.83-1.36-1.73-2.08-2.05c-1.93-0.97-4.01-1.64-6.14-2.07
				c-4.28-0.87-8.75-0.82-13.19-0.52c-8.93,0.74-17.95,1.44-26.91,2.09c-8.97,0.67-17.95,1.3-26.91,2.07l-0.61,0.05L878,82.76
				c-1.19-1.83-2.61-3.56-4.29-4.77c-0.84-0.6-1.75-1.04-2.65-1.14c-0.88-0.11-1.82,0.14-2.54,0.68c-1.46,1.07-2.1,3.35-1.76,5.25
				c0.21,1,0.6,2.06,1.01,3.09c0.42,1.04,0.88,2.07,1.36,3.09c0.48,1.01,0.99,2.06,1.5,3.01c0.48,0.86,1.2,1.61,2.07,2.21
				c1.73,1.21,3.87,1.92,6.04,2.37c2.18,0.45,4.43,0.64,6.68,0.69c1.13,0.02,2.26,0.01,3.39-0.03c1.13-0.05,2.27-0.07,3.38-0.32
				l0.01,0c0.37-0.08,0.74,0.16,0.82,0.53c0.08,0.37-0.16,0.74-0.53,0.82c-0.06,0.01-0.12,0.02-0.17,0.02
				c-1.16-0.04-2.3,0.13-3.45,0.2c-1.15,0.08-2.31,0.14-3.46,0.15c-2.32,0.03-4.65-0.09-6.97-0.49c-2.31-0.41-4.67-1.05-6.74-2.48
				c-1.03-0.7-1.98-1.63-2.63-2.78c-0.58-1.06-1.08-2.06-1.58-3.11c-0.5-1.04-0.98-2.1-1.42-3.18c-0.44-1.09-0.86-2.17-1.13-3.42
				c-0.26-1.33-0.14-2.61,0.23-3.86c0.38-1.24,1.1-2.44,2.21-3.28c1.11-0.84,2.52-1.22,3.9-1.07c1.36,0.16,2.55,0.76,3.56,1.47
				c2.02,1.46,3.52,3.34,4.81,5.32l-0.85-0.42c8.98-0.75,17.96-1.36,26.94-2.01c8.99-0.63,17.93-1.31,26.93-2.03
				c4.51-0.29,9.13-0.35,13.67,0.58c2.26,0.47,4.49,1.19,6.58,2.24c0.12,0.05,0.28,0.14,0.44,0.25c0.17,0.1,0.28,0.2,0.42,0.31
				c0.25,0.21,0.48,0.43,0.69,0.65c0.42,0.44,0.79,0.9,1.14,1.37c0.7,0.93,1.33,1.89,1.93,2.87c0.29,0.49,0.61,0.95,0.88,1.59
				c0.26,0.61,0.4,1.27,0.42,1.93c0.04,1.32-0.37,2.58-0.97,3.65c-1.24,2.14-3.11,3.76-5.28,4.82c-2.1,0.97-4.27,1.75-6.54,2.27
				c-2.26,0.51-4.63,0.75-7.01,0.45l1-0.95c0.64,7.05,0.45,14.18-0.67,21.18l-0.12,0.74l-0.74,0.05l-12.09,0.74l-3.04,0.18
				c-1.05,0.04-2.2,0.05-3.38-0.44c-0.58-0.25-1.14-0.65-1.55-1.15c-0.41-0.5-0.68-1.05-0.87-1.58c-0.38-1.06-0.6-2-0.87-3
				c-1.01-3.95-1.8-7.92-2.63-11.87c-0.83-3.95-1.63-7.9-2.7-11.74l-0.3-1.08l1.11-0.05l9.21-0.44c1.54-0.07,3.07-0.16,4.61-0.21
				c1.54-0.02,3.07-0.11,4.61-0.05c0.38,0.01,0.68,0.33,0.66,0.71C931.23,91.6,930.97,91.88,930.65,91.92z"/>
          </g>
          <g>
            <path className="st3" d="M934.76,74.29c-0.36-0.38-0.51-0.82-0.7-1.26c-0.18-0.44-0.37-0.87-0.57-1.3c-0.4-0.86-0.81-1.71-1.26-2.54
				c-0.89-1.67-1.9-3.27-3-4.81c-0.55-0.77-1.13-1.51-1.69-2.27c-0.29-0.37-0.58-0.74-0.89-1.11c-0.3-0.37-0.6-0.74-0.86-0.97
				c-0.28-0.24-0.47-0.35-0.8-0.41c-0.33-0.06-0.74-0.03-1.2,0.03l-2.87,0.38l-5.75,0.75l-1.41,0.18c-0.2,0.03-0.33,0.07-0.37,0.1
				c-0.04,0.03,0-0.01-0.03,0.03c-0.05,0.09-0.07,0.48-0.04,0.99l0.04,1.47c0.04,1.96-0.06,3.89-0.2,5.82l-0.21,2.89
				c-0.03,0.48-0.06,0.96-0.07,1.44l-0.02,0.72c0.02,0.24-0.07,0.48,0.1,0.71l0.02,0.03c0.28,0.38,0.2,0.91-0.18,1.19
				c-0.38,0.28-0.91,0.2-1.19-0.18c-0.22-0.3-0.21-0.69-0.02-0.98c0.17-0.25,0.04-0.49,0.03-0.74l-0.09-0.73l-0.17-1.46
				c-0.1-0.97-0.07-1.94-0.06-2.91c0.02-1.93,0.16-3.86,0.13-5.77l-0.02-1.43c0.01-0.45-0.1-1.05,0.21-1.82
				c0.16-0.38,0.52-0.77,0.89-0.94c0.37-0.19,0.7-0.24,0.98-0.28l1.46-0.17l5.76-0.68l2.88-0.34c0.5-0.05,1.05-0.11,1.66-0.03
				c0.61,0.06,1.3,0.39,1.73,0.77c0.46,0.39,0.77,0.77,1.1,1.14c0.33,0.37,0.65,0.74,0.97,1.12c0.63,0.76,1.23,1.54,1.78,2.36
				c1.12,1.62,2.14,3.32,3.04,5.07c0.46,0.87,0.87,1.77,1.27,2.67c0.19,0.45,0.39,0.91,0.57,1.36c0.19,0.45,0.36,0.92,0.35,1.44
				c-0.01,0.41-0.35,0.73-0.76,0.72c-0.2-0.01-0.38-0.09-0.51-0.23L934.76,74.29z"/>
          </g>
        </g>
      </g>
      <g id="Ebene_5">
      </g>
      <g id="Ebene_10">
      </g>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 1043.7 868.33";
  }
}

export default withStyles(svgIconStyles)(EmptyStructuredTableIcon);
