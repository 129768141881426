import * as React from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import SvgIconMetusCloud from "../../common/icons/SvgIconMetusCloud";
import {authenticate} from "../actions/ModelAsyncActionCreators";
import {interactionStore} from "../../common/stores/InteractionStore";
import {Cancel, Close} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {AuthenticationNeededAction, SetAuthenticationFailedAction} from "../../common/actions/InteractionStateActions";
import MetusTextField from "../../common/components/MetusTextField";
import {ResetModelLocationAction} from "../actions/ModelSelectionActions";
import {observer} from "mobx-react";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
}

interface LocalState {
  username: string;
  password: string;
}

const styles = (theme: Theme): StyleRules => createStyles({
  dialogPaper: {
    width: "880px",
    maxWidth: "880px",
    height: "504px",
    maxHeight: "504px",
    alignItems: "center",
    margin: 0,
    backgroundColor: "#f9f9fc"
  },
  dialogPaperError: {
    width: "880px",
    maxWidth: "880px",
    height: "584px",
    maxHeight: "584px",
    alignItems: "center",
    margin: 0,
    backgroundColor: "#f9f9fc",
    justifyContent: "space-between"
  },
  dialogTitle: {
    flex: "0 0 auto",
    margin: 0,
    padding: "35px 0 35px 0"
  },
  typographyTitle: {
    fontSize: "36px",
    fontWeight: 300,
    color: "#424242",
  },
  dialogContentRoot: {
    flex: "0 0 auto",
    padding: 0
  },
  dialogActionsRoot: {
    paddingBottom: "20px",
  },
  blankLine: {
    height: "56px"
  },
  header: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  closeIconButton: {
    alignSelf: "flex-end",
    color: theme.palette.secondary[600],
    paddingTop: "20px",
    paddingRight: "20px"
  },
  closeIcon: {
    height: "16px",
    width: "16px",
  },
  logoContainer: {
    alignSelf: "center",
  },
  logo: {
    height: "60px",
    color: "blue"
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "80px",
    backgroundColor: "#de323c",
  },
  cancelIconContainer: {
    alignSelf: "center",
    color: theme.palette.primary[50],
    paddingLeft: "16px",
  },
  cancelIcon: {
    height: "40px",
    width: "40px",
  },
  errorTextContainer: {
    alignSelf: "center",
    paddingLeft: "160px",
  },
  typographyErrorText: {
    fontSize: "21px",
    fontWeight: 500,
    color: theme.palette.primary[50],
  },
});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

@observer
class MetusLoginContainer extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  @autobind
  private handleFormSubmit(event: any): void {
    event.preventDefault();
    authenticate(interactionStore.originalRequest, this.state.username, this.state.password);
  }

  @autobind
  private handleClose(): void {
    this.setState({
      username: "",
      password: ""
    });

    Dispatcher.dispatch(new AuthenticationNeededAction(undefined));
    Dispatcher.dispatch(new ResetModelLocationAction());
  }

  @autobind
  private setUsername(event: any): void {
    log.debug("username " + event.target.value);
    this.setState({username: event.target.value});
    Dispatcher.dispatch(new SetAuthenticationFailedAction(false));
  }

  @autobind
  private setPassword(event: any): void {
    this.setState({password: event.target.value});
    Dispatcher.dispatch(new SetAuthenticationFailedAction(false));
  }

  render(): JSX.Element {
    renderLog.debug("Rendering MetusLoginContainer");
    const paperProps = interactionStore.isAuthenticationFailed ? this.props.classes.dialogPaperError : this.props.classes.dialogPaper;
    return <Dialog
        data-testselector="LoginDialog"
        classes={{paper: paperProps}}
        disableBackdropClick={true}
        open={interactionStore.isLoginDialogVisible}
        onClose={this.handleClose}>
      <div className={this.props.classes.header}>
        <IconButton onClick={this.handleClose} data-testselector="closeDialogButton"
                    classes={{root: this.props.classes.closeIconButton}}>
          <Close classes={{root: this.props.classes.closeIcon}}/>
        </IconButton>
        <div className={this.props.classes.logoContainer}>
          <SvgIconMetusCloud classes={{root: this.props.classes.logo}}/>
        </div>
      </div>
      <div className={this.props.classes.dialogTitle}>
        <Typography className={this.props.classes.typographyTitle}>Welcome to our METUS Cloud</Typography>
      </div>
      {this.dialogContent(interactionStore.isAuthenticationFailed)}
      <div className={this.props.classes.dialogActionsRoot}>
        <Button form="form.MetusLoginContainer" variant={"contained"} type="submit" onClick={this.handleFormSubmit}
                disabled={interactionStore.isAuthenticationInProgress}>Log-In</Button>
      </div>
      {
        interactionStore.isAuthenticationFailed &&
        <div className={this.props.classes.footer}>
          <div className={this.props.classes.cancelIconContainer}>
            <Cancel classes={{root: this.props.classes.cancelIcon}}/>
          </div>
          <div className={this.props.classes.errorTextContainer}>
            <Typography className={this.props.classes.typographyErrorText}>Wrong user name or password! Please try
              again.
            </Typography>
          </div>
        </div>
      }
    </Dialog>;
  }

  private dialogContent(isError: boolean): JSX.Element {
    return <div>
      <DialogContent classes={{root: this.props.classes.dialogContentRoot}}>
        <form id="form.MetusLoginContainer" onSubmit={this.handleClose}>
          <MetusTextField
              error={isError}
              autoFocus
              id="name"
              placeholder="User Name"
              onChange={this.setUsername}
              disabled={interactionStore.isAuthenticationInProgress}/>
          <div className={this.props.classes.blankLine}/>
          <MetusTextField error={isError}
                          type="password"
                          id="password"
                          placeholder="Password"
                          onChange={this.setPassword}
                          disabled={interactionStore.isAuthenticationInProgress}/>
          <div className={this.props.classes.blankLine}/>
        </form>
      </DialogContent>
    </div>;
  }

}

export default withStyles(styles)(MetusLoginContainer);
