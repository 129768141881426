import {ViewId, VisualAttributeId, VisualElementId, VisualId, VisualTableId} from "../../core/utils/Core";
import {AutoLayoutOptions} from "../utils/autolayout/AutoLayoutOptions";
import {Direction} from "../../common/utils/Direction";
import {ActionBase} from "../../common/actions/BaseAction";
import {SaveViewAction} from "../../commonviews/actions/SharedViewAsyncActions";


/**
 * move elements on diagram by a delta
 */
export class MoveElementsAction extends ActionBase<{ dx: number, dy: number, nodes: VisualElementId[] }> {
  type: "movenodes" = "movenodes";

  constructor(viewId: ViewId, dx: number, dy: number, nodes: VisualElementId[]) {
    super({dx, dy, nodes}, viewId);
  }
}

/**
 * move a table column in a diagram
 */
export class MoveTableColumnAction extends ActionBase<{ dx: number, dy: number, visualTableId: VisualTableId }> {
  type: "movetable" = "movetable";

  constructor(viewId: ViewId, dx: number, dy: number, visualTableId: VisualTableId) {
    super({dx, dy, visualTableId}, viewId);
  }
}

/**
 * move a table column in a diagram
 */
export class MoveAttributeColumnAction extends ActionBase<{ dx: number, dy: number, visualAttributeId: VisualAttributeId }> {
  type: "moveattribute" = "moveattribute";

  constructor(viewId: ViewId, dx: number, dy: number, visualAttributeId: VisualAttributeId) {
    super({dx, dy, visualAttributeId}, viewId);
  }
}

/**
 * set mode that attribute headers will be packed automatically one beneath the other on drag/drop
 */
export class AutoArrangeAttributeHeadersAction extends ActionBase<boolean> {
  type: "autoArrangeAttributeHeaders" = "autoArrangeAttributeHeaders";

  constructor(viewId: ViewId, autoArrange: boolean) {
    super(autoArrange, viewId);
  }
}

/**
 * set autolayout algorithm and parameters for the given chart
 */
export class AutolayoutAction extends ActionBase<AutoLayoutOptions> {
  type: "autolayout" = "autolayout";

  constructor(viewId: ViewId, options: AutoLayoutOptions) {
    super(options, viewId);
  }
}

export interface ResizePayload {
  visualId: VisualTableId | VisualAttributeId | VisualId;
  direction: Direction;
  dx: number;
  dy: number;
}

export interface NewTextboxPayload {
  x: number;
  y: number;
}

export interface UpdateTextboxTextPayload {
  id: string;
  newText: string;
}

export class ResizeAttributeAction extends ActionBase<ResizePayload> {
  type: "resizeAttribute" = "resizeAttribute";

  constructor(viewId: ViewId, visualId: VisualAttributeId, direction: Direction, dx: number, dy: number) {
    super({visualId, direction, dx, dy}, viewId);
  }
}

export class ResizeTableAction extends ActionBase<ResizePayload> {
  type: "resizeTable" = "resizeTable";

  constructor(viewId: ViewId, visualId: VisualTableId, direction: Direction, dx: number, dy: number) {
    super({visualId, direction, dx, dy}, viewId);
  }
}

export class ResizeVisualObjectAction extends ActionBase<ResizePayload> {
  type: "resizeVisualObject" = "resizeVisualObject";

  constructor(viewId: ViewId, visualId: VisualId, direction: Direction, dx: number, dy: number) {
    super({visualId, direction, dx, dy}, viewId);
  }

}

export class ChangeColumnCountValueChartLevelAction extends ActionBase<{ visualTableId: VisualTableId, columnCount: number }> {
  type: "changeColumnCountValueChartLevel" = "changeColumnCountValueChartLevel";

  constructor(viewId: ViewId, visualTableId: VisualTableId, columnCount: number) {
    super({visualTableId, columnCount}, viewId);
  }
}

export class ChangeAutoLayoutPropertiesAction extends ActionBase<AutoLayoutOptions> {
  type: "updateAutoLayoutProperties" = "updateAutoLayoutProperties";

  constructor(viewId: ViewId, props: AutoLayoutOptions) {
    super(props, viewId);
  }
}

export class ToggleFilterLineAction extends ActionBase<VisualTableId> {
  type: "toggleFilterLine" = "toggleFilterLine";

  constructor(viewId: ViewId, visualTableId: VisualTableId) {
    super(visualTableId, viewId);
  }
}

export class NewTextboxAction extends ActionBase<NewTextboxPayload> {
  type: "newTextbox" = "newTextbox";

  constructor(viewId: ViewId, x: number, y: number) {
    super({x, y}, viewId);
  }
}

export class UpdateTextboxTextAction extends ActionBase<UpdateTextboxTextPayload> {
  type: "updateTextboxText" = "updateTextboxText";

  constructor(viewId: ViewId, textBoxId: string, newText: string) {
    super({id: textBoxId, newText}, viewId);
  }
}

export class RemoveTextboxFromViewAction extends ActionBase<string> {
  type: "removeTextboxFromView" = "removeTextboxFromView";

  constructor(viewId: ViewId, textBoxId: string) {
    super(textBoxId, viewId);
  }
}

export class SortChartColumnAction extends ActionBase<{ visualAttributeId: VisualAttributeId, ascending: boolean }> {
  type: "sortChartColumn" = "sortChartColumn";

  constructor(viewId: ViewId, visualAttributeId: VisualAttributeId, ascending: boolean) {
    super({visualAttributeId: visualAttributeId, ascending: ascending}, viewId);
  }
}

export interface ScrollCoordinates {
  scrollTop: number;
  scrollLeft: number;
}

export class ScrollViewAction extends ActionBase<{ windowIndex: number, scrollCoordinates: ScrollCoordinates }> {
  type: "scrollView" = "scrollView";

  constructor(windowIndex: number, scrollCoordinates: ScrollCoordinates) {
    super({windowIndex, scrollCoordinates}, undefined);
  }
}


export type DiagramSyncActions =
    MoveElementsAction
    | MoveTableColumnAction
    | MoveAttributeColumnAction
    | AutoArrangeAttributeHeadersAction
    | AutolayoutAction
    | ResizeAttributeAction
    | ResizeTableAction
    | ChangeColumnCountValueChartLevelAction
    | ChangeAutoLayoutPropertiesAction
    | ToggleFilterLineAction
    | SaveViewAction
    | NewTextboxAction
    | UpdateTextboxTextAction
    | RemoveTextboxFromViewAction
    | ResizeVisualObjectAction
    | SortChartColumnAction
    | ScrollViewAction
    ;
