// lib imports
import * as React from "react";
import Log from "../../../common/utils/Logger";
import {AttributeDefinitionsComponent} from "./AttributeDefinitionsComponent";
import {ChartColumnHeaderComponent} from "./ChartColumnHeaderComponent";
import {HandleComponent} from "../common/HandleComponent";
import {Direction} from "../../../common/utils/Direction";
import {ResizeTableAction} from "../../actions/DiagramActions";
import {ViewerContext} from "../../utils/ViewerContext";
import {VisualAttributeIdString} from "../../../core/utils/Core";
import {VisualChartColumn} from "../../models/chart/VisualChartColumn";
import {VisualHeader} from "../../../commonviews/models/VisualHeader";
import {observer} from "mobx-react";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {getLevelDefaultColor} from "../../../core/utils/LevelColorUtil";
import autobind from "autobind-decorator";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

interface LocalProps {
  viewerContext: ViewerContext;
  table: VisualChartColumn;
  viewerFilters: Map<VisualAttributeIdString, string>;
  filterTextsValidities: Map<VisualAttributeIdString, boolean>;
}

@observer
export class ChartColumnHeaderWithAttributesComponent extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering ChartColumnHeaderWithAttributesComponent");
    const table: VisualChartColumn = this.props.table;
    const header: VisualHeader = table.header;
    const levelColor = getLevelDefaultColor(0, 1);
    const customizedHandleInfo = {
      EAST: {
        y: header.y,
        height: DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT
      }
    };
    return <g>
      <ChartColumnHeaderComponent viewContext={this.props.viewerContext.getViewContext()} header={header} id={table.id}
                                  index={0} backgroundColor={levelColor}/>
      <HandleComponent scale={this.props.viewerContext.scale}
                       EAST={this.onDragHandle}
                       visualModel={header}
                       customizedHandleInfo={customizedHandleInfo}
                       visualId={table.id}/>
      // this only renders attribute headers, values are rendered inside Nodes
      <AttributeDefinitionsComponent viewerContext={this.props.viewerContext}
                                     table={table}
                                     viewerFilters={this.props.viewerFilters}
                                     filterTextsValidities={this.props.filterTextsValidities} supportsAtFilter={true}
      />
    </g>;
  }

  @autobind
  private onDragHandle(direction: Direction, dx: number, dy: number): void {
    Dispatcher.dispatch(new ResizeTableAction(this.props.viewerContext.viewId, this.props.table.id, direction, dx, dy));
  }
}