import Log from "../../common/utils/Logger";
import {ViewId} from "../../core/utils/Core";
import {metusStore} from "../stores/MetusStore";
import {MosaicNode} from "react-mosaic-component";
import {CockpitData, OpenView} from "../../api/api";
import {modelStore} from "../../core/stores/ModelStore";
import {ModelLocationType} from "../../common/constants/Enums";

const log = Log.logger("MetusLocalStorage");

// see globalShims in mocha
const lStorage = global["localStorage"];
const currentAppStateKey = "appState";
const cockpitsKey = "cockpits";

export interface AppState {
  modelName: string;
  modelLocation: ModelLocationType;
  windowArrangement: MosaicNode<number>;
  openViews: OpenView[];
  emptyEditors: boolean[];
  openCockpit: string;
}


export function setStateToLocalStorage(openViews: OpenView[], windowArrangement: MosaicNode<number>): void {
  lStorage.setItem(currentAppStateKey, JSON.stringify(
      {
        modelName: modelStore.modelInfo.name,
        modelLocation: modelStore.modelInfo.location,
        windowArrangement,
        openViews,
        openCockpit: metusStore.currentCockpit && metusStore.currentCockpit.id,
      } as AppState
  ));
}

export function resetLocalStorage(): void {
  setStateToLocalStorage(undefined, undefined);
}

export function getStateFromLocalStorage(): AppState {
  let retVal = null;
  const item = lStorage.getItem(currentAppStateKey);
  if (item) {
    retVal = JSON.parse(item);
  }
  return retVal;
}

/**
 * The next methods are for test purposes only.
 */

export function addCockpitToLocalStorage(cockpitId: ViewId): void {
  const state: AppState = getStateFromLocalStorage();
  const cockpits: CockpitData[] = getCockpitsFromLocalStorage();

  const currentCockpit = {
    id: cockpitId,
    windowArrangement: metusStore.windowArrangement,
    emptyEditors: [],
    openViews: state ? state.openViews : []
  };

  cockpits.push(currentCockpit);
  lStorage.setItem(cockpitsKey, JSON.stringify(cockpits));
}


export function getCockpitFromLocalStorage(cockpitId: ViewId): CockpitData {
  const cockpits: CockpitData[] = getCockpitsFromLocalStorage();

  const retVal: CockpitData = cockpits.find(cockpit => cockpit.id === cockpitId);
  return retVal;
}

function getCockpitsFromLocalStorage(): CockpitData[] {
  let retVal: CockpitData[] = [];
  const item = lStorage.getItem(cockpitsKey);
  if (item) {
    retVal = JSON.parse(item);
  }
  return retVal;
}

