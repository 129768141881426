import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {ViewBody} from "../../api/api";

const log = Log.logger("LocalViewStore", Classifier.action);

/**
 * LocalViewStore is used as a replacement for server calls in
 * read-only (i.e. not embedded) mode
 */
class LocalViewStore {
  private uuidToViewBody: Map<string, ViewBody> = new Map();

  public putWebView(body: ViewBody): Promise<Response> {
    log.debug("LocalViewStore: putWebView", body);
    // when renaming before opening the view it saves it without any content.
    // We don't store it so that a following get request will fetch the content data from the server.
    if (body.content) {
      this.uuidToViewBody.set(body.uuid, body);
    }
    return Promise.resolve(new Response());
  }

  public getWebView(uuid: string): Promise<any> {
    log.debug("LocalViewStore: getWebView", uuid);
    if (uuid == null) {
      return Promise.resolve([]);
    } else {
      const viewBody = this.uuidToViewBody.get(uuid);
      const result = viewBody ? viewBody.content : null;
      return Promise.resolve(result ? JSON.parse(<string>result) : null);
    }
  }

  public hasWebView(uuid: string): boolean {
    return this.uuidToViewBody.has(uuid);
  }

  public reset() {
    this.uuidToViewBody.clear();
  }

}

const localViewStore = new LocalViewStore();
export default localViewStore;
