import "../../common/css/metusapp.css";
import * as React from "react";
import "react-loading-bar/dist/index.css";
import {
  createStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {observer} from "mobx-react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {Cloud, Folder} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";

const renderLog = Log.logger("MainNavigationComponent", Classifier.render);



const icon = {
  fill: "white",
  height: "32px",
  width: "32px",
  borderRadius: "4px",
  marginRight: "8px",
};

const styles = (theme: Theme):StyleRules => createStyles({

  folderIcon: {
    ...icon,
    backgroundColor: theme.metus.main.projectIcon,
  },
  cloudIcon: {
    ...icon,
    backgroundColor: theme.metus.main.cloudIcon,
  },
  listItemText: {
    color: theme.metus.main.primaryText,
  },
  listItemRoot: {
    padding: "0px",
    margin: "0px",
  },
  listItemButton: {
    "&:hover": {
      backgroundColor: theme.metus.navigation.active.secondaryFill,
    }
  },
});

type MainNavigationType = "cloud" | "local" | "liveserver";

interface LocalProps {
  text: string;
  navigationType: MainNavigationType;
  callback: () => void;
}

interface LocalState {
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

@observer class MainNavigationItemComponent extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering MainNavigationItemComponent " + this.props.text);
    const icon = this.props.navigationType === "cloud" ?
        <Cloud viewBox="-8 -8 40 40" classes={{root: this.props.classes.cloudIcon}}/> :
        <Folder viewBox="-8 -8 40 40" classes={{root: this.props.classes.folderIcon}}/>;

    return (
        <ListItem onClick={this.props.callback} classes={{button: this.props.classes.listItemButton}} button>
          <ListItemIcon>{icon}</ListItemIcon>
          <Typography variant="subtitle1">
            <ListItemText data-testselector={this.props["data-testselector"]}
                          primary={this.props.text}
                          classes={{
                            dense: this.props.classes.listItemText,// TODO MB
                            root: this.props.classes.listItemRoot
                          }} disableTypography={true}/></Typography>
        </ListItem>
    );
  }


}

export default withStyles(styles)(MainNavigationItemComponent);
