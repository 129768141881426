import {VisualTableId} from "../../core/utils/Core";
import {ValueChartProperties} from "../components/properties/ValueChartPropertiesComponent";
import {DiagramModel} from "../models/DiagramModel";
import {diagramStore} from "../stores/DiagramStore";
import {ValueChartDiagramModel} from "../models/valuechart/ValueChartDiagramModel";
import {AutoLayoutProperties} from "../components/properties/AutoLayoutPropertiesComponent";
import {ChartDiagramModel} from "../models/chart/ChartDiagramModel";
import Log from "../../common/utils/Logger";
import {
  IMetusPropertiesProviderContext,
  MetusPropertiesProvider
} from "../../commonviews/contexts/IMetusPropertiesProviderContext";

const log = Log.logger("diagram.propertiesprovider");

export const autoLayoutPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): AutoLayoutProperties => {
  log.debug("Providing autolayout properties for selection", context.activeViewId);

  let result: AutoLayoutProperties = null;
  const diagram: DiagramModel = diagramStore.getDiagramForId(context.activeViewId);

  if (diagram != null && diagram instanceof ChartDiagramModel) {
    result = {activeViewId: context.activeViewId, ...(diagram as ChartDiagramModel).autoLayoutProperties};
  }

  return result;
};
export const valueChartPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): ValueChartProperties => {
  log.debug("Providing value chart layout properties for selection", context.activeViewId);

  let result: ValueChartProperties = null;
  const diagram: DiagramModel = diagramStore.getDiagramForId(context.activeViewId);

  if (diagram != null && diagram instanceof ValueChartDiagramModel) {
    let visualTables: { visualTableId: VisualTableId, tableName: string, columnCount: number }[] = [];
    if (diagram.valueChart && diagram.valueChart.levels) {
      visualTables = diagram.valueChart.levels.map(level => {
        return {
          visualTableId: level.id,
          tableName: level.header.name,
          columnCount: level.columns
        };
      });
    }

    result = {
      activeViewId: context.activeViewId,
      multiline: false,
      visualTables: visualTables
    };
  }

  return result;
};