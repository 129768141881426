import {Point, Rect, Rectangle} from "./Geometry";

export interface CoordinateConverter {
  convertClientToSVG(x: number, y: number): Point;

  convertSVGToClient(x: number, y: number, width: number, height: number): Rectangle;

  convertSVGPointToClient(x: number, y: number): Point;
}

export interface SVGScalingConverter extends CoordinateConverter {
  scale: number;
  convertClientRectToSVG(x: number, y: number, width: number, height: number): Rect;
  convertSVGPointToClient(x: number, y: number): Point;
  convertSVGToClient(x: number, y: number, width: number, height: number): Rectangle;
  getVisibleSVGRect(): Rect;
}

/**
 * identity converter can be used if no coordinate transformation is needed
 */
export class IdentityConverter implements CoordinateConverter {
  convertClientToSVG(x: number, y: number): Point {
    return new Point(x, y);
  }

  convertSVGToClient(x: number, y: number, width: number, height: number): Rectangle {
    return Rectangle.from(x, y, width, height);
  }

  convertSVGPointToClient(x: number, y: number): Point {
    return new Point(x,y);
  }
}

export default new IdentityConverter();