import * as React from "react";

interface LocalProps {
  text: string;
}

/**
 * wrap text in span and add <WBR> Tags after underscores in the content
 */
export class WordBreakOpportunityComponent extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  render():JSX.Element {
    const split = this.props.text.split("_");
    return <span>{split.map((x, i) => { return  i === split.length-1 ? x : [x + "_", <wbr key={i}/>]; })}</span>;
  }
}
