/* ToolboxComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Juli 2017
 */
import * as React from "react";

import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import {ButtonProps} from "@material-ui/core/Button/Button";


const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "&>*": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
  },
});

const log = Log.logger("properties");
const renderLog = Log.logger("properties", Classifier.render);

interface LocalProps extends ButtonProps {
}

interface LocalState {
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class PropertiesSectionLayout extends React.PureComponent<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    return <div className={this.props.classes.root}>{this.props.children}</div>;
  }


}

export default withStyles(styles)(PropertiesSectionLayout);