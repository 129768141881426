import * as React from "react";
import PropertiesSectionComponent from "./PropertiesSectionComponent";
import {Section, SectionDefinition} from "../model/PropertiesModel";
import {observer} from "mobx-react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";

const renderLog = Log.logger("properties", Classifier.render);

interface LocalProps<C> {
  section: Section<C>;
  sectionDefinition: SectionDefinition<C>;
  activeSectionId: string;
  sectionChangeHandler: (id: string) => void;
}

/**
 * dynamically renders a section defined by the SectionDefinition using it's specified component and properties retrieved
 * from specified properties provider
 */
@observer
export class DynamicPropertiesSectionComponent<C> extends React.Component<LocalProps<C>> {

  constructor(props: LocalProps<C>) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering DynamicPropertiesSectionComponent " + this.props.sectionDefinition.id);
    const sectionDefinition = this.props.sectionDefinition;
    return <PropertiesSectionComponent
        key={sectionDefinition.id}
        sectionId={sectionDefinition.id}
        sectionLabel={sectionDefinition.label}
        isActive={sectionDefinition.id === this.props.activeSectionId}
        sectionChangeHandler={this.props.sectionChangeHandler}>
      {React.createElement(sectionDefinition.reactComponent, this.props.section.properties)}
    </PropertiesSectionComponent>;
  }
}
