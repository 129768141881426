import * as _ from "lodash";
import {VisualAttributeId, VisualElementId, visualId, VisualId, VisualTableId} from "../../core/utils/Core";
import {computed, observable} from "mobx";
import {DefaultAcceptImpl, IVisitable, IVisitCallback} from "../../common/utils/Visitor";
import {Rect, Rectangle} from "../../common/utils/Geometry";
import shallowEqual from "shallowequal";

/**
 * visual object which can provide observable bounds
 */
export interface IBoundsProvider {
  readonly bounds: Rect;
}

/**
 * type guard for bounds provider
 * @param object
 */
export function isBoundsProvider(object: any): object is IBoundsProvider {
  return object.bounds !== undefined;
}

/**
 * general abstraction for all visual objects in metus diagrams
 */
export interface VisualObject<ID = any> extends IVisitable, IBoundsProvider {
  /** unique id identifying this object, ATTENTION: some implementors may encode additional informations into this id,
   * usually the associated core object id
   */
  id: ID;
  /** find nested visual objects */
  children: VisualObject[];

  childrenRecursive: VisualObject[];
  parent: VisualObject;

  addChildren(...childVisuals: VisualObject[]): void;

  removeChildren(...childVisuals: VisualObject[]): void;

  removeChildById(visualId: any): void;

  removeAllChildren(): void;
}


/**
 * a visual element with rectangular bounds
 */
export interface IBoundedVisual extends IBoundsProvider {
  x: number;
  y: number;
  width: number;
  height: number;

}

/**
 * type guard for bounded visuals
 * @param object
 */
export function isBoundedVisual(object: any): object is IBoundedVisual {
  return object.x !== undefined && object.y !== undefined && object.width !== undefined && object.height !== undefined && object.bounds !== undefined;
}

/**
 * simplest implementation of IBoundedVisual
 */
export class BoundedVisual implements IBoundedVisual {

  constructor(public x: number, public y: number, public width: number, public height: number) {
  }

  get bounds(): Rect {
    return this;
  }
}


/**
 * an object with a name the user understands; this should be used to display the object
 */
export interface INamedObject {
  name: string;
}

/**
 * base class for visual objects providing calculated bounds; how they are calculated may vary.
 */

export abstract class VisualObjectBase<CHILD_TYPE extends VisualObject, ID_TYPE> implements VisualObject, IBoundsProvider {
  @observable public id: ID_TYPE;
  @observable parent: VisualObject;

  protected constructor(parent: VisualObject, id: ID_TYPE) {
    this.id = id;
    this.parent = parent;
  }

  abstract get children(): CHILD_TYPE[];

  get childrenRecursive(): CHILD_TYPE[] {
    const result = [];
    this.children.forEach(c => {
      result.push(c, ...c.childrenRecursive);
    });
    return result;
  }

  /**
   * avoid update due to creating new object by wrapping bounds into another observable,
   * see https://alexhisen.gitbooks.io/mobx-recipes/content/use-computedstruct-for-computed-objects.html
   */
  @computed
  public get bounds(): Rect {
    return this.internalBounds;
  }

  /**
   * @return default: union of bounds of all children, must be overwritten to define the bounds of this visual with all parts belonging to it
   */
  @computed.struct
  protected get internalBounds(): Rect {
    // const boundedVisualObjects: IBoundsProvider[] = this.children.filter(c => isBoundsProvider(c)) as IBoundsProvider[];
    const rectangle = Rectangle.union(...this.children.map(c => c.bounds));
    return rectangle ? rectangle.toJS : undefined;
  }

  public addChildren(...childVisuals: CHILD_TYPE[]): void {
    childVisuals.forEach(c => c.parent = this);
    this.children.push(...childVisuals);
    this.onAfterChildrenAdded(childVisuals);
  }

  // noinspection JSUnusedGlobalSymbols
  public removeChildren(...childVisuals: CHILD_TYPE[]): void {
    this.onBeforeChildrenDeleted(childVisuals);
    _.pullAll(this.children, childVisuals);
  }

  /**
   * @param {VisualTableId | VisualElementId | VisualId} id id to find
   * @param {VisualObject} child child to start from, start from this if undefined
   * @returns {VisualObject} first visual object found on depth-first-traversal
   */
  public findVisual(id: VisualTableId | VisualElementId | VisualId | VisualAttributeId, child: VisualObject = undefined): VisualObject {
    // start from child if specified, otherwise from this
    const root = child || this;
    let result = undefined;
    root.children.forEach(child => {
      if (visualId(child.id) === id) {
        result = child;
        return;
      } else {
        result = this.findVisual(id, child);
      }
    });
    return result;
  }

  public accept(visitCallback: IVisitCallback): boolean {
    const childIterator: Iterator<any> = this.children[Symbol.iterator]();
    return DefaultAcceptImpl<VisualObjectBase<any, any>, any>(this, visitCallback, childIterator);
  }

  public removeChildById(visualId: any): void {
    const child = this.children.find(child => shallowEqual(child.id, visualId));
    if (child) {
      this.onBeforeChildrenDeleted([child]);
      this.children.splice(this.children.indexOf(child), 1);
    }
  }

  public removeAllChildren(): void {
    this.onBeforeChildrenDeleted(this.children);
    this.children.splice(0, this.children.length);
  }

  /**
   * hook is called AFTER direct children are added to the visual object.
   * @param childVisuals
   */
  public onAfterChildrenAdded(childVisuals: CHILD_TYPE[]): void {
  }

  /**
   * hook is called BEFORE direct children will be deleted from the visual object
   * @param childVisuals
   */
  public onBeforeChildrenDeleted(childVisuals: CHILD_TYPE[]): void {
  }

}

/**
 * base class for visual objects storing position and size; use for objects which can be moved and resized by user and have children
 */
export class BoundedVisualObjectBase<CHILD_TYPE extends VisualObject, ID_TYPE> extends VisualObjectBase<CHILD_TYPE, ID_TYPE> implements IBoundedVisual {
  @observable protected _children: CHILD_TYPE[] = [];
  get children(): CHILD_TYPE[] {
    return this._children;
  }
  @observable x: number;
  @observable y: number;
  @observable width: number;
  @observable height: number;

  constructor(parent: VisualObject, id: ID_TYPE, x: number, y: number, width: number, height: number) {
    super(parent, id);
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  @computed.struct
  protected get internalBounds(): Rect {
    return {x: this.x, y: this.y, width: this.width, height: this.height};
  }

  public setInternalBounds(newBounds: Rect) {
    this.x = newBounds.x;
    this.y = newBounds.y;
    this.width = newBounds.width;
    this.height = newBounds.height;
  }
}
