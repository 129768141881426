import * as React from "react";
import {MouseEventHandler, TouchEventHandler} from "react";
import Log from "../../../common/utils/Logger";
import {Point, Rect, Rectangle, Size} from "../../../common/utils/Geometry";
import {checkNewScale, ZoomHelper, ZoomingStatus} from "../../../common/utils/ZoomHelper";
import {Input as HammerInput} from "hammerjs";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import HammerComponent from "react-hammerjs";
import {observer} from "mobx-react";
import {IBoundsProvider} from "../../models/VisualObject";
import autobind from "autobind-decorator";
import * as _ from "lodash";
import {Validate} from "../../../common/utils/Validate";
import {IReactionPublic, reaction} from "mobx";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {SVGScalingConverter} from "../../../common/utils/CoordinateUtil";
import {ZoomViewAction} from "../../../commonviews/actions/SharedViewActions";
import {viewManagerRegistry} from "../../../commonviews/models/ViewManager";
import {ScrollController} from "./DiagramComponentDropSpec";
import {AutoScrollOnHoverCalculator} from "./AutoScrollOnHoverCalculator";
import {ScrollCoordinates} from "../../actions/DiagramActions";

const log = Log.logger("PanZoomScrollableSVGComponent");
const eventLog = Log.logger("PanZoomScrollableSVGComponent", Classifier.event);
const dimensionLog = Log.logger("PanZoomScrollableSVGComponent", Classifier.update);
const renderLog = Log.logger("diagram", Classifier.render);

/**
 * @param scale in svg
 * @param width: svg width in svg coordinates
 * @param height: svg height in svg coordinates
 * @param visibleSVGRect: visible rect in svg coordinates
 */
export type  VisibleWindowChangedHandler = (scale: number, width: number, height: number, visibleSVGRect: Rect) => void;

interface LocalProps {
  /** callback to define point which should be kept on screen when zooming, if undefined, the middle of the viewable area will be used */
  zoomPointProvider: () => Point;
  /**
   * observable model object which is otified on bounds changes; the PanZoomScrollableSVGComponent updates its scroll container if the size of the contained svg bounding rect is changed
   */
  boundsProvider: IBoundsProvider;
  /**
   * called when the visible window has changed because of scrolling, zooming
   */
  onVisibleWindowChanged: VisibleWindowChangedHandler;
  /**
   * ms to wait until visible window events are dispatched; default 200ms
   */
  throttleMs?: number;
  // svg mouse events
  onMouseDown?: MouseEventHandler<SVGSVGElement>;
  onMouseLeave?: MouseEventHandler<SVGSVGElement>;
  onMouseMove?: MouseEventHandler<SVGSVGElement>;
  onMouseUp?: MouseEventHandler<SVGSVGElement>;
  // svg Touch Events
  onTouchCancel?: TouchEventHandler<SVGSVGElement>;
  onTouchEnd?: TouchEventHandler<SVGSVGElement>;
  onTouchMove?: TouchEventHandler<SVGSVGElement>;
  onTouchStart?: TouchEventHandler<SVGSVGElement>;
  onDoubleClick?: MouseEventHandler<SVGSVGElement>;
  windowIndex: number;
}

/**
 * wraps an svg image into a scrollable, zoomable, pannable container with touch and mouse support, supplying an infinite canvas on the svg which flexibly adjusts its size.
 *
 * Implementation Remarks:<BR/>
 * There are a lot of nasty problems/bugs around scroll handling in this component, which explain why updates to svg size and scroll position is fully done in DOM instead of React component state
 * <ul>
 *   <li>Hammer bug swallows child reference: https://github.com/JedWatson/react-hammerjs/issues/83 thus editor container div is wrapped in another div to make the containerRef work</li>
 *   <li>Scroll position cannot be set using React, thus DOM manipulation is mandatory</li>
 *   <li>Scroll event does not include information on how much was scrolled, thus default scroll behavior is executed and difference is measured</li>
 * </ul>
 */
@observer
export class PanZoomScrollableSVGComponent extends React.Component<LocalProps> implements SVGScalingConverter, ScrollController {

  readonly svgRef: React.RefObject<SVGSVGElement>;
  /** scale when pinching started */
  private originalScale: number;
  /** scroll pos when panning started */
  private originalScrollPosition: Point;
  /** flag if touch start/touch end occured without a pan or pinch in between */
  private inTouch: boolean = false;
  readonly containerRef: React.RefObject<HTMLDivElement>;
  /** old left top position to avoid strange movement if viewbox size shrinks in diagram */
  private lastLeftTopPosition: Point = null;
  readonly onVisibleWindowChangedThrottled: VisibleWindowChangedHandler;
  private _scale: number = 1.0;
  private reactionZoom: IReactionPublic;
  private reactionScroll: IReactionPublic;
  private visibleSVGRect: Rect = {x: 0, y: 0, width: 0, height: 0};

  constructor(props: LocalProps) {
    super(props);
    this.svgRef = React.createRef();
    this.containerRef = React.createRef();
    // throttle visible rect update since it might be expensive, MUST BE DONE HERE, DOES NOT WORK INLINE
    this.onVisibleWindowChangedThrottled = _.throttle(this.onVisibleWindowChanged, this.props.throttleMs || 200);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering PanZoomScrollableSVGComponent");
    // vM 20181024 MO-1016 for now just access the bounds, so the component is updated when they change
    // in the future this might be used to determine which components must be rendered and which can be ignored since they are not visible at all
    const bounds = this.props.boundsProvider.bounds;

    // layout with fixed header from here: http://stackoverflow.com/questions/36515103/scrollable-div-content-area-with-fixed-header
    // use bootstrap rows to layout the header so that it does not collapse if inline editing takes place.
    // Hammer bug swallows child reference: https://github.com/JedWatson/react-hammerjs/issues/83
    return <HammerComponent onPinchStart={this.onPinchStart}
                            onPinchEnd={this.onPinchEnd}
                            onPinchIn={this.onPinch}
                            onPinchOut={this.onPinch}
                            onPan={this.onPan}
                            onPanStart={this.onPanStart}
                            onPanEnd={this.onPanEnd}
                            options={{
                              recognizers: {
                                pinch: {enable: true, threshold: 0.1},
                                pan: {pointers: 2}
                              }
                            }}>
      <div style={{height: "100%", width: "100%"}}>
        <div className="editor-container" ref={this.containerRef} onWheel={this.onWheel} onScroll={this.onScroll}>
          <svg width="100%" height="100%"
               onMouseDown={this.props.onMouseDown}
               onMouseMove={this.props.onMouseMove}
               onMouseUp={this.props.onMouseUp}
               onMouseLeave={this.props.onMouseLeave}
               onTouchStart={this.props.onTouchStart}
               onTouchMove={this.props.onTouchMove}
               onTouchEnd={this.props.onTouchEnd}
               onTouchCancel={this.props.onTouchCancel}
               onDoubleClick={this.props.onDoubleClick}
               ref={this.svgRef}>
            {this.props.children}
          </svg>
        </div>
      </div>
    </HammerComponent>;
  }

  @autobind
  private effect(scale: number, reaction: IReactionPublic) {
    this.reactionZoom = reaction;

    const zoomingStatus: ZoomingStatus = this.onZoom(scale);

    // update MetusStore state since MetusStore state cannot calculate out of bounds information itself
    // because client window size is not available there
    if (zoomingStatus.outOfLowerBounds || zoomingStatus.outOfUpperBounds) {
      setTimeout(() => {
        Dispatcher.dispatch(new ZoomViewAction(this.props.windowIndex, zoomingStatus))
      }, 0);
    }
  }

  componentDidMount(): void {
    log.debug("componentDidMount");
    this.updateDimensions(true, true);
    reaction(() => { const editorState = viewManagerRegistry.viewManager.getEditorStateByWindowIndex(this.props.windowIndex);
      return editorState? editorState.scale : undefined },
        this.effect);
    let lastResult;
    reaction( () => {
      const editorState = viewManagerRegistry.viewManager.getEditorStateByWindowIndex(this.props.windowIndex);
      if (lastResult?.scrollLeft !== editorState.scrollLeft || lastResult?.scrollTop !== editorState.scrollTop) {
        lastResult = {scrollLeft: editorState.scrollLeft, scrollTop: editorState.scrollTop};
        log.debug("Setting new scrollcontainer position, reaction", lastResult);
      }
      return lastResult;
    }, (scrollPos, reaction) => {
      this.reactionScroll = reaction;
      if (scrollPos) {
        const scrollContainer = this.getScrollContainer();
        scrollContainer.scrollLeft = scrollPos.scrollLeft;
        scrollContainer.scrollTop = scrollPos.scrollTop;
      }
    })
  }

  componentDidUpdate(): void {
    log.debug("componentDidUpdate");
    this.updateDimensions(true, true);

    reaction(() => {
      const editorState = viewManagerRegistry.viewManager.getEditorStateByWindowIndex(this.props.windowIndex);
      return editorState ? editorState.scale : undefined;
    }, this.effect);
  }

  componentWillUnmount(): void {
    if (this.reactionZoom) {
      this.reactionZoom.dispose();
    }
    if (this.reactionScroll) {
      this.reactionScroll.dispose();
    }
  }

  public get scale(): number {
    return this._scale;
  }

  updateDimensions(updateTopLeft: boolean = true, updateScrollPosition: boolean = true, callVisibleWindowCallback: boolean = true): void {
    // bounding box of all svg elements in canvas should be new size of viewport
    const scrollContainer = this.getScrollContainer();
    if (scrollContainer) {
      const {visibleSVGRect, viewBox, svgSize, scrollPosition} = this.calculateDimensions(scrollContainer);
      // determine viewport width and height, enlarge by padding width
      // max to enlarge viewport if smaller than available area to fill whole container
      const viewBoxString: string = `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`;
      const svgDomNode: SVGSVGElement = this.svgRef.current;

      if (updateTopLeft) {
        // normalize diagram x position to the starting point of content
        // this must be fixed to avoid the diagram staying fix if the first column is moved left
        dimensionLog.debug("Setting lastLeftTop:" + viewBox.x + ", " + viewBox.y);
        this.lastLeftTopPosition = new Point(viewBox.x, viewBox.y);
      }

      if (svgDomNode) {
        svgDomNode.setAttribute("viewBox", viewBoxString);
        svgDomNode.setAttribute("width", "" + svgSize.width);
        svgDomNode.setAttribute("height", "" + svgSize.height);
        if (updateScrollPosition) {
          scrollContainer.scrollLeft = scrollPosition.x;
          scrollContainer.scrollTop = scrollPosition.y;
        }
        this.fireVisibleWindowChanged();
      }
    }
  }

  private getScrollContainer(): HTMLDivElement {
    return this.containerRef.current;
  }

  public getVisibleSVGRect(): Rect {
    return this.calculateDimensions(this.getScrollContainer()).visibleSVGRect;
  }

  private onZoom(newScale: number): ZoomingStatus {
    const scrollContainer = this.getScrollContainer();
    let zoomPoint: Point = this.props.zoomPointProvider();
    if (zoomPoint === undefined) {
      // use middle of visible area
      const x = scrollContainer.scrollLeft + scrollContainer.clientWidth / 2;
      const y = scrollContainer.scrollTop + scrollContainer.clientHeight / 2;
      zoomPoint = this.convertClientToSVG(x, y);
      eventLog.debug("Client Middle Zoom Point: ", zoomPoint);
    } else {
      eventLog.debug("Provided Zoom Point: ", zoomPoint);
    }
    const oldZoomPointClient = this.convertSVGPointToClient(zoomPoint.x, zoomPoint.y);

    const zoomingStatus: ZoomingStatus = checkNewScale(newScale, this._scale);

    this._scale = zoomingStatus.scale;

    if (!(zoomingStatus.outOfLowerBounds === true || zoomingStatus.outOfUpperBounds === true)) {
      // update the svg viewBox and size without adjusting scroll position since this was done above
      this.updateDimensions(false, false, false);
    }

    // correct scroll position so zoom point stays at same client coordinates
    const newZoomPointClient = this.convertSVGPointToClient(zoomPoint.x, zoomPoint.y);
    scrollContainer.scrollTop = scrollContainer.scrollTop + Math.round(newZoomPointClient.y - oldZoomPointClient.y);
    scrollContainer.scrollLeft = scrollContainer.scrollLeft + Math.round(newZoomPointClient.x - oldZoomPointClient.x);

    // log all details for debugging
    const zoomPointClient = this.convertSVGPointToClient(zoomPoint.x, zoomPoint.y);
    dimensionLog.debug("Before Zoom: " + oldZoomPointClient.x + "," + oldZoomPointClient.y);
    dimensionLog.debug("After Zoom: " + newZoomPointClient.x + "," + newZoomPointClient.y);
    dimensionLog.debug("Corrected mismatching zoomPoint: " + (newZoomPointClient.x - oldZoomPointClient.x) + "," + (newZoomPointClient.y - oldZoomPointClient.y));
    dimensionLog.debug("After Correction: " + zoomPointClient.x + "," + zoomPointClient.y);

    // trigger update visible window
    this.fireVisibleWindowChanged();

    return zoomingStatus;
  }

  public convertClientRectToSVG(x: number, y: number, width: number, height: number): Rect {
    const svg: SVGSVGElement = this.svgRef.current;
    Validate.isDefined(svg, "SVGRef not defined, SVG might not have been rendered yet");
    // convert from screen to matrix coordinates
    const point = svg.createSVGPoint();
    const transform = svg.getScreenCTM().inverse();
    point.x = x;
    point.y = y;
    const transformedPoint = point.matrixTransform(transform);
    point.x = x + width;
    point.y = y + height;
    const transformedPoint2 = point.matrixTransform(transform);
    return {
      x: transformedPoint.x,
      y: transformedPoint.y,
      width: transformedPoint2.x - transformedPoint.x,
      height: transformedPoint2.y - transformedPoint.y
    };
  }

  /**
   * fire visible window changed event, this is always fired on scroll, thus might not really have changed
   */
  private fireVisibleWindowChanged(): void {
    // bounding box of all svg elements in canvas should be new size of viewport
    const scrollContainer = this.getScrollContainer();
    const {visibleSVGRect, viewBox, svgSize, scrollPosition} = this.calculateDimensions(scrollContainer);
    this.onVisibleWindowChangedThrottled(this._scale, svgSize.width, svgSize.height, visibleSVGRect);
  }

  @autobind
  private onWheel(e: any): void {
    eventLog.debug("PanZoomScrollableSVGComponent.onWheel called");
    // override ctrl-zoom behaviour of browser
    if (e.ctrlKey) {
      const calculatedScale = new ZoomHelper(this._scale).calculateNewScaleFromMouseDelta(e.deltaY);
      eventLog.debug("Wheel Event: " + e.deltaY + ", scale: " + this._scale);
      const zoomingStatus: ZoomingStatus = this.onZoom(calculatedScale);
      Dispatcher.dispatch(new ZoomViewAction(this.props.windowIndex, zoomingStatus));
      e.preventDefault();
    }
  }

  /**
   * touch pinch by hammer.js
   * @param e
   */
  @autobind
  private onPinch(e: HammerInput): void {
    eventLog.debug("Pinch", e);
    const calculatedScale = this.originalScale * e.scale;
    this.onZoom(calculatedScale);
    e.preventDefault();
  }

  /**
   * touch pinch by hammer.js
   * @param e
   */
  @autobind
  private onPinchStart(e: HammerInput): void {
    eventLog.debug("PinchStart", e);
    this.originalScale = this._scale;
    e.preventDefault();
    this.inTouch = false;
  }

  /**
   * touch pinch by hammer.js
   * @param e
   */
  @autobind
  private onPinchEnd(e: HammerInput): void {
    eventLog.debug("PinchEnd", e);
    this.originalScale = null;
    e.preventDefault();
  }

  @autobind
  private onPanStart(e: HammerInput): void {
    eventLog.debug("PanStart", e);
    const scrollContainer = this.getScrollContainer();
    this.originalScrollPosition = new Point(scrollContainer.scrollLeft, scrollContainer.scrollTop);
    this.inTouch = false;
  }

  @autobind
  private onPanEnd(e: HammerInput): void {
    eventLog.debug("PanEnd", e);
    this.onPan(e);
    this.originalScrollPosition = null;
  }

  @autobind
  private onPan(e: HammerInput): void {
    eventLog.debug("Pan", e);
    if (this.originalScrollPosition) {
      const scrollContainer = this.getScrollContainer();
      scrollContainer.scrollTop = this.originalScrollPosition.y - e.deltaY;
      scrollContainer.scrollLeft = this.originalScrollPosition.x - e.deltaX;
    }
  }

  @autobind
  private onScroll(): void {
    eventLog.debug("onScroll called");
    this.fireVisibleWindowChanged();
  }

  public convertSVGPointToClient(x: number, y: number): Point {
    const svg: SVGSVGElement = this.svgRef.current;
    // convert from screen to matrix coordinates
    const point = svg.createSVGPoint();
    const transform = svg.getScreenCTM();
    point.x = x;
    point.y = y;
    const transformedPoint = point.matrixTransform(transform);
    return new Point(transformedPoint.x, transformedPoint.y);
  }

  public convertSVGToClient(x: number, y: number, width: number, height: number): Rectangle {
    const svg: SVGSVGElement = this.svgRef.current;
    // convert from screen to matrix coordinates
    const point = svg.createSVGPoint();
    const transform = svg.getScreenCTM();
    point.x = x;
    point.y = y;
    const transformedPoint = point.matrixTransform(transform);
    point.x = x + width;
    point.y = y + height;
    const transformedPoint2 = point.matrixTransform(transform);
    return Rectangle.fromPoints(transformedPoint.x, transformedPoint.y, transformedPoint2.x, transformedPoint2.y);
  }

  public convertClientToSVG(x: number, y: number): Point {
    const svg: SVGSVGElement = this.svgRef.current;
    // convert from screen to matrix coordinates
    const point = svg.createSVGPoint();
    const transform = svg.getScreenCTM().inverse();
    point.x = x;
    point.y = y;
    const transformedPoint = point.matrixTransform(transform);
    return new Point(transformedPoint.x, transformedPoint.y);
  }

  private onVisibleWindowChanged(scale: number, width: number, height: number, visibleClientRect: Rect): void {
    this.props.onVisibleWindowChanged(scale, width, height, visibleClientRect);
  }

  calculateNewScrollCoordinatesOnMouseHover(mousePosition: { x: number; y: number }): ScrollCoordinates {
    const container = this.containerRef.current;
    const absolutePositions = container.getBoundingClientRect();
    const autoScrollOnHoverCalculator = new AutoScrollOnHoverCalculator(absolutePositions, mousePosition);
    return autoScrollOnHoverCalculator.calculateTargetScrollCoordinates(container);
  }

  getCurrentScrollCoordinates(): ScrollCoordinates {
    const container = this.containerRef.current;
    return {scrollLeft: container.scrollLeft, scrollTop: container.scrollTop};
  }

  /**
   * @param scrollContainer
   * @return visible rect in svg coordinates, viewBox the svg uses, size of svg, current scrollposition in scrollcontainer coordinates
   */
  private calculateDimensions(scrollContainer: HTMLDivElement): { visibleSVGRect: Rect, viewBox: Rect, svgSize: Size, scrollPosition: Point } {
    const clientRect = scrollContainer.getBoundingClientRect();
    const visibleSVGRect = this.convertClientRectToSVG(clientRect.left, clientRect.top, clientRect.width, clientRect.height);
    // store visibleSVGRect as variable, so there's no need to recalculate the dimensions every time the DnD Feedback needs it
    this.visibleSVGRect = visibleSVGRect;
    const svgBox: Rect = this.props.boundsProvider.bounds ? this.props.boundsProvider.bounds : {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };
    const currentScrollPosition = new Point(scrollContainer.scrollLeft, scrollContainer.scrollTop);
    const clientSize = new Point(scrollContainer.clientWidth, scrollContainer.clientHeight);
    // determine container client height and width
    const msg = "BBox: " + JSON.stringify({
      x: svgBox.x,
      y: svgBox.y,
      width: svgBox.width,
      height: svgBox.height,
      clientSize,
      leftTop: this.lastLeftTopPosition,
      scrollPosition: currentScrollPosition
    });
    dimensionLog.debug(msg);
    const {viewBox, svgSize, scrollPosition} = ZoomHelper.determineViewBox(this._scale, this.lastLeftTopPosition, scrollContainer.clientWidth, scrollContainer.clientHeight, svgBox, currentScrollPosition);
    return {visibleSVGRect, viewBox, svgSize, scrollPosition};
  }

}
