import {baseurl, loadAndDispatchAction} from "../../commonviews/actions/RESTCallActionCreatorsBase";
import {ElementId, TableId, ViewId, VisualAttributeId, VisualTableId} from "../../core/utils/Core";
import {
  ChartAttributeData,
  chartChildren,
  ChartTableData,
  DiagramData,
  FilterData,
  GeneralizedTableAndAttributeData,
  Table,
  UUID,
  valueChartChildren,
  VisualElementEntry
} from "../../api/api";
import _ from "lodash";
import {
  getAttributeValuesForTablesWithReferencedAttributes,
  getConnectionsForTables,
  syncFilterData
} from "../../core/actions/CoreAsyncActionCreators";
import {DiagramVisualConstants} from "../../commonviews/constants/DiagramVisualConstants";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {DiagramSyncActions} from "./DiagramActions";
import {UpdateViewerFilterForAttributeAction} from "../../commonviews/actions/SharedViewActions";
import {modelStore} from "../../core/stores/ModelStore";
import {loadView} from "../../commonviews/services/ViewServices";
import {firstYPositionInTable, nextYPosition} from "../utils/DiagramPositionUtil";

export function loadDefaultStyles(groupId: UUID): Promise<any> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/defaultstyles`;
  return loadAndDispatchAction("defaultstyles", null, url, false, groupId);
}

/**
 * migrate diagram data if serialization format was changed
 * @param {DiagramData} data
 * @returns migrated DiagramData
 */
export function migrate(data: DiagramData): DiagramData {
  // compatibility with pre 2.1 views
  let children = data.children;
  if (!data.hasOwnProperty("children") && data.hasOwnProperty("tables")) {
    children = (data as any).tables;
  }
  return {...data, children};
}

/**
 * extracts all table and attribute data from an arbitrary diagram
 * @param data
 * @return an array of used tables and their attributes
 */
export function extractTableAndAttributeData(data: DiagramData): GeneralizedTableAndAttributeData[] {
  let result: GeneralizedTableAndAttributeData[] = chartChildren(data);
  if (result.length === 0) {
    // if there are more than one value chart, return union of all levels of all value charts
    result = _.flatten(valueChartChildren(data));
  }
  return result;
}

/**
 * uses same function for Chart and Value Chart, differences should only be in sync functions and logic of getAttributeValuesForTables
 * @param viewId
 * @param groupId
 */
//
export async function loadDiagramAndData(viewId: ViewId, groupId: UUID): Promise<DiagramData> {
  // load pure view data
  let diagramData: DiagramData = await loadView<DiagramData>(viewId, groupId);

  if (diagramData !== null) {
    // if a migration from old formats necessary do it (should have been done in Metus 12 already, but keep if clients skip a release)
    diagramData = migrate(diagramData);
    const isChart: boolean = !(valueChartChildren(diagramData).length !== 0);
    // sync tables and attributes used in diagram with the ones available in ModelStore table meta since they might have been deleted in between
    // all stale data will be deleted here
    if (isChart) {
      syncChartDataTablesAttributesAndFilters(diagramData);
    } else {
      syncValueChartDataTablesAttributesAndFilters(diagramData);
    }

    const tablesAndAttributes: GeneralizedTableAndAttributeData[] = extractTableAndAttributeData(diagramData);
    const tables: Table[] = await getAttributeValuesForTablesWithReferencedAttributes(viewId, tablesAndAttributes, groupId);
    if (isChart) {
      // charts have serialized elements which need to be synchronized, value charts currently have not
      tables.forEach(table => {
        syncChartDataValues(diagramData, table);
      });
    }

    dispatchViewContentAndAttributeValues(diagramData, tables, viewId, groupId);

    await getConnectionsForTables(viewId, tablesAndAttributes.map(table => table.id["tableId"] === undefined ? table.id as TableId : (table.id as VisualTableId).tableId), groupId);

    if (Array.isArray(diagramData.filterData)) {
      diagramData.filterData.forEach((filterData: FilterData): void => {
        const visualTableId: VisualTableId = new VisualTableId(filterData.visualTables[0][0], filterData.visualTables[0][1]);
        const visualAttributeId: VisualAttributeId = new VisualAttributeId(visualTableId, filterData.attribute);
        Dispatcher.dispatch(new UpdateViewerFilterForAttributeAction(viewId, visualAttributeId, filterData.filterText));
      });
    }
  }

  return diagramData;
}

function dispatchViewContentAndAttributeValues(diagramData: DiagramData, tables: Table[], viewId: ViewId, groupId: UUID) {
  Dispatcher.dispatch({
    type: "webview", payload: diagramData, resourceId: viewId,
    undoable: true, groupId
  });
  Dispatcher.dispatch({
    type: "loadAttributeValues", payload: tables, resourceId: viewId,
    undoable: true, groupId
  });
}

/**
 * deletes all attributes, tables and filter from the diagram for which core model element does not exist,
 * since it may have been deleted in between
 * @param chartData
 */
function syncChartDataTablesAttributesAndFilters(chartData: DiagramData): void {
  // delete tables and attributes that do not exist anymore and update indices
  const tablesNotDeletedInModel: ChartTableData[] = [];
  chartChildren(chartData).forEach((tableData: ChartTableData) => {
    if (modelStore.tableNameByTableId.has(tableData.id.tableId)) {
      const attributesNotDeleted: ChartAttributeData[] = [];
      tableData.attributes.forEach((attribute: ChartAttributeData) => {
        if (modelStore.tableHasAttributeDefinition(tableData.id.tableId, attribute.name)) {
          attributesNotDeleted.push(attribute);
        }
      });

      tableData.attributes = attributesNotDeleted;

      tablesNotDeletedInModel.push(tableData);
    }
  });

  const textboxes = chartData.children.filter(child => child.hasOwnProperty("text"));
  chartData.children = tablesNotDeletedInModel;
  // readd textboxes that got filtered out druing sync of tables
  chartData.children = chartData.children.concat(textboxes);
  chartData.filterData = syncFilterData(chartData.filterData);
}

/**
 * deletes all attributes, tables and filter from the diagram for which core model element does not exist,
 * since it may have been deleted in between
 * @param valueChartData
 */
function syncValueChartDataTablesAttributesAndFilters(valueChartData: DiagramData): void {
  // TODO: this was doing nothing before, but is this correct ?
}

function syncChartDataValues(diagramData: DiagramData, table: Table): void {
  const chartTables: ChartTableData[] = chartChildren(diagramData).filter(chartTableData => {
    return chartTableData.id.tableId === table.tableId;
  });

  chartTables.forEach(chartTableData => {
    // vM20190401 MO-1585: make sync work even if same nr of deletes/inserts were done
    /* Remove elements from serialized graph which have been deleted in the meanwhile. */
    _.remove(chartTableData.visualElements, (visualElementEntry: VisualElementEntry) => {
      return !table.attributeValues.generatedId.includes(visualElementEntry[0]);
    });
    /* Collect ids from elements which have been added in the meanwhile. */
    const storedElementIds: ElementId[] = chartTableData.visualElements.map(visualElement => visualElement[0]);
    const newElementIds: ElementId[] = table.attributeValues.generatedId.filter(elementId => {
      return !storedElementIds.includes(elementId);
    });

    const yPosition = calculateNextDefaultVisualElementPosition(chartTableData);
    /* Add new nodes to the serialized graph. */
    newElementIds.forEach((elementId, index) => {
      chartTableData.visualElements.push([elementId, 0, nextYPosition(yPosition, index + 1), 0, DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT]);
    });
  });
}

export function calculateNextDefaultVisualElementPosition(chartTableData: ChartTableData): number {
  /* export type VisualElementEntry = [ExtNodeId, x, y, width, height] */
  const allYValues: number[] = chartTableData.visualElements.map(visualElement => visualElement[2]);
  return _.max(allYValues) || firstYPositionInTable();
}

export type DiagramAsyncActions = UpdateViewerFilterForAttributeAction;
export type DiagramActions = DiagramAsyncActions | DiagramSyncActions;
