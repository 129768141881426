/* OverwriteWriteLockDialog.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as React from "react";
import Log from "../../common/utils/Logger";
import {createStyles, List, ListItem, ListItemText, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import {Classifier} from "../../common/utils/ClassifierLogger";
import MetusDialog from "../../common/components/MetusDialog";
import {showDialog} from "../../common/utils/CommonDialogUtil";
import {unlock} from "../../modelselection/actions/ModelAsyncActionCreators";
import {CommitStrategy} from "../../api/api";
import {EntityStateMergeConflict} from "../../api/mergeModelApi";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
  open: boolean;
  mergeConflicts: EntityStateMergeConflict[];
}

interface LocalState {
}

const styles = (theme: Theme): StyleRules => createStyles({

});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class MergeDialogIntern extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {  };
  }

  getDialogContent(): JSX.Element {
    let count = 0;
    return <List>
      {this.props.mergeConflicts.map(mergeConflict => {
        return <ListItem>
          <ListItemText id={"mergeConflict" + count++} primary={JSON.stringify(mergeConflict)}/>
        </ListItem>
      })}
    </List>;
  }

  render(): JSX.Element {
    return <MetusDialog
        data-testselector="MergeDialog"
        title="Merge Changes"
        open={this.props.open}
        onClose={this.handleClose}
        primaryButtonName={"Merge with Take Mine"}
        onPrimaryButtonPressed={this.automergeTakeMine}
        aux1ButtonName={"Merge with Take Theirs"}
        onAux1ButtonPressed={this.automergeTakeTheirs}>
      {this.getDialogContent()}
    </MetusDialog>;
  }

  @autobind
  private handleClose(): void {
    showDialog(false);
  }

  @autobind
  private automergeTakeMine(): void {
    this.automerge(CommitStrategy.CommitMine)
  }

  @autobind
  private automergeTakeTheirs(): void {
    this.automerge(CommitStrategy.CommitTheirs)
  }

  private automerge(commitStrategy: CommitStrategy): void {
    unlock(commitStrategy);
    this.handleClose();
  }
}


export function showMergeDialog(display: boolean, mergeConflicts: EntityStateMergeConflict[]): void {
  showDialog(display, <MergeDialog open={true} mergeConflicts={mergeConflicts}/>);
}

const MergeDialog = withStyles(styles)(MergeDialogIntern)
export default MergeDialog;
