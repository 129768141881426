/* CockpitsIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class Logo extends MetusSvgIcon {
  svg(): JSX.Element {
    return <g id="metus_pwc_logo" data-name="metus_pwc_logo">
      <g>
        <path d="M168.05,7.45l11.72,31.24,11.77-31.24h6.77V44.62h-4.69V13.69h-.1l-11.61,30.93h-4.22l-11.61-30.93h-.1v30.93h-4.69V7.45h6.77Z"/>
        <path d="M224.85,42.9c-2.01,1.53-4.55,2.29-7.6,2.29-2.15,0-4.02-.35-5.6-1.04-1.58-.69-2.91-1.67-3.98-2.92-1.08-1.25-1.88-2.74-2.42-4.48-.54-1.74-.84-3.63-.91-5.68,0-2.05,.31-3.92,.94-5.62,.62-1.7,1.5-3.18,2.63-4.43,1.13-1.25,2.46-2.22,4.01-2.92,1.54-.69,3.24-1.04,5.08-1.04,2.4,0,4.38,.5,5.96,1.48,1.58,.99,2.85,2.25,3.8,3.77,.95,1.53,1.61,3.19,1.98,5,.36,1.8,.51,3.52,.44,5.15h-20.15c-.04,1.18,.1,2.3,.42,3.36,.31,1.06,.82,2,1.51,2.81,.69,.82,1.58,1.47,2.66,1.95,1.08,.49,2.34,.73,3.8,.73,1.87,0,3.41-.43,4.61-1.3,1.2-.87,1.99-2.19,2.37-3.96h4.37c-.59,3.02-1.89,5.29-3.91,6.82Zm-1.07-17.29c-.4-.94-.94-1.74-1.61-2.42-.68-.68-1.48-1.21-2.4-1.61-.92-.4-1.94-.6-3.05-.6s-2.18,.2-3.1,.6c-.92,.4-1.71,.95-2.37,1.64-.66,.69-1.18,1.5-1.56,2.42-.38,.92-.61,1.9-.68,2.94h15.46c-.07-1.04-.3-2.03-.7-2.97Z"/>
        <path d="M245.26,17.7v3.91h-5.36v16.71c0,.52,.04,.94,.13,1.25,.09,.31,.25,.56,.49,.73,.24,.17,.58,.29,1.02,.34,.43,.05,1,.08,1.69,.08h2.03v3.91h-3.38c-1.15,0-2.13-.08-2.94-.23-.82-.16-1.48-.44-1.98-.86-.5-.42-.88-1.01-1.12-1.77-.24-.76-.36-1.77-.36-3.02V21.61h-4.58v-3.91h4.58V9.63h4.43v8.07h5.36Z"/>
        <path d="M268.28,44.62v-4.27h-.1c-.94,1.67-2.13,2.89-3.59,3.67-1.46,.78-3.18,1.17-5.15,1.17-1.77,0-3.25-.23-4.43-.7-1.18-.47-2.13-1.14-2.86-2-.73-.87-1.24-1.9-1.54-3.1-.3-1.2-.44-2.53-.44-3.98V17.7h4.43v18.22c0,1.67,.49,2.99,1.46,3.96,.97,.97,2.31,1.46,4.01,1.46,1.35,0,2.53-.21,3.51-.62,.99-.42,1.81-1.01,2.47-1.77,.66-.76,1.15-1.66,1.48-2.68,.33-1.02,.49-2.14,.49-3.36v-15.2h4.43v26.92h-4.17Z"/>
        <path d="M282.49,38.61c.42,.68,.96,1.21,1.64,1.61s1.44,.69,2.29,.86c.85,.17,1.73,.26,2.63,.26,.69,0,1.42-.05,2.19-.16,.76-.1,1.47-.29,2.11-.57,.64-.28,1.17-.69,1.59-1.22s.62-1.22,.62-2.06c0-1.15-.43-2.01-1.3-2.6-.87-.59-1.95-1.07-3.25-1.43-1.3-.36-2.72-.69-4.24-.99-1.53-.29-2.94-.71-4.24-1.25s-2.39-1.29-3.25-2.26c-.87-.97-1.3-2.33-1.3-4.06,0-1.35,.3-2.52,.91-3.49,.61-.97,1.39-1.76,2.34-2.37,.95-.61,2.03-1.06,3.23-1.35,1.2-.29,2.39-.44,3.57-.44,1.53,0,2.93,.13,4.22,.39,1.28,.26,2.42,.71,3.41,1.35,.99,.64,1.78,1.51,2.37,2.6,.59,1.09,.94,2.46,1.04,4.09h-4.43c-.07-.87-.3-1.59-.68-2.16-.38-.57-.87-1.03-1.46-1.38-.59-.35-1.24-.6-1.95-.75-.71-.16-1.43-.23-2.16-.23-.66,0-1.33,.05-2,.16-.68,.1-1.29,.29-1.85,.55-.56,.26-1.01,.61-1.35,1.04-.35,.43-.52,1-.52,1.69,0,.76,.27,1.4,.81,1.9,.54,.5,1.22,.92,2.06,1.25s1.77,.61,2.81,.83,2.08,.46,3.12,.7c1.11,.24,2.19,.54,3.25,.89,1.06,.35,2,.81,2.81,1.38,.82,.57,1.48,1.29,1.98,2.16,.5,.87,.75,1.94,.75,3.23,0,1.63-.34,2.99-1.02,4.06s-1.56,1.94-2.66,2.6c-1.09,.66-2.33,1.12-3.7,1.38-1.37,.26-2.73,.39-4.09,.39-1.49,0-2.92-.16-4.27-.47-1.35-.31-2.55-.82-3.59-1.54-1.04-.71-1.87-1.65-2.5-2.81-.62-1.16-.97-2.58-1.04-4.24h4.43c.03,.97,.26,1.8,.68,2.47Z"/>
      </g>
      <g>
        <path d="M130.19,10.8c-6.19,1-9.35,5.57-9.35,13.61s4.21,13.51,10.68,13.51c3.01,0,5.74-1,11.48-3.82v6.55c-6.89,3.16-10.95,4.14-16.5,4.14-6.01,0-10.24-1.6-13.69-5.12-3.54-3.54-5.31-8.22-5.31-13.42,0-11.58,8.58-19.42,21.1-19.42,8.32,0,14.04,3.8,14.04,9.38,0,3.59-2.66,6.07-6.61,6.07-2.03,0-3.69-.53-5.83-1.77V10.8Z"/>
        <path d="M99.66,26.25c5.55-6.99,7.51-9.82,7.51-13.25s-2.71-6.17-6.34-6.17c-2.23,0-4.24,1.05-5.23,2.15v14.25l-9.09,12.11V7.72h-8.64l-14.37,23.82V7.72h-4.97l-13.04,3.18v3.33l7.11,.72v29.21h9.2l13.78-22.65v22.65h10.07l14.01-17.92Z"/>
        <path d="M18.02,39.68c.98,.05,1.41,.05,2.03,.05,7.41,0,11.38-4.62,11.38-13.47,0-7.49-3.25-11.48-9.24-11.48-1,0-2.05,.12-4.16,.38v24.53Zm0,15.96l5.96,1.33v3.38s-23.39,0-23.39,0v-3.38l5.33-1.33V14.77H0v-3.5L14.31,6.92h3.71v4.88c6.7-3.99,9-4.69,12.44-4.69,7.94,0,13.8,7.32,13.8,17.46,0,11.72-7.82,19.61-20.14,19.61-1.39,0-3.71-.08-6.1-.36v11.82Z"/>
      </g>
    </g>;
  }

  viewBox(): string {
    return "0 0 300 65";
  }
}

export default withStyles(svgIconStyles)(Logo);
