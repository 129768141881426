import {HierarchicalElementGraph, NeighbourType} from "../elementgraph/ElementGraph";
import {ElementId} from "../Core";
import {ViewType} from "../../../common/constants/Enums";

/**
 * Created by P.Bernhard on 10.10.2017.
 */

export abstract class HierarchicalNodeCollectorMatcher {

  public filterTextMatches(filterText: string, hierarchy: HierarchicalElementGraph): boolean {
    try {
      return filterText.match(this.getMatcherRegex(hierarchy)) !== null;
    } catch (e) {
      return false;
    }
  }

  public restString(filterText: string, hierarchy: HierarchicalElementGraph): string {
    if (!this.filterTextMatches(filterText, hierarchy))
      return filterText;

    return filterText.substring(0, filterText.lastIndexOf("@"));
  }

  public abstract collectNodes(startNodes: ElementId[], hierarchy: HierarchicalElementGraph, evel: number): Set<ElementId>;

  public abstract isAllowedFor(view: ViewType): boolean;

  protected abstract getMatcherRegex(hierarchy: HierarchicalElementGraph): string;

  // helper functions
  protected getConnectedDirectedNeighbours(startNodes: Set<ElementId>, hierarchy: HierarchicalElementGraph, neighbourType: NeighbourType): Set<ElementId> {
    const directedNeighbours: Set<ElementId> = new Set<ElementId>();

    startNodes.forEach(nodeId => {
      let directedNeighboursForNode: Set<ElementId>;
      if (neighbourType === NeighbourType.Parents)
        directedNeighboursForNode = hierarchy.getParents(nodeId);
      else
        directedNeighboursForNode = hierarchy.getChildren(nodeId);

      directedNeighboursForNode.forEach(neighbourNodeId => {
        directedNeighbours.add(neighbourNodeId);
      });
    });

    return directedNeighbours;
  }

  protected getConnectedChildren(startNodes: Set<ElementId>, hierarchy: HierarchicalElementGraph): Set<ElementId> {
    return this.getConnectedDirectedNeighbours(startNodes, hierarchy, NeighbourType.Children);
  }

  protected getConnectedParents(startNodes: Set<ElementId>, hierarchy: HierarchicalElementGraph): Set<ElementId> {
    return this.getConnectedDirectedNeighbours(startNodes, hierarchy, NeighbourType.Parents);
  }

  protected getConnectedNodes(startNodes: Set<ElementId>, hierarchy: HierarchicalElementGraph): Set<ElementId> {
    const children: Set<ElementId> = this.getConnectedChildren(startNodes, hierarchy);
    const parents: Set<ElementId> = this.getConnectedParents(startNodes, hierarchy);

    parents.forEach(nodeId => {
      children.add(nodeId);
    });

    return children;
  }

  protected getAllNodesInLowerLevels(level: number, hierarchy: HierarchicalElementGraph): Set<ElementId> {
    const nodesInLowerLevels: Set<ElementId> = new Set<ElementId>();

    level--;
    while (level >= 0) {
      hierarchy.getElementsPerLevel(level).forEach(nodeId => {
        nodesInLowerLevels.add(nodeId);
      });

      level--;
    }

    return nodesInLowerLevels;
  }
}


class AllLowerLevelNodesAndAllConnectedChildrenMatcher extends HierarchicalNodeCollectorMatcher {// @n

  public collectNodes(startNodes: ElementId[], hierarchy: HierarchicalElementGraph, level: number): Set<ElementId> {
    const nodesInLowerLevels: Set<ElementId> = this.getAllNodesInLowerLevels(level, hierarchy);
    const connectedChildren: Set<ElementId> = this.getConnectedChildren(new Set<ElementId>(startNodes), hierarchy);

    connectedChildren.forEach(nodeId => {
      nodesInLowerLevels.add(nodeId);
    });

    return nodesInLowerLevels;
  }

  //noinspection JSUnusedGlobalSymbols
  public getLevel(filterText: string): number {
    return Number.parseInt(filterText.substring(filterText.lastIndexOf("@") + 1, filterText.length)) - 1;
  }

  public isAllowedFor(view: ViewType): boolean {
    return view === ViewType.StructuredTable;
  }

  protected getMatcherRegex(hierarchy: HierarchicalElementGraph): string {
    const numberOfLevels = hierarchy.numberOfLevels;

    let matcherRegex: string = "";

    const numDecadeLevels: number = Math.floor(numberOfLevels / 10);
    const moduloDecade: number = numberOfLevels % 10;

    if (numDecadeLevels > 0)
      matcherRegex += "[1-9]";
    else
      matcherRegex += "[1-" + moduloDecade + "]";

    if (numDecadeLevels > 1) {
      matcherRegex += "[0-9]{0," + (numDecadeLevels - 1) + "}";
      if (moduloDecade !== 0)
        matcherRegex += "[0-" + moduloDecade + "]";
    }

    matcherRegex = "^.*@" + matcherRegex + "$";

    return matcherRegex;
  }

}

export let allLowerLevelNodesAndAllConnectedChildrenMatcher: AllLowerLevelNodesAndAllConnectedChildrenMatcher = new AllLowerLevelNodesAndAllConnectedChildrenMatcher();


class AllConnectedParentsAndChildrenMatcher extends HierarchicalNodeCollectorMatcher { // @

  protected getMatcherRegex(hierarchy: HierarchicalElementGraph): string {
    return "^.*@$";
  }

  public isAllowedFor(view: ViewType): boolean {
    return view === ViewType.Chart;
  }

  public collectNodes(startNodes: ElementId[], hierarchy: HierarchicalElementGraph, level: number): Set<ElementId> {
    return this.getConnectedNodes(new Set<ElementId>(startNodes), hierarchy);
  }

}

export let allConnectedParentsAndChildrenMatcher: AllConnectedParentsAndChildrenMatcher = new AllConnectedParentsAndChildrenMatcher();


class AllConnectedParentsMatcher extends HierarchicalNodeCollectorMatcher { // For Structured Table

  protected getMatcherRegex(hierarchy: HierarchicalElementGraph): string {
    throw new Error("This is no regular matcher!");
  }

  public isAllowedFor(view: ViewType): boolean {
    return false;
  }

  public collectNodes(startNodes: ElementId[], hierarchy: HierarchicalElementGraph, level: number): Set<ElementId> {
    return this.getConnectedParents(new Set<ElementId>(startNodes), hierarchy);
  }

}

export let allConnectedParentsMatcher: AllConnectedParentsMatcher = new AllConnectedParentsMatcher();

// @i = allLowerLevelNodesAndAllConnectedChildrenMatcher removed from this list -> it is not used anymore (MO-358)
function getAllHierarchicalNodeCollectorMatcher(): HierarchicalNodeCollectorMatcher[] {
  return [allConnectedParentsAndChildrenMatcher];
}

export function isHierarchicalFilterText(filterText: string): boolean {
  return filterText.includes("@");
}

/**
 * Checks wether a NodeCollector is invalid
 *
 * @param filterText Can be the full filter text
 * @param hierarchy Current hierarchy
 */
export function isValidNodeCollectorText(filterText: string, hierarchy: HierarchicalElementGraph): boolean {
  const textIsHierarchical = isHierarchicalFilterText(filterText);

  if (!textIsHierarchical)
    return true;

  // no inspection is necessary because the detected control flow issue is nonsense
  //noinspection RedundantIfStatementJS
  if (!getNodeCollectorMatcher(filterText, hierarchy))
    return false;

  return true;
}

/**
 *
 *
 * @param filterText Can be the full filter text
 * @param hierarchy Current hierarchy
 * @returns A to the text matching filter. Null, if no filter could be found.
 */
export function getNodeCollectorMatcher(filterText: string, hierarchy: HierarchicalElementGraph): HierarchicalNodeCollectorMatcher {
  for (const matcher of getAllHierarchicalNodeCollectorMatcher())
    if (matcher.filterTextMatches(filterText, hierarchy))
      return matcher;

  return null;
}