import * as React from "react";
import Log from "../utils/Logger";
import {Classifier} from "../utils/ClassifierLogger";
import {createStyles, Fade, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";

const gifPath = require("../images/progress.gif");

const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
}

interface LocalState {
  isLoading: boolean;
}

const styles = (theme: Theme): StyleRules => createStyles({
  img: {
    alignSelf: "center",
  },
});

type StyledLocalProps = WithStyles<typeof styles> & LocalProps;

/**
 * This Progress Component only works on mount, not on updates. We did some experiments with getDerivedStateFromProps and componentDidUpdate to detect updates and
 * put it into the rendering code of the top level component, but ran into bigger problems since we cannot determine if the operation is big or small
 * and the component will always be replaced by the progress image on every update. setTimeout does not work either since it will already be rendering,
 * so we decided to wait for React 17 and suspense to do it properly if at all.
 */
class ShowRenderingProgressComponentNoStyles extends React.Component<StyledLocalProps, any> {
  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {isLoading: true};
  }

  componentDidMount(): void {
    renderLog.debug("componentDidMount isLoading: " + this.state.isLoading);
    // must call set timeout, otherwise the loading indicator does not display
    setTimeout(() => this.setState({isLoading: false}), 0);
  }

  render(): JSX.Element {
    let result;
    if (this.state.isLoading) {
      renderLog.debug("ShowRenderingProgressComponent: showing render indicator");
      // The Progress components (linear and circular) from Material UI do not work here
      // The animation of these components breaks after this method has been called with state isLoading === true
      result =
          <Fade in={true} appear={true} timeout={{enter: 4000}}><img className={this.props.classes.img} src={gifPath}
                                                                     alt={"Progress"}
                                                                     data-testselector={"ShowRenderingProgressComponent"}/></Fade>;
    } else {
      renderLog.debug("ShowRenderingProgressComponent: showing children");
      result = this.props.children;
    }
    return result;
  }
}


export const ShowRenderingProgressComponent = withStyles(styles)(ShowRenderingProgressComponentNoStyles);

