import * as React from "react";
import {IconButton} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import autobind from "autobind-decorator";
import {observer} from "mobx-react";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {ViewType} from "../../../common/constants/Enums";
import {diagramStore} from "../../../diagram/stores/DiagramStore";
import {ToggleHeaderExpandedAction} from "../../../commonviews/actions/SharedViewActions";
import {ViewInfo} from "../../../commonviews/models/ViewInfo";

interface LocalProps {
  viewInfo?: ViewInfo;
  windowIndex?: number;
}

interface LocalState {
  isHeaderExpanded: boolean;
}

@observer
export class CollapseHeaderControl extends React.Component<LocalProps, LocalState> {
  constructor(props: LocalProps) {
    super(props);
    this.state = {isHeaderExpanded: true};
  }

  get isHeaderExpanded(): boolean {
    let isHeaderExpanded = this.state.isHeaderExpanded;
    // VM20200427: vc is the only view type which serializes its expanded state (currently), thus this state is overwritten here in case of vc
    // since this must react to observable changes, it is done in this method called in render
    if (this.props.viewInfo.type === ViewType.ValueChart) {
      const diagram = diagramStore.getDiagramForId(this.props.viewInfo.id);
      isHeaderExpanded = diagram? diagram.isHeaderExpanded : true;
    }
    return isHeaderExpanded;
  }

  render(): JSX.Element {
    const isExpanded = this.isHeaderExpanded;
    return <IconButton onClick={this.toggleCollapsed}
                       disabled={this.props.viewInfo.type === ViewType.Chart}
                       data-testselector="CollapseHeaderButton">
      {isExpanded ? <ExpandLess/> : <ExpandMore/>}
    </IconButton>;
  }

  @autobind
  private toggleCollapsed(): void {
    const newValue = !this.isHeaderExpanded;
    // ATTENTION: vc will process this in view state (and thus persist it), other diagrams in editor state and thus not persist it
    Dispatcher.dispatch(new ToggleHeaderExpandedAction(this.props.viewInfo.id, this.props.windowIndex, newValue));
    this.setState(state => ({
      isHeaderExpanded: newValue
    }));
  }
}

