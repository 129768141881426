import {AttributeId, ElementId, TableId, ViewId, VisualTableId} from "../../core/utils/Core";
import {ICellEditorParams} from "ag-grid-community";

/**
 * hack to enable reuse of value chart header in matrix
 */
export class MatrixColumnTableId extends VisualTableId {
  constructor(tableId: TableId) {
    super(tableId, "columnTable." + tableId);
  }
}

export class MatrixRowTableId extends VisualTableId {
  constructor(tableId: TableId) {
    super(tableId, "rowTable." + tableId);
  }
}

/**
 * element path to uniquely identify rows in a structured table hierarchy
 * array of element ids, ids[0] is the root element, last element in array is the id of the deepest element in hierarchy
 */
export type ElementPath = ElementId[];

/**
 * element path as JSON.stringify(ElementPath) string representation
 */
export type ElementPathKey = string;

/**
 * @return primitive representation of element path suited to use as set or map key
 * @param path
 */
export function elementPathToKey(path: ElementPath): ElementPathKey {
  return JSON.stringify(path);
}

/**
 * @return primitive representation of element path suited to use as set or map key
 * @param elementPathKey key
 */
export function elementPathKeyToPath(elementPathKey: ElementPathKey): ElementPath {
  return JSON.parse(elementPathKey) as ElementPath;
}

/**
 * @return length of given path key
 * @param elementPathKey
 */
export function elementPathKeyLength(elementPathKey: ElementPathKey): number {
  return elementPathKeyToPath(elementPathKey).length;
}

/**
 * @return id of leaf element for this path
 * @param path
 */
export function leafElementId(path: ElementPath): ElementId {
  return path[path.length - 1];
}

/**
 * @return element id of leaf element of this path key
 * @param elementPathKey
 */
export function elementPathKeyToLeafElementId(elementPathKey: ElementPathKey): ElementId {
  return leafElementId(elementPathKeyToPath(elementPathKey));
}

/**
 * creates an element path directly from element ids
 * @param ids, root element has index 0, the deepest child id must be last element of array
 */
export function elementIdsToPath(...ids: ElementId[]): ElementPath {
  return ids;
}
/**
 * creates an element path key directly from element ids
 * @param ids, root element has index 0, the deepest child id must be last element of array
 */
export function elementIdsToPathKey(...ids: ElementId[]): ElementPathKey {
  return elementPathToKey(ids);
}

/**
 * Created by P.Bernhard on 17.11.2016.
 */

export interface Row {
  elementId: ElementId;
  name: string;
  level: number;
  treeId: ViewId;
  children?: ElementId[];
  tableId: TableId;
  isExpanded: (id: ElementId) => boolean;
}

/**
 * matrix cell editor params for Join Table Matrix
 */
export interface JoinCellEditorParams extends ICellEditorParams {
  joinTableAttributeId: AttributeId;
}

export interface ReferenceAttributeCellEditorParams extends ICellEditorParams {
  referenceAttributeId: AttributeId;
}

export function areJoinCellEditorParams(params: ICellEditorParams): params is JoinCellEditorParams {
  return (params as JoinCellEditorParams).joinTableAttributeId !== undefined;
}

export interface AllCellRenderer {
  DecimalCellRendererComponent,
  PercentCellRendererComponent,
  MatrixBubbleCellRendererComponent,
  ChainMatrixCellRendererComponent,
  AttributeCellRendererComponent,
  MatrixTreeColumnInnerCellRendererComponent
}

export type CellRendererType = keyof AllCellRenderer;

export interface AllCellEditor {
  ConnectionStrengthCellEditorComponent,
  MatrixConnectionsCellEditorComponent
}

export type CellEditorType = keyof AllCellEditor;
