/* ZoomableDIVComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Oktober 2017
 */
import * as React from "react";
import {CSSProperties} from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import autobind from "autobind-decorator";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {checkNewScale, ZoomingStatus} from "../../common/utils/ZoomHelper";
import {ZoomViewAction} from "../../commonviews/actions/SharedViewActions";

const log = Log.logger("ZoomableDIVComponent");
const dimensionLog = Log.logger("ZoomableDIVComponent", Classifier.update);

interface LocalProps {
  className?: string;
  style?: CSSProperties;
  windowIndex: number;
}

interface LocalState {
  scale: number;
}

export class ZoomableDivComponent extends React.Component<LocalProps, LocalState> {

  constructor(props: LocalProps) {
    super(props);
    this.state = {scale: 1};
  }

  render(): JSX.Element {
    log.debug("Rendering ZoomableDivComponent");

    return <div
        onWheel={this.onWheel}
        className={this.props.className}
        style={{...this.props.style}}>
      {this.props.children}
    </div>;
  }

  @autobind
  private onWheel(e: any): void {
    // react only to alt, because mousewheel will scroll vertically otherways
    if (e.ctrlKey) {
      const calculatedScale: number = this.calculateNewScaleFromMouseDelta(e.deltaY);
      const zoomingStatus: ZoomingStatus = checkNewScale(calculatedScale, this.state.scale);
      this.setState({scale: zoomingStatus.scale});
      Dispatcher.dispatch(new ZoomViewAction(this.props.windowIndex, zoomingStatus));
      e.preventDefault();
    }
  }

  @autobind
  private calculateNewScaleFromMouseDelta(mouseDelta: number): number {
    return this.state.scale - (mouseDelta / 1000);
  }

}
