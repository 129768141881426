import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class ReferenceAttributeIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M10,4 L20,4 L20,14 L15,14 L15,13 L19,13 L19,5 L11,5 L11,8.999 L10,8.999 L10,4 Z M12.501,7 L17.001,7 L17.001,11.5 L16.001,11.5 L16.001,8.707 L13,11.707 L13,20 L4,20 L4,11 L12.294,11 L15.294,8 L12.501,8 L12.501,7 Z M5,18.999 L12,18.999 L12,12 L5,12 L5,18.999 Z" /> ;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(ReferenceAttributeIcon);