/* PortfolioIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import * as React from "react";
import {withStyles} from "@material-ui/core";

class PortfolioIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
      .st0portfolio{fill:#FFFFFF;fill-opacity:0.5;}
      .st1portfolio{fill:#FFFFFF;}
     `}</style>
      <g>
        <path className="st0portfolio" d="M77.42,48.79H65.81c-0.31,0-0.57,0.25-0.57,0.57v0.65c0,0.31,0.25,0.57,0.57,0.57h11.61
		c0.31,0,0.57-0.25,0.57-0.57v-0.65C77.99,49.04,77.73,48.79,77.42,48.79z"/>
        <path className="st0portfolio" d="M49.35,48.79h-7.94V12.91c0-0.31-0.25-0.57-0.57-0.57h-0.66c-0.31,0-0.57,0.25-0.57,0.57v35.88H3.56
		c-0.31,0-0.57,0.25-0.57,0.57v0.65c0,0.31,0.25,0.57,0.57,0.57h36.06v11.2c0,0.31,0.25,0.57,0.57,0.57h0.66
		c0.31,0,0.57-0.25,0.57-0.57v-11.2h7.94c0.31,0,0.57-0.25,0.57-0.57v-0.65C49.92,49.04,49.67,48.79,49.35,48.79z"/>
      </g>
      <circle className="st1portfolio" cx="27.2" cy="18.75" r="7.78"/>
      <circle className="st0portfolio" cx="12.75" cy="38.1" r="6.21"/>
      <circle className="st0portfolio" cx="59.61" cy="22.71" r="4.29"/>
      <g>
        <path className="st1portfolio" d="M57.55,59.18c-4.63,0-8.4-3.77-8.4-8.4c0-4.63,3.77-8.4,8.4-8.4c4.63,0,8.4,3.77,8.4,8.4
		C65.95,55.41,62.18,59.18,57.55,59.18z M57.55,43.38c-4.08,0-7.4,3.32-7.4,7.4s3.32,7.4,7.4,7.4s7.4-3.32,7.4-7.4
		S61.63,43.38,57.55,43.38z"/>
      </g>
      <circle className="st1portfolio" cx="57.55" cy="50.99" r="5.08"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(PortfolioIcon);
