import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";



class ViewsFolderIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M12,16.4922 L16.889,13.0002 L12,9.5082 L7.111,13.0002 L12,16.4922 Z M5.32,13.0002 L12,8.2292 L18.68,13.0002 L12,17.7712 L5.32,13.0002 Z M1,2 L8,2 L10,4 L23,4 L23,21 L1,21 L1,2 Z M3.6,13 L12,19 L20.4,13 L12,7 L3.6,13 Z"/>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(ViewsFolderIcon);