/* UndoActionsComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, September 2018
 */

import {BaseSectionProperties} from "../../properties/model/PropertiesModel";
import * as React from "react";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {redo, undo} from "../actions/UndoRedoActionCreators";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {Redo, Undo} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";
import PropertiesSectionLayout from "../../properties/components/PropertiesSectionLayout";
import PropertiesButton from "../../properties/components/PropertiesButton";
import Action, {ActionBase} from "../../common/actions/BaseAction";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {ServerWritingAction} from "../../core/actions/CoreActions";
import {observer} from "mobx-react";
import {ActionGroup} from "../actions/UndoRedoActions";
import classnames from "classnames";
import {toPascalCaseWithSpaces} from "../../common/utils/FormatUtil";

const renderLog = Log.logger("properties", Classifier.render);

export function getActionDescription(action: ActionBase<any>) {
  let type;
  if (action instanceof ActionGroup) {
    type = action.actions[0].type;
  } else {
    type = action.type;
  }
  return toPascalCaseWithSpaces(type);
}

const styles = (theme: Theme): StyleRules => createStyles({
  list: {
    maxHeight: 500,
    overflow: "auto",
  },
  redoItem: {
    backgroundColor: theme.metus.properties.active.fill,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  highlighted: {
    fontWeight: "bold",
  },
  incomplete: {
    opacity: 0.5,
  },
  undoItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    "&:first-of-type": {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    }
  }
});


export interface UndoActionsProperties extends BaseSectionProperties {
  undoActions: Action[];
  redoActions: Action[];
  isDebug: boolean;
}

type StyledLocalProps = UndoActionsProperties & WithStyles<typeof styles>;

@observer
class UndoActionsComponent extends React.Component<StyledLocalProps> {
  constructor(props: StyledLocalProps) {
    super(props);
  }

  getText(action: Action): string {
    let result = getActionDescription(action);
    if (this.props.isDebug && action.type === "action_group") {
      const actions: Action[] = (action as ActionGroup).actions;
      result = actions.map(action => getActionDescription(action)).join(", ");
    }
    return result;
  }

  private hasServerCommand(action: Action):boolean {
    const commandId = (action as any as ServerWritingAction).commandId;
    return commandId !== undefined && commandId !== null;
  }

  render(): JSX.Element {
    const cbItem = (type:"undo" | "redo") => (action: Action):JSX.Element => {
      const classes = type === "undo" ? this.props.classes.undoItem : this.props.classes.redoItem;
      const textClasses = this.props.isDebug && this.hasServerCommand(action)?this.props.classes.highlighted:null;
      const allCs = classnames(textClasses, action.isCompleteUndoStep ? null : this.props.classes.incomplete);
      return <ListItem className={classes} key={action.id}>
              <ListItemText classes={{primary:allCs}}>{this.getText(action)}</ListItemText>
             </ListItem>;
    };
    renderLog.debug("Rendering UndoActionsComponent", this.props);
    let undoActions = this.props.undoActions.slice().reverse();
    let redoActions = this.props.redoActions.slice();
    if (!this.props.isDebug) {
      // only show complete actions ...
      undoActions = undoActions.filter(action => action.isCompleteUndoStep);
      // ... and cut off everything after the first non-undoable action
      const firstUndoableIndex = undoActions.findIndex(action => !action.undoable);
      if (firstUndoableIndex !== -1) {
        undoActions = undoActions.slice(0, firstUndoableIndex);
      }
      redoActions = redoActions.filter(action => action.isCompleteUndoStep);
    }

    // hasUndo and hasRedo should be calculated from the store and given in props. However, in order to make that work with mobx,
    // the properties container would need to be a mobx observable. if this is preferable or not, needs to be investigated
    const hasUndo = undoActions.length > 0 && undoActions[0].undoable;
    const hasRedo = redoActions.length > 0;
    return <PropertiesSectionLayout>
      <PropertiesButton onClick={undo}
                        disabled={!hasUndo}
                        icon={<Undo/>}>Undo</PropertiesButton>
      <PropertiesButton onClick={redo}
                        disabled={!hasRedo}
                        icon={<Redo/>}>Redo</PropertiesButton>
      <List className={this.props.classes.list}>{redoActions.map(cbItem("redo"))}{undoActions.map(cbItem("undo"))}</List>
    </PropertiesSectionLayout>;
  }

}

export default withStyles(styles)(UndoActionsComponent);
