import {DiagramModel} from "../DiagramModel";
import {VisualValueChartElement} from "./VisualValueChartElement";
import {
  ChartAttributeData,
  HeaderData,
  NAME_ATT_NAME,
  UUID,
  VisualValueChartData,
  VisualValueChartLevelData
} from "../../../api/api";
import {VisualValueChartLevel} from "./VisualValueChartLevel";
import {Rect, Rectangle, Size} from "../../../common/utils/Geometry";
import {
  ElementId,
  ElementObject,
  TableId,
  VisualAttributeId,
  VisualElementId,
  VisualElementIdString,
  VisualTableId
} from "../../../core/utils/Core";
import {VisualObject, VisualObjectBase} from "../VisualObject";
import {VisualValueChartAttributeValue} from "./VisualValueChartAttributeValue";
import {VisualAttributeDefinition} from "../common/VisualAttributeDefinition";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {modelStore} from "../../../core/stores/ModelStore";
import {computed, observable} from "mobx";
import {VisualHeader} from "../../../commonviews/models/VisualHeader";
import {VisitState} from "../../../common/utils/Visitor";
import Log from "../../../common/utils/Logger";
import {getSimpleMatcher, SimpleMatcher} from "../../../core/utils/filter/Evaluator";
import {VisualValueChartMetadata} from "./VisualValueChartMetadata";
import {AdditionalPropArgs, getDefaultModelSchema, list, ModelSchema, PropSchema, serializable} from "serializr";
import {invariant, polymorphic} from "../../../common/utils/SerializrUtils";
import {ViewType} from "../../../common/constants/Enums";

const log = Log.logger("model");

function modelSchemaResolver(objectOrType: Object | string): ModelSchema<VisualValueChartElement | VisualValueChartLevel> {
  if (typeof objectOrType === "string") {
    switch (objectOrType) {
      case "VisualValueChartElement":
        return getDefaultModelSchema(VisualValueChartElement);
      case "VisualValueChartLevel":
        return getDefaultModelSchema(VisualValueChartLevel);
      default:
        invariant(false, "Object Type not understood by modelSchemaResolver: " + objectOrType);
    }
  } else {
    if (objectOrType["title"] !== undefined) {
      return getDefaultModelSchema(VisualValueChartElement);
    } else if (objectOrType["columns"] !== undefined) {
      return getDefaultModelSchema(VisualValueChartLevel);
    }
    invariant(false, "Object not understood by modelSchemaResolver: " + objectOrType);
  }
}


export const handleAfterDeserializeChildren: AdditionalPropArgs = {
  afterDeserialize: afterDeserializeChildren
}

function afterDeserializeChildren(callback: (err: any, value: any) => void, error: any, newValue: VisualValueChart[], jsonValue: any, jsonParentValue: any, propNameOrIndex: string | number | symbol, context: any, propDef: PropSchema): void {
  newValue.forEach(vvc => {
    const children = vvc.children;
    const vvcLevels: VisualValueChartLevel[] = <VisualValueChartLevel[]>children.filter((child: VisualValueChartElement | VisualValueChartLevel) => child instanceof VisualValueChartLevel);
    const vvcElements: VisualValueChartElement[] = <VisualValueChartElement[]>children.filter((child: VisualValueChartElement | VisualValueChartLevel) => child instanceof VisualValueChartElement);

    /* Set leaf and root level to meta data. */
    vvc.vvcMetadata.leafLevel = getLeafLevel(vvcLevels, vvc.vvcMetadata);
    vvc.vvcMetadata.rootLevel = getRootLevel(vvcLevels, vvc.vvcMetadata);

    /* Assign elements to level. */
    if (vvcElements && vvcElements.length > 0) {
      vvcLevels.forEach(vvcLevel => {
        vvcLevel.removeAllChildren();
        /* Find corresponding json level. */
        const jsonLevel = jsonValue[0]._children.find(child => child.hasOwnProperty("_elements") && child.identifier === vvcLevel.id.toKey())
        jsonLevel._elements.forEach(elementId => {
          const vvcElement: VisualValueChartElement = getVvcElementByIdRecursive(elementId, vvcElements);
          if (vvcElement) {
            vvcLevel.elements.push(vvcElement);
          }
        })
      })
    }
  });
  callback(null, newValue);
}

function getVvcElementByIdRecursive(identifier: string, vvcElements: VisualValueChartElement[]): VisualValueChartElement {
  let retVal: VisualValueChartElement = vvcElements.find(vvcElement => vvcElement.id.toKey() === identifier);
  if (!retVal) {
    for (const vvcElement of vvcElements) {
      if (vvcElement.children && vvcElement.children.length > 0) {
        retVal = getVvcElementByIdRecursive(identifier, vvcElement.children);
        if (retVal) {
          break;
        }
      }
    }
  }
  return retVal;
}

function getRootLevel(levels: VisualValueChartLevel[], vvcMetadata: VisualValueChartMetadata): VisualValueChartLevel {
  let rootLevel: VisualValueChartLevel = undefined;

  levels.forEach((vvcLevel: VisualValueChartLevel) => {
    /* Set meta data to level. */
    vvcLevel.visualValueChartMetadata = vvcMetadata;
    if (!vvcLevel.parentLevel) {
      rootLevel = vvcLevel;
    }
  });

  return rootLevel;
}

function getLeafLevel(levels: VisualValueChartLevel[], vvcMetadata: VisualValueChartMetadata): VisualValueChartLevel {
  let leafLevel: VisualValueChartLevel = undefined;

  levels.forEach((vvcLevel: VisualValueChartLevel) => {
    /* Set meta data to level. */
    vvcLevel.visualValueChartMetadata = vvcMetadata;
    if (!vvcLevel.childLevel) {
      leafLevel = vvcLevel;
    }
  });

  return leafLevel;
}


export class VisualValueChart extends VisualObjectBase<VisualValueChartElement | VisualValueChartLevel, UUID> {
  /** not observable, caches the selection if the editor is made active and changes the selection */
  public secondarySelection: Set<ElementId> = new Set();
  @serializable(list(polymorphic<VisualValueChartLevel | VisualValueChartElement>(modelSchemaResolver))) @observable public _children: (VisualValueChartElement | VisualValueChartLevel)[] = [];
  get children(): (VisualValueChartElement | VisualValueChartLevel)[] {
    return this._children;
  }

  @observable private _bounds: Rect;
  private _visualValueChartMetadata: VisualValueChartMetadata = new VisualValueChartMetadata();

  constructor(parent: VisualObject, id: UUID, visualValueChartData?: VisualValueChartData) {
    super(parent, id);
    this._bounds = {x: 0, y: 0, width: 0, height: 0};

    if (visualValueChartData) {
      this.createVvcLevelFomSerializedData(visualValueChartData.tables);
    }
  }

  get levels(): VisualValueChartLevel[] {
    return this._visualValueChartMetadata.levels;
  }

  /**
   * return all children which are elements, filtering levels from visual children
   */
  public get elementChildren(): VisualValueChartElement[] {
    return this.children.filter(c => {
      return c instanceof VisualValueChartElement;
    }) as VisualValueChartElement[];
  }

  /**
   * @return all *visible* children which are elements
   */
  public get visibleElements(): VisualValueChartElement[] {
    return this.children.filter(c => {
      return c instanceof VisualValueChartElement && c.visible;
    }) as VisualValueChartElement[];
  }

  /**
   * Sorts the element children, leaves the level children ase they are
   * @param compareFn sort function for the element children
   */
  public sortElementChildren(compareFn: (a: VisualValueChartElement, b: VisualValueChartElement) => number): void {
    /**
     * Why is it necessary to clone the array?
     * -> OberservableArray cannot be sort in place
     */
    const sortedElements = [...this.elementChildren].sort(compareFn);
    const levels = this.children.filter(c => {
      return c instanceof VisualValueChartLevel
    });
    this._children = [...levels, ...sortedElements];
  }

  /**
   * @returns {VisualValueChartLevel} topmost level, undefined if no levels defined
   */
  get rootLevel(): VisualValueChartLevel {
    return this._visualValueChartMetadata.rootLevel;
  }

  /**
   * @returns {VisualValueChartLevel} bottommost level,  for which elements have no children , undefined if no levels defined, same as root level if only one level defined
   */
  get leafLevel(): VisualValueChartLevel {
    return this._visualValueChartMetadata.leafLevel;
  }

  get headerHeight(): number {
    return this._visualValueChartMetadata.headerHeight;
  }

  get vvcMetadata(): VisualValueChartMetadata {
    return this._visualValueChartMetadata;
  }

  /**
   * bounds calculated by layout
   * must be @computed.struct because otherwise it would not be called from within VisualObject.bounds
   * since mobx v. 5.7.0, due to https://github.com/mobxjs/mobx/commit/fcb75546c141e4fc05af46b2789cc8b7eac069f3
   */
  @computed.struct
  protected get internalBounds(): Rect {
    console.log("INTERNAL_BOUNDS-VisualValueChart");
    return this._bounds;
  }

  public addTable(visualTableId: VisualTableId, tableName: string): VisualValueChartLevel {
    const level: VisualValueChartLevel = this._visualValueChartMetadata.addTable(visualTableId, tableName);
    this.addChildren(level);
    this.addVisualElements(level.parentLevel);
    return level;
  }

  removeLevel(visualTableId: VisualTableId): VisualValueChartLevel {
    const level = this._visualValueChartMetadata.removeLevel(visualTableId);
    if (level) {
      if (level.parentLevel) {
        // if it was not root level which was removed, update this level
        this.updateVisualElementChildren(level.parentLevel.elements, false);
      } else {
        // root level was removed, thus full sync needed since a new root level or none now exists
        this.updateRootVisualElements();
      }
      // remove level visual from visual value chart
      this.removeChildren(level);
      this.layout();
    }
    return level;
  }

  /** assigns coordinates of all visual elements based on their text, number of elements and level properties */
  public layout(isReorderLevel: boolean = false): void {
    // layout all level sizes based on the leaf level size
    this._visualValueChartMetadata.layout(isReorderLevel);

    // then calculate header height
    const x = DiagramVisualConstants.VC_GAP;
    let currentY = this.getDiagram().isHeaderExpanded ? DiagramVisualConstants.HEADER_ELEMENT_VERTICAL_GAP + this._visualValueChartMetadata.headerHeight : 0;
    this.visibleElements.forEach(ve => {
      ve.layout(x, currentY);
      const {width, height} = ve.computeSize();
      currentY = currentY + height + DiagramVisualConstants.VC_GAP;
    });
    const size = this.computeSize();
    this._bounds = Rectangle.from(0, 0, size.width, size.height);
  }

  /**
   * move the given visual table of this diagram to the new level, levels counted from 0
   * @param visualTableId id of level to move
   * @param newIndex index of the table the new level should be inserted before
   */
  public moveLevel(visualTableId: VisualTableId, newIndex: number): void {
    // change level order and rebuild
    this._visualValueChartMetadata.moveLevel(visualTableId, newIndex);
    this.updateRootVisualElements(true);
  }

  /**
   * @returns {Size} size of the value chart content area, that is all elements including gaps
   */
  public computeSize(): Size {
    const elementWidth = Math.max(0, ...this.visibleElements.map(ve => ve.computeSize().width)) + 2 * DiagramVisualConstants.VC_GAP;
    const elementHeight = this.visibleElements.reduce((s, ve) => s + ve.computeSize().height, 0) + (this.visibleElements.length + 1) * DiagramVisualConstants.VC_GAP;
    const vcWidth = this.rootLevel ? Math.max(elementWidth, this.rootLevel.width) : elementWidth;
    return new Size(vcWidth, elementHeight + this._visualValueChartMetadata.headerHeight + DiagramVisualConstants.HEADER_ELEMENT_VERTICAL_GAP);
  }

  /**
   * recursively resync all visual elements for the root level, creating new ones if needed and deleting the ones which were deleted in the core model, updating all element properties too
   */
  public updateRootVisualElements(isReorderLevel: boolean = false): void {
    if (this.rootLevel !== undefined) {
      const connectedElementIds = modelStore.getElementsForTable(this.rootLevel.tableId).map(element => element.id);
      this.updateVisualElements(this, this.elementChildren, connectedElementIds, this.rootLevel, true);
    } else {
      // level might have been deleted
      this.updateVisualElements(this, this.elementChildren, [], undefined, false);
    }
    this.layout(isReorderLevel);
  }

  /**
   * update all child elements (and optionally attribute values) for the given parent elements differentially by checking which visual children are already there
   * creating missing visual elements for connected core elements and removing ones with no corresponding core element.
   * The Value Chart will not automatically re-layout its chidren after changes.
   *
   * @param updateElementProperties if true, attribute values and derived properties like visibility and conditional format will be updated for untouched visual elements on the way, for newly created ones they will be updated anyway
   * @param parentElements the elements for which children should be updated, each is processed separately
   */
  public updateVisualElementChildren(parentElements: VisualValueChartElement[], updateElementProperties: boolean): void {
    parentElements.forEach(parentElement => {
      const childLevel = parentElement.level.childLevel;
      let connectedElementIds;
      if (childLevel !== undefined) {
        connectedElementIds = modelStore.getConnectedElementIdsToTable(parentElement.id.elementId, childLevel.tableId);
      } else {
        connectedElementIds = [];
      }
      this.updateVisualElements(parentElement, parentElement.children, connectedElementIds, childLevel, updateElementProperties);
    });
  }

  /**
   * update all child elements (and optionally attribute values) for the given parent elements recursively and differentially by checking which visual children are already there
   * creating missing visual elements for connected core elements and removing ones with no corresponding core element
   *
   * @param parent parent object where children will be added/removed, can be VisualValueChartElement or VisualValueChart
   * @param visualElements the visual elements which should be synchronized with the element ids
   * @param elementIds the elements, after sync for each there will be one corresponding visual elements, all must be from the same level
   * @param level level the visual elements belong to, newly created visuals are assigned here, might be undefined if child level is not existing anymore
   * @param updateElementProperties if true, attribute values and derived properties like visibility and conditional format will be updated for untouched visual elements on the way, for newly created ones they will be updated anyway
   */
  public updateVisualElements(parent: VisualObject, visualElements: VisualValueChartElement[], elementIds: ElementId[], level: VisualValueChartLevel, updateElementProperties: boolean): void {
    // assumption: all visual elements have to be created
    const elementsToCreate = new Set<ElementId>(elementIds);
    const visualElementsToUpdate: VisualValueChartElement[] = [];
    const visualElementsToDelete: VisualValueChartElement[] = [];
    // check all existing visual elements if still there
    visualElements.forEach(visualElement => {
      if (elementsToCreate.has(visualElement.id.elementId)) {
        // visual element for core element already existing, just update
        // remove it from the set of elements to create
        elementsToCreate.delete(visualElement.id.elementId);
        // still exists, thus structurally nothing to do here, just recursively chck children
        if (updateElementProperties) {
          this.updateElementProperties(level, visualElement);
        }
        visualElementsToUpdate.push(visualElement);
      } else {
        // visual element where core element does not exist anymore, thus remove
        visualElementsToDelete.push(visualElement);
      }
    });

    if (visualElementsToDelete.length > 0) {
      // recursively delete visual elements where resulting core element (connection) does not exist anymore
      this.removeChildrenRecursively(...visualElementsToDelete);
    }

    // recursively update structure for existing visual elements, there might have been changes too
    if (visualElementsToUpdate.length > 0) {
      this.updateVisualElementChildren(visualElementsToUpdate, updateElementProperties);
    }

    // now create missing visuals
    if (elementsToCreate.size > 0) {
      const created: VisualValueChartElement[] = [];
      elementsToCreate.forEach(elementId => {
        const element = modelStore.getElement(elementId);
        const newVisualElement = new VisualValueChartElement(this, new VisualElementId(elementId), element[NAME_ATT_NAME], level, {});
        created.push(newVisualElement);
      });

      this.updateElementProperties(level, ...created);
      parent.addChildren(...created);

      // recursively add children for newly created
      this.updateVisualElementChildren(created, true);
    }
  }

  /**
   * update all element attributes and if they changed the derived properties, relayouts children to match potentially changed filter visibility
   */
  public updateAllElementProperties(): void {
    this.accept(visualElement => {
      if (visualElement instanceof VisualValueChartElement) {
        this.updateElementProperties(visualElement.level, visualElement);
      }
      return VisitState.CONTINUE;
    });
    this.layout();
  }

  /**
   * update derived properties of all elements, relayouts children to match potentially changed filter visibility
   */
  public updateAllDerivedProperties(): void {
    // ORDER IS IMPORTANT for show connected to selection, since visible child element will set parent chain to visible
    this.levels.forEach(level => this.updateDerivedProperties(level, level.elements));
    this.layout();
  }

  /**
   * update element properties does the following for all given visual elements (it does not recurse into children):
   *  - synchronizes all attribute values with the current model state
   *  - checks if anything has changed and calls updateDerivedProperties accordingly (which in turn updates filter visibility and conditional format)
   */
  public updateElementProperties(level: VisualValueChartLevel, ...visualElements: VisualValueChartElement[]): void {
    const keys = new Set(level.visualAttributeDefinitions.keys());
    const visualElementsToReevaluateDerivedProperties: VisualValueChartElement[] = [];
    visualElements.forEach(visualElement => {
      let reevaluateDerivedProperties: boolean = false;
      const keysToDelete = new Set(visualElement.attributeValues.keys());
      keys.forEach((attName: string) => {
        keysToDelete.delete(attName);
        const element = modelStore.getElement(visualElement.id.elementId);
        const visualAttributeDefinition = level.visualAttributeDefinitions.get(attName);
        if (visualElement.attributeValues.has(attName)) {
          // already existing, update
          const visualAttributeValue = visualElement.attributeValues.get(attName);
          if (visualAttributeValue.value !== element[attName]) {
            reevaluateDerivedProperties = true;
            visualAttributeValue.value = element[attName];
          }
        } else {
          // not existing, create new one
          reevaluateDerivedProperties = true;
          const visualValueChartAttributeValue = new VisualValueChartAttributeValue(visualAttributeDefinition, visualElement, element[attName]);
          visualElement.attributeValues.set(attName, visualValueChartAttributeValue);
        }
      });
      // all keys checked, now remove existing attribute value for which key does not exist anymore
      if (keysToDelete.size > 0) {
        keysToDelete.forEach(key => visualElement.attributeValues.delete(key));
        reevaluateDerivedProperties = true;
      }
      if (reevaluateDerivedProperties) {
        visualElementsToReevaluateDerivedProperties.push(visualElement);
      }
    });
    if (visualElementsToReevaluateDerivedProperties.length > 0) {
      this.updateDerivedProperties(level, visualElementsToReevaluateDerivedProperties);
    }
  }

  mergeAttributeValues(tableId: TableId, elements: ElementObject[]): void {
    const levels = this._visualValueChartMetadata.levelsForTable(tableId);
    const elementIds = new Set<ElementId>(elements.map(e => e.id));
    levels.forEach(level => {
      const visualsToUpdate = level.elements.filter(ve => elementIds.has(ve.id.elementId));
      this.updateElementProperties(level, ...visualsToUpdate);
    });
    this.layout();
  }

  /**
   * updates filter visibility and conditional format for the given visual elements
   * @param level the visual elements belong to
   * @param visualElements visual elements to update derived properties for
   */
  public updateDerivedProperties(level: VisualValueChartLevel, visualElements: VisualValueChartElement[]): void {
    const that = this;
    // assumption: all elements visible
    const visibleElementIdStrings = new Set<VisualElementIdString>(visualElements.map(ve => ve.id.toKey()));
    level.visualAttributeDefinitions.forEach(visualAttributeDefinition => {
      const conditionalFormats = visualAttributeDefinition.conditionalFormats;
      const matcher: SimpleMatcher[] = conditionalFormats.map(cf => getSimpleMatcher(cf.filterExpression, cf.operand.visualTableId.tableId, cf.operand.attributeName));
      const filterExpression = this.getDiagram().viewerFilters.get(visualAttributeDefinition.id.toKey());
      const filterMatcher = filterExpression ? getSimpleMatcher(filterExpression, visualAttributeDefinition.id.visualTableId.tableId, visualAttributeDefinition.id.attributeName) : undefined;
      visualElements.forEach(visualElement => {

        // update conditional format for this element
        const visualAttributeValue = visualElement.attributeValues.get(visualAttributeDefinition.id.attributeName);
        if (visualAttributeValue) {
          let conditionalStyles = undefined;
          for (let i = 0; i < matcher.length; i++) {
            // evaluate conditional formats until one matches; if none matches, style is undefined
            if (matcher[i].matches(visualElement.id.elementId)) {
              conditionalStyles = conditionalFormats[i].styles;
              break;
            }
          }
          visualAttributeValue.conditionalStyles = conditionalStyles;

          // determine visible state by evaluating filters for this attribute; if one filter fails matching, it becomes invisible
          if (filterMatcher && filterMatcher.isValid(ViewType.ValueChart) && !filterMatcher.matches(visualElement.id.elementId)) {
            visibleElementIdStrings.delete(visualElement.id.toKey());
          }
        }
      });
    });

    // if show connected to selection, remove visuals which are not in secondary selection

    // update derived properties which are not dependent on visual attribute definition
    visualElements.forEach(visualElement => {
      // update element title
      if (visualElement.attributeValues.has(NAME_ATT_NAME)) {
        visualElement.title = visualElement.attributeValues.get(NAME_ATT_NAME).value;
      }
      // update visibility by filter AND showConnectedOnly
      const connected = !that.getDiagram().showConnectedOnly || this.secondarySelection.has(visualElement.id.elementId);
      visualElement.visible = visibleElementIdStrings.has(visualElement.id.toKey()) && connected;
      if (that.getDiagram().showConnectedOnly && connected && visibleElementIdStrings.has(visualElement.id.toKey())) {
        // show connected mode and visible by connection to secondary selection ==> set all parents to visible
        let parent = visualElement.parent;
        while (parent && parent instanceof VisualValueChartElement) {
          parent.visible = true;
          parent = parent.parent;
        }
      }
    });
  }

  /**
   * update the value chart after connections have changed, updates the whole hierarchy
   */
  updateAfterConnectionsChanged(): void {
    // TODO: more efficient
    this.updateRootVisualElements();
  }

  newVisualElement(tableId: TableId, elementId: ElementId, name: string): void {
    // TODO: more efficient
    this.updateRootVisualElements();
  }

  public removeVisualElements(...elementIds: ElementId[]): void {
    this.childrenRecursive.forEach(child => {
      if (child instanceof VisualValueChartElement && elementIds.indexOf(child.id.elementId) >= 0) {
        this.removeChildrenRecursively(child);
      }
    });
    this.layout();
  }

  /**
   * remove all given visual elements and their children recursively from the value chart
   * @param visualElements visual elements to remove, they might be from different levels too
   */
  public removeChildrenRecursively(...visualElements: VisualValueChartElement[]): void {
    visualElements.forEach(visualElement => {
      // remove it from level
      const index = visualElement.level.elements.indexOf(visualElement);
      if (index >= 0) {
        visualElement.level.elements.splice(index, 1);
      } else {
        // should not happen
        log.warn("Internal Error: Visual Element not found in Level");
      }
      // and from parent
      if (visualElement.parent) {
        visualElement.parent.removeChildById(visualElement.id);
      }
      this.removeChildrenRecursively(...visualElement.children);
    });
  }

  /**
   * add attribute definition to first matching visual table for this value chart
   * @param {VisualTableId} visualTableId visual table to which attribute belongs
   * @param {string} attName attribute name
   * @param {number} x position relative to START of value chart in svg coordinates
   * @param {number} width width of attribute compartment
   * @param {number} height height of attribute compartment
   * @param {boolean} levelRelativePosition
   * @param  {boolean} addAtLastPosition
   * @returns {VisualAttributeDefinition}
   */
  addAttributeDefinition(visualTableId: VisualTableId, attName: string, x: number, width: number, height: number, levelRelativePosition: boolean, addAtLastPosition: boolean): VisualAttributeDefinition {
    const result = this._visualValueChartMetadata.addAttributeDefinition(visualTableId, attName, x, width, height, levelRelativePosition, addAtLastPosition);
    const level = this._visualValueChartMetadata.getLevel(visualTableId);
    if (level) {
      this.updateElementProperties(level, ...level.elements);
      this.layout();
    } else {
      throw Error("Table '" + visualTableId.toKey() + "' for Attribute '" + attName + "' not found in Value Chart");
    }
    return result;
  }

  /**
   * dumps the element tree structure of the value chart for debug purposes
   */
  public debugDump(visualElements: VisualValueChartElement[], indent: number = 0, showHeader: boolean = true): string {
    let result = "";
    if (showHeader) {
      this._visualValueChartMetadata.debugDump();
    }
    visualElements.forEach(visualElement => {
      result += " ".repeat(indent) + visualElement.id.toKey() + "\n";
      result += this.debugDump(visualElement.children, indent + 2, false);
    });
    return result;
  }

  public getDiagram(): DiagramModel {
    return (this.parent as any) as DiagramModel;
  }

  public removeVisualAttributeDefinition(visualAttributeId: VisualAttributeId): VisualValueChartLevel {
    const level = this._visualValueChartMetadata.getLevel(visualAttributeId.visualTableId);
    if (level) {
      level.removeVisualAttributeDefinition(visualAttributeId);
    }
    return level;
  }

  levelsForTable(tableId: TableId): VisualValueChartLevel[] {
    return this._visualValueChartMetadata.levelsForTable(tableId);
  }

  getLevel(visualTableId: VisualTableId): VisualValueChartLevel {
    return this._visualValueChartMetadata.getLevel(visualTableId);
  }

  private createVvcLevelFomSerializedData(serializedLevel: VisualValueChartLevelData[]): void {
    let newLevel: VisualValueChartLevel;

    serializedLevel.forEach(levelData => {
      const visualTableId = new VisualTableId(levelData.id.tableId, levelData.id.visualId);
      const headerData: HeaderData = levelData.header;

      newLevel = this._visualValueChartMetadata.createAndAddLevel(visualTableId, headerData.name, levelData.columns, newLevel);
      this.addHeaderToLevel(newLevel, headerData);
      this.addVisualAttributDefinitions(levelData.attributes, visualTableId, newLevel);
      this.addChildren(newLevel);
      this.addVisualElements(newLevel.parentLevel);
    });
  }

  private addHeaderToLevel(level: VisualValueChartLevel, headerData: HeaderData) {
    Object.assign(level.header, new VisualHeader(headerData.name, headerData.x, headerData.y, headerData.width, headerData.height));
  }

  private addVisualAttributDefinitions(attributes: ChartAttributeData[], visualTableId: VisualTableId, level: VisualValueChartLevel) {
    const visualAttributeDefinitions = attributes.map(chartAttributeData => {
      const visualAttributeDefinition = this.getDiagram().initFromSerializedAttDef(visualTableId, chartAttributeData);
      level.visualAttributeDefinitions.set(visualAttributeDefinition.header.name, visualAttributeDefinition);
      return visualAttributeDefinition;
    });
    level.attributeColumnLayout.addColumns(visualAttributeDefinitions.map(vAD => vAD.header));
  }

  private addVisualElements(parentLevel: VisualValueChartLevel) {
    if (parentLevel) {
      this.updateVisualElementChildren(parentLevel.elements, true);
    } else {
      this.updateRootVisualElements();
    }

    this.layout();
  }

}
