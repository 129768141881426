import {debounce, DebounceSettings} from "lodash";

/**
 * return the argument
 * @param arg
 */
export function identity<T>(arg: T): T {
  return arg;
}

/** does nothing */
export function noop(...args: any[]): void {
}

/** wrapper to handle optional handler callbacks and pass a noop if none was defined */
export function optionalHandler(handler: any): any {
  return handler ? handler : noop;
}

/**
 * debounce the given function separately for the first argument of the function.
 * ATTENTION: for every unique first param a debounced function is memorized in the given map so this might grow infinitely
 * @param memoMap map where debounced functions will be stored keyed by the first argument of func
 * @param func function to debounce based on first argument, thus func(1) call will not debounce func(2) call
 * @param wait debounce wait time in ms
 * @param options debounce options, see lodash.debounce
 */
export function debounceByParam<T extends (...args: any) => any>(memoMap: Map<any,any>, func: T, wait: number, options: DebounceSettings) {
  return (...args) => {
    // use first argument as a key
    // its possible to use all args as a key - e.g JSON.stringify(args) or hash(args)
    const [searchType] = args;

    if (typeof memoMap[searchType] === 'function') {
      return memoMap[searchType](...args);
    }
    // leading required for return promise
    memoMap[searchType] = debounce<T>(func, wait, {...options, leading: true});
    return memoMap[searchType](...args);
  };
}

