import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import React from "react";
import TextFieldComponent from "../../../../common/components/materialuiderived/TextFieldComponent";
import {observer} from "mobx-react";
import autobind from "autobind-decorator";
import {BaseSectionProperties} from "../../../../properties/model/PropertiesModel";
import {updateMaxNumberOfColumnsPerPage} from "../../../actions/MatrixAsyncActionCreators";

const styles = (theme: Theme): StyleRules => createStyles({
  numberOfMaxColumnsTextField: {
    paddingBottom: "10px"
  },
});

export interface ColumnLimitProperties extends BaseSectionProperties {
  maxNumberOfColumnsPerPage: number;
}

type StyledLocalProps = ColumnLimitProperties & WithStyles<typeof styles>;

@observer
class ColumnLimitComponent extends React.Component<StyledLocalProps> {

  constructor(props) {
    super(props);
  }

  @autobind
  render(): JSX.Element {
    // key got set to maxNumberOfColumnsPerPage in order to trigger a rerender when switching between views
    // the defaultValue itself only gets set once and doesn't react to change directly
    return <React.Fragment>
      <TextFieldComponent defaultValue={this.props.maxNumberOfColumnsPerPage}
                          key={this.props.maxNumberOfColumnsPerPage}
                          onKeyPress={this.handleKeypress}
                          variant="outlined" type={"number"}
                          className={this.props.classes.numberOfMaxColumnsTextField}/>
    </React.Fragment>
  }

  @autobind
  handleKeypress(event: any): void {
    if (event.key === "Enter") {
      updateMaxNumberOfColumnsPerPage(this.props.activeViewId, parseInt(event.target.value));
    }
  }

}

export default withStyles(styles)(ColumnLimitComponent);