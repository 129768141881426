import "../../common/css/metusapp.css";
import * as React from "react";
import "react-loading-bar/dist/index.css";


import MainNavigationItemComponent from "../../modelselection/components/MainNavigationItemComponent";
import {loadInitialServerData} from "../actions/ModelAsyncActionCreators";
import {Classifier} from "../../common/utils/ClassifierLogger";
import Log from "../../common/utils/Logger";
import {observer} from "mobx-react";
import autobind from "autobind-decorator";

import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {ResetModelLocationAction} from "../actions/ModelSelectionActions";
import {ContextMenuTrigger} from "react-contextmenu";
import {showModelCreateDialog} from "./ModelCreateDialog";
import {ModelLocationType} from "../../common/constants/Enums";

const renderLog = Log.logger("MainNavigationComponent", Classifier.render);


const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    // paddingTop: theme.spacing(1) + "px",
    // paddingBottom: theme.spacing(1) + "px",
  },
});

interface LocalProps {
  location?: ModelLocationType;
  modelName?: string;
}

interface LocalState {
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

const metusCloudText = "Metus Cloud";
const localText = "Project";


@observer
class MainNavigationComponent extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  @autobind
  private cloudLocationSelected(): void {
    loadInitialServerData(ModelLocationType.server);
  }

  @autobind
  private projectLocationSelected(): void {
    loadInitialServerData(ModelLocationType.file);
  }

  @autobind
  private resetModelLocation(): void {
    Dispatcher.dispatch(new ResetModelLocationAction());
  }

  render(): JSX.Element {
    renderLog.debug("Rendering MainNavigationComponent", this.props);
    let result = undefined;

    if (this.props.location === undefined) {
      result = [
        <MainNavigationItemComponent key="cloud"
                                     navigationType="cloud"
                                     data-testselector="mainNavCloud"
                                     text={metusCloudText}
                                     callback={this.cloudLocationSelected}/>,
        <MainNavigationItemComponent key="local"
                                     navigationType="local"
                                     data-testselector="mainNavLocal"
                                     text={localText}
                                     callback={this.projectLocationSelected}/>];
    } else if (this.props.location !== ModelLocationType.memory) {
      const navigationType = this.props.location === ModelLocationType.server ? "cloud" : "local";
      const text = this.props.location === ModelLocationType.server ? metusCloudText : localText;

      result = <React.Fragment>
        <ContextMenuTrigger key={this.props.location} id="cm_mn_modellocation"
                            data-id={this.props.location}
                            holdToDisplay={-1}
                            attributes={{"data-testselector": "contextmenutrigger"} as any}>

          <MainNavigationItemComponent key="location"
                                       navigationType={navigationType}
                                       data-testselector="mainNavLocation"
                                       text={text}
                                       callback={this.resetModelLocation}/>
        </ContextMenuTrigger>
      </React.Fragment>
    }

    return <div className={this.props.classes.root}>{result}</div>;
  }

  @autobind
  openCreateModelDialog(): void {
    showModelCreateDialog();
  }

}

export default withStyles(styles)(MainNavigationComponent);
