import {DownloadActionsProperties} from "../components/properties/DownloadActionsPropertiesComponent";
import {modelStore} from "../../core/stores/ModelStore";
import {TestDataCreationProperties} from "../components/properties/TestDataCreationComponent";
import {ShareActionsProperties} from "../components/properties/ShareActionsPropertiesComponent";
import Log from "../../common/utils/Logger";
import {viewManagerRegistry} from "../models/ViewManager";
import {IMetusPropertiesProviderContext, MetusPropertiesProvider} from "../contexts/IMetusPropertiesProviderContext";
import {configurationStore} from "../../core/stores/ConfigurationStore";
import {PersistencyState} from "../../common/constants/Enums";

const log = Log.logger("commonviews.propertiesprovider");

export const testDataCreationPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): TestDataCreationProperties => {
  const tables = Array.from(modelStore.tableNameByTableId.entries());
  const viewInfo = viewManagerRegistry.viewManager.getViewInfoById(context.activeViewId);
  if (viewInfo != null) {
    return configurationStore.configuration.haveTestData ? {
      activeViewId: context.activeViewId,
      tables
    }: null
  }
};
export const shareActionsPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): ShareActionsProperties => {
  log.debug("Providing shareActions properties for selection", context.activeViewId);

  let result: ShareActionsProperties = null;
  const viewInfo = viewManagerRegistry.viewManager.getViewInfoById(context.activeViewId);

  if (viewInfo != null) {
    result = {
      activeViewId: context.activeViewId,
      modelLocation: modelStore.modelInfo.location,
      cockpitId: viewManagerRegistry.viewManager.currentCockpitId,
      canActivateViewLink: viewInfo.persistencyState !== PersistencyState.New && configurationStore.canLink(),
      canActivateCockpitLink: viewManagerRegistry.viewManager.currentCockpitId !== undefined && configurationStore.canLink(),
    };
  }

  return result;
};
export const downloadActionsPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): DownloadActionsProperties => {
  log.debug("Providing shareActions properties for selection", context.activeViewId);

  let result: DownloadActionsProperties = null;
  const viewInfo = viewManagerRegistry.viewManager.getViewInfoById(context.activeViewId);

  if (viewInfo != null) {
    result = {
      activeViewId: context.activeViewId,
      activeViewType: viewInfo.type,
      activeViewState: viewInfo.persistencyState,
      activeViewVersion: viewInfo.viewVersion,
      modelLocation: modelStore.modelInfo.location,
    };
  }

  return result;
};
