import * as React from "react";
import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core";
import {AttributeId, ElementId, generateId, ViewId} from "../../core/utils/Core";
import autobind from "autobind-decorator";
import {StyleRules} from "@material-ui/core/styles";
import ListSelectionDialog, {ObjectIdAndTitle} from "../../common/components/ListSelectionDialog";
import {modelStore} from "../../core/stores/ModelStore";
import {observer} from "mobx-react";
import {getAttributeValuesForTable, getConnectionsForTables} from "../../core/actions/CoreAsyncActionCreators";
import {updateElements} from "../actions/SharedViewAsyncActions";
import {hideDialog} from "../../common/utils/CommonDialogUtil";
import {generateUUID} from "../../common/utils/IdGenerator";
import {ReferenceAttributeDefinition} from "../../api/api";

const styles = (theme: Theme): StyleRules =>
    createStyles({});

interface LocalProps {
  open: boolean;
  /**
   * if true, the component will not be shown as dialog on a separate layer, but just as div with an apply button
   */
  usePopupStyle: boolean;

  /** element to connect to if list entry checked */
  rowElementId: ElementId;
  /** elements displayed in list */
  columnElementId?: ElementId;
  /** attributeId of the JoinTable attribute or the attribute referenced by the reference attribute*/
  referencedAttributeId: AttributeId;
  /**
   * called if dialog is closed
   */
  onClose?: () => void;
  /** attributeId of the reference attribute that is being edited */
  referenceAttributeId?: AttributeId
  /** viewId of the ciew in which the dialog got triggered*/
  viewId?: ViewId
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

@observer
class EditElementConnectionsDialog extends React.Component<StyledLocalProps, any> {
  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    const tableId = this.props.referencedAttributeId.tableId;
    const elementIdsToShow = this.props.columnElementId ? modelStore.getConnectedElementIdsToTable(this.props.columnElementId, tableId) : modelStore.getElementsForTable(tableId).map(el => el.id);
    const attributeName = this.props.referencedAttributeId.attributeName;
    const idsAndValuesToShow: ObjectIdAndTitle[] = modelStore.getElementsById(elementIdsToShow).map(elementObject => ({
      id: elementObject.id,
      title: elementObject[attributeName]
    }));
    const idsConnectedToRow = elementIdsToShow.filter(joinElementId => modelStore.isConnected(this.props.rowElementId, joinElementId));
    return <ListSelectionDialog
        open={this.props.open}
        usePopupStyle={this.props.usePopupStyle}
        title={modelStore.getTableName(tableId)}
        listObjects={idsAndValuesToShow}
        selectedObjectIds={idsConnectedToRow}
        onSubmit={this.onSubmit}
        onDiscard={this.onDiscard}
        generateIdCallback={generateId}
    />;
  }

  @autobind
  private onSubmit(toCreate: ObjectIdAndTitle[], toDelete: string[], toToggle: ElementId[]) {
    updateElements(toCreate, toDelete, toToggle, this.props.rowElementId, this.props.referencedAttributeId, this.props.columnElementId).then(() => {
      if (this.props.referenceAttributeId && this.props.viewId) {
        getAttributeValuesForTable(this.props.viewId, this.props.referenceAttributeId.tableId, [this.props.referenceAttributeId.attributeName], generateId(), true, true);
      }
      this.props.onClose && this.props.onClose();
      hideDialog();
    });
  }

  @autobind
  private onDiscard(): void {
    this.props.onClose && this.props.onClose();
  }

}

const StyledEditElementConnectionsDialog = withStyles(styles)(EditElementConnectionsDialog);
export default StyledEditElementConnectionsDialog;

export async function getEditElementConnectionsDialog(
    rowElementId: ElementId,
    referenceAttributeId: AttributeId,
    referencedAttributeId: AttributeId,
    viewId: ViewId,
    usePopupStyle: boolean,
    columnElementId?: ElementId,
    onClose?: () => void): Promise<JSX.Element> {

  const attributeDefinition = modelStore.getAttributeDefinition(referenceAttributeId.tableId, referenceAttributeId.attributeName);
  const referencedTableId = (attributeDefinition as ReferenceAttributeDefinition)?.referencedTableId;
  if (referencedTableId !== undefined && !modelStore.tablesWithConnectionsLoaded.includes(referencedTableId)) {
    await getConnectionsForTables(null, [referencedTableId], generateUUID());
  }

  return <StyledEditElementConnectionsDialog
      open={true}
      usePopupStyle={usePopupStyle}
      rowElementId={rowElementId}
      referenceAttributeId={referenceAttributeId}
      viewId={viewId}
      referencedAttributeId={referencedAttributeId}
      columnElementId={columnElementId}
      onClose={onClose}/>;
}
