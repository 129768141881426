import Log from "./Logger";
import {observable} from "mobx";

const logger = Log.logger("TwoLevelMapToSet");

/**
 * implements a nested observable map which is indexed by two keys of the same type and maintains a set of values of the given value type.
 * Used to manage connection lookup by element, table, element
 */
export class TwoLevelMapToSet<K1, K2, V> {
  @observable private innerMap: Map<K1, Map<K2, Set<V>>> = new Map<K1, Map<K2, Set<V>>>();

  public get(key1: K1, key2: K2): Set<V> {
    // return early
    if (!this.innerMap.has(key1)) {
      return undefined;
    }
    return this.innerMap.get(key1).get(key2);
  }

  /**
   * @return all elements of second level, regardless of 2nd level key
   * @param key1 key to return all elements for
   */
  public getAll(key1: K1): Set<V> {
    const allValues = new Set<V>();
    if (this.innerMap.has(key1)) {
      const secondLevelMap: Map<K2, Set<V>> = this.innerMap.get(key1);
      const allEntries = Array.from(secondLevelMap.values());
      allEntries.forEach(set => set.forEach(v => allValues.add(v)));
    }
    return allValues;
  }

  public has(key1: K1, key2: K2): boolean {
    return this.innerMap.has(key1) && this.innerMap.get(key1).has(key2);
  }

  public set(key1: K1, key2: K2, value: Set<V>): void {
    if (!this.innerMap.has(key1)) {
      return;
    }

    const map = this.innerMap.get(key1);
    const values = map.get(key2);
    if (!values) {
      return;
    } else {
      map.set(key2, value);
    }
  }

  public add(key1: K1, key2: K2, value: V): void {
    if (!this.innerMap.has(key1)) {
      const tableIdToElementIds = observable(new Map<K2, Set<V>>());
      this.innerMap.set(key1, tableIdToElementIds);
    }

    const map = this.innerMap.get(key1);
    const values = map.get(key2);
    if (!values) {
      map.set(key2, observable(new Set([value])));
    } else {
      values.add(value);
    }
  }

  public delete(key1: K1, key2: K2, value: V): void {
    if (!this.innerMap.has(key1)) {
      return;
    }

    const map = this.innerMap.get(key1);
    const valueSet = map.get(key2);
    if (!valueSet) {
      return;
    } else {
      logger.debug("value getting deleted from TwoLevelMapToSet", value);
      valueSet.delete(value);
    }
  }

  public clear(): void {
    this.innerMap.forEach(map => map.clear());
    this.innerMap.clear();
  }
}
