// lib imports
import * as React from "react";
import Log from "../../../common/utils/Logger";
import {ChartColumnComponent} from "./ChartColumnComponent";
import {ViewerContext} from "../../utils/ViewerContext";
import {VisualAttributeId, VisualAttributeIdString, VisualTableId} from "../../../core/utils/Core";
import {VisualChartColumn} from "../../models/chart/VisualChartColumn";
import {observer} from "mobx-react";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {Rect} from "../../../common/utils/Geometry";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

interface LocalProps {
  viewerContext: ViewerContext;
  visibleSVGRect: Rect;
  tables: Map<string, VisualChartColumn>;
  viewerFilters: Map<VisualAttributeIdString, string>;
  filterTextsValidities: Map<VisualAttributeIdString, boolean>;
  animationCount?: number;
}

// App pure component
@observer
export class ChartColumnsComponent extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);

    this.getViewerFiltersPerTable = this.getViewerFiltersPerTable.bind(this);
    this.getfilterTextsValiditiesPerTable = this.getfilterTextsValiditiesPerTable.bind(this);
  }

  render(): JSX.Element {
    renderLog.debug(`Rendering ChartColumnsComponent with ${this.props.tables.size} columns`);
    return <g>
      {Array.from(this.props.tables.values()).map((table: VisualChartColumn) => <ChartColumnComponent
          viewerContext={this.props.viewerContext}
          viewerFilters={this.getViewerFiltersPerTable(table.id)}
          visibleSVGRect={this.props.visibleSVGRect}
          animationCount={this.props.animationCount}
          filterTextsValidities={this.getfilterTextsValiditiesPerTable(table.id)}
          key={table.id.toKey()}
          table={table}/>)}
    </g>;
  }

  private getViewerFiltersPerTable(id: VisualTableId): Map<VisualAttributeIdString, string> {
    // const id: VisualTableId = null;
    let viewerFilterPerTable: Map<VisualAttributeIdString, string> = new Map<VisualAttributeIdString, string>();
    this.props.viewerFilters.forEach((filterText: string, key: VisualAttributeIdString): void => {
      if (VisualAttributeId.fromKey(key).visualTableId.toKey() === id.toKey())
        viewerFilterPerTable = viewerFilterPerTable.set(key, filterText);
    });

    return viewerFilterPerTable;
  }

  private getfilterTextsValiditiesPerTable(id: VisualTableId): Map<VisualAttributeIdString, boolean> {
    // const id: VisualTableId = null;
    let filterTextsValiditiesPerTable: Map<VisualAttributeIdString, boolean> = new Map<VisualAttributeIdString, boolean>();
    this.props.filterTextsValidities.forEach((isValid: boolean, key: VisualAttributeIdString): void => {
      if (VisualAttributeId.fromKey(key).visualTableId.toKey() === id.toKey())
        filterTextsValiditiesPerTable = filterTextsValiditiesPerTable.set(key, isValid);
    });

    return filterTextsValiditiesPerTable;
  }
}