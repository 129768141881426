import React, {CSSProperties} from "react";

interface LocalProps {
  width: number;
  height: number;
  onClick?: any;
  x?: number;
  y?: number;
  style?: CSSProperties;
}

export class ClickableAreaFiller extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    return <rect className="clickableAreaFiller"
                 width={this.props.width}
                 height={this.props.height}
                 onClick={this.props.onClick}
                 x={this.props.x}
                 y={this.props.y}
                 style={this.props.style}>
    </rect>
  }
}