import "../../common/css/metusapp.css";
import * as React from "react";
import "react-loading-bar/dist/index.css";
import Log from "../../common/utils/Logger";


import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import autobind from "autobind-decorator";
import {observer} from "mobx-react";
import {createStyles, ListItem, ListItemText, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import {showConfirmModelDeleteDialog} from "./ConfirmModelDeleteDialog";
import {modelStore} from "../../core/stores/ModelStore";
import {configurationStore} from "../../core/stores/ConfigurationStore";
import {ModelLocationType} from "../../common/constants/Enums";


const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    "&:hover": {
      backgroundColor: theme.metus.navigation.fill,
    },
    height: "fit-content"
  },
});

const log = Log.logger("ProjectSelectionComponent");

interface LocalProps {
  modelLocation: ModelLocationType;
  modelNames: string[];
  onModelSelected: (modelName: string) => void;
  onResetModelCache: (modelName: string) => void;
}

interface LocalState {
}


type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

function collect(props: any): any {
  return {modelName: props["data-id"]};
}

@observer
class ProjectSelectionComponent extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    log.debug("Rendering ProjectSelectionComponent");
    const modelNames = this.props.modelNames ? this.props.modelNames : [];
    const sortedModelNames = Array.from(modelNames).sort();
    return <div>
      {sortedModelNames.map(modelName => this.createListItem(modelName))}

      {this.canDeleteProject() &&
      <ContextMenu id={"cm_ms_modelentry_mo"}>
        <MenuItem onClick={this.onReset}>Reset Model Cache</MenuItem>
        <MenuItem onClick={this.onDelete}>Delete Model</MenuItem>
      </ContextMenu>}
      {this.resetModelCacheAllowed() &&
      <ContextMenu id={"cm_ms_modelentry_mc"}>
        <MenuItem onClick={this.onReset}>Reset Model Cache</MenuItem>
      </ContextMenu>}
    </div>
  }


  private createListItem(modelName: string): JSX.Element {
    let triggerId: string;
    if (this.resetModelCacheAllowed()) {
      triggerId = "cm_ms_modelentry_mc";
    } else if (this.canDeleteProject() && modelName === modelStore.modelInfo?.name) {
      triggerId = "cm_ms_modelentry_mo";
    } else {
      triggerId = "dummy";
    }

    return <ContextMenuTrigger key={modelName} id={triggerId}
                               data-id={modelName}
                               collect={collect}
                               holdToDisplay={-1}
                               attributes={{"data-testselector": "contextmenutrigger"} as any}>
      <ListItem key={modelName}
                value={modelName}
                onClick={(): void => {
                  this.props.onModelSelected(modelName);
                }}
                classes={{root: this.props.classes.root}}>
        <ListItemText primary={modelName} disableTypography={true}/>
      </ListItem>
    </ContextMenuTrigger>
  }


  private canDeleteProject(): boolean {
    return configurationStore.canDeleteProject();
  }

  private resetModelCacheAllowed(): boolean {
    return this.props.modelLocation !== ModelLocationType.liveserver;
  }

  @autobind
  private onReset(event: any, data: { modelName: string }): void {
    this.props.onResetModelCache(data.modelName);
  }

  @autobind
  private onDelete(event: any, data: { modelName: string }): void {
    showConfirmModelDeleteDialog(data.modelName);
  }
}

export default withStyles(styles)(ProjectSelectionComponent);
