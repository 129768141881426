/* Root.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, August 2017
 */
import * as React from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";

const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
  children: any;
}

export default class Root extends React.Component<LocalProps, any> {

  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering Root");
    return this.props.children;
  }
}
