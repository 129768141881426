/* ViewManagerAsyncActionCreators.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, August 2017
 *
 * All Actions needed for generic view management, i.e. creating new web views, reading web view hierarchy, creating folders, saving views etc..
 */
import {TableId, ViewId} from "../../core/utils/Core";
import {CockpitIconType, PersistencyState, TreeItemType, ViewType} from "../../common/constants/Enums";
import {generateUUID} from "../../common/utils/IdGenerator";
import {
  NewCockpitAction,
  NewViewAction,
  OpenCockpitAction,
  OpenViewAction,
  RenameViewAction
} from "./ViewManagerActions";
import {loadDiagramAndData} from "../../diagram/actions/DiagramAsyncActionCreators";
import {diagramStore} from "../../diagram/stores/DiagramStore";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {
  CockpitData,
  CockpitInfo,
  DiagramData,
  OpenView,
  UUID
} from "../../api/api";
import {
  FOLDER_VERSION,
  COCKPIT_VERSION,
  getVersionOfViewType
} from "../../common/constants/ViewVersions";
import {MosaicPath} from "react-mosaic-component";
import {
  AddNavigationItemAction,
  MoveNavigationItemToFolderAction,
  MoveNavigationItemToNewPositionAction,
  RenameNavigationItemAction
} from "../../core/actions/NavigationActions";
import {metusStore} from "../stores/MetusStore";
import {NewFolderAction} from "../../core/actions/CoreActions";
import {matrixStore} from "../../matrix/stores/MatrixStore";
import {normalizeName} from "../../core/utils/NameNormalizer";
import {serialize} from "serializr";
import {DiagramModelSerializer} from "../../diagram/models/DiagramModelSerializer";
import {SaveViewAction} from "../../commonviews/actions/SharedViewAsyncActions";
import {
  deleteMovablesPredecessor,
  getWebViewHierarchy,
  loadView,
  saveFolder,
  saveViewWithBody,
  updateMovablesPredecessor,
  updateParentFolder
} from "../../commonviews/services/ViewServices";
import {loadTableFolderTree, saveTable} from "../../core/services/CoreDataServices";
import {ViewInfo} from "../../commonviews/models/ViewInfo";
import {loadMatrix, loadTable} from "../../matrix/actions/MatrixAsyncActionCreators";
import Log from "../../common/utils/Logger";
import {configurationStore} from "../../core/stores/ConfigurationStore";
import {hasViewChanged} from "../../commonviews/models/DeserializedModel";
import {viewManagerRegistry} from "../../commonviews/models/ViewManager";
import {MoveTreeItemToNewPositionPayload, TreeItemPayload} from "../../core/actions/NavigationPayloads";

const log = Log.logger("ViewManagerAsyncActionCreators");
const ROOT = "root";

/** ATTENTION: THIS MUST FIT THE RestServer response parameter naming */
export interface ViewBody {
  uuid: string;
  name: string;
  content?: string;
  type?: string;
  props?: any;
  parentId?: string;
  /* ViewType */
}


export async function createNewView(name: string, type: ViewType, parentId?: string): Promise<string> {
  /* Create and dispatch ViewInfo */
  const viewId: string = generateUUID();
  const viewInfo = new ViewInfo(type, viewId, name, PersistencyState.New, getVersionOfViewType(type));
  const groupId = generateUUID();
  log.debug("New view:", viewInfo);
  Dispatcher.dispatch(new NewViewAction(viewInfo, groupId));

  const result = await saveView(type, viewId, name, viewInfo.viewVersion, true, undefined, groupId, parentId);

  /* Create and dispatch new left list item. */
  const payload: TreeItemPayload = {
    type: TreeItemType.View,
    viewType: type,
    name,
    viewVersion: viewInfo.viewVersion,
    parentId: parentId ? parentId : metusStore.cockpitAndViewHierarchy.id
  };
  result && Dispatcher.dispatch(new AddNavigationItemAction(viewId, payload, groupId));

  return viewId;
}

export function renameView(viewId: ViewId, name: string): Promise<any> {
  // TODO write action creator test verifying new vs update handling

  const viewInfo = metusStore.getViewInfoById(viewId);
  const body: ViewBody = {name, uuid: viewId};

  // viewInfo sollte nur in Unit-Tests undefined sein
  const isNew = (typeof viewInfo !== "undefined") ? viewInfo.persistencyState === PersistencyState.New : true;

  if (isNew && typeof viewInfo !== "undefined") {
    body.type = ViewType[viewInfo.type];
  }

  return saveViewWithBody(body, isNew).then((writeResult) => {
    if (writeResult) {
      Dispatcher.dispatch(new RenameViewAction(writeResult.commandId, name, viewId));
    }
  });
}

// TODO: reuse createNewView
export function createNewCockpit(cockpitInfo: CockpitInfo): void {
  /* Create and dispatch ViewInfo */
  const id: ViewId = generateUUID();
  const groupId = generateUUID();
  Dispatcher.dispatch(new NewCockpitAction({id, ...cockpitInfo}, groupId));
  const viewProps = {
    svgIconColor: cockpitInfo.iconColor,
    svgIconType: CockpitIconType[cockpitInfo.iconType]
  };

  saveView(ViewType.Cockpit, id, cockpitInfo.name, COCKPIT_VERSION, true /*isNew*/, viewProps)
      .then(() => {
        /* Create and dispatch new left list item. */
        const payload: TreeItemPayload = {
          type: TreeItemType.View,
          viewType: ViewType.Cockpit,
          name: cockpitInfo.name,
          viewVersion: COCKPIT_VERSION,
          svgIconColor: cockpitInfo.iconColor,
          svgIconType: cockpitInfo.iconType,
          parentId: metusStore.cockpitAndViewHierarchy.id,
        };
        Dispatcher.dispatch(new AddNavigationItemAction(id, payload, groupId));
      });
}

export function createNewFolder(name: string, parentId: string): void {
  const folderId: ViewId = generateUUID();

  saveFolder(name, folderId, parentId, true /*isnew*/)
      .then(writeResult => {
        if (writeResult) {
          const groupId = generateUUID();
          Dispatcher.dispatch(new NewFolderAction(writeResult.commandId, name, folderId, groupId));

          const payload: TreeItemPayload = {
            type: TreeItemType.Folder,
            viewVersion: FOLDER_VERSION,
            name,
            parentId
          };
          Dispatcher.dispatch(new AddNavigationItemAction(folderId, payload, groupId));
        }
      });
}

export function moveTreeItemToFolder(sourceId: string, targetId: string): void {
  updateParentFolder(sourceId, targetId)
      .then(writeResult => {
        if (writeResult) {
          Dispatcher.dispatch(new MoveNavigationItemToFolderAction(writeResult.commandId, sourceId, targetId));
        }
      });
}

export async function moveTreeItemToNewPosition(payload: MoveTreeItemToNewPositionPayload): Promise<void> {
  /* PoS <-- S <-- NoS */
  if (payload.nextOfSourceId !== undefined) {
    if (payload.previousOfSourceId !== undefined) {
      /* Close gap (PoS <-- S <-- NoS) */
      await updateMovablesPredecessor(payload.nextOfSourceId, payload.previousOfSourceId)
    } else {
      // If we are moving from head -> new head has no predecessor.
      await deleteMovablesPredecessor(payload.nextOfSourceId)
    }
  }

  let placeSourceAfterTarget: boolean;
  if (payload.sourceParentId === payload.targetParentId) {
    placeSourceAfterTarget = payload.headToTail;
  } else {
    await updateParentFolder(payload.sourceId, payload.targetParentId);
    placeSourceAfterTarget = false;
  }

  if (placeSourceAfterTarget) {
    /* T <-- S <-- NoT */
    await updateMovablesPredecessor(payload.sourceId, payload.targetId)

    if (payload.nextOfTargetId !== undefined) {
      // Always necessary except in the case that target is tail of list.
      await updateMovablesPredecessor(payload.nextOfTargetId, payload.sourceId)
    }
  } else {
    /* PoT <-- S <-- T */
    await updateMovablesPredecessor(payload.targetId, payload.sourceId)

    if (payload.previousOfTargetId !== undefined) {
      await updateMovablesPredecessor(payload.sourceId, payload.previousOfTargetId)
    } else {
      // Delete is only necessary if we move to head of list
      await deleteMovablesPredecessor(payload.sourceId);
    }
  }

  Dispatcher.dispatch(new MoveNavigationItemToNewPositionAction("dummyCommandId", payload.sourceId, payload));
}


export function renameTreeItem(listItemType: TreeItemType, id: string, name: string): Promise<any> {
  switch (listItemType) {
    case TreeItemType.View:
      // dispatch einer RenameNavigationItemAction ist nicht nötig, da der Name, der in der Navigation dargestellt wird,
      // aus der ViewInfo gelesen wird.
      return renameView(id, name);
    case TreeItemType.Folder:
      return saveFolder(name, id).then(result => result && Dispatcher.dispatch(new RenameNavigationItemAction(id, name, result.commandId)));
    case TreeItemType.Table:
      name = normalizeName(name);
      return saveTable(name, id).then(result => result && loadTableFolderTree(generateUUID()));
    default:
      log.error("Unhandled rename Tree type", listItemType);
  }
}

export async function saveView(viewType: ViewType, viewId: ViewId, name: string, viewVersion: number, isNew: boolean = true, props: any = undefined, groupId: UUID = undefined, parentId?: string): Promise<any> {
  // it's crucial to not store a view with a higher version number. relevant data will get lost!
  if (viewVersion > getVersionOfViewType(viewType)) {
    return `View '${name}' with id ${viewId} of type ${ViewType[viewType]} is not saved, because it has version ${viewVersion}, but current version is ${getVersionOfViewType(viewType)};`
  }

  if (viewType === ViewType.Table) {
    return Promise.resolve({commandId: "FakeCommandId"});
  }
  log.debug("Saving " + ViewType[viewType] + ": " + viewId);

  // TODO: Move into then of saveView Promise, see createNewView
  /* Get data from stores and serializeView them appropriately. */
  const [content, hasChanged] = serializeView(viewType, viewId);

  if (viewType === ViewType.Cockpit) {
    metusStore.getCurrentCockpitData().openViews.map(openView => openView.id ?
        saveView(openView.type,
            openView.id,
            metusStore.getViewInfoById(openView.id).name,
            metusStore.getViewInfoById(openView.id).viewVersion,
            false)
        : undefined)
  }

  if (isNew === true || hasChanged === true) {
    /* Call REST service */
    const viewBody: ViewBody = {
      name,
      uuid: viewId,
      content,
      type: ViewType[viewType],
      props: {...props, viewVersion: getVersionOfViewType(viewType)}, // viewVersion is updated to current version, because there may be new data
      parentId
    };
    const writeResult = await saveViewWithBody(viewBody, isNew);

    const payload = configurationStore.canWriteToServer() ? PersistencyState.Saved : PersistencyState.LocallySaved;
    Dispatcher.dispatch(new SaveViewAction(writeResult.commandId, payload, viewId, groupId));

    return writeResult;
  } else {
    return `No changes in view '${name}' with id ${viewId} of type ${ViewType[viewType]};`;
  }

}

export function loadWebViewHierarchy(groupId: UUID): Promise<any> {
  return getWebViewHierarchy()
      .then((result) => {
        log.debug("Loaded Webview Hierarchy", result);
        if (result !== undefined) {
          Dispatcher.dispatch({
            type: "webviewHierarchy",
            resourceId: "webviewHierarchy",
            groupId: groupId,
            payload: result,
            undoable: true
          });
        }
        return result;
      });
}

export function openCockpit(cockpitId: ViewId, iconColor: string, iconType: CockpitIconType): Promise<any> {
  const groupId: UUID = generateUUID();

  return loadView<CockpitData>(cockpitId, groupId)
      .then((cockpit: CockpitData) => {
        cockpit.cockpitInfo = {id: cockpit.id, iconColor, iconType};
        loadViewsForCockpit(cockpit, groupId);
      });
}

export function loadViewsForCockpit(cockpit: CockpitData, groupId: UUID): Promise<any> {
  let result: Promise<any> = Promise.resolve(null);

  // MO-1408 Blau Hellblau Logik kaputt: die Views nacheinander laden, damit auch alle Verbindungen geladen werden
  cockpit.openViews.forEach((openView: OpenView) => {
    if (metusStore.getViewInfoById(openView.id)) {
      // TODO version checking
      result = result.then(result => loadViewByViewType(openView.id, openView.type, groupId));
    }
  });

  return result.then(arg => Dispatcher.dispatch(new OpenCockpitAction(cockpit)));
}

/**
 * opens a view in the given editor window.
 * Precondition: viewInfos in Modelstore must have been initialized by action webviewlist or chartlist
 * @param viewId view id
 * @param viewType type of view to open
 * @param windowPath defined by mosaic framework. Only set while dragging & droping a view on an editor, not when clicking.
 * @param groupId when opening a cockpit groupId is already set.
 * @returns {Promise<void>}
 */
export function openViewAndLoadIfNecessary(viewId: ViewId, viewType: ViewType, windowPath?: MosaicPath, groupId?: UUID): Promise<any> {
  if ((viewId === undefined || viewId === null) || viewType === undefined) {
    return Promise.resolve();
  }

  groupId = groupId ? groupId : generateUUID();

  Dispatcher.dispatch(new OpenViewAction(viewId, windowPath, groupId));

  // TODO version checking?
  return loadViewByViewType(viewId, viewType, groupId);
}

function loadViewByViewType(viewId: ViewId, viewType: ViewType, groupId: UUID): Promise<any> {
  let result: Promise<any> = Promise.resolve();

  if ((viewId === undefined || viewId === null) || viewType === undefined) {
    return result;
  }

  switch (viewType) {
    case ViewType.Chart:
      // fall through
    case ViewType.ValueChart:
      // Only reload the diagram if it doesn't exist in the DiagramStore
      if (!diagramStore.getDiagramForId(viewId)) {
        log.debug("Open view triggers loading web chart", viewId);
        result = loadDiagramAndData(viewId, groupId);
      }
      break;
    case ViewType.Table:
      log.debug("Open view triggers loading classic table", viewId);
      // Only reload the Table if it doesn't exist in the MatrixStore
      if (!matrixStore.getMatrixById(viewId)) {
        result = loadTable(viewId as TableId, groupId);
      }
      break;
    case ViewType.ChainMatrix:
    case ViewType.Matrix:
    case ViewType.StructuredTable:
      // Only reload the Matrix if it doesn't exist in the MatrixStore
      if (!matrixStore.getMatrixById(viewId)) {
        log.debug("Open view triggers loading matrix", viewId);
        result = loadMatrix(viewId, groupId);
      }
      break;
    default:
      throw new Error("Opening View Type " + ViewType[viewType] + " is not supported");
  }

  return result;
}

/**
 * converts the view into a flat (json) format suitable for storing/transferring
 * @param viewType
 * @param viewId
 */
function serializeView(viewType: ViewType, viewId: ViewId): [string, boolean] {
  let serializedView: string;
  let hasChanged: boolean = true;
  // TODO version: make sure version is serialized
  switch (viewType) {
    case ViewType.Chart:
    case ViewType.ValueChart: {
      const diagramModel = diagramStore.getDiagramForId(viewId);
      serializedView = JSON.stringify(DiagramModelSerializer.toJSON(diagramModel));
      hasChanged = hasViewChanged<DiagramData>(diagramModel);
      break;
    }
    case ViewType.StructuredTable:
    case ViewType.ChainMatrix:
    case ViewType.Matrix: {
      const matrixModel = matrixStore.getMatrixById(viewId);
      serializedView = JSON.stringify(serialize(matrixModel));
      hasChanged = hasViewChanged<Object>(matrixModel);
      break;
    }
    case ViewType.Cockpit:
      serializedView = JSON.stringify(metusStore.getCurrentCockpitData());
      break;

    default:
      throw new Error("SaveView: can not create content");
  }

  return [serializedView, hasChanged];
}

export async function saveAllOpenViews(): Promise<void> {
  log.info("saving all open views");
  const viewInfos: ViewInfo[] = viewManagerRegistry.viewManager.getOpenViewInfos();
  viewInfos.forEach(viewInfo => {
    log.info("Saving View " + viewInfo.name);
    saveView(viewInfo.type, viewInfo.id, viewInfo.name, viewInfo.viewVersion, false);
  });
}
