import {CSSProperties} from "react";
import {METUS_DEFAULT_STYLES} from "../utils/ElementStyleUtil";
import {StyleUtil} from "../../core/utils/StyleUtil";
import {computed, observable} from "mobx";
import {DefaultStyles} from "../../api/api";
import {IBoundedVisual} from "../../diagram/models/VisualObject";
import {Rect} from "../../common/utils/Geometry";
import {serializable} from "serializr";

export class VisualHeader implements IBoundedVisual {
  @serializable @observable public name: string;
  @serializable @observable public x: number;
  @serializable @observable public y: number;
  @serializable @observable public width: number;
  @serializable @observable public height: number;
  @serializable @observable private readonly defaultStyleKey: keyof DefaultStyles;

  @observable public styles: any;

  constructor(name: string, x: number = 0, y: number = 0, width: number = 0, height: number = 0, defaultStyleKey: keyof DefaultStyles = "tableHeader") {
    this.name = name;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.defaultStyleKey = defaultStyleKey;
  }

  @computed.struct get textStyles(): CSSProperties {
    return StyleUtil.updateStyles({...this.styles}, METUS_DEFAULT_STYLES[this.defaultStyleKey], StyleUtil.toTextStyle);
  }

  @computed.struct get rectStyles(): CSSProperties {
    return StyleUtil.updateStyles({...this.styles}, METUS_DEFAULT_STYLES[this.defaultStyleKey], StyleUtil.toRectStyle);
  }

  @computed.struct get bounds(): Rect {
    return {x: this.x, y: this.y, width: this.width, height: this.height};
  }

}
