import * as React from "react";
import {AgGridReact} from "ag-grid-react";
import Log from "../../common/utils/Logger";
import {
  CellEditingStartedEvent,
  CellValueChangedEvent,
  ColDef,
  Column,
  ColumnApi,
  ColumnResizedEvent,
  DisplayedColumnsChangedEvent,
  ExcelExportParams,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  MenuItemDef,
  OriginalColumnGroup,
  RowDataTransaction,
  RowEvent,
  RowNode,
  RowSelectedEvent,
  SortChangedEvent
} from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-enterprise/chartsModule";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../css/matrix.css";
import {ElementId, ElementObject, TableId, ViewId, VisualAttributeId, VisualTableId} from "../../core/utils/Core";
import {
  createChildElement,
  deleteElementsAndDispatch,
  updateAttributeValuesForElementAndTable
} from "../../core/actions/CoreAsyncActionCreators";
import {Classifier} from "../../common/utils/ClassifierLogger";
import autobind from "autobind-decorator";
import {modelStore} from "../../core/stores/ModelStore";
import {ZoomableDivComponent} from "./ZoomableDivComponent";
import {MatrixRow, RowDataUpdater} from "../models/RowDataUpdater";
import {MatrixRowElements} from "../models/MatrixRowElements";
import theme, {metusElementSelectionColors} from "../../common/theme/Theme";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {
  AllCellEditor,
  AllCellRenderer,
  elementPathKeyToLeafElementId,
  elementPathToKey,
  Row
} from "../models/MatrixCoreDefinitions";
import {hasValueChanged, isMatrixColumn, isNameColumn, MatrixModel} from "../models/MatrixModel";
import {RemoveAttributeFromViewAction, ZoomViewAction} from "../../commonviews/actions/SharedViewActions";
import {observer} from "mobx-react";
import {MatrixContext} from "./MatrixContext";
import {AttributeDefinition} from "../../api/api";
import {ColumnPaginatorComponent} from "./ColumnPaginatorComponent";
import {MatrixVisualConstants} from "../models/common/MatrixVisualConstants";
import {getLevelDefaultColor, getLevelDefaultTextColor} from "../../core/utils/LevelColorUtil";
import MatrixTreeColumnInnerCellRendererComponent from "./cellrenderer/MatrixTreeColumnInnerCellRendererComponent";
import AttributeCellRendererComponent from "./cellrenderer/AttributeCellRendererComponent";
import ChainMatrixCellRendererComponent from "./cellrenderer/ChainMatrixCellRendererComponent";
import PercentCellRendererComponent from "./cellrenderer/PercentCellRendererComponent";
import MatrixBubbleCellRendererComponent from "./cellrenderer/MatrixBubbleCellRendererComponent";
import {ConnectionStrengthCellEditorComponent} from "./ConnectionStrengthCellEditorComponent";
import {MatrixConnectionsCellEditorComponent} from "./MatrixConnectionsCellEditorComponent";
import DecimalCellRendererComponent from "./cellrenderer/DecimalCellRendererComponent";
import {FirstDataRenderedEvent} from "ag-grid-community/dist/lib/events";
import {calculateInternalValue} from "../../common/utils/FormatUtil";
import {duplicateElements, newElement} from "../../core/services/CoreDataServices";
import {ClearSelectionAction, SelectElementsAction} from "../../core/actions/CoreActions";
import ReactTooltip from "react-tooltip";
import {CustomNoRowsOverlayComponent} from "./CustomNoRowsOverlayComponent";
import {checkNewScale, ZoomingStatus} from "../../common/utils/ZoomHelper";
import {changeStandardCellConnection} from "../actions/MatrixAsyncActionCreators";
import {IEditorState} from "../../commonviews/models/IEditorState";
import {SetWordWrapOnColumnAction, ShowFilterAction} from "../actions/MatrixActions";
import {matrixStore} from "../stores/MatrixStore";
import {viewManagerRegistry} from "../../commonviews/models/ViewManager";
import * as _ from "lodash";
import {ConditionalFormat} from "../../commonviews/models/ConditionalFormat";
import {showConditionalFormatDialog} from "../../commonviews/components/ConditionalFormatDialog";

const log = Log.logger("MatrixComponent");
const dndLog = Log.logger("MatrixComponent", Classifier.dnd);
const rowHeight: number = 15;

export interface MatrixGridComponentProps {
  viewModel: MatrixModel;
  windowIndex: number;
  viewId: ViewId;
  editorState: IEditorState;
}

/**
 The MatrixGridComponent serves as the glue between our reactive mobx world and ag-grid.
 It observes the matrix model itself and the columnDefinitions.
 Changes to all the inner workings of the grid are translated by RowDataUpdater and MatrixRowElements model to ag-grid update transactions.
 This is set up in AgGrid.onGridReady and updates ag-grid until component is unmounted.

 Changes in the column hierarchy like additions of elements to column tables, filtering, new or deleted connections between column tables will be handled by this component:
 <ul>
 <li>Render will be triggered by changes in MatrixModel.columnDefinitions, which is a computed property updating on all changes affecting column definitions</li>
 </ul>

 Everything else will be triggered from RowDataUpdater via the onRowDataChanged and onMetusSelectionChanged callbacks.
 */
@observer
export class MatrixGridComponent extends React.Component<MatrixGridComponentProps> {
  private _gridApi: GridApi;
  private _columnApi: ColumnApi;
  private _rowDataUpdater: RowDataUpdater;
  private _matrixRowElements: MatrixRowElements;
  private _lastHeaderHeight: number;
  private _displayedColumns: Column[] = [];
  private _filterIsShown: boolean = undefined;
  private _currentChartsRef: any = [];

  constructor(props: MatrixGridComponentProps) {
    super(props);
  }

  @autobind
  public componentDidUpdate(prevProps: MatrixGridComponentProps): void {
    if (this._gridApi) {
      this._gridApi.resetRowHeights();
      this.updateHeaders();
      this._gridApi.redrawRows({rowNodes: this._gridApi.getRenderedNodes()});
    }
  }

  private updateHeaders() {
    // Why is there no bitwise OR in TypeScript?:  const headerUpdated: boolean = this.updateHeaderHeight() | this.updateFilterIsShown();
    const headerHeightUpdated: boolean = this.updateHeaderHeight();
    const filterIsShownUpdated: boolean = this.updateFilterIsShown();
    if (headerHeightUpdated || filterIsShownUpdated)
      this._gridApi.refreshHeader();
  }

  private updateHeaderHeight(): boolean {
    const currentHeight = this.props.viewModel.leafHeaderHeight;
    if (this._gridApi && this._lastHeaderHeight !== currentHeight) {
      this._gridApi.setHeaderHeight(currentHeight);
      this._gridApi.setGroupHeaderHeight(MatrixVisualConstants.BASE_GROUPHEADER_HEIGHT);

      this._lastHeaderHeight = currentHeight;

      return true;
    }

    return false;
  }

  private updateFilterIsShown(): boolean {
    const filterIsShown = this.props.viewModel.filterIsShown;
    if (this._gridApi && this._filterIsShown !== filterIsShown) {
      // work around with height is needed, because there is no chance to set floatingFilter true or false after the first time we build the grid options (see GridOptions in this class)
      if (!filterIsShown) {
        this._gridApi.setFloatingFiltersHeight(0);
      } else {
        this._gridApi.setFloatingFiltersHeight(MatrixVisualConstants.FLOATING_FILTER_HEIGHT);
      }

      this._filterIsShown = filterIsShown;

      return true;
    }

    return false;
  }

  @autobind
  restoreChart(): void {
    this.clearCharts();

    for (const chartModel of this.props.viewModel.chartModels) {
      const options = chartModel.chartOptions;
      const createRangeChartParams = {
        cellRange: chartModel.cellRange,
        chartType: chartModel.chartType,
        // chartThemeName: chartModel.chartThemeName,
        processChartOptions: function () {
          return options;
        },
      };
      this._currentChartsRef.push(this._gridApi.createRangeChart(createRangeChartParams));
    }
  }

  @autobind
  clearCharts(): void {
    for (const chartRef of this._currentChartsRef) {
      chartRef.destroyChart();
    }
    this._currentChartsRef = [];
  }

  @autobind
  saveChart(event: any): void {
    if (!event.api.destroyCalled) {
      // don't trigger update of charts if the whole matrix is closed
      this.props.viewModel.chartModels = this._gridApi.getChartModels();
    }
  }

  @autobind
  private onGridReady(event: GridReadyEvent): void {
    log.debug("MatrixGridComponent: Grid ready, setting rowData and activating RowDataUpdater");
    this._gridApi = event.api;
    this._columnApi = event.columnApi;
    this._gridApi.closeToolPanel();
    // FILTERED ELEMENTS
    this._matrixRowElements = new MatrixRowElements(this.props.viewModel);
    this._rowDataUpdater = new RowDataUpdater(this._matrixRowElements, this.props.viewModel, this.props.viewModel.cellConfiguration, this.onRowDataChanged, this.onMetusSelectionChanged);
    // set initial rows when grid is ready
    this._gridApi.setRowData(this._rowDataUpdater.initializeAndCreateMatrixRows());
    if (this.updateFilterIsShown())
      this._gridApi.refreshHeader();

    this.restoreChart();
  }

  @autobind
  private onFirstDataRendered(event: FirstDataRenderedEvent): void {
    this.autosizeMatrixColumns();
    if (this.props.viewModel.columnState && this.props.viewModel.columnGroupState) {
      this._columnApi.setColumnState(this.props.viewModel.columnState)
      this._columnApi.setColumnGroupState(this.props.viewModel.columnGroupState)
    } else {
      this.props.viewModel.columnState = this._columnApi.getColumnState();
      this.props.viewModel.columnGroupState = this._columnApi.getColumnGroupState();
    }
    if (this.props.viewModel.sortModel) {
      this._gridApi.setSortModel(this.props.viewModel.sortModel);
    }
    this.updateHeaderHeight();
  }

  // saves the column state of all row Attribute Columns
  @autobind
  private autosizeMatrixColumns(): void {
    const toResize: Column[] = this.getMatrixColumns();
    this._columnApi.autoSizeColumns(toResize);
  }

  public componentWillUnmount(): void {
    this._rowDataUpdater.dispose();
    this._matrixRowElements.dispose();
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  componentDidMount(): void {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  }

  private ctrlKeyPressed: boolean = false;
  private shiftKeyPressed: boolean = false;

  @autobind
  private handleKeyUp(e: any): void {
    if (e.getModifierState("Control")) {
      this.ctrlKeyPressed = false;
    }
    if (e.getModifierState("Shift")) {
      this.shiftKeyPressed = false;
    }
  }

  @autobind
  private handleKeyDown(e: any): void {
    if (e.getModifierState("Control")) {
      this.ctrlKeyPressed = true;
    }
    if (e.getModifierState("Shift")) {
      this.shiftKeyPressed = true;
    }
  }

  /**
   * called by RowDataUpdater if metus selection was changed
   * @param elementId
   * @param isPrimaryUpdate true if primary selection was updated, false if secondary selection was updated
   */
  @autobind
  private onMetusSelectionChanged(elementId: ElementId, isPrimaryUpdate: boolean): void {
    // the same element can be displayed in multiple rows
    const affectedVisibleRows = this._gridApi.getRenderedNodes().filter(rowNode => rowNode.data.dataPath && rowNode.data.dataPath[rowNode.data.dataPath.length - 1] === elementId);
    affectedVisibleRows.forEach(aVR => {
      // for primary and secondary updates call setData in order to re-apply styles
      aVR.setData(aVR.data);
      // only if it is an updated of the primary selection, sync with the local selection
      // of the grid, too.
      if (isPrimaryUpdate) {
        const isInPrimarySelection = modelStore.primarySelection.has(elementId);
        aVR.setSelected(isInPrimarySelection);
      }
    });

    this.props.viewModel.conditionalFormatsByAttributeName.forEach((value: ConditionalFormat[], key: string, map: Map<string, ConditionalFormat[]>) => {
      this._gridApi.refreshCells({columns: [key], force: true});
    });
  }

  /**
   * called by RowDataUpdater if row data has changed, debounced to collect changes for multiple actions
   * @param transaction
   */
  @autobind
  private onRowDataChanged(transaction: RowDataTransaction): void {
    this._gridApi.updateRowData(transaction);
  }

  @autobind
  private onCellEditingStarted(event: CellEditingStartedEvent): void {
    // hide tooltip if visible
    ReactTooltip.hide();
  }

  @autobind
  private onCellValueChanged(event: CellValueChangedEvent): void {
    const newValue = event.newValue;
    if (isMatrixColumn(event.colDef)) {
      if (!this.props.viewModel.cellConfiguration.isJoinTable()) {
        // write back cell value
        const rowNode = event.api.getDisplayedRowAtIndex(event.rowIndex);
        const elementId = elementPathKeyToLeafElementId(rowNode.id);
        changeStandardCellConnection(elementId, event.colDef.field, newValue);
      } else {
        // nothing to do, jointable updates will be handled by cell editor directly
      }
    } else {
      if (hasValueChanged(event)) {
        const attributeDefinition = this.props.viewModel.getAttributeDefinitionByNodeLevel(event.node.level, event.colDef.field);
        const internalValue = calculateInternalValue(event.newValue, attributeDefinition);

        updateAttributeValuesForElementAndTable(
            [{name: event.colDef.headerName, value: internalValue}],
            event.data.elementId,
            this.props.viewModel.rowHierarchy.tables[event.node.level].tableId
        );
      }
    }
  }

  @autobind
  private onColumnResized(event: ColumnResizedEvent): void {
    if (event.column) {
      this.props.viewModel.columnState = this._columnApi.getColumnState();
      this.props.viewModel.columnGroupState = this._columnApi.getColumnGroupState();
      /* This is for resizing of images. */
      event.api.refreshCells({columns: [event.column], force: true});
      event.api.resetRowHeights();
    }
  }

  @autobind
  private onSortChanged(event: SortChangedEvent): void {
    this.props.viewModel.sortModel = this._gridApi.getSortModel();
  }

  @autobind
  private onDisplayedColumnsChanged(event: DisplayedColumnsChangedEvent): void {
    if (this._columnApi) {
      if (this.props.viewModel.attributeColumnAdded) {
        this._columnApi.setColumnState(this.props.viewModel.columnState);
        this.props.viewModel.attributeColumnAdded = false;
      }
      // only save when ag grid isn't drawing anymore, since it otherwise can store some in between rendering states which get applied afterwards
      if (this._gridApi.isAnimationFrameQueueEmpty()) {
        this.props.viewModel.columnState = this._columnApi.getColumnState();
        this.props.viewModel.columnGroupState = this._columnApi.getColumnGroupState();
      }
    }
  }

  render(): JSX.Element {
    log.debug("Rendering MatrixGridComponent", this.props);

    const cellRenderer: AllCellRenderer = {
      AttributeCellRendererComponent,
      MatrixTreeColumnInnerCellRendererComponent,
      ChainMatrixCellRendererComponent,
      MatrixBubbleCellRendererComponent,
      PercentCellRendererComponent,
      DecimalCellRendererComponent,
    };

    const cellEditor: AllCellEditor = {
      ConnectionStrengthCellEditorComponent,
      MatrixConnectionsCellEditorComponent: MatrixConnectionsCellEditorComponent
    };

    // re-render:
    // - when columnDefs changed or
    // - row tables changed
    // do not re-render when row hierarchy changed, since RowDataUpdater handles that
    return <React.Fragment>
      <div style={{height: "100%"}} data-testselector={"aggrid" + this.props.windowIndex}
           onClick={this.onClickOfGridContainer}>
        <ZoomableDivComponent
            style={{height: "100%", width: "100%", transformOrigin: "0 0"}}
            className="ag-theme-material"
            windowIndex={this.props.windowIndex}
        >
          <AgGridReact gridOptions={this.createGridOptions()}
                       columnDefs={this.props.viewModel.columnDefinitions.colDefs}
                       deltaColumnMode={false}
                       rowSelection={"multiple"}
                       rowHeight={this.calculateRowHeight()}
                       frameworkComponents={{...cellRenderer, ...cellEditor, CustomNoRowsOverlayComponent}}
                       groupSuppressAutoColumn={true}
                       stopEditingWhenGridLosesFocus={true}
          />
        </ZoomableDivComponent>
      </div>

    </React.Fragment>;
  }

  @autobind
  private calculateRowHeight(): number {
    let retVal: number = MatrixVisualConstants.BASE_ROW_HEIGHT * this.props.editorState.scale;

    if (this._gridApi && this._gridApi.getDisplayedRowAtIndex(0)) {
      const rowNode: RowNode = this._gridApi.getDisplayedRowAtIndex(0);
      const oldScale = rowNode.rowHeight / MatrixVisualConstants.BASE_ROW_HEIGHT;
      const zoomingStatus: ZoomingStatus = checkNewScale(this.props.editorState.scale, oldScale);

      if (zoomingStatus.outOfLowerBounds || zoomingStatus.outOfUpperBounds) {
        setTimeout(() => {
          Dispatcher.dispatch(new ZoomViewAction(this.props.windowIndex, zoomingStatus))
        }, 0);

        retVal = MatrixVisualConstants.BASE_ROW_HEIGHT * zoomingStatus.scale;
      }
    }

    return retVal;
  }

  @autobind
  private getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    const menuEntries = [];
    menuEntries.push({
      name: "Duplicate Element(s)",
      action: this.duplicateSelectedElement,
      cssClasses: ["redFont", "bold"]
    });
    // if not clicked over cell, params.node is null
    if (params.node) {
      const nodeData = params.node.data;
      const nextLevel = nodeData.dataPath.length;
      const elementId = nodeData.elementId;
      menuEntries.push({
        name: "New element",
        action: () => this.newElementOfTable(params.node.level, params.node.parent.data?.elementId),
        cssClasses: ["redFont", "bold"]
      });
      if (nextLevel < this.props.viewModel.rowHierarchy.tables.length) {
        menuEntries.push({
          name: "New Child Element",
          action: () => {
            this.newElementOfTable(nextLevel, elementId);
          },
          cssClasses: ["redFont", "bold"]
        });
      }
      menuEntries.push(
          {
            name: "Delete element",
            action: this.deleteSelectedElements,
            cssClasses: ["redFont", "bold"]
          },
          "copy",
          "paste",
          "separator",
          "chartRange");

      // link attributes have "Open" context menu entry opening the link in a new window
      const attDef: AttributeDefinition = params.context.getAttributeDefinitionByNodeLevel(params.node.level, params.column.getColDef().field);
      if (attDef?.type === "Link") {
        const link = this._gridApi.getValue(params.column, params.node);
        try {
          const url = new URL(link);
          menuEntries.push({
            name: "Open",
            action: () => window.open(link),
            cssClasses: ["redFont", "bold"]
          });
        } catch (e) {
          // not a valid url, ignore
        }
      }
    }
    return menuEntries;
  }

  @autobind
  private getMainMenuItems(params: GetMainMenuItemsParams): (string | MenuItemDef)[] {
    log.debug("getMainMenuItems", params.column);
    const items: (string | MenuItemDef)[] = [];

    if (this.props.viewModel.rowHierarchy.tables.length === 1) {
      _.remove(params.defaultItems, s => s === "expandAll" || s === "contractAll");
    }

    const colDef = params.column.getColDef();

    if (!isMatrixColumn(colDef, true)) {
      // add remove to defaults
      items.push({
        name: "Remove Column",
        action: () => {
          this.handleRemoveAttribute(colDef);
        },
        disabled: isNameColumn(colDef)
      });

      items.push({
        name: this.props.viewModel.filterIsShown ? "Deactivate Filters" : "Activate Filters",
        action: () => {
          this.handleShowFilter(!this.props.viewModel.filterIsShown);
        },
        disabled: false
      });

      items.push({
        name: "Conditional Format...",
        action: () => {
          const currentConditionalFormats: ConditionalFormat[] = this.props.viewModel.getConditionalFormats(colDef.headerName);
          // The current ConditionalFormat Dialog and Action needs a VisualAttribute (for Diagrams).
          // We do not have/need VisualAttributes in a matrix.
          // To not having to refactor everything, we just fake a VisualAttributeId
          const fakeVisualAttributeId: VisualAttributeId = new VisualAttributeId(new VisualTableId("", null), colDef.headerName);
          const visualAttributeIdsForAttributeName: VisualAttributeId[] = this.props.viewModel.getVisualAttributeIdsForAttributeName(colDef.headerName);
          const gridApi = this._gridApi;
          const onClose = function (): void {
            gridApi.refreshCells({columns: [params.column], force: true});
          };
          showConditionalFormatDialog(true, this.props.viewModel.id, visualAttributeIdsForAttributeName[0], visualAttributeIdsForAttributeName, currentConditionalFormats, true, onClose);
        }
      });

      const isWordWrap = matrixStore.getMatrixById(this.props.viewId).getWordWrapOnColumn(colDef.colId);
      items.push({
        name: "Word wrap",
        action: () => {
          Dispatcher.dispatch(new SetWordWrapOnColumnAction(this.props.viewId, colDef.colId, !isWordWrap));
        },
        disabled: false,
        checked: isWordWrap,
        tooltip: "Toggle Word wrap for this column",
      });

      items.push({
        name: "Export to Excel",
        action: () => {
          const params: ExcelExportParams = {
            fileName: "ExportedExcel",
            rowHeight: 50,
            columnGroups: true
          }
          this._gridApi.exportDataAsExcel(params);
        },
        disabled: false
      })
    }

    return [...params.defaultItems, ...items];
  }

  // if headers are rotated it returns the current attributeHeaderHeight set in the viewModel

  /* dynamic background color of row on selection, ag-grid feature */
  @autobind
  private getRowStyle(rowEvent: RowEvent): any {
    const row: MatrixRow = rowEvent.data;
    const nodeId = row.elementId;
    const result = {};
    const isPrimary = modelStore.primarySelection.has(nodeId);

    try {
      const rowNestingLevel: number = rowEvent.data.dataPath.length - 1;
      const totalNestingLevels: number = this.props.viewModel.rowHierarchy.tables.length;
      result["backgroundColor"] = getLevelDefaultColor(rowNestingLevel, totalNestingLevels);
      result["color"] = getLevelDefaultTextColor(rowNestingLevel, totalNestingLevels);

      if (isPrimary) {
        result["backgroundColor"] = metusElementSelectionColors.primary;
      } else {
        const isSecondary = modelStore.secondarySelection.has(nodeId);
        if (isSecondary) {
          result["backgroundColor"] = metusElementSelectionColors.secondary;
        }
      }
    } catch (ex) {
      log.warn("More nesting levels than expected: " + ex.message);
    }
    log.debug("Set style for node", nodeId, result);
    return result;
  }

  @autobind
  private getScale():number {
    return viewManagerRegistry.viewManager.getEditorStateByWindowIndex(this.props.windowIndex).scale;
  }

  private createGridOptions(): GridOptions {
    const filterIsShown = this.props.viewModel.filterIsShown

    // context passing information to cell editors/renderers
    const context = {
      getSelectedRowIds: this.getSelectedRowIds,
      getAttributeDefinition: this.getAttributeDefinition,
      getAttributeDefinitionByNodeLevel: this.props.viewModel.getAttributeDefinitionByNodeLevel,
      getTableIdByNodeLevel: this.props.viewModel.getTableIdByNodeLevel,
      viewId: this.props.viewId,
      getScale: this.getScale
    } as MatrixContext;

    return {
      sideBar: {
        toolPanels: [],// ["columns", "filters"],
        defaultToolPanel: "columns",
        hiddenByDefault: false,
      },
      headerHeight: this.props.viewModel.leafHeaderHeight,
      groupHeaderHeight: MatrixVisualConstants.BASE_GROUPHEADER_HEIGHT,
      // It seems that the ag-grid takes always the options just before the first rendering. Every alternation after that must be via gridapi, columnapi, ....
      // Why do I think that?
      // The code in the following two commented lines does nothing, if ag-grid is rendered again, even this method is triggered.
      // floatingFiltersHeight: this.props.viewModel.filterIsShown ? MatrixVisualConstants.FLOATING_FILTER_HEIGHT : 0,
      // floatingFilter: this.props.viewModel.filterIsShown,
      floatingFiltersHeight: MatrixVisualConstants.FLOATING_FILTER_HEIGHT,
      // normally "floatingFilter" is set in defaultColDef or in each coldef (https://www.ag-grid.com/javascript-grid-floating-filters/).
      // But colDef.ts does not contain "floatingFilter", but "filter", but that also seems to do nothing
      // I have to set "floatingFilter: this.props.viewModel.filterIsShown" instead of floatingFilter: true. Otherwise it is not rerendered (filterIsShown is @observable)
      floatingFilter: true,
      getContextMenuItems: this.getContextMenuItems,
      getMainMenuItems: this.getMainMenuItems,
      rowStyle: {fontFamily: theme.metus.main.fontFamily},
      getRowStyle: this.getRowStyle,
      onGridReady: this.onGridReady,
      onCellValueChanged: this.onCellValueChanged,
      onCellEditingStarted: this.onCellEditingStarted,
      onDisplayedColumnsChanged: this.onDisplayedColumnsChanged,
      onColumnResized: this.onColumnResized,
      onSortChanged: this.onSortChanged,
      onFirstDataRendered: this.onFirstDataRendered,
      onRowSelected: this.onRowSelected,
      onChartCreated: this.saveChart,
      onChartDestroyed: this.saveChart,
      onChartOptionsChanged: this.saveChart,
      onChartRangeSelectionChanged: this.saveChart,
      enableCharts: true,
      // see also MatrixCellRendererComponentProps
      enableRangeSelection: true,
      treeData: true,
      getDataPath: (data): string[] => {
        if (data) {
          return data.dataPath;
        } else {
          return undefined;
        }
      },
      getRowNodeId: (data: MatrixRow): ElementId => {
        return elementPathToKey(data.dataPath);
      },
      autoGroupColumnDef: {
        field: "name"
      },
      defaultColDef: {
        sortable: true,
        resizable: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: "agSelectedRowCountComponent", align: "left"},
          {statusPanel: "agTotalRowCountComponent", align: "left"},
          {
            statusPanel: "agAggregationComponent",
            statusPanelParams: {
              // possible values are: 'count', 'sum', 'min', 'max', 'avg'
              aggFuncs: ["avg", "sum", "count"]
            }
          },
          {
            statusPanelFramework: ColumnPaginatorComponent,
            statusPanelParams: {
              matrixId: this.props.viewId
            },
            align: "right"
          },
        ]
      },
      context,
      noRowsOverlayComponent: "CustomNoRowsOverlayComponent",
      noRowsOverlayComponentParams: {
        viewId: this.props.viewId,
        viewModel: this.props.viewModel
      }
    } as GridOptions;
  }

  /**
   * callback to get selected rows, used by cell renderer for multiple elements drag support
   * @returns {Set<ElementId>}
   */
  @autobind
  private getSelectedRowIds(): Set<ElementId> {
    // do not use primarySelection because this might include a lot of invisible nodes which the user might not have touched at all
    // use grid multiple selection
    const selectedRows: Row[] = this._gridApi.getSelectedRows();
    return new Set<ElementId>(selectedRows.map(row => row.elementId));
  }

  @autobind
  private getAttributeDefinition(tableId: TableId, attributeName: string): AttributeDefinition {
    return modelStore.getAttributeDefinition(tableId, attributeName);
  }

  @autobind
  private getMatrixColumns(): Column[] {
    return this._columnApi.getAllColumns().filter(column => !this.props.viewModel.rowAttributes.map(it => it.attributeName).includes(column.getColId()));
  }

  @autobind
  private getInternalValue(elementId: ElementId, attributeName: string): string {
    const elementObject: ElementObject = modelStore.getElement(elementId);
    return elementObject[attributeName];
  }

  @autobind
  private duplicateSelectedElement(): void {
    const elementIds = this.getSelectedRowIds();
    duplicateElements(Array.from(elementIds), this.props.viewId);
  }

  @autobind
  private newElementOfTable(toLevel: number, parentElementId?: ElementId): void {
    if (parentElementId) {
      createChildElement(this.props.viewModel.rowHierarchy.tables[toLevel].tableId, parentElementId);
    } else {
      newElement(this.props.viewModel.rowHierarchy.tables[toLevel].tableId);
    }
  }

  @autobind
  private deleteSelectedElements(): void {
    log.debug("MatrixGridComponent: delete selected element(s)");
    const elementIds = this.getSelectedRowIds();
    deleteElementsAndDispatch(Array.from(elementIds));
  }

  @autobind
  private handleRemoveAttribute(colDef: ColDef): void {
    log.debug("remove row attribute " + colDef.field);
    // remove attribute name
    Dispatcher.dispatch(new RemoveAttributeFromViewAction(this.props.viewId, VisualAttributeId.noVisualId(undefined, colDef.field), undefined));
  }

  @autobind
  private handleShowFilter(showFilter: boolean): void {
    log.debug("show filter " + showFilter);
    // remove attribute name
    Dispatcher.dispatch(new ShowFilterAction(this.props.viewId, showFilter, undefined));
  }

  @autobind
  private setAllMatrixColumnsExpansionState(state: boolean): void {
    const matrixColumns = this.getMatrixColumns();
    matrixColumns.forEach(matrixColumn => {
      const origParent = this.getExpandableParent(matrixColumn);
      this._columnApi.setColumnGroupOpened(origParent, state);
    });
  }

  @autobind
  private getExpandableParent(column: Column) {
    let origParent = column.getOriginalParent();
    while (!origParent.isExpandable() && origParent.getOriginalParent()) {
      origParent = origParent.getOriginalParent();
    }
    return origParent;
  }

  @autobind
  private setMatrixColumnExpansionState(column: Column, state: boolean) {
    const origParent = this.getExpandableParent(column);
    this.setColumnTreeExpansionStateRecursive(origParent, state);
  }

  @autobind
  private setColumnTreeExpansionStateRecursive(root: OriginalColumnGroup, state: boolean): void {
    this._columnApi.setColumnGroupOpened(root, state);
    const children = root.getChildren();
    if (children) {
      children.forEach(child => {
        if (child instanceof OriginalColumnGroup) {
          this.setColumnTreeExpansionStateRecursive(child, state);
        }
      });
    }

  }

  @autobind
  private onRowSelected(event: RowSelectedEvent): void {
    log.debug("On Row Selected", event);
    const node = event.node;
    const elementId = node.data.elementId;
    const isInPrimarySelection = modelStore.primarySelection.has(elementId);
    let doUpdateSelection: boolean;
    if (!node.isSelected() && isInPrimarySelection) {
      // MO-2824 do not deselect, if there are other occurrences of the same element
      const otherOccurrence = event.api.getSelectedNodes().find(node => node.data.elementId === elementId);
      doUpdateSelection = otherOccurrence === undefined;
      if (!doUpdateSelection) {
        // restore selected state
        node.setSelected(true);
      }
    } else {
      doUpdateSelection = node.isSelected() !== isInPrimarySelection;
    }
    if (doUpdateSelection) {
      log.debug("Updating Selected Row", elementId, node.isSelected());
      const toggle = isInPrimarySelection
      Dispatcher.dispatch(new SelectElementsAction([elementId], this.ctrlKeyPressed || this.shiftKeyPressed, toggle));
    }
  }

  private onClickOfGridContainer(event) {
    const targetElement: HTMLElement = event.nativeEvent && event.nativeEvent.toElement;
    if (targetElement) {
      const isClickedOutsideCells = ["ag-center-cols-viewport", "ag-header-viewport"].includes(targetElement.className);
      log.debug("Clicked Outside Grid Cells", isClickedOutsideCells);
      if (isClickedOutsideCells)
        Dispatcher.dispatch(new ClearSelectionAction());
    }
  }
}


