import {ColDef} from "ag-grid-community";
import {ConditionalFormat} from "../../../commonviews/models/ConditionalFormat";
import {modelStore} from "../../../core/stores/ModelStore";
import {getSimpleMatcher, SimpleMatcher} from "../../../core/utils/filter/Evaluator";
import {AttributeDefinition} from "../../../api/api";

export interface ShrinkedCellRendererParams {
  data: any;
  colDef: ColDef;
}

export const extractCellValue = (params: ShrinkedCellRendererParams): any => {
  const columnId = params.colDef.field;
  if (params?.data) {
    return params.data[columnId];
  } else {
    return undefined;
  }
}

export const convertToPercentage = (cellValue: any): number => {
  let result;
  if (typeof (cellValue) === "number") {
    result = cellValue * 100;
  }
  return result;
}

export const convertToOpacity = (cellValue: any): number => {
  let result = cellValue
  if (typeof (result) !== "number") {
    result = 0.0;
  } else {
    result = Math.max(result, 0.0);
    result = Math.min(result, 1.0);
  }
  return result;
}

export const getCellStyleInGradientMode = (params: ShrinkedCellRendererParams): any => {
  let retVal: any = {};
  const cellValue = extractCellValue(params);

  if (cellValue !== undefined) {
    const opacity = convertToOpacity(cellValue);
    retVal = {
      // also in primary palette, A100, as #3a9bfc
      backgroundColor: `rgba(58, 155, 252, ${opacity})`,
      justifyContent: "center",
      alignItems: "center"
      // borderRight: "solid #a5a5a5 1px"
    };
  }

  return retVal;
}

export const getCellStyleWithoutGradientBackground = (params: ShrinkedCellRendererParams): any => {
  return {
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
  };
}

export const styleForCellsWithConditionalFormat = (params: any, conditionalFormats: ConditionalFormat[], attributeDefinition: AttributeDefinition): any => {
  let result: {} = {alignItems: "center"};
  if (attributeDefinition !== undefined && attributeDefinition?.formatType === "Double") {
    result = {...result, justifyContent: "flex-end"}; // Flex-end should be compatible with Chrome 59 which is used in MOE
  }

  if (params.node && params.node.data && params.node.data.elementId) {
    const nodeId = params.node.data.elementId;

    const isPrimary = modelStore.primarySelection.has(nodeId);
    const isSecondary = modelStore.secondarySelection.has(nodeId);

    if (isPrimary || isSecondary) {
      return {...result, background: "none"};
    }

    if (params.data && params.data.elementId) {
      const matchingConditionalFormats: ConditionalFormat[] = [];
      for (let conditionalFormat of conditionalFormats) {
        if (conditionalFormat.operand.attributeName === params.colDef.headerName) {
          matchingConditionalFormats.push(conditionalFormat);
        }
      }

      if (matchingConditionalFormats.length > 0) {
        const matcher: SimpleMatcher[] = conditionalFormats.map(cf => getSimpleMatcher(cf.filterExpression, cf.operand.visualTableId.tableId, cf.operand.attributeName));
        for (let i = 0; i < matcher.length; i++) {
          if (matcher[i].matches(params.data.elementId)) {
            return {...result, backgroundColor: conditionalFormats[i].styles.fill};
          }
        }
      }
    }
  }

  return {...result, background: "none"};
}
