/** list of useful cursor styles, enhance if needed */
import {AttributeFormatType} from "../../api/api";
import {AttributeType} from "../../core/utils/Core";

export enum Cursor {
  /** link cursor */
  ALIAS = "alias",
  /** default cursor */
  AUTO = "auto",
  /** move cursor */
  MOVE = "move",
  /** cannot be dropped */
  NO_DROP = "no-drop",
  COPY = "copy",
  /** east west resize cursor */
  EW_RESIZE = "ew-resize",
  ROW_RESIZE = "row-resize",
}

export enum ViewType {
  Chart, StructuredTable, WebViewHierarchy, Table, ValueChart, Cockpit, Matrix, ChainMatrix
}

export function isMatrixDerivative(viewType: ViewType) {
  return viewType === ViewType.Table || viewType === ViewType.StructuredTable || viewType === ViewType.Matrix || viewType === ViewType.ChainMatrix;
}

export function isDiagramDerivative(viewType: ViewType) {
  return viewType === ViewType.Chart || viewType === ViewType.ValueChart
}

type AttributeTypeItem = { type: AttributeType, name: string };

export const attributeTypeItems: AttributeTypeItem[] = [
  {type: "String", name: "Normal"},
  {type: "Image", name: "Image"},
  {type: "Attachment", name: "Attachment"},
  {type: "Memo", name: "Memo"},
  {type: "Link", name: "Link"},
  {type: "Formula", name: "Formula"},
  {type: "Derived", name: "Reference"}
];

type AttributeFormatTypeItem = { type: AttributeFormatType, name: string };

export const attributeFormatTypeItems: AttributeFormatTypeItem[] = [
  {type: "String", name: "None"},
  {type: "Double", name: "Number"},
  {type: "Date", name: "Date"}
];

type AttributeTypeFittingFormatTypeItem = { type: AttributeType, formatType: AttributeFormatType[] | null };

type ViewTypeItem = { type: ViewType, name: string };

export enum TreeItemType {
  Table, Folder, Attribute, View
}

type ListItemTypeItem = { type: TreeItemType, name: string };

export const listItemTypeItems: ListItemTypeItem[] = [
  {type: TreeItemType.Folder, name: "folder"},
  {type: TreeItemType.Table, name: "table"},
  {type: TreeItemType.Attribute, name: "attribute"},
  {type: TreeItemType.View, name: "view"},
];

export function getNameForListItemType(listItemType: TreeItemType): string {
  return listItemTypeItems.filter((t) => t.type === listItemType)[0].name;
}

export function getListItemTypeForName(name: string): TreeItemType {
  const result = listItemTypeItems.filter((t) => t.name === name);
  if (result.length === 0) {
    throw new Error(`Unknown list item type: '${name}'. Should be one of folder, table, attribute, view.`);
  }
  return result[0].type;
}

export enum PersistencyState {
  New, Loadable, Loaded, Saved, LocallySaved /** saved locally but not on server since not possible */
}

export enum CockpitIconType {
  Variants,
  Chart,
  Modularity,
  Ideation,
  Requirements,
  Productstructure,
  Markets,
  Concepts,
  Interfaces,
  Cost,
  Production,
  Productkit,
  Processes,
  Data,
  Portfolio,
  Kpi,
}

export enum CockpitColors {
  SoftBlue = "#7C86DA",
  DarkCyan = "#50A2B1",
  LimeGreen = "#22BB89",
  SoftGreen = "#93DA5C",
  StrongYellow = "#D0C115",
  ModerateYellow = "#D0B248",
  BrightOrange = "#F38E40",
  SoftRed = "#DD4F4F",
  SlightlyDesaturatedPink = "#D67FB2",
  VerySoftRed = "#FBAEB8",
  LightGray = "#D8D8D8",
  DarkGrayishBlue = "#898B91",
  SlightlyDesaturatedOrange = "#C6B084",
  MostlyDesaturatedDarkOrange = "#AD846C"
}

export enum PastelColors {
  Red = "#e47f72",
  DarkOrange = "#ffa54f",
  LightOrange = "#ffc151",
  Yellow = "#ffe672",
  GrassGreen = "#c7da91",
  LightGreen = "#aadfaa",
  Green = "#7ac698",
  Petrol = "#6fbeb4",
  DarkBlue = "#94bfd7",
  Blue = "#a0cdeb",
  LightBlue = "#c8dfff",
  Lavendel = "#c3cbff",
  Lila = "#7c86da",
  Pink = "#d091c0",
  Rosa = "#fbaeb8",
  SkinColored = "#ffdcd9",
}

export enum SignalColors {Red = "#f43336", Yellow = "#ffeb3b", Green = "#4caf50"}

/**
 * target for a table action inside a view, usually row hierarchy, but for matrix can be column too
 */
export enum Target {
  ROW, COLUMN
}

export enum ModelLocationType {
  file, server, memory, liveserver
}
