import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";



class ValueChartIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M45.0001,29 C46.6571,29 48.0001,30.343 48.0001,32 L48.0001,42.538 C48.0001,43.898 46.8971,45 45.5381,45 L29.0001,45 C27.3431,45 26.0001,43.657 26.0001,42 L26.0001,32 C26.0001,30.343 27.3431,29 29.0001,29 L45.0001,29 Z M41,20 C43.757,20 46,22.243 46,25 L46,27 L44,27 L44,25 C44,23.346 42.654,22 41,22 L22,22 C20.346,22 19,23.346 19,25 L19,38 C19,39.654 20.346,41 22,41 L24,41 L24,43 L22,43 C19.243,43 17,40.757 17,38 L17,25 C17,22.243 19.243,20 22,20 L41,20 Z M39,11 C41.757,11 44,13.243 44,16 L44,18 L42,18 L42,16 C42,14.346 40.654,13 39,13 L13,13 C11.346,13 10,14.346 10,16 L10,36 C10,37.654 11.346,39 13,39 L15,39 L15,41 L13,41 C10.243,41 8,38.757 8,36 L8,16 C8,13.243 10.243,11 13,11 L39,11 Z" />;
  }

  viewBox(): string {
    return "0 0 56 56";
  }
}

export default withStyles(svgIconStyles)(ValueChartIcon);