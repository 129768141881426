import {VisualAttributeDefinition} from "./VisualAttributeDefinition";
import {CSSProperties} from "react";
import {VisualBaseElement} from "./CommonDiagramTypes";
import {computed, observable} from "mobx";
import {StyleUtil} from "../../../core/utils/StyleUtil";
import {METUS_DEFAULT_STYLES} from "../../../commonviews/utils/ElementStyleUtil";

export abstract class VisualBaseAttributeValue {
  @observable value: string;
  @observable attributeDefinition: VisualAttributeDefinition;
  @observable visualElement: VisualBaseElement;
  @observable styles: CSSProperties;

  /**
   * if a conditional format is evaluated, it will set the conditionalStyle on this value, otherwise it is undefined
   */
  @observable conditionalStyles: CSSProperties;

  abstract get x(): number;

  abstract get y(): number;

  abstract get dx(): number;

  abstract get dy(): number;

  abstract get width(): number;

  abstract get height(): number;

  constructor(visualAttributeDefinition: VisualAttributeDefinition, visualElement: VisualBaseElement, value: string, styles?: CSSProperties) {
    this.attributeDefinition = visualAttributeDefinition;
    this.visualElement = visualElement;
    this.value = value;
    this.styles = styles;
  }

  @computed.struct get textStyles(): CSSProperties {
    return StyleUtil.updateStyles(this.styles, METUS_DEFAULT_STYLES.attributeValue, StyleUtil.toTextStyle);
  }

  @computed.struct get rectStyles(): CSSProperties {
    return StyleUtil.updateStyles(this.styles, METUS_DEFAULT_STYLES.attributeValue, StyleUtil.toRectStyle);
  }
}