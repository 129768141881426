import {
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
  DropTargetSpec,
  XYCoord
} from "react-dnd";
import * as React from "react";
import {FeedbackHelper} from "../../common/utils/DragDropHelper";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {DndSourceFeedbackAction, DndTargetFeedbackAction} from "../../common/actions/InteractionStateActions";
import {Cursor, ViewType} from "../../common/constants/Enums";
import {VisualTableDragInfo} from "../../common/utils/CoreDropTypes";
import {dragTypeAdapterManager} from "../utils/DragTypeAdapterManager";
import DragTypes from "../../common/constants/DragTypes";
import DragType from "../../common/constants/DragTypes";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import _ from "lodash";
import {
  TableColumnHeaderComponentLocalAndDndProps,
  TableColumnHeaderComponentNoDnd
} from "./TableColumnHeaderComponent";
import {VisualTableId} from "../../core/utils/Core";
import {addTableToDiagram} from "../actions/SharedViewAsyncActions";
import {ReorderTablesAction} from "../actions/SharedViewActions";

const dndLog = Log.logger("TableColumnHeaderComponent", Classifier.dnd);

function targetCollect(connect: DropTargetConnector, monitor: DropTargetMonitor): Object {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

//noinspection JSUnusedLocalSymbols,JSUnusedLocalSymbols,JSUnusedLocalSymbols
const dropTargetSpec: DropTargetSpec<TableColumnHeaderComponentLocalAndDndProps> = {
  hover(props: TableColumnHeaderComponentLocalAndDndProps, monitor: DropTargetMonitor, component: TableColumnHeaderComponentNoDnd): void {
    const shallow = monitor.isOver({shallow: true});
    dndLog.debug("Hover over id,name, shallow, isOverCurrent", props.id, props.header.name, shallow);
    if (props.viewContext.viewType !== ViewType.Table) {
      if (shallow) {
        const box = FeedbackHelper.createTargetFeedbackBox(component, monitor);
        dndLog.debug("Setting target feedback", props.id);
        Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.ALIAS, <div
            style={{position: "absolute"}}>{box} </div>));
      }
    } else {
      Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.NO_DROP, "cannot alter the table configuration of a table view"));
    }
  },
  canDrop(props: TableColumnHeaderComponentLocalAndDndProps, monitor: DropTargetMonitor): boolean {
    dndLog.debug("Can Drop", monitor.getItemType(), monitor.getItem());
    const mousePosition: XYCoord = monitor.getClientOffset() ? monitor.getClientOffset() : {x: 100, y: 100};
    let result: boolean = false;
    if (props.viewContext.viewType !== ViewType.Table) {
      switch (monitor.getItemType()) {
        case DragTypes.CORE_TABLE:
          result = true;
          break;
        case DragTypes.VISUAL_TABLE_COLUMN_MOVE:
          result = true;
          break;
        default:
          result = false;
          break;
      }
    } else {
      result = false;
    }
    return result;
  },
  drop(props: TableColumnHeaderComponentLocalAndDndProps, monitor: DropTargetMonitor, component: TableColumnHeaderComponentNoDnd): VisualTableDragInfo {
    dndLog.debug("Drop occured");
    const shallow = monitor.isOver({shallow: true});
    dndLog.debug("TableColumnHeaderComponent drop " + props.header.name + ", isOver: " + shallow);
    const item: VisualTableDragInfo = monitor.getItem();
    if (!monitor.didDrop()) {
      if (item) {
        dndLog.debug("Drop VISUAL_TABLE_COLUMN " + JSON.stringify(item) + " at index " + props.index);
        const droppedVisualTableId = new VisualTableId(item.tableId, item.visualId);
        if (props.viewContext.viewId === item.viewId) {
          Dispatcher.dispatch(new ReorderTablesAction(props.viewContext.viewId, droppedVisualTableId, props.id, props.index, props.header.name));
        } else {
          // drag from other view adds the table
          addTableToDiagram(props.viewContext.viewId, droppedVisualTableId.tableId, props.index);
        }
      } else {
        dndLog.error("Drop with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
      }
    } else {
      dndLog.debug("ignoring drop, was handled by nested element before");
    }
    return item;
  }
};

function sourceCollect(connect: DragSourceConnector, monitor: DragSourceMonitor): Object {
  return {
    connectDragSource: connect.dragSource()
  };
}

//noinspection JSUnusedLocalSymbols,JSUnusedLocalSymbols,JSUnusedLocalSymbols
const dragSourceSpec: DragSourceSpec<TableColumnHeaderComponentLocalAndDndProps, VisualTableDragInfo> = {
  beginDrag(props: TableColumnHeaderComponentLocalAndDndProps, dragSourceMonitor: DragSourceMonitor, component: React.Component<TableColumnHeaderComponentLocalAndDndProps>): VisualTableDragInfo {
    dndLog.debug("beginDrag");
    Dispatcher.dispatch(new DndSourceFeedbackAction(FeedbackHelper.createSourceFeedbackBox(component, props.header.name)));
    return {
      ...props.id,
      viewId: props.viewContext.viewId,
      isColumnHierarchy: props.isColumnHierarchy,
      type: DragType.VISUAL_TABLE_COLUMN_MOVE
    };
  }
};

// HOCs must be composed using flow first
export const TableColumnHeaderComponentDnd = _.flow(
    DragSource<TableColumnHeaderComponentLocalAndDndProps>(DragTypes.VISUAL_TABLE_COLUMN_MOVE, dragSourceSpec, sourceCollect),
    DropTarget<TableColumnHeaderComponentLocalAndDndProps>(dragTypeAdapterManager.getCompatibleTypes(DragTypes.VISUAL_TABLE_COLUMN_MOVE), dropTargetSpec, targetCollect)
)(TableColumnHeaderComponentNoDnd);
