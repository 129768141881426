import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";

class ChainMatrixIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path
        d="M42.779,10 C45.658,10 48,12.342 48,15.221 L48,15.221 L48,41 C48,43.757 45.757,46 43,46 L43,46 L13.221,46 C10.342,46 8,43.658 8,40.779 L8,40.779 L8,15 C8,12.243 10.243,10 13,10 L13,10 Z M46,19 L18,19 L18,44 L43,44 C44.654,44 46,42.654 46,41 L46,41 L46,19 Z M28.2121,28.1674 C30.3071,26.0704 33.7161,26.0724 35.8101,28.1674 C36.1481,28.5044 36.4111,28.8844 36.6401,29.2794 L36.6401,29.2794 L35.1551,30.7634 C34.9921,30.3324 34.7421,29.9274 34.3961,29.5814 C33.0811,28.2644 30.9391,28.2664 29.6261,29.5814 L29.6261,29.5814 L24.9871,34.2194 C24.3501,34.8564 23.9991,35.7034 23.9991,36.6044 C23.9991,37.5054 24.3501,38.3524 24.9871,38.9894 C26.2631,40.2634 28.4821,40.2644 29.7581,38.9894 L29.7581,38.9894 L30.7451,38.0024 C31.4631,38.2314 32.2201,38.3554 32.9951,38.3554 C33.0751,38.3554 33.1521,38.3464 33.2321,38.3434 L33.2321,38.3434 L31.1721,40.4034 C30.1251,41.4514 28.7481,41.9754 27.3731,41.9754 C25.9961,41.9754 24.6211,41.4514 23.5731,40.4034 C22.5581,39.3894 21.9991,38.0404 21.9991,36.6044 C21.9991,35.1694 22.5581,33.8194 23.5731,32.8054 L23.5731,32.8054 Z M33.834,22.5444 C35.929,20.4504 39.336,20.4484 41.433,22.5444 C43.527,24.6394 43.527,28.0484 41.433,30.1434 L41.433,30.1434 L36.794,34.7814 C35.779,35.7964 34.431,36.3554 32.995,36.3554 C31.56,36.3554 30.211,35.7964 29.196,34.7814 C28.862,34.4474 28.598,34.0664 28.366,33.6694 L28.366,33.6694 L29.851,32.1844 C30.018,32.6224 30.27,33.0254 30.61,33.3674 C31.884,34.6414 34.106,34.6414 35.38,33.3674 L35.38,33.3674 L40.019,28.7294 C41.334,27.4134 41.334,25.2734 40.019,23.9584 C38.744,22.6844 36.524,22.6834 35.248,23.9584 L35.248,23.9584 L34.257,24.9504 C33.45,24.6934 32.609,24.5814 31.771,24.6084 L31.771,24.6084 Z M18,12 L13,12 C11.346,12 10,13.346 10,15 L10,15 L10,19 L18,19 L18,12 Z"/>;
  }

  viewBox(): string {
    return "0 0 56 56";
  }
}

export default withStyles(svgIconStyles)(ChainMatrixIcon);