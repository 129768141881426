import Log from "../../../common/utils/Logger";
import {ScrollCoordinates} from "../../actions/DiagramActions";

const log = Log.logger("AutoScrollOnHoverCalculator");

export class AutoScrollOnHoverOptions {
  // the inner border width within the rectangle in which the hovering mouse triggers auto scrolling
  autoScrollingBorderWidth: number;
  // a factor with which the proximity value is multiplied in order to get the scrolling distance
  // the proximity value is value between 0 and 1, with 1 being on the outer edge and 0 being outside the auto scrolling border)
  scrollVelocity: number;
}

const defaultOptions: AutoScrollOnHoverOptions = {
  autoScrollingBorderWidth: 40,
  scrollVelocity: 40
}

/**
 * calculates relative and absolute new scrolling coordinates depeding on the mouse position within a rectangle.
 * An inner border within the rectangle is the active zone within which auto scrolling occurrs. If the mouse position
 * is outside this zone, there will be no auto scrolling.
 * Scrolling is faster the closer the mouse is to the outer edge.
 */
export class AutoScrollOnHoverCalculator {

  constructor(private absolutePosition: { x: number, y: number, width: number, height: number }, private mousePosition: { x: number, y: number }, private options: AutoScrollOnHoverOptions = defaultOptions) {
  }

  // returns the distance to the nearest border:
  // undefined if not within the auto scrolling border
  // negative distance in pixels if near the first border
  // positive distance in pixcels if near the second border
  private calcDistanceToBorder(firstBorder: number, secondBorder: number, mousePosition: number): number {
    // check distance to first border
    let result = firstBorder - mousePosition;
    let isWithinBorder = result > -1 * this.options.autoScrollingBorderWidth && result < 0;
    if (!isWithinBorder) {
      // then check distance to second border
      result = secondBorder - mousePosition;
      isWithinBorder = result > 0 && result < this.options.autoScrollingBorderWidth;
      if (!isWithinBorder) {
        result = undefined;
      }
    }
    console.log("distance to border", {firstBorder, secondBorder, mousePosition, result});
    return result;
  }

  // return the scrolling distance in dependency of the distance to the border
  // the closer the higher the the scrolling distance
  private calcScrollDistance(distanceToBorder): number {
    const relativeProximity = Math.abs(distanceToBorder) / this.options.autoScrollingBorderWidth;
    return (relativeProximity - 1) * -1 * this.options.scrollVelocity * Math.sign(distanceToBorder);
  }


  public calculateOffsetScrollCoordinates(): ScrollCoordinates {
    const leftBorder = this.absolutePosition.x;
    const topBorder = this.absolutePosition.y;
    const bottomBorder = topBorder + this.absolutePosition.height;
    const rightBorder = leftBorder + this.absolutePosition.width;

    let scrollTopOffset;
    const verticalDistanceToBorder = this.calcDistanceToBorder(topBorder, bottomBorder, this.mousePosition.y)
    if (verticalDistanceToBorder) {
      scrollTopOffset = this.calcScrollDistance(verticalDistanceToBorder);
    }

    let scrollLeftOffset;
    const horizontalDistanceToBorder = this.calcDistanceToBorder(leftBorder, rightBorder, this.mousePosition.x)
    if (horizontalDistanceToBorder) {
      scrollLeftOffset = this.calcScrollDistance(horizontalDistanceToBorder);
    }

    let result;
    if (scrollTopOffset || scrollLeftOffset) {
      result = {
        scrollTop: scrollTopOffset || 0,
        scrollLeft: scrollLeftOffset || 0
      }
    }
    console.log("Auto Scrolling", {result, verticalDistanceToBorder, horizontalDistanceToBorder});

    return result;
  }

  calculateTargetScrollCoordinates(existingScrollCoordinates: ScrollCoordinates): ScrollCoordinates {
    let result;
    const scrollOffset = this.calculateOffsetScrollCoordinates();
    if (scrollOffset) {
      let scrollTop = Math.max(0, existingScrollCoordinates.scrollTop + scrollOffset.scrollTop);
      let scrollLeft = Math.max(0, existingScrollCoordinates.scrollLeft + scrollOffset.scrollLeft);
      result = {
        scrollTop,
        scrollLeft
      }
    }
    return result;
  }
}
