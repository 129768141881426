import {VisualValueChartElement} from "./VisualValueChartElement";
import {VisualBaseAttributeValue} from "../common/VisualBaseAttributeValue";
import {VisualAttributeDefinition} from "../common/VisualAttributeDefinition";

export class VisualValueChartAttributeValue extends VisualBaseAttributeValue {
  get dx(): number {
    return this.attributeDefinition.header.x;
  }

  get dy(): number {
    return this.attributeDefinition.header.y;
  }

  get x(): number {
    return this.attributeDefinition.header.x;
  }

  get y(): number {
    return this.visualElement.y;
  }

  get width(): number {
    return this.attributeDefinition.header.width;
  }

  get height(): number {
    return this.attributeDefinition.header.height;
  }

  constructor(visualAttributeDefinition: VisualAttributeDefinition, visualElement: VisualValueChartElement, value: string) {
    super(visualAttributeDefinition, visualElement, value);
  }
}