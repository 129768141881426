import {SelectionKind, VisualElementId} from "../../../core/utils/Core";
import {CSSProperties} from "react";
import {VisualValueChartLevel} from "./VisualValueChartLevel";
import {Point, Rect, Rectangle, Size} from "../../../common/utils/Geometry";
import {IBoundedVisual, VisualObject, VisualObjectBase} from "../VisualObject";
import {VisualBaseElement, VisualBaseTable} from "../common/CommonDiagramTypes";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {ElementStyleUtil, METUS_DEFAULT_STYLES} from "../../../commonviews/utils/ElementStyleUtil";
import {getLevelDefaultColor} from "../../../core/utils/LevelColorUtil";
import {computed, observable} from "mobx";
import {VisualBaseAttributeValue} from "../common/VisualBaseAttributeValue";
import {AdditionalPropArgs, identifier, PropSchema, serializable} from "serializr";
import {jsonObjectAsString} from "../../../common/utils/SerializrUtils";

const handleAfterDeserializeIdentifier: AdditionalPropArgs = {
  afterDeserialize: function (callback: (err: any, value: any) => void, error: any, newValue: any, jsonValue: any, jsonParentValue: any, propNameOrIndex: string | number | symbol, context: any, propDef: PropSchema): void {
    context.target.id = VisualElementId.fromKey(newValue);
  }
};

export class VisualValueChartElement extends VisualObjectBase<VisualValueChartElement, VisualElementId> implements VisualBaseElement, IBoundedVisual {
  @serializable(identifier(handleAfterDeserializeIdentifier)) public identifier: string;
  @serializable @observable public title: string;
  @serializable(jsonObjectAsString()) styles: CSSProperties;
  // TODO fix problem with self reference in serializable @serializable(list(object(VisualValueChartElement)))
  @observable protected _children: VisualValueChartElement[] = [];

  get children(): VisualValueChartElement[] {
    return this._children;
  }

  /* NOT SERIALIZABLE */
  @observable public id: VisualElementId;
  @observable public level: VisualValueChartLevel;
  @observable public selection: SelectionKind = SelectionKind.None;
  /** maps attribute name to its value */
  @observable public attributeValues: Map<string, VisualBaseAttributeValue>;
  @observable private readonly _relativeBounds: Rect;
  @observable public _visible: boolean;

  /**
   * create a new visual value chart element for the given level and maintain the bidirectional list i.e. add itself to given level
   * @param parent {VisualObject} parent of this visual element
   * @param {VisualElementId} id visual node id
   * @param {string} name chart title
   * @param {VisualValueChartLevel} level
   * @param {React.CSSProperties} styles
   */
  constructor(parent: VisualObject, id: VisualElementId, name: string, level: VisualValueChartLevel, styles: CSSProperties) {
    super(parent, id);
    this.identifier = id ? id.toKey() : undefined;
    this.title = name;
    this.styles = styles;
    this.level = level;
    this.attributeValues = new Map();
    this._visible = true;
    this._relativeBounds = {x: 0, y: 0, width: 0, height: 0};
    if (level) {
      level.elements.push(this);
    }
  }

  /**
   * @return true if this visual value chart element is *recursively* visible, so NOT state of set visible is returned, but visibility of whole parent chain
   */
  @computed get visible(): boolean {
    let result = this._visible;
    if (result && this.parent instanceof VisualValueChartElement) {
      result = result && this.parent.visible;
    }
    return result;
  }

  set visible(visible: boolean) {
    if (this._visible !== visible) {
      this._visible = visible;
    }
  }

  /**
   * bounds are relative to the parent element containers top left
   * @returns {Rectangle}
   */
  public get relativeBounds(): Rect {
    return this._relativeBounds;
  }

  @computed
  public get x(): number {
    return this.bounds.x;
  }

  @computed
  public get y(): number {
    return this.bounds.y;
  }

  public get width(): number {
    return this._relativeBounds.width;
  }

  public get height(): number {
    return this._relativeBounds.height;
  }

  public get relativeX(): number {
    return this._relativeBounds.x;
  }

  public get relativeY(): number {
    return this._relativeBounds.y;
  }

  @computed.struct get textStyles(): CSSProperties {
    return ElementStyleUtil.updateNodeTextStyle(this, METUS_DEFAULT_STYLES.visualElement);
  }

  @computed.struct get rectStyles(): CSSProperties {
    const defaultStyles = {
      ...METUS_DEFAULT_STYLES.visualElement,
      "fill": getLevelDefaultColor(this.level.index, this.level.levelCount)
    };
    return ElementStyleUtil.updateNodeRectStyle(this, defaultStyles);
  }

  get visualTable(): VisualBaseTable {
    return this.level;
  }

  /**
   * returns the bounds in absolute diagram (svg) coordinates
   * @returns {Point}
   */
  @computed.struct
  protected get internalBounds(): Rect {
    let {x, y} = {x: this.relativeBounds.x, y: this.relativeBounds.y};
    const parent = this.parent;
    if (parent instanceof VisualValueChartElement) {
      // add parent container offset, since position relative to parent container
      const cx = parent.level.contentDx;
      const cy = parent.level.contentDy + DiagramVisualConstants.VC_GAP + DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT;
      x = x + parent.bounds.x + cx;
      y = y + parent.bounds.y + cy;
    }
    return {x: x, y: y, width: this.relativeBounds.width, height: this.relativeBounds.height};
  }

  private get visibleElements(): VisualValueChartElement[] {
    return this.children.filter(c => {
      return c instanceof VisualValueChartElement && c.visible;
    }) as VisualValueChartElement[];
  }

  public computeSize(): Size {
    const g = DiagramVisualConstants.VC_GAP;
    const width: number = this.level.computeWidth();
    // start with space for element name without content box
    let height: number = g + DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT + this.level.contentDy + g;

    if (this.level.childLevel && this.visibleElements.length > 0) {
      const cl = this.level.childLevel;
      // calculate variable line heights
      let sumLines = 0;
      let maxLineHeight = 0;
      let lines = 0;
      this.visibleElements.forEach((child, idx) => {
        maxLineHeight = Math.max(maxLineHeight, child.computeSize().height);
        if (idx % cl.columns === cl.columns - 1) {
          // start new row, add max of current row to sum of line heights
          sumLines += maxLineHeight;
          maxLineHeight = 0;
          lines = lines + 1;
        }
      });
      // row was started but not ended
      if (maxLineHeight > 0) {
        sumLines += maxLineHeight;
        lines = lines + 1;
      }
      // add one more gap than lines
      const contentHeight = (lines + 1) * g + sumLines;
      height = height + this.level.contentDy + contentHeight + g;
    }
    return new Size(width, height);
  }

  /**
   * layout this value chart by assigning all elements bounds recursively, elements are positioned relative to their parent containers upper left corner
   */
  public layout(x: number, y: number): void {
    const size = this.computeSize();
    this._relativeBounds.x = x;
    this._relativeBounds.y = y;
    this._relativeBounds.width = size.width;
    this._relativeBounds.height = size.height;
    if (this.level.childLevel) {
      // recursively layout children
      const columns = this.level.childLevel.columns;
      let currentX = DiagramVisualConstants.VC_GAP;
      let currentY = DiagramVisualConstants.VC_GAP;
      let currentColumn = 0;
      let maxLineHeight = 0;
      this.visibleElements.forEach(ve => {
        ve.layout(currentX, currentY);
        const {width: childWidth, height: childHeight} = ve.computeSize();
        maxLineHeight = Math.max(maxLineHeight, childHeight);
        currentX += childWidth + DiagramVisualConstants.VC_GAP;
        currentColumn++;
        // line break
        if (currentColumn >= columns) {
          currentColumn = 0;
          currentY += maxLineHeight + DiagramVisualConstants.VC_GAP;
          currentX = DiagramVisualConstants.VC_GAP;
          maxLineHeight = 0;
        }
      });
    }
  }

  public sortChildren(compareFn: (a: VisualValueChartElement, b: VisualValueChartElement) => number): void {
    /**
     * Why is it necessary to clone the array?
     * -> OberservableArray cannot be sort in place
     */
    this._children = [...this._children].sort(compareFn);
  }
}
