
export class Validate {
  static isTrue(ex: boolean,  message: string = ""): void {
    if (!ex){
      throw Error("Validate.isTrue failed: " + message);
    }
  }

  //noinspection JSUnusedGlobalSymbols
  static notNull(ex: Object, message: string = ""): void {
    if (typeof ex === "undefined" || ex == null){
      throw Error("Validate.notNull failed: " + message);
    }
  }

  /**
   * throws exception if the given object is undefined or null
   * @param {Object} ex
   * @param {string} message
   */
  static isDefined(ex: Object, message: string = ""): void {
    if (typeof ex === "undefined" || ex === null) {
      throw Error("Validate.isDefined failed: " + message);
    }
  }

  static fail(message: string = ""): void {
    throw Error("Validate failed: " + message);
  }

}

export function predicate(s: any): any {
  return (_s: any): boolean => _s === s;
}
