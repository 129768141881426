import {ContextMenu, MenuItem} from "react-contextmenu";
import Portal from "@material-ui/core/Portal";
import * as React from "react";
import {RefObject} from "react";
import {ElementId, VisualTableId} from "../../core/utils/Core";
import {UpdateElementFilterAction} from "../actions/MatrixActions";
import {Dispatcher} from "../../common/utils/Dispatcher";
import autobind from "autobind-decorator";
import Log from "../../common/utils/Logger";
import {MatrixModel} from "../models/MatrixModel";
import {ViewContext, viewContextId} from "../../commonviews/contexts/ViewContext";
import {observer} from "mobx-react";
import {RemoveTableFromViewAction} from "../../commonviews/actions/SharedViewActions";
import {Target} from "../../common/constants/Enums";

interface ColumnTableHeaderMenuProps {
  matrix: MatrixModel;
  visualTableId: VisualTableId;
  viewContext: ViewContext;
}

const log = Log.logger("TableHeaderMenu");

/**
 * menu component for the context menu for matrix column tables, including remove and show/hide column elements
 */
@observer
export class ColumnTableHeaderMenu extends React.Component<ColumnTableHeaderMenuProps> {
  readonly svgRectRefs: RefObject<SVGRectElement>[] = [];

  constructor(props: ColumnTableHeaderMenuProps) {
    super(props);
  }

  render() {
    const visualTableId = this.props.visualTableId;
    const tableId = visualTableId.tableId;
    const contextMenuId = "cm_dg_TableHeaderComponent" + viewContextId(this.props.viewContext) + visualTableId.toKey();
    return <Portal key={visualTableId.toKey()}>
      <ContextMenu id={contextMenuId}
                   className={"metusContextMenu"}
                   key={contextMenuId}>
        <MenuItem preventClose={false} onClick={this.handleRemoveTable}
                  attributes={{className: "contextmenu-option--remove", "data-testselector": "RemoveInContextMenu"} as any}>RemoveTable</MenuItem>
      </ContextMenu>
    </Portal>;
  }

  @autobind
  private elementFilterChanged(visualTableId: VisualTableId, elementId: ElementId, ev: any, checked: boolean): void {
    log.debug("Show hide element", visualTableId, elementId, checked);
    const action = new UpdateElementFilterAction(this.props.matrix.id, visualTableId, checked ? "remove" : "add", elementId);
    Dispatcher.dispatch(action);
  }

  @autobind
  private handleRemoveTable(evt: any, data: { id: string }): void {
    log.debug("RemoveTable", data);
    const visualTableId = VisualTableId.fromKey(data.id);
    Dispatcher.dispatch(new RemoveTableFromViewAction(this.props.matrix.id, visualTableId, Target.COLUMN));
  }

}