import {Validate} from "./Validate";
import {Point, Rect, Rectangle, Size} from "./Geometry";


export class ZoomHelper {
  private readonly widthCm: number;
  private readonly heightCm: number;
  private readonly scale: number;
  /** padding around svg diagram to enabe moving elements outside of original bounds of container */
  private static PADDING_WIDTH: number = 50;

  constructor(scale: number, widthCm?: number, heightCm?: number){
    this.widthCm = widthCm || 20.0;
    this.heightCm = heightCm || 15.0;
    this.scale = scale;
  }

  public adaptRatio(width: number, height: number): {scaledX: number, scaledY: number} {
    // determine size which fits less into A4 landscape (20x15cm)
    const ratio = width / height;
    // scale x or y so that both fit into 20/15
    let scaledX: number = this.widthCm;
    let scaledY: number = this.heightCm;
    if (ratio >= this.widthCm / this.heightCm) {
      scaledY = this.widthCm / ratio;
    } else {
      scaledX = this.heightCm * ratio;
    }
    Validate.isTrue(scaledY <= this.heightCm +0.001 && scaledX <= this.widthCm + 0.001, "internal Scaling error");
    return {scaledX, scaledY};
  }

  public static determineZoomPoint(...rectangles: Rectangle[]): Point {
    return rectangles.length > 0 ? rectangles.reduce((acc, val) => acc.union(val), rectangles[0]).getMiddle() : new Point(0, 0);
  }

  /**
   * determine the svg viewbox from the diagram svg width/height and initial x/y offset to show negative coordinates too
   * @param scale
   * @param lastLeftTopPosition last left top position of view box in view box coordinates
   * @param clientWidth width of available client area to display
   * @param clientHeight height of available client area to display
   * @param svgRect svg content bounding box
   * @param currentScrollPosition current left top scroll position of div around svg
   * @returns {{viewBox: Rectangle, scrollPosition: Point, svgSize: Size}} current viewbox in svg coordinates, scrollPosition in screen coordinates, size in scaled svg coordinates
   */
  public static determineViewBox(scale: number, lastLeftTopPosition: Point, clientWidth: number, clientHeight: number, svgRect: Rect, currentScrollPosition: Point): { viewBox: Rectangle, scrollPosition: Point, svgSize: Size } {
    const scaledScrollPosition = currentScrollPosition.scale(1.0 / scale);
    const pad = this.PADDING_WIDTH / scale;
    const [dx, dy] = [svgRect.x - pad, svgRect.y - pad];
    const viewportWidth = Math.max(svgRect.width + pad * 2, clientWidth / scale);
    const viewportHeight = Math.max(svgRect.height + pad * 2, clientHeight / scale);
    let viewBox = Rectangle.from(dx, dy, viewportWidth, viewportHeight);
    let scrollPosition = scaledScrollPosition;
    // scroll offset to correct relative position if viewbox does not fit on screen
    if (lastLeftTopPosition != null) {
      const scaledLastLeftTopPosition = lastLeftTopPosition;
      scrollPosition = new Point(scaledScrollPosition.x + (scaledLastLeftTopPosition.x - viewBox.x), scaledScrollPosition.y + (scaledLastLeftTopPosition.y - viewBox.y));
    }
    // if scroll should be done, but not available because viewport is too small, enhance it
    if (viewBox.width - scrollPosition.x < clientWidth / scale) {
      viewBox = Rectangle.from(viewBox.x, viewBox.y, viewBox.width + scrollPosition.x, viewBox.height);
    }
    if (viewBox.height - scrollPosition.y < clientHeight / scale) {
      viewBox = Rectangle.from(viewBox.x, viewBox.y, viewBox.width, viewBox.height + scrollPosition.y);
    }
    if (scrollPosition.x < 0) {
      viewBox = Rectangle.from(viewBox.x + scrollPosition.x, viewBox.y, viewBox.width - scrollPosition.x, viewBox.height);
    }
    if (scrollPosition.y < 0) {
      viewBox = Rectangle.from(viewBox.x, viewBox.y + scrollPosition.y, viewBox.width, viewBox.height - scrollPosition.y);
    }

    const svgSize = new Size(viewBox.width * scale, viewBox.height * scale);
    const unscaledScrollPosition = scrollPosition.scale(scale);
    return {viewBox, scrollPosition: unscaledScrollPosition, svgSize};
  }

  public calculateNewScaleFromMouseDelta(mouseDelta: number): number {
    return this.scale - ((mouseDelta / 120) * 0.1) * this.scale;
  }

}

const ZOOM_FACTOR_MAX = 5;
const ZOOM_FACTOR_MIN = 0.1;

export function checkNewScale(newScale: number, currentScale: number): ZoomingStatus {
  const outOfLowerBounds = newScale <= ZOOM_FACTOR_MIN;
  const outOfUpperBounds = newScale >= ZOOM_FACTOR_MAX;
  const scale = (outOfLowerBounds === true || outOfUpperBounds === true) ? currentScale : newScale;
  return {scale, outOfLowerBounds, outOfUpperBounds}
}

export interface ZoomingStatus {
  scale: number;
  outOfLowerBounds?: boolean;
  outOfUpperBounds?: boolean;
}
