/**
 * @author Marco van Meegen
 */
import * as React from "react";
import {ChangeEvent} from "react";
import * as ReactDnD from "react-dnd";
import {MatrixDisplayMode, MatrixModel} from "../models/MatrixModel";
import {ElementId, ViewId, VisualAttributeId, VisualTableId} from "../../core/utils/Core";
import {observer} from "mobx-react";
import Portal from "@material-ui/core/Portal";
import {ContextMenu, MenuItem as ContextMenuItem} from "react-contextmenu";
import {Dispatcher} from "../../common/utils/Dispatcher";
import autobind from "autobind-decorator";
import {UpdateDisplayModeAction, UpdateElementFilterAction} from "../actions/MatrixActions";
import Log from "../../common/utils/Logger";
import {RemoveAttributeFromViewAction, RemoveTableFromViewAction} from "../../commonviews/actions/SharedViewActions";
import {TableHierarchyHeaderComponent} from "../../commonviews/components/TableHierarchyHeaderComponentNoDnd";
import {ColumnTableHeaderMenu} from "./ColumnTableHeaderMenu";
import {DiagramVisualConstants} from "../../commonviews/constants/DiagramVisualConstants";
import {SVGEmbeddedIcons} from "../../common/components/SVGEmbeddedIcons";
import {ViewContext, viewContextId} from "../../commonviews/contexts/ViewContext";
import {identity} from "../../common/utils/FunctionUtil";
import {AttributeSelectionSubMenuComponent} from "../../commonviews/components/AttributeSelectionSubMenuComponent";
import AddRowTableHeaderIcon from "../icons/AddRowTableHeaderIcon";
import AddColumnTableHeaderIcon from "../icons/AddColumnTableHeaderIcon";
import AddCellContentHeaderIcon from "../icons/AddCellContentHeaderIcon";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import {TableHierarchyHeaderModel} from "../../commonviews/models/TableHierarchyHeaderModel";
import {Target, ViewType} from "../../common/constants/Enums";
import {JoinTableSectionHeaderComponent} from "./JoinTableSectionHeaderComponent";
import {modelStore} from "../../core/stores/ModelStore";
import DropDownComponent from "../../common/components/materialuiderived/DropDownComponent";
import DropDownItemComponent from "../../common/components/materialuiderived/DropDownItemComponent";
import {addAttributeToMatrix} from "../actions/MatrixAsyncActionCreators";

const log = Log.logger("MatrixComponent");


export interface MatrixHeaderComponentProps {
  matrix: MatrixModel;
  viewContext: ViewContext;
  connectDropTarget?: ReactDnD.ConnectDropTarget;
}

const styles = (theme: Theme): StyleRules => createStyles({
  sections: {
    "borderBottom": "1px solid #A5A5A5",
    width: "100%",
    overflow: "auto",
    "& > div": {
      margin: "5px"
    }
  },
});

type StyledLocalProps = MatrixHeaderComponentProps & WithStyles<typeof styles>;

/**
 * displays the matrix header consisting of row configuration area, cell configuration area and column configuration area.
 * Height of a hierarchy will be dynamically calculated and adjusted using the layout on the TableHierarchyHeaderModel,
 * everything else is laid out using flexbox and divs.
 * @author Marco van Meegen
 */
@observer
export class MatrixHeaderComponentNoDnd extends React.Component<StyledLocalProps> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): React.ReactNode {
    log.debug("Rendering MatrixHeaderComponentNoDnd:", this.props);
    const columnHierarchyHeaderModel = this.props.matrix.columnHierarchyHeaderModel;
    const rowHierarchyHeaderModel = this.props.matrix.rowHierarchyHeaderModel;
    // min height is determined by cell configuration section
    const minHeight = this.props.matrix.viewType === ViewType.ChainMatrix ? DiagramVisualConstants.TABLE_HEADER_HEIGHT_NO_FILTER : DiagramVisualConstants.TABLE_HEADER_HEIGHT_NO_ATTRIBUTES;
    // height is max of section's heights
    const headerHeight = Math.max(minHeight, Math.max(columnHierarchyHeaderModel.height, rowHierarchyHeaderModel.height));
    const connectDropTarget = this.props.connectDropTarget || identity;

    const rowHierarchyHeader = this.renderRowHierarchyHeader(rowHierarchyHeaderModel, headerHeight);
    // matrix has to additional sections for cell configuration and column hierarchy
    let matrixHeader = null;
    if (this.props.matrix.viewType === ViewType.Matrix || this.props.matrix.viewType === ViewType.ChainMatrix) {
      const separatorStyle = {borderLeft: "1px solid #A5A5A5"};
      matrixHeader = <>
        {<div key="sep1" style={separatorStyle}/>}
        {this.renderCellConfigurationHeader(headerHeight)}
        {this.props.matrix.hasColumnHierarchy && <div key="sep2" style={separatorStyle}/>}
        {this.props.matrix.hasColumnHierarchy && this.renderColumnHierarchyHeader(columnHierarchyHeaderModel, headerHeight)}
      </>;
    }

    return connectDropTarget(<div
        style={{display: "flex", padding: 3}} className={this.props.classes.sections}>
      {rowHierarchyHeader}
      {matrixHeader}
    </div>);
  }

  private renderCellConfigurationHeader(headerHeight: number): React.ReactNode {
    const cellConfiguration = this.props.matrix.cellConfiguration;
    const joinTableName: string = cellConfiguration.joinTableId ? modelStore.getTableName(cellConfiguration.joinTableId.tableId) : undefined;
    return <div style={{display: "flex"}}>
      <AddCellContentHeaderIcon/>
      {this.props.matrix.viewType === ViewType.ChainMatrix ?
          <JoinTableSectionHeaderComponent viewContext={this.props.viewContext}
                                           joinTableId={cellConfiguration.joinTableId}
                                           joinTableAttributeName={cellConfiguration.joinTableAttributeName}
                                           title={joinTableName}
                                           height={headerHeight}
                                           width={DiagramVisualConstants.MINIMUM_TABLE_WIDTH}/>
          : this.renderStandardHeaderContent()}
    </div>;
  }

  private renderRowHierarchyHeader(rowHierarchyHeaderModel: TableHierarchyHeaderModel, headerHeight: number) {
    return <div key="rows" style={{display: "flex"}}>
      <AddRowTableHeaderIcon/>
      <svg style={{height: headerHeight, width: rowHierarchyHeaderModel.width}}>
        <SVGEmbeddedIcons/>
        <TableHierarchyHeaderComponent viewContext={this.props.viewContext} viewModel={rowHierarchyHeaderModel}
                                       isColumnHierarchy={false}/>
      </svg>
      {this.renderRowHeaderContextMenus()}
    </div>;
  }

  private renderColumnHierarchyHeader(columnHierarchyHeaderModel: TableHierarchyHeaderModel, headerHeight: number): React.ReactNode {
    let columnHierarchyHeader = null;
    if (this.props.matrix.hasColumnHierarchy) {
      columnHierarchyHeader = <div key="columns" style={{display: "flex"}}>
        <AddColumnTableHeaderIcon/>
        <svg style={{height: headerHeight, width: columnHierarchyHeaderModel.width}}>
          <SVGEmbeddedIcons/>
          <TableHierarchyHeaderComponent viewContext={this.props.viewContext} viewModel={columnHierarchyHeaderModel}
                                         isColumnHierarchy={true}/>
          {this.renderColumnHeaderContextMenus()}
        </svg>
      </div>;
    }
    return columnHierarchyHeader;
  }

  private renderColumnHeaderContextMenus(): React.ReactNode {
    return this.props.matrix.columnHierarchy.tables.map((visualTableId: VisualTableId) => {
      return <ColumnTableHeaderMenu key={visualTableId.toKey()} matrix={this.props.matrix} visualTableId={visualTableId}
                                    viewContext={this.props.viewContext}/>;
    });
  }

  @autobind
  private handleStandardMatrixDisplayModeChange(event: ChangeEvent<{ value: string }>): void {
    log.debug("Setting new mode for matrix", event);
    const newMode = event.target.value as MatrixDisplayMode;
    const matrixId = this.props.matrix.id;
    Dispatcher.dispatch(new UpdateDisplayModeAction(matrixId, newMode));
  }

  private renderStandardHeaderContent(): React.ReactNode {
    // variant property of Select works only after version 4.4.3 or so of material-ui, see
    // https://github.com/mui-org/material-ui/pull/17304, therefore using TextField
    const selectModeEntries: { value: MatrixDisplayMode, displayText: string }[] = [
      {value: "Decimal", displayText: "Connection as Decimal"},
      {value: "PercentAndGradient", displayText: "Connection as Filled Area in %"},
      {value: "Percent", displayText: "Connection as %"},
      {value: "Bubble", displayText: "Connection as Dots"},
    ];


    return <DropDownComponent
        value={this.props.matrix.displayMode}
        onChange={this.handleStandardMatrixDisplayModeChange} data-testselector="CellMode" variant="outlined">
      {
        selectModeEntries.map((entry) => <DropDownItemComponent key={entry.value}
                                                                value={entry.value}>{entry.displayText}</DropDownItemComponent>)
      }
    </DropDownComponent>
  }

  private renderRowHeaderContextMenus() {
    return this.props.matrix.rowHierarchy.tables.map((visualTableId: VisualTableId) => {
      const contextMenuId = "cm_dg_TableHeaderComponent" + viewContextId(this.props.viewContext) + visualTableId.toKey();
      const visualAttributeDefinitionIds = this.props.matrix.rowHierarchy.attributeIds.map(attId => new VisualAttributeId(visualTableId, attId.attributeName));
      return <Portal key={visualTableId.toKey()}>
        <ContextMenu id={contextMenuId}
                     className={"metusContextMenu"}
                     key={contextMenuId}>
          {this.props.viewContext.viewType !== ViewType.Table ?
              <ContextMenuItem preventClose={false} onClick={this.handleRemoveTable}
                               attributes={{className: "contextmenu-option--remove"} as any}>RemoveTable</ContextMenuItem> : undefined}
          <AttributeSelectionSubMenuComponent
              viewId={this.props.matrix.id}
              visualTableId={visualTableId}
              visualAttributeDefinitionIds={visualAttributeDefinitionIds}
              onAddAttribute={this.handleAddAttributeToView}
              onRemoveAttribute={this.handleRemoveAttributeFromView}
          />
        </ContextMenu>
      </Portal>;
    });
  }

  private elementFilterChanged(visualTableId: VisualTableId, elementId: ElementId, ev: any, checked: boolean): void {
    log.debug("Show hide element", visualTableId, elementId, checked);
    const action = new UpdateElementFilterAction(this.props.viewContext.viewId, visualTableId, checked ? "remove" : "add", elementId);
    Dispatcher.dispatch(action);
  }

  @autobind
  private handleRemoveTable(evt: any, data: { id: string }): void {
    log.debug("RemoveTable", data);
    const visualTableId = VisualTableId.fromKey(data.id);
    Dispatcher.dispatch(new RemoveTableFromViewAction(this.props.viewContext.viewId, visualTableId, Target.ROW));
  }

  @autobind handleRemoveAttribute(evt: any, data: { id: string }, target: any): void {
    this.handleRemoveAttributeFromView(this.props.matrix.id, VisualAttributeId.fromKey(data.id));
  }

  @autobind
  private handleAddAttributeToView(viewId: ViewId, visualAttributeId: VisualAttributeId): void {
    addAttributeToMatrix(viewId, visualAttributeId, {x: 0.0, y: 0.0}, false, true);
  }

  @autobind
  private handleRemoveAttributeFromView(viewId: ViewId, visualAttributeId: VisualAttributeId): void {
    log.debug("Remove attribute viewId: " + JSON.stringify(viewId) + ", visualAttributeId: " + JSON.stringify(visualAttributeId));
    Dispatcher.dispatch(new RemoveAttributeFromViewAction(viewId, visualAttributeId, undefined, false));
  }

}

export const MatrixHeaderComponent = withStyles(styles)(MatrixHeaderComponentNoDnd);
