/* ColorPickerDialog.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, November 2019
 */
import * as React from "react";
import {createStyles, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import MetusDialog from "../../common/components/MetusDialog";
import autobind from "autobind-decorator";
import CirclePicker from "react-color/lib/components/circle/Circle";
import {ColorResult} from "react-color";
import {PastelColors, SignalColors} from "../constants/Enums";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ColorWheelIcon from "./icons/ColorWheelIcon";
import ChromePicker from "react-color/lib/components/chrome/Chrome";
import HuePicker from "react-color/lib/components/hue/Hue";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import theme from "../theme/Theme";
import MetusTextField from "./MetusTextField";
import {ValidationResult} from "../utils/CommonDialogUtil";

export enum PickerType {Simple, Detail}

const PREFIX = "#";
const pastelColors: string[] = Object.keys(PastelColors).map(key => PastelColors[key]);
const signalColors: string[] = Object.keys(SignalColors).map(key => SignalColors[key]);

interface LocalProps {
  open: boolean;
  onChangeComplete: (color: string) => void;
  preSelection?: string;
}

interface LocalState {
  pickerType: PickerType;
  selectedPastelColor: string;
  selectedSignalColor: string;
  hexCode: string;
  latestUserInput: string;
  validationResult: ValidationResult;
}

const styles = (theme: Theme): StyleRules => createStyles({
  blankLine: {
    height: "5px",
    backgroundColor: "black"
  },
  pickerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  simplePicker1: {
    paddingBottom: "15px",
  },
  simplePicker2: {
    display: "flex",
    paddingTop: "15px",
  },
  colorWheelIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingLeft: "30px",
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  chromePicker: {
    paddingTop: "16px",
    height: "100px",
    alignSelf: "center",
    width: "250px",
  },
  hexCode: {
    paddingTop: "16px",
  },
  colorRectangle: {
    alignSelf: "center",
    width: 20,
    height: 20,
    border: "1px solid rgba(0, 0, 0, .2)",
  },
  divider: {
    height: "1.5px",
    backgroundColor: "rgba(0, 0, 0, 0.54)",
  },
});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;


export class ColorPickerDialog extends React.Component<StyledLocalProps, LocalState> {
  private initialState: LocalState = {
    pickerType: PickerType.Simple,
    selectedPastelColor: undefined,
    selectedSignalColor: undefined,
    hexCode: undefined,
    latestUserInput: undefined,
    validationResult: {isError: false, errorMessage: undefined},
  };

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = this.initialState;
  }

  render(): JSX.Element {
    const {classes} = this.props;
    let aux1ButtonCallback: () => void;
    let content: JSX.Element;

    if (this.state.pickerType === PickerType.Simple) {
      content = this.getSimplePicker();
      aux1ButtonCallback = this.handleCancel;
    } else {
      content = this.getDetailPicker();
      aux1ButtonCallback = this.switchToSimplePicker;
    }

    return <MetusDialog
        data-testselector={"ColorPickerDialog"}
        title="Select color"
        open={this.props.open}
        onClose={this.handleCancel}
        primaryButtonName={"OK"}
        onPrimaryButtonPressed={this.handleSubmit}
        aux1ButtonName={this.state.pickerType === PickerType.Simple ? "Cancel" : "Back"}
        onAux1ButtonPressed={aux1ButtonCallback}
        errorMessage={this.state.validationResult.errorMessage}>
      <div className={classes.pickerContainer}>{content}</div>
    </MetusDialog>;
  }

  private getSimplePicker(): JSX.Element {
    const {classes} = this.props;
    const circleSize = 40;
    const circleSpacing = 30;
    const pickerWidth = "280px";

    return <React.Fragment>
      <div className={classes.simplePicker1}>
        <CirclePicker color={this.state.selectedPastelColor}
                      colors={pastelColors}
                      circleSize={circleSize}
                      circleSpacing={circleSpacing}
                      width={pickerWidth}
                      onChange={this.handlePastelColorClick}/>
      </div>

      <Divider variant="fullWidth" classes={{root: classes.divider}}/>

      <div className={classes.simplePicker2}>
        <CirclePicker color={this.state.selectedSignalColor}
                      colors={signalColors}
                      circleSize={circleSize}
                      circleSpacing={circleSpacing}
                      width={"210px"}
                      onChange={this.handleSignalColorClick}/>
        <IconButton onClick={this.switchToDetailPicker} className={classes.colorWheelIcon}
                    data-testselector="ColorWheelButton">
          <ColorWheelIcon style={{fontSize: 40}}/>
        </IconButton>
      </div>
    </React.Fragment>;
  }

  private getDetailPicker(): JSX.Element {
    const {classes} = this.props;
    const backgroundColor = this.state.hexCode ? this.state.hexCode : "white";

    return <React.Fragment>
      <ChromePicker color={this.state.hexCode}
                    onChange={this.handlePickerChange}
                    styles={{
                      default: {
                        picker: {
                          boxShadow: "none",
                          fontFamily: theme.metus.main.fontFamily,
                          alignSelf: "center",
                          width: "250px"
                        },
                        body: {display: "none"}
                      }
                    }}
      />

      <div className={classes.chromePicker}>
        <HuePicker height={"17px"} width={"auto"} color={this.state.hexCode} onChange={this.handlePickerChange}/>
        <div className={classes.hexCode}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <div className={classes.colorRectangle} style={{backgroundColor}}/>
            </Grid>
            <Grid item>
              <MetusTextField
                  id="hex-code"
                  data-testselector="hex-code-input-field"
                  value={this.hexCodeDisplayValue()}
                  onChange={this.handleHexCodeChange}
                  error={this.state.validationResult.isError}
                  fullWidth={true}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><Typography
                        style={{fontSize: "16px", fontWeight: "normal"}}>{PREFIX}</Typography></InputAdornment>,
                  }}
                  inputProps={{maxLength: "6"}}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>;
  }

  @autobind
  private handlePastelColorClick(colorResult: ColorResult): void {
    this.setState(prevState => ({
      hexCode: colorResult.hex,
      selectedPastelColor: colorResult.hex,
      selectedSignalColor: undefined,
      validationResult: {isError: false, errorMessage: undefined},
    }));
  }

  @autobind
  private handleSignalColorClick(colorResult: ColorResult): void {
    this.setState(prevState => ({
      hexCode: colorResult.hex,
      selectedSignalColor: colorResult.hex,
      selectedPastelColor: undefined,
      validationResult: {isError: false, errorMessage: undefined},
    }));
  }

  @autobind
  private handlePickerChange(colorResult: ColorResult): void {
    this.setState(prevState => ({
      hexCode: colorResult.hex,
      latestUserInput: colorResult.hex,
      selectedSignalColor: undefined,
      selectedPastelColor: undefined,
      validationResult: {isError: false, errorMessage: undefined},
    }));
  }

  @autobind
  private handleHexCodeChange(event: any): void {
    const latestUserInput = event.target.value;
    let hexCode: string;
    if (latestUserInput) {
      hexCode = this.addPrefixToUserInput(latestUserInput);
    }

    const result = ColorPickerDialog.validateHexCode(hexCode);
    if (result.isError) {
      hexCode = undefined;
    } else {
      this.setState(prevState => ({validationResult: {isError: false, errorMessage: undefined}}));
    }

    this.setState(prevState => ({hexCode, latestUserInput}));
  }

  @autobind
  private handleSubmit(): void {
    let hexCodeToValidate;
    if (this.state.pickerType === PickerType.Detail) {
      if (this.state.latestUserInput) {
        if (this.state.latestUserInput.startsWith(PREFIX)) {
          hexCodeToValidate = this.state.latestUserInput;
        } else {
          hexCodeToValidate = this.addPrefixToUserInput(this.state.latestUserInput);
        }
      } else {
        hexCodeToValidate = undefined;
      }
    } else {
      hexCodeToValidate = this.state.hexCode;
    }

    const result = ColorPickerDialog.validateHexCode(hexCodeToValidate, this.state.pickerType);

    if (!result.isError) {
      this.resetState();
      this.props.onChangeComplete(this.state.hexCode);
    } else {
      this.setState(prevState => ({
        validationResult: result,
      }));
    }
  }

  @autobind
  private switchToDetailPicker(): void {
    this.setState(prevState => ({pickerType: PickerType.Detail}));
  }

  @autobind
  private switchToSimplePicker(): void {
    this.resetState();
  }

  @autobind
  private handleCancel(): void {
    this.resetState();
    this.props.onChangeComplete(undefined);
  }

  @autobind
  private resetState(): void {
    this.setState(prevState => this.initialState);
  }

  private hexCodeDisplayValue(): string {
    let retVal: string;
    if (this.state.hexCode) {
      retVal = this.state.hexCode.substring(1, this.state.hexCode.length);
    }
    return retVal;
  }

  private addPrefixToUserInput(input: string): string {
    return PREFIX + input;
  }

  public static validateHexCode(hexCode: string, pickerType?: PickerType): ValidationResult {
    let retVal: ValidationResult = {isError: false};

    if (!hexCode) {
      retVal.isError = true;
      retVal.errorMessage = pickerType === PickerType.Detail ? "Please enter a hex code." : "Please select a color.";
    } else {
      const regex = new RegExp(/^#([0-9A-F]{3}){1,2}$/i);
      if (!regex.test(hexCode)) {
        retVal.isError = true;
        retVal.errorMessage = "Invalid hex code.";
      }
    }

    return retVal;
  }

}

export default withStyles(styles)(ColorPickerDialog);
