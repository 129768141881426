/* HandleComponent.<extension>
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by MeegenM, Juni 2017
 */

import * as React from "react";
import {Direction, DirectionHelper} from "../../../common/utils/Direction";
import DragTypes from "../../../common/constants/DragTypes";
import {ConnectDragPreview, ConnectDragSource, DragSource, DragSourceMonitor, DragSourceSpec} from "react-dnd";
import {getEmptyImage} from "../../../common/utils/ImageUtil";
import {IBoundedVisual} from "../../models/VisualObject";
import {observer} from "mobx-react";
import Log from "../../../common/utils/Logger";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {EnumValues} from "enum-values";
import {isVisualIdObject, VisualAttributeId, VisualElementId, VisualId, VisualTableId} from "../../../core/utils/Core";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

export type HandleCallback = (direction: Direction, dx: number, dy: number) => void;

/** customizes drag handle active area by defining coordinates along the direction where it will be active; drag handle will be shown in the middle between x1, x2 */
export interface CustomizedHandleInfo {
  /**
   * substitute y,height along the direction axis (NORTH/SOUTH = x and width, EAST/WEST = y and height) where the handle will get active; handle itself is drawn in the middle y+height/2
   */
  y: number;
  height: number;
}

export interface HandleItem {
  id: string;
  direction: Direction;
  visualRect: IBoundedVisual;
  /** scale of current editor, drag layer needs this for positioning */
  scale: number;
}

interface LocalHandleProps {
  /** direction in which handle is positioned on boundary of visualRect */
  direction: Direction;
  /** visual element which is decorated by handle */
  visualRect: IBoundedVisual;
  /** callback called after handle was dragged an dropped */
  callback: HandleCallback;

  /**
   * info on customizing the standard handle
   */
  customizedHandleInfo: CustomizedHandleInfo;

  /** scale of current editor, drag layer needs this for positioning */
  scale: number;

  /** injected react-dnd monitor properties */
  connectDragSource?: ConnectDragSource;
  connectDragPreview?: ConnectDragPreview;
  isDragging?: boolean;
}

const handleSource: DragSourceSpec<LocalHandleProps, HandleItem> = {
  beginDrag(props: LocalHandleProps): HandleItem {
    return {
      id: "HANDLE",
      direction: props.direction,
      visualRect: props.visualRect,
      scale: props.scale
    };
  },
  endDrag(props: LocalHandleProps, monitor: DragSourceMonitor, component: Handle): any {
    if (!monitor.didDrop()) {
      return;
    }

    // When dropped on a compatible target, do something
    const item: any = monitor.getDropResult();
    if ("dx" in item && "dy" in item) {
      component.props.callback(component.props.direction, item.dx / props.scale, item.dy / props.scale);
    } else {
      log.warn("No targetPosition in DropResult");
    }
  }
};

@observer
class Handle extends React.Component<LocalHandleProps, any> {
  constructor(props: LocalHandleProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering Handle");
    const hr = DiagramVisualConstants.HANDLE_RADIUS;
    let handle;
    const offset = DirectionHelper.getHandleOffsetInner(this.props.direction, hr);
    if (!this.props.customizedHandleInfo) {
      const v = this.props.visualRect;
      const handleCenter = DirectionHelper.getHandleCenterPosition(this.props.direction, v.x, v.y, v.width, v.height);
      handle = <rect key={this.props.direction}
                     data-testselector={Direction[this.props.direction]}
                     x={handleCenter.x + offset.x} y={handleCenter.y + offset.y}
                     width={2 * hr} height={2 * hr} className="handle"/>;
    } else {
      // create a group with handle and bigger invisible activity rect and make the whole group use over css class
      const v = {...this.props.visualRect, ...this.props.customizedHandleInfo};
      const handleCenter = DirectionHelper.getHandleCenterPosition(this.props.direction, v.x, v.y, v.width, v.height);
      // TODO: make NORTH and SOUTH customizable too, right now only the height and y are customizable,
      //  in order to customize NORTH and SOUTH you need to be able to adjust width and x
      handle = <g className="handle">
        <rect key={this.props.direction} data-testselector={Direction[this.props.direction]}
              x={handleCenter.x + offset.x} y={handleCenter.y + offset.y} width={2 * hr} height={2 * hr}/>
        <rect key={this.props.direction + "a"} x={handleCenter.x + offset.x} y={v.y + offset.y} width={2 * hr}
              height={v.height} className="clickableAreaFiller"/>
      </g>;
    }
    return this.props.connectDragSource(handle);
  }

  componentDidMount(): void {
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    this.props.connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true
    });
  }
}

export const DndHandle = DragSource<LocalHandleProps>(DragTypes.RESIZE_HANDLE, handleSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
}))(Handle);


class LocalProps {
  visualId: VisualId | VisualTableId | VisualAttributeId | VisualElementId;
  visualModel: IBoundedVisual;
  scale: number;
  defaultCallback?: HandleCallback;
//noinspection JSUnusedGlobalSymbols
  NORTH?: HandleCallback;
  EAST?: HandleCallback;
  WEST?: HandleCallback;
//noinspection JSUnusedGlobalSymbols
  SOUTH?: HandleCallback;
//noinspection JSUnusedGlobalSymbols
  NORTHEAST?: HandleCallback;
//noinspection JSUnusedGlobalSymbols
  SOUTHEAST?: HandleCallback;
//noinspection JSUnusedGlobalSymbols
  NORTHWEST?: HandleCallback;
//noinspection JSUnusedGlobalSymbols
  SOUTHWEST?: HandleCallback;

  // handles might be modified from standard selectable area by passing these props
  // x1,x2 are the coordinates on the objects border from where to where the handle will get visible on hover;
  // the actually drawn handle will always be the same
  customizedHandleInfo?: { [K in keyof typeof Direction]?: CustomizedHandleInfo };
}

/**
 * Provides an IBoundedVisualElement with drag handles in some or all compass directions.
 * It must be defined, how handles come into place, eg. by selecting stuff or hovering but what with touch at hovering ?
 * Since mouse capture is difficult, another way must be found to capture the mouse during drag e.g. use React-DnD
 */
@observer
export class HandleComponent extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }


  render(): JSX.Element {
    renderLog.debug("Rendering HandleComponent");
    const handles: JSX.Element[] = [];
    const vm = this.props.visualModel;
    const visualIdKey = isVisualIdObject(this.props.visualId) ? this.props.visualId.toKey() : this.props.visualId;
    EnumValues.getNames(Direction).forEach((key) => {
      // Object.key iterates over an Enumn iterates over string and number values of that Enum
      // That's why we filter all keys smaller than 2 which represent the number values
      if (this.props[key] || this.props.defaultCallback) {
        const callback = this.props[key] ? this.props[key] : this.props.defaultCallback;
        const handleProps = this.props.customizedHandleInfo ? this.props.customizedHandleInfo[key] : undefined;
        handles.push(<DndHandle visualRect={vm} key={key} direction={Direction[key]} customizedHandleInfo={handleProps}
                                callback={callback}
                                scale={this.props.scale}/>);
      }
    });
    return <g data-handleid={visualIdKey}>{handles}</g>;
  }
}