import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {Direction} from "../../../common/utils/Direction";
import {VisualValueChartLevel} from "./VisualValueChartLevel";
import {TableId, VisualAttributeId, VisualTableId} from "../../../core/utils/Core";
import {observable} from "mobx";
import {VisualAttributeDefinition} from "../common/VisualAttributeDefinition";
import {modelStore} from "../../../core/stores/ModelStore";
import shallowEqual from "shallowequal";
import {Validate} from "../../../common/utils/Validate";

export class VisualValueChartMetadata {
  /** uppermost level, undefined if no level available */
  @observable private _rootLevel: VisualValueChartLevel;
  /** leaf level, might be = root level if only one level defined */
  @observable private _leafLevel: VisualValueChartLevel;

  constructor() {
  }

  /**
   * @returns {VisualValueChartLevel} topmost level, undefined if no levels defined
   */
  get rootLevel(): VisualValueChartLevel {
    return this._rootLevel;
  }

  set rootLevel(level: VisualValueChartLevel) {
    this._rootLevel = level;
  }

  /**
   * @returns {VisualValueChartLevel} bottommost level,  for which elements have no children , undefined if no levels defined, same as root level if only one level defined
   */
  get leafLevel(): VisualValueChartLevel {
    return this._leafLevel;
  }

  set leafLevel(level: VisualValueChartLevel) {
    this._leafLevel = level;
  }

  public layout(isReorderLevel: boolean = false): void {
    const totalHeight = this.headerHeight;
    let headerX: number = 0;
    let headerY: number = 0;
    const ATTRIBUTE_HEADER_START_POSITION = DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH;
    this.levels.forEach(level => {
      level.header.x = headerX;
      level.header.y = headerY;
      level.header.height = totalHeight - headerY;
      headerX += DiagramVisualConstants.VC_LEVEL_INSET + DiagramVisualConstants.VC_GAP;
      headerY += level.levelHeaderHeightNotIncludingNested;
      level.visualAttributeDefinitions.forEach(vad => vad.header.y = ATTRIBUTE_HEADER_START_POSITION);
    });

    // layout width from bottom to top level to accurately reflect changes (add/remove table, change columns)
    let currentLevel = this._leafLevel;
    let i = 0;
    while (currentLevel) {
      const width = i === 0 && isReorderLevel ? currentLevel.header.width + DiagramVisualConstants.VC_LEVEL_INSET / 2 : currentLevel.header.width;
      currentLevel.attributeColumnLayout.resizeContainer(Direction.EAST, currentLevel.computeWidth() - width);
      currentLevel = currentLevel.parentLevel;
      i++;
    }
  }

  get headerHeight(): number {
    return this.levels.reduce((sum, level) => level.levelHeaderHeightNotIncludingNested + sum, DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC);
  }

  get levels(): VisualValueChartLevel[] {
    const result = [];
    let level = this._leafLevel;
    while (level) {
      result.unshift(level);
      level = level.parentLevel;
    }
    return result;
  }

  get levelCount(): number {
    return this.levels.length;
  }

  public levelsForTable(id: TableId): VisualValueChartLevel[] {
    return this.levels.filter(vclevel => vclevel.tableId === id);
  }

  /**
   * @param {VisualTableId} visualTableId remove table with given visual id, throws error if not found
   * @returns true if the root level was removed
   */
  public removeLevel(visualTableId: VisualTableId): VisualValueChartLevel {
    const result = this.getLevel(visualTableId);
    this.removeLevelFromLinkedList(visualTableId);
    // if root level was removed set column count to 1, since root level column count cannot change
    if (this._rootLevel) {
      this._rootLevel.columns = 1;
    }
    this.layout();
    return result;
  }

  /**
   * unlinks a level in the level linked list updating root and leaf pointers
   * @param visualTableId
   */
  private removeLevelFromLinkedList(visualTableId: VisualTableId) {
    let result: boolean = false;
    const level = this.getLevel(visualTableId);
    if (level) {
      const childLevel = level.childLevel;
      // found level, remove from parent chain
      if (childLevel) {
        // set parent from childLevel to this levels parent
        childLevel.parentLevel = level.parentLevel;
      } else {
        // level was leaf level, thus update
        this._leafLevel = level.parentLevel;
      }
      // set parents child to childLevel
      if (level.parentLevel) {
        level.parentLevel.childLevel = childLevel;
      } else {
        // level was root level, thus update
        this._rootLevel = childLevel;
        result = true;
      }
    } else {
      throw Error("Could not remove table from value chart with visual id " + visualTableId.toKey());
    }
    return result;
  }

  /**
   * inserts the level before another level
   * @param levelToInsert level which will be inserted before the level insertBefore
   * @param insertBefore level before which the level should be inserted, insert at leaf level if insertBefore is undefined
   */
  private insertLevelBefore(levelToInsert: VisualValueChartLevel, insertBefore: VisualValueChartLevel): void {
    const levelBefore = insertBefore ? insertBefore.parentLevel : this._leafLevel;
    const levelAfter = insertBefore;
    levelToInsert.parentLevel = levelBefore;
    if (levelBefore) {
      levelBefore.childLevel = levelToInsert;
    } else {
      // no levelBefore, thus new root
      this._rootLevel = levelToInsert;
    }
    levelToInsert.childLevel = levelAfter;
    if (levelAfter) {
      levelAfter.parentLevel = levelToInsert;
    } else {
      // no level after, thus new leaf
      this._leafLevel = levelToInsert;
    }
  }

  public createAndAddLevel(
      visualTableId: VisualTableId,
      tableName: string,
      columns: number,
      parentLevel: VisualValueChartLevel = undefined,
      isFilterVisible: boolean = false,
      isAttributeVisible: boolean = true): VisualValueChartLevel {

    const newLevel: VisualValueChartLevel = new VisualValueChartLevel(this, visualTableId, tableName, columns, parentLevel, isFilterVisible, isAttributeVisible);

    /* Set leaf level */
    this._leafLevel = newLevel;

    /* Set root level if not happened. */
    if (!this._rootLevel) {
      this._rootLevel = newLevel;
    }

    this.layout();

    return newLevel;
  }

  public addTable(visualTableId: VisualTableId, tableName: string): VisualValueChartLevel {
    const parentLevel = this.leafLevel;
    // default: 1 column for first level, 2 for others
    const columns = parentLevel ? 2 : 1;
    const result = this.createAndAddLevel(visualTableId, tableName, columns, parentLevel);
    return result;
  }

  /**
   *
   * @param {VisualTableId} visualTableId
   * @returns {VisualValueChartLevel} level with this visual id, undefined if not found
   */
  public getLevel(visualTableId: VisualTableId): VisualValueChartLevel {
    let result = undefined;
    this.levels.forEach(level => {
      if (shallowEqual(level.id, visualTableId)) {
        result = level;
      }
    });
    return result;
  }

  /**
   * add attribute definition to first matching visual table for this value chart
   * @param {VisualTableId} visualTableId table to which attribute belongs
   * @param {string} attName attribute name
   * @param {number} x position relative to START of value chart in svg coordinates
   * @param {number} width width of attribute compartment
   * @param {number} height height of attribute compartment
   * @param {boolean} levelRelativePosition
   * @param  {boolean} addAtLastPosition
   * @returns {VisualAttributeDefinition}
   */
  public addAttributeDefinition(visualTableId: VisualTableId, attName: string, x: number, width: number, height: number, levelRelativePosition: boolean, addAtLastPosition: boolean): VisualAttributeDefinition {
    let result = undefined;
    const level: VisualValueChartLevel = this.getLevel(visualTableId);
    if (level) {
      // coordinates are relative to start of level, subtract level header x position and gap (don't know why gap is needed ?!)
      const dx = levelRelativePosition ? x : (addAtLastPosition ? level.header.x + level.header.width : x - level.header.x - level.index * DiagramVisualConstants.VC_GAP);
      const dy = DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH;
      const attributeDefinition = modelStore.getAttributeDefinition(visualTableId.tableId, attName);
      result = new VisualAttributeDefinition(new VisualAttributeId(level.id, attName), attributeDefinition, attName, attName, dx, dy, width, height);
      level.addVisualAttributeDefinition(result);
    } else {
      throw Error("Table '" + visualTableId.toKey() + "' for Attribute '" + attName + "' not found in Value Chart");
    }
    return result;
  }

  /**
   * move the given visual table of this diagram to the new level, levels counted from 0
   */
  public moveLevel(idOfLevelToMove: VisualTableId, newIndex: number): void {
    const levelToMove = this.getLevel(idOfLevelToMove);
    Validate.isDefined(levelToMove);
    Validate.isTrue(0 <= newIndex && newIndex < this.levels.length);
    const wasRootLevel = this.removeLevelFromLinkedList(idOfLevelToMove);
    const levelBeforeItShouldBeInserted = this.levels[newIndex];
    this.insertLevelBefore(levelToMove, levelBeforeItShouldBeInserted);
    // if root level was changed set column count to 1, since root level column count cannot change
    if (this._rootLevel) {
      this._rootLevel.columns = 1;
    }
    this.layout(true);
  }

  public debugDump(): string {
    return "Level Table Ids: " + this.levels.map(l => l.tableId).join(",") + "\n\n";
  }
}
