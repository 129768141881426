import {ActionBase, LoadAction} from "../../common/actions/BaseAction";
import {MatrixCellConfigurationPayload, MatrixModel} from "../models/MatrixModel";
import {ViewId, VisualTableId} from "../../core/utils/Core";
import {UUID} from "../../api/api";

/**
 * change the matrix cell configuration to the given values
 */
export class ChangeCellConfigurationAction extends ActionBase<MatrixCellConfigurationPayload> {
  type: "changeCellConfiguration" = "changeCellConfiguration";

  constructor(viewId: ViewId, joinTableId: VisualTableId, joinTableAttributeName: string, showCount: boolean, groupId?: UUID) {
    super({joinTableId, joinTableAttributeName: joinTableAttributeName, showCount}, viewId, groupId);
  }
}

export type MatrixAsyncActions =
    ChangeCellConfigurationAction
    ;

/**
 * Load actions are handled by generic mechanism, so we just define some types for typed payloads, but do not use these classes
 * TODO: find a better way to handle this
 */

export class LoadMatrixAction extends LoadAction<MatrixModel> {
  type: "loadMatrix" = "loadMatrix";
}