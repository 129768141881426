import {ElementId, TableId} from "../Core";
import {HierarchicalElementGraph} from "../elementgraph/ElementGraph";
import {HierarchicalNodeCollectorMatcher} from "./NodeMatcher";

/**
 * Created by P.Bernhard on 08.11.2017.
 */

export interface NodeCollector {
  collectNodes(tableNodeCollectorData: TableNodeCollectorData): Set<ElementId>;
}

export class TableNodeCollectorData {
  private readonly _startNodes: ElementId[];
  private readonly _table: TableId;
  private readonly _hierarchy: HierarchicalElementGraph;

  constructor(startNodes: ElementId[], table: TableId, hierarchy: HierarchicalElementGraph) {
    this._startNodes = startNodes;
    this._table = table;
    this._hierarchy = hierarchy;

    if (!this.startNodesAreInGivenTable())
      throw new Error("All start nodes must be within the same table!");
  }

  public get startNodes(): ElementId[] {
    return this._startNodes;
  }

  public get hierarchy(): HierarchicalElementGraph {
    return this._hierarchy;
  }

  public get level(): number {
    return this._hierarchy.getLevelForTable(this._table);
  }

  private startNodesAreInGivenTable(): boolean {
    const level: number = this._hierarchy.getLevelForTable(this._table);
    const nodesInLevel: Set<ElementId> = this._hierarchy.getElementsPerLevel(level);
    for (const startNode of this._startNodes) {
      if (!nodesInLevel.has(startNode)) {
        return false;
      }
    }

    return true;
  }
}


export class TableNodeCollector implements NodeCollector {

  private _nodeCollectorMatcher: HierarchicalNodeCollectorMatcher;

  constructor(nodeCollectorMatcher: HierarchicalNodeCollectorMatcher) {
    this._nodeCollectorMatcher = nodeCollectorMatcher;
  }

  public collectNodes(tableNodeCollectorData: TableNodeCollectorData): Set<ElementId> {
    const collectedNodes: Set<ElementId> = this._nodeCollectorMatcher.collectNodes(tableNodeCollectorData.startNodes, tableNodeCollectorData.hierarchy, tableNodeCollectorData.level);
    tableNodeCollectorData.startNodes.forEach(nodeId => {
      collectedNodes.add(nodeId);
    });

    return collectedNodes;
  }

}