/* InfoDialog.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Februar 2018
 */
import * as React from "react";
import {ViewType} from "../constants/Enums";
import Log from "../utils/Logger";
import {createStyles, Dialog, IconButton, Link, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {Close, Email} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";
import {Dispatcher} from "../utils/Dispatcher";
import {ShowDialogAction} from "../actions/InteractionStateActions";
import {showDialog} from "../utils/CommonDialogUtil";
import {Configuration} from "../utils/Configuration";
import Logo from "../icons/header/Logo";

interface LocalProps {
  open: boolean;
  version: string;
}

interface LocalState {
  type: ViewType;
}

const styles = (theme: Theme): StyleRules => createStyles({
  dialogPaper: {
    width: "440px",
    maxWidth: "440px",
    height: "250px",
    maxHeight: "250px",
    alignItems: "center",
    margin: 0,
    backgroundColor: theme.metus.dialog.dialogBackgroundColor,
    position: "fixed",
    bottom: "12px",
    left: "76px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px",
  },
  logoContainer: {
    alignContent: "left",
    paddingTop: "15px",
  },
  logo: {
    width: "150px",
    color: theme.palette.primary[50]
  },
  closeIconButton: {
    color: theme.palette.primary[50],
    paddingBottom: "25px"
  },
  closeIcon: {
    height: "16px",
    width: "16px",
  },
  dialogTitle: {
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px",
  },
  typographyTitle: {
    fontSize: "24px",
    fontFamily:  theme.metus.typography.fontFamily, //"Helvetica Neue",
    paddingLeft: "20px",
    fontWeight: 300,
    color: theme.palette.primary[50],
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "98%",
    height: "58px",
    borderTop: "thin solid black",
    marginTop: "50px"
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row"
  },
  addressContainer: {
    alignSelf: "center",
  },
  typographyAddress: {
    fontSize: "21px",
    fontWeight: 400,
    fontFamily:  theme.metus.typography.fontFamily,//"Helvetica Neue",
    color: theme.metus.dialog.primaryText,
  },
  iconContainer: {
    alignSelf: "center",
    color: theme.metus.dialog.buttonFill,
    paddingRight: "10px",
    paddingTop: "4px",
  },
  icon: {
    height: "21px",
    width: "21px",
  },

});


const log = Log.logger("InfoDialog");
type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class InfoDialog extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {type: ViewType.Chart};
  }

  private handleCancel(): void {
    Dispatcher.dispatch(new ShowDialogAction(false));
  }

  render(): JSX.Element {
    log.debug("InfoDialog rendering", this.state);
    return <Dialog data-testselector="NewDiagramPopup"
                   open={this.props.open}
                   classes={{paper: this.props.classes.dialogPaper}}
                   onClose={this.handleCancel}>
      <div className={this.props.classes.header}>
        <div className={this.props.classes.logoContainer}>
          <Logo classes={{root: this.props.classes.logo}}/>
        </div>
        <IconButton onClick={this.handleCancel} data-testselector="closeDialogButton"
                    classes={{root: this.props.classes.closeIconButton}}>
          <Close classes={{root: this.props.classes.closeIcon}}/>
        </IconButton>
      </div>
      <div className={this.props.classes.dialogTitle}>
        <Typography
            className={this.props.classes.typographyTitle}>{`Client Version: ${this.props.version}`}</Typography>
      </div>
      <div className={this.props.classes.footer}>
        <div className={this.props.classes.footerContainer}>
          <div className={this.props.classes.iconContainer}>
            <Email classes={{root: this.props.classes.icon}}/>
          </div>
          <div className={this.props.classes.addressContainer}>
            <Link href="mailto:support-next@metus.de" underline={"none"}>
              <Typography className={this.props.classes.typographyAddress}>
                support-next@metus.de
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </Dialog>;
  }

}

const StyledInfoDialog = withStyles(styles)(InfoDialog);
export default StyledInfoDialog;

export function showInfoDialog(display: boolean): void {
  showDialog(display, <StyledInfoDialog open={display} version={Configuration.version()}/>);
}
