/**
 * Created by MeegenM on 04.04.2017.
 */

/**
 * drag types for common elements
 */
export enum DragType {
  // metus core table
  CORE_TABLE = "CORE_TABLE",
  // metus core attribute definition
  CORE_ATTRIBUTE_DEFINITION = "CORE_ATTRIBUTE_DEFINITION",
  CORE_FOLDER = "CORE_FOLDER",
  // one or more elements
  CORE_ELEMENTS = "CORE_ELEMENTS",
  // metus view
  VIEW = "VIEW",
  RESIZE_HANDLE = "RESIZE_HANDLE",
  // one or more visual elements
  VISUAL_ELEMENTS = "VISUAL_ELEMENTS",
  ATTRIBUTE_COLUMN_MOVE = "VISUAL_ATTRIBUTE_COLUMN_MOVE",
  /** visual table column */
  VISUAL_TABLE_COLUMN_MOVE = "VISUAL_TABLE_COLUMN_MOVE"
}

export default DragType;
