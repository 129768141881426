import {MatrixFilterProperties} from "../components/properties/filter/MatrixFilterPropertiesComponent";
import {matrixStore} from "../stores/MatrixStore";
import Log from "../../common/utils/Logger";
import {MatrixCellConfigurationProperties} from "../components/properties/MatrixCellConfigurationPropertiesComponent";
import {
  IMetusPropertiesProviderContext,
  MetusPropertiesProvider
} from "../../commonviews/contexts/IMetusPropertiesProviderContext";
import {ViewType} from "../../common/constants/Enums";

const log = Log.logger("matrix.propertiesprovider");
export const matrixCellConfigurationPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): MatrixCellConfigurationProperties => {
  let result: MatrixCellConfigurationProperties = null;
  const matrix = matrixStore.getMatrixById(context.activeViewId);

  if (matrix && matrix.viewType === ViewType.ChainMatrix) {
    result = {
      activeViewId: context.activeViewId,
      joinTableId: matrix.cellConfiguration.joinTableId,
      joinTableAttributeName: matrix.cellConfiguration.joinTableAttributeName,
      showCount: matrix.cellConfiguration.showCount
    };
  }

  return result;
};

export const matrixColumnFilterPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): MatrixFilterProperties => {
  log.debug("Providing matrix properties for selection", context.activeViewId);

  let result: MatrixFilterProperties = null;
  const matrix = matrixStore.getMatrixById(context.activeViewId)

  if (matrix !== undefined && matrix.hasColumnHierarchy) {
    result = {activeViewId: context.activeViewId};
  }

  return result;
};