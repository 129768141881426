import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class AttachmentAttributeIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M6.498,19.542 C5.563,19.542 4.685,19.178 4.023,18.517 C2.659,17.152 2.659,14.932 4.023,13.567 C4.023,13.567 12.038,5.552 12.127,5.464 C13.07,4.521 14.325,4 15.661,4 C16.997,4 18.253,4.521 19.197,5.465 C21.146,7.414 21.146,10.586 19.197,12.536 L12.479,19.253 L11.772,18.546 L18.49,11.829 C20.049,10.269 20.049,7.731 18.49,6.172 C17.734,5.417 16.729,5 15.661,5 C14.593,5 13.588,5.417 12.833,6.172 C12.743,6.261 4.73,14.274 4.73,14.274 C3.757,15.249 3.757,16.835 4.73,17.81 C5.677,18.755 7.322,18.754 8.267,17.81 L16.368,9.708 C16.758,9.317 16.758,8.683 16.368,8.293 C15.992,7.917 15.332,7.916 14.954,8.293 L8.236,15.011 L7.529,14.304 L14.247,7.586 C15.003,6.83 16.321,6.832 17.075,7.586 C17.855,8.366 17.855,9.635 17.075,10.415 L8.974,18.517 C8.312,19.178 7.434,19.542 6.498,19.542"/> ;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(AttachmentAttributeIcon);