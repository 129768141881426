import * as React from "react";

import {Classifier} from "../../../../common/utils/ClassifierLogger";
import Log from "../../../../common/utils/Logger";
import {BaseSectionProperties} from "../../../../properties/model/PropertiesModel";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import {matrixStore} from "../../../stores/MatrixStore";
import AttributeFilterComponent from "./AttributeFilterComponent";
import PropertiesSectionLayout from "../../../../properties/components/PropertiesSectionLayout";
import PropertiesButton from "../../../../properties/components/PropertiesButton";
import autobind from "autobind-decorator";
import {FilterDefinition} from "../../../models/FilterDefinition";
import {Dispatcher} from "../../../../common/utils/Dispatcher";
import {AddFilterDefinitionAction} from "../../../../commonviews/actions/SharedViewActions";
import {observer} from "mobx-react";
import {generateUUID} from "../../../../common/utils/IdGenerator";

const log = Log.logger("properties");


const renderLog = Log.logger("MatrixFilterPropertiesSection", Classifier.render);

const styles = (theme: Theme): StyleRules => createStyles({});

export interface MatrixFilterProperties extends BaseSectionProperties {
}

interface LocalState {
}

type StyledLocalProps = MatrixFilterProperties & WithStyles<typeof styles>;

@observer
class MatrixFilterPropertiesComponent extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    log.debug("Rendering Matrix Filter Properties");

    const matrix = matrixStore.getMatrixById(this.props.activeViewId);
    const tables = matrix.columnHierarchy.tables;
    const tableFilters = matrix.columnHierarchy.filterDefinitions;

    return <PropertiesSectionLayout>
      {tableFilters.map(tableFilter => <AttributeFilterComponent matrixId={this.props.activeViewId} key={generateUUID()}
                                                                 filterDefinition={tableFilter}/>)}
      <PropertiesButton onClick={this.createNewFilterDefinition}>New Filter</PropertiesButton>
    </PropertiesSectionLayout>;
  }


  @autobind
  private createNewFilterDefinition():void {
    const matrixId = this.props.activeViewId;
    log.debug("Create new Attribute Filter Definition");
    Dispatcher.dispatch(new AddFilterDefinitionAction(matrixId, new FilterDefinition()));
  }
}

export default withStyles(styles)(MatrixFilterPropertiesComponent);