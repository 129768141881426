// lib imports
import * as React from "react";
import Log from "../../../common/utils/Logger";
import {ViewerContext} from "../../utils/ViewerContext";
import {NestedElementComponent} from "../common/NestedElementComponent";
import {VisualChartElement} from "../../models/chart/VisualChartElement";
import {VisualChartColumn} from "../../models/chart/VisualChartColumn";
import {observer} from "mobx-react";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {Rect} from "../../../common/utils/Geometry";
import {ComponentMapper, mapVirtualized, VirtualVisibility} from "../../../common/utils/VirtualRenderUtil";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

interface LocalProps {
  viewerContext: ViewerContext;
  visibleSVGRect: Rect;
  table: VisualChartColumn;
  animationCount?: number;
}

// App pure component
@observer
export class ChartColumnElementsComponent extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    const visualElements: VisualChartElement[] = this.props.table.children;
    renderLog.debug(`Rendering ChartColumnElementsComponent with ${visualElements.length} elements`);
    // render invisible components as plain rect, prerendered and visible as standard
    const componentMapper: ComponentMapper<VisualChartElement> = (visualElement: VisualChartElement, visibility: VirtualVisibility, levelCount: number, isThumbNail: boolean): JSX.Element =>
        visibility === VirtualVisibility.INVISIBLE ?
            <rect key={visualElement.id.toKey()} x={visualElement.x} y={visualElement.y} width={visualElement.width}
                  height={visualElement.height} style={{fill: "none", stroke: "black"}}/> :
            <NestedElementComponent key={visualElement.id.toKey()}
                                    renderWithoutDetails={isThumbNail}
                                    visualAttributeDefinitions={this.props.table.visualAttributeDefinitions}
                                    visualTableId={this.props.table.id} viewModel={visualElement}
                                    viewerContext={this.props.viewerContext} levelCount={1} hidden={false}
                                    animationCount={this.props.animationCount}
                                    visibleSVGRect={this.props.visibleSVGRect}
                                    wrapLinesInAttributes={this.props.visibleSVGRect === undefined}
            />;
    const visualsForVirtualRendering = this.props.table.children.filter((value: VisualChartElement) => value.visible);
    const virtualMappedElements = mapVirtualized(this.props.visibleSVGRect, 1, visualsForVirtualRendering, componentMapper, this.props.viewerContext.scale,"Chart Column " + this.props.table.header.name);
    return <g>
      {virtualMappedElements}
    </g>;
  }

}