/**
 * Created by MeegenM on 09.02.2017.
 * helper to calculate text lines contained in a given box with auto-wrapping and truncation.
 *
 * Supported Features:
 * <ul>
 *   <li>supports forced line breaks using \n and/or \r in text</li>
 *   <li>Text can be horizontally left/center/right aligned</li>
 *   <li>Text can be vertically top/center/bottom aligned</li>
 *   <li>If text does not fit in the box, it will be truncated using ellipsis</li>
 *   <li>Truncation can be done at the end of text or in the middle</li>
 * </ul>
 * @author Marco van Meegen
 *
 */
import {SVGTextSizer, TextSizer} from "./TextSizer";
import {Validate} from "./Validate";
import Log from "./Logger";
const log = Log.logger("TextWrappingHelper");
export enum TruncationStyle {
  /** text which does not fit will be hidden */
  hidden,
      /** text will be truncated, last characters displayed will be ellipsis */
  ellipsis
}

export class TextWrappingHelper {
  private text: string;
  private width: number;
  private height: number;
  private horizontalMargin: number;
  private verticalMargin: number;
  private style: any;
  private svgTextStyle: any;
  private truncation: TruncationStyle;

  private _lineHeight: number;

  private cacheValid: boolean = false;
  private lines: string[];
  private xOffset: number;
  private yOffset: number;

  private static textSizer: TextSizer = new SVGTextSizer();


  public static readonly ELLIPSIS: string = "...";

  constructor(text: string, width: number, height: number, horizontalMargin: number, verticalMargin: number, truncation: TruncationStyle, style: any) {
    this.lines = [];
    this.updateValues(text, width, height, horizontalMargin, verticalMargin, truncation, style);
  }

  public updateValues(text: string, width: number, height: number, horizontalMargin: number, verticalMargin: number, truncation: TruncationStyle, style: any): void {
    this.text = text || "";
    this.width = width;
    this.height = height;
    this.horizontalMargin = horizontalMargin;
    this.verticalMargin = verticalMargin;
    this.truncation = truncation;
    this.style = style;
    this.cacheValid = false;
    this.svgTextStyle = TextWrappingHelper.convertTextStyle(style);
  }

  public updateText(newText: string): void {
    this.text = newText || "";
    this.wrapLines();
  }

  public static setTextSizer(textSizer: TextSizer): void {
    this.textSizer = textSizer;
  }

  get lineHeight(): number {
    return this._lineHeight;
  }

  get calculatedTextStyle(): any {
    return this.svgTextStyle;
  }

  /**
   * calculate svg text style translating from html/metus style
   * @return svgTextStyle ; textAlign->textAnchor, color->fill, dominant-baseline="text-after-edge" is added
   */
  public static convertTextStyle(metusStyle: any, central: boolean = false): any {
    const svgTextStyle: any = Object.assign({
      dominantBaseline: central ? "central":"text-after-edge"
    }, metusStyle);
    if (svgTextStyle.hasOwnProperty("color")) {
      svgTextStyle["fill"] = svgTextStyle["color"];
    }

    // horizontal align
    svgTextStyle["textAnchor"] = "start";
    if (svgTextStyle.hasOwnProperty("textAlign")) {
      switch (svgTextStyle["textAlign"]) {
        case "left":
        default:
          break;
        case "center":
          svgTextStyle["textAnchor"] = "middle";
          break;
        case "right":
          svgTextStyle["textAnchor"] = "end";
          break;
      }
    }
    delete svgTextStyle.textAlign;
    delete svgTextStyle.color;
    return svgTextStyle;
  }

  /**
   * calculate svg text style and xy offsets dependent on margins, lineHeight, #lines after wrapping and horizontal/vertical alignment
   * @return {svgTextStyle, xOffset,yOffset}
   */
  private calculateOffsets(): {xOffset: number, yOffset: number} {
    const contentAvailableWidth = this.width - 2 * this.horizontalMargin;
    const contentAvailableHeight = this.height - 2 * this.verticalMargin;
    const contentHeight = this.lines.length * this.lineHeight;
    let xOffset = this.horizontalMargin;
    let yOffset = this.verticalMargin;
    // horizontal align
    if (this.calculatedTextStyle.hasOwnProperty("textAnchor")) {
      const align: string = this.calculatedTextStyle["textAnchor"];
      switch (align) {
        case "start":
        default:
          xOffset = this.horizontalMargin;
          break;
        case "middle":
          xOffset = this.horizontalMargin + contentAvailableWidth / 2;
          break;
        case "end":
          xOffset = this.horizontalMargin + contentAvailableWidth;
          break;
      }
    }

    // vertical align
    const space = Math.max(0, contentAvailableHeight - contentHeight);
    if (this.calculatedTextStyle.hasOwnProperty("verticalAlign")) {
      const valign: string = this.calculatedTextStyle["verticalAlign"];
      switch (valign) {
        case "top":
        default:
          yOffset = this.verticalMargin;
          break;
        case "middle":
          yOffset = this.verticalMargin + space / 2;
          break;
        case "bottom":
          yOffset = this.verticalMargin + space;
          break;
      }
    }
    return {xOffset, yOffset};
  }

  public calculatedOffsets(): {xOffset: number, yOffset: number} {
    if (!this.cacheValid) {
      this.wrapLines();
    }
    Validate.isTrue(this.cacheValid, "wrapLines() must be called before");
    return {xOffset: this.xOffset, yOffset: this.yOffset};
  }

  public wrapLines(): string[] {
    if (!this.cacheValid) {
      TextWrappingHelper.textSizer.activate(this.svgTextStyle);
      try {
        this._lineHeight = TextWrappingHelper.textSizer.lineHeight();
        // always show one line
        const maxLines = Math.max(1, Math.floor((this.height - 2 * this.verticalMargin) / this.lineHeight));
        this.lines = this.calculateLines(this.text, this.width - 2 * this.horizontalMargin, maxLines);
        const {xOffset, yOffset} = this.calculateOffsets();
        this.xOffset = xOffset;
        this.yOffset = yOffset;
        this.cacheValid = true;
      } finally {
        TextWrappingHelper.textSizer.deactivate();
      }
    }
    return this.lines;
  }

  private calculateLines(text: string, targetWidth: number, numLines: number): string[] {
    log.debug("CalculateLines", text, targetWidth, numLines);
    const ellipsisWidth: number = TextWrappingHelper.textSizer.calculateWidth(TextWrappingHelper.ELLIPSIS);
    const textLines = text.split("\n").map(text => {
      let hyphenSplits: string[] = text.split(/-/);
      hyphenSplits = hyphenSplits.map((word, index) => index < hyphenSplits.length - 1 ? word + "-" : word);

      // split remaining words by blank
      return hyphenSplits.map(text => text.split(/\s+/)).reduce((a, b) => a.concat(b));
    });
    let didTruncate: boolean = true;

    const lines = [];
    for (let line = 1; line <= numLines; line++) {
      const textWords = textLines[0];

      // Handle newline
      if (textWords.length === 0) {
        lines.push();
        textLines.shift();
        line--;
        continue;
      }

      let resultLine = textWords.join(" ");

      if (this.measureWidth(resultLine) <= targetWidth) {
        if (textLines.length === 1) {
          // Line is end of text and fits without truncating
          didTruncate = false;

          lines.push(resultLine);
          break;
        }
      }

      if (line === numLines) {
        // Binary search determining the longest possible line inluding truncate string
        const textRest = textWords.join(" ");

        let lower = 0;
        let upper = textRest.length - 1;

        while (lower <= upper) {
          const middle = Math.floor((lower + upper) / 2);

          const testLine = textRest.slice(0, middle + 1);

          if (this.measureWidth(testLine) + ellipsisWidth <= targetWidth) {
            lower = middle + 1;
          } else {
            upper = middle - 1;
          }
        }

        resultLine = textRest.slice(0, lower) + TextWrappingHelper.ELLIPSIS;
      } else {
        // Binary search determining when the line breaks
        let lower = 0;
        let upper = textWords.length - 1;

        while (lower <= upper) {
          const middle = Math.floor((lower + upper) / 2);

          const testLine = textWords.slice(0, middle + 1).join(" ");

          if (this.measureWidth(testLine) <= targetWidth) {
            lower = middle + 1;
          } else {
            upper = middle - 1;
          }
        }

        // The first word of this line is too long to fit it
        if (lower === 0) {
          // Jump to processing of last line
          line = numLines - 1;
          continue;
        }

        resultLine = textWords.slice(0, lower).join(" ");
        textLines[0].splice(0, lower);
      }

      lines.push(resultLine);
    }
    log.debug("CalculateLines result: ", lines);

    return lines;
  }

  private measureWidth(text: string): number {
    return TextWrappingHelper.textSizer.calculateWidth(text);
  }
}

