import * as React from "react";
import Log from "../utils/Logger";
import {Classifier} from "../utils/ClassifierLogger";
import * as _ from "lodash";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";

const log = Log.logger("ResizerComponent");
const renderLog = Log.logger("ResizerComponent", Classifier.render);

const WIDTH = 6;

const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    width: WIDTH,
    position: "absolute",
    top: 64,
    bottom: 0,
    zIndex: 2, // over mosaic
    backgroundColor: "#000000",
    opacity: 0,
    margin: "0px 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // align in the middle of the screen (top-bottom)
    alignItems: "center", // align on top of the vertical line (right-left)
    "&:hover": {
      cursor: "ew-resize",
      opacity: 1,
    }
  },
  icon: {
    width: "100%",
    margin: `${theme.spacing(1) / 2}px 0px`,
    backgroundColor: "#ff0000",// theme.palette.secondary.A100, TODO MB
    fill: theme.palette.secondary[500],
    "&:hover": {
      cursor: `pointer`,
    }
  },
});

interface LocalProps {
  onResize: (delta: number) => void;
  throttleMS: number;
  onRightClicked?: () => void;
  onLeftClicked?: () => void;
  xPosition: number;
  positionAnchor: "right" | "left";
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

interface LocalState {
  position: number;
  isDragging: boolean;
}

class ResizerComponent extends React.PureComponent<StyledLocalProps, LocalState> {
  private _resizeThrottled: any;

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {position: null, isDragging: false};
    this.createThrottledOnResize(props);
  }

  componentDidMount(): void {
    document.addEventListener("mouseup", this.onMouseUp);
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("touchmove", this.onTouchMove);
    document.addEventListener("touchend", this.onTouchEnd);
  }

  componentWillUnmount(): void {
    document.removeEventListener("mouseup", this.onMouseUp);
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("touchmove", this.onTouchMove);
    document.removeEventListener("touchend", this.onTouchEnd);
  }

  componentDidUpdate(prevProps: StyledLocalProps): void {
    if (prevProps.onResize !== this.props.onResize || prevProps.throttleMS !== this.props.throttleMS) {
      this.createThrottledOnResize(this.props);
    }
  }

  createThrottledOnResize(props: StyledLocalProps): void {
    log.debug("Creating throttled onResize function");
    const wrappedOnResize = (delta: number): void => {
      log.debug("Doing resize", delta);
      this.setState(prevState => {
        return {position: prevState.position + delta};
      });
      props.onResize(delta);
    };
    this._resizeThrottled = _.throttle(wrappedOnResize, props.throttleMS);
  }

  convertToTouch(mouseEvent: any): void {
    return Object.assign({}, mouseEvent, {
      touches: [{clientX: mouseEvent.clientX, clientY: mouseEvent.clientY}],
    });
  }

  @autobind onMouseDown(event: any): void {
    this.onTouchStart(this.convertToTouch((event)));
  }

  @autobind onTouchStart(event: any): void {
    log.debug("ResizerComponent starts moving");
    const position = event.touches[0].clientX;
    this.setState({
      position,
      isDragging: true
    });
  }

  @autobind onMouseUp(event: any): void {
    this.onTouchEnd(this.convertToTouch(event));
  }

  @autobind onTouchEnd(event: any): void {
    log.debug("ResizerComponent stops moving");
    this.setState({
      position: null,
      isDragging: false,
    });
  }

  @autobind onMouseMove(event: any): void {
    this.onTouchMove(this.convertToTouch(event));
  }

  @autobind onTouchMove(event: any): void {
    if (this.state.isDragging) {
      log.debug("ResizerComponent handles move", event);
      const position = event.touches[0].clientX;
      const delta = position - this.state.position;
      if (delta !== 0) {
        log.debug("Resized", delta);
        this._resizeThrottled(delta);
      }
    }
  }

  render(): JSX.Element {
    renderLog.debug("Resizer: rendering", this.state);
    const styles = {[this.props.positionAnchor]: this.props.xPosition - WIDTH / 2};
    return <div
        className={this.props.classes.root}
        style={styles}
        onMouseDown={this.onMouseDown}
        onTouchStart={this.onTouchStart}
    >{this.props.onLeftClicked ?
        <ChevronLeft viewBox="6 6 12 12"
                     className={this.props.classes.icon}
                     onClick={(e: any): void => {
                       log.debug("Left button clicked");
                       this.props.onLeftClicked();
                     }}/> : null}
      {this.props.onRightClicked ?
          <ChevronRight viewBox="6 6 12 12"
                        className={this.props.classes.icon}
                        onClick={(e: any): void => {
                          log.debug("Right button clicked");
                          this.props.onRightClicked();
                        }}/> : null}
    </div>;
  }
}

export default withStyles(styles)(ResizerComponent);